import { Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

// RXJS
import { fromEvent } from 'rxjs';

// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Services
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { SwitchGroupService } from '@app/core/_base/layout';

@Component({
    selector: 'kt-blocco-unita',
    templateUrl: './blocco-unita.component.html',
    styleUrls: ['./blocco-unita.component.scss']
})
export class BloccoUnitaComponent implements OnInit {

    pagination: any;
    utility: any;
    categorieMerci: any;
    currentFilterCfg: any;

    @ViewChild('filterAllFiledVisGood', { static: true }) filterAllFiledVisGood!: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private gestioneMerciService: GestioneMerciService,
        private translate: TranslateService,
        public staticCollectionsService: StaticCollectionsService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private switchGroupService: SwitchGroupService,
        private ref: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.pagination = {
            pageSize: 10,
            TotalRows: 0,
            pageIndex: 0
        };

        fromEvent(this.filterAllFiledVisGood.nativeElement, 'keydown').subscribe(
            (event: any) => {
                if (event.keyCode === 13) {
                    this.filter(this.filterAllFiledVisGood.nativeElement.value, 'FreeSearch');
                }
            }
        );

        $(window).resize(() => {
            this.redrawTable();
        });

        this.refreshTable();
    }


    clearFilters() {
        this.filterAllFiledVisGood.nativeElement.value = '';
        this.categorieMerci = [];

        delete this.currentFilterCfg.FreeSearch;
        delete this.currentFilterCfg.CategoryIds;

        this.table.draw();
        this.ref.detectChanges();
    }

    filter(event: any, type: string) {
        this.currentFilterCfg[type] = event;
        this.table.draw();
    }

    refreshTable() {
        if (!this.currentFilterCfg || Object.keys(this.currentFilterCfg).length == 0) {
            this.currentFilterCfg = {
                EnableState: 2,
                VisibleState: 1,
                FBType: 7
            };
        }

        if (!this.currentFilterCfg.FBType) {
            this.currentFilterCfg.FBType = 7;
        }

        if (!this.currentFilterCfg.EnableState) {
            this.currentFilterCfg.EnableState = 2;
        }

        if (!this.currentFilterCfg.VisibleState) {
            this.currentFilterCfg.VisibleState = 1;
        }

        if (!this.currentFilterCfg.ShowInvisible) {
            this.currentFilterCfg.ShowInvisible = false;
        }

        if (!this.currentFilterCfg.ShowDisabled) {
            this.currentFilterCfg.ShowDisabled = false;
        }

        this.initDataTable();
    }

    table: any;
    hoverglassActive: boolean = false;
    initDataTable() {
        if ($.fn.dataTable.isDataTable('#tableBlockUnit')) {
            $('#tableBlockUnit').DataTable().destroy();
        }

        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();

            let dtOptions: any = {
                initComplete: () => {
                    this.hoverglassActive = false;
                },
                processing: true,
                serverSide: true,
                scrollToTop: true,
                ajax: {
                    url: currentLogin.endpointURL + "/api/Entity/DataGoodsPage",
                    type: "POST",
                    data: (d: any) => {
                        d.filters = this.currentFilterCfg;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        this.pagination.TotalRows = response.recordsFiltered;
                        this.ref.detectChanges();
                        return response.data;
                    }
                },
                dom: 'rt<"paginator">',
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'//this.translate.instant('COMMONS.PROCESSING')
                },
                paging: true,
                searching: true,
                ordering: true,
                scrollY: 'calc(100vh - 300px)',
                scrollCollapse: true,
                scrollX: true,
                fixedColumns: {
                    leftColumns: 2
                },
                columnDefs: [
                    {
                        targets: [0], width: '100px', data: 'Code', name: 'Code', render: (data) => {
                            return `<div class="overflow-ellipses colCode" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }
                    },
                    {
                        targets: [1], width: '300px', data: 'Name', name: 'Name', render: (data, type, item) => {
                            const barred = item.IsDisabled ? 'barred' : '';
                            const cursive = item.IsHidden ? 'cursive' : '';

                            // Pulisco
                            data = data.replace(/"/g, '')
                                .replace(/'/g, '')
                                .replace(/\n/g, '')
                                .trim();

                            const name = data.length > 50 ? data.substr(0, 50) + '...' : data;

                            const linkToGood = `<div class="display-flex-fill overflow-ellipses"><a href="javascript:void(0);" data-toggle="tooltip" title="${data}" 
                                                   class="editItem ${barred} ${cursive} ${!item.IsRecipeUsable ? 'text-danger' : ''}">                                                    
                                                    ${name}
                                                </a></div>`;

                            return `<div class="display-flex-space-between flex-align-center display-flex-fill">
                                        <div class="display-flex-left display-flex-fill">
                                            ${linkToGood}
                                        </div>
                                    </div>`;
                        }
                    },
                    {
                        targets: [2], width: '100px', data: 'Unit', name: 'Unit'
                    },
                    {
                        targets: [3], width: '100px', data: 'SecondaryUnit', name: 'SecondaryUnit'
                    },
                    {
                        targets: [4], width: '30px', data: 'Lock_Inventory_UnitBase', name: 'Lock_Inventory_UnitBase', render: (data) => {
                            return data ? `<i class="pointer fas fa-check-square" name="Lock_Inventory_UnitBase"></i>` : `<i class="pointer far fa-square" name="Lock_Inventory_UnitBase"></i>`;
                        }, className: 'text-center clickable', orderable: false
                    },
                    {
                        targets: [5], width: '30px', data: 'Lock_Inventory_Unit', name: 'Lock_Inventory_Unit', render: (data) => {
                            return data ? `<i class="pointer fas fa-check-square" name="Lock_Inventory_Unit"></i>` : `<i class="pointer far fa-square" name="Lock_Inventory_Unit"></i>`;
                        }, className: 'text-center clickable', orderable: false
                    },
                    {
                        targets: [6], width: '30px', data: 'Lock_Waste_UnitBase', name: 'Lock_Waste_UnitBase', render: (data) => {
                            return data ? `<i class="pointer fas fa-check-square" name="Lock_Waste_UnitBase"></i>` : `<i class="pointer far fa-square" name="Lock_Waste_UnitBase"></i>`;
                        }, className: 'text-center clickable', orderable: false
                    },
                    {
                        targets: [7], width: '30px', data: 'Lock_Waste_Unit', name: 'Lock_Waste_Unit', render: (data) => {
                            return data ? `<i class="pointer fas fa-check-square" name="Lock_Waste_Unit"></i>` : `<i class="pointer far fa-square" name="Lock_Waste_Unit"></i>`;
                        }, className: 'text-center clickable', orderable: false
                    },
                    {
                        targets: [8], width: '30px', data: 'Lock_Transfert_UnitBase', name: 'Lock_Transfert_UnitBase', render: (data) => {
                            return data ? `<i class="pointer fas fa-check-square" name="Lock_Transfert_UnitBase"></i>` : `<i class="pointer far fa-square" name="Lock_Transfert_UnitBase"></i>`;
                        }, className: 'text-center clickable', orderable: false
                    },
                    {
                        targets: [9], width: '30px', data: 'Lock_Transfert_Unit', name: 'Lock_Transfert_Unit', render: (data) => {
                            return data ? `<i class="pointer fas fa-check-square" name="Lock_Transfert_Unit"></i>` : `<i class="pointer far fa-square" name="Lock_Transfert_Unit"></i>`;
                        }, className: 'text-center clickable', orderable: false
                    }

                ],
                autoWidth: true,
                aaSorting: [[0, 'asc'], [1, 'asc']],
            };

            this.table = $('#tableBlockUnit').DataTable(dtOptions);
            this.table.on('page.dt', function () {
                $('.dataTables_scrollBody').animate({
                    scrollTop: 0
                }, 'slow');
            });

            $('#tableBlockUnit tbody ').on('click', 'td.clickable i', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                if (data) {
                    const isAlreadyChecked = $(event.target).hasClass('fas fa-check-square');
                    if (isAlreadyChecked) {
                        $(event.target).removeClass('fas fa-check-square');
                        $(event.target).addClass('far fa-square');
                    } else {
                        $(event.target).addClass('fas fa-check-square');
                        $(event.target).removeClass('far fa-square');
                    }

                    const model = $(event.target).attr('name');
                    switch (model) {
                        case 'Lock_Inventory_UnitBase':
                            data.Lock_Inventory_UnitBase = !isAlreadyChecked;
                            break;
                        case 'Lock_Inventory_Unit':
                            data.Lock_Inventory_Unit = !isAlreadyChecked;
                            break;
                        case 'Lock_Transfert_UnitBase':
                            data.Lock_Transfert_UnitBase = !isAlreadyChecked;
                            break;
                        case 'Lock_Transfert_Unit':
                            data.Lock_Transfert_Unit = !isAlreadyChecked;
                            break;
                        case 'Lock_Waste_UnitBase':
                            data.Lock_Waste_UnitBase = !isAlreadyChecked;
                            break;
                        case 'Lock_Waste_Unit':
                            data.Lock_Waste_Unit = !isAlreadyChecked;
                            break;
                    }

                    this.gestioneMerciService.updateGoods(data).subscribe(
                        (result: any) => {
                            if (result.SavedId) {
                                const message = this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.SUCCESS');
                                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                            } else {
                                const message = this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.ERROR');
                                this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                            }

                        }, (error: any) => {
                            console.log('updateGoods', error);
                        }
                    );

                    //this.selectItem(!isAlreadyChecked, data);
                }
            });

        }, 100);

    }

    changePagination(event: any) {
        if (this.pagination.pageSize != event.pageSize) {
            this.pagination.pageSize = event.pageSize;
            this.pagination.pageIndex = 0;
            this.table.page.len(this.pagination.pageSize).draw();
            this.table.page(0).draw('page');
        } else if (this.pagination.pageIndex != event.pageIndex) {
            this.pagination.pageIndex = event.pageIndex;
            this.table.page(this.pagination.pageIndex).draw('page');
        }
    }

    redrawTable() {
        this.table.columns.adjust().draw();
    }


    /******************************************************************************
      ______                       _    __   ___       _______   __
     |  ____|                     | |   \ \ / / |     / ____\ \ / /
     | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
     |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
     | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
     |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
           | |
           |_|
    ******************************************************************************/
    exportAsXLSX() {
        this.table.button(1).trigger();
    }

    /******************************************************************************
            ______                       _     _____  _____  ______
         |  ____|                     | |   |  __ \|  __ \|  ____|
         | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
         |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
         | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
         |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
                     | |
                     |_|
    ******************************************************************************/
    PDFPreferences: any;
    exportAsPDF() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.translate.instant('GESTIONE_MERCI.VISIBILITA_MERCI') }),
                    //period: ''
                }
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                }
                result['language'] = this.translationService.getSelectedLanguage();
                result['table'].headerRows = 1;

                this.PDFPreferences = result;

                this.table.button(0).trigger();

            };
        });

    }

}
