// Angular
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

import moment from 'moment';

// Translate Module
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout';

import * as util from '@app/core/services/utilityfunctions';
import { MatInput } from '@angular/material/input';
import { LocalizedDateTimePipe } from '@app/core/pipes/localized-date-time.pipe';
import _ from 'lodash';

@Component({
  selector: 'kt-ddt-edit',
  templateUrl: './ddt-edit.component.html',
  styleUrls: ['./ddt-edit.component.scss']
})
export class DdtEditComponent implements OnInit {

  @ViewChild('dateInput', {
    read: MatInput
  }) dateInput!: MatInput;

  // Public properties
  content: any;
  contentForm!: FormGroup;
  hasFormErrors: boolean = false;
  viewLoading: boolean = false;
  editMode: boolean = false;
  currentSelectedWasteType!: string;
  startDate: any;
  notes!: string;

  // 
  selectedCompany: any;
  selectedSupplier: any;

  utility: any;
  locale!: string;
  isEdit: boolean = false;
  listaSuppliers: any;


  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private translate: TranslateService,
    private magazzinoService: MagazzinoService,
    public staticCollectionsService: StaticCollectionsService,
    private translationService: TranslationService,
    private localizedDateTimePipe: LocalizedDateTimePipe
  ) {
    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
    });

    this.utility = util;
  }

  ngOnInit() {
    this.content = this.data.item;
    this.editMode = this.content.Id && this.content.Id.length > 0;

    if (_.isEmpty(this.data.endpoint) || !this.data) {
      ;
    } else {
      this.isEdit = true;
    }

    //https://mantis.fbmanager.com/view.php?id=1290
    this.listaSuppliers = this.isEdit ? this.staticCollectionsService.fornitoriAbilitati$ : this.staticCollectionsService.fornitoriAbilitati$.filter((x: any) => !x.IsDisabled && !x.PurchaseImportExcluded);

    $('body').on('keyup', (e: any) => {
      if (e.code == 'F2') {
        e.preventDefault();
        e.stopPropagation();
        this.onSubmit();
      }
    });

    this.createForm();

    if (this.content && Object.keys(this.content).length > 0) {
      this.startDate = new Date((new Date(this.content.Date).getTime() - 0));      
      setTimeout(() => {
        this.selectedSupplier = this.content.SupplierId;
        this.selectedCompany = this.content.Store;
      }, 100);
    }

  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    $('body').off('keyup');
  }

  createForm() {
    this.contentForm = this.fb.group({
      Date: [this.content.Date, Validators.required],
      Amount: [this.utility.formatNumber(this.content.Amount, this.locale, 2), Validators.required],
      Description: [this.content.Description],
      Number: [this.content.Number, Validators.required],
      Store: [this.content.Store, Validators.required],
      SupplierId: [this.content.SupplierId, Validators.required],
      Note: [this.content.Note, null]
    });
  }

  /**
  * Returns page title
  */
  getTitle(): string {
    if (this.editMode) {
      return this.translate.instant('MAGAZZINO.DDT.EDIT');
    }
    return this.translate.instant('MAGAZZINO.DDT.NEW_DDT');
  }

  /**
   * Check control is invalid
   * @param controlName: string
   */
  isControlInvalid(controlName: string): boolean {
    const control = this.contentForm.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }

  onDateChange(event: any) {
    if (!this.contentForm.controls['Description'].value) {
      const description = this.localizedDateTimePipe.transform(event.value, 'MMM YY', true);
      this.contentForm.controls['Description'].setValue(description);
    }
  }

  /** ACTIONS */

  /**
   * Returns prepared customer
   */
  prepareItem(): any {
    const controls = this.contentForm.controls;
    let prepareJson = {
      Date: moment(controls['Date'].value).format('YYYY-MM-DD'),
      Amount: this.utility.parseNumber(controls['Amount'].value, this.locale),
      Number: controls['Number'].value,
      Description: controls['Description'].value,
      Store: controls['Store'].value,
      SupplierId: controls['SupplierId'].value,
      Note: controls['Note'].value
    };
    if (this.editMode) {
      prepareJson['Id'] = this.content.Id;
    }
    return prepareJson;
  }

  supplierChanged(event: any) {
    this.contentForm.controls['SupplierId'].setValue(event);
  }

  companyChanged(event: any) {
    this.contentForm.controls['Store'].setValue(event);
  }

  clearFormOnStay() {
    this.contentForm.controls['Date'].setValue('');
    this.contentForm.controls['Amount'].setValue('');
    this.contentForm.controls['Number'].setValue('');
    this.contentForm.controls['Description'].setValue('');
    //https://mantis.fbmanager.com/view.php?id=1473
    //this.contentForm.controls['Store'].setValue('');
    //this.selectedCompany = undefined;
    this.dateInput.value = '';
  }

  forceStay: any;
  /**
   * On Submit
   */
  onSubmit(forceStay: boolean = false) {
    this.hasFormErrors = false;
    const controls = this.contentForm.controls;
    /** check form */
    if (this.contentForm.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched();
        console.log(controlName);
      });
      this.hasFormErrors = true;
      return;
    }

    const editedItem = this.prepareItem();
    console.log('prepareItem', editedItem);

    if (this.editMode) {
      this.updateItem(forceStay, editedItem);
    } else {
      this.createItem(forceStay, editedItem);
    }
  }

  /**
   * Update customer
   *
   * @param _customer: CustomerModel
   */
  updateItem(forceStay: boolean, item: any) {
    this.magazzinoService.updateDDT(item).subscribe(
      (ret: any) => {
        this.forceStay = { item, isEdit: true };
        this.clearFormOnStay();
        if (!forceStay) {
          this.dialogRef.close(this.forceStay);
        }
      }, (error: any) => {
        this.forceStay = !this.forceStay ? null : this.forceStay;
        if (!forceStay) {
          this.dialogRef.close(this.forceStay);
        }
      }
    )
  }

  /**
   * Update customer
   *
   * @param _customer: CustomerModel
   */
  createItem(forceStay: boolean, item: any) {
    this.magazzinoService.addDDT(item).subscribe(
      (ret: any) => {
        this.forceStay = { item, isEdit: false };
        this.clearFormOnStay();
        if (!forceStay) {
          this.dialogRef.close(this.forceStay);
        }
      }, (error: any) => {
        if (!forceStay) {
          this.forceStay = !this.forceStay ? null : this.forceStay;
          this.dialogRef.close(this.forceStay);
        }
      }
    )
  }

}

