import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API } from '@env/environment';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

@Injectable({
	providedIn: 'root'
})
export class StoreService {

	constructor(
		private http: HttpClient,
		private switchGroupService: SwitchGroupService
	) { }

	getHeader(){
		const currentLogin = this.switchGroupService.getCurrentGroup();

		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + currentLogin.access_token
		});

		return  {url: currentLogin.endpointURL, header: httpHeaders};
	}

	

	getStores(): Observable<any> {
		const httpHeaders = this.getHeader();
		return this.http.get<any>(`${httpHeaders.url}/api/Admin/StoresFull`, { headers: httpHeaders.header });
	}


	postStore( entityBody: any): Observable<any> {
		const httpHeaders = this.getHeader();
		return this.http.put<any>(`${httpHeaders.url}/api/Admin/SaveStore`, entityBody, { headers: httpHeaders.header });
	}


}
