// Core
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
// RXJS
import { BehaviorSubject, Observable, Subscription, from, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
// Services
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { GestioneRicetteService } from '@app/core/services/gestione-ricette.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { FiltriService } from '@app/core/services/filtri.service';
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { CategorieProdottiService } from '@app/core/services/categorie-prodotti.service';
import { BulkActionsService } from '@app/core/services/bulk-actions.service';

// Layout
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';

//Dialog
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ColumnsSelectionPopupComponent } from '@app/views/partials/content/crud/columns-selection-popup/columns-selection-popup.component';
import { SostituisciIngredienteComponent } from './sostituisci-ingrediente/sostituisci-ingrediente.component';
import { EditRicettaComponent } from './edit-ricetta/edit-ricetta.component';
import { SetTypeDlgComponent } from './set-type-dlg/set-type-dlg.component';
import { SetCategoryDlgComponent } from './set-category-dlg/set-category-dlg.component';

// Pagination
import { PageEvent } from '@angular/material/paginator';

import { MatAutocompleteTrigger } from '@angular/material/autocomplete';


// Utilities
import * as util from '@app/core/services/utilityfunctions';
import * as _ from 'lodash';
import { PDFService } from '@app/core/services/pdf.service';
import { LocalizedDateTimePipe } from '@app/core/pipes/localized-date-time.pipe';
import { ManutenzioneRicetteComponent } from './manutenzione-ricette/manutenzione-ricette.component';
import { SetDepartmentDlgComponent } from './set-department-dlg/set-department-dlg.component';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';

@Component({
    selector: 'kt-gestione-ricette',
    templateUrl: './gestione-ricette.component.html',
    styleUrls: ['./gestione-ricette.component.scss']
})
export class GestioneRicetteComponent implements OnInit, OnDestroy {

    switchGroupSubcription!: Subscription;
    filtriServiceSubscription!: Subscription;
    currentCompanyFilter!: string;
    locale!: string;

    PriceZero: string = '0';
    CostZero: string = '0';
    FBType: string = '7';
    IsUsed: any = '3';
    goodsOrSubrecipeNames = new FormControl();

    PDFPreferences: any;

    isLinkedFinished: boolean = false;

    // 3 => tutti
    // 2 => SOLO IsAlert
    // 1 => NO IsAlert
    AlertState: string = '3';
    isAlert: boolean = false;

    showInitialImage: boolean = true;

    ProductOrCode: any;

    private paginator!: MatPaginator;
    @ViewChild(MatPaginator, { static: false }) set _set(content: MatPaginator) {
        if (content) { // initially setter gets called with undefined
            this.paginator = content;
        }
    }

    private autoComplete!: MatAutocompleteTrigger;
    @ViewChild('trigger', { static: false }) set _setAutoComplete(content: MatAutocompleteTrigger) {
        if (content) { // initially setter gets called with undefined
            this.autoComplete = content;
        }
    }

    table: any;
    referralFilterCfg: any;
    currentFilterCfg: any;

    utility: any;
    bulkSelect!: string;

    allRows: any;
    pagination: any;
    columnsList: any = [];
    RecipeType: any;
    filteredOptions!: Observable<any[]>;

    constructor(
        private filtriService: FiltriService,
        private gestioneRicetteService: GestioneRicetteService,
        private translate: TranslateService,
        private layoutUtilsService: LayoutUtilsService,
        private switchGroupService: SwitchGroupService,
        public dialog: MatDialog,
        public roleService: RoleService,
        public staticCollectionsService: StaticCollectionsService,
        private gestioneMerciService: GestioneMerciService,
        private ref: ChangeDetectorRef,
        private categorieProdottiService: CategorieProdottiService,
        private pdfService: PDFService,
        private translationService: TranslationService,
        private bulkActionsService: BulkActionsService,
        private localizedDateTimePipe: LocalizedDateTimePipe,
        private anagraficheService: AnagraficheService
    ) {

        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });

        this.utility = util;

        this.PriceZero = '0';
        this.CostZero = '0';
        this.FBType = '7';

        this.RecipeType = [
            '',
            'RECIPE',
            'SUBRECIPE',
            'PRODUCT',
            'MENU'
        ];
        this.columnsList = [
            { width: '15', label: '', enabled: true, hideOnExport: true },
            { width: '80', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.CODICE'), enabled: true },
            { width: '100', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.TIPO'), enabled: true },
            { label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.PRODOTTO'), enabled: true },
            { width: '150', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.CATEGORIA'), enabled: true },
            { width: '60', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.FC_MEDIO'), enabled: true },
            { width: '60', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.FC') + '%', enabled: true },
            { width: '60', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.PV_MEDIO'), enabled: true },
            { width: '60', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.IVA'), enabled: false },
            { width: '20', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.RESA'), enabled: true },
            { width: '60', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.UNITA_BASE'), enabled: true },
            { width: '50', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.DATA_CREAZIONE'), enabled: false },
            { width: '50', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.DATA_MODIFICA'), enabled: true },
            { width: '100', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.REPARTO'), enabled: false },
            { width: '15', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.IS_IMPORTED'), enabled: true, hideOnExport: true },
            { width: '15', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.IS_USED'), enabled: true, hideOnExport: true },
            { width: '15', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.IS_ALERT'), enabled: true, hideOnExport: true },
            { width: '50', label: this.translate.instant('GESTIONE_RICETTE.COLUMNS.ACTIONS'), enabled: true, hideOnExport: true },
        ];
        this.translate.onLangChange.subscribe((event: any) => {
            this.columnsList[1].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.CODICE');
            this.columnsList[2].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.TIPO');
            this.columnsList[3].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.PRODOTTO');
            this.columnsList[4].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.CATEGORIA');
            this.columnsList[5].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.FC_MEDIO');
            this.columnsList[6].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.FC') + '%';
            this.columnsList[7].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.PV_MEDIO');
            this.columnsList[8].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.IVA');
            this.columnsList[9].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.RESA');
            this.columnsList[10].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.UNITA_BASE');
            this.columnsList[11].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.DATA_CREAZIONE');
            this.columnsList[12].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.DATA_MODIFICA');
            this.columnsList[13].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.REPARTO');
            this.columnsList[14].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.IS_IMPORTED');
            this.columnsList[15].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.IS_USED');
            this.columnsList[16].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.IS_ALERT');
            this.columnsList[17].label = this.translate.instant('GESTIONE_RICETTE.COLUMNS.ACTIONS');
        });

    }

    getRecipeType(recipeTypeIndex: number) {
        return this.translate.instant(`GESTIONE_RICETTE.RECIPE_TYPE.${this.RecipeType[recipeTypeIndex]}`);
    }

    async ngOnInit() {

        this.pagination = {
            pageSize: 10,
            TotalRows: 0,
            pageIndex: 0
        };

        document.body.classList.add('kt-aside--minimize');

        this.currentFilterCfg = this.filtriService.filterConfig;

        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            (filterConfig: any) => {

                if (filterConfig.constructor === Object && Object.keys(filterConfig).length > 0) {
                    this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

                    if (this.currentFilterCfg.PriceZero) {
                        this.PriceZero = this.currentFilterCfg.PriceZero;
                    }

                    if (this.currentFilterCfg.CostZero) {
                        this.CostZero = this.currentFilterCfg.CostZero;
                    }

                    if (this.currentFilterCfg.FBType) {
                        this.FBType = this.currentFilterCfg.FBType;
                    }

                    //if (this.currentFilterCfg.GoodsOrSubrecipeName) {
                    //    //const item = this.goodsOrSubrecipeNamesList.find((item: any) => item.Name === this.currentFilterCfg.GoodsOrSubrecipeName);
                    //    this.goodsOrSubrecipeNames.setValue(this.currentFilterCfg.GoodsOrSubrecipeName.trim());
                    //}

                    if (this.currentFilterCfg.ProductOrCode) {
                        this.ProductOrCode = this.currentFilterCfg.ProductOrCode;
                    }

                    if (this.table) {
                        this.table.draw();
                    } else {
                        this.initDataTable();
                    }
                }

            }, (error: any) => {
                console.log(error);
            }
        );

        //https://stackoverflow.com/questions/47088363/how-to-implement-angular-material-2-autocomplete-with-ajax
        this.filteredOptions = this.goodsOrSubrecipeNames.valueChanges
            .pipe(
                //startWith(''),
                debounceTime(500),
                //distinctUntilChanged(),
                switchMap((value: string) => this._filter(value))
            );
    }

    showFromYearStart() {
        this.showInitialImage = false;
        this.currentFilterCfg = { ShowDisabled: false, ShowInvisible: false };
        this.filtriService.performFilter$.next(this.currentFilterCfg);
        this.ref.detectChanges();
    }

    manageProductOrCodeKeyDown(event: any) {
        //if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        this.filter(this.ProductOrCode, 'ProductOrCode');
        //}
    }

    displayFn(product: any): string {
        if (!product) return '';
        const productCode = product.Type === 'Goods' ? '(' + product.Goods.Code.trim() + ') ' : '';
        return productCode + product.Goods.Name.trim();
    }

    private _filter(value: any) {
        let filterValue = '';
        if (value.constructor === String) {
            filterValue = value.toLowerCase();
        } else if (value.constructor === Object) {
            filterValue = value.Name ? value.Name.toLowerCase() : '';
        }
        return this.gestioneRicetteService.getAvailableIngredients({
            Name: filterValue
        });
    }

    selectElement(event: any) {
        this.showInitialImage = false;
        if(!this.currentFilterCfg) {
            this.currentFilterCfg = { ShowDisabled: false, ShowInvisible: false };
        }
        this.currentFilterCfg['GoodsOrSubrecipeName'] = event.option.value.Goods.Name.trim();
        this.goodsOrSubrecipeNames.setValue(event.option.value);
        this.filtriService.performFilter$.next(this.currentFilterCfg);
    }

    /**
       * After view init
       */
    ngAfterViewInit(): void {

        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.clearFilters();
                }
            }
        );
    }

    ingredientiSottoricette: any;

    openColumnsDialog() {
        const dialogRef = this.dialog.open(ColumnsSelectionPopupComponent, {
            data: {
                columnsList: this.columnsList
            },
            width: '300px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            if (res.success && res.columnsList) {
                this.columnsList = res.columnsList;

                this.refreshColumnsVisibility();
            }
        });
    }

    refreshColumnsVisibility() {
        for (let index = 0; index < this.columnsList.length; index++) {
            var column = this.table.column(index);
            if (column) column.visible(this.columnsList[index].enabled);
        }
    }

    clearFilters() {
        this.PriceZero = '0';
        this.CostZero = '0';
        this.FBType = '7';
        if (this.ProductOrCode) this.ProductOrCode = '';
        this.goodsOrSubrecipeNames.setValue('');

        this.currentFilterCfg = {
            EnableState: 2,
            VisibleState: 1
        };

        ($('#gestioneMerciTable') as any).DataTable().clear().destroy();
        this.table = undefined;
        this.currentFilterCfg = [];
        this.showInitialImage = true;
        this.ref.detectChanges();
    }

    ngOnDestroy() {
        this.switchGroupSubcription.unsubscribe();
        this.filtriServiceSubscription.unsubscribe();

        // cancello il filtro
        this.filtriService.filterConfig = {};
    }

    getTitle() {
        return this.translate.instant('GESTIONE_RICETTE.GESTIONE_RICETTE');
    }

    getPv() {
        if (!this.currentFilterCfg.Companies || this.currentFilterCfg.Companies.length > 1) {
            return this.translate.instant('GESTIONE_RICETTE.COLUMNS.PV_MEDIO');
        } else if (this.currentFilterCfg.Companies && this.currentFilterCfg.Companies.length == 1) {
            return this.translate.instant('GESTIONE_RICETTE.COLUMNS.PV');
        } else
            return '';
    }

    getFc() {
        if (!this.currentFilterCfg.Companies || this.currentFilterCfg.Companies.length > 1) {
            return this.translate.instant('GESTIONE_RICETTE.COLUMNS.FC_MEDIO');
        } else if (this.currentFilterCfg.Companies && this.currentFilterCfg.Companies.length == 1) {
            return this.translate.instant('GESTIONE_RICETTE.COLUMNS.FC');
        } else
            return '';
    }

    async initDataTable(forceRefreshGoods: boolean = false) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        if (!this.currentFilterCfg || Object.keys(this.currentFilterCfg).length == 0) {
            this.currentFilterCfg = {
                EnableState: 2,
                VisibleState: 1,
                FBType: 7
            };
        }

        if (!this.currentFilterCfg.FBType) {
            this.currentFilterCfg.FBType = 7;
        }

        if (!this.currentFilterCfg.EnableState) {
            this.currentFilterCfg.EnableState = 2;
        }

        if (!this.currentFilterCfg.VisibleState) {
            this.currentFilterCfg.VisibleState = 1;
        }

        setTimeout(() => {
            let dtOptions: any = {
                destroy: true,
                drawCallback: () => {
                    setTimeout(() => {
                        const width = $(`#gestioneRicetteTable tbody tr td:nth-child(3)`).width() || 0;
                        $('#gestioneRicetteTable tbody tr td:nth-child(3) .overflow-ellipses').css('max-width', (width - 15) + 'px');
                    }, 5000);
                },
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: true,
                searching: true,
                ordering: true,
                scrollY: 'calc(100vh - 350px)',
                scrollCollapse: true,
                dom: 'rt<"paginator">',
                processing: true,
                serverSide: true,
                ajax: {
                    url: currentLogin.endpointURL + "/api/Recipe/DataList",
                    type: "POST",
                    data: (d: any) => {
                        d.filters = this.currentFilterCfg;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        this.pagination.TotalRows = response.recordsFiltered;
                        this.ref.detectChanges();
                        return response.data;
                    }
                },
                columnDefs: [
                    {
                        targets: [0], orderable: false, className: 'excludeFromExport max-width-20', render: (data) => {
                            return `<i class="far fa-square select pointer"></i>`;
                        }, visible: this.columnsList[0].enabled
                    },
                    {
                        targets: [1], data: 'Code', name: 'Code', className: 'max-width-80', render: (data) => {
                            return `<div class="overflow-ellipses colCode" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }, visible: this.columnsList[1].enabled
                    },
                    {
                        targets: [2], className: 'max-width-80', data: 'Type', name: 'Type', render: (data) => {
                            const recipeType = this.getRecipeType(data);
                            return `<div class="overflow-ellipses colType" data-toggle="tooltip" title="${recipeType}">${recipeType}</div>`;
                        }, visible: this.columnsList[2].enabled
                    },
                    {
                        targets: [3], data: 'Name', name: 'Name', render: (data, type, p) => {
                            const name = data.length > 50 ? data.substr(0, 50) + '...' : data;

                            const isBarred = p.Excluded ? 'barred' : '';
                            const color: string = p.Code && p.Type == 1 ? 'text-success' : 'text-primary';

                            const linkToGood = `<div class="overflow-ellipses">
                                                    <a href="javascript:void(0);" data-toggle="tooltip" title="${data}" class="editItem ${isBarred} ${color}">${name}</a>
                                                </div>`;
                            return linkToGood;
                        }, visible: this.columnsList[3].enabled
                    },
                    {
                        targets: [4], className: 'max-width-100', data: 'Category', name: 'Category', render: (data) => {
                            return `<div class="overflow-ellipses colCategory" data-toggle="tooltip" title="${data?.Name}">${data?.Name}</div>`;
                        }, visible: this.columnsList[4].enabled
                    },
                    {
                        targets: [5], className: 'max-width-50 text-right', data: 'FoodCost', name: 'FoodCost', render: (data) => {
                            return this.utility.formatNumber(data, this.locale)
                        }, visible: this.columnsList[5].enabled
                    },
                    {
                        targets: [6], className: 'max-width-50 text-right', data: 'FoodCostPerc', name: 'FoodCostPerc', render: (data) => {
                            return this.utility.formatNumber(data, this.locale)
                        }, visible: this.columnsList[6].enabled
                    },
                    {
                        targets: [7], className: 'max-width-50 text-right', data: 'SellPrice', name: 'SellPrice', render: (data) => {
                            return this.utility.formatNumber(data, this.locale)
                        }, visible: this.columnsList[7].enabled
                    },
                    {
                        targets: [8], className: 'max-width-50 text-right', data: 'Vat', name: 'Vat', render: (data) => {
                            return this.utility.formatNumber(data, this.locale)
                        }, visible: this.columnsList[8].enabled
                    },
                    {
                        targets: [9], className: 'max-width-50 text-right', data: 'Yield', name: 'Yield', render: (data) => {
                            return this.utility.formatNumber(data, this.locale)
                        }, visible: this.columnsList[9].enabled
                    },
                    {
                        targets: [10], className: 'max-width-50', data: 'Unit', name: 'Unit', render: (data) => {
                            return `<div class="overflow-ellipses colCategory" data-toggle="tooltip" title="${data?.Name}">${data?.Name}</div>`;
                        }, visible: this.columnsList[10].enabled
                    },
                    {
                        targets: [11], className: 'max-width-50', data: 'CreationDate', name: 'CreationDate', render: (data) => {
                            return this.localizedDateTimePipe.transform(data, undefined, true);
                        }, visible: this.columnsList[11].enabled
                    },
                    {
                        targets: [12], className: 'max-width-50', data: 'ChangeDate', name: 'ChangeDate', render: (data) => {
                            return this.localizedDateTimePipe.transform(data, undefined, true);
                        }, visible: this.columnsList[12].enabled
                    },
                    {
                        targets: [13], className: 'max-width-80', data: 'Department', name: 'Department', render: (data) => {
                            return `<div class="overflow-ellipses colCategory" data-toggle="tooltip" title="${data?.Name}">${data?.Name}</div>`;
                        }, visible: this.columnsList[13].enabled
                    },
                    {
                        targets: [14], className: 'max-width-15 text-center', orderable: false, data: 'IsImported', name: 'IsImported', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }
                    },
                    {
                        targets: [15], className: 'max-width-15 text-center', orderable: false, data: 'IsUsed', name: 'IsUsed', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }
                    },
                    {
                        targets: [16], className: 'max-width-15 text-center', orderable: false, data: 'IsAlert', name: 'IsAlert', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }
                    },
                    {
                        targets: [17], width: this.columnsList[17].width + 'px', orderable: false, className: 'excludeFromExport text-center', render: (data) => {
                            const deleteItem = this.roleService.isCompanyAdminOrMore() ? `&nbsp;<i class="fas fa-trash text-danger pointer deleteItem" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_RICETTE.ACTIONS.DELETE')}"></i>` : '';
                            const duplicateItem = this.roleService.isCompanyAdminOrMore() ? `&nbsp;<i class="fas fa-copy text-warning pointer duplicateItem" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_RICETTE.ACTIONS.DUPLICATE')}"></i>` : '';

                            return `<div class="display-flex-center flex-align-center">
                                <i class="fas fa-pencil-alt text-primary pointer editItem" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_RICETTE.ACTIONS.EDIT')}"></i>
                                ${duplicateItem}
                                ${deleteItem}                                
                                </div>
                            `;
                        }
                    }
                ],
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                search: 'none',
                                page: 'all',
                            },
                            columns: ':visible:not(.excludeFromExport)',
                            format: {
                                header: function (data, row, column, node) {
                                    if (data.indexOf('fa-file-import') >= 0) {
                                        return String.fromCharCode(parseInt('0x' + 'f56f'));
                                    } else if (data.indexOf('fa-utensils') >= 0) {
                                        return String.fromCharCode(parseInt('0x' + 'f2e7'));
                                    } else if (data.indexOf('fa-exclamation-triangle') >= 0) {
                                        return String.fromCharCode(parseInt('0x' + 'f071'));
                                    } else {
                                        return data
                                    }
                                },
                                body: function (data, row, column, node) {

                                    if (data.indexOf('colCode') >= 0) {
                                        // se la colonna è quella del codice della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colCode').attr('title');
                                        return title;
                                    } else if (data.indexOf('colType') >= 0) {
                                        // se la colonna è quella del nome della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colType').attr('title');
                                        return title;
                                    } else if (data.indexOf('editItem') >= 0) {
                                        // se la colonna è quella del nome della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.editItem').attr('title');
                                        return title;
                                    } else if (data.indexOf('colAlternateName') >= 0) {
                                        // se la colonna è quella del nome alternativo della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colAlternateName').attr('title');
                                        return title;
                                    } else if (data.indexOf('colCategory') >= 0) {
                                        // se la colonna è quella della categoria della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colCategory').attr('title');
                                        return title;

                                    } else if (data.indexOf("far fa-square") >= 0) {
                                        return String.fromCharCode(parseInt('0x' + 'f0c8'));
                                    } else if (data.indexOf("fas fa-check-square") >= 0) {
                                        return String.fromCharCode(parseInt('0x' + 'f14a'));
                                    } else {
                                        return data;
                                    }
                                }
                            }
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            var allVisibleArray = this.table.columns().visible().toArray()
                                .map((value, index) => {
                                    return {
                                        index: index,
                                        value: value
                                    }
                                }).filter(item => item.value);

                            //const arrayCols = this.table.column(6).visible() ? ['auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : ['auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto']
                            delete allVisibleArray[0];
                            delete allVisibleArray[allVisibleArray.length - 1];
                            let arrayCols = Array(allVisibleArray.length).fill('auto')

                            arrayCols[2] = '*';

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, arrayCols, '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;
                            doc.styles = docDefinition.styles;

                            doc.content[1].table.body.forEach((row, index) => {
                                row.forEach((field, col) => {
                                    if (col >= row.length - 4) {
                                        if (index == 0) {
                                            field.style = 'fontAwesomeHeader';
                                        } else if (field.style.indexOf('Odd') >= 0) {
                                            field.style = 'fontAwesomeOdd';
                                        } else {
                                            field.style = 'fontAwesome';
                                        }
                                    }
                                })
                            });
                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsDisplay
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.pdfHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        //autoFilter: true,
                        //sheetName: 'Exported data',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.excludeFromExport)',
                            format: {
                                header: (data, row, column, node) => {
                                    if (data.indexOf('fa-file-import') >= 0) {
                                        return this.translate.instant('GESTIONE_RICETTE.COLUMNS.IS_IMPORTED');
                                    } else if (data.indexOf('fa-utensils') >= 0) {
                                        return this.translate.instant('GESTIONE_RICETTE.COLUMNS.IS_USED');
                                    } else if (data.indexOf('fa-exclamation-triangle') >= 0) {
                                        return this.translate.instant('GESTIONE_RICETTE.COLUMNS.IS_ALERT');
                                    } else {
                                        return data
                                    }
                                },
                                body: (data, row, column, node) => {
                                    if (data && data.indexOf('colCode') >= 0) {
                                        // se la colonna è quella del codice della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colCode').attr('title');
                                        return title;
                                    } else if (data && data.indexOf('colType') >= 0) {
                                        // se la colonna è quella del nome della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colType').attr('title');
                                        return title;
                                    } else if (data && data.indexOf('editItem') >= 0) {
                                        // se la colonna è quella del nome della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.editItem').attr('title');
                                        return title;
                                    } else if (data && data.indexOf('colAlternateName') >= 0) {
                                        // se la colonna è quella del nome alternativo della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colAlternateName').attr('title');
                                        return title;
                                    } else if (data && data.indexOf('colCategory') >= 0) {
                                        // se la colonna è quella della categoria della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colCategory').attr('title');
                                        return title;

                                    } else if (data && data.indexOf("far fa-square") >= 0) {
                                        return '\0' + '☐';
                                    } else if (data && data.indexOf("fas fa-check-square") >= 0) {
                                        return '\0' + '☑';
                                    } else {
                                        //return data;
                                        // Aggiunto \0 per forzare il riconoscimento come stringa per mantenere la formattazione
                                        return '\0' + data
                                    }
                                }
                            },
                            orthogonal: 'export'
                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsDisplay
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.excelHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        },
                        customize: (xlsx: any) => {

                            // Get sheet.
                            var sheet = xlsx.xl.worksheets['sheet1.xml'];

                            const numToAlpha = (num: number) => {
                                var alpha = '';
                                for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
                                    alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
                                }
                                return alpha;
                            }

                            const colList = this.columnsList.filter((item: any) => item.enabled && !item.hideOnExport);

                            const fcMedioIndex = colList.findIndex((item: any) => {
                                return item.enabled && item.label == this.translate.instant('GESTIONE_RICETTE.COLUMNS.FC_MEDIO')
                            });

                            const pvMedioIndex = colList.findIndex((item: any) => {
                                return item.enabled && item.label == this.translate.instant('GESTIONE_RICETTE.COLUMNS.PV_MEDIO')
                            });

                            const resaMedioIndex = colList.findIndex((item: any) => {
                                return item.enabled && item.label == this.translate.instant('GESTIONE_RICETTE.COLUMNS.RESA')
                            });

                            if (fcMedioIndex >= 0 || pvMedioIndex >= 0 || resaMedioIndex >= 0) {
                                $('sheetData', sheet).children().each((index, element) => {
                                    if (fcMedioIndex >= 0)
                                        $(`row c[r="${numToAlpha(fcMedioIndex)}${index}"]`, sheet).attr('s', '52');

                                    if (pvMedioIndex >= 0)
                                        $(`row c[r="${numToAlpha(pvMedioIndex)}${index}"]`, sheet).attr('s', '52');

                                    if (resaMedioIndex >= 0)
                                        $(`row c[r="${numToAlpha(resaMedioIndex)}${index}"]`, sheet).attr('s', '52');
                                });
                            }


                        }
                    }
                ],
                aaSorting: [[12, 'desc']],
                autoWidth: true
            };
            this.table = $('#gestioneRicetteTable').DataTable(dtOptions);
            this.table.on('page.dt', function () {
                $('.dataTables_scrollBody').animate({
                    scrollTop: 0
                }, 'slow');
            });
            //this.table.columns.adjust().draw();

            $('#gestioneRicetteTable tbody').on('click', 'td:first-child i', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                if (data) {
                    const isAlreadyChecked = $(event.target).hasClass('fas fa-check-square');
                    if (isAlreadyChecked) {
                        $(event.target).removeClass('fas fa-check-square');
                        $(event.target).addClass('far fa-square');
                    } else {
                        $(event.target).addClass('fas fa-check-square');
                        $(event.target).removeClass('far fa-square');
                    }
                    this.selectItem(!isAlreadyChecked, data);
                }
            });

            $('#gestioneRicetteTable tbody').on('click', '.editItem', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                if (data) {
                    this.editItem(data);
                }
            });

            $('#gestioneRicetteTable tbody').on('click', 'i.duplicateItem', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                if (data) {
                    this.duplicateItem(data);
                }
            });

            $('#gestioneRicetteTable tbody').on('click', 'i.deleteItem', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                if (data) {
                    this.deleteItem(data.Id);
                }
            });

            this.bulkSelect = "";
            this.ref.detectChanges();
        }, 100);
    }

    almoustOneSelected() {
        const almoustOne = $('#gestioneRicetteTable tbody td:first-child i.fas.fa-check-square');
        return almoustOne.length > 0;
    }

    onlyOneSelected() {
        const almoustOne = $('#gestioneRicetteTable tbody td:first-child i.fas.fa-check-square');
        return almoustOne.length == 1;
    }

    openManutenzioneRicette() {
        const dialogRef = this.dialog.open(ManutenzioneRicetteComponent, {
            width: '100%',
            height: '100%'
        });

        dialogRef.afterClosed().subscribe(() => {
            if (this.table) {
                //this.table.draw(); 
                this.table.page.len(this.pagination.pageSize);
                this.table.page(this.pagination.pageIndex).draw('page');
            }
        });
    }

    selectItem(event: any, item: any) {
        item['selected'] = event.checked;
    }

    selectAll(event: any) {
        $('#gestioneRicetteTable tbody td:first-child i').each((index: number, el: any) => {
            if (!event.checked) {
                $(el).removeClass('fas fa-check-square');
                $(el).addClass('far fa-square');
            } else {
                $(el).addClass('fas fa-check-square');
                $(el).removeClass('far fa-square');
            }
        })
    }

    changePagination(event: PageEvent) {
        if (this.pagination.pageIndex != event.pageIndex) {
            this.pagination.pageIndex = event.pageIndex;
            this.table.page(this.pagination.pageIndex).draw('page');
        } else if (this.pagination.pageSize != event.pageSize) {
            this.pagination.pageSize = event.pageSize;
            this.table.page.len(this.pagination.pageSize).draw();
        }
    }

    filter(event: any, type: string) {
        if(!this.currentFilterCfg){
            this.currentFilterCfg = { ShowDisabled: false, ShowInvisible: false };
        }
        if (type == 'IsAlert') {
            type = 'StateAlert';
            this.currentFilterCfg[type] = event ? '2' : '3';
        } else if (type == 'FinishedState') {
            this.currentFilterCfg[type] = event ? '2' : '3';
        } else {
            this.currentFilterCfg[type] = event;
        }

        this.showInitialImage = false;

        this.filtriService.performFilter$.next(this.currentFilterCfg);
    }

    newItem() {
        const dialogRef = this.dialog.open(EditRicettaComponent, {
            width: '100%',
            height: '100%'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            setTimeout(async () => {
                if (res && this.table) {
                    //await this.refreshGoods();
                    //this.table.draw();
                    this.table.page.len(this.pagination.pageSize);
                    this.table.page(this.pagination.pageIndex).draw('page');
                }
            }, 100);
        });

    }

    async performBulkSelection(event: any) {
        let selectedItems: any = [];
        $('#gestioneRicetteTable tbody td:first-child i.fas.fa-check-square').each((index: number, el: any) => {

            const tr = $(el).closest('tr');
            const data: any = this.table.row(tr).data();
            selectedItems.push(data);

        });

        switch (event.value) {
            case 'setCategory': {
                const categorieProdotti = await this.categorieProdottiService.getCategorieProdotti().toPromise();
                const dialogRef = this.dialog.open(SetCategoryDlgComponent, {
                    data: {
                        categorieProdotti: categorieProdotti
                    },
                    width: '300px',
                });
                dialogRef.afterClosed().subscribe(async (res: any) => {
                    await this.bulkActionsService.massiveActions({
                        "Action": "Recipe_SetCategory",
                        "EntityIds": selectedItems.map((item: any) => item.Id),
                        "Value": res
                    }).toPromise();
                    //this.table.draw();
                    this.table.page.len(this.pagination.pageSize);
                    this.table.page(this.pagination.pageIndex).draw('page');
                });
            }
                break;
            case 'setDepartment': {
                const departments = await this.anagraficheService.getEntity('Department').toPromise();
                const dialogRef = this.dialog.open(SetDepartmentDlgComponent, {
                    data: {
                        departments: departments
                    },
                    width: '300px',
                });
                dialogRef.afterClosed().subscribe(async (res: any) => {
                    await this.bulkActionsService.massiveActions({
                        "Action": "Recipe_SetDepartment",
                        "EntityIds": selectedItems.map((item: any) => item.Id),
                        "Value": res
                    }).toPromise();
                    //this.table.draw();
                    this.table.page.len(this.pagination.pageSize);
                    this.table.page(this.pagination.pageIndex).draw('page');
                });
            }
                break;
            case 'setType': {
                const dialogRef = this.dialog.open(SetTypeDlgComponent, {
                    width: '300px',
                });
                dialogRef.afterClosed().subscribe(async (res: any) => {
                    await this.bulkActionsService.massiveActions({
                        "Action": "Recipe_SetType",
                        "EntityIds": selectedItems.map((item: any) => item.Id),
                        "Value": res
                    }).toPromise();
                    //this.table.draw();
                    this.table.page.len(this.pagination.pageSize);
                    this.table.page(this.pagination.pageIndex).draw('page');
                });
            }
                break;
            case 'setDisabled':
                await this.bulkActionsService.massiveActions({
                    "Action": "Recipe_SetDisabled",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                //this.table.draw();
                this.table.page.len(this.pagination.pageSize);
                this.table.page(this.pagination.pageIndex).draw('page');
                break;
            case 'setTopSeller':
                await this.bulkActionsService.massiveActions({
                    "Action": "Recipe_SetTopSeller",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                //this.table.draw();
                this.table.page.len(this.pagination.pageSize);
                this.table.page(this.pagination.pageIndex).draw('page');
                break;
            case 'setNoTopSeller':
                await this.bulkActionsService.massiveActions({
                    "Action": "Recipe_SetTopSeller",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                //this.table.draw();
                this.table.page.len(this.pagination.pageSize);
                this.table.page(this.pagination.pageIndex).draw('page');
                break;
            case 'setAvgPrice':
                await this.bulkActionsService.massiveActions({
                    "Action": "Recipe_SetAvgPrice",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                //this.table.draw();
                this.table.page.len(this.pagination.pageSize);
                this.table.page(this.pagination.pageIndex).draw('page');
                break;
            case 'setNoAvgPrice':
                await this.bulkActionsService.massiveActions({
                    "Action": "Recipe_SetAvgPrice",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                //this.table.draw();
                this.table.page.len(this.pagination.pageSize);
                this.table.page(this.pagination.pageIndex).draw('page');
                break;
            case 'setEnabled':
                await this.bulkActionsService.massiveActions({
                    "Action": "Recipe_SetEnabled",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                //this.table.draw();
                this.table.page.len(this.pagination.pageSize);
                this.table.page(this.pagination.pageIndex).draw('page');
                break;
            case 'setInvisible':
                await this.bulkActionsService.massiveActions({
                    "Action": "Recipe_SetInvisible",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                //this.table.draw();
                this.table.page.len(this.pagination.pageSize);
                this.table.page(this.pagination.pageIndex).draw('page');
                break;
            case 'setVisible':
                await this.bulkActionsService.massiveActions({
                    "Action": "Recipe_SetInvisible",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                //this.table.draw();
                this.table.page.len(this.pagination.pageSize);
                this.table.page(this.pagination.pageIndex).draw('page');
                break;
            case 'delete':
                await this.bulkActionsService.massiveActions({
                    "Action": "Recipe_Delete",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                //this.table.draw();
                this.table.page.len(this.pagination.pageSize);
                this.table.page(this.pagination.pageIndex).draw('page');
                break;
        }
    }

    bulkSelection(event: any) {
        //if (!this.listaFiltered || this.listaFiltered.Rows.length == 0 || !event || !event.value) return;

        let _title: string = '';
        switch (event.value) {
            case 'setCategory':
                _title = this.translate.instant('GESTIONE_RICETTE.BULK_ACTIONS.SET_CATEGORY');
                break;
            case 'setType':
                _title = this.translate.instant('GESTIONE_RICETTE.BULK_ACTIONS.SET_DISABLED');
                break;
            case 'setDisabled':
                _title = this.translate.instant('GESTIONE_RICETTE.BULK_ACTIONS.SET_ENABLED');
                break;
            case 'setEnabled':
                _title = this.translate.instant('GESTIONE_RICETTE.BULK_ACTIONS.SET_INVISIBLE');
                break;
            case 'setInvisible':
                _title = this.translate.instant('GESTIONE_RICETTE.BULK_ACTIONS.SET_VISIBLE');
                break;
            case 'setVisible':
                _title = this.translate.instant('GESTIONE_RICETTE.BULK_ACTIONS.SET_TYPE');
                break;
            case 'delete':
                _title = this.translate.instant('GESTIONE_RICETTE.BULK_ACTIONS.DELETE');
                break;
        }

        const _description: string = this.translate.instant('GESTIONE_RICETTE.BULK_ACTIONS.CONFIRM_ASK');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('COMMONS.CONFIRM');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(async (ok: any) => {
            if (ok) {
                this.performBulkSelection(event);
            }
            this.bulkSelect = "";
            this.ref.detectChanges();
        });

    }

    ckeckResetFilter(event: KeyboardEvent) {
        if ((event.code === 'Enter' || event.code == 'NumpadEnter') && !this.goodsOrSubrecipeNames.value) {
            this.currentFilterCfg['GoodsOrSubrecipeName'] = undefined;
            //this.table.draw();
            this.table.page.len(this.pagination.pageSize);
            this.table.page(this.pagination.pageIndex).draw('page');
            this.autoComplete.closePanel();
        }
    }

    editItem(ricetta: any) {
        this.gestioneRicetteService.getSingleRecipe(ricetta.Id).toPromise().then(
            (result: any) => {
                const dialogRef = this.dialog.open(EditRicettaComponent, {
                    data: {
                        ricetta: result
                    },
                    width: '100%',
                    height: '100%'
                });
                dialogRef.afterClosed().subscribe((res: any) => {
                    setTimeout(async () => {
                        if (res) {
                            //await this.refreshGoods();
                            //this.table.draw();
                            this.table.page.len(this.pagination.pageSize);
                            this.table.page(this.pagination.pageIndex).draw('page');
                        }
                    }, 100);
                });
            }
        );
    }

    duplicateItem(item: any) {
        const _title: string = this.translate.instant('GESTIONE_RICETTE.DUPLICATE_DIALOG.TITLE');
        const _description: string = this.translate.instant('GESTIONE_RICETTE.DUPLICATE_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('GESTIONE_RICETTE.DUPLICATE_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            if (confirm) {
                this.gestioneRicetteService.duplicateRecipe(item.Id).toPromise().then(
                    async (result: any) => {
                        let message: string = '';
                        if (result.Msg === 'Ok') {
                            message = this.translate.instant('GESTIONE_RICETTE.DUPLICATE_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                            //await this.refreshGoods();
                            //this.table.draw();
                            this.table.page.len(this.pagination.pageSize);
                            this.table.page(this.pagination.pageIndex).draw('page');
                        } else {
                            message = this.translate.instant('GESTIONE_RICETTE.DUPLICATE_DIALOG.ERROR');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                        }
                    }
                )
            }

        });
    }

    deleteItem(itemId: any) {
        const _title: string = this.translate.instant('GESTIONE_RICETTE.DELETE_DIALOG.TITLE');
        const _description: string = this.translate.instant('GESTIONE_RICETTE.DELETE_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('GESTIONE_RICETTE.DELETE_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            if (confirm) {
                this.gestioneRicetteService.deleteMultiRecipe([itemId]).toPromise().then(
                    async (result: any) => {
                        let message: string = '';
                        if (result.Msg === 'Ok') {
                            message = this.translate.instant('GESTIONE_RICETTE.DELETE_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                            //await this.refreshGoods();
                            //this.table.draw();
                            this.table.page.len(this.pagination.pageSize);
                            this.table.page(this.pagination.pageIndex).draw('page');
                        } else {
                            message = this.translate.instant('GESTIONE_RICETTE.DELETE_DIALOG.ERROR');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                        }
                    }
                )
            }

        });
    }

    deleteMultiItems() {
        const _title: string = this.translate.instant('GESTIONE_RICETTE.DELETE_DIALOG.TITLE');
        const _description: string = this.translate.instant('GESTIONE_RICETTE.DELETE_DIALOG.DESC_MULTI');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('GESTIONE_RICETTE.DELETE_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            if (confirm) {
                let selectedRows = [];//this.listaFiltered.Rows.filter((row: any) => row.selected);
                let ids;
                selectedRows.forEach((element: any) => ids.push(element.Id));

                this.gestioneRicetteService.deleteMultiRecipe({ 'ids': ids }).toPromise()
                    .then(
                        async (result: any) => {
                            let message: string = '';
                            if (result.Msg === 'Ok') {
                                message = this.translate.instant('GESTIONE_RICETTE.DELETE_DIALOG.SUCCESS');
                                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                                //await this.refreshGoods();
                                //this.table.draw();
                                this.table.page.len(this.pagination.pageSize);
                                this.table.page(this.pagination.pageIndex).draw('page');

                            } else {
                                message = this.translate.instant('GESTIONE_RICETTE.DELETE_DIALOG.ERROR');
                                this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                            }
                        }
                    )
            }


        });
    }

    recalculateFC() {
        const _title: string = this.translate.instant('GESTIONE_RICETTE.CALCFC_DIALOG.TITLE');
        const _description: string = this.translate.instant('GESTIONE_RICETTE.CALCFC_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('GESTIONE_RICETTE.CALCFC_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            if (confirm) {
                this.gestioneRicetteService.recalculateFC().toPromise()
                    .then(
                        async (result: any) => {
                            let message = this.translate.instant('GESTIONE_RICETTE.CALCFC_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                            //await this.refreshGoods();
                            //this.table.draw();
                            this.table.page.len(this.pagination.pageSize);
                            this.table.page(this.pagination.pageIndex).draw('page');
                        })
                    .catch(
                        (err: any) => {
                            let message = this.translate.instant('GESTIONE_RICETTE.CALCFC_DIALOG.ERROR');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                        }
                    )
            }
        });
    }

    createVersion() {

        let selectedRows: any = [];
        $('#gestioneRicetteTable tbody td:first-child i.fas.fa-check-square').each((index: number, el: any) => {

            const tr = $(el).closest('tr');
            const data: any = this.table.row(tr).data();
            selectedRows.push(data);

        });

        let row: any = selectedRows[0];

        const _title: string = this.translate.instant('GESTIONE_RICETTE.VERSION_DIALOG.TITLE');
        const _description: string = this.translate.instant('GESTIONE_RICETTE.VERSION_DIALOG.DESC') + '(' + row.Code + ') ' + row.Name;
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('GESTIONE_RICETTE.VERSION_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            if (confirm) {
                this.gestioneRicetteService.creaVersionRecipe(row.Id).toPromise()
                    .then(
                        async (result: any) => {
                            let message: string = '';
                            if (result.Msg === 'Ok') {
                                message = this.translate.instant('GESTIONE_RICETTE.VERSION_DIALOG.SUCCESS');
                                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                                //await this.refreshGoods();
                                //this.table.draw();
                                this.table.page.len(this.pagination.pageSize);
                                this.table.page(this.pagination.pageIndex).draw('page');
                            } else {
                                message = this.translate.instant('GESTIONE_RICETTE.VERSION_DIALOG.ERROR');
                                this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                            }
                        }
                    )
            }
        });
    }

    openSostituisciIngrediente() {
        const dialogRef = this.dialog.open(SostituisciIngredienteComponent, {
            data: {},
            width: '600px',
            panelClass: 'SostituisciIngrediente'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            if (res.success) {
                const message = this.translate.instant('GESTIONE_RICETTE.SOSTITUISCI_INGREDIENTE.MESSAGE_SUCCESS');
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
            }
        });
    }

    deleteMulti() { }

    importCodes() { }

    /******************************************************************************
    ______                       _    __   ___       _______   __
    |  ____|                     | |   \ \ / / |     / ____\ \ / /
    | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
    |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
    | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
    |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
         | |
         |_|
    ******************************************************************************/

    async exportAsXLSX() {
        this.table.button(1).trigger();
        setTimeout(() => {
            this.ref.detectChanges();
        }, 100);
    }

    /******************************************************************************
  ______                       _     _____  _____  ______
  |  ____|                     | |   |  __ \|  __ \|  ____|
  | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
  |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
  | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
  |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
             | |
             |_|
  ******************************************************************************/

    exportAsPDF(expand: boolean = false) {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
            explodeRows: this.translate.instant('EXPORT_PDF.EXPLODE_ROWS')
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            //if (result) this.preparePdfConfiguration(result);
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('GESTIONE_FATTURE.TITLE'),
                    period: ''
                };
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                };
                result['language'] = this.translationService.getSelectedLanguage();

                this.PDFPreferences = result;

                this.table.button(0).trigger();
            };
        });
    }
}
