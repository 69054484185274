import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { BehaviorSubject, timer, Observable, Subscription, fromEvent } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
// Layout
import { PDFService } from '@app/core/services/pdf.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

//Services
import { FiltriService } from '@app/core/services/filtri.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { BulkActionsService } from '@app/core/services/bulk-actions.service';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';

// Utilities
import * as util from '@app/core/services/utilityfunctions';
import * as _ from 'lodash';

//Dialog
import { MatDialog } from '@angular/material/dialog';
import { EditMerciComponent } from './edit-merci/edit-merci.component';
import { ColumnsSelectionPopupComponent } from '@app/views/partials/content/crud/columns-selection-popup/columns-selection-popup.component';
import { VisibilitaMerciComponent } from './visibilita-merci/visibilita-merci.component';
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { ConfigService } from '@app/core/services/config.service';
import { SetCategoryDlgComponent } from '@app/views/pages/gestione-dati/gestione-ricette/set-category-dlg/set-category-dlg.component';
import { SetVATDlgComponent } from '@app/views/pages/gestione-dati/gestione-merci/set-vatdlg/set-vatdlg.component';
import { SetSupplierComponent } from './set-supplier/set-supplier.component';
import { AddBrandsDlgComponent } from '../gestione-ricette/add-brands-dlg/add-brands-dlg.component';

// Pagination
import { RecipesFromGoodsComponent } from './recipes-from-goods/recipes-from-goods.component';

// Pipes
import { LocalizedDateTimePipe } from '@app/core/pipes/localized-date-time.pipe';

declare var $: any;
//import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/draggable.js';
import 'jquery-ui/ui/widgets/droppable.js';
import 'datatables.net/js/jquery.dataTables.min.js';
import { ManutenzioneMerciComponent } from './manutenzione-merci/manutenzione-merci.component';
import { PageEvent } from '@angular/material/paginator';
import { AnalisiPrezziFornitoriDialogComponent } from '@app/views/partials/content/crud/analisi-prezzi-fornitori-dialog/analisi-prezzi-fornitori-dialog.component';
import { BloccoUnitaComponent } from './blocco-unita/blocco-unita.component';

@Component({
    selector: 'kt-gestione-merci',
    templateUrl: './gestione-merci.component.html',
    styleUrls: ['./gestione-merci.component.scss']
})
export class GestioneMerciComponent implements OnInit, OnDestroy {

    companies: any;
    currentCompanyFilter!: string;
    locale!: string;
    categorieMerciList: any;
    globalFilter!: string;

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    importing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    importProgress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    importTimer!: Observable<number>;
    importSubscription!: Subscription;
    switchGroupSubcription!: Subscription;

    table: any;
    bulkSelect!: any;
    currentFilterCfg: any;

    pagination: any;
    optScarto!: number;
    allGoods: any;

    showSuppliers: boolean = false

    showInitialImage: boolean = true;

    showAnchor: boolean = false;

    PDFPreferences: any;

    selectedGood: any;

    utility: any;

    columnsList: any;
    FBType: string = '7';

    staticCollectionsReady: boolean = false;

    filtriSubscription!: Subscription;

    constructor(
        private filtriService: FiltriService,
        private gestioneMerciService: GestioneMerciService,
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private pdfService: PDFService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private switchGroupService: SwitchGroupService,
        public roleService: RoleService,
        private dialog: MatDialog,
        public staticCollectionsService: StaticCollectionsService,
        private confSrv: ConfigService,
        private bulkActionsService: BulkActionsService,
        private anagraficheService: AnagraficheService,
        private localizedDateTimePipe: LocalizedDateTimePipe,
    ) {
        this.utility = util;
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });

        this.columnsList = [
            { label: '', enabled: true, hideOnExport: true, width: 20 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.ID'), enabled: true, width: 100 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.NAME'), enabled: true, width: 300 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.BRANDS'), enabled: false, width: 60 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.SUPPLIERS'), enabled: false, width: 100 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.UNIT'), enabled: false, width: 70 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.ORDER_UNIT'), enabled: true, width: 70 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.AVG_PRICE'), enabled: true, width: 60 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.ALTERNATE_NAME'), enabled: false, width: 40 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.VAT'), enabled: false, width: 150 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.CATEGORY'), enabled: true, width: 60 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.WASTE'), enabled: true, width: 70 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.GROUP'), enabled: false, width: 60 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.POSITION'), enabled: false, width: 60 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.DATA_CREAZIONE'), enabled: false, width: 90 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.DATA_MODIFICA'), enabled: true, width: 90 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.IS_FRESH'), enabled: true, width: 30 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.IS_FINISHED'), enabled: true, width: 30 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.IS_CHECKED'), enabled: true, width: 30 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.IS_RECIPE_USABLE'), enabled: true, width: 30 },
            { label: this.translate.instant('GESTIONE_MERCI.COLUMNS.ALERT_PRICE'), enabled: true, width: 30 },
            { label: '', enabled: true, width: 50 },
        ];
        this.translate.onLangChange.subscribe((event: any) => {
            this.columnsList[1].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.ID');
            this.columnsList[2].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.NAME');
            this.columnsList[3].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.BRANDS');
            this.columnsList[4].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.SUPPLIERS');
            this.columnsList[5].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.UNIT');
            this.columnsList[6].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.ORDER_UNIT');
            this.columnsList[7].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.AVG_PRICE');
            this.columnsList[8].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.ALTERNATE_NAME');
            this.columnsList[9].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.VAT');
            this.columnsList[10].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.CATEGORY');
            this.columnsList[11].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.WASTE');
            this.columnsList[12].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.GROUP');
            this.columnsList[13].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.POSITION');
            this.columnsList[14].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.DATA_CREAZIONE');
            this.columnsList[15].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.DATA_MODIFICA');
            this.columnsList[16].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.IS_FRESH');
            this.columnsList[17].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.IS_FINISHED');
            this.columnsList[18].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.IS_CHECKED');
            this.columnsList[19].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.IS_RECIPE_USABLE');
            this.columnsList[20].label = this.translate.instant('GESTIONE_MERCI.COLUMNS.ALERT_PRICE');
        });
    }

    ngOnDestroy() {
        if (this.importSubscription) this.importSubscription.unsubscribe();
        this.filtriSubscription.unsubscribe();
        this.switchGroupSubcription.unsubscribe();


        // cancello il filtro
        this.filtriService.filterConfig = {};
    }

    showFromYearStart() {
        this.showInitialImage = false;
        this.currentFilterCfg = { ShowDisabled: false, ShowInvisible: false };
        this.filtriService.performFilter$.next(this.currentFilterCfg);
        this.ref.detectChanges();
    }

    getTitle() {
        return this.translate.instant('GESTIONE_MERCI.TITLE');
    }

    async ngOnInit() {

        this.categorieMerciList = await this.anagraficheService.getEntity('GoodsCategory').toPromise();

        this.confSrv.getConfigurations().subscribe(
            r => {
                this.optScarto = r.WasteMode;
            }
        );
        this.pagination = {
            pageSize: 10,
            TotalRows: 0,
            pageIndex: 0
        };

        try {
            let currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
            if (currentUser.Merci) this.pagination.pageSize = currentUser.Merci.pageSize;
        } catch (error) {
            console.log(error);
        }

        this.importTimer = timer(0, 10000);

        this.importSubscription = this.importTimer.subscribe(
            (r: any) => {
                this.gestioneMerciService.getImportStatus()
                    .subscribe((result: any) => {
                        if (result.IsImporting) {
                            this.importing$.next(true);
                            this.importProgress$.next(result.Progress);
                        }
                        else {
                            this.importing$.next(false);
                            this.importProgress$.next(0);
                        }
                    });

            });

        document.body.classList.add('kt-aside--minimize');
        this.filtriSubscription = this.filtriService.performFilter$.subscribe(
            (filterConfig: any) => {
                if (filterConfig.constructor !== Object) return;
                if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) return;

                //if (JSON.stringify(filterConfig) == JSON.stringify(this.currentFilterCfg)) return;

                this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));
                this.allGoods = undefined;
                this.showInitialImage = false;
                if (this.table) {
                    this.table.draw();
                } else {
                    this.initTable();
                }
            }
        );

        this.staticCollectionsReady = false;
        await this.staticCollectionsService.fillStaticCollection(false);
        this.staticCollectionsReady = true;
        this.initSelect2();

        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.clearFilters();
                }
            }
        );
    }

    performShowSuppliers($event: any) {
        this.columnsList[4].enabled = $event.checked;
        this.table.column(4).visible($event.checked);
        this.table.draw();
    }

    performShowBrands($event: any) {
        this.columnsList[3].enabled = $event.checked;
        this.table.column(3).visible($event.checked);
        //this.table.columns.adjust().draw();
        this.table.draw();
    }

    showChecked: boolean = false;
    showBrands: boolean = false;
    threeStateChecked: number = 3;
    performFilterChecked() {
        this.threeStateChecked++;
        if (this.threeStateChecked > 3) {
            this.threeStateChecked = 1;
        }
        this.filter(3 - this.threeStateChecked, 'ValidatedState')
    }

    performFreeSearch() {
        this.filter(this.globalFilter, 'FreeSearch');
    }

    filter(event: any, type: string) {
        this.showInitialImage = false;
        if (!this.currentFilterCfg) {
            this.currentFilterCfg = { ShowDisabled: false, ShowInvisible: false };
        }

        let cfg = JSON.parse(JSON.stringify(this.currentFilterCfg));

        if (type == 'Companies' && event) {
            cfg['OnlyVisibleForCompany'] = true;
        }

        if (event) {
            cfg[type] = event;
        } else {
            delete cfg[type];
        }


        cfg['fromLocal'] = false;
        this.filtriService.performFilter$.next(cfg);
    }

    openManutenzioneMerci() {
        const dialogRef = this.dialog.open(ManutenzioneMerciComponent, {
            data: {
                merce: ''
            },
            width: '100%',
            height: '100%'
        });

        dialogRef.afterClosed().subscribe(() => {
            if (this.table) this.table.draw();
        });
    }

    openGoodsVisibilityDialog() {
        const dialogRef = this.dialog.open(VisibilitaMerciComponent, {
            data: {
                merce: ''
            },
            width: '100%',
            height: '100%'
        });
    }

    openBlockUnitDialog() {
        const dialogRef = this.dialog.open(BloccoUnitaComponent, {
            data: {
                merce: ''
            },
            width: '100%',
            height: '100%'
        });
    }

    openColumnsDialog() {
        const dialogRef = this.dialog.open(ColumnsSelectionPopupComponent, {
            data: {
                columnsList: this.columnsList
            },
            width: '300px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            if (res.success && res.columnsList) {
                this.columnsList = res.columnsList;
                this.refreshColumnsVisibility();
            }
        });
    }

    refreshColumnsVisibility() {
        for (let index = 0; index < this.columnsList.length; index++) {
            var column = this.table.column(index);
            if (column) column.visible(this.columnsList[index].enabled);
        }
    }

    unlock() {

        this.gestioneMerciService.unlock()
            .subscribe((result: any) => {
                this.importing$.next(false);
                this.importProgress$.next(0);
            });
    }

    clearFilters(actionFilter: boolean = true) {
        this.globalFilter = '';
        this.categorieMerci = [];
        this.currentCompanyFilter = '';
        this.FBType = '7';

        ($('#gestioneMerciTable') as any).DataTable().clear().destroy();
        this.table = undefined;
        this.currentFilterCfg = [];
        this.showInitialImage = true;
        this.ref.detectChanges();
    }

    selectCompany() {
        const companyFilter = this.currentCompanyFilter ? [this.currentCompanyFilter] : ''
        this.filter(companyFilter, 'Companies');
    }

    selectCategories(categoryIds: any) {
        this.filter(categoryIds, 'CategoryIds');
    }

    categorieMerci: any = [];
    initSelect2() {
        this.categorieMerci = [];
        this.currentCompanyFilter = '';
        this.categorieMerci = [];
    }


    async performBulkSelection(event: any) {
        let selectedItems: any = [];
        $('#gestioneMerciTable tbody td:first-child i.fas.fa-check-square').each((index: number, el: any) => {

            const tr = $(el).closest('tr');
            const data: any = this.table.row(tr).data();
            selectedItems.push(data);

        });

        switch (event.value.toLowerCase()) {
            case 'recipefromgood': {

                // Verifico che tutte le merci selezionate abbiano la stessa nità di base
                let units: any = [];
                let i: number = 0;
                while (i < selectedItems.length) {
                    const index = units.findIndex(u => u.unit == selectedItems[i].Unit)
                    if (index >= 0) {
                        units[index].count++;
                    } else {
                        units.push({
                            count: 1,
                            unit: selectedItems[i].Unit,
                            unitId: selectedItems[i].UnitId,
                        });
                    }
                    i++;
                }

                if (units.length >= 2) {

                    const _title: string = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.RECIPE_FROM_GOOD_NOT_SAME_UNIT');
                    const _yesButton = this.translate.instant('COMMONS.OK');
                    //const _noButton = this.translate.instant('COMMONS.CANCEL');
                    //const _yesButton2 = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.RECIPE_FROM_GOOD_CONTINUA_ANYWAY');

                    let _description = '';

                    selectedItems.forEach(item => {
                        _description += `${item.Name} (${item.Unit})\n`;
                    })

                    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, '', _yesButton);
                    //dialogRef.afterClosed().subscribe(async (ok: any) => {

                    //});

                } else {
                    const dialogRef = this.dialog.open(RecipesFromGoodsComponent, {
                        width: '400px',
                        data: {
                            baseUnitId: units[0].unitId
                        }

                    });
                    dialogRef.afterClosed().subscribe(async (res: any) => {
                        if (res && res.baseUnitId) {
                            await this.bulkActionsService.massiveActions({
                                "Action": "Recipe_FromGood",
                                "EntityIds": selectedItems.map((item: any) => item.Id),
                                "RecipeFromGood": res
                            }).toPromise();
                            //this.refreshTable();
                            const message = this.translate.instant('GESTIONE_RICETTE.SAVE_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                        }
                    });
                }

            }
                break;
            case 'addbrands': {
                const brands = await this.anagraficheService.getBrands().toPromise();
                const dialogRef = this.dialog.open(AddBrandsDlgComponent, {
                    data: {
                        brandsList: brands
                    },
                    width: '400px',
                });
                dialogRef.afterClosed().subscribe(async (res: any) => {
                    await this.bulkActionsService.massiveActions({
                        "Action": "Goods_AddBrands",
                        "EntityIds": selectedItems.map((item: any) => item.Id),
                        "Targets": res
                    }).toPromise();
                    this.table.draw();
                });
            }
                break;
            case 'setcategory': {
                const categorieProdotti = await this.gestioneMerciService.getGoodsCategories().toPromise();
                const dialogRef = this.dialog.open(SetCategoryDlgComponent, {
                    data: {
                        categorieProdotti: categorieProdotti
                    },
                    width: '400px',
                });
                dialogRef.afterClosed().subscribe(async (res: any) => {
                    await this.bulkActionsService.massiveActions({
                        "Action": "Goods_SetCategory",
                        "EntityIds": selectedItems.map((item: any) => item.Id),
                        "Value": res
                    }).toPromise();
                    this.table.draw();
                });
            }
                break;
            case 'addsupplier': {
                //const suppliers = await this.gestioneMerciService.getSuppliers().toPromise();
                const dialogRef = this.dialog.open(SetSupplierComponent, {
                    data: {
                        suppliers: this.staticCollectionsService.fornitoriAbilitati$
                    },
                    width: '600px',
                });
                dialogRef.afterClosed().subscribe(async (res: any) => {
                    await this.bulkActionsService.massiveActions({
                        "Action": "Goods_AddSupplier",
                        "EntityIds": selectedItems.map((item: any) => item.Id),
                        "Value": res.supplierId,
                        "Content": res.companies && res.companies.length > 0 ? res.companies.join(",") : ''
                    }).toPromise();
                    this.table.draw();
                });
            }
                break;
            case 'setdisabled':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetDisabled",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                this.table.draw();
                break;
            case 'setenabled':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetDisabled",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                this.table.draw();
                break;
            case 'setinvisible':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetInvisible",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                this.table.draw();
                break;
            case 'setvisible':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetInvisible",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                this.table.draw();
                break;
            case 'setfresh':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetFresh",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                this.table.draw();
                break;
            case 'setnotfresh':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetFresh",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                this.table.draw();
                break;
            case 'setfinishedproduct':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetFinishedProduct",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                this.table.draw();
                break;
            case 'setnotfinishedproduct':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetFinishedProduct",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                this.table.draw();
                break;
            case 'setfoodcostbylist':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetFoodCostByList",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                }).toPromise();
                this.table.draw();
                break;
            case 'setfoodcostbyaverage':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetFoodCostByAverage",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                }).toPromise();
                this.table.draw();
                break;
            case 'setfoodcostbylast':
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetFoodCostByLast",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                }).toPromise();
                this.table.draw();
                break;
            case 'setvat': {
                const dialogRef = this.dialog.open(SetVATDlgComponent, {
                    width: '300px',
                });
                dialogRef.afterClosed().subscribe(async (res: any) => {
                    await this.bulkActionsService.massiveActions({
                        "Action": "Goods_SetVAT",
                        "EntityIds": selectedItems.map((item: any) => item.Id),
                        "Value": res
                    }).toPromise();
                    this.table.draw();
                });
            }
                break;
            case 'delete': {
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_Delete",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                }).toPromise();
                this.table.draw();
                break;
            }

            case 'isrecipeusable': {
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetUsableInRecipe",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                this.table.draw();
                break;
            }


            case 'isrecipeunusable': {
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetUsableInRecipe",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }).toPromise();
                this.table.draw();
            }
                break;

            case 'setalertprice': {
                await this.bulkActionsService.massiveActions({
                    "Action": "Goods_SetAlertPrice",
                    "EntityIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }).toPromise();
                this.table.draw();
            }
                break;
        }
    }

    bulkSelection(event: any) {
        this.bulkSelect = undefined;
        this.ref.detectChanges();
        if (event.value.toLowerCase() !== "addsupplier") {
            let _title: string = '';
            switch (event.value.toLowerCase()) {
                case 'setcategory':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.SET_CATEGORY');
                    break;
                case 'setdisabled':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.SET_DISABLED');
                    break;
                case 'setenabled':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.SET_ENABLED');
                    break;
                case 'setinvisible':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.SET_INVISIBLE');
                    break;
                case 'setvisible':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.SET_VISIBLE');
                    break;
                case 'setfresh':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.IS_FRESH');
                    break;
                case 'setnotfresh':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.IS_NOT_FRESH');
                    break;
                case 'setfinishedproduct':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.IS_FINISHED');
                    break;
                case 'setnotfinishedproduct':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.IS_NOT_FINISHED');
                    break;
                case 'setfoodcostbylist':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.FOOD_COST_CALC_PAM');
                    break;
                case 'setfoodcostbyaverage':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.FOOD_COST_CALC_PMP');
                    break;
                case 'setfoodcostbylast':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.FOOD_COST_CALC_UP');
                    break;
                case 'setvat':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.SET_VAT');
                    break;
                case 'delete':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.DELETE');
                    break;
                case 'recipefromgood':
                    _title = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.RECIPE_FROM_GOOD');
                    break;
            }

            const _description: string = this.translate.instant('GESTIONE_MERCI.BULK_ACTIONS.CONFIRM_ASK');
            const _waitDesciption: string = '';
            const _yesButton = this.translate.instant('COMMONS.CONFIRM');
            const _noButton = this.translate.instant('COMMONS.CANCEL');

            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
            dialogRef.afterClosed().subscribe(async (ok: any) => {
                if (ok) {
                    this.performBulkSelection(event);
                }
                this.bulkSelect = "";
                this.ref.detectChanges();
            });

        } else {
            this.performBulkSelection(event);
        }

    }

    selectItem(checked: boolean, item: any) {
        item['selected'] = checked;
    }

    selectAll(event: any) {
        $('#gestioneMerciTable tbody td:first-child i').each((index: number, el: any) => {
            if (!event.checked) {
                $(el).removeClass('fas fa-check-square');
                $(el).addClass('far fa-square');
            } else {
                $(el).addClass('fas fa-check-square');
                $(el).removeClass('far fa-square');
            }
        })
    }

    almoustOneSelected() {
        const almoustOne = $('#gestioneMerciTable tbody td:first-child i.fas.fa-check-square');
        return almoustOne.length > 0;
    }

    getWaste(wn) {
        let w = wn / 100;
        let wReturn = 0;
        switch (this.optScarto) {
            case 0:
                wReturn = wn;
                break;
            case 1:
                wReturn = (w / (1 + w)) * 100;

                break;
            case 2:
                wReturn = (1 + w) * 100;
                break;
        }

        return wReturn;
    }

    initTable() {
        if (!this.currentFilterCfg || Object.keys(this.currentFilterCfg).length == 0) {
            this.currentFilterCfg = {
                EnableState: 2,
                VisibleState: 1,
                FBType: 7
            };
        }

        if (!this.currentFilterCfg.FBType) {
            this.currentFilterCfg.FBType = 7;
        }

        if (!this.currentFilterCfg.EnableState) {
            this.currentFilterCfg.EnableState = 2;
        }

        if (!this.currentFilterCfg.VisibleState) {
            this.currentFilterCfg.VisibleState = 1;
        }

        this.filtriService.filterConfig = this.currentFilterCfg;

        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();

            let dtOptions: any = {
                destroy: true,
                paging: true,
                searching: true,
                dom: 'rt<"paginator">',
                ordering: true,
                scrollY: 'calc(100vh - 350px)',
                scrollX: false,
                scrollCollapse: true,
                autoWidth: true,
                order: [[15, 'desc']],
                processing: true,
                serverSide: true,
                scrollToTop: true,
                ajax: {
                    url: currentLogin.endpointURL + "/api/Entity/DataGoodsPage",
                    type: "POST",
                    data: (d: any) => {
                        d.filters = this.currentFilterCfg;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        this.pagination.TotalRows = response.recordsFiltered;

                        this.ref.detectChanges();

                        response.data.forEach((item: any) => {
                            // Gestione Avg Price
                            item.WasteCorrect = this.getWaste(item.WastPercentage);
                        });

                        return response.data;
                    }
                },
                columnDefs: [
                    {
                        targets: [0], width: this.columnsList[0].width + 'px', orderable: false, className: 'excludeFromExport', render: (data) => {
                            return `<i class="far fa-square select pointer"></i>`;
                        }, visible: this.columnsList[0].enabled
                    },
                    {
                        targets: [1], width: this.columnsList[1].width + 'px', data: 'Code', name: 'Code', render: (data) => {
                            return `<div class="overflow-ellipses colCode" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }, visible: this.columnsList[1].enabled
                    },
                    {
                        targets: [2], width: this.columnsList[1].width + 'px', data: 'Name', name: 'Name', render: (data, x, item) => {
                            const barred = item.IsDisabled ? 'barred' : '';
                            const cursive = item.IsHidden ? 'cursive' : '';

                            let anchorIcon = this.showAnchor && !this.selectedGood ? `<i class="fas fa-anchor pointer" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.UNISCI_MERCI.ANCHOR')}"></i>&nbsp;` : '';
                            let dragIcon = this.selectedGood ? `<i class="fas fa-arrows-alt pointer"></i>&nbsp;` : '';

                            // Pulisco
                            data = data.replace(/"/g, '')
                                .replace(/'/g, '')
                                .replace(/\n/g, '')
                                .trim();

                            const name = data.length > 50 ? data.substr(0, 50) + '...' : data;

                            const draggable = this.selectedGood ? 'draggable' : '';

                            const linkToGood = `<div class="display-flex-fill overflow-ellipses ${draggable}"><a href="javascript:void(0);" data-toggle="tooltip" title="${data}" 
                                                   class="editItem ${barred} ${cursive} ${!item.IsRecipeUsable ? 'text-danger' : ''}">                                                    
                                                    ${name}
                                                </a></div>`;

                            const graphTooltip = this.translate.instant('MAGAZZINO.VISUALIZZA_GRAFICO_ANALISI_PREZZI');
                            const showGraph = `<a href="javascript:;" data-toggle="tooltip" title="${graphTooltip}" class="showPriceGraph"><i class="fas fa-chart-area"></i></a>`;

                            return `<div class="display-flex-space-between flex-align-center display-flex-fill">
                                        <div class="display-flex-left display-flex-fill">
                                            ${dragIcon}
                                            ${anchorIcon}
                                            ${linkToGood}
                                        </div>
                                        ${showGraph}
                                    </div>`;
                        }, createdCell: function (td, cellData, rowData, row, col) {
                            $(td).addClass('draggable');
                        }, visible: this.columnsList[2].enabled
                    },
                    {
                        targets: [3], width: this.columnsList[3].width + 'px', data: 'Brands', name: 'Brands', render: (data) => {
                            let brands = data ? data.map((brand: any) => brand.Name).join(',') : '';
                            return `<div class="overflow-ellipses colBrand" data-toggle="tooltip" title="${brands}">${brands}</div>`;
                        }, visible: this.columnsList[3].enabled
                    },
                    {
                        targets: [4], width: this.columnsList[4].width + 'px', orderable: false, render: (data, type, p, meta) => {
                            let suppliers = "";
                            this.getSuppliers(p).split("\n").forEach(supplier => {
                                suppliers += `<div class="overflow-ellipses colSuppliers" data-toggle="tooltip" title="${supplier}">${supplier}</div>`;
                            })
                            return suppliers;
                        }, visible: this.columnsList[4].enabled
                    }, // Fornitori (40 chars)
                    {
                        targets: [5], width: this.columnsList[5].width + 'px', data: 'Unit', name: 'Unit', render: (data) => {
                            return data
                        }, visible: this.columnsList[5].enabled
                    },
                    {
                        targets: [6], width: this.columnsList[6].width + 'px', data: 'SecondaryUnit', name: 'SecondaryUnit', render: (data) => {
                            return data
                        }, visible: this.columnsList[6].enabled
                    },
                    {
                        targets: [7], width: this.columnsList[7].width + 'px', data: 'SecondaryPrice', name: 'SecondaryPrice', className: 'text-right', render: (data) => {
                            return `<span class="colAvgPrice">${this.utility.formatNumber(data, this.locale)}</span>`;
                        }, visible: this.columnsList[7].enabled
                    }, // Avg Price (15 chars)
                    {
                        targets: [8], width: this.columnsList[8].width + 'px', data: 'AlternateName', name: 'AlternateName', render: (data) => {
                            return `<div class="overflow-ellipses colAlternateName" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }, visible: this.columnsList[8].enabled
                    }, // IVA (5 chars)
                    {
                        targets: [9], width: this.columnsList[9].width + 'px', data: 'VAT', name: 'VAT', className: 'text-right', render: (data) => {
                            return this.utility.formatNumber(data, this.locale)
                        }, visible: this.columnsList[9].enabled
                    }, // Categoria (30 chars)
                    {
                        targets: [10], width: this.columnsList[10].width + 'px', data: 'Category', name: 'Category', render: (data) => {
                            return `<div class="overflow-ellipses colCategory" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }, visible: this.columnsList[10].enabled
                    }, // Scarto (8 chars)
                    {
                        targets: [11], width: this.columnsList[11].width + 'px', data: 'WastPercentage', className: 'text-right', name: 'WastPercentage', render: (data) => {
                            return this.utility.formatNumber(data, this.locale)
                        }, visible: this.columnsList[11].enabled
                    }, // Scarto (8 chars)
                    {
                        targets: [12], width: this.columnsList[12].width + 'px', data: 'Group', name: 'Group', render: (data) => {
                            return data
                        }, visible: this.columnsList[12].enabled
                    }, // Scarto (8 chars)
                    {
                        targets: [13], width: this.columnsList[13].width + 'px', data: 'Position', name: 'Position', render: (data) => {
                            return data
                        }, visible: this.columnsList[13].enabled
                    }, // Scarto (8 chars)
                    {
                        targets: [14], width: this.columnsList[14].width + 'px', data: 'CreationDate', name: 'CreationDate', render: (data) => {
                            return this.localizedDateTimePipe.transform(data, undefined, true);
                        }, visible: this.columnsList[14].enabled
                    }, // Data Creazione (15 chars)
                    {
                        targets: [15], width: this.columnsList[15].width + 'px', data: 'ChangeDate', name: 'ChangeDate', render: (data) => {
                            return this.localizedDateTimePipe.transform(data, undefined, true);
                        }, visible: this.columnsList[15].enabled
                    }, // Data Modifica (15 chars)
                    {
                        targets: [16], className: 'text-center', width: this.columnsList[16].width + 'px', orderable: false, data: 'IsFresh', name: 'IsFresh', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }, visible: this.columnsList[16].enabled
                    }, // Merce fresca
                    {
                        targets: [17], className: 'text-center', width: this.columnsList[17].width + 'px', orderable: false, data: 'IsFinished', name: 'IsFinished', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }, visible: this.columnsList[17].enabled
                    }, // Prodotto finito
                    {
                        targets: [18], className: 'text-center', width: this.columnsList[18].width + 'px', orderable: false, data: 'IsChecked', name: 'IsChecked', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }, visible: this.columnsList[18].enabled
                    }, // Is Checked
                    {
                        targets: [19], className: 'text-center', width: this.columnsList[19].width + 'px', orderable: false, data: 'IsRecipeUsable', name: 'IsRecipeUsable', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }, visible: this.columnsList[19].enabled
                    }, // IsRecipeUsable
                    {
                        targets: [20], className: 'text-center', width: this.columnsList[20].width + 'px', orderable: false, data: 'AlertPrice', name: 'AlertPrice', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }, visible: this.columnsList[20].enabled
                    }, // AlertPrice
                    {
                        targets: [21], width: this.columnsList[21].width + 'px', orderable: false, data: 'Name', name: 'Name', className: 'excludeFromExport text-center', render: (data) => {
                            const deleteMerce = this.roleService.isCompanyAdminOrMore() ? '&nbsp;<i class="fas fa-trash text-danger pointer deleteMerce"></i>' : '';
                            return `<div class="display-flex-center flex-align-center">
                                <i class="fas fa-pencil-alt text-primary pointer editItem"></i>
                                &nbsp;
                                <i class="fas fa-copy text-warning pointer duplicateItem"></i>
                                ${deleteMerce}
                                </div>
                            `;
                        }
                    }  // Actions
                ],
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                rowCallback: (row, data, index, element) => {
                    // Nascondo la merce selezionata come ricevente
                    if (this.selectedGood && data.Id == this.selectedGood.Id) {
                        $(row).hide();
                    }
                },
                drawCallback: () => {
                    if (this.selectedGood) {
                        ($("td.draggable") as any).draggable({
                            appendTo: 'body',
                            start: (event, a) => {
                                const tr = $(event.target).closest('tr');
                                const good = this.table.row(tr).data();
                                $(event.target).data('good', JSON.stringify(good));
                            },
                            stop: function () {
                                $('td').removeClass("selectedCell");
                            },
                            helper: function () {
                                var selected = $('td.selectedCell');
                                if (selected.length === 0) {
                                    selected = $(this).addClass('selectedCell');
                                }
                                var container = $('<div/>').attr('id', 'draggingContainer');
                                container.css('background', '#dddddd');
                                container.css('border', '1px solid grey');
                                container.css('padding', '10px');
                                container.append(selected.clone());
                                return container;
                            }
                        });
                    }

                    setTimeout(() => {
                        const width = $(`#gestioneMerciTable tbody tr td:nth-child(3)`).width();
                        $('#gestioneMerciTable tbody tr td:nth-child(3) .overflow-ellipses').css('max-width', (width - 15) + 'px');
                    }, 5000);
                },
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                search: 'none',
                                page: 'all',
                            },
                            columns: ':visible:not(.excludeFromExport)',
                            format: {
                                header: function (data, row, column, node) {
                                    if (data.indexOf('fa-carrot') >= 0) {
                                        return String.fromCharCode(parseInt('0x' + 'f787'));
                                    } else if (data.indexOf('fa-business-time') >= 0) {
                                        return String.fromCharCode(parseInt('0x' + 'f64a'));
                                    } else if (data.indexOf('fa-check-square') >= 0) {
                                        return String.fromCharCode(parseInt('0x' + 'f14a'));
                                    } else if (data.indexOf('fa-utensils') >= 0) {
                                        return String.fromCharCode(parseInt('0x' + 'f2e7'));
                                    } else if (data.indexOf('fa-bell') >= 0) {
                                        return String.fromCharCode(parseInt('0x' + 'f0f3'));
                                    } else {
                                        return data
                                    }
                                },
                                body: function (data, row, column, node) {

                                    if (data.indexOf('colCode') >= 0) {
                                        // se la colonna è quella del codice della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colCode').attr('title');
                                        return title;
                                    } else if (data.indexOf('editItem') >= 0) {
                                        // se la colonna è quella del nome della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.editItem').attr('title');
                                        return title;
                                    } else if (data.indexOf('colBrand') >= 0) {
                                        // se la colonna è quella del brand della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colBrand').attr('title');
                                        return title;
                                    } else if (data.indexOf('colSuppliers') >= 0) {
                                        // se la colonna è quella del brand della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        //const title = $(htmlCol).find('.colSuppliers').attr('title');
                                        let title = "";
                                        $(htmlCol).find('.colSuppliers').each((index, el) => {
                                            title += $(el).attr('title') + '\n';
                                        })
                                        return title;
                                    } else if (data.indexOf('colAlternateName') >= 0) {
                                        // se la colonna è quella del nome alternativo della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colAlternateName').attr('title');
                                        return title;
                                    } else if (data.indexOf('colCategory') >= 0) {
                                        // se la colonna è quella della categoria della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colCategory').attr('title');
                                        return title;

                                    } else if (data.indexOf("far fa-square") >= 0) {
                                        return String.fromCharCode(parseInt('0x' + 'f0c8'));
                                    } else if (data.indexOf("fas fa-check-square") >= 0) {
                                        return String.fromCharCode(parseInt('0x' + 'f14a'));
                                    } else {
                                        return data;
                                    }
                                }
                            }
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            var allVisibleArray = this.table.columns().visible().toArray()
                                .map((value, index) => {
                                    return {
                                        index: index,
                                        value: value
                                    }
                                }).filter(item => item.value);

                            //const arrayCols = this.table.column(6).visible() ? ['auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : ['auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto']
                            delete allVisibleArray[0];
                            delete allVisibleArray[allVisibleArray.length - 1];
                            let arrayCols = Array(allVisibleArray.length).fill('auto')

                            arrayCols[2] = '*';

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, arrayCols, '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;
                            doc.styles = docDefinition.styles;

                            doc.content[1].table.body.forEach((row, index) => {
                                row.forEach((field, col) => {
                                    if (col >= row.length - 4) {
                                        if (index == 0) {
                                            field.style = 'fontAwesomeHeader';
                                        } else if (field.style.indexOf('Odd') >= 0) {
                                            field.style = 'fontAwesomeOdd';
                                        } else {
                                            field.style = 'fontAwesome';
                                        }
                                    }
                                })
                            });
                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.pdfHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        //autoFilter: true,
                        //sheetName: 'Exported data',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.excludeFromExport)',
                            format: {
                                header: (data, row, column, node) => {
                                    if (data.indexOf('fa-carrot') >= 0) {
                                        return this.translate.instant('GESTIONE_MERCI.COLUMNS.IS_FRESH');
                                    } else if (data.indexOf('fa-business-time') >= 0) {
                                        return this.translate.instant('GESTIONE_MERCI.COLUMNS.IS_FINISHED');
                                    } else if (data.indexOf('fa-check-square') >= 0) {
                                        return this.translate.instant('GESTIONE_MERCI.COLUMNS.IS_CHECKED');
                                    } else if (data.indexOf('fa-utensils') >= 0) {
                                        return this.translate.instant('GESTIONE_MERCI.EDIT.PARAMETERS.USABILERICETTE');
                                    } else {
                                        return data
                                    }
                                },
                                body: (data, row, column, node) => {
                                    if (data.indexOf('colCode') >= 0) {
                                        // se la colonna è quella del codice della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colCode').attr('title');
                                        return title;
                                    } else if (data.indexOf('editItem') >= 0) {
                                        // se la colonna è quella del nome della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.editItem').attr('title');
                                        return title;
                                    } else if (data.indexOf('colBrand') >= 0) {
                                        // se la colonna è quella del brand della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colBrand').attr('title');
                                        return title;
                                    } else if (data.indexOf('colSuppliers') >= 0) {
                                        // se la colonna è quella del brand della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        //const title = $(htmlCol).find('.colSuppliers').attr('title');
                                        let title = "";
                                        $(htmlCol).find('.colSuppliers').each((index, el) => {
                                            title += $(el).attr('title') + '\n';
                                        })
                                        return title;
                                    } else if (data.indexOf('colAlternateName') >= 0) {
                                        // se la colonna è quella del nome alternativo della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colAlternateName').attr('title');
                                        return title;
                                    } else if (data.indexOf('colCategory') >= 0) {
                                        // se la colonna è quella della categoria della merce, prendo direttamente il contenuto del tooltip
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const title = $(htmlCol).find('.colCategory').attr('title');
                                        return title;
                                    } else if (data.indexOf('colAvgPrice') >= 0) {
                                        var parser = new DOMParser();
                                        var htmlCol = parser.parseFromString(data, 'text/html');
                                        const value = $(htmlCol).find('.colAvgPrice').text();
                                        return data = '\0' + value;
                                    } else if (data.indexOf("far fa-square") >= 0) {
                                        return 'false';
                                    } else if (data.indexOf("fas fa-check-square") >= 0) {
                                        return 'true'
                                    } else {
                                        return data;
                                    }
                                }
                            },
                            orthogonal: 'export'
                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.excelHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    }
                ],
            };

            this.table = $('#gestioneMerciTable').DataTable(dtOptions);

            $('#gestioneMerciTable tbody').on('click', 'td:first-child i', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                if (data) {
                    const isAlreadyChecked = $(event.target).hasClass('fas fa-check-square');
                    if (isAlreadyChecked) {
                        $(event.target).removeClass('fas fa-check-square');
                        $(event.target).addClass('far fa-square');
                    } else {
                        $(event.target).addClass('fas fa-check-square');
                        $(event.target).removeClass('far fa-square');
                    }
                    this.selectItem(!isAlreadyChecked, data);
                }
            });

            $('#gestioneMerciTable tbody').on('click', 'i.editItem', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                if (data) {
                    this.editItem(data);
                }
            });

            $('#gestioneMerciTable tbody').on('click', 'i.duplicateItem', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                if (data) {
                    this.duplicateItem(data);
                }
            });

            $('#gestioneMerciTable tbody').on('click', 'i.deleteMerce', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                if (data) {
                    this.deleteMerce(data.Id);
                }
            });

            $('#gestioneMerciTable tbody').on('click', 'a.editItem', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                if (data) {
                    this.editItem(data);
                }
            });

            $('#gestioneMerciTable tbody').on('click', 'a.showPriceGraph', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                if (data) {
                    this.showPriceGraph(data);
                }
            });

            $('#gestioneMerciTable tbody').on('click', '.fa-anchor', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data: any = this.table.row(tr).data();
                if (data) {
                    this.selectAnchor(data);
                }
            });

            this.table.on('page.dt', function () {
                $('.dataTables_scrollBody').animate({
                    scrollTop: 0
                }, 'slow');
            });

            this.bulkSelect = "";
            this.ref.detectChanges();

        }, 100);

    }

    async performMergeGood(anchorGood: any, newGood: any) {
        const anchorSuppliers = anchorGood.Suppliers.map((supplier: any) => parseInt(supplier.Id));
        const goodSuppliers = newGood.Suppliers.map((supplier: any) => parseInt(supplier.Id));

        try {
            // Scorro tutti i fornitori della merce da aggiungere rispetto alla merce ricevente
            for (let index = 0; index < goodSuppliers.length; index++) {
                const supplierId = goodSuppliers[index];

                if (anchorSuppliers.includes(supplierId)) {
                    /* Stesso fornitore */
                    // 1. Cerco l'indice del fornitore all'interno dell'array fornitori di good
                    const indexSupplier = newGood.Suppliers.findIndex(sup => sup.Id == supplierId);
                    // 2. Recupero la variation di good e per ogni elemento all'interno creo un nuovo codice merce
                    const newVariation = newGood.Suppliers[indexSupplier].Variations;
                    for (let index = 0; index < newVariation.length; index++) {
                        const variation = newVariation[index];
                        variation.SecondaryUnitRatio = '';
                        variation.Alias = newGood.Name;
                    };
                    // 3. Recupero l'indice del supplier di riferimento della merce ricevente
                    const indexSupplierAnchor = anchorGood.Suppliers.findIndex(sup => sup.Id == supplierId);
                    // 4. Aggiungo la nuova variation alla merce ricevente.

                    anchorGood.Suppliers[indexSupplierAnchor].Variations.forEach((variation: any) => {
                        if (!variation.Alias) {
                            variation.Alias = variation.Name;
                        }
                        if (!anchorGood.IsChecked) {
                            //variation.SecondaryUnitRatio = '';
                        }
                    })

                    anchorGood.Suppliers[indexSupplierAnchor].Variations = anchorGood.Suppliers[indexSupplierAnchor].Variations.concat(newVariation);

                } else {
                    /* Aggiungo nuovo fornitore al ricevente */

                    // 1. Recupero le info del nuovo fornitore da staticCollectionsService
                    const newSupplier = this.staticCollectionsService.fornitoriTutti$.find((s: any) => s.Id == supplierId);
                    // 2. Recupero l'indice del fornitore dentro good
                    const indexSupplier = newGood.Suppliers.findIndex(sup => sup.Id == supplierId);
                    // 3. Imposto un nuovo codice merce per tutte le variations
                    for (let index = 0; index < newGood.Suppliers[indexSupplier].Variations.length; index++) {
                        const variation = newGood.Suppliers[indexSupplier].Variations[index];
                        variation.SecondaryUnitRatio = '';
                        variation.Alias = newGood.Name;
                    };

                    anchorGood.Suppliers.forEach(supplier => {
                        supplier.Variations.forEach(variation => {
                            if (!variation.Alias) {
                                variation.Alias = variation.Name;
                            }
                            if (!anchorGood.IsChecked) {
                                //variation.SecondaryUnitRatio = '';
                            }
                        })
                    })

                    // 4. Aggiungo in nuovo fornitore alla merce ricevente
                    anchorGood.Suppliers.push({
                        CalculatedPrice: false,
                        Id: supplierId,
                        IsDisabled: newSupplier.IsDisabled,
                        LockPriceChange: false,
                        Name: newSupplier.Name,
                        Variations: newGood.Suppliers[indexSupplier].Variations
                    });
                }
            }
        } catch (error) {
            console.log('selectAnchor', 'drop', error);
        }

        //http://mantis.fbmanager.com/view.php?id=1130
        //Deve azzerare non solo ciò che viene trascinato da D&D ma anche tutti i rapporti mag/base di tutti i fornitori presenti
        //anchorGood.Suppliers.forEach(supplier => {
        //    supplier.Variations.forEach(variation => {
        //        variation.SecondaryUnitRatio = '';
        //    })
        //})

        // Quì sopra ennesima RIMODIFICA https://mantis.fbmanager.com/view.php?id=1188

        // Serve per indicare che questa merce è un merge con un altra
        // mi serve per queesto ticket https://mantis.fbmanager.com/view.php?id=1502
        anchorGood.merged = true;

        this.editItem(anchorGood, newGood.Id);
    }

    selectAnchor(good: any) {
        this.selectedGood = good;
        this.table.draw();
        this.ref.detectChanges();

        ($('#selectedGoodContainer') as any).droppable({
            accept: "td.draggable",
            classes: {
                "ui-droppable-active": "ui-state-highlight"
            },
            drop: async (event: any, ui: any) => {
                var goodStr = ui.draggable.data('good');
                try {
                    const good = JSON.parse(goodStr);
                    const anchorGood = JSON.parse(JSON.stringify(this.selectedGood));
                    this.performMergeGood(anchorGood, good);
                } catch (error) {
                    console.log('selectAnchor', 'drop', error, goodStr);
                }
            }
        });
    }

    async newMerce() {

        const newCode = await this.gestioneMerciService.getFirstAvailableGoodCode().toPromise();

        const unit = this.staticCollectionsService.unit$.find((unit: any) => unit.Name.toLowerCase() === 'kg');
        const defGroup = this.staticCollectionsService.gruppiMerci$.find((m: any) => m.Name == '---');
        let newItem: any = {
            AlternateName: '',
            AvgPrice: '',
            BaseUnitId: unit ? unit.MainUnitId : '',
            A: unit.A,
            B: unit.B,
            Category: '',
            CategoryId: '',
            Code: newCode,
            MBCode: newCode,
            ExcludedByConsumption: false,
            Group: '',
            GroupId: defGroup ? defGroup.Id : '',
            InOut: 0,
            IsDisabled: false,
            IsFinished: false,
            IsFresh: false,
            IsHidden: false,
            LockPriceChange: false,
            Name: '',
            Note: '',
            OtherUnit: "",
            OtherUnitId: unit ? unit.Id : '',
            OtherUnitRatio: 1,
            Position: '',
            Price: '',
            PriceMode: "MasterPrice",
            Prices: [],
            ProductionRatio: 1,
            ProductionUnit: "Kg",
            ProductionUnitId: unit ? unit.Id : '',
            SecondaryUnit: "",
            SecondaryUnitId: unit ? unit.Id : '',
            SecondaryUnitRatio: 1,
            Suppliers: [],
            Unit: 'Kg',
            UnitId: unit ? unit.Id : '',
            VAT: 0,
            WastPercentage: 0
        };

        const orderUnit = this.staticCollectionsService.orderUnit$[0];

        this.staticCollectionsService.aziende$.forEach((company: any) => {
            newItem.Prices.push({
                Alias: '',
                Azienda: company.Name,
                IsMaster: false,
                IsVisible: true,
                OtherUnit: '',
                OtherUnitId: orderUnit.Id,
                OtherUnitRatio: 1,
                Price: '',
                PriceUM: '',
                PriceUO: '',
                SecondaryUnit: '',
                SecondaryUnitId: orderUnit.Id,
                SecondaryUnitRatio: 1,
                SupplierId: ''
            });
        });

        this.editItem(newItem);
    }

    fixNumericFields(merce) {
        merce.WastPercentage = merce.WastPercentage ? util.parseNumber(merce.WastPercentage, this.locale) : 0;
        merce.ProductionRatio = merce.ProductionRatio ? util.parseNumber(merce.ProductionRatio, this.locale) : 0;
        if (!merce.Prices) merce['Prices'] = [];
        merce.Prices.forEach(e => {
            e.OtherUnitRatio = e.OtherUnitRatio ? util.parseNumber(e.OtherUnitRatio, this.locale) : 0;
            e.SecondaryUnitRatio = e.SecondaryUnitRatio ? util.parseNumber(e.SecondaryUnitRatio, this.locale) : 0;

            e.Price = e.Price ? util.parseNumber(e.Price, this.locale) : 0;
            e.PriceUM = e.PriceUM ? util.parseNumber(e.PriceUM, this.locale) : 0;
            e.PriceUO = e.PriceUO ? util.parseNumber(e.PriceUO, this.locale) : 0;
        });

        if (!merce.Suppliers) merce['Suppliers'] = [];
        merce.Suppliers.forEach(e => {
            e.Variations.forEach(v => {
                v.OtherUnitRatio = v.OtherUnitRatio ? util.parseNumber(v.OtherUnitRatio, this.locale) : 0;
                v.SecondaryUnitRatio = v.SecondaryUnitRatio ? util.parseNumber(v.SecondaryUnitRatio, this.locale) : 0;
                v.Price = v.Price ? util.parseNumber(v.Price, this.locale) : 0;
            });
        });

        return merce;
    }

    async duplicateItem(merce: any) {

        const _title: string = this.translate.instant('GESTIONE_MERCI.DUPLICATE_DIALOG.TITLE');
        const _description: string = this.translate.instant('GESTIONE_MERCI.DUPLICATE_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('GESTIONE_MERCI.DUPLICATE_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(async (ok: any) => {
            if (ok) {
                merce.Code = await this.gestioneMerciService.getFirstAvailableGoodCode().toPromise();
                merce = this.fixNumericFields(merce);
                this.gestioneMerciService.saveGoods(merce).subscribe(
                    (result: any) => {
                        this.loading$.next(false);
                        let message: string = '';
                        if (result.SavedId) {
                            message = this.translate.instant('GESTIONE_MERCI.DUPLICATE_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                        } else {
                            message = this.translate.instant('GESTIONE_MERCI.DUPLICATE_DIALOG.ERROR');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                        }

                        this.table.draw();
                    }, (error: any) => {
                        this.loading$.next(false);
                    }
                );
            }
        });


    }

    editItem(merce: any, linkedGoodId: string = '') {
        const dialogRef = this.dialog.open(EditMerciComponent, {
            data: {
                merce: merce,
                linkedGoodId: linkedGoodId
            },
            width: '100%',
            height: '100%'
        });

        dialogRef.afterClosed().subscribe((res: any) => {
            setTimeout(() => {
                if (res) {
                    if (this.selectedGood) {
                        this.selectedGood = res;
                        this.gestioneMerciService.checkStatus().toPromise()
                            .then((status: any) => {
                                //console.log(status);
                            });
                    }

                    this.table.page.len(this.pagination.pageSize);
                    this.table.page(this.pagination.pageIndex).draw('page');

                    let message = merce.Id ? this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.SUCCESS') : this.translate.instant('GESTIONE_MERCI.SAVE_DIALOG.SUCCESS');
                    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                }
            }, 100);
        });
    }

    changePagination(event: PageEvent) {
        if (this.pagination.pageIndex != event.pageIndex) {
            this.pagination.pageIndex = event.pageIndex;
            this.table.page(this.pagination.pageIndex).draw('page');
        } else if (this.pagination.pageSize != event.pageSize) {
            this.pagination.pageSize = event.pageSize;
            this.table.page.len(this.pagination.pageSize);
            this.table.page(0).draw('page');
        }
    }

    importMerci(event: any) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.importing$.next(true);
            let file: File = fileList[0];
            let formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            this.gestioneMerciService.uploadMerci(formData).subscribe(
                (result) => {
                    let message = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_OK');
                    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                    event.target.value = ''
                },
                (error) => {
                    let message = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_FAIL');
                    this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                    event.target.value = ''
                }
            );
        }
    }

    download() {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        this.gestioneMerciService.dowloadFile().subscribe(
            resultOp => {
                window.open(`${currentLogin.endpointURL}${resultOp.link}`, '_blank');
            }
        );
    }

    importFile(event: any) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.importing$.next(true);
            let file: File = fileList[0];
            let formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            this.gestioneMerciService.uploadFile(formData).subscribe(
                (result) => {

                    let message = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_OK');
                    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                    event.target.value = ''
                },
                (error) => {
                    let message = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_FAIL');
                    this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                    event.target.value = ''
                }
            );
        }
    }

    deleteMerce(itemId: any) {
        const _title: string = this.translate.instant('GESTIONE_MERCI.DELETE_DIALOG.TITLE');
        const _description: string = this.translate.instant('GESTIONE_MERCI.DELETE_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('GESTIONE_MERCI.DELETE_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            if (confirm) {
                this.gestioneMerciService.deleteGoods(itemId).subscribe(
                    (result: any) => {
                        let message: string = '';
                        if (result.Msg === 'Ok') {
                            message = this.translate.instant('GESTIONE_MERCI.DELETE_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                            this.table.draw();
                        } else {
                            message = this.translate.instant('GESTIONE_MERCI.DELETE_DIALOG.ERROR');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                        }
                    }
                )
            }

        });
    }

    getSuppliers(item: any) {
        if (item.Suppliers && item.Suppliers.length > 0) {
            let suppliersList = '';
            item.Suppliers.forEach(supplier => {
                if (suppliersList.length > 0) suppliersList += '\n';
                suppliersList += supplier.Name;
            });
            return suppliersList;
        } else {
            return '';
        }
    }


    isImported(good: any) {
        if (this.categorieMerciList && this.categorieMerciList.length > 0) {
            const categoryIndex = this.categorieMerciList.findIndex((c: any) => c.Id == good.CategoryId && c.IsImport);
            return categoryIndex >= 0;
        } else {
            return false;
        }
    }

    performShowAnchor() {
        this.showAnchor = !this.showAnchor;
        if (!this.showAnchor) {
            this.selectedGood = undefined;
        } else {
            this.showSuppliers = true;
            // attivo la vista del fonitore
            this.columnsList[4].enabled = true;
            this.table.column(4).visible(true);
        }
        this.table.draw();
    }

    showPriceGraph(item: any) {
        const dialogRef = this.dialog.open(AnalisiPrezziFornitoriDialogComponent, {
            data: {
                ProductId: item.Id
            },
            width: '100%',
            height: '800px'
        });
    }

    /******************************************************************************
    ______                       _    __   ___       _______   __
    |  ____|                     | |   \ \ / / |     / ____\ \ / /
    | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
    |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
    | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
    |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
         | |
         |_|
    ******************************************************************************/

    async exportAsXLSX() {
        this.table.button(1).trigger();
        this.loading$.next(true);
        setTimeout(() => {
            this.ref.detectChanges();
            this.loading$.next(false);
        }, 100);
    }

    /******************************************************************************
      ______                       _     _____  _____  ______
     |  ____|                     | |   |  __ \|  __ \|  ____|
     | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
     |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
     | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
     |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
                 | |
                 |_|
    ******************************************************************************/

    exportAsPDF(expand: boolean = false) {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
            explodeRows: this.translate.instant('EXPORT_PDF.EXPLODE_ROWS')
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            //if (result) this.preparePdfConfiguration(result);
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('GESTIONE_FATTURE.TITLE'),
                    period: ''
                };
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                };
                result['language'] = this.translationService.getSelectedLanguage();

                this.PDFPreferences = result;

                this.table.button(0).trigger();
            };
        });
    }

}