import { Component, OnInit } from '@angular/core';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { AziendeService } from '@app/core/services/aziende.service';
import { environment } from '@env/environment';

@Component({
	selector: 'kt-switch-group',
	templateUrl: './switch-group.component.html',
	styleUrls: ['./switch-group.component.scss']
})
export class SwitchGroupComponent implements OnInit {

	loginGroup: any = undefined;
	logoBase64: any = {};
	isLoading: any = {};
	isError: any = {};

	groups: any;

	constructor(
		public switchGroupService: SwitchGroupService,
		public aziendeService: AziendeService
	) {
	}

	ngOnInit() {
		this.groups = this.switchGroupService.getGroupsList();

		this.groups.forEach((item: any) => {
			let fieldName = item.group.replace(/\s+/g, '_');
			this.isLoading[fieldName] = true;
			this.aziendeService.getLogo(item.endpointURL, item.access_token).toPromise()
			.then((logo: any) => {
					this.isLoading[fieldName] = false;
					this.logoBase64[fieldName] = logo;
				}
			)
			.catch((error: any) => {
				this.isLoading[fieldName] = false;
				this.isError[fieldName] = true;
			})
		});

		this.loginGroup = this.switchGroupService.getCurrentGroup();

		let x = 1;
	}

	performSwitchGroup(index: number) {
		// Imposto il gruppo corrente
		this.switchGroupService.setCurrentGroup(index);
		this.loginGroup = this.switchGroupService.getCurrentGroup();
		//console.log('performSwitchGroup', this.loginGroup);
		this.switchGroupService.performSwitch.next({
			change: true,
			index: index
		});
	}

	getLogo(currentGroup: any) {
		let fieldName = currentGroup.group.replace(/\s+/g, '_');
		return this.logoBase64[fieldName];
	}

	onErrorImage(event: any, group: any = undefined) {
		// if (group) {
		// 	this.switchGroupService.removeFromGroupList(group);
		// }
		// // console.log('onErrorImage', event);
		// $(event.target).attr('src', 'assets/media/logos/MyBusiness.png')
	}

}
