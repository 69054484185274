import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API } from '@env/environment';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

@Injectable({
    providedIn: 'root'
})
export class StoricoVenditeService {

    constructor(
        private http: HttpClient,
        private switchGroupService: SwitchGroupService
    ) { }

    getSellingList(filter: any, pagination: any, grouped: boolean = false): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Selling/List?page=${pagination.page}&pagesize=${pagination.pageSize}&sort=${pagination.sort}&sortMode=${pagination.sortMode}&grouped=${grouped}`;

        return this.http.post<any>(currentLogin.endpointURL + url, filter, { headers: httpHeaders });
    }

    deleteSelling(id): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Selling/Delete?id=${id}`;

        return this.http.delete<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
    }

    importCsv(file: any, store: string): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Action': 'upload',
            'Authorization': 'Bearer ' + currentLogin.access_token

        });
        const url = `/api/Selling/UploadLightSpeedCSV?store=${store}`;
        return this.http.post(currentLogin.endpointURL + url, file, { headers: httpHeaders });
    }

    updateSelling(item: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.put<any>(currentLogin.endpointURL + '/api/Selling/Update', item, { headers: httpHeaders });
    }

    compensateSelling(payload: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.post<any>(currentLogin.endpointURL + '/api/Selling/Compensate', payload, { headers: httpHeaders });
    }

}
