import { Component, OnInit } from '@angular/core';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-warehouse-selector',
  templateUrl: './warehouse-selector.component.html',
  styleUrls: ['./warehouse-selector.component.scss']
})
export class WarehouseSelectorComponent implements OnInit {

  public selectedModel: string = 'Purchase';
  setModelSubscription!: Subscription;

  constructor(
    private magazzinoService: MagazzinoService
  ) { }

  ngOnInit() {
    this.setModelSubscription = this.magazzinoService.setModel$.subscribe(
      (model: string) => {
        this.selectedModel = model
      }
    );
  }

  ngOnDestroy() {
    this.setModelSubscription.unsubscribe();
  }

  changeSelector($event: any) {
    this.magazzinoService.selectorChanged$.next({
      model: this.selectedModel,
      localized: $('#warehouseSelector option:selected').text()
    });
  }



}
