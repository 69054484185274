import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import {PasswordValidator, RepeatPasswordEStateMatcher, RepeatPasswordValidator} from '../../../../core/validators/password-validator';



@Component({
  selector: 'kt-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  passwordsMatcher = new RepeatPasswordEStateMatcher();
  form: FormGroup;
  hide1 = true;
  hide2 = true;
  hide3 = true;
  
  get oldPasswordInput(): FormControl { return this.form.get('oldPassword') as FormControl; }
  get newPasswordInput(): FormControl { return this.form.get('newPassword') as FormControl; }
  get confirmPasswordInput(): FormControl { return this.form.get('confirmPassword') as FormControl;  }


constructor(public dialogRef: MatDialogRef<any>,
            private builder: FormBuilder) {
      this.form = builder.group({
                oldPassword: new FormControl('', [Validators.required, Validators.minLength(6), PasswordValidator.strong ]),
                newPassword: new FormControl('', [Validators.required, Validators.minLength(6), PasswordValidator.strong ]),
                confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6), PasswordValidator.strong ])
              }, { validator: RepeatPasswordValidator });

}

ngOnInit() {
  }

  isInvalid() {

    const required = this.oldPasswordInput.hasError('required');
    const min = this.oldPasswordInput.hasError('min');
    const strong = this.oldPasswordInput.hasError('strong');
    return this.oldPasswordInput.invalid || this.newPasswordInput.invalid || this.confirmPasswordInput.invalid || this.confirmPasswordInput.value !== this.newPasswordInput.value;
  }

onSubmit() {

			this.dialogRef.close({
			success: true,
			body: {
				OldPassword: this.oldPasswordInput.value,
				NewPassword: this.newPasswordInput.value,
			},
		});
	}


}
