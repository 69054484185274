import { Component, Input, OnInit, Inject } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
//Services
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { MagazzinoService } from '@app/core/services/magazzino.service';

// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


// Utility
import * as util from '@app/core/services/utilityfunctions';

import { EditMerciComponent } from '../edit-merci/edit-merci.component';

@Component({
    selector: 'kt-merge-goods',
    templateUrl: './merge-goods.component.html',
    styleUrls: ['./merge-goods.component.scss']
})
export class MergeGoodsComponent implements OnInit {

    enableOverwrite: boolean = false;
    merce: any;
    list: any;
    listFiltered: any;
    totRows: number = 50;
    searchText!: string;
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    invoice: any;
    utility: any;
    locale!: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private gestioneMerciService: GestioneMerciService,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        public dialogRef: MatDialogRef<MergeGoodsComponent>,
        private magazzinoService: MagazzinoService,
        private dialog: MatDialog
    ) {
        this.utility = util;
    }

    ngOnInit(): void {
        this.merce = this.data.merce;
        this.list = this.data.list;
        this.locale = this.data.locale;

        this.list.forEach(item => {
            item.whatAction = this.whatAction(item);
        });

        this.enableOverwrite = this.data.enableOverwrite;
        this.invoice = this.data.invoice;
        this.listFiltered = this.list.slice(0, this.totRows);
        this.searchText = this.merce.Name;
    }

    whatAction(item: any): string {
        let _whatAction: string = '';
        // Verifico ho stesso fornitore ma codici diversi, allora unisco sotto allo stesso fornitore
        const sameSupplier = this.merce.Suppliers && this.merce.Suppliers.length > 0 ? item.Suppliers.find(supplier => supplier.Id == this.merce.Suppliers[0].Id) : false;
        // esiste lo stesso fornitore verifico che non sia già presente quel codice
        if (sameSupplier) {
            const sameVariation = sameSupplier.Variations.find(variation => variation.Code == this.merce.Suppliers[0].Variations[0].Code);
            if (!sameVariation) {
                _whatAction = 'MERGE_GOODS';
            } else {
                _whatAction = 'SAME_VARIATION';
            }

            // Verifico ho fornitori diversi
        } else {
            _whatAction = 'MERGE_SUPPLIERS';
        }

        return _whatAction;
    }

    mergeGood(item: any, overwrite: boolean = false) {

        let _title, _description, _waitDesciption, _yesButton, _noButton, _success;

        if (overwrite) {
            _title = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.OVERWRITE_DIALOG.TITLE');
            _description = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.OVERWRITE_DIALOG.DESC');
            _waitDesciption = '';
            _yesButton = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.OVERWRITE_DIALOG.BUTTON');
            _noButton = this.translate.instant('COMMONS.CANCEL');
            _success = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.OVERWRITE_DIALOG.SUCCESS');

        } else if (this.whatAction(item) == 'MERGE_SUPPLIERS') {
            _title = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.MERGE_SUPPLIERS_DIALOG.TITLE');
            _description = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.MERGE_SUPPLIERS_DIALOG.DESC');
            _waitDesciption = '';
            _yesButton = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.MERGE_SUPPLIERS_DIALOG.BUTTON');
            _noButton = this.translate.instant('COMMONS.CANCEL');
            _success = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.MERGE_SUPPLIERS_DIALOG.SUCCESS');

        } else if (this.whatAction(item) == 'MERGE_GOODS') {
            _title = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.MERGE_GOODS_DIALOG.TITLE');
            _description = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.MERGE_GOODS_DIALOG.DESC');
            _waitDesciption = '';
            _yesButton = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.MERGE_GOODS_DIALOG.BUTTON');
            _noButton = this.translate.instant('COMMONS.CANCEL');
            _success = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.MERGE_GOODS_DIALOG.SUCCESS');

        } else {
            return;
        }

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);

        dialogRef.afterClosed().subscribe((confirm: any) => {
            console.log(confirm);
            if (confirm) {
                if (overwrite) {
                    this.magazzinoService.updateInvoice({
                        FileName: this.invoice.FileName,
                        RowNumber: this.invoice.RowNumber,
                        GoodsId: item.Id,
                        GoodsCode: item.Suppliers[0].Variations[0].Code,
                        IsForAllDocument: this.invoice.IsForAllDocument,
                        SupplierId: this.invoice.SupplierId,
                        CurrentGoodsCode: this.invoice.CurrentGoodsCode,

                    }).toPromise()
                        .then((result) => {
                            this.layoutUtilsService.showActionNotification(_success, MessageType.Create, 3000, true, false, 3000, 'top', 'snackbar-success');

                            this.dialogRef.close({
                                type: 'overwrite',
                                merceId: item.Id
                            });
                        });

                } else {

                    let payload = {
                        targetId: item.Id
                    }
                    if (!this.merce.Id) {
                        payload['source'] = this.merce;
                    } else if (this.merce.Id) {
                        payload['sourceId'] = this.merce.Id;
                    }

                    payload = this.cleanPayload(payload);

                    lastValueFrom(this.gestioneMerciService.mergeGoods(payload))
                        .then((result) => {
                            this.layoutUtilsService.showActionNotification(_success, MessageType.Create, 3000, true, false, 3000, 'top', 'snackbar-success');

                            const response: any = {
                                type: this.whatAction(item),
                                merceId: item.Id
                            };

                            if(response.type == 'MERGE_SUPPLIERS') {
                                response['supplierToMerge'] = item.Suppliers
                            }

                            this.dialogRef.close(response);
                        });
                }

            }
        });

    }

    cleanPayload(payload: any) {

        if (payload.source) {

            if (payload.source.Prices && payload.source.Prices.length > 0) {
                payload.source.Prices.forEach((price: any) => {
                    if (!price.OtherUnitRatio) delete price.OtherUnitRatio;
                    if (!price.SecondaryUnitRatio) delete price.SecondaryUnitRatio;
                });
            }

            if (payload.source.Suppliers && payload.source.Suppliers.length > 0) {
                payload.source.Suppliers.forEach((supplier: any) => {
                    supplier.Variations.forEach((variation: any) => {
                        if (!variation.OtherUnitRatio) delete variation.OtherUnitRatio;
                        if (!variation.SecondaryUnitRatio) delete variation.SecondaryUnitRatio;
                    });
                });
            }

        }

        return payload;
    }

    updateTotRows() {
        this.listFiltered = this.list.slice(0, this.totRows);
    }

    extendToAllSuppliers: boolean = false;
    searchMore() {
        this.loading$.next(true);
        // Forzatura per eliminare la ricerca Levenshtein.

        const selection: any = window.getSelection() ? window.getSelection()?.toString() : '';

        const higlightedText = selection && selection.length > 0 ? selection : this.searchText;

        const searchText = `%23${higlightedText}`; //this.searchText.trim().split(' ').length == 1 ? `%23${this.searchText}` : this.searchText;
        const merceId = this.merce && this.merce.Id ? this.merce.Id : -1;
        const supplierId = this.merce.Suppliers.length > 0 && !this.extendToAllSuppliers ? this.merce.Suppliers[0].Id : ''
        this.gestioneMerciService.checkLevenshteinDistance(merceId, searchText, supplierId).toPromise()
            .then((result: any) => {
                this.list = result;
                this.list.forEach(item => {
                    item.whatAction = this.whatAction(item);
                });
                console.log(result);
                this.updateTotRows();
            })
            .finally(() => {
                this.loading$.next(false);
            });
    }

    async editMerce(item: any) {
        const merce = await this.gestioneMerciService.getSingleGoods(item.Id).toPromise();
        const dialogRef = this.dialog.open(EditMerciComponent, {
            data: {
                merce: merce
            },
            width: '100%',
            height: '100%'
        });
    }
}
