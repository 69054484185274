import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map, debounceTime, tap, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

// Services
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { LayoutConfigService } from '@app/core/_base/layout';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

// Material
import { MatDialog } from '@angular/material/dialog';

import { InserimentoOrdiniSemplificatiPopupComponent } from '@app/views/pages/magazzino/ordini-semplificati/inserimento-ordini-popup/inserimento-ordini-popup.component';
import { EditNotesComponent } from '@app/views/pages/magazzino/components/edit-notes/edit-notes.component';

// Utilities
import * as _ from 'lodash';
import * as util from '@app/core/services/utilityfunctions';
declare var $: any;

enum COLUMNS_INDEX {
    POSITION = 0,
    CATEGORIA = 1,
    CODICE = 2,
    PRODOTTO = 3,
    QUANTITA_UNITA_ORDINE = 4,
    UNITA_ORDINE = 5,
    QUANTITA_UNITA_MAGAZZINO = 6,
    GIACENZE = 7,
    UNITA_MAGAZZINO = 8,
    QUANTITA_UNITA_BASE = 9,
    UNITA_BASE = 10,
    ACTIONS = 11,
    ID = 12
}

@Component({
    selector: 'kt-inserimento-ordini',
    templateUrl: './inserimento-ordini.component.html',
    styleUrls: ['./inserimento-ordini.component.scss', '../../common-styles.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InserimentoOrdiniSemplificatiComponent implements OnInit {

    @ViewChild('ProductOrCode', { static: true }) ProductOrCode!: ElementRef;
    @ViewChild('SogliaVariazionePrezzoPerc', { static: true }) SogliaVariazionePrezzoPerc!: ElementRef;
    currentConfig: any;
    referral!: string;
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    dataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    // Filtri
    listaCategorie: any;
    listaPosizioni: any;
    categoriaFiltro: any = '';
    posizioneFiltro: string = '';
    productOrCode!: string;
    hidePricesZero: boolean = false;
    qtyHigherZero: boolean = false;
    groupByQty: boolean = true;
    hideBase: boolean = false;
    smallScreen: boolean = false;
    select2Categorie: string = '';
    // Tablella
    lista: any;
    table: any;
    locale!: string;
    // Input
    inputChanged: Subject<any> = new Subject<any>();
    filterChanged: Subject<any> = new Subject<any>();

    isAcquisto: boolean = false;
    itemId: any; // Modalità edit

    restoreMap = {};
    utility: any;

    hoverglassActive: boolean = false;
    enablePopup: boolean = false;

    mappaSelect: any = {};

    warehouseWorker!: Worker;

    switchGroupSubcription!: Subscription;
    needFilter: boolean = false;

    constructor(
        private magazzinoService: MagazzinoService,
        private translate: TranslateService,
        private layoutConfigService: LayoutConfigService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public staticCollectionsService: StaticCollectionsService,
        private ref: ChangeDetectorRef,
        public dialog: MatDialog,
        public roleService: RoleService,
        private gestioneMerciService: GestioneMerciService,
        private switchGroupService: SwitchGroupService
    ) {
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
        this.utility = util;

        this.filterChanged
            .pipe(
                debounceTime(300),
                //tap(() => this.filter(true))
                tap(() => {
                    $('#table').removeClass('hidden');
                    this.needFilter = true;
                    this.table.draw();
                })
            )
            .subscribe();

        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.goBack();
                }
            }
        );


        $.fn.dataTable.ext.search.push(
            (settings, data, dataIndex) => {

                let acceptRow: boolean = true;

                if (!this.lista || this.lista.length == 0) return true;

                if (!this.needFilter) return true;

                const rowItem = this.lista.find((f: any) => f.Id == data[12]);

                if (this.hidePricesZero) {
                    //const val = this.utility.parseNumber($(`#table tbody>tr:nth-child(${dataIndex})`).attr('data-price'), this.locale);
                    const val = rowItem && rowItem.Price ? this.utility.parseNumber(rowItem.Price) : 0;
                    if (!(val > 0)) {
                        acceptRow = false;
                    }
                }

                if (this.qtyHigherZero) {
                    //const val = this.utility.parseNumber($(`#table tbody>tr:nth-child(${dataIndex})`).attr('data-quantity'), this.locale);
                    const val = rowItem && rowItem.Quantity ? this.utility.parseNumber(rowItem.Quantity) : 0;
                    if (!(val > 0)) {
                        acceptRow = false;
                    }
                }

                if (this.categoriaFiltro) {
                    if (data[1].toLowerCase() !== this.categoriaFiltro.toLowerCase()) {
                        acceptRow = false;
                    }
                }

                if (this.productOrCode) {
                    if (
                        data[2].toLowerCase().indexOf(this.productOrCode.toLowerCase()) < 0 &&
                        data[3].toLowerCase().indexOf(this.productOrCode.toLowerCase()) < 0
                    ) {
                        acceptRow = false;
                    }

                }

                return acceptRow;

            }
        );
    }

    goods: any;
    async ngOnInit() {

        if (typeof Worker !== 'undefined') {
            this.warehouseWorker = new Worker(new URL('../../../../../workers/warehouse.worker', import.meta.url));
            this.warehouseWorker.onmessage = (response: any) => {
                console.log(response);
                switch (response.data.operation) {
                    case 'updateRowCalculationSimpleOrders':

                        // Aggiorno l'attuale lista
                        if (response.data.indexLista < this.lista.length) {
                            this.lista[response.data.indexLista].QuantitaMagazzino = response.data.item.QuantitaMagazzino;
                            this.lista[response.data.indexLista].QuantitaUnitaBase = response.data.item.QuantitaUnitaBase;
                            this.lista[response.data.indexLista].Totale = response.data.item.Totale;
                        }

                        this.ref.detectChanges();
                        break;
                }
            };
        }

        this.currentConfig = this.magazzinoService.getCurrentConfig();

        console.log('this.currentConfig', this.currentConfig);

        this.activatedRoute.queryParams.subscribe(async (params: any) => {
            //console.log(params);
            this.referral = params.referral ? params.referral : '/';

            if (!this.currentConfig) {
                this.router.navigate([`${this.referral}`], { queryParams: {} });
                return;
            }

            this.itemId = params.itemId;

            this.loading$.next(true);
            let filter = {
                // 	FBType: 6, //NON CI VUOLE IL FILTRO SUL TIPO DI MERCE NEGLI ORDINI
                EnableState: 'yes'

            }
            if (this.currentConfig) {
                filter['SupplierIds'] = [this.currentConfig.body.SupplierId];
                filter['OnlyVisibleForCompany'] = true;
                filter['Companies'] = [];
                filter['Companies'].push(this.currentConfig.body.Company);
            }
            this.goods = await this.gestioneMerciService.getGoods(filter, true, true).toPromise();
            this.loading$.next(false);

            this.refreshRows();

        });

        $.fn.dataTable.ext.order['dom-text-numeric'] = (settings, col) => {
            return this.table.column(col, { order: 'index' }).nodes().map(function (td, i) {
                var content: any = $(td).html();
                console.log(content);
                if (content.indexOf('<input') >= 0) {
                    content = $('input', td).val();
                } else if (content.indexOf('<!--') >= 0 && content.indexOf('-->') > 0) {
                    content = content.substring(content.indexOf('-->') + 3);
                    content = content.replace('.', '').replace(',', '.');
                }
                console.log(content);
                return Number(content);
            });
        }

        $.extend((<any>$.fn).dataTableExt.oSort, {
            "input-num-desc": (a, b) => {
                const elementA = this.createElementFromHTML(a);
                const valueA = $(elementA).attr('ng-reflect-model') ? Number($(elementA).attr('ng-reflect-model')) : 0;
                const elementB = this.createElementFromHTML(b);
                const valueB = $(elementB).attr('ng-reflect-model') ? Number($(elementB).attr('ng-reflect-model')) : 0;
                return ((valueA < valueB) ? 1 : ((valueA > valueB) ? -1 : 0));
            },
            "input-num-asc": (a, b) => {
                const elementA = this.createElementFromHTML(a);
                const valueA = $(elementA).attr('ng-reflect-model') ? Number($(elementA).attr('ng-reflect-model')) : 0;
                const elementB = this.createElementFromHTML(b);
                const valueB = $(elementB).attr('ng-reflect-model') ? Number($(elementB).attr('ng-reflect-model')) : 0;
                return ((valueA < valueB) ? -1 : ((valueA > valueB) ? 1 : 0));
            }
        });
    }

    ngOnDestroy() {
        this.switchGroupSubcription.unsubscribe();
        this.lista = undefined;
    }

    clearFilters() {
        this.hidePricesZero = false;
        this.qtyHigherZero = false;
        this.productOrCode = '';
        this.categoriaFiltro = '';
        this.posizioneFiltro = '';
        this.groupByQty = true;
        this.hideBase = true;
        this.needFilter = true;
        this.select2Categorie = '';
        $('#table').addClass('hidden');
        this.table.draw();
    }

    getRows(results: any) {
        let needFilter = false;
        if (results.length == 0) {
            //TODO --> INSERIRE POPUP CON MESSAGGIO "Nessuna merce disponibile"
            const _title: string = this.translate.instant('MAGAZZINO.ORDINI_SEMPLIFICATI.TITLE');
            const _description: string = this.translate.instant('MAGAZZINO.ORDINI_SEMPLIFICATI.NESSUNA_MERCE');
            const _yesButton: string = this.translate.instant('COMMONS.OK');
            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, '', _yesButton);
            dialogRef.afterClosed().subscribe((confirm: any) => {
                this.router.navigate([`${this.referral}`], { queryParams: {} });
            });
            return false;

        } else {

            this.listaCategorie = [];
            this.listaPosizioni = [];
            //console.log(results, this.currentConfig.prePop);
            results.forEach((item: any) => {
                let exists = this.listaCategorie.find(category => category.Id === item.CategoryId);
                if (!exists) {
                    this.listaCategorie.push({
                        Id: item.CategoryId,
                        Name: item.Category
                    });
                }

                exists = this.listaPosizioni.find(position => position === item.Position);
                if (!exists) {
                    this.listaPosizioni.push(item.Position);
                }

                if (this.currentConfig.prePop) {
                    const prepop = this.currentConfig.prePop.Items.find(prepop => prepop.Id.toString() === item.Id.toString());
                    if (prepop) {

                        //console.log('PREOP', prepop, item);

                        let qOrder = parseFloat(prepop.Quantity.toString()) ? parseFloat(prepop.Quantity.toString()) : 0;
                        item.QuantitaMagazzino = qOrder * parseFloat(item.OrderUnitRatio.toString());
                        item.QuantitaUnitaBase = parseFloat(item.QuantitaMagazzino.toString()) * parseFloat(item.SecondaryUnitRatio.toString());

                        item.Price = parseFloat(prepop.Price.toString()) ? parseFloat(prepop.Price.toString()) : parseFloat(item.Price.toString());
                        item.Totale = parseFloat(qOrder.toString()) * parseFloat(item.Price.toString()) * parseFloat(item.OrderUnitRatio.toString());

                        //this.qtyHigherZero = true;
                        //this.groupByQty = true;
                        //this.hideBase = true;
                        //this.needFilter = true;

                        //console.log(item);
                    }
                } else {
                    item.QuantitaMagazzino = undefined;
                    item.QuantitaUnitaBase = undefined;
                    item.Quantity = undefined;
                }

                //item.Price = util.formatNumber(item.Price, this.locale, 3);

                //item.InitialPrice = item.Price;
                item['CostCenter'] = this.currentConfig.body.CostCenterId ? this.currentConfig.body.CostCenterId : undefined;
            });
            this.lista = results;
            this.lista.forEach((element: any) => {
                element.UniqueId = element.Category + "|" + element.Id + "|" + element.Code;
            });

            this.addNewEmptyIngredient();

            //if (needFilter) this.filter(false);

            return true;

        }

    }

    populate() {
        this.loading$.next(true);
        // this.ref.detectChanges();

        // Nel body aggiungere SearchText
        if (this.productOrCode) {
            this.currentConfig.body['SearchText'] = this.productOrCode;
        }

        // if (this.hidePricesZero) {
        this.currentConfig.body['hidePricesZero'] = this.hidePricesZero;
        // }

        this.magazzinoService.populate(this.currentConfig.body, true, true).subscribe(
            (results: any) => {
                console.log('populate', results);
                this.loading$.next(false);
                if (this.getRows(results)) {
                    //this.filter(); // aggiungo il filtro prima di far vedere le righe
                    this.initDataTable();
                }

                setTimeout(() => {
                    this.select2Categorie = '';
                    this.ref.detectChanges();
                }, 1000);
            }, (error: any) => {
                console.log(error);
                this.loading$.next(false);
                this.router.navigate([`${this.referral}`], { queryParams: {} });
            }
        )

    }

    refreshRows() {
        // Se sono in modifica
        if (this.itemId) {
            if (this.getRows(this.currentConfig.items)) {
                this.initDataTable();
                this.loading$.next(false);
                this.editPopup(this.currentConfig.body);
            }
        } else {
            this.populate();
        }
    }

    createElementFromHTML(htmlString) {
        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();

        // Change this to div.childNodes to support multiple top-level nodes
        return div.firstChild;
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        $('#productOrCode').keydown((event: any) => {
            const keyCode = event.which;
            if (event.code === 'Tab' || (event.code === 'Enter' || event.code == 'NumpadEnter')) {
                event.preventDefault();
                event.stopPropagation();
                //$('.input_quantita').first().select();
                //$('.input_quantita').first().focus();
                let element = $('.td_quantita').first();
                // this.triggerClickToShowInput(element);
                element.select();
                element.focus();
            }
        });
    }

    checkHideColumns(adjus: boolean = true) {
        let colStock = this.table.column(COLUMNS_INDEX.GIACENZE);

        if (this.groupByQty) {
            if (colStock) colStock.visible(false);
        } else {
            if (colStock) colStock.visible(true);
        }

        let colQtaUnitaBase = this.table.column(COLUMNS_INDEX.QUANTITA_UNITA_BASE);
        let colUnita = this.table.column(COLUMNS_INDEX.UNITA_BASE);

        if (this.hideBase) {
            if (colQtaUnitaBase) colQtaUnitaBase.visible(false);
            if (colUnita) colUnita.visible(false);
        } else {
            if (colQtaUnitaBase) colQtaUnitaBase.visible(true);
            if (colUnita) colUnita.visible(true);
        }
        if (adjus) this.table.columns.adjust().draw();

        this.ref.detectChanges();
    }

    performSmallScreen() {
        this.table.columns([COLUMNS_INDEX.CATEGORIA, COLUMNS_INDEX.CODICE]).visible(!this.smallScreen);
        this.ref.detectChanges();
    }

    focusInput(event: any) {
        $(event.target).focus();
        $(event.target).select();
    }

    initDataTable(setFocus: boolean = true) {
        this.hoverglassActive = true;
        if ($.fn.dataTable.isDataTable('#table')) {
            $('#table').DataTable().destroy();
        }
        setTimeout(() => {
            let dtOptions: any = {
                initComplete: () => {
                    this.hoverglassActive = false;
                },
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: true,
                dom: 'lrtip',
                ordering: true,
                orderCellsTop: true,
                scrollY: 'calc(100vh - 400px)',
                scrollCollapse: true,
                columnDefs: [
                    { targets: [COLUMNS_INDEX.POSITION], visible: false, data: 'pos' },

                    { targets: [COLUMNS_INDEX.CATEGORIA], width: '100px', className: "pre word-break" }, // Categoria
                    { targets: [COLUMNS_INDEX.CODICE], width: '70px' }, // Codice
                    { targets: [COLUMNS_INDEX.QUANTITA_UNITA_BASE, COLUMNS_INDEX.QUANTITA_UNITA_MAGAZZINO, COLUMNS_INDEX.QUANTITA_UNITA_ORDINE], width: '60px' }, // Quantità Unità Base, Quantità Unità Magazzino, Quantità Unità Ordine
                    { targets: [COLUMNS_INDEX.UNITA_BASE, COLUMNS_INDEX.UNITA_MAGAZZINO, COLUMNS_INDEX.UNITA_ORDINE], orderable: false, width: '60px' }, // Unità Base, Unità Magazzino, Unità Ordine

                    { targets: [COLUMNS_INDEX.GIACENZE], width: '80px' }, // Giacenze
                    { targets: [COLUMNS_INDEX.QUANTITA_UNITA_ORDINE], orderable: true, orderDataType: 'dom-text-numeric' }, // QUANTITA_UNITA_ORDINE

                    { targets: [COLUMNS_INDEX.ACTIONS], orderable: false, width: '45px' }, // Actions
                    { targets: [COLUMNS_INDEX.ID], visible: false } // Id
                ],
                rowReorder: {
                    dataSrc: 'pos'
                },
                //aaSorting: [[COLUMNS_INDEX.POSITION, 'asc'], [COLUMNS_INDEX.CATEGORIA, 'asc']],
                aaSorting: [],
                autoWidth: false
            };
            this.table = $('#table').DataTable(dtOptions);

            this.table.on('row-reorder', (e, diff, edit) => {
                var result = 'Reorder started on row: ' + edit.triggerRow.data()[1] + '<br>';
                for (var i = 0, ien = diff.length; i < ien; i++) {
                    const from = diff[i].oldData;
                    const to = diff[i].newData;
                    let index = this.lista.findIndex((i: any) => i.pos == from);
                    if (index >= 0) this.lista[index].pos = to;
                }
            });

            $('#table tbody input').off();
            $('#table tbody input').on({
                keydown: (event: KeyboardEvent) => {
                    if (event.code == 'Tab') {
                        setTimeout(() => {
                            $(event.target).closest('tr').next('tr').find('input').focus();
                            $(event.target).closest('tr').next('tr').find('input').select();
                        }, 100);

                    } else if (event.code === 'Enter' || event.code == 'NumpadEnter') {
                        event.preventDefault();
                        event.stopPropagation();
                        $('#productOrCode').focus();
                        $('#productOrCode').select();
                    }
                }
            });

            this.checkHideColumns();

            this.ref.detach();
            setTimeout(() => {
                $('#table tbody>tr:first-child .input_quantita').focus()
                $('#table tbody>tr:first-child .input_quantita').select()
                this.ref.detectChanges();
                //this.table.draw();
            }, 2000);

            setTimeout(() => {
                $('#productOrCode').focus();
                $('#productOrCode').select();
            }, 250);

        }, 250);

    }

    indexFromInfiniteScroll: number = 0;
    fetchValueInfiniteScroll: number = 50;
    onScrollDown() {
        //const nextItems = this.fetchNext();
        //this.listaInfiniteScroll = this.listaInfiniteScroll.concat(nextItems);
        this.ref.detectChanges();
    }


    filterPriceZero() {
        this.ref.detectChanges();
        $('#table').removeClass('hidden');
        this.needFilter = true;
        this.table.draw();
    }

    tmpLista: any;
    filterSavedDate() {
        this.ref.detectChanges();
        $('#table').removeClass('hidden');
        this.needFilter = true;
        this.table.draw();
    }

    onFilterChange(event: any) {
        if (event.code === 'Enter' || event.code == 'NumpadEnter') {
            this.filterChanged.next(true);
            setTimeout(() => {
                $('table tbody>tr:first-child input').focus();
                $('table tbody>tr:first-child input').select();
            }, 1000);
        }
    }

    onInputChange(item: any) {
        this.warehouseWorker.postMessage({
            operation: 'updateRowCalculationSimpleOrders',
            item: item,
            lista: this.lista,
            listaFiltered: this.lista,
            locale: this.locale
        });
    }

    getSumQty() {
        let tot: number = 0;
        if (this.lista && this.lista.length > 0) {
            this.lista.forEach(item => {
                if (item.Quantity) {
                    tot += util.parseNumber(item.Quantity, this.locale)
                }
            });
        }
        return tot;
    }

    getSumTotale() {
        let tot: number = 0;
        if (this.lista && this.lista.length > 0) {
            this.lista.forEach(item => {
                if (item.Totale) {
                    tot += util.parseNumber(item.Totale, this.locale)
                }
            });
        }
        return tot;
    }

    IsSaveDisabled() {
        //console.log('IsSaveDisabled');
        let disabled = true;
        if (this.lista) {
            this.lista.forEach((x: any) => {
                if (x.flag || x.QuantitaUnitaBase) {
                    disabled = false;
                    return;
                }
            });
        }

        return disabled;
    }

    cleanRow(item: any) {
        item.QuantitaUnitaBase = undefined;
        item.QuantitaMagazzino = undefined;
        item.Sconto = undefined;
        item.Totale = undefined;
        item.Quantity = undefined;
        //item.Price = item.InitialPrice;
        this.ref.detectChanges();
    }

    cleanAllRow() {

        const _title: string = this.translate.instant('MAGAZZINO.ORDINI_SEMPLIFICATI.INSERIMENTO_ORDINI.CLEAR_ALL.CLEAR_ALL_TITLE');
        const _description: string = this.translate.instant('MAGAZZINO.ORDINI_SEMPLIFICATI.INSERIMENTO_ORDINI.CLEAR_ALL.CLEAR_ALL_SUBTITLE');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('COMMONS.SAVE');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((expand: any) => {
            if (expand) {
                this.lista.forEach((item: any) => {
                    this.cleanRow(item);
                })
                console.log('olè');
                this.ref.detectChanges();
            }
        });

    }

    insertNote(item: any) {
        const dialogRef = this.dialog.open(EditNotesComponent, {
            data: { item },
            width: '600px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            console.log(res);
            if (res.success && res.Note) {
                item.Note = res.Note;
                this.ref.detectChanges();
            }

        });
    }

    saveAsk() {
        const _title: string = this.translate.instant('MAGAZZINO.ORDINI_SEMPLIFICATI.INSERIMENTO_ORDINI.TITLE');
        const _description: string = this.translate.instant('MAGAZZINO.ORDINI_SEMPLIFICATI.INSERIMENTO_ORDINI.INSERIMENTO_ASK');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('COMMONS.SAVE');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((expand: any) => {
            if (expand) {
                this.save();
                this.enablePopup = true;
                this.ref.detectChanges();
            }
        });
    }


    prepareData() {
        let body: any[] = [];

        const productList = this.lista.filter((item: any) => {
            return util.parseNumber(item.QuantitaMagazzino, this.locale) > 0 || item.flag;
        });

        productList.forEach((item: any) => {
            console.log(item);

            let itemTmp = {
                Company: this.currentConfig.body.Company,
                SupplierId: Number(item.BestSupplierId),
                OrderId: this.currentConfig.body.OrderId ? this.currentConfig.body.OrderId : null,
                Date: this.currentConfig.body.Date,
                GoodId: Number(item.Id),
                Quantity: item.Quantity ? util.parseNumber(item.Quantity, this.locale) : 0,
                Price: util.parseNumber(item.Price, this.locale),
                Code: item.BestSupplierCode,
                Description: this.currentConfig.body.Description,
                Note: item.Note,
            };
            if (item.SavedSupplierId) {
                itemTmp.SupplierId = Number(item.SavedSupplierId);
            }
            var costCenter;
            if (this.itemId) {
                costCenter = this.staticCollectionsService.costCenters$.find((cc: any) => (cc.Id && item.CostCenterId) ? cc.Id.toString() === item.CostCenterId.toString() : false);
            } else {
                costCenter = this.staticCollectionsService.costCenters$.find((cc: any) => (cc.Id && item.CostCenter) ? cc.Id.toString() === item.CostCenter.toString() : false);
            }
            //var costCenter = this.staticCollectionsService.costCenters$.find((cc: any) => cc.Id.toString() === item.CostCenterId.toString());
            if (costCenter && costCenter.$id) delete costCenter.$id;
            if (costCenter && !costCenter.Name) {
                costCenter.Name = '---';
            }
            itemTmp['CostCenter'] = costCenter;


            if (this.itemId) {
                itemTmp['Id'] = this.itemId;
            }

            body.push(itemTmp);
        });

        body.forEach((element: any) => {
            element['IsSimplified'] = true;
            element['IsVisible'] = this.currentConfig.body['IsVisible'];
        });

        return body;
    }

    save() {
        const body = this.prepareData();

        const productList = this.lista.filter((item: any) => {
            return util.parseNumber(item.QuantitaMagazzino, this.locale) > 0 || item.flag;
        });

        if (this.itemId) {
            this.magazzinoService.update('Order', body).toPromise()
                .then((result: any) => {
                    console.log(result);
                    let message: string = '';
                    if (result.Msg === 'Ok') {
                        message = this.translate.instant('MAGAZZINO.MODIFICHE.MODIFICA_OK');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

                        productList.forEach((element: any) => {
                            this.cleanRow(element);
                        });
                        this.restoreMap = {};
                    } else {
                        message = this.translate.instant('MAGAZZINO.MODIFICHE.MODIFICA_ERROR');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                    }
                })
                .finally(() => {
                    this.loading$.next(false);
                    this.ref.detectChanges();
                });
        } else {
            this.magazzinoService.save('Order', body).toPromise()
                .then((result: any) => {
                    // console.log(result);
                    let message: string = '';
                    if (result.Msg === 'Ok') {
                        message = this.translate.instant('MAGAZZINO.INSERIMENTI.INSERIMENTO_OK');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                        productList.forEach((element: any) => {
                            this.cleanRow(element);
                        });
                        this.restoreMap = {};
                    } else {
                        message = this.translate.instant('MAGAZZINO.INSERIMENTI.INSERIMENTO_ERROR');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                    }
                })
                .finally(() => {
                    this.loading$.next(false);
                    this.ref.detectChanges();
                });
        }

    }

    getSaveCaption() {
        return this.translate.instant('MAGAZZINO.ORDINI_SEMPLIFICATI.INSERIMENTO_ORDINI.SALVA');
    }

    getPopupCaption() {
        return this.translate.instant('MAGAZZINO.ORDINI_SEMPLIFICATI.TOOLBAR.NEW');
    }

    getGoBackCaption() {
        return this.translate.instant('MAGAZZINO.ORDINI_SEMPLIFICATI.TITLE');
    }

    goBack() {
        this.router.navigate([`${this.referral}`], { queryParams: {} });
    }

    openPopup() {
        const newEndpoint = {};
        this.editPopup(newEndpoint, true);
    }

    editPopup(endpoint: any, forceReload: boolean = false) {
        const dialogRef = this.dialog.open(InserimentoOrdiniSemplificatiPopupComponent, {
            data: { endpoint },
            width: '600px'

        });
        dialogRef.afterClosed().subscribe((res: any) => {
            /*
            if (_.isEmpty(res) || !res) {
              return;
            }
            */

            if (res && res.success && res.body) {
                this.magazzinoService.setCurrentConfig(res);
                this.currentConfig = res;
                if (forceReload || !this.itemId) {
                    this.lista = [];
                    $('#table').DataTable().destroy();
                    this.populate();
                }
                this.magazzinoService.summaryUpdated$.next(true);
                this.ref.detectChanges();

            } else if (this.itemId) {
                this.magazzinoService.summaryUpdated$.next(true);
                this.ref.detectChanges();
            } else {
                return;
            }

            $('#productOrCode').focus();
            $('#productOrCode').select();

        });
    }

    sortType: string = 'cat_cod';
    sort() {
        switch (this.sortType) {
            case 'cat_cod':
                this.table.columns([0, 1]).order('asc').draw();
                break;
            case 'cat_prod':
                this.table.columns([0, 2]).order('asc').draw();
                break;
        }
    }

    /***************************************************************************************************** */
    prodottiFiltrati!: Observable<any[]>;
    autocompleteDescription = new FormControl();
    showAutocompleteDescription(item: any, $event: any) {
        if (this.mappaSelect[item.Id]) return;

        const index = $($event.target).closest('tr').index();

        item['IndexSelect'] = index;

        this.mappaSelect = {};
        this.mappaSelect[item.IndexSelect] = true;

        this.prodottiFiltrati = this.autocompleteDescription.valueChanges
            .pipe(
                startWith(''),
                map(value => this.filterGoods(value)),
                tap(() => this.ref.detectChanges()) // Serve per aggiornare l'interfaccia, perchè cè il detach
            );

        const model = this.goods;

        const value = model.find((prodotto: any) => {
            return !item.Id || item.Id.toString() === prodotto.Id.toString();
        });

        if (Number(item.Id) > 0) {
            this.autocompleteDescription.setValue(value);
        } else {
            this.autocompleteDescription.setValue('');
        }

        setTimeout(() => {
            //(<any>document).getElementById("matAutoCompleteInput").select();
            $('#matAutoCompleteInput').select();
            this.ref.detectChanges();
        }, 100);
        this.ref.detectChanges();
    }
    /***************************************************************************************************** */
    private filterGoods(value: any) {
        const model = this.goods;
        if (!value || value.constructor !== String) {
            return model;
        } else {
            return model.filter((prodotto: any) => {
                return prodotto.Name.toLowerCase().includes(value.toLowerCase())
            });
        }
    }
    /***************************************************************************************************** */
    addNewEmptyIngredient() {
        // Se per caso ho già un ultima riga vuota non la metto
        if (this.lista &&
            this.lista[this.lista.length - 1] &&
            this.lista[this.lista.length - 1].Name.length === 0) return;

        let maxPosition = 0;
        this.lista.forEach((element: any) => {
            maxPosition = element.pos > maxPosition ? element.pos : maxPosition;
        });

        this.lista.push({
            pos: maxPosition + 1,
            Category: '',
            Code: '',
            AlternateName: '',
            Name: '',
            QuantitaUnitaBase: 0,
            QuantitaMagazzino: 0,
            Stock: 0,
            Unit: '',
            SecondaryUnit: '',
            SecondaryUnitRatio: 1,
            Quantity: 0,
            OrderUnit: '',
            OrderUnitRatio: 1,
            Price: 0,
            Totale: 0,
            Position: '',
            Note: ''
        });
    }
    /***************************************************************************************************** */
    /**
     * Selezione nuovo ingrediente 
     * @param event 
     * @param ingredient 
     */
    selectNewIngredient(event: any, ingredient: any) {
        // const row = $('#select_description').closest('tr').index() + 1;

        let newIngredient = event.option.value;

        console.log('newIngredient', newIngredient);

        const index = this.lista.findIndex((obj => obj.UniqueId == ingredient.UniqueId));

        let maxPosition = 0;
        this.lista.forEach((element: any) => {
            maxPosition = element.pos > maxPosition ? element.pos : maxPosition;
        });

        // Sostituisco l'attuale ingrediente con quello nuovo
        this.lista[index].pos = maxPosition + 1;
        this.lista[index].Category = newIngredient.Category;
        this.lista[index].CategoryId = newIngredient.CategoryId;
        this.lista[index].Code = newIngredient.Code;
        this.lista[index].Name = newIngredient.Name;
        this.lista[index].AlternateName = newIngredient.Name;

        /* DA VERIFICARE */
        this.lista[index].Unit = this.getBaseUnit(newIngredient).Name;
        this.lista[index].UnitId = this.getBaseUnit(newIngredient).Id;
        this.lista[index].SecondaryUnit = newIngredient.SecondaryUnit;
        this.lista[index].SecondaryUnitId = newIngredient.SecondaryUnitId;
        this.lista[index].SecondaryUnitRatio = newIngredient.SecondaryUnitRatio;
        this.lista[index].OrderUnit = newIngredient.OtherUnit;
        this.lista[index].OrderUnitId = newIngredient.OtherUnitId;
        this.lista[index].OrderUnitRatio = newIngredient.OtherUnitRatio;
        /*****************/

        this.lista[index].Id = newIngredient.Id;
        this.lista[index].Price = newIngredient.Price;
        this.lista[index].UniqueId = newIngredient.Category + "|" + newIngredient.Id + "|" + newIngredient.Code

        // Se ho utilizzato l'ultima riga vuota allora ne aggiungo un altra.      
        this.addNewEmptyIngredient();

        // console.log(this.lista);
        this.mappaSelect = {};
        // $('#table').DataTable().destroy();
        // setTimeout(() => {
        this.initDataTable();
        setTimeout(() => {
            // $(`#table tr:nth-child(${row}) .qty_input`).select();
            var $scrollBody = $(this.table.table().node()).parent();
            $scrollBody.scrollTop($scrollBody.get(0).scrollHeight);
        }, 1000);
        // }, 1000);
    }
    /***************************************************************************************************** */
    /**
     * Gets base unit according to selected unit
     * @param newIngredient 
     */
    getBaseUnit(newIngredient: any): any {

        const unit = this.staticCollectionsService.unit$.find((unit: any) => unit.Id === newIngredient.UnitId);
        const baseUnit = this.staticCollectionsService.unit$.find((u: any) => u.Id === unit.MainUnitId)
        let u: any = unit;
        if (baseUnit.Name.toLowerCase() === 'kg' && unit.Name.toLowerCase() !== 'g') {
            u = this.staticCollectionsService.unit$.find((u: any) => u.Name.toLowerCase() === 'g');
        } else if (baseUnit.Name.toLowerCase() === 'lt' && unit.Name.toLowerCase() !== 'ml') {
            u = this.staticCollectionsService.unit$.find((u: any) => u.Name.toLowerCase() === 'ml');
        } else {
            u = unit;
        }

        return {
            Id: u.Id,
            Name: u.Name,
            BaseId: u.MainUnitId,
            //BaseUnitName: u.Name,
            A: u.A,
            B: u.B
        };

    }
    /***************************************************************************************************** */
    displayFn(product: any): string {
        if (!product) return '';
        return product.Name;
    }

    changeCategory() {
        $('#table').removeClass('hidden');
        this.needFilter = true;
        this.table.draw();
    }

    getTranslate(string: string) {
        return this.translate.instant(string);
    }

    showActionTooltip(event: MouseEvent, show: boolean, tooltip: string = '') {
        if (show && !tooltip) return;

        if (show) {
            $('#tooltip').text(tooltip);
            const positionX = event.clientX - 100;
            const positionY = event.clientY - 50;
            $('#tooltip').css('top', `${positionY}px`);
            $('#tooltip').css('left', `${positionX}px`);
            $('#tooltip').show();
        } else {
            $('#tooltip').text();
            $('#tooltip').hide();
        }

        setTimeout(() => {
            this.ref.detectChanges();
        }, 10);
    }
}
