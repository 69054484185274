import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var $: any;

@Component({
	selector: 'm-export-pdf-dialog',
	templateUrl: './export-pdf-dialog.component.html'
})
export class ExportDialogComponent implements OnInit {
	viewLoading: boolean = false;
	tTitle: string = '';
	ckExplodeRows: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<ExportDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	ngOnInit() {
        //setTimeout(() => {
        //    $('#tTitle').focus();
        //}, 1000);
	}

	ngAfterViewInit() {
		$('#tTitle').focus();
		$('#tTitle').select();
	}

	prepareResponse(confirm: boolean) {
		let response: any = {
			confirm: confirm
		};
		if ( this.data.askTitle ) response['title'] = this.tTitle;
		if ( this.data.askExplodeRows ) response['explode'] = this.ckExplodeRows;

		response['table'] = {
			headerRows: 2
		};
		return response;
	}

    manageKeyDown(event: KeyboardEvent) {
        if(event.code == 'Enter' || event.code == 'NumpadEnter') {
            this.onYesClick();
        }
    }

	onYesClick(): void {
		this.viewLoading = true;
		this.dialogRef.close(this.prepareResponse(true));
	}

	onCloseClick(): void {
		this.dialogRef.close(undefined);
	}
}
