import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwitchGroupService } from '@app/core/_base/layout';

@Injectable({
  providedIn: 'root'
})
export class ContoEconomicoService {

  constructor(
    private http: HttpClient,
    private switchGroupService: SwitchGroupService
  ) { }

  getCE(payload: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(`${currentLogin.endpointURL}/api/PL/DataTable`, {
      filters: payload
    }, { headers: httpHeaders });
  }

  savePL(payload: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(`${currentLogin.endpointURL}/api/PL/Save`, payload, { headers: httpHeaders });
  }

  savePLMultiple(payload: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(`${currentLogin.endpointURL}/api/PL/SaveMultiple`, payload, { headers: httpHeaders });
  }

  deletePL(id: string) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.delete<any>(`${currentLogin.endpointURL}/api/PL/Delete?id=${id}`, { headers: httpHeaders });
  }

  updatePL(payload: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.put<any>(`${currentLogin.endpointURL}/api/PL/Update`, payload, { headers: httpHeaders });
  }

  sendPL(payload: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(`${currentLogin.endpointURL}/api/PL/Send`, payload, { headers: httpHeaders });
  }

  SplitOnTime(payload: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(`${currentLogin.endpointURL}/api/PL/SplitOnTime`, payload, { headers: httpHeaders });
  }

  SendFromPurchase(payload: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(`${currentLogin.endpointURL}/api/PL/SendFromPurchase`, payload, { headers: httpHeaders });
  }

  sendAll(payload: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(`${currentLogin.endpointURL}/api/PL/SendAll`, payload, { headers: httpHeaders });
  }

  ByCategory(payload: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(`${currentLogin.endpointURL}/api/PL/ByCategory`, payload, { headers: httpHeaders });
  }
}
