import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { MagazzinoService } from '@app/core/services/magazzino.service';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { FornitoriEditComponent } from '@app/views/pages/anagrafiche/fornitori/fornitori-edit/fornitori-edit.component';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';

@Component({
    selector: 'kt-send-inner-mail',
    templateUrl: './send-inner-mail.component.html',
    styleUrls: ['./send-inner-mail.component.scss']
})
export class SendInnerMailComponent implements OnInit {

    Company!: string;
    Supplier!: string;
    Destination!: string;
    Subject!: string;
    Object!: string;
    Signature!: string;
    sending: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private magazzinoService: MagazzinoService,
        private translationService: TranslationService,
        private staticCollectionsService: StaticCollectionsService,
        private translate: TranslateService,
        private layoutUtilsService: LayoutUtilsService,
        public dialog: MatDialog,
    ) { }

    ngOnInit() {
        console.log('this.data', this.data);
        if (this.data && this.data.mailInfo) {
            this.Company = this.data.mailInfo.Company;
            this.Supplier = this.data.mailInfo.Supplier;
            this.Destination = this.data.mailInfo.Destination;
            this.Subject = this.data.mailInfo.Subject;
            this.Object = this.data.mailInfo.Object;
            this.Signature = this.data.mailInfo.Signature;
        }

    }

    onSubmit() {
        this.sending = true;
        const body = {
            ListId: this.data.mailInfo.ListId,
            SupplierId: this.data.mailInfo.SupplierId,
            Destination: this.Destination,
            Subject: this.Subject,
            Body: this.Object,
            Signature: this.Signature
        };
        let lang = this.translationService.getSelectedLanguage();
        this.magazzinoService.sendOrderMail(body, lang)
            .subscribe(
                (result: any) => {
                    console.log(body);
                    this.sending = false;
                    this.dialogRef.close({ success: true });
                }, (error: any) => {
                    console.log(error);
                    this.sending = false;
                })
    }

    editSupplier() {
        const index = this.staticCollectionsService.fornitoriTutti$.findIndex((f: any) => f.Id == this.data.mailInfo.SupplierId);
        if (index < 0) return;

        let supplier = this.staticCollectionsService.fornitoriTutti$[index];
        supplier.SubjectType = 1;

        const dialogRef = this.dialog.open(FornitoriEditComponent, { data: { item: supplier }, width: '900px' });
        dialogRef.afterClosed().subscribe(async (res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            const _saveMessage = this.translate.instant('ANAGRAFICHE.FORNITORI.ACTION_EDIT.UPDATE_MESSAGE');
            this.layoutUtilsService.showActionNotification(_saveMessage, MessageType.Update);
            await this.staticCollectionsService.refreshFornitori();
        });
    }

}
