import { Injectable } from '@angular/core';
import { size } from 'lodash';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
declare var $: any;
import { environment, jsPDFfonts } from '../../../environments/environment';

import { jsPDF } from "jspdf";
import loadImage from 'image-promise';

@Injectable()
export class PDFService {

    //https://www.devlinpeck.com/tutorials/jspdf-custom-font
    constructor() {
        const callAddFont = (js: any) => {
            (<any>jsPDF.API).addFileToVFS('fa-solid-900-normal.ttf', jsPDFfonts.fontawesome5_fa_solid_900);
            (<any>jsPDF.API).addFont('fa-solid-900-normal.ttf', 'fa-solid-900', 'normal');
        };
        const events = jsPDF?.API?.events;
        if(jsPDF && jsPDF.API && jsPDF.API.events) {
            if (jsPDF.API.events.length > 0) {
                if(!Object.keys(jsPDF.API.events).includes('addFonts')) {
                    jsPDF.API.events.push(['addFonts', callAddFont.bind(jsPDF)]);
                }
            } else {
                jsPDF.API.events.push(['addFonts', callAddFont.bind(jsPDF)]);
            }
        }        

        window.pdfMake.vfs['fa-solid-900-Regular.ttf'] = this.addFontAwesome();
        window.pdfMake.vfs['fa-solid-900-Medium.ttf'] = this.addFontAwesome();
        window.pdfMake.vfs['fa-solid-900-Italic.ttf'] = this.addFontAwesome();
        window.pdfMake.vfs['fa-solid-900-Bold.ttf'] = this.addFontAwesome();

        window.pdfMake.fonts = {
            // Default font should still be available
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Medium.ttf',
                italics: 'Roboto-Italic.ttf',
                bolditalics: 'Roboto-Italic.ttf'
            },
            // Make sure you define all 4 components - normal, bold, italics, bolditalics - (even if they all point to the same font file)
            'Font Awesome 5 Free': {
                normal: 'fa-solid-900-Regular.ttf',
                bold: 'fa-solid-900-Bold.ttf',
                italics: 'fa-solid-900-Italics.ttf',
                bolditalics: 'fa-solid-900-Italics.ttf'
            }
        };
    }

    addFontAwesome() {
        return jsPDFfonts.fontawesome5_fa_solid_900
    }

    /***

    preference: any = {
        title: string,
        header: {
            export_title: string
            period: string
        },
        footer: {
            printed_by: string
        }
    }

    **/

    async getBase64ImageFromUrl(imageUrl) {
        var res = await fetch(imageUrl);
        var blob = await res.blob();

        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.addEventListener("load", function () {
                resolve(reader.result);
            }, false);

            reader.onerror = () => {
                return reject(this);
            };
            reader.readAsDataURL(blob);
        })
    }

    makePdf(preference: any, tableBody: any, widths: any = ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']) {
        const imageLogoB64 = $('#kt_header .logoimg').attr('src');
        const size = {
            width: $('#kt_header .logoimg').width(),
            height: $('#kt_header .logoimg').height()
        };
        this.performPdf(preference, tableBody, widths, imageLogoB64 ? imageLogoB64 : environment.pdfDefaultHeaderImage, size);
    }

    performPdf(preference: any, tableBody: any, widths: any, companyImageBase64: string, companyImageSize: any) {

        const documentDefinition = this.getDocDefinition(preference, widths, tableBody, companyImageBase64, companyImageSize);
        pdfMake.createPdf(documentDefinition).open();
    }

    async chartToPdf(canvas: any, title: string, legend: any = undefined, header: string = '') {
        const canvasImage = canvas.toDataURL('image/jpeg', 1.0);

        const max = { height: 210 - 17, width: 277 } // 297 - 10mm per lato
        const loadedImage = await loadImage(canvasImage);
        let height = loadedImage.height, width = loadedImage.width, src = loadedImage.src, ratio = loadedImage.height / loadedImage.width;
        if (height > max.height || width > max.width) {
            if (height > width) {
                height = max.height;
                width = height * (1 / ratio);
                // Making reciprocal of ratio because ration of height as width is no valid here needs width as height
            } else if (width > height) {
                width = max.width;
                height = width * ratio;
                // Ratio is valid here 
            }
        }

        const pdf = new jsPDF('l', 'mm', [297, 210])
        
        pdf.setFontSize(14);
        // stampo il grafico
        pdf.addImage(canvasImage, 'JPEG', 10, 17, width, height);
        // stampo il titolo
        pdf.text(title, 10, 10);

        // stampo l'header
        if(header) {
          pdf.setFontSize(12);
          pdf.text(header, 60, 10);
        }

        if (legend && legend.length > 0) {
            pdf.addPage();
            let row = 1;
            legend.forEach((item: any, index: number) => {

                // print icon
                pdf.setFont('fa-solid-900', 'normal');
                pdf.setFontSize(10);
                pdf.setTextColor('rgb(10, 187, 135)');
                pdf.text(String.fromCharCode(parseInt('0x' + item.unicode)), 10, (15 * (index + 1)) + (row * 5));

                row++;

                pdf.setFontSize(7);
                pdf.setFont('helvetica', 'normal');
                pdf.setTextColor('rgb(0, 0, 0)');
                const legend = item.value.map(l => l.label);
                var splitLegend = pdf.splitTextToSize(legend.join(' | '), 277); // 297 - 10mm per lato
                splitLegend.forEach((line: string) => {
                    pdf.text(line, 10, (15 * (index + 1)) + (row * 5));
                    row++;
                });

            });
        }

        pdf.save(`${title}.pdf`);
    }

    getDocDefinition(preference: any, widths: any = ['*', 'auto'], tableBody: any = undefined, companyImageBase64: string = '', companyImageSize: any = '') {
        const documentDefinition: any = {
            pageSize: 'A4',
            pageOrientation: preference.orientation ? preference.orientation : 'landscape',
            pageMargins: [30, 50, 30, 30],
            content: [
                { text: preference.title, style: 'documentTitle', alignment: 'center', margin: [0, 0, 0, 5] },
                {
                    layout: {
                        defaultBorder: false,
                        hLineColor: function (i, node) {
                            return '#dddddd';
                        },
                        vLineColor: function (i, node) {
                            return '#dddddd';
                        },
                    },
                    table: {
                        headerRows: preference.table.headerRows,
                        widths: widths,
                        body: tableBody
                    }
                }
            ],

            header: (currentPage, pageCount, pageSize) => {
                return [
                    {
                        columns: [
                            {
                                alignment: 'left',
                                width: '15%',
                                text: preference.header.export_title,
                                margin: [30, 20, 0, 10],
                                style: 'headerStyle'
                            },
                            {
                                alignment: 'center',
                                width: '70%',
                                text: preference.header.period ? preference.header.period : '',
                                margin: [0, 20, 0, 0],
                                style: 'headerStyle'
                            },
                            {
                                alignment: 'right',
                                fit: [companyImageSize.width / companyImageSize.height * 45 * 0.75 * ((preference.orientation && preference.orientation === 'portrait') ? 0.75 : 1), 45],
                                margin: [0, 5, 20, 0],
                                style: 'headerStyle',
                                image: companyImageBase64
                            }
                        ]
                    }
                ]
            },
            footer: (currentPage, pageCount) => {
                const today = new Date();
                const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
                return {
                    columns: [
                        {
                            alignment: 'left',
                            width: '33%',
                            text: preference.footer.printed_by,
                            style: 'footerStyle',
                        },
                        {
                            alignment: 'center',
                            width: '33%',
                            text: [`${preference.footer.page} `, { text: currentPage.toString() }, ` ${preference.footer.of} `, { text: pageCount.toString() }],
                            style: 'footerStyle',
                        },
                        {
                            alignment: 'right',
                            width: '33%',
                            text: today.toLocaleDateString(preference.language, options),
                            style: 'footerStyle',
                        }
                    ],
                    margin: [30, 5, 30, 0]
                }
            },

            styles: {
                // Per retrocompatibilità con export DATATABLE
                tableFooter: {
                    fontSize: 8,
                    bold: true,
                    color: 'white',
                    fillColor: '#2d4154'
                },
                tableHeader: {
                    fontSize: widths.length > 10 ? 6 : 9,
                    bold: true,
                    color: 'white',
                    fillColor: '#2d4154'
                },
                tableBodyOdd: {
                    fillColor: "#f3f3f3"
                },
                tableBodyEven: {
                },
                // NUOVI STYLES
                documentTitle: {
                    fontSize: 15,
                    bold: true,
                    color: 'black'
                },
                headerStyle: {
                    fontSize: 8,
                    bold: true,
                    color: 'black'
                },
                footerStyle: {
                    fontSize: 8,
                    bold: true,
                    color: 'black'
                },
                tableHeaderStyle: {
                    fontSize: widths.length > 10 ? 6 : 9,
                    bold: true,
                    color: 'black'
                },
                tableBodyStyle: {
                    fontSize: widths.length > 10 ? 6 : 9,
                    bold: false,
                    color: 'black'
                },
                tableBodySubStyle: {
                    fontSize: widths.length > 10 ? 6 : 7,
                    bold: false,
                    color: 'black'
                },
                tableHeaderStyleGreen: {
                    fontSize: widths.length > 10 ? 6 : 9,
                    bold: true,
                    color: '#0abb87'
                },
                tableBodyStyleGreen: {
                    fontSize: widths.length > 10 ? 6 : 9,
                    bold: false,
                    color: '#0abb87'
                },
                tableHeaderStyleRed: {
                    fontSize: widths.length > 10 ? 6 : 9,
                    bold: true,
                    color: '#fd397a'
                },
                tableBodyStyleRed: {
                    //fontSize: widths.length > 10 ? 6 : 9,
                    //bold: false,
                    color: '#fd397a'
                },
                tableBodyParent: {
                    fillColor: "#f3f3f3"
                },
                tableBodySubHeader: {
                    fillColor: '#f9f9f9'
                },
                tableBodyTotal: {
                    bold: true,
                    fillColor: '#F1E8C1'
                },
                fontAwesomeHeader: {
                    font: 'Font Awesome 5 Free',
                    color: '#ffffff',
                    fillColor: "#2d4154"
                },
                fontAwesome: {
                    font: 'Font Awesome 5 Free',
                    color: '#b0b0b0',
                },
                fontAwesomeOdd: {
                    font: 'Font Awesome 5 Free',
                    fillColor: "#f3f3f3",
                    color: '#b0b0b0',
                },
                tableBodyOddRight: {
                    fillColor: "#f3f3f3",
                    alignment: 'right'
                },
                tableBodyRight: {
                    alignment: 'right'
                },
            },
            defaultStyle: {
                fontSize: 9,
            },
        };
        return documentDefinition;
    }

    htmlToPdf(html: string, name: string) {
        return new Promise(resolve => {
            const doc = new jsPDF();
            doc.html(html, {
                callback: function (doc) {
                    // Save the PDF
                    doc.save(`${name}.pdf`);
                    resolve(true);
                },
                margin: [5, 5, 5, 5],
                x: 0,
                y: 0,
                width: 225, //target width in the PDF document
                windowWidth: 900 //window width in CSS pixels
            });
        });
    }


}	
