import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { catchError, map, take, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FiltriService } from '@app/core/services/filtri.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { TranslateService } from '@ngx-translate/core';
// Layout
import { LayoutConfigService } from '@app/core/_base/layout';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { PageEvent } from '@angular/material/paginator';
import { InserimentoInventariPopupComponent } from './inserimento-inventari-popup/inserimento-inventari-popup.component';
import { ColumnsSelectionPopupComponent } from '@app/views/partials/content/crud/columns-selection-popup/columns-selection-popup.component';
import { InserimentoType } from '@app/views/pages/magazzino/inserimento-comune/inserimento-comune.component';

import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { AziendeService } from '@app/core/services/aziende.service';
import { environment } from '@env/environment';
import * as util from '@app/core/services/utilityfunctions';
import { ScreenSizeService } from '@app/core/services/screen-size.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import moment from 'moment';
import { BloccoInventariComponent } from './blocco-inventari/blocco-inventari.component';

@Component({
    selector: 'kt-inventari',
    templateUrl: './inventari.component.html',
    styleUrls: ['./inventari.component.scss', '../common-styles.scss'],
})
export class InventariComponent implements OnInit, AfterViewInit, OnDestroy {

    lista: any;
    listaFiltered: any;
    listaSubscribtions!: Subscription
    companies: any;
    currentCompanyFilter!: string;
    locale!: string;

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    dataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    table: any;
    currentFilterCfg: any;

    switchGroupSubcription!: Subscription;
    filtriServiceSubscription!: Subscription;
    // staticCollectionsSubcription!: Subscription;

    pagination: any;
    columnsList: any = [];
    orderModes: any[] = [
        { Id: 'Code', Text: 'Code' },
        { Id: 'Name', Text: 'Name' },
        { Id: 'CategoryAndName', Text: 'Category And Name' }

    ];
    selectedOrderMode: any;

    @ViewChild('ProductOrCode', { static: true }) ProductOrCode!: ElementRef;

    utility: any;
    isMobile: boolean = false;

    constructor(
        private filtriService: FiltriService,
        private magazzinoService: MagazzinoService,
        private gestioneMerciService: GestioneMerciService,
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private layoutConfigService: LayoutConfigService,
        private excelService: ExcelService,
        private pdfService: PDFService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private switchGroupService: SwitchGroupService,
        public dialog: MatDialog,
        public staticCollectionsService: StaticCollectionsService,
        public aziendeSrv: AziendeService,
        private router: Router,
        public roleService: RoleService,
        private screenSizeService: ScreenSizeService,
        private activatedRoute: ActivatedRoute
    ) {
        this.utility = util;

        this.screenSizeService.ScreenSize.subscribe((res: number) => {
            this.isMobile = res <= 1023;
        });
    }

    /**
     * After view init
     */
    async ngAfterViewInit() {
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });

        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.loading$.next(true);
                    //console.log('performSwitchObsv', event);					
                    this.clearFilters(false);
                    $('#table').DataTable().clear().destroy();
                    this.lista = [];
                    this.listaFiltered = [];
                    //this.ref.detectChanges();
                    this.loading$.next(false);
                }
            }
        );

        await this.staticCollectionsService.fillStaticCollection(false);
        this.loading$.next(false);
        //this.initFiltri();

        this.activatedRoute.queryParams.subscribe((params: any) => {
            setTimeout(() => {
                this.initFiltri();
                if (params.filters) {
                    try {
                        const filters = JSON.parse(params.filters);
                        if (!this.currentFilterCfg) this.currentFilterCfg = {};
                        for (const [key, value] of Object.entries(filters)) {
                            this.currentFilterCfg[key] = value;
                        }
                        this.filtriService.performFilter$.next(this.currentFilterCfg);
                    } catch (error) {
                        console.log(error);
                    }
                }
            }, 1000);
        });

        this.columnsList = [
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DATE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COMPANY'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COSTCENTERS'), enabled: false, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CODE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CATEGORY'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NAME'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.QUANTITY'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.UNIT'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.PRICE'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.TOTAL'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DEPARTMENT'), enabled: false, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NOTE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } }
        ];
        this.selectedOrderMode = 'Name';

        this.orderModes = [
            { Id: 'Code', Text: this.translate.instant('EXPORT_PDF.SORT.CODE') },
            { Id: 'Name', Text: this.translate.instant('EXPORT_PDF.SORT.NAME') },
            { Id: 'CategoryAndName', Text: this.translate.instant('EXPORT_PDF.SORT.CAT_NAME') }
        ];
    }

    ngOnDestroy() {
        if (this.listaSubscribtions) this.listaSubscribtions.unsubscribe();
        this.switchGroupSubcription.unsubscribe();
        // if (this.staticCollectionsSubcription) this.staticCollectionsSubcription.unsubscribe();
        this.filtriServiceSubscription.unsubscribe();

        // cancello il filtro
        this.filtriService.filterConfig = {};

        // Se l'url non include inserimento-comune allora svuoto la localstorage
        if (!this.router.url.includes('/magazzino/inserimento-comune')) {
            localStorage.removeItem('currentFilterCfg');
        }
    }

    searchByCode(code: string) {
        this.ProductOrCode.nativeElement.value = code;
        this.filter(code, 'ProductOrCode')
    }

    getTitle() {
        return this.translate.instant('MAGAZZINO.INVENTARI.TITLE');
    }

    ngOnInit() {
        this.categorie = [];
        this.fornitori = [];
        this.aziendeSrv.getAziende().subscribe(
            result => this.companies = result
        )
        document.body.classList.add('kt-aside--minimize');
        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            (filterConfig: any) => {

                let hasAlreadyFilter = false;

                if (localStorage.getItem('currentFilterCfg')) {
                    try {
                        this.currentFilterCfg = JSON.parse(localStorage.getItem('currentFilterCfg') || '');
                        localStorage.removeItem('currentFilterCfg');
                        this.filtriService.performFilter$.next(this.currentFilterCfg);
                        hasAlreadyFilter = true;
                    } catch (error) {
                        localStorage.removeItem('currentFilterCfg');
                    }
                }

                if (!hasAlreadyFilter) {
                    //
                    if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) return;
                    if (filterConfig.constructor !== Object) return;
                    this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

                    this.pagination = {
                        page: 1,
                        pageSize: 15,
                        sort: 'Date',
                        sortMode: 'Desc',
                        TotalRows: 0
                    };

                    // Inizializzo EVENTUALMENTE le select
                    setTimeout(() => {
                        if (this.currentFilterCfg && this.currentFilterCfg.CategoryIds && this.currentFilterCfg.CategoryIds.length > 0) {
                            $('#select2Categorie').val(this.currentFilterCfg.CategoryIds).trigger('change');
                            this.categorie = this.currentFilterCfg.CategoryIds;
                        }

                        if (this.currentFilterCfg && this.currentFilterCfg.SupplierIds && this.currentFilterCfg.SupplierIds.length > 0) {
                            $('#select2Fornitori').val(this.currentFilterCfg.SupplierIds).trigger('change');
                            this.fornitori = this.currentFilterCfg.SupplierIds;
                        }
                    }, 1000)

                    this.refreshTable();
                }
            }
        );



        /*
        fromEvent(this.ProductOrCode.nativeElement, 'keyup')
            .pipe(
                // tslint:disable-next-line:max-line-length
                debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
                distinctUntilChanged(), // This operator will eliminate duplicate values
                tap(() => {
                    this.filter(this.ProductOrCode.nativeElement.value, 'ProductOrCode');
                })
            )
            .subscribe();
        */

        fromEvent(this.ProductOrCode.nativeElement, 'keydown').subscribe(
            (event: any) => {
                if (event.keyCode === 13) {
                    this.filter(this.ProductOrCode.nativeElement.value, 'ProductOrCode');
                }
            }
        );
    }

    editLocks() {
        const dialogRef = this.dialog.open(BloccoInventariComponent, {
            width: '600px'
        });
        dialogRef.afterClosed().subscribe(async (res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            if (res.Action === 'save') {
                this.loading$.next(true);
                this.magazzinoService.lockInventory(res.Object).toPromise()
                    .then((result: any) => {
                        this.loading$.next(false);
                        let message = this.translate.instant('INVENTARI.BLOCCO_INVENTARI.SALVATAGGIO');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                        this.refreshTable();

                    });
            }

        });
    }

    clearFilters(actionFilter: boolean = true) {
        $('#select2Categorie').val('').trigger('change');
        $('#select2Fornitori').val('').trigger('change');
        this.ProductOrCode.nativeElement.value = '';

        this.filtriService.clearFilters$.next(actionFilter);

        $('#table').DataTable().clear().destroy();
        this.lista = [];
        this.listaFiltered = [];
        this.dataReady$.next(false);
    }

    selectCategory(categoryId: string) {
        if (this.categorie.indexOf(categoryId) < 0) {
            this.categorie.push(categoryId);
            this.filter(this.categorie, 'CategoryIds');
        }
    }

    categorie: any;
    fornitori: any;

    initFiltri() {
        //console.log('initFiltri');
        (<any>$('#select2Categorie')).select2({
            placeholder: this.translate.instant('FILTRI.CATEGORIES_PLACEHOLDER'),
            allowClear: true,
            closeOnSelect: false,
            multiple: true,
            width: '100%',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });
        $('#select2Categorie').on('select2:select', (e: any) => {
            /*
            this.categorie.push({
                Id: e.params.data.id,
                Name: e.params.data.text
            });
            */
            if (this.categorie.indexOf(e.params.data.id) < 0) {
                this.categorie.push(e.params.data.id);
                this.filter(this.categorie, 'CategoryIds');
            }
        });
        $('#select2Categorie').on('select2:clear', (e: any) => {
            if (this.categorie.length > 0) {
                this.categorie = [];
                this.filter(this.categorie, 'CategoryIds');
            }
        });
        $('#select2Categorie').on('select2:unselect', (e: any) => {
            if (this.categorie.indexOf(e.params.data.id) >= 0) {
                this.categorie = this.categorie.filter((categoria: any) => categoria !== e.params.data.id);
                this.filter(this.categorie, 'CategoryIds');
            }

        });
        /* FORNITORI */
        (<any>$('#select2Fornitori')).select2({
            placeholder: this.translate.instant('FILTRI.FORNITORE_PLACEHOLDER'),
            allowClear: true,
            closeOnSelect: false,
            multiple: true,
            width: '100%',
            containerCssClass: 'select2Multiple',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });
        $('#select2Fornitori').on('select2:select', (e: any) => {
            /*
            this.fornitori.push({
                Id: e.params.data.id,
                Name: e.params.data.text
            });
            */
            if (this.fornitori.indexOf(e.params.data.id) < 0) {
                this.fornitori.push(e.params.data.id);
                this.filter(this.fornitori, 'SupplierIds');
            }
        });
        $('#select2Fornitori').on('select2:clear', (e: any) => {
            if (this.fornitori.length > 0) {
                this.fornitori = [];
                this.filter(this.fornitori, 'SupplierIds');
            }
        });
        $('#select2Fornitori').on('select2:unselect', (e: any) => {
            if (this.fornitori.indexOf(e.params.data.id) >= 0) {
                this.fornitori = this.fornitori.filter((fornitore: any) => fornitore !== e.params.data.id);
                this.filter(this.fornitori, 'SupplierIds');
            }
        });

        setTimeout(() => {
            $('#select2Categorie').val('').trigger('change');
            $('#select2Fornitori').val('').trigger('change');
        }, 1000);
    }

    refreshTable() {
        if (this.listaSubscribtions) this.listaSubscribtions.unsubscribe();
        this.loading$.next(true);
        this.dataReady$.next(false);
        this.filtriService.readOnly$.next(true);
        if ($.fn.dataTable.isDataTable('#table')) {
            $('#table').DataTable().destroy();
        }
        //console.log(this.pagination);
        //this.companies = [];
        this.listaSubscribtions = this.magazzinoService.getWarehouse('GetInventory', this.currentFilterCfg, this.pagination)
            .subscribe((ret: any) => {
                console.log('lista', ret);

                // TODO: Elaborare dati ricevuti
                ret['Quantity'] = 0;
                ret['Total'] = 0;
                ret.Rows.forEach((row: any) => {
                    ret.Quantity += row.Quantity;
                    ret.Total += row.Total;
                    ret.Note = ret.Note ? ret.Note.trim() : '';
                });

                if (this.pagination.TotalRows !== ret.TotalRows) {
                    this.pagination.TotalRows = ret.TotalRows;
                }

                this.lista = ret;
                this.listaFiltered = JSON.parse(JSON.stringify(ret));
                this.initDataTable();
                this.loading$.next(false);
                this.dataReady$.next(true);
                this.filtriService.readOnly$.next(false);

            });
    }

    initDataTable() {
        $('#table').DataTable().clear().destroy();
        setTimeout(() => {
            let dtOptions: any = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: true,
                orderCellsTop: true,
                scrollY: 'calc(100vh - 400px)',
                scrollX: false,
                scrollCollapse: true,
                aaSorting: [], // inizialmente nessun ordinamento
                columnDefs: [
                    { targets: 0, width: '80px', visible: this.columnsList[0].enabled },
                    { targets: 1, width: '120px', visible: this.columnsList[1].enabled },
                    { targets: 2, width: '120px', visible: this.columnsList[2].enabled },
                    { targets: 3, width: '120px', visible: this.columnsList[3].enabled },
                    { targets: 4, width: '120px', visible: this.columnsList[4].enabled },
                    { targets: 5, visible: this.columnsList[5].enabled, className: 'max-width-250' },
                    { targets: 6, width: '80px', visible: this.columnsList[6].enabled },
                    { targets: 7, width: '80px', visible: this.columnsList[7].enabled },
                    { targets: 8, width: '80px', visible: this.columnsList[8].enabled },
                    { targets: 9, width: '80px', visible: this.columnsList[9].enabled },
                    { targets: 10, width: '120px', visible: this.columnsList[10].enabled },
                    { targets: 11, width: '80px', visible: this.columnsList[11].enabled }
                ],
                autoWidth: true
            };
            this.table = $('#table').DataTable(dtOptions);
            this.table.on('page.dt', function () {
                $('.dataTables_scrollBody').animate({
                    scrollTop: 0
                }, 'slow');
            });
            //this.refreshColumnsVisibility();

            if (this.isMobile) {
                new (<any>$.fn).dataTable.Responsive(this.table, {
                    details: {
                        display: (<any>$.fn).dataTable.Responsive.display.childRowImmediate,
                        type: 'none',
                        target: ''
                    }
                });

                //setTimeout(()=>$('#table thead').hide()); // Serve per fittare meglio la tabella
            }
            //this.table.columns.adjust().draw();
        }, 100);
    }

    changePagination(pagination: PageEvent) {
        this.pagination.page = pagination.pageIndex + 1;
        this.pagination.pageSize = pagination.pageSize;
        this.refreshTable();
    }

    // sort(event: any, type: string) {
    // 	const columnLabel = $(event.target).closest('th').text().trim();

    // 	this.columnsList.forEach((element: any) => {
    // 		if (element.label.toLowerCase() === columnLabel.toLowerCase()) {

    // 			this.pagination.sort = type;

    // 			if (element.sort && element.sort === 'ASC') {
    // 				element.sort = 'DESC';
    // 				this.pagination.sortMode = 'Desc';
    // 			} else {
    // 				element.sort = 'ASC';
    // 				this.pagination.sortMode = 'Asc';
    // 			}

    // 			//console.log('sortMode', columnLabel, this.pagination.sortMode);
    // 			this.refreshTable();
    // 		}
    // 	});
    // }

    sort(event: any, index: number, type: string) {
        console.log('sort', event, index);
        this.pagination.sort = type;

        if (this.columnsList[index].sort && this.columnsList[index].sort === 'ASC') {
            this.columnsList[index].sort = 'DESC';
            this.pagination.sortMode = 'Desc';
        } else {
            this.columnsList[index].sort = 'ASC';
            this.pagination.sortMode = 'Asc';
        }

        console.log('sort => ', 'refreshTable', this.pagination.sort, this.pagination.sortMode);
        this.refreshTable();
    }

    filter(event: any, type: string) {
        //console.log('filter', event, type);
        switch (type) {
            case 'ProductOrCode':
                if (!this.currentFilterCfg) this.currentFilterCfg = {};
                this.currentFilterCfg['ProductOrCode'] = event;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
                break;
            case 'CategoryIds':
                if (!this.currentFilterCfg) this.currentFilterCfg = {};
                this.currentFilterCfg['CategoryIds'] = event;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
                break;
            case 'SupplierIds':
                if (!this.currentFilterCfg) this.currentFilterCfg = {};
                this.currentFilterCfg['SupplierIds'] = event;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
                break;
        }
    }

    openPopup() {
        const newEndpoint = {};
        this.editPopup(newEndpoint);
    }

    async editPopup(endpoint: any) {
        const dialogRef = this.dialog.open(InserimentoInventariPopupComponent, {
            data: { endpoint },
            width: '600px'
        });
        dialogRef.afterClosed().subscribe(async (res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            //console.log(res);
            if (res.success && res.body) {
                // mi salvo la configurazione dei filtri nei cookie.				
                if (this.currentFilterCfg) {
                    //console.log('mi salvo la configurazione dei filtri nei cookie.');
                    localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));
                }

                if (res.body.selectedFile) {
                    res.body.import = await this.importInventory(res.body);
                }

                this.magazzinoService.setCurrentConfig(res);

                this.openExistingInventoryOrNew(res.body);
            }

        });
    }

    refreshColumnsVisibility() {
        //console.log('refreshColumnsVisibility');
        for (let index = 0; index < this.columnsList.length; index++) {
            var column = this.table.column(index);
            if (column) column.visible(this.columnsList[index].enabled);
        }
    }

    openColumnsDialog() {
        const dialogRef = this.dialog.open(ColumnsSelectionPopupComponent, {
            data: { columnsList: this.columnsList },
            width: '300px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            //console.log(res);
            if (res.success && res.columnsList) {
                this.columnsList = res.columnsList;
                for (let index = 0; index < this.columnsList.length; index++) {
                    var column = this.table.column(index);
                    if (column) column.visible(this.columnsList[index].enabled);
                }
            }
        });
    }

    async importInventory(popupData: any) {
        const excel = await this.excelService.readFile(popupData.selectedFile, popupData.Company, 1);

        const colCodice = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CODICE');
        const colProdotto = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.PRODOTTO');
        const colQUB = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_UNITA_BASE');
        const colUB = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_BASE');
        const colQUM = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_MAGAZZINO_UNITA_MAGAZZINO');
        const colUM = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_MAGAZZINO');

        let importBody: any[] = [];

        //const locale = 'en'; //this.locale;
        const locale = this.locale;

        const productList = excel.filter((item: any) => {

            try {

                // validQUM = true se item[colQUM] è un valore numerico >= 0
                const validQUM = _.isNumber(item[colQUM]);
                // validQUB = true se item[colQUB] è un valore numerico >= 0
                const validQUB = _.isNumber(item[colQUB]);

                const parsedColQUM = validQUM ? util.parseNumber(item[colQUM], locale) : -1;
                const parsedColQUB = validQUB ? util.parseNumber(item[colQUB], locale) : -1;
    
                const res = (parsedColQUM >= 0 || parsedColQUB >= 0) ? true : false;
                return res;
            } catch (error) {
                return false
            }

        });

        productList.forEach((item: any) => {
            //console.log(item);

            let qMag = util.parseNumber(item[colQUM], locale);
            let qBase = util.parseNumber(item[colQUB], locale);

            let itemTmp = {
                GoodId: Number(item.Id),
                Date: popupData.Date,
                DocumentNumber: popupData.DocNumber,
                SupplierId: Number(popupData.SupplierId),
                OrderId: popupData.OrderId ? popupData.OrderId.OrderId : null,
                DepartmentId: popupData.DepartmentId ? Number(popupData.DepartmentId) : null,
                CodiceMerce: item[colCodice],
                Company: popupData.Company,

                // Servono per la precompilazione
                BaseQuantity: qBase,
                Quantity: qMag,
            };


            var costCenter;
            costCenter = this.staticCollectionsService.costCenters$.find((cc: any) => (cc.Id && popupData.CostCenterId) ? cc.Id.toString() === popupData.CostCenterId.toString() : false);
            if (!costCenter) costCenter = this.staticCollectionsService.costCenters$[0];
            if (costCenter && costCenter.$id) delete costCenter.$id;
            if (costCenter && !costCenter.Name) {
                costCenter.Name = '---';
            }
            itemTmp['CostCenter'] = costCenter;

            importBody.push(itemTmp);

        });

        // this.magazzinoService.save('Inventory', body).toPromise()
        // .then((result: any) => {
        // 		let message: string = '';
        // 		if (result.Msg === 'Ok') {
        // 			message = this.translate.instant('MAGAZZINO.INSERIMENTI.INSERIMENTO_OK');
        // 			this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
        // 		} else {
        // 			message = this.translate.instant('MAGAZZINO.INSERIMENTI.INSERIMENTO_ERROR');
        // 			this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
        // 		}
        // 	}
        // )
        // .catch((err: any) => console.log);

        return importBody;

    }

    openExistingInventoryOrNew(body: any) {
        this.loading$.next(true);
        this.magazzinoService.getExistingInvetory(body).subscribe(
            result => {
                let resultConfig = JSON.parse(JSON.stringify(result));
                resultConfig.hideExcluded = true;
                resultConfig.hideFresh = true;
                resultConfig.import = body.import;
                const resultsRows: any = JSON.parse(JSON.stringify(result.Rows));
                delete resultConfig.Rows;
                this.magazzinoService.setCurrentConfig({
                    insertType: InserimentoType.INVENTARI,
                    body: resultConfig,
                    items: resultsRows,
                    prePop: {
                        Items: resultsRows
                    },
                    isNewInvetoryInSameDay: true
                    // import: body.import
                });

                // mi salvo la configurazione dei filtri nei cookie.
                if (this.currentFilterCfg) {
                    //console.log('mi salvo la configurazione dei filtri nei cookie.');
                    localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));
                }

                this.loading$.next(false);
                this.router.navigate(['/magazzino/inserimento-comune'], {
                    queryParams: {
                        inserimentoType: InserimentoType.INVENTARI,
                        referral: '/magazzino/inventari',
                        isEditFromNew: true,
                        itemId: resultsRows[0].Id,
                    }
                });
            },
            (err: any) => {

                // if (body.import && body.import.length > 0) {
                // 	if (!this.currentFilterCfg) this.currentFilterCfg = {};
                // 	this.currentFilterCfg.import = body.import;
                // 	localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));
                // }
                this.loading$.next(false);

                this.router.navigate(['/magazzino/inserimento-comune'], {
                    queryParams: {
                        inserimentoType: InserimentoType.INVENTARI,
                        referral: '/magazzino/inventari'
                    }
                });
            }
        )
    }

    editDayItem(item: any) {

        var body = {
            Date: item.Date,

            Company: item.Company,
            DepartmentId: item.DepartmentObj.Id,
            CostCenterId: item.CostCenter.Id,

        };

        this.openExistingInventoryOrNew(body)

    }

    editItem(itemId: any) {

        this.loading$.next(true);
        this.magazzinoService.itemsForUpdate(itemId, 'Inventory')
            .subscribe((result: any) => {
                //console.log('itemsForUpdate', result);

                const resultConfig = JSON.parse(JSON.stringify(result));
                const resultsRows: any = JSON.parse(JSON.stringify(result.Rows));
                delete resultConfig.Rows;
                this.magazzinoService.setCurrentConfig({
                    insertType: InserimentoType.INVENTARI,
                    body: resultConfig,
                    items: resultsRows,
                    prePop: {
                        Items: resultsRows
                    }
                });

                // mi salvo la configurazione dei filtri nei cookie.
                if (this.currentFilterCfg) {
                    //console.log('mi salvo la configurazione dei filtri nei cookie.');
                    localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));
                }

                this.loading$.next(false);
                this.router.navigate(['/magazzino/inserimento-comune'], {
                    queryParams: {
                        inserimentoType: InserimentoType.INVENTARI,
                        referral: '/magazzino/inventari',
                        itemId: itemId,
                    }
                });

            })
    }

    deleteItem(itemId: any) {

        const _title: string = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.TITLE');
        const _description: string = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.DESC_MULTI');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.BUTTON_SINGLE');
        const _yes2Button = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.BUTTON_MULTI');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton, _yes2Button);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            //console.log(confirm);
            if (confirm === 2) {

                this.magazzinoService.deleteMultiInventory(itemId).subscribe(
                    (result: any) => {
                        //console.log(result);
                        let message: string = '';
                        if (result.Msg === 'Ok') {
                            message = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                            this.refreshTable();
                        } else {
                            message = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.ERROR');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                        }
                    }
                )

            } else if (confirm) {

                this.magazzinoService.delete(InserimentoType.INVENTARI, [itemId]).subscribe(
                    (result: any) => {
                        //console.log(result);
                        let message: string = '';
                        if (result.Msg === 'Ok') {
                            message = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                            this.refreshTable();
                        } else {
                            message = this.translate.instant('MAGAZZINO.INVENTARI.DELETE_DIALOG.ERROR');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                        }
                    }
                )

            }
        });

    }

    showFromYearStart() {
        this.currentFilterCfg = { Period: 'YEAR', ShowDisabled: true, ShowInvisible: true };
        this.filtriService.performFilter$.next(this.currentFilterCfg);
        this.ref.detectChanges();
    }

    showToday() {
        const today = new Date();
        const todayStr = today.toLocaleDateString('ja-JP');
        this.currentFilterCfg = { DateFilter: { Start: todayStr, End: todayStr }, ShowDisabled: true, ShowInvisible: true };
        this.filtriService.performFilter$.next(this.currentFilterCfg);
        this.ref.detectChanges();
    }

    getInsertTypeIcon(item: any) {
        //console.log('getInsertTypeIcon', item);
    }

    /****************************************************************************
        ______                         _
       |  ____|                       | |
       | |__ ___  _ __ _ __ ___   __ _| |_
       |  __/ _ \| '__| '_ ` _ \ / _` | __|
       | | | (_) | |  | | | | | | (_| | |_
       |_|  \___/|_|  |_| |_| |_|\__,_|\__|

       ****************************************************************************/
    formatNumber(x: any, decimal: number = 2, showZero: boolean = true, currency: boolean = false) {
        return this.utility.formatNumber(x, this.locale, decimal, showZero, currency);
    }

    /******************************************************************************
        ______                       _    __   ___       _______   __
       |  ____|                     | |   \ \ / / |     / ____\ \ / /
       | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
       |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
       | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
       |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
         | |
         |_|
      ******************************************************************************/

    exportXLSXLayout() {

        const config: any = {
            title: this.translate.instant('MAGAZZINO.INVENTARI.EXPORT_XLSX_LAYOUT.TITLE'),
            yesButton: this.translate.instant('EXPORT_XLSX.YESBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            companyLabel: this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.COMPANY'),
            orderLabel: this.translate.instant('MAGAZZINO.INVENTARI.ORDINAMENTO'),
            layoutsLabel: this.translate.instant('MAGAZZINO.INVENTARI.EXPORT_XLSX_LAYOUT.LAYOUTS'),
            orderModes: this.orderModes,
            companies: this.companies,
            layouts: [
                { Id: '1', Name: this.translate.instant('MAGAZZINO.INVENTARI.EXPORT_XLSX_LAYOUT.LAYOUT1') },
                { Id: '2', Name: this.translate.instant('MAGAZZINO.INVENTARI.EXPORT_XLSX_LAYOUT.LAYOUT2') }
            ]
        };

        const dialogRef = this.layoutUtilsService.exportPdfSheet(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                console.log('result', result);
                this.loading$.next(true);
                let lang = this.translationService.getSelectedLanguage();
                this.magazzinoService.getInventoryLayout(result.orderMode, result.company, lang, result.categoryIds).toPromise()
                    .then((layout: any) => {
                        console.log('getInventoryLayout', layout);

                        if (layout.pdf && layout.pdf.Stores && layout.pdf.Stores.length > 0) {

                            layout.pdf.Stores.forEach((store: any) => {

                                if (!store.Rows || store.Rows.length == 0) return;

                                let xlsx: any[] = [];
                                let blockedGoods: any[] = [];

                                store.Rows.forEach((item: any, index: number) => {

                                    let name = item.Alias ? item.Alias : (item.Name ? item.Name : '');

                                    name = name.replaceAll('\n', '');
                                    if (item.Note && item.Note.length > 0) {
                                        name += ' [' + item.Note + ']';
                                    }

                                    if (result.layout == '1') {
                                        xlsx.push({
                                            [`Id`]: item.Id ? item.Id : '',
                                            [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CODICE')}`]: item.Code ? item.Code : '',
                                            [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.PRODOTTO')}`]: name,
                                            [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_UNITA_BASE')}`]: '',
                                            [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_BASE')}`]: item.Unit1 && !item.Lock_Inventory_UnitBase ? item.Unit1 : ''
                                        });
                                    } else {
                                        xlsx.push({
                                            [`Id`]: item.Id ? item.Id : '',
                                            [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CODICE')}`]: item.Code ? item.Code : '',
                                            [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.PRODOTTO')}`]: name,
                                            [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_UNITA_BASE')}`]: '',
                                            [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_BASE')}`]: item.Unit1 && !item.Lock_Inventory_UnitBase ? item.Unit1 : '',
                                            [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_MAGAZZINO_UNITA_MAGAZZINO')}`]: '',
                                            [`${this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_MAGAZZINO')}`]: item.Unit2 && !item.Lock_Inventory_Unit ? item.Unit2 : ''
                                        });
                                    }

                                    // Raccolgo tutte le info di tutte le merci con unità di misura bloccate
                                    if (item.Lock_Inventory_Unit || item.Lock_Inventory_UnitBase) {
                                        const blockedGood: any = { ...item };
                                        blockedGood.index = index;
                                        blockedGoods.push(blockedGood);
                                    }

                                });

                                let columnsList: any = [];

                                let printOptions: any = {
                                    props: {
                                        fit: { width: 1, height: 0 },
                                        paper: "A4",
                                        orientation: 'landscape'
                                    },
                                    margins: { left: 0.25, right: 0.25, top: 0.75, bottom: 0.75, header: 0.3, footer: 0.3 }
                                };
                                if (result.layout == '1') {
                                    columnsList = [
                                        { label: 'Id', enabled: false, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } },
                                        { width: 12, label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CODICE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } },
                                        { width: 85, label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.PRODOTTO'), enabled: true, format: '', style: { alignment: { horizontal: "left", wrapText: true }, name: 'Calibri', sz: 10 } },
                                        { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_UNITA_BASE'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 10, } },
                                        { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_BASE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } }
                                    ]
                                } else {
                                    columnsList = [
                                        { label: 'Id', enabled: false, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } },
                                        { width: 12, label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.CODICE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } },
                                        { width: 75, label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.PRODOTTO'), enabled: true, format: '', style: { alignment: { horizontal: "left", wrapText: true }, name: 'Calibri', sz: 10 } },
                                        { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_UNITA_BASE'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 10 } },
                                        { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_BASE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } },
                                        { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.QUANTITA_MAGAZZINO_UNITA_MAGAZZINO'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 10 } },
                                        { label: this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.COLUMNS.UNITA_MAGAZZINO'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 10 } }
                                    ]
                                }

                                let filename: string = store.Store.replace(' ', '_');

                                filename += '_' + moment().format('YYYYMMDD');

                                const alternateRows: boolean = false;
                                this.excelService.exportInventoryLayout(xlsx, filename, [], columnsList, 2, [], alternateRows, store.Store, this.translate.instant('MAGAZZINO.INVENTARI.EXPORT_XLSX_LAYOUT.NOTE'), printOptions, blockedGoods);
                            });

                        }

                        this.loading$.next(false);

                    })
                    .catch((err: any) => {
                        this.loading$.next(false)
                    })

            };
        });
    }

    exportAsXLSX() {
        let pagination = JSON.parse(JSON.stringify(this.pagination));
        if (this.pagination.TotalRows) {

            if (pagination.TotalRows < environment.ExcelPaginationMaxSize)
                pagination.pageSize = pagination.TotalRows;
            else {
                pagination.pageSize = environment.ExcelPaginationMaxSize;
                const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
                let _description: string = this.translate.instant('EXPORT_XLSX.MAX5000');
                _description = _description.replace('{0}', '' + environment.ExcelPaginationMaxSize);
                const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');

                const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');


                const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton);
                dialogRef.afterClosed().subscribe(expand => {

                });
            }

        }
        else {
            const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
            const _description: string = this.translate.instant('EXPORT_XLSX.NO_ROWS');
            const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');

            const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');


            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton);
            dialogRef.afterClosed().subscribe(expand => {
                return;
            });
            return;
        }



        this.loading$.next(true);
        let xlsx: any[] = [];
        let merges: any[] = [];
        let listaSubscribtions = this.magazzinoService.getWarehouse('GetInventory', this.currentFilterCfg, pagination)
            .subscribe((ret: any) => {

                // TODO: Elaborare dati ricevuti
                ret['Quantity'] = 0;
                ret['Total'] = 0;
                ret.Rows.forEach((row: any) => {
                    ret.Quantity += row.Quantity;
                    ret.Total += row.Total;
                    ret.Note = ret.Note ? ret.Note.trim() : '';
                });

                if (this.pagination.TotalRows !== ret.TotalRows) {
                    this.pagination.TotalRows = ret.TotalRows;
                }

                let printList = JSON.parse(JSON.stringify(ret));

                // Totali
                xlsx.push({
                    [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.DATE')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.COMPANY')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.COSTCENTERS')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.CODE')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.CATEGORY')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.NAME')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.QUANTITY')}`]: printList.Quantity ? this.utility.formatNumberExcel(printList.Quantity, 2) : '',
                    [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.UNIT')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.PRICE')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.TOTAL')}`]: printList.Total ? this.utility.formatNumberExcel(printList.Total, 2) : '',
                    [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.DEPARTMENT')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.NOTE')}`]: ''
                });

                printList.Rows.forEach((item: any) => {
                    xlsx.push({
                        [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.DATE')}`]: item.Date ? (new Date(item.Date)).toLocaleDateString('it-IT') : '',
                        [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.COMPANY')}`]: item.Company ? item.Company.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.COSTCENTERS')}`]: item.CostCenter ? item.CostCenter.Name.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.CODE')}`]: item.Code ? item.Code : '',
                        [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.CATEGORY')}`]: item.Category ? item.Category.Name.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.NAME')}`]: item.Goods.Name ? item.Goods.Name : '',
                        [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.QUANTITY')}`]: item.Quantity ? this.utility.formatNumberExcel(item.Quantity, 2) : '',
                        [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.UNIT')}`]: item.Unit ? item.Unit : '',
                        [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.PRICE')}`]: item.Price ? this.utility.formatNumberExcel(item.Price, 2) : '',
                        [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.TOTAL')}`]: item.Total ? this.utility.formatNumberExcel(item.Total, 2) : '',
                        [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.DEPARTMENT')}`]: item.Department ? item.Department.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.NOTE')}`]: item.Note ? item.Note : ''
                    });
                });
                this.loading$.next(false);
                this.excelService.exportAsExcelFile(xlsx, 'inventory', merges, this.columnsList, 2, [], true);
            });

    }

    /******************************************************************************
      ______                       _     _____  _____  ______
     |  ____|                     | |   |  __ \|  __ \|  ____|
     | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
     |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
     | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
     |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
           | |
           |_|
    ******************************************************************************/

    exportPDFSheet() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.SHEET_TITLE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            companyLabel: this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.COMPANY'),
            orderLabel: this.translate.instant('MAGAZZINO.INVENTARI.ORDINAMENTO'),
            orderModes: this.orderModes,
            companies: this.companies
        };

        const dialogRef = this.layoutUtilsService.exportPdfSheet(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {

                this.loading$.next(true);
                let lang = this.translationService.getSelectedLanguage();
                this.magazzinoService.getInventoryPDF(result.orderMode, result.company, lang, result.categoryIds).subscribe(
                    (resultOp: any) => {
                        this.loading$.next(false);
                        window.open(`${resultOp.endpointURL}${resultOp.link}`, '_blank');
                    }
                );

            };
        });
    }

    exportAsPDF() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.getTitle() }),
                    //period: ''
                }
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                }
                result['language'] = this.translationService.getSelectedLanguage();
                result['table'].headerRows = 1;

                let widths: any = [];
                this.columnsList.filter((item: any) => item.enabled).forEach((element: any) => {
                    widths.push('auto')
                });
                widths[widths.length - 1] = '*';

                this.pdfService.makePdf(result, this.getPDFTableBody(), widths)
            };
        });

    }

    getPDFTableBody() {
        let body: any = [];
        let tmpRow: any = [];

        // aggiungo intestazione
        // totali Header
        tmpRow = [
            { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.DATE')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[1].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.COMPANY')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[2].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.COSTCENTERS')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[3].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.CODE')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[4].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.CATEGORY')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[5].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.NAME')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[6].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.QUANTITY')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[7].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.UNIT')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[8].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.PRICE')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[9].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.TOTAL')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[10].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.DEPARTMENT')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[11].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.INVENTARI.COLUMNS.NOTE')}`, style: 'tableHeaderStyle' }
        ];
        body.push(tmpRow.filter((item: any) => item.visible));

        tmpRow = [
            { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[1].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[2].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[3].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[4].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[5].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[6].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.listaFiltered.Quantity ? this.formatNumber(this.listaFiltered.Quantity) : ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[7].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[8].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[9].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.listaFiltered.Total ? this.formatNumber(this.listaFiltered.Total) : ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[10].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[11].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' }
        ];
        body.push(tmpRow.filter((item: any) => item.visible));

        let i: number = 0;
        let sortedList = this.utility.sortList(this.listaFiltered.Rows, 'table');
        sortedList.forEach((item: any) => {
            //this.listaFiltered.Rows.forEach((item: any) => {
            tmpRow = [
                { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Date ? (new Date(item.Date)).toLocaleDateString('it-IT') : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[1].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Company ? item.Company.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[2].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.CostCenter ? item.CostCenter.Name.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[3].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Code ? item.Code : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[4].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Category ? item.Category.Name.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[5].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Goods.Name ? item.Goods.Name : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[6].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Quantity ? this.formatNumber(item.Quantity) : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[7].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Unit ? item.Unit : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[8].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Price ? this.formatNumber(item.Price) : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[9].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Total ? this.formatNumber(item.Total) : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[10].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Department ? item.Department.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[11].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Note ? item.Note : ' ', style: 'tableBodyStyle' }
            ];
            body.push(tmpRow.filter((item: any) => item.visible));
            i++;
        });

        return body;
    }

}
