import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { Injectable } from "@angular/core";

@Injectable()
export class MyMatPaginatorIntl extends MatPaginatorIntl {

    private rangeLabelIntl!: string;

    constructor(private translate: TranslateService, private translateParser: TranslateParser) {
        super();

        this.translate.onLangChange.subscribe((e: Event) => {
            this.getTranslations();
        });

        this.getTranslations();
    }

    getTranslations() {
        this.translate.get([
            'PAGINATOR.ITEMS_PER_PAGE',
            'PAGINATOR.NEXT_PAGE',
            'PAGINATOR.PREVIOUS_PAGE',
            'PAGINATOR.RANGE'
        ])
            .subscribe(translation => {
                this.itemsPerPageLabel = translation['PAGINATOR.ITEMS_PER_PAGE'];
                this.nextPageLabel = translation['PAGINATOR.NEXT_PAGE'];
                this.previousPageLabel = translation['PAGINATOR.PREVIOUS_PAGE'];
                this.rangeLabelIntl = translation['PAGINATOR.RANGE'];
                this.changes.next();
            });
    }

    //getRangeLabel = (page: number, pageSize: number, length: number) => {
    //    if (length === 0 || pageSize === 0) {
    //        return `0 / ${length}`;
    //    }
    //    length = Math.max(length, 0);
    //    const startIndex = page * pageSize;
    //    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    //    return `${startIndex + 1} - ${endIndex} / ${length}`;
    //}

}
