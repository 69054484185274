import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';

// Material
import { MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';

// Services
import { GestioneRicetteService } from '@app/core/services/gestione-ricette.service';

//RxJs
import { Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';

@Component({
    selector: 'kt-sostituisci-ingrediente',
    templateUrl: './sostituisci-ingrediente.component.html',
    styleUrls: ['./sostituisci-ingrediente.component.scss']
})

export class SostituisciIngredienteComponent implements OnInit {

    daSostituire = new FormControl();
    sostituente = new FormControl();
    prodottiDaSostituireFiltrati!: Observable<any[]>;
    prodottiSostituentiFiltrati!: Observable<any[]>;
    showAlert = false;
    date!: Date;
    elimina: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<any>,
        private gestioneRicetteService: GestioneRicetteService,
        private gestioneMerciService: GestioneMerciService
    ) { }

    ngOnInit() {

        this.prodottiDaSostituireFiltrati = this.daSostituire.valueChanges
            .pipe(
                startWith(''),
                switchMap((value: string) => this._filter(value, true))
            );

        this.prodottiSostituentiFiltrati = this.sostituente.valueChanges
            .pipe(
                startWith(''),
                switchMap((value: string) => this._filter(value, false))
            );
    }

    private _filter(value: any, showDisabled: boolean) {
        this.showAlert = this.daSostituire.value && this.sostituente.value && this.daSostituire.value.UnitId !== this.sostituente.value.UnitId;

        let filterValue = '';
        if (value.constructor === String) {
            filterValue = value.toLowerCase();
        } else if (value.constructor === Object) {
            filterValue = value.Name ? value.Name.toLowerCase() : '';
        }

        return this.gestioneMerciService.getGoods({
            Name: filterValue
        });

    }

    displayFn(product: any): string {
        if (!product) return '';
        return '(' + product.Code + ') ' + product.Name;
    }

    onSubmit() {

        const body: any = {
            Date: this.date,
            Delete: this.elimina,
            OldIngredientId: this.daSostituire.value.Id,
            NewIngredientId: this.sostituente.value.Id,
            Type: ''
        };

        this.gestioneRicetteService.ReplaceIngredient(body).subscribe(
            (result: any) => {
                if (result.Msg === 'Ok') {
                    this.dialogRef.close({
                        success: true
                    });
                }
                else {
                    this.dialogRef.close({
                        success: false
                    });
                }
            }, (error: any) => {
                this.dialogRef.close({
                    success: false
                });
            }
        )

    }

}