import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//RxJS
import { Subscription } from 'rxjs';

// Service
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { TranslateService } from '@ngx-translate/core';
import { FiltriService } from '@app/core/services/filtri.service';

@Component({
    selector: 'kt-statistiche',
    templateUrl: './statistiche.component.html',
    styleUrls: ['./statistiche.component.scss']
})
export class StatisticheComponent implements OnInit, OnDestroy {

    currentFilterCfg: any;
    selectorChangedSubscription!: Subscription;

    currentFilterCfgOLD!: string;
    curentSelector!: string;
    entity!: string;
    entityLocalized!: string;

    constructor(
        private magazzinoService: MagazzinoService,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private filtriService: FiltriService,
    ) {
        document.body.classList.add('kt-aside--minimize');

        this.translate.get('MAGAZZINO.STATISTICHE.WAREHOUSE_SELECTOR.ACQUISTI').subscribe((res: string) => {
            this.entityLocalized = res;
        });

        this.entity = 'Purchase';
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params: any) => {
            console.log(params);
            if (params && params.entity && params.localized) {
                this.entity = params.entity;
                this.entityLocalized = this.translate.instant(params.localized);

                this.magazzinoService.setModel$.next(params.entity);
            } else {
                this.magazzinoService.setModel$.next(this.entity);
            }
        });

        this.selectorChangedSubscription = this.magazzinoService.selectorChanged$.subscribe(
            (selector: any) => {
                if (selector && JSON.stringify(selector) != this.curentSelector) {
                    this.curentSelector = JSON.stringify(selector);
                    if (selector && selector.model) {
                        //if (selector.model !== 'Purchase') {
                            this.entity = selector.model;
                            this.entityLocalized = selector.localized;
                            if (!this.currentFilterCfg) {
                                this.currentFilterCfg = {
                                    Period: "year",
                                    Length: 20,
                                    DayOfWeek: 0,
                                    ShowDisabled: true,
                                    ShowInvisible: true,
                                    Start: 0,
                                    TimeInterval: 'none',
                                    fromLocal: true
                                };
                            }
                            setTimeout(() => {
                                this.currentFilterCfg['Grouping'] = 'Company';
                                this.filtriService.performFilter$.next(this.currentFilterCfg);
                            }, 100);
                        //}
                    }
                }
            }
        );
    }

    ngOnDestroy() {
        this.magazzinoService.selectorChanged$.next(null);
        this.selectorChangedSubscription.unsubscribe();
    }

}
