import { Component, OnInit } from '@angular/core';
import { Observable, of, BehaviorSubject, combineLatest, EmptyError } from 'rxjs';
import { StoreService } from '@app/core/services/store.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SwitchGroupService } from '@app/core/_base/layout';

@Component({
    selector: 'kt-stores',
    templateUrl: './stores-component.html',
    styleUrls: ['./stores-component.scss']
})
export class StoresComponent implements OnInit {

    allStores: any[] = [];

    selectedStore: any;

    hide = true;

    panelOpenState = false;

    constructor(private srv: StoreService,
        private translate: TranslateService,
        private layoutUtilsService: LayoutUtilsService,
        private switchGroupService: SwitchGroupService
    ) {
        const currentGroup: any = this.switchGroupService.getCurrentGroup();

        console.log('integrations', currentGroup.integrations);
    }

    ngOnInit() {
        this.loadStores(undefined);
    }

    checkIntegration(integration) {
        const currentGroup: any = this.switchGroupService.getCurrentGroup();
        return currentGroup.integrations.findIndex(i => i.Integration === integration && i.IsAvailable) > -1;
    }

    onSelect(item) {
        this.selectedStore = item;
    }

    newStore() {
        let selected = this.allStores.find(x => x.IsNew);

        if (!selected) {
            var newStore =
            {
                "Code": "",
                "Name": "?????",
                "Area": "",
                "Group": "",
                "Company": "",
                "SyncroName": "",
                "Note": " YX",
                "Default_PaymentModality": -1,
                "Default_Bank": -1,
                "Default_PaymentType": "",
                "Default_PayFixDate": "2017-12-30T00:00:00",
                "Default_Invoices_Suffix": " ",
                "Default_FixDay": 10,
                "Default_PaymentTypeId": 3,
                "Default_Operation": -1,
                "Default_PaymentModalitySuplier": -1,
                "Default_BankSuplier": -1,
                "Default_PayTypeSuplier": " ",
                "Default_FixDatePaySuplier": "2017-12-30T00:00:00",
                "Default_Invoices_SuffixSuplier": " ",
                "Default_FixDaySuplier": 10,
                "Default_PayTypeSuplierId": 3,
                "Default_OperationSuplier": -1,
                "ExportId": -1,
                "PercRoyalities": 4,
                "InvoiceAddress": " ",
                "ShippingAddress": " ",
                "MailSender": " ",
                "MailSignature": " ",
                "SMTP": " ",
                "MailAuth": false,
                "MailUsername": " ",
                "MailPassword": " ",
                "MailSSL": false,
                "ExcludeCE": false,
                "IsMaster": false,
                "MailPort": 0,
                "EDI_CODE": " ",
                "EDI_NAME": " ",
                "EDI_ADDRESS": " ",
                "EDI_CITY": " ",
                "EDI_CAP": " ",
                "EDI_PROV": " ",
                "FE_CODE": " ",
                "FISCALCODE": "",
                "ZUCCEHTTI_ID": "",
                "LIGHTSPEED_ID": "",
                "TILBY_KEY": "",
                "IPRATICO_KEY": "",
                "Latitude": 0,
                "Longitude": 0,
                "MeteoZone": "",
                "TestoMail": " ",
                "OggettoMail": ""
            };

            newStore["IsNew"] = true;


            var master = this.allStores.find(x => x.IsMaster);
            newStore.Area = master.Area;
            newStore.Group = master.Group;
            newStore.Company = master.Company;



            this.allStores.push(newStore);
            selected = this.allStores.find(x => x.IsNew);
        }
        this.selectedStore = selected;
        this.onSelect(selected);
    }

    isSaveDisabled() {
        if (this.selectedStore) {
            let store = this.selectedStore;
            let enabled = (store.Name && store.Name != "?????") && store.Code;
            if (enabled) {
                var byName = this.allStores.filter(x => x.Name === store.Name);
                var byCode = this.allStores.filter(x => x.Code === store.Code);

                enabled = byName.length === 1 && byCode.length === 1;
            }

            return !enabled;
        }
        return true;
    }

    loadStores(selectedStore) {
        this.allStores = [];
        this.srv.getStores().subscribe(
            r => {
                this.allStores = r;
                if (selectedStore) {
                    this.onSelect(this.allStores.find(x => x.Name === selectedStore));

                } else {
                    this.onSelect(this.allStores[0]);
                }

            }
        );
    }

    getTranslation(type, key) {
        const lanKey = type + '.' + key;
        return this.translate.instant(lanKey);
    }

    saveStore() {



        this.srv.postStore(this.selectedStore).subscribe(
            r => {
                let message = this.translate.instant('STORE.SAVED_STORE');;
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                this.loadStores(this.selectedStore.Name);
            },
            e => {
                let message = this.translate.instant('STORE.SAVED_ERR');;
                this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-failure');
                this.loadStores(this.selectedStore.Name);
            }
        );
    }


}
