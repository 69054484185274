import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'kt-edit-notes',
  templateUrl: './edit-notes.component.html',
  styleUrls: ['./edit-notes.component.scss']
})
export class EditNotesComponent implements OnInit {

  Note!: string;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    console.log('this.data', this.data);
    if ( this.data && this.data.item ) {
      this.Note = this.data.item.Note;
    }
    
  }

  onSubmit() {
    this.dialogRef.close({
      success: true,
      Note: this.Note
    });
  }

}
