import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import {
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, fromEvent } from 'rxjs';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as util from '@app/core/services/utilityfunctions';

@Component({
    selector: 'kt-inserimento-acquisti-popup',
    templateUrl: './inserimento-acquisti-popup.component.html',
    styleUrls: ['./inserimento-acquisti-popup.component.scss', '../../common-styles.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ]
})
export class InserimentoAcquistiPopupComponent implements OnInit {

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    insertType!: string;
    company: any;
    costCenter: any;
    department: any;
    order: any;
    supplier: any;
    dataDocument!: Date;
    numberDocument!: string;

    listaCompanies: any;
    listaCostCenters: any;
    listaDepartment: any;
    listaOrders: any;
    listaSuppliers: any;

    viewLoading: boolean = false;
    hasFormErrors: boolean = false;
    errorMessage!: string;

    minDate: Date;
    maxDate: Date;
    //date: Date = new Date((new Date().getTime() - 0));
    date!: Date;

    prePop: any;

    isEdit: boolean = false;
    searchFilter: string = '';
    allSuppliers: any;
    note!: string;
    locale!: string;
    utility: any;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        private staticCollectionsService: StaticCollectionsService,
        private _adapter: DateAdapter<any>,
        private translationService: TranslationService,
        private magazzinoService: MagazzinoService,
        private ref: ChangeDetectorRef
    ) {
        this.minDate = new Date();
        this.minDate.setFullYear((new Date()).getFullYear() - 1);
        this.maxDate = new Date(); // http://mantis.fbmanager.com/view.php?id=803
        // this.maxDate.setFullYear((new Date()).getFullYear() + 1);

        this.utility = util;

        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });

        this.supplier = undefined;

    }

    getTitle() {
        return this.isEdit ? this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.TITLE_UPDATE') : this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.TITLE');
    }

    ngOnInit() {

        if (_.isEmpty(this.data.endpoint) || !this.data) {
            ;
        } else {
            this.isEdit = true;
        }

        this._adapter.setLocale('it');//this._adapter.setLocale(this.translationService.getSelectedLanguage());

        this.listaCompanies = this.staticCollectionsService.aziende$;
        this.listaCostCenters = this.staticCollectionsService.costCenters$;
        this.listaDepartment = this.staticCollectionsService.repartiMagazzino$;
        const listaAllSuppliers = this.staticCollectionsService.fornitoriTutti$;
        const listaInnerAllSuppliers = this.staticCollectionsService.fornitoriInterni$;
        this.allSuppliers = listaAllSuppliers.concat(listaInnerAllSuppliers);
        // https://mantis.fbmanager.com/view.php?id=1290
        this.listaSuppliers = this.isEdit ? this.allSuppliers : this.allSuppliers.filter((x: any) => !x.IsDisabled && !x.PurchaseImportExcluded);
        if (!this.isEdit) {
            this.insertType = 'fromDocument';
            if (this.listaCompanies.length == 1) {
                this.company = this.listaCompanies[0].Name;
                this.updateOrders(this.company);
            }
            if (this.listaCostCenters.length > 0) {
                const defCostCenter = this.listaCostCenters.filter(costCenter => costCenter.Name === '---');
                this.costCenter = defCostCenter ? defCostCenter[0].Id : undefined;
            }
            if (this.listaDepartment.length > 0) {
                const defDepartment = this.listaDepartment.filter(department => department.Name === '---');
                this.department = defDepartment ? defDepartment[0].Id : undefined;
            }
        } else {

            if (this.data.endpoint.Company && this.listaCompanies && this.listaCompanies.length > 0) {
                const existsCompany = this.listaCompanies.find((company: any) => company.Name.toString() === this.data.endpoint.Company.toString());
                if (existsCompany) {
                    this.company = existsCompany.Name;
                }
            }
            if (this.data.endpoint.CostCenter && this.listaCostCenters && this.listaCostCenters.length > 0) {
                const existsCostCenter = this.listaCostCenters.find((costCenter: any) => costCenter.Id.toString() === this.data.endpoint.CostCenter.Id.toString());
                if (existsCostCenter) {
                    this.costCenter = existsCostCenter.Id;
                }
            }
            if (this.data.endpoint.DepartmentId && this.listaDepartment && this.listaDepartment.length > 0) {
                const existsDepartment = this.listaDepartment.find((department: any) => department.Id.toString() === this.data.endpoint.DepartmentId.toString());
                if (existsDepartment) {
                    this.department = existsDepartment.Id;
                }
            }
            if (this.data.endpoint.SupplierId && this.listaSuppliers && this.listaSuppliers.length > 0) {
                const existsSuppliers = this.listaSuppliers.find((supplier: any) => supplier.Id.toString() === this.data.endpoint.SupplierId.toString());
                if (existsSuppliers) {
                    setTimeout(() => {
                        this.supplier = existsSuppliers.Id;
                    }, 100);                    
                }
            }
            if (this.data.endpoint.Date) {
                this.date = new Date((new Date(this.data.endpoint.Date).getTime() - 0));
            }
            this.numberDocument = this.data.endpoint.DocumentNumber;

        }
    }

    changeInsertType(event: any) {
        if (this.insertType !== 'fromOrder') {
            this.listaSuppliers = this.staticCollectionsService.fornitori$;
        } else {
            this.listaSuppliers = [];
            this.supplier = undefined;
            this.order = undefined;
            if (this.listaCompanies.length === 1) {
                this.updateOrders(this.listaCompanies[0].Name);
            }
        }
        this.updateOrders(this.company);
    }

    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    updateOrders(company: string) {
        if (this.insertType !== 'fromOrder' || !company) return;
        this.loading$.next(true);
        this.listaOrders = [];
        this.magazzinoService.getOrders(company).subscribe(
            (orders: any) => {
                this.listaOrders = orders;
                this.loading$.next(false);
                if (this.listaOrders.length === 1) {
                    this.order = this.listaOrders[0];
                    this.getSingleOrder(this.listaOrders[0]);
                }
            }
        )
    }

    getSingleOrder(item: any) {
        if (!item) return;
        this.listaSuppliers = undefined;
        this.supplier = undefined;
        this.loading$.next(true);
        this.magazzinoService.getSingleOrder(item).subscribe(
            (orderResult: any) => {
                this.prePop = orderResult;
                const fornitore = this.allSuppliers.filter(fornitore => fornitore.Id.toString() === orderResult.SupplierId.toString());

                this.date = orderResult.Date;
                this.listaSuppliers = [];
                this.listaSuppliers.push(fornitore[0]);
                setTimeout(() => {
                    this.supplier = fornitore[0].Id;
                }, 100);                
                const p = item.OrderId.indexOf('_EDI_');
                if (p > 0) {
                    this.numberDocument = item.OrderId.substring(p + 5);
                }

                this.loading$.next(false);
            }
        )
    }

    onSubmit() {

        if (!this.company) {
            this.errorMessage = this.translate.instant('FILTRI.COMPANIES') + ' ' + this.translate.instant('COMMONS.IS_REQUIRED');
            this.hasFormErrors = true;
            return;
        }
        if (!this.costCenter) {
            this.errorMessage = this.translate.instant('FILTRI.COSTS_CENTERS') + ' ' + this.translate.instant('COMMONS.IS_REQUIRED');
            this.hasFormErrors = true;
            return;
        }
        if (!this.supplier) {
            this.errorMessage = this.translate.instant('FILTRI.FORNITORE') + ' ' + this.translate.instant('COMMONS.IS_REQUIRED');
            this.hasFormErrors = true;
            return;
        }
        if (!this.numberDocument) {
            this.errorMessage = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI_POPUP.NUMBER_DOCUMENT') + ' ' + this.translate.instant('COMMONS.IS_REQUIRED');
            this.hasFormErrors = true;
            return;
        }
        if (!this.date) {
            this.errorMessage = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI_POPUP.DATA_DOCUMENT') + ' ' + this.translate.instant('COMMONS.IS_REQUIRED');
            this.hasFormErrors = true;
            return;
        }

        this.dialogRef.close({
            success: true,
            insertType: this.insertType,
            body: {
                Company: this.company,
                CostCenterId: this.costCenter,
                DepartmentId: this.department,
                OrderId: this.order,
                SupplierId: this.supplier,
                Date: moment(this.date).format('YYYY-MM-DDTHH:mm:ss'),
                DocNumber: this.numberDocument,
                hideExcluded: true,
                hideInvisible: true,
                OnlyVisibleForCompany: true,
                SearchText: this.searchFilter,
                note: this.note
            },
            prePop: this.prePop
        });
    }

}
