import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GestioneRicetteService {  

  goodsOrSubrecipeNamesList: any;
  subrecipeList: any;

  constructor(
    private http: HttpClient,
    private switchGroupService: SwitchGroupService
  ) { }


  getRecipeList(filter: any, pagination: any = undefined): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = pagination ? `/api/Recipe/List?page=${pagination.page}&pagesize=${pagination.pageSize}&sort=${pagination.sort}&sortMode=${pagination.sortMode}` : '/api/Recipe/List';

    return this.http.post<any>(currentLogin.endpointURL + url, filter, { headers: httpHeaders });
  }

  getAvailableIngredients(filter: any, pagination: any = undefined): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Recipe/AvailableIngredients`;
    return this.http.post<any>(currentLogin.endpointURL + url, filter, { headers: httpHeaders });
  }

  recalculateFC(): Observable<any>{
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Admin/RecalcFC`;

    return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  getNextRecipeCode(): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Entity/NextRecipeCode`;

    return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
  }


  getSingleRecipe(id) : Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Recipe/Single?id=${id}`;
    return this.http.get<any>(currentLogin.endpointURL + url,  { headers: httpHeaders });
  }

  deleteMultiRecipe(body: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Recipe/Delete`;
    return this.http.post<any>(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }

  
  creaVersionRecipe(id: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Recipe/VersionRecipe?recipeId=${id}`;
    return this.http.get<any>(currentLogin.endpointURL + url,  { headers: httpHeaders });
  }

  getRecipePDF(id: any, mode: any, lang: string): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Recipe/PDF?recipeId=${id}&version=${mode}&lang=${lang}`;
    return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders })
      .pipe(
        map((result: any) => {
          result.endpointURL = currentLogin.endpointURL;
          return result;
        })
      );      
  } 

  uploadPhoto(file): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();
    //INVOCARE api/Recipe/Photo
    const httpHeaders = new HttpHeaders({
      'Action': 'upload',
      'Authorization': 'Bearer ' + currentLogin.access_token
     
    });
    

    const url = `/api/Recipe/Photo`;
    return this.http.post(currentLogin.endpointURL + url, file, { headers: httpHeaders })
      .pipe(
        map((result: any) => {
        
          return result;
        })
      );      
  }



  getRecipeImagePath(): any {

    const currentLogin = this.switchGroupService.getCurrentGroup();
    const url=currentLogin.endpointURL + '/in/images/recipes';
    return url;
  }

  ReplaceIngredient(data: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Recipe/ReplaceIngredient`;
    return this.http.post<any>(currentLogin.endpointURL + url, data, { headers: httpHeaders });
  }

  saveRecipe(data: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();
    data.Ingredients.forEach((element: any) => {
      delete element.$id; 
    });
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Recipe/Save`;

    console.log('saveRecipe', url, data);
    return this.http.post<any>(currentLogin.endpointURL + url, data, { headers: httpHeaders });
  }


  updateRecipe(data: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();
    data.Ingredients.forEach((element: any) => {
      delete element.$id; 
      if(element.Goods){
        delete element.Goods.$id; 
      }
      if(element.Unit){
        delete element.Unit.$id; 
      }
    });
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Recipe/Update`;
    return this.http.put<any>(currentLogin.endpointURL + url, data, { headers: httpHeaders });
  }

  duplicateRecipe(id: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Recipe/Duplicate?id=${id}`;
    return this.http.post<any>(currentLogin.endpointURL + url, null, { headers: httpHeaders });
  }

}
