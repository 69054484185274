import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../../../core/core.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

// General widgets
import { FiltriComponent } from './filtri/filtri.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
// import { Select2Module } from 'ng2-select2';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { InsertSummaryComponent } from './magazzino/insert-summary/insert-summary.component';
import { SummaryComponent } from './filtri/summary/summary.component';
import { MonthsSelectorComponent } from './filtri/months-selector/months-selector.component';
import { NumericKeyboardComponent } from './numeric-keyboard/numeric-keyboard.component';
import { WarehouseSelectorComponent } from './warehouse-selector/warehouse-selector.component';
import { DndUploadComponent } from './dnd-upload/dnd-upload.component';
import { ProgressComponent } from './dnd-upload/progress/progress.component';

import { MBComponentsModule } from '@app/mb-components/mb-components.module';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        // Widgets
        FiltriComponent,
        InsertSummaryComponent,
        SummaryComponent,
        MonthsSelectorComponent,
        NumericKeyboardComponent,
        WarehouseSelectorComponent,
        DndUploadComponent,
        ProgressComponent,
    ],
    exports: [
        // Widgets
        FiltriComponent,
        InsertSummaryComponent,
        NumericKeyboardComponent,
        WarehouseSelectorComponent,
        DndUploadComponent,
        MonthsSelectorComponent
    ],
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        CoreModule,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatRadioModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatDialogModule,
        MatButtonToggleModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MBComponentsModule,
        NgxMaterialTimepickerModule,
        NgbDropdownModule        
        // Select2Module
    ]
})
export class WidgetModule {
}
