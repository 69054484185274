import { Component, OnInit, ViewChild } from '@angular/core';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { AziendeService } from '@app/core/services/aziende.service';
import { environment } from '@env/environment';

@Component({
    selector: 'kt-switch-group',
    templateUrl: './switch-group.component.html',
    styleUrls: ['./switch-group.component.scss']
})
export class SwitchGroupComponent implements OnInit {

    loginGroup: any = undefined;
    logoBase64: any = {};
    isLoading: any = {};
    isError: any = {};

    public filterGroup: any;
    @ViewChild('filterGroup', { static: false }) set _set(content: any) {
        if (content) { // initially setter gets called with undefined
            this.filterGroup = content;
        }
    }

    groups: any;
    filteredGroups: any;  // Lista filtrata
    searchTerm: string = '';  // Termine di ricerca

    constructor(
        public switchGroupService: SwitchGroupService,
        public aziendeService: AziendeService
    ) { }

    ngOnInit() {
        this.groups = this.switchGroupService.getGroupsList();
        // Inizializza i gruppi filtrati
        this.filteredGroups = [...this.groups];

        this.filteredGroups.forEach((item: any) => {
            let fieldName = item.group.replace(/\s+/g, '_');
            this.isLoading[fieldName] = true;
            this.aziendeService.getLogo(item.endpointURL, item.access_token).toPromise()
                .then((logo: any) => {
                    this.isLoading[fieldName] = false;
                    this.logoBase64[fieldName] = logo;
                }
                )
                .catch((error: any) => {
                    this.isLoading[fieldName] = false;
                    this.isError[fieldName] = true;
                })
        });

        this.loginGroup = this.switchGroupService.getCurrentGroup();
    }

    onOpenChange(open: any) {
        if(open && this.filterGroup) {
            setTimeout(() => {
                this.filterGroup.nativeElement.focus();
                this.filterGroup.nativeElement.select();
            }, 100);
        }
    }

    performSwitchGroup(endpoint: any) {
        // Imposto il gruppo corrente
        const index: number = this.switchGroupService.setCurrentGroupByName(endpoint.group);
        if(index < 0) {
            console.error('Group not found');
            return;
        }
        this.loginGroup = this.switchGroupService.getCurrentGroup();
        this.switchGroupService.performSwitch.next({
            change: true,
            index: index
        });
    }

    getLogo(currentGroup: any) {
        let fieldName = currentGroup.group.replace(/\s+/g, '_');
        return this.logoBase64[fieldName];
    }

    onErrorImage(event: any, group: any = undefined) {
        // if (group) {
        // 	this.switchGroupService.removeFromGroupList(group);
        // }
        // // console.log('onErrorImage', event);
        // $(event.target).attr('src', 'assets/media/logos/MyBusiness.png')
    }

    // Metodo per filtrare i gruppi
    filterGroups() {
        this.filteredGroups = this.groups.filter((group: any) =>
            group.group.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
    }

}
