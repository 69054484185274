import { Component, OnInit, Inject } from '@angular/core';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-set-type-dlg',
  templateUrl: './set-type-dlg.component.html',
  styleUrls: ['./set-type-dlg.component.scss']
})
export class SetTypeDlgComponent implements OnInit {

  type!: string;

  constructor(
    private dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    /* FORNITORI */
    (<any>$('#select2RecipeTypeBulk')).select2({
      containerCssClass: 'select2Single',
      minimumResultsForSearch: -1,
      language: {
          errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
          inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
          inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
          loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
          maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
          noResults: () => this.translate.instant('SELECT2.noResults'),
          searching: () => this.translate.instant('SELECT2.searching')
      }
    });
    $('#select2RecipeTypeBulk').on('select2:select', (e: any) => {
      this.type = e.params.data.id;
    });
    setTimeout(() => {
      $('#select2RecipeTypeBulk').val('').trigger('change');
    }, 100);
  }

  onSubmit() {
    this.dialogRef.close(this.type);
	}

}
