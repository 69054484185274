import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API } from '@env/environment';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

@Injectable({
  providedIn: 'root'
})
export class ProdottiService {

  constructor(
		private http: HttpClient,
		private switchGroupService: SwitchGroupService
	) {}

  getProdotti(): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.get<any>(`${currentLogin.endpointURL}/api/Filter${API.API_PRODUCTS}`, { headers: httpHeaders });
  }

  filterProdotti(payload: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(`${currentLogin.endpointURL}/api/Filter${API.API_PRODUCTS}`,
    payload, { headers: httpHeaders });
  }

}
