// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

// Translate Module
import { TranslateService } from '@ngx-translate/core';


@Component({
	selector: 'kt-unit-edit',
	templateUrl: './unit-edit.component.html',
	styleUrls: ['./unit-edit.component.scss']
})
export class UnitEditComponent implements OnInit {

	// Public properties
	content: any;
	mainUnitList: any;
	contentForm!: FormGroup;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	editMode: boolean = false;
	currentSelectedMainUnit!: any;

	constructor(
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private anagraficheService: AnagraficheService,
		private switchGroupService: SwitchGroupService,
		private translate: TranslateService
	) {
	}

	ngOnInit() {
		console.log(this.data);
		this.content = this.data.item;
		this.mainUnitList = this.data.mainUnitList;
		this.editMode = this.content.Id && this.content.Id > 0;

		$('body').on('keyup', (e: any)=> {
			if (e.code == 'F2') {
			  e.preventDefault();
			  e.stopPropagation();
			  this.onSubmit();
			}
		  });

		this.createForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		$('body').off('keyup');
	}

	/**
	 * Close alert
	 *
	 * @param $event: Event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}


	createForm() {
		this.contentForm = this.fb.group({
			Name: [this.content.Name, Validators.required],
			A: [this.content.A, Validators.required],
			B: [this.content.B, Validators.required],
			MainUnitId: [this.content.MainUnitId, null],
			Yield: [this.content.Yield, Validators.required]
		});

		if (this.content.MainUnitId && this.content.MainUnitId > 0) {
			this.getCurrentMainUnitById(this.content.MainUnitId);
		}
	}

	getCurrentMainUnitById(mainUnitId: any) {
		const obj = this.mainUnitList.filter(mainUnit => mainUnit.Id === mainUnitId);
		if (obj.length > 0) this.currentSelectedMainUnit = obj[0];
	}

	change(event: any) {
		this.getCurrentMainUnitById(event.value);
	}

	/**
	* Returns page title
	*/
	getTitle(): string {
		if (this.editMode) {
			return this.translate.instant('COMMONS.EDIT') + ` '${this.content.Name}'`;
		}
		return this.translate.instant('ANAGRAFICHE.UNIT.NEW_ENTITY');
	}

	/**
	 * Check control is invalid
	 * @param controlName: string
	 */
	isControlInvalid(controlName: string): boolean {
		const control = this.contentForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */

	/**
	 * Returns prepared customer
	 */
	prepareItem(): any {
		const controls = this.contentForm.controls;
		let prepareJson = {
			Name: controls['Name'].value,
			A: parseFloat(controls['A'].value.toString().replace(',', '.')),
			B: parseFloat(controls['B'].value.toString().replace(',', '.')),
			MainUnitId: controls['MainUnitId'].value,
			Yield: parseFloat(controls['Yield'].value.toString().replace(',', '.'))
		};
		if (this.editMode) {
			prepareJson['Id'] = this.content.Id;
		}
		return prepareJson;
	}

	/**
	 * On Submit
	 */
	onSubmit() {
		this.hasFormErrors = false;
		const controls = this.contentForm.controls;
		/** check form */
		if (this.contentForm.invalid) {
			Object.keys(controls).forEach(controlName => {
				controls[controlName].markAsTouched();
				console.log(controlName);
			});
			this.hasFormErrors = true;
			return;
		}

		const editedItem = this.prepareItem();
		console.log('prepareItem', editedItem);
		if (this.editMode) {
			this.updateEntityItem(editedItem);
		} else {
			this.createEntityItem(editedItem);
		}
	}

	/**
	 * Update customer
	 *
	 * @param _customer: CustomerModel
	 */
	updateEntityItem(item: any) {
		this.anagraficheService.putEntity('Unit', item).subscribe(
			(ret: any) => {
				console.log('updateEntityItem', ret);
				this.dialogRef.close({ item, isEdit: true });
			}, (error: any) => {
				this.dialogRef.close(null);
			}
		)
	}

	/**
	 * Update customer
	 *
	 * @param _customer: CustomerModel
	 */
	createEntityItem(item: any) {
		this.anagraficheService.postEntity('Unit', item).subscribe(
			(ret: any) => {
				console.log('createEntityItem', ret);
				this.dialogRef.close({ item, isEdit: false });
			}, (error: any) => {
				this.dialogRef.close(null);
			}
		)
	}

}
