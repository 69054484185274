import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, Input } from '@angular/core';
import { BehaviorSubject, Subscription, fromEvent, lastValueFrom } from 'rxjs';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { TranslateService } from '@ngx-translate/core';
// Layout
import { PDFService } from '@app/core/services/pdf.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

// Material
import { MatDialog } from '@angular/material/dialog';
import { FornitoriEditComponent } from '@app/views/pages/anagrafiche/fornitori/fornitori-edit/fornitori-edit.component';
import * as _ from 'lodash';

import { RoleService } from '@app/core/_base/layout/services/role.service';

import * as util from '@app/core/services/utilityfunctions';
import * as XLSX from '@sheet/core';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

@Component({
    selector: 'kt-fornitori',
    templateUrl: './fornitori.component.html',
    styleUrls: ['./fornitori.component.scss', '../common-styles.scss']
})
export class FornitoriComponent implements OnInit, AfterViewInit, OnDestroy {

    switchGroupSubcription!: Subscription;
    languageSubcription!: Subscription;

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    table: any;
    currentFilterCfg: any;
    filterType = 'all';
    filterDisabled = 1;

    utility: any;
    locale!: string;

    accountList: any;
    selectedAccount: any;
    accountType: number = 1;

    pagination: any = {
        TotalRows: 0,
        pageSize: 10,
        pageIndex: 0
    }

    constructor(
        public dialog: MatDialog,
        private anagraficheService: AnagraficheService,
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private pdfService: PDFService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private switchGroupService: SwitchGroupService,
        public roleService: RoleService,
        private staticCollectionsService: StaticCollectionsService
    ) {
        this.utility = util;

        this.languageSubcription = this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });

        this.currentFilterCfg = {
            Type: 1,
            Disabled: 1
        }
    }

    async ngOnInit() {
        document.body.classList.add('kt-aside--minimize');

        await this.staticCollectionsService.fillStaticCollection(false);

        this.changeAccountType(this.accountType);
    }

    ngAfterViewInit() {
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.clearFilters(false);
                }
            }
        );
    }

    ngOnDestroy() {
        this.switchGroupSubcription.unsubscribe();
        this.languageSubcription.unsubscribe();
    }

    changePagination(event: any) {
        if (this.pagination.pageSize != event.pageSize) {
            this.pagination.pageSize = event.pageSize;
            this.pagination.pageIndex = 0;
            this.table.page.len(this.pagination.pageSize).draw();
            this.table.page(0).draw('page');
        } else if (this.pagination.pageIndex != event.pageIndex) {
            this.pagination.pageIndex = event.pageIndex;
            this.table.page(this.pagination.pageIndex).draw('page');
        }
    }

    getPlaceholder() {
        let placeholder = "";
        switch (this.accountType) {
            case 1:
                placeholder = this.translate.instant('FILTRI.FORNITORE_PLACEHOLDER');
                break;
            case 2:
                placeholder = this.translate.instant('FILTRI.CLIENTE_PLACEHOLDER');
                break;
            case 4:
                placeholder = this.translate.instant('FILTRI.DIPENDENTE_PLACEHOLDER');
                break;
        }

        return placeholder;
    }

    // Se ho un filtro impostato allora lo elimino e aggiorno l'elenco delle scadenze raggruppate
    manageChangeAccountType(event: any) {
        this.currentFilterCfg['Type'] = event;
        this.refreshTable();
    }

    performFilter(value, name) {
        if (!this.currentFilterCfg) {
            this.currentFilterCfg = {
                Type: 1,
                Disabled: 1
            };
        }
        if (!value) {
            value = '';
            if (name) delete this.currentFilterCfg[name];
        } else {
            if (name) this.currentFilterCfg[name] = value;
        }
        if (name) {
            const key = name ? name + ":name" : '';
            var column = this.table.column(key);
            if (column) {
                column.search(value).draw();
            }
        } else {
            this.table.search(value).draw();
        }
    }

    change(event: any) {
        switch (event) {
            case 'all':
                this.performFilter('', 'IsInternal');
                break;
            case 'internal':
                this.performFilter('true', 'IsInternal');
                break;
            case 'external':
                this.performFilter('false', 'IsInternal');
                break;
        }
    }

    changeAccountType(event: any) {
        this.accountList = undefined;
        this.selectedAccount = undefined;
        this.currentFilterCfg['Type'] = event;
        this.currentFilterCfg['Disabled'] = 1;
        this.filterDisabled = 1;
        setTimeout(() => {
            switch (event) {
                case 1:
                    this.accountList = this.staticCollectionsService.fornitori$;
                    break;
                case 2:
                    this.accountList = this.staticCollectionsService.clientiTutti$;
                    break;
                case 4:
                    this.accountList = this.staticCollectionsService.dipendentiTutti$;
                    break;
            }

            this.refreshTable();

            this.ref.detectChanges();
        }, 100);
    }

    getTitle() {
        return this.translate.instant('ANAGRAFICHE.TITLE');
    }

    async refreshTable() {
        if ($.fn.dataTable.isDataTable('#table')) {
            //$('#table').DataTable().clear().destroy();
            this.table.draw();
        } else {
            this.initDataTable();
        }
    }

    clearFilters(actionFilter: boolean = true) {
        this.filterType = 'all';
        this.filterDisabled = 1;
        this.table = undefined;
        $('#table').DataTable().clear().destroy();
        this.refreshTable();
        this.ref.detectChanges();
    }

    initDataTable() {
        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();
            let dtOptions: any = {
                destroy: true,
                dom: 'rt<"paginator">',
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: true,
                searching: true,
                ordering: true,
                orderCellsTop: true,
                scrollY: 'calc(100vh - 300px)',
                scrollCollapse: true,
                processing: true,
                serverSide: true,
                ajax: {
                    url: currentLogin.endpointURL + `/api/Supplier/DataTable`,
                    type: "POST",
                    data: (d: any) => {
                        d.filters = this.currentFilterCfg;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        this.pagination.TotalRows = response.recordsFiltered;
                        this.ref.detectChanges();
                        return response.data;
                    }
                },
                columnDefs: [
                    {
                        targets: [0], width: '200px', orderable: true, className: 'max-width-200',
                        data: 'Name', name: 'Name', render: (data) => {
                            return `<div class="overflow-ellipses exportableTooltip" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }
                    },
                    {
                        targets: [1], width: '100px', orderable: true, className: 'max-width-100',
                        data: 'Code', name: 'Code', render: (data) => {
                            return `<div class="overflow-ellipses exportableTooltip" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }
                    },
                    {
                        targets: [2], width: '80px', orderable: false, className: 'text-center max-width-80',
                        data: 'CheckPrice', name: 'CheckPrice', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }
                    },
                    {
                        targets: [3], width: '80px', orderable: false, className: 'text-center max-width-80',
                        data: 'IsDefault', name: 'IsDefault', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }
                    },
                    {
                        targets: [4], width: '80px', orderable: false, className: 'text-center max-width-80',
                        data: 'IsDisabled', name: 'IsDisabled', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }
                    },
                    {
                        targets: [5], width: '80px', orderable: false, className: 'text-center max-width-80',
                        data: 'LinkedProfittAndLossIds', name: 'LinkedProfittAndLossIds', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }
                    },
                    {
                        targets: [6], width: '80px', orderable: false, className: 'text-center max-width-80',
                        data: 'IsInternal', name: 'IsInternal', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }
                    },
                    {
                        targets: [7], width: '80px', orderable: false, className: 'text-center max-width-80',
                        data: 'PurchaseImportExcluded', name: 'PurchaseImportExcluded', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }
                    },
                    {
                        targets: [8], width: '80px', orderable: false, className: 'text-center max-width-80',
                        data: 'CEImportExcluded', name: 'CEImportExcluded', render: (data) => {
                            return data ? `<i class="fas fa-check-square"></i>` : `<i class="far fa-square"></i>`;
                        }
                    },
                    {
                        targets: [9], width: '100px', orderable: false, className: 'text-right max-width-100',
                        data: 'MinOrder', name: 'MinOrder', render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },

                    {
                        targets: [10], width: '60px', orderable: false, className: 'actions max-width-60 excludeFromExport',
                        render: (data, type, p, meta) => {
                            const deleteTooltip = this.translate.instant('ANAGRAFICHE.ACTIONS_TOOLTIPS.DELETE');
                            const editTooltip = this.translate.instant('ANAGRAFICHE.ACTIONS_TOOLTIPS.EDIT');
                            return `
                                    <div class="display-flex-right flex-align-center">
                                        <a href="javascript:;" name="editEntityItem" data-toggle="tooltip" title="${editTooltip}">
                                            <i class="fas fa-pencil-alt text-primary"></i>
                                        </a>
                                        <div class="h_spacer"></div>
                                        <a href="javascript:;" name="deleteEntityItem" data-toggle="tooltip" title="${deleteTooltip}">
                                            <i class="fas fa-trash text-danger"></i>
                                        </a>
                                    </div>
                                    `;
                        }
                    } // Actions
                ],
                autoWidth: false,
                aaSorting: [[0, 'asc']],
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.excludeFromExport)',
                            format: {
                                body: function (data, row, column, node) {
                                    if (data.indexOf('exportableTooltip') >= 0) {
                                        var parser = new DOMParser();
                                        var html = parser.parseFromString(data, 'text/html');
                                        let html$ = $(html);
                                        data = html$.find(`.exportableTooltip`).attr("title");
                                    } else if (data.indexOf("fa-square") >= 0) {
                                        data = String.fromCharCode(parseInt('0x' + 'f0c8'));
                                    } else if (data.indexOf("fa-check-square") >= 0) {
                                        data = String.fromCharCode(parseInt('0x' + 'f14a'));
                                    }

                                    return data;
                                }
                            }
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            let arrayCols: any = ['*', 'auto', '7%', '7%', '7%', '7%', '7%', '7%', '7%', 'auto'];

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, arrayCols, '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
                            doc.defaultStyle = docDefinition.defaultStyle;
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;
                            doc.styles = docDefinition.styles;

                            // Imposto gli allineamenti
                            var rowCount = doc.content[1].table.body.length;

                            for (let i = 0; i < rowCount; i++) {
                                for (let index = 0; index < arrayCols.length; index++) {
                                    doc.content[1].table.body[i][index].alignment = ['left', 'left', 'center', 'center', 'center', 'center', 'center', 'center', 'center', 'right'][index];
                                }
                            }

                            doc.content[1].table.body.forEach((row, index) => {
                                row.forEach((field, col) => {
                                    if (col >= 2 && col <= 8) {
                                        if (index == 0) {
                                            //field.style = 'fontAwesomeHeader';
                                        } else if (field.style.indexOf('Odd') >= 0) {
                                            field.style = 'fontAwesomeOdd';
                                        } else {
                                            field.style = 'fontAwesome';
                                        }
                                    }
                                })
                            });

                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.pdfHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        //autoFilter: true,
                        //sheetName: 'Exported data',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.excludeFromExport)',
                            format: {
                                body: (data, row, column, node) => {
                                    if (data.indexOf('exportableTooltip') >= 0) {
                                        var parser = new DOMParser();
                                        var html = parser.parseFromString(data, 'text/html');
                                        let html$ = $(html);
                                        data = html$.find(`.exportableTooltip`).attr("title");
                                    } else if (data.indexOf("fa-square") >= 0) {
                                        data = '\0' + '☐';
                                    } else if (data.indexOf("fa-check-square") >= 0) {
                                        data = '\0' + '☑';
                                    } else if (column == 9) {
                                        data = this.utility.parseNumber(data, this.locale);
                                    }

                                    data = '\0' + data;

                                    return data;
                                }
                            },
                            orthogonal: 'export'
                        },
                        customize: async (xlsx: any) => {
                            XLSX.SSF.setlocale("de-DE");

                            let sheet = xlsx.xl.worksheets['sheet1.xml'];

                            // Nella colonna J dalla riga 3 in avanti importi lo stile 64
                            $(`row c[r^="J3"]`, sheet).attr('s', '64');

                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.excelHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    }
                ]
            };
            this.table = $('#table').DataTable(dtOptions);

            this.table.on('page.dt', function () {
                $('.dataTables_scrollBody').animate({
                    scrollTop: 0
                }, 'slow');
            });

            $('tbody').off('click');

            $('tbody').on('click', '.actions', async (event: any) => {
                const tr = $(event.target).closest('tr');
                const btn = $(event.target).closest('a');
                const data = this.table.row(tr.index()).data();

                switch (btn.attr('name')) {
                    case 'editEntityItem':
                        this.editEntityItem(data);
                        break;
                    case 'deleteEntityItem':
                        this.deleteEntityItem(data.Id);
                        break;
                }

            });

        }, 100);
    }

    /** ACTIONS */
    deleteEntityItem(_item: any) {
        const _title: string = this.translate.instant('ANAGRAFICHE.FORNITORI.ACTION_DELETE.TITLE');
        const _description: string = this.translate.instant('ANAGRAFICHE.FORNITORI.ACTION_DELETE.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('ANAGRAFICHE.FORNITORI.ACTION_DELETE.WAIT_DESCRIPTION');
        const _deleteMessage = this.translate.instant('ANAGRAFICHE.FORNITORI.ACTION_DELETE.MESSAGE');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe((res: any) => {
            if (!res) {
                return;
            }

            lastValueFrom(this.anagraficheService.deleteEntity('Supplier', {Id: _item})).then(
                (ret: any) => {
                    this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
                    this.table.draw();
                })
                .catch((error: any) => {
                    console.log(error);
                })
        });
    }

    addEntityItem() {
        const newEntityItem = {
            Id: ''
        };
        this.editEntityItem(newEntityItem);
    }

    editEntityItem(item: any) {
        let saveMessageTranslateParam = 'ANAGRAFICHE.FORNITORI.ACTION_EDIT.';
        saveMessageTranslateParam += item.Id > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = item.Id.length > 0 ? MessageType.Update : MessageType.Create;

        item.SubjectType = this.accountType;

        const dialogRef = this.dialog.open(FornitoriEditComponent, { data: { item }, width: '900px' });
        dialogRef.afterClosed().subscribe(async (res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);            
            this.table.draw();
            await this.staticCollectionsService.refreshFornitori();
        });
    }

    /******************************************************************************
      ______                       _    __   ___       _______   __
     |  ____|                     | |   \ \ / / |     / ____\ \ / /
     | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
     |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
     | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
     |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
           | |
           |_|
    ******************************************************************************/


    exportAsXLSX() {
        this.table.button(1).trigger();
        this.loading$.next(true);
        setTimeout(() => {
            this.ref.detectChanges();
            this.loading$.next(false);
        }, 100);
    }

    /******************************************************************************
      ______                       _     _____  _____  ______
     |  ____|                     | |   |  __ \|  __ \|  ____|
     | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
     |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
     | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
     |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
                 | |
                 |_|
    ******************************************************************************/

    PDFPreferences: any;
    exportAsPDF() {
        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
            explodeRows: this.translate.instant('EXPORT_PDF.EXPLODE_ROWS')
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.getTitle(),
                    period: ''
                };
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                };
                result['language'] = this.translationService.getSelectedLanguage();

                this.PDFPreferences = result;
                this.table.button(0).trigger();
            };
        });
    }

}
