import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SwitchGroupService, TranslationService } from '@app/core/_base/layout';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import * as utils from '@app/core/services/utilityfunctions';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { PDFService } from '@app/core/services/pdf.service';
import * as XLSX from '@sheet/core';

@Component({
    selector: 'kt-food-cost-detail',
    templateUrl: './food-cost-detail.component.html',
    styleUrls: ['./food-cost-detail.component.scss']
})
export class FoodCostDetailComponent implements OnInit {

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    table: any;
    utility: any;
    GoodsName: string = '';

    constructor(
        public dialogRef: MatDialogRef<FoodCostDetailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private switchGroupService: SwitchGroupService,
        private translate: TranslateService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private pdfService: PDFService
    ) {
        this.utility = utils;
    }

    ngOnInit(): void {
        this.initDataTable();
    }

    filter(event: any) {
        if (event.keyCode == 13) {
            const v = $(event.target).val()
            this.performFilter(v, event.target.name);
        }
    }

    performFilter(value, name) {
        if (!value) {
            value = '';
        }
        const key = name + ":name";
        var column = this.table.column(key);
        if (column) {
            column.search(value).draw();
        }
        else {
            console.log("Controllare la colonna")
        }
    }

    initDataTable() {
        const payload: any = {
            DateFilter: {
                Start: this.data.monthData.Name + '-01',
                End: moment(this.data.monthData.Name + '-01').endOf('month').format('YYYY-MM-DD')
            },
            SelectedType: this.data.type,
            FoodRevenue: this.data.monthData.FoodRevenue.Gross,
            BeverageRevenue: this.data.monthData.BeverageRevenue.Gross,
            OtherRevenue: this.data.monthData.OtherRevenue.Gross,
        };

        if(this.data.currentFilterCfg && this.data.currentFilterCfg.Companies) {
            payload.Companies = this.data.currentFilterCfg.Companies;
        }

        if ($.fn.dataTable.isDataTable('#foodcost_detailed')) {
            $('#foodcost_detailed').DataTable().clear().destroy();
        }
        const currentLogin = this.switchGroupService.getCurrentGroup();

        setTimeout(() => {
            const dtOptions: any = {
                destroy: true,
                paging: false,
                searching: true,
                dom: 'lrtip',
                ordering: true,
                //orderCellsTop: true,
                scrollX: true,
                scrollY: 'calc(100vh - 300px)',
                scrollCollapse: true,
                autoWidth: true,
                order: [],
                processing: true,
                serverSide: true,
                ajax: {
                    url: currentLogin.endpointURL + "/api/FoodCostAnalysis/Details",
                    type: "POST",
                    data: (d: any) => {
                        d.filters = payload;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        console.log('FoodCostAnalysis Details', response);
                        return response.data;
                    }
                },
                columnDefs: [
                    {   // Merce
                        targets: 0, data: 'GoodsName', name: 'GoodsName', render: (data, type, p, meta) => {
                            return `<div class="overflow-ellipses exportText">${data}</div>`;
                        }, className: 'max-width-200'
                    },
                    {   // Inventario iniziale
                        targets: 1, className: 'text-right', data: 'InitialInventory', name: 'InitialInventory', render: (data, type, p, meta) => {
                            return utils.formatNumber(data, this.data.locale);
                        }, width: '100px'
                    },
                    {
                        targets: 2, className: 'text-right', data: 'Purchases', name: 'Purchases', render: (data, type, p, meta) => {
                            return utils.formatNumber(data, this.data.locale);
                        }, width: '100px'
                    },
                    {
                        targets: 3, className: 'text-right', data: 'Waste', name: 'Waste', render: (data, type, p, meta) => {
                            return utils.formatNumber(data, this.data.locale);
                        }, width: '100px'
                    },
                    {
                        targets: 4, className: 'text-right', data: 'Transfer', name: 'Transfer', render: (data, type, p, meta) => {
                            return utils.formatNumber(data, this.data.locale);
                        }, width: '100px'
                    },
                    {
                        targets: 5, className: 'text-right', data: 'StaffMeals', name: 'StaffMeals', render: (data, type, p, meta) => {
                            return utils.formatNumber(data, this.data.locale);
                        }, width: '100px'
                    },
                    {
                        targets: 6, className: 'text-right', data: 'OfferedMeals', name: 'OfferedMeals', render: (data, type, p, meta) => {
                            return utils.formatNumber(data, this.data.locale);
                        }, width: '100px'
                    },
                    {
                        targets: 7, className: 'text-right', data: 'FinalInventory', name: 'FinalInventory', render: (data, type, p, meta) => {
                            return utils.formatNumber(data, this.data.locale);
                        }, width: '100px'
                    },
                    {
                        targets: 8, className: 'text-right', render: (data, type, p, meta) => {
                            return utils.formatNumber(p.UsedGoodsValue, this.data.locale);
                        }, width: '100px'
                    },

                    // Serve per evitare di riceve avvisi quando arrivano campi a null
                    {
                        defaultContent: '-',
                        targets: '_all'
                    }
                ],
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)'
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            const arrayCols = ['auto', '*', '*', '*', '*', '*', '*', '*', '*'];

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, arrayCols, '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
                            ////doc.defaultStyle = docDefinition.defaultStyle;
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;
                            doc.styles = docDefinition.styles;

                            doc.content[1].table.headerRows = 2;

                            var firstHeaderRow: any = [];
                            $('#foodcost_detailed').find("thead>tr:first-child>th>div").each(
                                (index: number, element: any) => {
                                    const headerText: string = element.innerHTML;
                                    console.log(element.innerHTML);
                                    firstHeaderRow.push({
                                        text: headerText,
                                        style: "tableHeader"
                                    });
                                });


                            // Imposto gli allineamenti
                            var rowCount = doc.content[1].table.body.length;
                            //console.log(doc.content[1].table);
                            for (let i = 0; i < rowCount; i++) {
                                doc.content[1].table.body[i][0].alignment = 'left';
                                doc.content[1].table.body[i][1].alignment = 'right';
                                doc.content[1].table.body[i][2].alignment = 'right';
                                doc.content[1].table.body[i][3].alignment = 'right';
                                doc.content[1].table.body[i][4].alignment = 'right';
                                doc.content[1].table.body[i][5].alignment = 'right';
                                doc.content[1].table.body[i][6].alignment = 'right';
                                doc.content[1].table.body[i][7].alignment = 'right';
                                doc.content[1].table.body[i][8].alignment = 'right';
                            }

                            doc.content[1].table.body.unshift(firstHeaderRow);
                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.pdfHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)',
                            format: {
                                header: (data, column, node) => {
                                    const value = column >= 1 ? this.utility.parseNumber(data, this.data.locale) : data;
                                    return value;
                                },
                                body: (data, row, column, node) => {
                                    if (data.indexOf('exportText') >= 0) {
                                        var parser = new DOMParser();
                                        var htmlDoc = parser.parseFromString(data, 'text/html');
                                        return $(htmlDoc).find('.exportText').text();
                                    } else {
                                        // Formatto
                                        const value = column >= 1 ? this.utility.parseNumber(data, this.data.locale) : data;
                                        return value;

                                    }
                                }
                            }
                        },
                        customize: async (xlsx: any) => {
                            XLSX.SSF.setlocale("de-DE");

                            let sheet = xlsx.xl.worksheets['sheet1.xml'];

                            // Allineo a sx
                            $('row c[r^="A"]', sheet).attr('s', '50');

                            // Allineo a dx
                            $('row c[r^="B"]', sheet).attr('s', '64');
                            $('row c[r^="C"]', sheet).attr('s', '64');
                            $('row c[r^="D"]', sheet).attr('s', '64');
                            $('row c[r^="E"]', sheet).attr('s', '64');
                            $('row c[r^="F"]', sheet).attr('s', '64');
                            $('row c[r^="G"]', sheet).attr('s', '64');
                            $('row c[r^="H"]', sheet).attr('s', '64');
                            $('row c[r^="I"]', sheet).attr('s', '64');

                            const numToAlpha = (num: number) => {
                                var alpha = '';
                                for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
                                    alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
                                }
                                return alpha;
                            }

                            // Faccio un clone della prima riga che contiene i totali
                            // e gli inserisco i titoli delle colonne      
                            let header = '<row r="2">';
                            let indexCol = 0;
                            for (let index = 0; index < 9; index++, indexCol++) {
                                let text = '';
                                if (indexCol == 0) {
                                    text = this.translate.instant('ANALISI_FOODCOST.COLUMNS.GOOD');
                                } else if (indexCol == 1) {
                                    text = this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY');
                                } else if (indexCol == 2) {
                                    text = this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES');
                                } else if (indexCol == 3) {
                                    text = this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE');
                                } else if (indexCol == 4) {
                                    text = this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT');
                                } else if (indexCol == 5) {
                                    text = this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS');
                                } else if (indexCol == 6) {
                                    text = this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS');
                                } else if (indexCol == 7) {
                                    text = this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY');
                                } else if (indexCol == 8) {
                                    text = this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE');
                                }
                                header += `<c t="inlineStr" r="${numToAlpha(index)}2" s="2"><is><t xml:space="preserve">${text}</t></is></c>`;
                            }
                            header += '</row>';

                            // Get a clone of the sheet data.        
                            var sheetData = $('sheetData', sheet).clone();
                            // Clear the current sheet data for appending rows.
                            $('sheetData', sheet).empty();

                            // Sposto in avanti la numerazione delle righe, siccome la riga 2 c'erano i totali, 
                            // io ho messo gli headers, allora sposto tutto avanti di 1
                            let rowCount = 3;
                            sheetData.children().each((indexRow: number, row: any) => {
                                const row$ = $(row);
                                if (indexRow == 0) {
                                    $('sheetData', sheet).append(row$[0].outerHTML);
                                    $('sheetData', sheet).append(header);
                                    return;
                                };
                                row$.attr('r', rowCount);

                                // Iterate each cell in the row to change the rwo number.
                                row$.children().each((indexCol, col) => {
                                    var cell = $(col);
                                    // Set each cell's row value.
                                    var rc: any = cell.attr('r');
                                    rc = rc.replace(/\d+$/, "") + rowCount;
                                    cell.attr('r', rc);
                                });

                                $('sheetData', sheet).append(row$[0].outerHTML);

                                rowCount++;
                            });

                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.excelHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    }
                ]
            };

            this.table = $('#foodcost_detailed').DataTable(dtOptions)
        }, 100);
    }

    exit() {
        this.dialogRef.close();
    }

    async exportAsXLSX() {
        this.table.button(1).trigger();
    }

    PDFPreferences: any;
    exportAsPDF(expand: boolean = false) {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
            explodeRows: this.translate.instant('EXPORT_PDF.EXPLODE_ROWS')
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('GESTIONE_FATTURE.TITLE'),
                    period: ''
                };
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                };
                result['language'] = this.translationService.getSelectedLanguage();

                this.PDFPreferences = result;

                this.table.button(0).trigger();
            };
        });
    }

}
