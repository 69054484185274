import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';

// RXJS
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Services
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { FiltriService } from '@app/core/services/filtri.service';

// Pagination
import { PageEvent } from '@angular/material/paginator';

// Utilities
import * as util from '@app/core/services/utilityfunctions';

@Component({
	selector: 'kt-visibilita-merci',
	templateUrl: './visibilita-merci.component.html',
	styleUrls: ['./visibilita-merci.component.scss']
})
export class VisibilitaMerciComponent implements OnInit {

	goodsTable: any;
	goodsTableFiltered: any;
	loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	dataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	pagination: any;
	columnsList: any;
	utility: any;
	currentFilterCfg: any;
	filtriServiceSubscription!: Subscription;

	@ViewChild('filterAllFiledVisGood', { static: true }) filterAllFiledVisGood!: ElementRef;

	constructor(
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private gestioneMerciService: GestioneMerciService,
		private translate: TranslateService,
		public staticCollectionsService: StaticCollectionsService,
		private layoutUtilsService: LayoutUtilsService,
		private excelService: ExcelService,
		private pdfService: PDFService,
		private translationService: TranslationService,
		private filtriService: FiltriService
	) { }

	ngOnDestroy() {
		this.filtriServiceSubscription.unsubscribe();
		// cancello il filtro
		this.filtriService.filterConfig = {};
	}

	ngOnInit() {
		this.pagination = {
			page: 1,
			pageSize: 15,
			sort: 'Product',
			sortMode: 'Asc',
			TotalRows: 0
		};

		this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
			(filterConfig: any) => {
				console.log('ngOnInit', filterConfig);
				if (filterConfig.constructor !== Object) return;
				if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) return;
				this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));
				this.refreshTable();
			}
		);

		fromEvent(this.filterAllFiledVisGood.nativeElement, 'keydown').subscribe(
			(event: any) => {
				if (event.keyCode === 13) {
					this.filter(this.filterAllFiledVisGood.nativeElement.value, 'FreeSearch');
				}
			}
		);

		this.refreshTable();
		this.initCategorie();

		$(window).resize(() => {
			this.redrawTable();
		});
	}

	categorieMerci: any;
	initCategorie() {
		console.log('initCategorie');
		this.categorieMerci = [];
		if ($('#catMerci').data('select2')) {
			(<any>$('#catMerci')).select2('destroy');
		}
		(<any>$('#catMerci')).select2({
			placeholder: this.translate.instant('FILTRI.CATEGORIES_PLACEHOLDER'),
			allowClear: true,
			closeOnSelect: false,
			multiple: true,
			width: '300px',
			containerCssClass: 'select2Multiple',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
		});
		$('#catMerci').on('select2:select', (e: any) => {
			this.categorieMerci.push({
				Id: e.params.data.id,
				Name: e.params.data.text
			});
			var CategoryIds: any = [];
			this.categorieMerci.forEach((element: any) => {
				CategoryIds.push(element.Id.toLowerCase());
			});
			this.filter(CategoryIds, 'CategoryIds');
		});
		$('#catMerci').on('select2:clear', (e: any) => {
			this.categorieMerci = [];
			this.filter([], 'CategoryIds');
		});
		$('#catMerci').on('select2:unselect', (e: any) => {
			this.categorieMerci = this.categorieMerci.filter((categoria: any) => categoria.Id != e.params.data.id);
			this.filter([], 'CategoryIds');
		});

		setTimeout(() => {
			$('#catMerci').val('').trigger('change');
		}, 1000);
	}


	filter(event: any, type: string) {
		console.log('filter', event, type);
		this.currentFilterCfg[type] = event;
		console.log(this.currentFilterCfg);
		this.filtriService.performFilter$.next(this.currentFilterCfg);
	}

	refreshTable() {
		this.loading$.next(true);
		this.dataReady$.next(false);
		this.hoverglassActive = true;

		if (!this.currentFilterCfg) {
			this.currentFilterCfg = {
				EnableState: 2,
				VisibleState: 1
			}
		}

		this.gestioneMerciService.getGoodsTable(this.currentFilterCfg, this.pagination).toPromise()
			.then((result: any) => {
				console.log('getGoodsTable', result);

				if (!this.columnsList) {
					this.columnsList = [
						{ label: this.translate.instant('GESTIONE_MERCI.COLUMNS.ID'), enabled: false },
						{ label: this.translate.instant('GESTIONE_MERCI.COLUMNS.CODE'), enabled: true },
						{ label: this.translate.instant('GESTIONE_MERCI.COLUMNS.NAME'), enabled: true },
					];

					this.staticCollectionsService.aziende$.forEach((company: any) => {
						this.columnsList.push({
							label: company.Name, enabled: true
						});
					});
				}

				if (this.pagination.TotalRows !== result.TotalRows) {
					this.pagination.TotalRows = result.TotalRows;
				}

				this.goodsTable = JSON.parse(JSON.stringify(result));;
				this.goodsTableFiltered = JSON.parse(JSON.stringify(result));
				this.dataReady$.next(true);
				this.initTableVisibilita();
				// this.loading$.next(false);
				// this.initCategorie();
			})
			.catch(error => {
				console.log(error);
				this.hoverglassActive = false;
			})
			.finally(() => {
				this.loading$.next(false);
			});
	}

	table: any;
	hoverglassActive: boolean = false;
	initTableVisibilita() {
		if ($.fn.dataTable.isDataTable('#tableVisibilita')) {
			$('#tableVisibilita').DataTable().destroy();
		}

		setTimeout(() => {
			let dtOptions: any = {
				initComplete: () => {
					this.hoverglassActive = false;
				},
				dom: 'lrtip',
				destroy: true,
				language: {
					emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
					zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
				},
				paging: false,
				searching: true,
				ordering: true,
				scrollY: 'calc(100vh - 300px)',
				scrollCollapse: true,
				scrollX: true,
				fixedColumns: {
					leftColumns: 2
				},
				columnDefs: [
					{ targets: [0], width: '80px', orderable: true },
					{ targets: [1], width: '180px', orderable: true },
				],
				autoWidth: true,
				aaSorting: [[0, 'asc'], [1, 'asc']],
			};

			let indexCompany = 2;
			this.staticCollectionsService.aziende$.forEach((company: any) => {
				dtOptions.columnDefs.push({
					targets: [indexCompany], width: '80px', orderable: false
				});
				indexCompany++;
			});

			this.table = $('#tableVisibilita').DataTable(dtOptions);
            this.table.on('page.dt', function () {
                $('.dataTables_scrollBody').animate({
                    scrollTop: 0
                }, 'slow');
            });
			//this.tableFoodCost.columns.adjust().draw();
		}, 100);

	}

	changePagination(pagination: PageEvent) {
		this.pagination.page = pagination.pageIndex + 1;
		this.pagination.pageSize = pagination.pageSize;
		console.log('changePagination', 'refreshTable', pagination, this.pagination);
		this.refreshTable();
	}

	sort(event: any, index: number, type: string) {
		console.log('sort', event, index);
		this.pagination.sort = type;

		if (this.columnsList[index].sort && this.columnsList[index].sort === 'ASC') {
			this.columnsList[index].sort = 'DESC';
			this.pagination.sortMode = 'Desc';
		} else {
			this.columnsList[index].sort = 'ASC';
			this.pagination.sortMode = 'Asc';
		}

		console.log('sort => ', 'refreshTable', this.pagination.sort, this.pagination.sortMode);
		this.refreshTable();
	}

	setGoodsVisibility(row: any, company: string) {
		var visibility = !row.Infos[company];
		this.gestioneMerciService.setGoodsVisibility({
			Id: row.Id,
			Company: company,
			IsVisible: visibility
		}).toPromise()
			.then((result: any) => {
				const message = this.translate.instant('GESTIONE_MERCI.VISIBILITA_MERCI_AGGIORNATA');
				this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
			});
	}

	checKeypress(event: any, row: any, companyName: string) {
		// Se preme barra spazio
		if (event.keyCode == 32) {
			this.setGoodsVisibility(row, companyName);
		}
	}

	redrawTable() {
		this.table.columns.adjust().draw();
	}


	/******************************************************************************
	  ______                       _    __   ___       _______   __
	 |  ____|                     | |   \ \ / / |     / ____\ \ / /
	 | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
	 |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
	 | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
	 |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
		   | |
		   |_|
	******************************************************************************/
	exportAsXLSX() {
		this.loading$.next(true);
		let xlsx: any[] = [];
		let merges: any[] = [];
		let i: number = 0;
		let pagination = JSON.parse(JSON.stringify(this.pagination));
		if (this.pagination.TotalRows) {
			pagination.pageSize = pagination.TotalRows;

		}
		else {
			const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
			const _description: string = this.translate.instant('EXPORT_XLSX.NO_ROWS');
			const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');

			const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');


			const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton);
			dialogRef.afterClosed().subscribe(expand => {
				return;
			});
			return;
		}

		let columnsInfo = [
			{ width: 0, enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } }, // Codice
			{ width: 0, enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } }, // Descrizione
		];

		this.staticCollectionsService.aziende$.forEach((company: any) => {
			columnsInfo.push({ width: 25, enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } });
		});

		let rowsStyles: any = []
		this.gestioneMerciService.getGoodsTable(this.currentFilterCfg, pagination).toPromise()
			.then((ret: any) => {
				if (this.pagination.TotalRows !== ret.TotalRows) {
					this.pagination.TotalRows = ret.TotalRows;
				}
				let printList = JSON.parse(JSON.stringify(ret));
				printList.Rows.forEach((item: any) => {
					if (i % 2 === 0) {
						rowsStyles[i] = {
							fgColor: { rgb: 'e6edfb' }
						};
					}

					let columns: any = {};

					columns[`${this.translate.instant('GESTIONE_MERCI.COLUMNS.ID')}`] = item.Id;
					columns[`${this.translate.instant('GESTIONE_MERCI.COLUMNS.CODE')}`] = item.Code;
					columns[`${this.translate.instant('GESTIONE_MERCI.COLUMNS.NAME')}`] = item.Name;

					this.staticCollectionsService.aziende$.forEach((company: any) => {
						columns[company.Name] = item.Infos[company.Name] ? 'X' : '';
					});

					xlsx.push(columns);
					i++;
				});
				this.excelService.exportAsExcelFile(xlsx, 'goods_visibility', merges, this.columnsList, 2, [], true);
				// this.loading$.next(false);
			})
			.catch(error => console.log)
			.finally(() => {
				this.loading$.next(false);
			});
	}

	async importXLSX(fileImport: any) {
		//const excel = await this.excelService.readFile(fileImport.files[0]);
		//let goods: any[] = [];
		//excel.forEach(async (row: any) => {
		//	this.staticCollectionsService.aziende$.forEach(company => {
		//		const visibility = row[company.Name] ? row[company.Name] === 'X' : false
		//		goods.push({
		//			Id: row.Id,
		//			Company: company.Name,
		//			IsVisible: visibility
		//		});
		//	});
		//});
		//// this.layoutUtilsService.simpleElement(this.translate.instant('GESTIONE_MERCI.VISIBILITA_MERCI_INIZIO_IMPORT'));
		//alert(this.translate.instant('GESTIONE_MERCI.VISIBILITA_MERCI_INIZIO_IMPORT'));

		//this.gestioneMerciService.setAllGoodsVisibility(goods).subscribe(
		//	(result: any) => {
		//		// this.refreshTable();
		//		// this.layoutUtilsService.simpleElement(this.translate.instant('GESTIONE_MERCI.VISIBILITA_MERCI_FINE_IMPORT'));
		//		alert(this.translate.instant('GESTIONE_MERCI.VISIBILITA_MERCI_FINE_IMPORT'));
		//	}
		//);
	}

	/******************************************************************************
			______                       _     _____  _____  ______
		 |  ____|                     | |   |  __ \|  __ \|  ____|
		 | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
		 |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
		 | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
		 |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
					 | |
					 |_|
	******************************************************************************/
	exportAsPDF() {

		const config: any = {
			title: this.translate.instant('EXPORT_PDF.TITLE'),
			description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
			waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
			success: this.translate.instant('EXPORT_PDF.MESSAGE'),
			yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
			noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
			closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
			askTitle: true,
			pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
			askExplodeRows: false,
		};

		const dialogRef = this.layoutUtilsService.exportElement(config);
		dialogRef.afterClosed().subscribe((result: any) => {
			if (result) {
				result['header'] = {
					export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.translate.instant('GESTIONE_MERCI.VISIBILITA_MERCI') }),
					//period: ''
				}
				result['footer'] = {
					printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
					page: this.translate.instant('EXPORT_PDF.PAGE'),
					of: this.translate.instant('EXPORT_PDF.OF')
				}
				result['language'] = this.translationService.getSelectedLanguage();
				result['table'].headerRows = 1;

				let widts: string[] = ['*', '*'];
				this.staticCollectionsService.aziende$.forEach((company: any) => {
					widts.push('auto');
				});

				this.pdfService.makePdf(result, this.getPDFTableBody(), widts); //Array(10).fill('auto')
			};
		});

	}

	getPDFTableBody() {
		let body: any = [];
		let tmpRow: any = [];

		// aggiungo intestazione
		// totali Header
		tmpRow = [
			{ visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.COLUMNS.ID')}`, style: 'tableHeaderStyle' },
			{ visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.COLUMNS.NAME')}`, style: 'tableHeaderStyle' },
		];

		this.staticCollectionsService.aziende$.forEach((company: any) => {
			tmpRow.push({
				visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${company.Name}`, style: 'tableHeaderStyle'
			})
		});

		body.push(tmpRow.filter((item: any) => item.visible));

		let i: number = 0;
		let sortedList = this.goodsTable.Rows;//this.utility.sortList(this.ricetta.Prices, 'table');
		sortedList.forEach((item: any) => {
			//this.consumptionListFiltered.forEach((item: any) => {

			tmpRow = [
				{ visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Code, style: 'tableBodyStyle' },
				{ visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Name, style: 'tableBodyStyle' },
			];

			this.staticCollectionsService.aziende$.forEach((company: any) => {
				tmpRow.push({
					visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Infos[company.Name] ? company.Name : '', style: 'tableBodyStyle'
				})
			});

			body.push(tmpRow.filter((item: any) => item.visible));
			i++;
		});

		return body;
	}

	allChecked(row: any) {
		delete row.Infos.$id;
		// Controllo che tutti i values dell'oggetto Infos siano a true.
        const values: any = Object.values(row.Infos);
		return values.every((company: boolean) => company);
	}

	async setAll(checked: boolean, row: any) {
		delete row.Infos.$id;
		this.loading$.next(true);
		let goods: any = [];
		Object.keys(row.Infos).forEach(company => {
			row.Infos[company] = checked;
			goods.push({
				Id: row.Id,
				Company: company,
				IsVisible: checked
			});
		});

		await this.gestioneMerciService.setAllGoodsVisibility(goods).toPromise();
		this.loading$.next(false);

    const message = this.translate.instant('GESTIONE_MERCI.VISIBILITA_MERCI_AGGIORNATA');
    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

	}

	someChecked(row: any): boolean {
		delete row.Infos.$id;
        const values: any = Object.values(row.Infos);
		const areSomeChecked = values.filter((company: boolean) => company);
		return areSomeChecked.length > 0 && areSomeChecked.length != Object.values(row.Infos).length;
	}

}
