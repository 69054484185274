import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SwitchGroupService } from '@app/core/_base/layout';
import { EndpointsService } from '@app/core/services/endpoints.service';
import { ManutenzioneService } from '../../manutenzione/manutenzione.service';
import { combineLatest, lastValueFrom } from 'rxjs';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'kt-integrations-edit',
    templateUrl: './integrations-edit.component.html',
    styleUrls: ['./integrations-edit.component.scss']
})
export class IntegrationsEditComponent implements OnInit {

    integrationsList: any;
    ready: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private endpointsService: EndpointsService,
        private manutenzioneService: ManutenzioneService,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private switchGroupService: SwitchGroupService
    ) { }

    ngOnInit(): void {


        const type$ = this.manutenzioneService.getImportTypes()
        const integrations$ = this.endpointsService.getIntegrationsByEndpoint(this.data.endpoint)

        this.integrationsList = [];
        lastValueFrom(combineLatest([type$, integrations$]))
        .then(([types, integrations]) => {
            this.integrationsList = types.map((type: any) => {
                const integration = integrations.find((i: any) => i.Integration === type);
                return {
                    IsAvailable: integration ? integration.IsAvailable : false,
                    Integration: type,
                    IntegrationLabel: this.translate.instant(`INTEGRATIONS.SISTEMI_CASSA.${type}`)
                }
            });
            this.ready = true;
        });

    }

    onSubmit() {
        const endpoint = this.data.endpoint;
        lastValueFrom(this.endpointsService.updateIntegrations(this.integrationsList, endpoint))
            .then((result: any) => {
                if(!result) {
                    const message = this.translate.instant('ENDPOINTS.EDIT.ERROR');
                    this.layoutUtilsService.showActionNotification(message, MessageType.Error);
                } else {
                    const message = this.translate.instant('ENDPOINTS.EDIT.UPDATE_MESSAGE');
                    this.layoutUtilsService.showActionNotification(message, MessageType.Create);

                    // Aggiorno la lista delle integrazioni
                    this.switchGroupService.setGroupIntegrations(endpoint.ApiEndpointURL, this.integrationsList);

                    this.dialogRef.close(true);
                }
                
            })
            .catch((error: any) => {
                const message = this.translate.instant('ENDPOINTS.EDIT.ERROR');
                this.layoutUtilsService.showActionNotification(message, MessageType.Error);
            });
    }

}
