import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ScadenziarioService } from '../../scadenziario.service';
import { TranslateService } from '@ngx-translate/core';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import moment from 'moment';

@Component({
    selector: 'kt-bulk-payment-dialog',
    templateUrl: './bulk-payment-dialog.component.html',
    styleUrls: ['./bulk-payment-dialog.component.scss']
})
export class BulkPaymentDialogComponent implements OnInit {

    paymentDate: any = new Date();
    bankOperation: any = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public staticCollectionsService: StaticCollectionsService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private layoutUtilsService: LayoutUtilsService,
        private ref: ChangeDetectorRef,
        private scadenziarioService: ScadenziarioService,
        public dialogRef: MatDialogRef<any>
    ) { }

    ngOnInit(): void {
        this.data
    }

    checkDisabled(): boolean {
        return !this.paymentDate || !this.bankOperation;
    }

    setPaymentDate(event: any) {
        this.paymentDate=new Date(moment(event.startDate).format('YYYY-MM-DD'));
    }

    onSubmit() {
        const _title: string = this.translate.instant('SCADENZIARIO.BULK_PAYMENT_DIALOG.TITLE');
        const _description: string = this.translate.instant('SCADENZIARIO.BULK_PAYMENT_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('SCADENZIARIO.BULK_PAYMENT_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');
        const success = this.translate.instant('SCADENZIARIO.BULK_PAYMENT_DIALOG.SUCCESS');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            if (confirm) {

                let payload: any = []

                this.data.selectedItems.forEach((scadenza: any) => {

                    payload.push({
                        Id: scadenza.Id,
                        PaymentDate: this.paymentDate,
                        PayedAmount: scadenza.Total,
                        NextScheduleDate: '',
                        OperationId: this.bankOperation
                    });

                });

                this.scadenziarioService.bulkPayment(payload).subscribe(() => {
                    this.layoutUtilsService.showActionNotification(success, MessageType.Update);
                    this.dialogRef.close(true);
                });
            }
        })
    }
}
