import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, BehaviorSubject, combineLatest, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { AziendeService } from './aziende.service';
import { CategorieProdottiService } from './categorie-prodotti.service';
import { ProdottiService } from './prodotti.service';
import { CostCenterService } from './cost-center.service';
import { GestioneMerciService } from './gestione-merci.service';
import { AnagraficheService } from './anagrafiche.service';

@Injectable({
    providedIn: 'root'
})
export class StaticCollectionsService implements OnDestroy {

    public ready$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    private currentPair: any;

    private aziendeObserv$!: Observable<any[]>;
    private aziendeFullObserv$!: Observable<any[]>;
    private categorieProdottiObserv$!: Observable<any[]>;
    private prodottiObserv$!: Observable<any>;
    private costCentersObserv$!: Observable<any[]>;

    public aziende$;
    public aziendeForPL$;
    public aziendeFull$;
    public categorieProdotti$;
    public prodotti$;
    public costCenters$;

    private unitObserv$!: Observable<any>;
    public unit$;
    private orderUnitObserv$!: Observable<any>;
    public orderUnit$;

    // Gestione Merci
    private prodottiMerciObserv$!: Observable<any>;
    private categorieMerciObserv$!: Observable<any[]>;
    private gruppiMerciObserv$!: Observable<any[]>;
    private tipoAnagraficaObserv$!: Observable<any>;
    private tagsObserv$!: Observable<any[]>;
    private brandsObserv$!: Observable<any[]>;

    public prodottiMerci$;
    public categorieMerci$;
    public gruppiMerci$;

    public clientiTutti$;
    public dipendentiTutti$;
    public fornitoriTutti$;

    public clientiAbilitati$;
    public dipendentiAbilitati$;

    public fornitori$;
    public fornitoriInterni$;
    public fornitoriAbilitati$;
    public fornitoriFB$;
    public fornitoriFBAll$;
    public tags$;
    public brands$

    // Magazzino
    private repartiMagazzinoObserv$!: Observable<any[]>;
    private gruppiMagazzinoObserv$!: Observable<any[]>;

    public repartiMagazzino$;
    public gruppiMagazzino$;

    constructor(
        private aziendeService: AziendeService,
        private categorieProdottiService: CategorieProdottiService,
        private prodottiService: ProdottiService,
        private costCenterService: CostCenterService,
        private gestioneMerciService: GestioneMerciService,
        private anagraficheService: AnagraficheService,
    ) { }

    ngOnDestroy() {
    }

    async fillStaticCollection(forceReload: boolean = true) {
        if (!forceReload && this.currentPair) {
            return of(this.currentPair);
        } else {

            this.aziendeObserv$ = this.aziendeService.getAziende();
            this.aziendeFullObserv$ = this.aziendeService.getAziendeFull();
            this.categorieProdottiObserv$ = this.categorieProdottiService.getCategorieProdotti();
            this.prodottiObserv$ = this.prodottiService.getProdotti();
            this.costCentersObserv$ = this.costCenterService.getCostCenters();

            this.categorieMerciObserv$ = this.gestioneMerciService.getGoodsCategories();
            this.gruppiMerciObserv$ = this.gestioneMerciService.getGoodsGroups();
            this.tipoAnagraficaObserv$ = this.anagraficheService.getEntity('Supplier');//this.gestioneMerciService.getSuppliers();
            this.tagsObserv$ = this.anagraficheService.getEntity('Tags');

            this.unitObserv$ = this.anagraficheService.getUnitList();
            this.orderUnitObserv$ = this.anagraficheService.getOrderUnitList();

            this.brandsObserv$ = this.anagraficheService.getBrands();

            // magazzino
            this.repartiMagazzinoObserv$ = this.anagraficheService.getEntity('Department'); // Reparto
            this.gruppiMagazzinoObserv$ = this.anagraficheService.getEntity('Group'); // Gruppo


            const combined$ = lastValueFrom(combineLatest([
                this.aziendeObserv$,
                this.aziendeFullObserv$,
                this.categorieProdottiObserv$,
                this.prodottiObserv$,
                this.costCentersObserv$,
                this.categorieMerciObserv$,
                this.gruppiMerciObserv$,
                this.tipoAnagraficaObserv$,
                this.tagsObserv$,
                this.unitObserv$,
                this.orderUnitObserv$,
                this.repartiMagazzinoObserv$,
                this.gruppiMagazzinoObserv$,
                this.brandsObserv$
            ]).pipe(
                map(([aziendeObserv, aziendeFullObserv, categorieProdottiObserv, prodottiObserv, costCentersObserv,
                    categorieMerciObserv, gruppiMerciObserv, tipoAnagraficaObserv, tagsObserv, unitObserv, orderUnitObserv,
                    repartiMagazzinoObserv, gruppiMagazzinoObserv, brandsObserv]) => ({
                        aziendeObserv,
                        aziendeFullObserv,
                        categorieProdottiObserv,
                        prodottiObserv,
                        costCentersObserv,
                        categorieMerciObserv,
                        gruppiMerciObserv,
                        tipoAnagraficaObserv,
                        tagsObserv,
                        unitObserv,
                        orderUnitObserv,
                        repartiMagazzinoObserv,
                        gruppiMagazzinoObserv,
                        brandsObserv
                    }))
            ));

            const pair: any = await combined$;
            console.log('fillStaticCollection', pair);
            this.aziendeFull$ = pair && pair.aziendeFullObserv ? pair.aziendeFullObserv : undefined;
            this.aziende$ = pair && pair.aziendeObserv ? pair.aziendeObserv : undefined;

            this.aziendeForPL$ = this.aziende$.filter((company: any) => !company.ExcludeCE);

            this.categorieProdotti$ = pair && pair.categorieProdottiObserv ? pair.categorieProdottiObserv : undefined;
            this.prodotti$ = pair && pair.prodottiObserv ? pair.prodottiObserv : undefined;
            this.costCenters$ = pair && pair.costCentersObserv ? pair.costCentersObserv : undefined;
            this.categorieMerci$ = pair && pair.categorieMerciObserv ? pair.categorieMerciObserv : undefined;
            this.gruppiMerci$ = pair && pair.gruppiMerciObserv ? pair.gruppiMerciObserv : undefined;
            // Fitltro per tipo anagrafica
            let tipoAnagrafica = pair && pair.tipoAnagraficaObserv ? pair.tipoAnagraficaObserv : undefined;

            this.fornitoriTutti$ = tipoAnagrafica.filter((anagrafica: any) => (anagrafica.SubjectType & 1) !== 0);
            this.clientiTutti$ = tipoAnagrafica.filter((anagrafica: any) => (anagrafica.SubjectType & 2) !== 0);
            this.dipendentiTutti$ = tipoAnagrafica.filter((anagrafica: any) => (anagrafica.SubjectType & 4) !== 0);

            // Fornitori
            this.fornitori$ = this.fornitoriTutti$.filter((x: any) => !x.IsInternal);
            this.fornitoriInterni$ = this.fornitoriTutti$.filter((x: any) => x.IsInternal);
            this.fornitoriAbilitati$ = this.fornitoriTutti$.filter((x: any) => !x.IsDisabled);
            this.fornitoriFB$ = this.fornitoriTutti$.filter((x: any) => !x.IsDisabled && !x.PurchaseImportExcluded);
            this.fornitoriFBAll$ = this.fornitoriTutti$.filter((x: any) => !x.PurchaseImportExcluded);

            // Clienti
            this.clientiAbilitati$ = this.clientiTutti$.filter((x: any) => !x.IsDisabled);

            // Dipendenti
            this.dipendentiAbilitati$ = this.dipendentiTutti$.filter((x: any) => !x.IsDisabled);

            this.tags$ = pair && pair.tagsObserv ? pair.tagsObserv : undefined;

            this.unit$ = pair && pair.unitObserv ? pair.unitObserv : undefined;
            this.orderUnit$ = pair && pair.orderUnitObserv ? pair.orderUnitObserv : undefined;

            this.repartiMagazzino$ = pair && pair.repartiMagazzinoObserv ? pair.repartiMagazzinoObserv : undefined;
            this.gruppiMagazzino$ = pair && pair.gruppiMagazzinoObserv ? pair.gruppiMagazzinoObserv : undefined;

            this.brands$ = pair && pair.brandsObserv ? pair.brandsObserv : undefined;

            this.currentPair = pair;

            this.ready$.next(this.currentPair);

            return of(this.currentPair);
        }


    }

    collectionReady() {
        return this.currentPair && Object.keys(this.currentPair).length > 0;
    }

    async refreshFornitori() {
        const allSuppliers = await this.anagraficheService.getEntity('Supplier').toPromise();
        this.fornitoriTutti$ = allSuppliers;
        this.fornitori$ = allSuppliers.filter((x: any) => !x.IsInternal);
        this.fornitoriInterni$ = allSuppliers.filter((x: any) => x.IsInternal);
        this.fornitoriAbilitati$ = allSuppliers.filter((x: any) => !x.IsDisabled);
        this.fornitoriFB$ = allSuppliers.filter((x: any) => !x.IsDisabled && !x.PurchaseImportExcluded);
    }

};
