import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectCompanyComponent } from './select-company/select-company.component';
import { TranslateModule } from '@ngx-translate/core';
import { MBComponentsModule } from '@app/mb-components/mb-components.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { SaveSettingsComponent } from './save-settings/save-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    SelectCompanyComponent,
    SaveSettingsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MBComponentsModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    SelectCompanyComponent
  ]
})
export class MbDialogsModule { }
