import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';

// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//RxJs
import { Observable, Subscription, BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { tap, map, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'kt-import-ingredients',
  templateUrl: './import-ingredients.component.html',
  styleUrls: ['./import-ingredients.component.scss']
})
export class ImportIngredientsComponent implements OnInit {

  myControl = new FormControl();
  prodottiFiltrati!: Observable<any[]>;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {

    this.prodottiFiltrati = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.myControl.setValue('');

  }

  private _filter(value: any) {
    const model = this.data.recipes;
    if (!value || value.constructor !== String) {
      return model;
    } else {
      return model.filter((prodotto: any) => {
        return prodotto.Name.toLowerCase().includes(value.toLowerCase())
      });
    }
  }

  getSupplier(item) {
    let s = "";
    if (item.Suppliers && item.Suppliers.length > 0) {
      s = "[";
      item.Suppliers.forEach((element: any) => {
        s += element.Name + ", ";
      });
      s = s.substring(0, s.length - 2);
      s += "]";
    }

    return s;
  }

  displayFn(product: any): string {
    if (!product) return '';
    return product.Name;
  }

  currentRecipe: any;
  selectRecipe(event: any) {
    this.currentRecipe = event.option.value;
  }

  onSubmit() {
    this.dialogRef.close({
      success: true,
      recipe: this.currentRecipe
    });
  }

}
