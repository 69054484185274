// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// NgBootstrap
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// Core module
import { CoreModule } from '../../core/core.module';
// CRUD Partials
import {
  ActionNotificationComponent,
  AlertComponent,
  DeleteEntityDialogComponent,
  SimpleEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent,
  ExportDialogComponent,
  ExportPdfSheetDialogComponent,
  ColumnsSelectionPopupComponent
} from './content/crud';
// Layout partials
import {
  ContextMenu2Component,
  ContextMenuComponent,
  LanguageSelectorComponent,
  SwitchGroupComponent,
  NotificationComponent,
  NavigationHistoryComponent,
  QuickActionComponent,
  ScrollTopComponent,
  SearchDefaultComponent,
  SearchDropdownComponent,
  SearchResultComponent,
  SplashScreenComponent,
  StickyToolbarComponent,
  Subheader1Component,
  Subheader2Component,
  Subheader3Component,
  Subheader4Component,
  Subheader5Component,
  SubheaderSearchComponent,
  UserProfile2Component,
  UserProfile3Component,
  UserProfileComponent,
  SupportSelectorComponent
} from './layout';
// General
import { NoticeComponent } from './content/general/notice/notice.component';
import { PortletModule } from './content/general/portlet/portlet.module';
// Errpr
import { ErrorComponent } from './content/general/error/error.component';
// Extra module
import { WidgetModule } from './content/widgets/widget.module';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CartComponent } from './layout/topbar/cart/cart.component';

import { TranslateModule } from '@ngx-translate/core';
import { ShortcutDateFilterComponent } from './layout/topbar/shortcut-date-filter/shortcut-date-filter.component';
import { DiscountsEditDialogComponent } from './content/crud/discounts-edit-dialog/discounts-edit-dialog.component';
import { PresetGoodsDialogComponent } from './content/crud/preset-goods-dialog/preset-goods-dialog.component';
import { ImportedPurchasesComponent } from './content/crud/imported-purchases/imported-purchases.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { MBComponentsModule } from '@app/mb-components/mb-components.module';
import { MapStructureComponent } from './content/crud/map-structure/map-structure.component';
import { BulkEditNewMerciComponent } from './content/crud/bulk-edit-new-merci/bulk-edit-new-merci.component';
import { AnalisiPrezziFornitoriDialogComponent } from './content/crud/analisi-prezzi-fornitori-dialog/analisi-prezzi-fornitori-dialog.component';
import { MultipleSuppliersComponent } from './content/crud/multiple-suppliers/multiple-suppliers.component';
import { ShowXmlInvoiceDialogComponent } from './content/crud/show-xml-invoice-dialog/show-xml-invoice-dialog.component';
import { SetSupplierDialogComponent } from './content/crud/set-supplier-dialog/set-supplier-dialog.component';
import { MergeOrderUnitDialogComponent } from './content/crud/merge-order-unit-dialog/merge-order-unit-dialog.component';

@NgModule({
  declarations: [
    ScrollTopComponent,
    NoticeComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    SimpleEntityDialogComponent,
    ColumnsSelectionPopupComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    ExportDialogComponent,
    ExportPdfSheetDialogComponent,
    AlertComponent,

    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    StickyToolbarComponent,
    Subheader1Component,
    Subheader2Component,
    Subheader3Component,
    Subheader4Component,
    Subheader5Component,
    SubheaderSearchComponent,
    LanguageSelectorComponent,
    SupportSelectorComponent,
    SwitchGroupComponent,
    NotificationComponent,
    NavigationHistoryComponent,
    QuickActionComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    UserProfile2Component,
    UserProfile3Component,
    CartComponent,

    ErrorComponent,

    SwitchGroupComponent,

    SupportSelectorComponent,
    ShortcutDateFilterComponent,
    DiscountsEditDialogComponent,
    PresetGoodsDialogComponent,
    ImportedPurchasesComponent,
    MapStructureComponent,
    BulkEditNewMerciComponent,
    MultipleSuppliersComponent,
    AnalisiPrezziFornitoriDialogComponent,
    ShowXmlInvoiceDialogComponent,
    SetSupplierDialogComponent,
    MergeOrderUnitDialogComponent

  ],
  exports: [
    WidgetModule,
    PortletModule,
    TranslateModule,
    ScrollTopComponent,
    NoticeComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    SimpleEntityDialogComponent,
    ColumnsSelectionPopupComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    ExportDialogComponent,
    ExportPdfSheetDialogComponent,
    AlertComponent,

    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    StickyToolbarComponent,
    Subheader1Component,
    Subheader2Component,
    Subheader3Component,
    Subheader4Component,
    Subheader5Component,
    SubheaderSearchComponent,
    LanguageSelectorComponent,
    SupportSelectorComponent,
    SwitchGroupComponent,
    NotificationComponent,
    NavigationHistoryComponent,
    QuickActionComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    UserProfile2Component,
    UserProfile3Component,
    CartComponent,
    ErrorComponent,
    ImportedPurchasesComponent,
    PresetGoodsDialogComponent,
    BulkEditNewMerciComponent,
    MultipleSuppliersComponent,
    AnalisiPrezziFornitoriDialogComponent,
    MergeOrderUnitDialogComponent
  ],
  imports: [
    InfiniteScrollModule,
    TranslateModule.forChild(),
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    InlineSVGModule,
    CoreModule,
    PortletModule,
    WidgetModule,

    // angular material modules
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    MatButtonToggleModule,

    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTooltipModule,
    DragDropModule,
    MatExpansionModule,
    MBComponentsModule
  ],
})
export class PartialsModule {
}
