import { Component, OnInit, Inject } from '@angular/core';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from '@app/core/services/config.service';

@Component({
  selector: 'kt-send-ddt',
  templateUrl: './send-ddt.component.html',
  styleUrls: ['./send-ddt.component.scss']
})
export class SendDdtComponent implements OnInit {
  company: any;
  autoSend: boolean = false;
  sameDate: boolean = false;
  sending: boolean = false;
  listaCompanies: any;
  doSendToPurchase: boolean = false;

  constructor(  public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private staticCollectionsService: StaticCollectionsService,
    private magazzinoService: MagazzinoService,
    private confSrv: ConfigService,) { }

  async ngOnInit() {
     this.confSrv.getConfigurations().subscribe(
            r => {
                this.doSendToPurchase = r.AutoCreatePurchaseOnDDT;
                this.autoSend = this.doSendToPurchase;
               
            }
        );
    await this.staticCollectionsService.fillStaticCollection(false);
    this.listaCompanies = this.staticCollectionsService.aziende$;
  }


  
  onSubmit() {
    this.sending = true;
    const body = {
      ListId: this.data.ListId,
      SupplierId: this.data.SupplierId,    
      autoSend:this.autoSend,
      sameDate:this.sameDate
    };
  
    this.magazzinoService.sendDDT(body)
    .subscribe(
       (result: any) => {
      console.log(body);
      this.sending = false;
      this.dialogRef.close({  success: true });
    }, (error: any) => {
      console.log(error);
      this.sending = false;
    })
  }

}
