import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'kt-mb-input-search-advanced',
    templateUrl: './mb-input-search-advanced.component.html',
    styleUrls: ['./mb-input-search-advanced.component.scss']
})
export class MbInputSearchAdvancedComponent implements OnInit {

    @Input() disabled: boolean = false;
    @Input() placeholder: string = '';

    @Input() model: any;
    @Output() modelChange = new EventEmitter<any>();

    @Input() ordStartsWith: number = 0;
    @Output() ordStartsWithChange = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void {
    }

    manageClickEvent() {
        this.ordStartsWith = this.ordStartsWith+1>2 ? 0 : this.ordStartsWith +1;
        this.ordStartsWithChange.emit(this.ordStartsWith);
    }

    manageKeydownEvent(event: any) {
        if (event.code === 'Enter' || event.code == 'NumpadEnter') {

            if(!this.model) {
                this.modelChange.emit('');    
            } else {
                this.modelChange.emit(this.ordStartsWith == 1 ? '#' + this.model : (this.ordStartsWith == 2 ? '#' + this.model + '#' : this.model));
            }
        }
    }

}
