import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API } from '@env/environment';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  constructor(
		private http: HttpClient,
		private switchGroupService: SwitchGroupService
	) {}

  getDashboard(config: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });
    return this.http.post<any>(currentLogin.endpointURL + '/api/Dashboard', config , { headers: httpHeaders });
  }

  getTopSeller(config: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(currentLogin.endpointURL + '/api/Dashboard' + API.API_TOP_SELLER, config , { headers: httpHeaders });
  }

  getBadSeller(config: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    let payload: any = JSON.parse(JSON.stringify(config));
    payload.Reverse = true;

    return this.http.post<any>(currentLogin.endpointURL + '/api/Dashboard' + API.API_TOP_SELLER, payload , { headers: httpHeaders });
  }

  getSalesTrend(config: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(currentLogin.endpointURL + '/api/Dashboard' + API.API_SALES_TREND, config , { headers: httpHeaders });
  }

  getRicaviMedi(config: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(currentLogin.endpointURL + '/api/Dashboard' + API.API_RICAVI_MEDI, config , { headers: httpHeaders });
  }
}
