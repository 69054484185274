import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizedDateTimePipe } from '@app/core/pipes/localized-date-time.pipe';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { LayoutConfigService, SwitchGroupService, TranslationService } from '@app/core/_base/layout';
import * as util from '@app/core/services/utilityfunctions';
import { TranslateService } from '@ngx-translate/core';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import moment from 'moment';
import { PDFService } from '@app/core/services/pdf.service';
import { BehaviorSubject } from 'rxjs';
import { Chart } from 'chart.js';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'kt-dettaglio',
    templateUrl: './dettaglio.component.html',
    styleUrls: ['./dettaglio.component.scss'],
    providers: [DatePipe]
})
export class DettaglioComponent implements OnInit {

    maxValuesPerPage: number = 6;
    maxValuesPerPageTmp: number = 6;
    excludePagination: boolean = false;
    graphData: any;
    myChart: any;
    chart: any;
    @ViewChild('chart', { static: false }) set _set(content: any) {
        if (content) {
            this.chart = content;

            if ($.fn.dataTable.isDataTable('#avp_detail')) {
                $('#avp_detail').DataTable().destroy();
            }

            if (this.myChart) {
                this.initChartJS();
            } else {
                this.refreshGraph();
            }
        }
    }

    @ViewChild('table', { static: false }) set _setTable(content: any) {
        if (content) {
            this.initDataTable();
        }
    }

    avpDetails: any;
    currentFilterCfg: any;
    utility: any;
    locale!: string;
    table: any;
    totRows!: number;
    PDFPreferences: any;
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    SendingId: any = null;

    totAmount!: number;
    totDiscount!: number;
    totVat!: number;

    filterManual: number = 0;

    showColumnsFilters: boolean = false;
    filters: any = {
        Company: undefined,
        Date: '',
        Supplier: undefined,
        CostCenter: undefined,
        Document: '',
        Amount: '',
        Vat: '',
        Discount: '',
        Description: ''
    }

    currentView: string = 'table';

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public staticCollectionsService: StaticCollectionsService,
        private switchGroupService: SwitchGroupService,
        private localizedDateTimePipe: LocalizedDateTimePipe,
        private translate: TranslateService,
        public dialog: MatDialog,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private pdfService: PDFService,
        private changedetecortRef: ChangeDetectorRef,
        private datePipe: DatePipe,
        private layoutConfigService: LayoutConfigService
    ) {
        this.utility = util;
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.avpDetails = this.data.item;
        this.currentFilterCfg = this.data.currentFilterCfg;
        this.locale = this.data.locale;
        this.SendingId = this.data.SendingId;

        //this.initDataTable();

        $.fn.dataTable.ext.order['localized_date'] = (settings, col) => {
            if (!this.table) return false;
            return this.table.column(col, { order: 'index' }).nodes().map(function (td, i) {
                const date: any = $(td).html();
                const rest = moment(date, 'DD/MM/YYYY').unix();
                return rest;
            });
        }
    }

    changeView() {
        this.currentView = this.currentView == 'table' ? 'graph' : 'table';

        setTimeout(() => {
            this.changedetecortRef.detectChanges();
        }, 100);
    }

    getTooltip() {
        return this.currentView == 'graph' ? this.translate.instant('ANALISI_VARIAZIONE_PREZZI.TOOLTIP_BTN_GRAPH') : this.translate.instant('ANALISI_VARIAZIONE_PREZZI.TOOLTIP_BTN_TABLE');
    }

    filter() {
        this.table.draw();
    }

    getTitle() {
        return this.data.name + ' - ' + this.avpDetails.Supplier;
    }

    exportAsXLSX() {
        const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
        const _description: string = this.translate.instant('EXPORT_XLSX.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');
        const _success = this.translate.instant('EXPORT_XLSX.MESSAGE');
        const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(expand => {
            if (expand) this.table.button(1).trigger();
        });
    }

    exportAsPDF(expand: boolean = false) {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {

            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('GESTIONE_FATTURE.TITLE'),
                    period: ''
                };
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                };
                result['language'] = this.translationService.getSelectedLanguage();

                this.PDFPreferences = result;

                this.table.button(0).trigger();
            };
        });
    }

    firstTime: boolean = true;
    initDataTable() {
        if ($.fn.dataTable.isDataTable('#avp_detail')) {
            $('#avp_detail').DataTable().destroy();
        }

        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();

            const dtOptions: any = {
                orderCellsTop: true,
                destroy: true,
                paging: false,
                searching: true,
                dom: 'rtlp',
                ordering: true,
                scrollX: true,
                scrollY: '300px',
                scrollCollapse: true,
                autoWidth: true,
                order: [[0, 'desc']],
                data: this.avpDetails.Prices,
                columnDefs: [
                    {
                        targets: 0, data: 'Date', name: 'Date', render: (data, type, p, meta) => {
                            return this.localizedDateTimePipe.transform(data, undefined, true);
                        }, orderDataType: 'localized_date', width: '25%'
                    },
                    {
                        targets: 1, data: 'Price', name: 'Price', render: (data, type, p, meta) => {
                            return util.formatNumber(data, this.locale);
                        }, width: '25%'
                    },
                    {
                        targets: 2, data: 'Difference', name: 'Difference', render: (data, type, p, meta) => {
                            return util.formatNumber(data, this.locale);
                        }, width: '25%'
                    },
                    {
                        targets: 3, data: 'PercentDifference', name: 'PercentDifference', render: (data, type, p, meta) => {
                            return util.formatNumber(data, this.locale);
                        }, width: '25%'
                    },
                    // Serve per evitare di riceve avvisi quando arrivano campi a null
                    {
                        defaultContent: '-',
                        targets: '_all'
                    }
                ],
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdfHtml5',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)',
                        },
                        customize: (doc: any) => {
                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, ['auto', 'auto', 'auto', 'auto'], '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;
                            console.log(doc.styles);

                            doc.styles = docDefinition.styles;

                            doc.content[1].table.body.forEach(function (line, i) {
                                const length = doc.content[1].table.body[i].length;
                                doc.content[1].table.body[i][length - 3].alignment = 'right';
                                doc.content[1].table.body[i][length - 2].alignment = 'right';
                                doc.content[1].table.body[i][length - 1].alignment = 'right';
                            });

                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            format: {
                                body: (data, row, column, node) => {
                                    return column >= 1 ? this.utility.parseNumber(data, this.locale, 2) : data;
                                }
                            },
                            columns: ':visible:not(.actions)',
                            orthogonal: 'export'
                        },
                        customize: async (xlsx: any) => {
                            // Get sheet.
                            var sheet = xlsx.xl.worksheets['sheet1.xml'];
                            // Nelle colonne B/C/D, dalla riga 3 in avanti importi lo stile 64
                            $(`row c[r^="B2"]`, sheet).attr('s', '64');
                            $(`row c[r^="C2"]`, sheet).attr('s', '64');
                            $(`row c[r^="D2"]`, sheet).attr('s', '64');
                        }
                    }
                ]

            };

            this.table = $('#avp_detail').DataTable(dtOptions);
        }, 100);
    }

    refreshGraph() {
        let apiDataItems = this.avpDetails.Prices;

        let labels: any = apiDataItems.map((item: any) => this.datePipe.transform(item.Date, 'dd/MM/yyyy', this.locale));
        let datasets: any = [];

        datasets.push(
            {
                type: 'line',
                borderWidth: 2,
                fill: false,
                label: this.translate.instant('ANALISI_VARIAZIONE_PREZZI.COLUMNS.DIFFERENCE_PERC'),
                backgroundColor: this.layoutConfigService.getConfig('colors.state.warning'),
                borderColor: this.layoutConfigService.getConfig('colors.state.warning'),
                data: apiDataItems.map((item: any) => this.utility.parseNumber(item['PercentDifference'], this.data.locale, 2)),
                yAxisID: "y-axis-delta",
                tipo: 'delta',
                cubicInterpolationMode: 'monotone'
            },
            {
                type: 'bar',
                label: this.translate.instant('ANALISI_VARIAZIONE_PREZZI.COLUMNS.PRICE'),
                backgroundColor: this.layoutConfigService.getConfig('colors.state.success'),
                data: apiDataItems.map((item: any) => this.utility.parseNumber(item['Price'], this.data.locale, 2)),
                yAxisID: "y-axis-price",
            }
        )

        this.graphData = {
            labels: labels,
            datasets: datasets
        };

        this.initChartJS();
    }

    /** Init chart */
    initChartJS() {
        // this.firstLoad = false;
        // For more information about the chartjs, visit this link
        // https://www.chartjs.org/docs/latest/getting-started/usage.html

        if (this.myChart) {
            this.myChart.destroy();
        }

        // Serve per esportazione in PDF per background bianco, altrimenti rimane nero.
        const bgColor = {
            id: 'bgColor',
            beforeDraw: (chart: any, options: any) => {
                const { ctx, width, height } = chart;
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, width, height);
                ctx.restore();
            }
        }

        const moveChart = {
            id: 'moveChart',
            afterEvent: (chart: any, args: any) => {
                if (this.excludePagination) return;

                const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;
                canvas.addEventListener('mousemove', (event) => {
                    const x = args.event.x;
                    const y = args.event.y;

                    if (x >= (left - 15) && x <= (left + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
                        const leftEnabled = chart.config.options.scales.x.min > 0
                        canvas.style.cursor = leftEnabled ? 'pointer' : 'not-allowed';
                    } else if (x >= (right - 15) && x <= (right + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
                        const dataLength = chart.config.data.labels.length;
                        const rightEnabled = chart.config.options.scales.x.max < dataLength - 1;
                        canvas.style.cursor = rightEnabled ? 'pointer' : 'not-allowed';
                    } else {
                        canvas.style.cursor = 'default';
                    }
                });
            },
            afterDraw: (chart: any, args: any, pluginOptions: any) => {
                const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = chart;

                if (this.excludePagination) {

                    // Scrivo il totale dei risultati
                    ctx.beginPath();
                    const rect = canvas.getBoundingClientRect();
                    ctx.fillStyle = "#333333";
                    ctx.font = `600 12px Poppins`;
                    const pagination = `${chart.config.data.labels.length} ${this.translate.instant('COMMONS.RESULTS')}`;
                    const textWidth = ctx.measureText(pagination).width;
                    ctx.fillText(pagination, right - textWidth, top - 12);
                    ctx.closePath();

                } else {

                    class CircleChevron {
                        //constructor(x1, y1) {}

                        draw(ctx, x1, pixel, enabled: boolean = true) {
                            const angle = Math.PI / 180;

                            ctx.beginPath();
                            ctx.lineWidth = 3;
                            ctx.strokeStyle = 'rgba(102, 102, 102, .5)';
                            ctx.fillStyle = 'white';
                            ctx.arc(x1, height / 2 + top, 10, angle * 0, angle * 360, false);
                            ctx.stroke();
                            ctx.fill();
                            ctx.closePath();

                            // chevron Arrow
                            ctx.beginPath();
                            ctx.lineWidth = 3;
                            ctx.strokeStyle = enabled ? '#5867DD' : '#CCCCCC';
                            ctx.moveTo(x1 + pixel, height / 2 + top - 5.5);
                            ctx.lineTo(x1 - pixel, height / 2 + top);
                            ctx.lineTo(x1 + pixel, height / 2 + top + 5.5);
                            ctx.stroke();
                            ctx.closePath();
                        }
                    }

                    const leftEnabled = chart.config.options.scales.x.min > 0
                    let drawCircleLeft = new CircleChevron();
                    drawCircleLeft.draw(ctx, left, 3, leftEnabled);

                    const dataLength = chart.config.data.labels.length;
                    const rightEnabled = chart.config.options.scales.x.max < dataLength - 1;
                    let drawCircleRight = new CircleChevron();
                    drawCircleRight.draw(ctx, right, -3, rightEnabled);

                    // Scrivo la paginazione
                    ctx.beginPath();
                    const rect = canvas.getBoundingClientRect();
                    ctx.fillStyle = "#333333";
                    ctx.font = `600 12px Poppins`;
                    const pagination = `${this.translate.instant('EXPORT_PDF.PAGE')} ${Math.ceil(chart.config.options.scales.x.min / this.maxValuesPerPage) + 1} ${this.translate.instant('EXPORT_PDF.OF')} ${Math.ceil(dataLength / this.maxValuesPerPage)}`;
                    const textWidth = ctx.measureText(pagination).width;
                    ctx.fillText(pagination, right - textWidth, top - 12);
                    ctx.closePath();
                }
            }
        }

        this.myChart = new Chart(this.chart.nativeElement, {
            type: 'line',
            data: this.graphData,
            plugins: [
                moveChart,
                bgColor
            ],
            options: {
                interaction: {
                    intersect: false,
                    mode: 'index',
                },
                plugins: {
                    title: {
                        display: false,
                    },
                    tooltip: {
                        padding: 10,
                        caretPadding: 10,
                        callbacks: {
                            title: (tooltipItem) => {
                                return tooltipItem[0].label
                            },
                            label: (tooltipItem) => {
                                return this.utility.formatNumber(tooltipItem.dataset.data[tooltipItem.dataIndex], this.data.locale, 2, true, true);
                            }
                        }
                    },
                    legend: {
                        display: true,
                        position: 'top',
                        onClick: (e, legendItem) => {
                            var index = legendItem.datasetIndex;
                            if(!index) return;
                            var ci = this.myChart;
                            var meta = ci.getDatasetMeta(index);

                            // See controller.isDatasetVisible comment
                            meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

                            this.myChart.config.data.datasets[index].hidden = meta.hidden;

                            // We hide a dataset ... rerender the chart
                            ci.update();

                            this.changedetecortRef.detectChanges();
                        }
                    },
                    datalabels: {
                        display: false, // Imposta a false per nascondere i valori all'interno delle barre
                    }
                }
                ,
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        min: this.graphData.labels.length - this.maxValuesPerPage,
                        max: this.graphData.labels.length,
                        display: true,
                        grid: {
                            display: false
                        },
                        ticks: {
                            padding: 0,
                        },
                    },
                    "y-axis-delta": {
                        position: 'right',
                        display: true,
                        ticks: {
                            callback: (value: any, index: number, values: any) => {
                                return value + '%';
                            }
                        },
                        title: {
                            display: true,
                            text: this.translate.instant('ANALISI_VARIAZIONE_PREZZI.COLUMNS.DIFFERENCE_PERC')
                        }
                    },
                    "y-axis-price": {
                        position: 'left',
                        display: true,
                        grid: {
                            display: false
                        },
                        ticks: {
                            callback: (value: any, index: number, values: any) => {
                                if (parseFloat(value) > 0)
                                    return this.utility.kFormatter(value, this.data.locale);
                            }
                        },
                        title: {
                            display: true,
                            text: this.translate.instant('ANALISI_VARIAZIONE_PREZZI.COLUMNS.PRICE')
                        }
                    }
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 18,
                        top: 0,
                        bottom: 0
                    }
                }
            }
        });

        const moveScroll = () => {
            this.myChart.canvas.addEventListener('click', (event: any) => {
                const { ctx, canvas, chartArea: { left, right, top, bottom, width, height } } = this.myChart;
                const rect = canvas.getBoundingClientRect();
                const x = event.clientX - rect.left;
                const y = event.clientY - rect.top;

                if (x >= (left - 15) && x <= (left + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {
                    this.myChart.config.options.scales.x.min -= this.maxValuesPerPage;
                    this.myChart.config.options.scales.x.max -= this.maxValuesPerPage;
                    if (this.myChart.config.options.scales.x.min <= 0) {
                        this.myChart.config.options.scales.x.min = 0
                        this.myChart.config.options.scales.x.max = this.maxValuesPerPage - 1;
                    }
                    this.myChart.update();
                } else if (x >= (right - 15) && x <= (right + 15) && y >= height / 2 + top - 15 && y <= height / 2 + top + 15) {

                    this.myChart.config.options.scales.x.min += this.maxValuesPerPage;
                    this.myChart.config.options.scales.x.max += this.maxValuesPerPage;

                    const dataLength = this.myChart.config.data.labels.length;
                    if (this.myChart.config.options.scales.x.max >= dataLength) {
                        this.myChart.config.options.scales.x.min = dataLength - this.maxValuesPerPage;
                        this.myChart.config.options.scales.x.max = dataLength - 1;
                    }
                    this.myChart.update();
                }

            });
        }

        this.myChart.ctx.onclick = moveScroll();
    }

    excludeGraphPagination(event: any) {
        this.excludePagination = event.checked;
        if (this.excludePagination) {
            this.myChart.config.options.scales.x.min = 0;
            this.myChart.config.options.scales.x.max = this.myChart.config.data.labels.length - 1;
            this.myChart.config.options.scales.x.ticks.padding = 0;
        } else {
            this.myChart.config.options.scales.x.min = 0;
            this.myChart.config.options.scales.x.max = this.maxValuesPerPage - 1;
            this.myChart.config.options.scales.x.ticks.padding = 35;
        }
        this.myChart.update();
    }

    onKeyUpMaxValues(event: any) {
        if (event.code == 'Enter' || event.code == 'NumpadEnter') {
            this.maxValuesPerPage = this.maxValuesPerPageTmp;
            this.myChart.config.options.scales.x.max = this.maxValuesPerPage - 1;
            this.myChart.update();
        }
    }

    printChart() {
        this.myChart.update();
        setTimeout(() => {
            var canvas = <HTMLCanvasElement>document.getElementById('avp_detail_chart');

            let header = this.getTitle();

            this.pdfService.chartToPdf(canvas, this.getTitle(), undefined, header);
        }, 100);
    }
}

