import { Component, OnInit, Inject, ViewChild } from '@angular/core';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MbSelect2Component } from '@app/mb-components/mb-select2/mb-select2.component';

@Component({
    selector: 'kt-add-brands-dlg',
    templateUrl: './add-brands-dlg.component.html',
    styleUrls: ['./add-brands-dlg.component.scss']
})
export class AddBrandsDlgComponent implements OnInit {

    brandsList: any;
    brands: any;

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        this.brandsList = this.data.brandsList;
    }

    selectBrand(brandId: any) {
        if (!this.brands) this.brands = [];
        this.brands.push({
            Id: brandId,
            Name: this.brandsList.find(b => b.Id == brandId).Name
        });
    }
    
    unselectBrand(brandId: any) {
        this.brands = this.brands.filter(b => b.Id !== brandId);
    }

    clearBrands(event: any) {
        this.brands = [];
    }

    onSubmit() {
        this.dialogRef.close(this.brands.map(b => b.Id));
    }

}
