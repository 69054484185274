import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kt-columns-selection-popup',
  templateUrl: './columns-selection-popup.component.html',
  styleUrls: ['./columns-selection-popup.component.scss']
})
export class ColumnsSelectionPopupComponent implements OnInit {
  viewLoading: boolean = false;

  constructor(
		public dialogRef: MatDialogRef<ColumnsSelectionPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

  ngOnInit() {
    console.log('data', this.data);
  }

  onNoClick(): void {
    this.dialogRef.close({success: false});
  }

  onYesClick(): void {
      this.dialogRef.close({
        success: true,
        columnsList: this.data.columnsList
      }); // Keep only this row
  }

}
