import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwitchGroupService } from '@app/core/_base/layout';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ScadenziarioService {

    constructor(
        private http: HttpClient,
        private switchGroupService: SwitchGroupService
    ) { }

    getScadenzeDt(isGrouped: boolean = true, payload) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const grouped = isGrouped ? `/Grouped` : '';

        return this.http.post<any>(currentLogin.endpointURL + `/api/Schedule${grouped}`, {
            filters: payload
          }, { headers: httpHeaders });
    }

    deleteScadenza(scadenzaId: string): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.delete<any>(currentLogin.endpointURL + `/api/Schedule/Delete?id=${scadenzaId}`, { headers: httpHeaders });
    }

    stornoScadenza(ids: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        let queryParams = '';
        ids.forEach((id, index) => {
            if(index > 0) {
                queryParams += `&`;
            }
            queryParams += `id=${id}`;
        });

        return this.http.get<any>(currentLogin.endpointURL + `/api/Schedule/ReversePay?${queryParams}`, { headers: httpHeaders });
    }

    payScadenza(payload: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.post<any>(currentLogin.endpointURL + `/api/Schedule/Pay`, payload, { headers: httpHeaders });
    }

    bulkPayment(payload: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.post<any>(currentLogin.endpointURL + `/api/Schedule/BulkPay`, payload, { headers: httpHeaders });
    }

    getBanks(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + `/api/Bank`, { headers: httpHeaders });
    }

    getPaymentModality(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + `/api/PaymentModality`, { headers: httpHeaders });
    }

    getBankOperations(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + `/api/BankOperation`, { headers: httpHeaders });
    }

    createSchedule(payload: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.post<any>(currentLogin.endpointURL + `/api/Schedule/CreateMultiple`, payload, { headers: httpHeaders });
    }

    updateSchedule(payload: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.put<any>(currentLogin.endpointURL + `/api/Schedule/Update`, payload, { headers: httpHeaders });
    }

    getLinkedItems(itemId: string): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + `/api/Schedule/GetLinkedItems?id=${itemId}`, { headers: httpHeaders });
    }
}
