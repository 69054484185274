import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { BulkEditNewMerciComponent } from '../bulk-edit-new-merci/bulk-edit-new-merci.component';
import * as utils from '@app/core/services/utilityfunctions';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import { TranslateService } from '@ngx-translate/core';

export enum Preset {
  NO_PRESET = -1,
  GOOD_WEIGHT_KG_1_1 = 0,
  GOOD_WEIGHT_LT_1_1 = 1,
  GOOD_BOTTLE_BOT_1_1 = 2,
  GOOD_BOTTLE_POURING_LT_1_X = 3,
  GOOD_PIECE_KG_1_X = 4,
  GOOD_PACKAGE_PZ_1_X = 5
}

@Component({
  selector: 'kt-preset-goods-dialog',
  templateUrl: './preset-goods-dialog.component.html',
  styleUrls: ['./preset-goods-dialog.component.scss']
})
export class PresetGoodsDialogComponent implements OnInit {

  viewLoading: boolean = false;
  tTitle: string = '';
  ckExplodeRows: boolean = false;

  preset: any;

  categorieMerci: any;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<PresetGoodsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public staticCollectionsService: StaticCollectionsService,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService
  ) {
    this.preset = Preset;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  onYesClick(selection: Preset): void {
    if (this.data && this.data.showCategorySelector) {
      this.dialogRef.close({
        selection: selection,
        category: this.categorieMerci
      });
    } else {
      this.dialogRef.close(selection);
    }
  }

  onCloseClick(): void {
    this.dialogRef.close(undefined);
  }

  showManualDialog() {

    // Preparo l'array da mandare
    let selection: any = [];
    this.data.importableInvoices.forEach((invoice: any) => {

      if (invoice.Check && invoice.Check.Rows && invoice.Check.Rows.length > 0) {
        invoice.Check.Rows.forEach((merce: any) => {
          let merceCloned = JSON.parse(JSON.stringify(merce));
          if (merceCloned.Quantity > 0 && (merceCloned.ErrorType === 'GoodsNotFound' || merceCloned.ErrorType === 'GoodsNotMappedOnPL')) {
            const alreadyExists = selection.findIndex((selected: any) => selected.Code == merceCloned.Code && selected.SupplierId == invoice.Check.SupplierId) >= 0;
            if (!alreadyExists) {
              merceCloned.Price = utils.formatNumber(merceCloned.Price, this.data.locale, 2);
              merceCloned['SupplierId'] = invoice.Check.SupplierId;
              merceCloned['FileName'] = invoice.FileName;
              selection.push(merceCloned);
            }
          }
        })
      }

    });

    if (selection.length > 0) {
      const dialogRef = this.dialog.open(BulkEditNewMerciComponent, {
        width: '90%',
        //height: '500px',
        data: {
          selection: selection
        }
      }).afterClosed().subscribe(async (result: any) => {
        if(result && result.success) {
          this.dialogRef.close(result);
        }        
      });
    } else {
      this.layoutUtilsService.showActionNotification(this.translate.instant('BULK_EDIT_NEW_MERCI.NO_GOODS_TO_SAVE'), MessageType.Create);
    }

  }

}
