// Angular
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, ViewRef } from '@angular/core';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationStart,
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
    Router
} from '@angular/router';
// Object-Path
import * as objectPath from 'object-path';
// Loading bar
import { LoadingBarService } from '@ngx-loading-bar/core';
// Layout
import { LayoutConfigService, LayoutRefService, TranslationService } from '../../../core/_base/layout';
// HTML Class Service
import { HtmlClassService } from '../html-class.service';

import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'kt-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
    // Public properties
    menuHeaderDisplay: boolean = false;
    fluid: boolean = false;
    showFilter: boolean = false;
    showWarehouseSummary: boolean = false;
    oldUrl!: string;
    showWarehouseSelector: boolean = false;

    @ViewChild('ktHeader', { static: true }) ktHeader!: ElementRef;

    /**
     * Component constructor
     *
     * @param router: Router
     * @param layoutRefService: LayoutRefService
     * @param layoutConfigService: LayoutConfigService
     * @param loader: LoadingBarService
     * @param htmlClassService: HtmlClassService
     */
    constructor(
        private router: Router,
        private layoutRefService: LayoutRefService,
        private layoutConfigService: LayoutConfigService,
        public loader: LoadingBarService,
        public htmlClassService: HtmlClassService,
        public ref: ChangeDetectorRef,
        private translate: TranslateService,
        private translationService: TranslationService
    ) {
        // page progress bar percentage
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                // set page progress bar loading to start on NavigationStart event router
                this.loader.start();
            }
            if (event instanceof RouteConfigLoadStart) {
                this.loader.increment(35);
            }
            if (event instanceof RouteConfigLoadEnd) {
                this.loader.increment(75);
            }
            if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
                // set page progress bar loading to end on NavigationEnd event router
                this.loader.complete();

                this.checkShowFilter();
                this.checkShowWarehouseSummary();
                this.getTitle();
            }
        });
    }

    /**
     * @ Lifecycle sequence(s: any) => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        const config = this.layoutConfigService.getConfig();

        // get menu header display option
        this.menuHeaderDisplay = objectPath.get(config, 'header.menu.self.display');

        // header width fluid
        this.fluid = objectPath.get(config, 'header.self.width') === 'fluid';

        // animate the header minimize the height on scroll down. to be removed, not applicable for default demo
        /*if (objectPath.get(config, 'header.self.fixed.desktop.enabled') || objectPath.get(config, 'header.self.fixed.desktop')) {
            // header minimize on scroll down
            this.ktHeader.nativeElement.setAttribute('data-ktheader-minimize', '1');
        }*/
        this.checkShowFilter();
        this.checkShowWarehouseSummary();
    }

    ngAfterViewInit(): void {
        // keep header element in the service
        this.layoutRefService.addElement('header', this.ktHeader.nativeElement);
        this.showFilter = false;
        this.showWarehouseSummary = false;

        this.translationService.performSwitchLanguage.subscribe((lang) => {
            setTimeout(() => {
                this.getTitle();
                this.ref.detectChanges();
            }, 750);
        });
    }

    checkShowWarehouseSummary() {
        const showSummary = this.router.url.includes('magazzino/inserimento-comune') || this.router.url.includes('magazzino/inserimento-ordini');
        this.showWarehouseSummary = showSummary;
    }

    checkShowFilter() {

        this.showWarehouseSelector = this.router.url.includes('magazzino/statistiche');

        const hideFilter = this.router.url.includes('fornitori') ||
            this.router.url.includes('unita-misura') ||
            this.router.url.includes('unita-secondarie') ||
            this.router.url.includes('reparti') ||
            this.router.url.includes('razioni') ||
            this.router.url.includes('brands') ||
            this.router.url.includes('endpoints') ||
            this.router.url.includes('categorie-merci') ||
            this.router.url.includes('inserimento-comune') ||
            this.router.url.includes('inserimento-ordini') ||
            this.router.url.includes('categorie-prodotti') ||
            this.router.url.includes('gestione-ricette/edit') ||
            this.router.url.includes('manutenzione') ||
            this.router.url.includes('vendite-manuali') ||
            this.router.url.includes('estrazione-dati')
            ;

        if (!hideFilter && this.oldUrl !== this.router.url) {
            this.showFilter = false;
            setTimeout(() => {
                this.showFilter = true;

                if (this.ref && !(this.ref as ViewRef).destroyed) {
                    this.ref.detectChanges();
                }
            }, 1000);
        } else if (hideFilter) {
            if (document.body.classList.contains('kt-subheader--fixed')) document.body.classList.remove('kt-subheader--fixed');
            this.showFilter = false;
        } else {
            if (!document.body.classList.contains('kt-subheader--fixed')) document.body.classList.add('kt-subheader--fixed');
            this.showFilter = true;
        }

        this.oldUrl = this.oldUrl !== this.router.url ? this.router.url : this.oldUrl;
    }

    menuIsHidden: boolean = false;
    hideMenu() {
        if ($('kt-aside-left').css('display') != 'none') {
            $('kt-aside-left').hide();
            $('#kt_wrapper').css('padding-left', '0');
            $('#kt_header').css('left', '0px');
            this.menuIsHidden = true;
        } else {
            $('kt-aside-left').show();
            $('#kt_wrapper').css('padding-left', '90px');
            $('#kt_header').css('left', '90px');
            this.menuIsHidden = false;
        }
        this.ref.detectChanges();
    }

    getTooltipHideMenu() {
        return this.menuIsHidden ? this.translate.instant('MENU.SHOW_MENU') : this.translate.instant('MENU.HIDE_MENU');
    }

    title: string = '';
    getTitle() {
        this.title = '';
        switch (this.router.url) {
            case '/amministrazione/conto-economico':
                this.title = this.translate.instant('CONTO_ECONOMICO.TITLE');
                break;
            case '/amministrazione/scadenziario':
                this.title = this.translate.instant('SCADENZIARIO.TITLE');
                break;
            case '/analisi/analisi-vendite':
                this.title = this.translate.instant('ANALISI_VENDITE.TITLE');
                break;
            case '/analisi/analisi-foodcost':
                this.title = this.translate.instant('ANALISI_FOODCOST.TITLE');
                break;
            case '/analisi/analisi-consumi':
                this.title = this.translate.instant('ANALISI_CONSUMI.TITLE');
                break;
            case '/storico-vendite':
                this.title = this.translate.instant('STORICO_VENDITE.TITLE');
                break;
            case '/analisi/analisi-prezzi-fornitori':
                this.title = this.translate.instant('ANALISI_PREZZI_FORNITORI.TITLE');
                break;
            case '/analisi/variazione-prezzi':
                this.title = this.translate.instant('ANALISI_VARIAZIONE_PREZZI.TITLE');
                break;
            case '/analisi/estrazione-dati':
                this.title = this.translate.instant('ESTRAZIONE_DATI.TITLE');
                break;
            case '/gestione-dati/gestione-fatture':
                this.title = this.translate.instant('GESTIONE_FATTURE.TITLE');
                break;
            case '/magazzino/gestione-merci':
                this.title = this.translate.instant('GESTIONE_MERCI.TITLE');
                break;
            case '/gestione-ricette':
                this.title = this.translate.instant('GESTIONE_RICETTE.TITLE');
                break;
            case '/vendite-manuali':
                this.title = this.translate.instant('VENDITE_MANUALI.TITLE');
                break;
            case '/rettifiche-vendita':
                this.title = this.translate.instant('RETTIFICHE_VENDITA.TITLE');
                break;
            case '/prodotti-finiti':
                this.title = this.translate.instant('PRODOTTI_FINITI.TITLE');
                break;
            case '/inventario':
                this.title = this.translate.instant('MENU.VENDITE_MANUALI_INVENTARIO');
                break;
            case '/magazzino/ordini':
                this.title = this.translate.instant('MAGAZZINO.ORDINI.TITLE');
                break;
            case '/magazzino/ordini-interni':
                this.title = this.translate.instant('MAGAZZINO.ORDINI_INTERNI.TITLE');
                break;
            case '/magazzino/ordini-semplificati':
                this.title = this.translate.instant('MAGAZZINO.ORDINI_SEMPLIFICATI.TITLE');
                break;
            case '/magazzino/ddt':
                this.title = this.translate.instant('MAGAZZINO.DDT.TITLE');
                break;
            case '/magazzino/acquisti':
                this.title = this.translate.instant('MAGAZZINO.ACQUISTI.TITLE');
                break;
            case '/magazzino/inventari':
                this.title = this.translate.instant('MAGAZZINO.INVENTARI.TITLE');
                break;
            case '/magazzino/trasferimenti':
                this.title = this.translate.instant('MAGAZZINO.TRASFERIMENTI.TITLE');
                break;
            case '/magazzino/sprechi':
                this.title = this.translate.instant('MAGAZZINO.SPRECHI.TITLE');
                break;
            case '/magazzino/giacenze':
                this.title = this.translate.instant('MAGAZZINO.GIACENZE.TITLE');
                break;

            //ANAGRAFICHE

            case '/centri-costo':
                this.title = this.translate.instant('ANAGRAFICHE.COSTCENTERS.TITLE');
                break;
            case '/brands':
                this.title = this.translate.instant('ANAGRAFICHE.BRANDS.TITLE');
                break;
            case '/categorie-merci':
                this.title = this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.TITLE');
                break;
            case '/fornitori':
                this.title = this.translate.instant('ANAGRAFICHE.TITLE');
                break;
            case '/categorie-prodotti':
                this.title = this.translate.instant('ANAGRAFICHE.CATEGORIE_PRODOTTI.TITLE');
                break;
            case '/gruppi':
                this.title = this.translate.instant('ANAGRAFICHE.GRUPPI.TITLE');
                break;
            case '/gruppi-merci':
                this.title = this.translate.instant('ANAGRAFICHE.GRUPPI.TITLE');
                break;
            case '/reparti':
                this.title = this.translate.instant('ANAGRAFICHE.REPARTI.TITLE');
                break;
            case '/razioni':
                this.title = this.translate.instant('ANAGRAFICHE.RAZIONI.TITLE');
                break;
            case '/unita-misura':
                this.title = this.translate.instant('ANAGRAFICHE.UNIT.TITLE');
                break;
            case '/unita-secondarie':
                this.title = this.translate.instant('ANAGRAFICHE.ORDER_UNIT.TITLE');
                break;
            case '/wastetypes':
                this.title = this.translate.instant('ANAGRAFICHE.WASTETYPES.TITLE');
                break;

            //CONFIGURAZIONE
            case '/companyUsers':
                this.title = this.translate.instant('USERS.TITLE');
                break;
            case '/stores':
                this.title = this.translate.instant('STORES.TITLE');
                break;
            case '/endpointSettings':
                this.title = this.translate.instant('ENDPOINTS_SETTINGS.TITLE');
                break;

            //MANUTENZIONE
            case '/manutenzione/importexport':
                this.title = this.translate.instant('MANUTENZIONE.IMPORT_EXPORT.TITLE');
                break;
            case '/manutenzione/tasks':
                this.title = this.translate.instant('MANUTENZIONE.TASKS.TITLE');
                break;
            case '/manutenzione/logs':
                this.title = this.translate.instant('MANUTENZIONE.LOGS.TITLE');
                break;
        }

    }
}
