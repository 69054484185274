import { ChangeDetectorRef, Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import { RoleService, SwitchGroupService, TranslationService } from '@app/core/_base/layout';
import { LocalizedDateTimePipe } from '@app/core/pipes/localized-date-time.pipe';
import { FiltriService } from '@app/core/services/filtri.service';
import { PDFService } from '@app/core/services/pdf.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { TranslateService } from '@ngx-translate/core';

import * as util from '@app/core/services/utilityfunctions';
import { BehaviorSubject, Subscription, combineLatest, lastValueFrom } from 'rxjs';
import { ScadenziarioEditComponent } from '../scadenziario-edit/scadenziario-edit.component';
import { ScadenziarioService } from '../scadenziario.service';
import _ from 'lodash';
import { NgbDateParserFormatter, NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { ScadenziarioPayComponent } from '../scadenziario-pay/scadenziario-pay.component';
import { BulkActionsService } from '@app/core/services/bulk-actions.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { BulkPaymentDialogComponent } from '../scadenziario-tabella/bulk-payment-dialog/bulk-payment-dialog.component';

declare var window: any;

@Injectable()
class CustomDatepickerI18n extends NgbDatepickerI18n {
    private languageSubscription!: Subscription;

    constructor(private translate: TranslateService) {
        super();
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        // Personalizza l'etichetta per l'accessibilità (aria-label) dei giorni
        return `${date.day}-${date.month}-${date.year}`;
    }

    getWeekdayShortName(weekday: number): string {
        // Restituisci l'abbreviazione dei giorni della settimana (Es. Dom, Lun, Mar, ...)
        return this.translate.instant(`dayNamesShort.${weekday}`);
    }

    getWeekdayLabel(weekday: number): string {
        // Restituisci il nome completo dei giorni della settimana (Es. Domenica, Lunedì, Martedì, ...)
        return this.translate.instant(`dayNamesShort.${weekday}`);
    }

    getMonthShortName(month: number): string {
        // Restituisci l'abbreviazione dei mesi (Es. Gen, Feb, Mar, ...)
        return this.translate.instant(`monthNamesShort.${month}`);
    }

    getMonthFullName(month: number): string {
        // Restituisci il nome completo dei mesi (Es. Gennaio, Febbraio, Marzo, ...)
        return this.translate.instant(`monthNames.${month}`);
    }

    ngOnDestroy(): void {
        // Disiscriviti alla chiusura del componente per evitare memory leaks
        this.languageSubscription.unsubscribe();
    }
}

@Component({
    selector: 'kt-scadenziario-tabella',
    templateUrl: './scadenziario-tabella.component.html',
    styleUrls: ['./scadenziario-tabella.component.scss'],
    providers: [{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})
export class ScadenziarioTabellaComponent implements OnInit {

    @Output() onClickGraph = new EventEmitter<boolean>(false);
    @Output() onLoading = new EventEmitter<boolean>(false);
    @Input() banksList: any
    @Input() bankOperationsList: any
    @Input() paymentModalityList: any;
    @Input() currentFilterCfg: any

    selectedAll: boolean = false;

    utility: any;
    locale!: string;
    switchGroupSubcription!: Subscription;
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    ordStartsWith: boolean = false;
    bulkSelect!: any;

    filterStore: any;
    filterPaymentDate: any;
    filterBanks: any;
    filterBankOperations: any;
    filterDescription: any;
    filterDate: any;
    isPayedFilterList: any;
    filterIsPayed: string = '';
    filterStatus: string = '2';

    selectedAccounts: any = [];
    accountType: number = 1;
    accountList: any;

    model!: NgbDateStruct;
    data: any = [];

    private languageSubscription: Subscription;

    constructor(
        public staticCollectionsService: StaticCollectionsService,
        public filtriService: FiltriService,
        private switchGroupService: SwitchGroupService,
        public roleService: RoleService,
        private translate: TranslateService,
        private localizedDateTimePipe: LocalizedDateTimePipe,
        private translationService: TranslationService,
        private layoutUtilsService: LayoutUtilsService,
        private pdfService: PDFService,
        private ref: ChangeDetectorRef,
        public dialog: MatDialog,
        private scadenziarioService: ScadenziarioService,
        private bulkActionsService: BulkActionsService,
        private gestioneMerciService: GestioneMerciService
    ) {
        this.utility = util;
        this.languageSubscription = this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
    }

    ngOnInit(): void {
        this.changeAccountType(this.accountType);

        if (this.currentFilterCfg) {
            setTimeout(() => {
                this.filterDate = this.currentFilterCfg.Date;
                this.filterStore = this.currentFilterCfg.Companies;
                this.filterDescription = this.currentFilterCfg.Description;
                this.filterBankOperations = this.currentFilterCfg.BankOperations;
                this.filterBanks = this.currentFilterCfg.Banks;
                this.filterIsPayed = this.currentFilterCfg.IsPayed;
                this.filterPaymentDate = this.currentFilterCfg.PaymentDate;
                this.filterStatus = this.currentFilterCfg.Status;
                this.selectedAccounts = this.currentFilterCfg.Subjects;
                this.accountType = this.currentFilterCfg.Type ?? 1;
            }, 1000);
        }
    }

    performChangeAccountType(event: any) {
        this.changeAccountType(event);

        if (!this.currentFilterCfg) {
            this.currentFilterCfg = {
                Period: 'YTD',
                Type: event.value,
                Status: "2" // not payed
            };
        } else {
            this.currentFilterCfg['Type'] = event;
        }
        this.filtriService.filterConfig = this.currentFilterCfg;
        this.table.draw();
    }

    changeAccountType(eventy: any) {
        this.accountList = undefined;
        setTimeout(() => {
            switch (eventy) {
                case 1:
                    this.accountList = this.staticCollectionsService.fornitori$;
                    break;
                case 2:
                    this.accountList = this.staticCollectionsService.clientiTutti$;
                    break;
                case 4:
                    this.accountList = this.staticCollectionsService.dipendentiTutti$;
                    break;
            }
            this.ref.detectChanges();
        }, 100);
    }

    getPlaceholder() {
        let placeholder = "";
        switch (this.accountType) {
            case 1:
                placeholder = this.translate.instant('FILTRI.FORNITORE_PLACEHOLDER');
                break;
            case 2:
                placeholder = this.translate.instant('FILTRI.CLIENTE_PLACEHOLDER');
                break;
            case 4:
                placeholder = this.translate.instant('FILTRI.DIPENDENTE_PLACEHOLDER');
                break;
        }

        return placeholder;
    }

    async ngAfterViewInit() {
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.clearFilters();
                }
            }
        );
    }

    ngOnDestroy() {
        this.switchGroupSubcription.unsubscribe();
        this.languageSubscription.unsubscribe();
    }

    clearFilters() {
        this.filtriService.clearFilters$.next(false);

        // Cancello tutti i campi della tabella
        this.filterDate = undefined;
        this.filterStore = undefined;
        this.filterDescription = '';
        this.filterBankOperations = undefined;
        this.filterBanks = undefined;
        this.filterIsPayed = '';
        this.filterPaymentDate = undefined;
        this.filterStatus = '0';
        this.accountType = 1;

        this.bulkSelect = "";

        this.currentFilterCfg = {
            Period: 'YTD',
            Type: 1,
            IsPayed: false,
            IsExpired: false
        };

        this.filtriService.filterConfig = this.currentFilterCfg;

        if (this.table) this.table.draw();
        this.ref.detectChanges();
    }

    goToDashboard() {
        this.onClickGraph.emit(true);
    }

    pagination: any = {
        TotalRows: 0,
        pageSize: 10,
        pageIndex: 0
    }

    changePagination(event: any) {
        if (this.pagination.pageSize != event.pageSize) {
            this.pagination.pageSize = event.pageSize;
            this.pagination.pageIndex = 0;
            this.table.page.len(this.pagination.pageSize).draw();
            this.table.page(0).draw('page');
        } else if (this.pagination.pageIndex != event.pageIndex) {
            this.pagination.pageIndex = event.pageIndex;
            this.table.page(this.pagination.pageIndex).draw('page');
        }
    }

    filterByDescription(event: any) {

        if (event.code == 'Enter' || event.code == 'NumpadEnter') {
            this.performFilter(this.filterDescription, 'Description')
        }
    }

    expandAll: boolean = false;
    expandColapseAll() {
        this.expandAll = !this.expandAll;
        $('#scadenziarioTable tbody').find('.expand-icon')
            .each((index: number, td: any) => {

                const tr = $(td).closest('tr');
                const row = this.table.row(tr);

                const rowData: any = row.data();

                if (row.child.isShown() && !this.expandAll) {
                    // Nascondi la riga figlio
                    row.child.hide();
                    $(td).find('.expand-icon').removeClass('fas fa-minus-circle').addClass('fas fa-plus-circle');
                } else if (!row.child.isShown() && this.expandAll) {
                    // Mostra la riga figlio con i dati
                    row.child(this.formatChildRows(rowData)).show();
                    $(td).find('.expand-icon').removeClass('fas fa-plus-circle').addClass('fas fa-minus-circle');
                }

            });
    }

    expandColapse() {

    }

    table: any;
    totalAmount: number = 0;
    initDataTable() {
        if ($.fn.dataTable.isDataTable('#scadenziarioTable')) {
            $('#scadenziarioTable').DataTable().destroy();
        }

        if (!this.currentFilterCfg) {
            this.currentFilterCfg = {
                Period: 'YTD',
                Type: 1,
                Status: "2" // not payed
            }
            this.filtriService.filterConfig = this.currentFilterCfg;
        }

        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();

            this.loading$.next(true);
            const dtOptions: any = {
                orderCellsTop: true,
                destroy: true,
                paging: true,
                searching: true,
                dom: 'rt<"paginator">',
                ordering: true,
                scrollY: 'calc(100vh - 300px)',
                scrollCollapse: true,
                autoWidth: false,
                //order: [],
                processing: true,
                serverSide: true,
                ajax: {
                    url: currentLogin.endpointURL + "/api/Schedule/Grouped",
                    type: "POST",
                    data: (d: any) => {
                        // https://github.com/FB-Manager/FBMyBusiness-Web/issues/177#issuecomment-2144710515
                        if (this.filterStatus !== 'DisabledStatus') {
                            this.currentFilterCfg['DisabledStatus'] = 1
                        }
                        d.filters = this.currentFilterCfg;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        this.pagination.TotalRows = response.recordsFiltered;
                        this.totalAmount = this.accountType == 2 ? response.TotalValue.TotalCredit : response.TotalValue.TotalDebit;
                        this.ref.detectChanges();
                        this.data = response.data;
                        return response.data;
                    }
                },
                columnDefs: [
                    {
                        targets: '_all',
                        defaultContent: ''
                    },
                    {
                        targets: [0], // Prima colonna: Checkbox e icona di espansione
                        orderable: false,
                        className: 'dt-body-center excludeFromExport',
                        width: '40px',
                        render: (data, type, row, meta) => {
                            // Aggiungi il checkbox e l'icona per l'espansione
                            return `<div class="display-flex-left flex-align-center">
                                <i class="far fa-square select pointer"></i>&nbsp;
                                <i class="fas fa-plus-circle expand-icon pointer ml-2"></i>
                                </div>
                            `;
                        },
                        createdCell: (td, cellData, rowData, row, col) => {
                            // Gestisci il click sul checkbox
                            $(td).find('.select').on('click', (event) => {
                                const isChecked = $(td).find('i.select').hasClass('fas fa-check-square');
                                if (isChecked) {
                                    $(td).find('i.select').removeClass('fas fa-check-square').addClass('far fa-square');
                                } else {
                                    $(td).find('i.select').addClass('fas fa-check-square').removeClass('far fa-square');
                                }
                                this.toggleChildRowSelection($(td).closest('tr'), !isChecked); // Funzione per selezionare/deselezionare righe figlio
                            });

                            // Gestisci il click sull'icona di espansione
                            $(td).find('.expand-icon').on('click', (event) => {
                                const tr = $(td).closest('tr');
                                const row = this.table.row(tr);

                                if (row.child.isShown()) {
                                    // Nascondi la riga figlio
                                    row.child.hide();
                                    $(td).find('.expand-icon').removeClass('fas fa-minus-circle').addClass('fas fa-plus-circle');
                                } else {
                                    // Mostra la riga figlio con i dati
                                    row.child(this.formatChildRows(rowData)).show();
                                    $(td).find('.expand-icon').removeClass('fas fa-plus-circle').addClass('fas fa-minus-circle');
                                }
                            });
                        },
                    },
                    { targets: 1, width: '70px' }, // Date
                    { targets: 2, width: '120px' }, // Store
                    {
                        targets: 3, data: 'Name', name: 'Name', render: (data, type, p, meta) => {
                            return `<div class="overflow-ellipses exportableTooltip" title="${data}">${data}</div>`;
                        }, width: '200px'
                    },
                    {
                        targets: 4, className: 'max-width-250' // Description
                    },
                    {
                        targets: 5, width: '120px', // Operation
                    },
                    {
                        targets: 6, width: '120px' // BankName
                    },
                    {
                        targets: 7, width: '50px', data: 'Total', name: 'Total', className: 'text-right', render: (data, type, p, meta) => {
                            return util.formatNumber(data, this.locale);
                        }
                    },
                    {
                        targets: 8, width: '50px', data: 'Payed', name: 'Payed', render: (data, type, p, meta) => {
                            const icon = data ? `<i class="fas fa-check-square text-success"></i>` : `<i class="far fa-square text-danger"></i>`;
                            return `${icon}`;
                        }, className: 'text-center', visible: false
                    },
                    {
                        targets: 9, width: '60px' // Payment Date
                    },
                    {
                        targets: 10, width: '80px', className: 'status' // Status
                    },
                    {
                        targets: 11, width: '40px', className: 'text-right excludeFromExport actions', orderable: false // Actions
                    },
                    // Serve per evitare di riceve avvisi quando arrivano campi a null
                    {
                        defaultContent: '-',
                        targets: '_all'
                    }
                ],
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                    //lengthMenu: this.coreService.getTranslation('Admin.NumberItemsPerPage') + ' _MENU_',
                    //zeroRecords: "Nothing found - sorry",
                    //info: this.coreService.getTranslation('Generic.NumRows') + " _TOTAL_",
                    //infoEmpty: "No records available",
                    //infoFiltered: "(filtered from _MAX_ total records)"
                },
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        //text: '<i class="fa fa-file-pdf text-danger"></i>',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.excludeFromExport)',
                            format: {
                                body: function (data, row, column, node) {
                                    if (data.indexOf('exportableTooltip') >= 0) {
                                        var parser = new DOMParser();
                                        var html = parser.parseFromString(data, 'text/html');
                                        let html$ = $(html);
                                        data = html$.find(`.exportableTooltip`).attr("title");
                                    }

                                    return data;
                                }
                            }
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            const arrayCols = ['auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'];

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, arrayCols, '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
                            ////doc.defaultStyle = docDefinition.defaultStyle;
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;
                            doc.styles = docDefinition.styles;

                            // Imposto gli allineamenti
                            var rowCount = doc.content[1].table.body.length;
                            for (let i = 0; i < rowCount; i++) {
                                doc.content[1].table.body[i][0].alignment = 'left';
                                doc.content[1].table.body[i][1].alignment = 'left';
                                doc.content[1].table.body[i][2].alignment = 'left';
                                doc.content[1].table.body[i][3].alignment = 'left';
                                doc.content[1].table.body[i][4].alignment = 'left';
                                doc.content[1].table.body[i][5].alignment = 'left';
                                doc.content[1].table.body[i][6].alignment = 'right';
                                doc.content[1].table.body[i][7].alignment = 'left';
                                doc.content[1].table.body[i][8].alignment = 'left';
                            }
                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.pdfHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.excludeFromExport)',
                            format: {
                                body: (data, row, column, node) => {
                                    if (column == 6) {
                                        data = this.utility.parseNumber(data, this.locale)
                                    } else if (data.indexOf('exportableTooltip') >= 0) {
                                        var parser = new DOMParser();
                                        var html = parser.parseFromString(data, 'text/html');
                                        let html$ = $(html);
                                        data = html$.find(`.exportableTooltip`).attr("title");
                                        data = '\0' + data;
                                    }

                                    return data;
                                }
                            },
                            orthogonal: 'export'
                        },
                        customize: async (xlsx: any) => {

                            let sheet = xlsx.xl.worksheets['sheet1.xml'];
                            // Formatto la colonna dei valori
                            $('row c[r^="G"]', sheet).attr('s', '64');

                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.excelHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    }
                ],
                aaSorting: [[8, 'asc'], [1, 'asc']],
            };

            this.table = $('#scadenziarioTable').DataTable(dtOptions);
            window.table = this.table;

            this.loading$.next(false);

            this.table.on('page.dt', function () {
                $('.dataTables_scrollBody').animate({
                    scrollTop: 0
                }, 'slow');
            });

            $('tbody').off('click');

            $('tbody').on('click', '.actions', async (event: any) => {
                const tr = $(event.target).closest('tr');
                const btn = $(event.target).closest('a');
                const dataId = tr.data('id');
                const innersList = this.data.map((item: any) => item.InnerRows).flat();
                const data = innersList.find((item: any) => item.Id == dataId);

                switch (btn.attr('name')) {
                    case 'editItem':
                        this.editItem(data);
                        break;
                    case 'deleteItem':
                        this.deleteItem(data.Id);
                        break;
                    case 'resetItem':
                        this.resetItem(data.Id);
                        break;
                    case 'openInvoiceXml':
                        this.openInvoiceXml(data);
                        break;
                }

            });

            $('tbody').on('click', '.status', async (event: any) => {
                const tr = $(event.target).closest('tr');
                const btn = $(event.target).closest('a');
                const dataId = tr.data('id');
                const innersList = this.data.map((item: any) => item.InnerRows).flat();
                const data = innersList.find((item: any) => item.Id == dataId);

                switch (btn.attr('name')) {
                    case 'pay':
                        if(data) this.pay(data);
                        break;
                }

            });

            // Handle row details expansion (child rows)
            $('#scadenziarioTable tbody').on('click', 'td.details-control', (e) => {
                const tr = $(e.target).closest('tr');
                const row = this.table.row(tr);
                if (row.child.isShown()) {
                    row.child.hide();
                    $(e.target).removeClass('fas fa-minus-circle').addClass('fas fa-plus-circle');
                } else {
                    row.child(this.formatChildRows(row.data())).show();
                    $(e.target).removeClass('fas fa-plus-circle').addClass('fas fa-minus-circle');
                }
            });

            window.pdfMake.vfs['fa-solid-900-Regular.ttf'] = this.pdfService.addFontAwesome();
            window.pdfMake.vfs['fa-solid-900-Medium.ttf'] = this.pdfService.addFontAwesome();
            window.pdfMake.vfs['fa-solid-900-Italic.ttf'] = this.pdfService.addFontAwesome();
            window.pdfMake.vfs['fa-solid-900-Bold.ttf'] = this.pdfService.addFontAwesome();

            window.pdfMake.fonts = {
                // Default font should still be available
                Roboto: {
                    normal: 'Roboto-Regular.ttf',
                    bold: 'Roboto-Medium.ttf',
                    italics: 'Roboto-Italic.ttf',
                    bolditalics: 'Roboto-Italic.ttf'
                },
                // Make sure you define all 4 components - normal, bold, italics, bolditalics - (even if they all point to the same font file)
                'Font Awesome 5 Free': {
                    normal: 'fa-solid-900-Regular.ttf',
                    bold: 'fa-solid-900-Bold.ttf',
                    italics: 'fa-solid-900-Italics.ttf',
                    bolditalics: 'fa-solid-900-Italics.ttf'
                }
            };

            this.bulkSelect = "";
            this.ref.detectChanges();

            $('#scadenziarioTable tbody ').on('click', 'tr.childRow td i.select', (event: any) => {
                const tr = $(event.target).closest('tr');
                const dataId: any = tr.data('id');

                const innersList = this.data.map((item: any) => item.InnerRows).flat();

                const data: any = innersList.find((item: any) => item.Id == dataId);
                if (data) {
                    const isAlreadyChecked = $(event.target).hasClass('fas fa-check-square');
                    if (isAlreadyChecked) {
                        $(event.target).removeClass('fas fa-check-square');
                        $(event.target).addClass('far fa-square');
                    } else {
                        $(event.target).addClass('fas fa-check-square');
                        $(event.target).removeClass('far fa-square');
                    }
                    this.selectItem(!isAlreadyChecked, data);
                }
            });

            $('tbody').on('click', '.actionBtn', async (event: any) => {
                const tr = $(event.target).closest('tr');
                const btn = $(event.target).closest('i');
                const data = this.table.row(tr.index()).data();

                switch (btn.attr('name')) {
                    case 'editItem':
                        this.editItem(data);
                        break;
                    case 'deleteItem':
                        this.deleteItem(data.FileName);
                        break;
                }

            });

        }, 100);

    }

    getStatus(p: any) {
        let tooltip = '', content = '', attrs = '', color = '', status = '';
        if (p.IsPayed) {
            tooltip = content = this.translate.instant('SCADENZIARIO.FILTERS.ISPAYED.IS_PAYED');
        } else {

            if (this.filterStatus === 'DisabledStatus') {
                tooltip = this.translate.instant('SCADENZIARIO.FILTERS.STATUS.ANNULLATO');
                status = tooltip;
                color = 'text-danger';
            } else {
                let isExpired = p.IsExpired ? `(${this.translate.instant('SCADENZIARIO.FILTERS.STATUS.EXPIRED')})` : '';
                tooltip = p.BankName ? this.translate.instant('SCADENZIARIO.ACTIONS.PAGA') : this.translate.instant('SCADENZIARIO.ACTIONS.BANK_MISSING');
                tooltip = `${tooltip} ${isExpired}`;

                attrs = p.BankName ? `name='pay'` : ``;
                color = p.IsExpired ? (p.BankName ? 'text-danger' : 'text-grey') : (p.BankName ? 'text-primary' : 'text-grey');

                status = this.translate.instant('SCADENZIARIO.ACTIONS.PAGA');
            }

            content = `<div class='text-center'><a href='javascript:;' class='${color}' ${attrs}>${status}</a></div>`;
        }

        return `<div class="overflow-ellipses exportableTooltip" title="${tooltip}">${content}</div>`;
    }

    getActions(p: any) {
        const iconColor = p.Note ? 'active' : '';
        const tooltip = p.Note ? `data-toggle="tooltip" title="${p.Note}"` : '';
        const nota = ``; //`<a href="javascript:;" name="editNote" ${tooltip}><i class="fas fa-sticky-note ${iconColor}"></i></a>`

        const editScadenza = this.translate.instant('SCADENZIARIO.ACTIONS.EDIT');
        const deleteSacdenza = this.translate.instant('SCADENZIARIO.ACTIONS.DELETE');

        const isManual = p.InsertTypeId == 0;

        const actionEdit = !p.IsPayed ? `<div class="h_spacer"></div><a href="javascript:;" name="editItem" data-toggle="tooltip" title="${editScadenza}"><i class="fas fa-pencil-alt text-primary"></i></a>` : '';

        const openInvoiceXml = !isManual ? `<div class="h_spacer"></div><a href="javascript:;" name="openInvoiceXml" class="openInvoiceXml"><i class="actionBtn fas fas fa-eye text-primary pointer" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.VIEW_INVOICE')}"></i></a>` : '';

        const actionDelete = !isManual || p.IsPayed ? '' : `
                            <div class="h_spacer"></div>
                            <a href="javascript:;" name="deleteItem" data-toggle="tooltip" title="${deleteSacdenza}">
                                <i class="fas fa-trash text-danger"></i>
                            </a>`;

        const stornaSacdenza = this.translate.instant('SCADENZIARIO.ACTIONS.RESET_PAYMENT');

        const storno = p.IsPayed ? `<a href="javascript:;" name="resetItem" data-toggle="tooltip" title="${stornaSacdenza}">
                                                            <i class="fas fa-angle-double-right text-danger"></i>
                                                        </a>`: '';

        return `<div class="display-flex-right flex-align-center">
                            ${nota}
                            ${storno}
                            ${openInvoiceXml}
                            ${actionEdit}
                            ${actionDelete}
                        </div>`;
    }

    formatChildRows(data: any) {
        let childRows = '';
        data.InnerRows.forEach(row => {
            childRows += `<tr class="childRow" data-id="${row.Id}">
                <td class="text-center"><i class="far fa-square select pointer"></td>
                <td class="">${this.localizedDateTimePipe.transform(row.Date, undefined, true)}</td>
                <td class="">${row.Store}</td>
                <td class="">${row.SupplierName}</td>
                <td class="">${row.Description}</td>
                <td class="">${row.Operation}</td>
                <td class="">${row.BankName}</td>
                <td class="text-right">${this.utility.formatNumber(row.Total, this.locale)}</td>
                <td class="">${row.PaymentDate ? this.localizedDateTimePipe.transform(row.PaymentDate, undefined, true) : ''}</td>
                <td class="status">${this.getStatus(row)}</td>
                <td class="actions">${this.getActions(row)}</td>`;
        });
        return $(childRows).toArray();
    }

    toggleChildRowSelection(parentRowData: any, isSelected: boolean) {
        const childRows = this.table.row(parentRowData).child();
        console.log(parentRowData, childRows);
        if (childRows) {
            $(childRows).find('i.select').each((index, element) => {
                if (isSelected) {
                    $(element).removeClass('far fa-square').addClass('fas fa-check-square');
                } else {
                    $(element).removeClass('fas fa-check-square').addClass('far fa-square');
                }
            });
        }
    }

    isFilterActive: boolean = true;
    showFilters() {
        if ($('#scadenziarioTable_wrapper thead tr:nth-child(2)').is(':visible')) {
            $('#scadenziarioTable_wrapper thead tr:nth-child(2)').hide();
            this.isFilterActive = false;
        } else {
            $('#scadenziarioTable_wrapper thead tr:nth-child(2)').show();
            this.isFilterActive = true;
        }

        this.table.columns.adjust().draw();
    }

    async performBulkSelection(event: any) {
        let selectedItems: any = [];
        $('#scadenziarioTable tbody td:first-child i.fas.fa-check-square').each((index: number, el: any) => {

            const tr = $(el).closest('tr');

            if (tr.hasClass('childRow')) {
                // Se la riga è un child
                // recupero il data-id e poi recupero i dati dalla lista
                const dataId: any = tr.data('id');
                const innersList = this.data.map((item: any) => item.InnerRows).flat();
                const data: any = innersList.find((item: any) => item.Id == dataId);
                selectedItems.push(data);
                return;
            } else {
                // se è la riga è una tr parent
                // verifico che non sia espansa
                // se è espansa la salto perchè tanto dalla selezione mi arrivano anche i figli
                // altrimenti prendo la riga stessa e recupero le innersRows
                const row = this.table.row(tr);

                if (row.child.isShown()) {
                    // salto, prendo solo i filgi
                    return;
                } else {
                    // recupero i dati della riga parent
                    const data: any = this.table.row(tr).data();
                    data.InnerRows.forEach((data: any) => {
                        selectedItems.push(data);
                    });
                }
            }

        });

        switch (event.value) {
            case 'ResetPayment':
                selectedItems = selectedItems.filter((item: any) => item.IsPayed);
                lastValueFrom(this.scadenziarioService.stornoScadenza(selectedItems.map((item: any) => item.Id)))
                    .then(() => {
                        this.table.draw();
                    });
                break;
            case 'Schedule_Disable':
                selectedItems = selectedItems.filter((item: any) => !item.IsPayed);
                lastValueFrom(this.bulkActionsService.massiveActions({
                    "Action": "Schedule_Disable",
                    "EntityGuiIds": selectedItems.map((item: any) => item.Id),
                    "Value": 1
                }))
                    .then(() => {
                        this.table.draw();
                    });
                break;
            case 'Schedule_Enable':
                selectedItems = selectedItems.filter((item: any) => !item.IsPayed);
                lastValueFrom(this.bulkActionsService.massiveActions({
                    "Action": "Schedule_Disable",
                    "EntityGuiIds": selectedItems.map((item: any) => item.Id),
                    "Value": 0
                }))
                    .then(() => {
                        this.table.draw();
                    });
                break;
            case 'BulkPayment':
                const dialogRef = this.dialog.open(BulkPaymentDialogComponent, {
                    width: '600px',
                    data: {
                        selectedItems: selectedItems,
                        bankOperationsList: this.bankOperationsList,
                    }
                });
                dialogRef.afterClosed()
                    .subscribe({
                        next: async (res: any) => {
                            if (res) this.table.draw();
                            this.bulkSelect = "";
                        },
                        error: (error: any) => {
                            this.bulkSelect = "";
                            this.ref.detectChanges();
                        }
                    });
        }

        this.selectedAll = false;
    }

    selectAll(event: any) {
        $('#scadenziarioTable tbody td:first-child i.select').each((index: number, el: any) => {
            if (!event.checked) {
                $(el).removeClass('fas fa-check-square');
                $(el).addClass('far fa-square');
            } else {
                $(el).addClass('fas fa-check-square');
                $(el).removeClass('far fa-square');
            }
        })
    }

    bulkSelection(event: any) {
        this.bulkSelect = undefined;
        this.ref.detectChanges();

        if (event.value === 'BulkPayment') {
            this.performBulkSelection(event);
            this.bulkSelect = "";
        } else {

            let _title: string = '';
            const _description: string = this.translate.instant('SCADENZIARIO.BULK_ACTIONS.CONFIRM_ASK');
            const _waitDesciption: string = '';
            const _yesButton = this.translate.instant('COMMONS.CONFIRM');
            const _noButton = this.translate.instant('COMMONS.CANCEL');

            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
            dialogRef.afterClosed()
                .subscribe({
                    next: async (ok: any) => {
                        if (ok) {
                            this.performBulkSelection(event);
                        }
                        this.bulkSelect = "";
                        this.ref.detectChanges();
                    },
                    error: (error: any) => {
                        this.bulkSelect = "";
                        this.ref.detectChanges();
                    }
                });
        }

    }

    selectItem(checked: boolean, item: any) {
        item['selected'] = checked;
    }

    PDFPreferences: any;
    exportAsPDF(expand: boolean = false) {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
            explodeRows: this.translate.instant('EXPORT_PDF.EXPLODE_ROWS')
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            //if (result) this.preparePdfConfiguration(result);
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('SCADENZIARIO.TITLE'),
                    period: this.filtriService.getCurrentPeriod()
                };
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                };
                result['language'] = this.translationService.getSelectedLanguage();

                this.PDFPreferences = result;

                this.table.button(0).trigger();
            };
        });
    }

    newDeadline() {
        this.editItem({});
    }

    editItem(scadenza: any) {
        const dialogRef = this.dialog.open(ScadenziarioEditComponent, {
            data: {
                scadenza: scadenza,
                bankOperationsList: this.bankOperationsList,
                banksList: this.banksList,
                paymentModalityList: this.paymentModalityList,
                locale: this.locale
            },
            width: '900px'
        });

        dialogRef.componentInstance.onLoading.subscribe((res: any) => {
            this.loading$.next(res);
        });

        dialogRef.afterClosed().subscribe((res: any) => {
            if (!res || _.isEmpty(res)) {
                return;
            }
            const _saveMessage = this.translate.instant('SCADENZIARIO.ACTION_EDIT.UPDATE_MESSAGE');
            this.layoutUtilsService.showActionNotification(_saveMessage, MessageType.Update);
            this.table.draw();
            this.staticCollectionsService.fillStaticCollection(true);
        });
    }

    deleteItem(scadenzaId: string) {
        const _title: string = this.translate.instant('SCADENZIARIO.DELETE_DIALOG.TITLE');
        const _description: string = this.translate.instant('SCADENZIARIO.DELETE_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('SCADENZIARIO.DELETE_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            if (confirm) {
                this.loading$.next(true);
                this.scadenziarioService.deleteScadenza(scadenzaId).toPromise()
                    .then(() => {
                        const message = this.translate.instant('SCADENZIARIO.DELETE_DIALOG.SUCCESS');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                        this.table.draw();
                    })
                    .catch(() => {
                        const message = this.translate.instant('SCADENZIARIO.DELETE_DIALOG.ERROR');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                    })
                    .finally(() => this.loading$.next(false));

            }
        })

    }

    resetItem(scadenzaId: string) {
        const _title: string = this.translate.instant('SCADENZIARIO.RESET_PAYMENT_DIALOG.TITLE');
        const _description: string = this.translate.instant('SCADENZIARIO.RESET_PAYMENT_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('SCADENZIARIO.RESET_PAYMENT_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            if (confirm) {
                this.loading$.next(true);
                this.scadenziarioService.stornoScadenza([scadenzaId]).toPromise()
                    .then(() => {
                        const message = this.translate.instant('SCADENZIARIO.RESET_PAYMENT_DIALOG.SUCCESS');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                        this.table.draw();
                    })
                    .catch(() => {
                        const message = this.translate.instant('SCADENZIARIO.RESET_PAYMENT_DIALOG.ERROR');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                    })
                    .finally(() => this.loading$.next(false));

            }
        })
    }

    openInvoiceXml(scadenza: any) {
        this.gestioneMerciService.openXmlHighlightedByFileName(scadenza.InvoiceFile, "", "")
            .then(() => console.log)
            .catch((error: any) => {
                this.layoutUtilsService.showActionNotification(this.translate.instant(error.error_code), MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-danger');
            })
    }

    pay(scadenza: any) {

        const dialogRef = this.dialog.open(ScadenziarioPayComponent, {
            data: {
                scadenza: scadenza,
                bankOperationsList: this.bankOperationsList,
                locale: this.locale
            },
            width: '600px'
        });

        dialogRef.componentInstance.onLoading.subscribe((res: any) => {
            this.loading$.next(res);
        });

        dialogRef.afterClosed().subscribe((res: any) => {
            if (!res || _.isEmpty(res)) {
                return;
            }
            const _saveMessage = this.translate.instant('SCADENZIARIO.ACTION_EDIT.UPDATE_MESSAGE');
            this.layoutUtilsService.showActionNotification(_saveMessage, MessageType.Update);
            this.table.draw();
            this.staticCollectionsService.fillStaticCollection(true);
        });

    }

    filter(event: any) {
        if (event.keyCode == 13) {
            const v = this.ordStartsWith ? '#' + $(event.target).val() : $(event.target).val();
            this.performFilter(v, event.target.name);
        }
    }

    filterByStore() {

        const stores = this.filterStore.map((item: any) => item.Name);

        this.performFilter(stores, 'Companies');
    }

    filterByDate(date: any, field: string) {
        let filterDate = '';
        if (date) {
            filterDate = `${date.day >= 10 ? date.day : '0' + date.day} /${date.month >= 10 ? date.month : '0' + date.month}/${date.year} `;
        }
        this.performFilter(filterDate, field);
    }

    filterByPaymentStatus() {
        if (this.filterIsPayed === '') {
            this.filterIsPayed = 'true';
        } else if (this.filterIsPayed === 'true') {
            this.filterIsPayed = 'false';
        } else {
            this.filterIsPayed = '';
        }

        this.performFilter(this.filterIsPayed, 'IsPayed');
    }

    filterByStatus() {
        if (!this.currentFilterCfg) {
            this.currentFilterCfg = {
                Period: 'YTD',
                Type: 1,
                Status: "2" // not payed
            };
        }

        if (this.filterStatus === 'DisabledStatus') {

            delete this.currentFilterCfg['Status'];
            this.currentFilterCfg['DisabledStatus'] = 2;

        } else {
            delete this.currentFilterCfg['DisabledStatus'];
            this.currentFilterCfg['Status'] = this.filterStatus;
        }

        this.filtriService.filterConfig = this.currentFilterCfg;
        this.table.draw();
    }

    getTooltipByFiilterIsPayedValue() {
        let tooltip: string = '';

        switch (this.filterIsPayed) {
            case 'true':
                tooltip = this.translate.instant('SCADENZIARIO.FILTERS.ISPAYED.IS_PAYED');
                break;
            case 'false':
                tooltip = this.translate.instant('SCADENZIARIO.FILTERS.ISPAYED.NOT_PAYED');
                break;
            default:
                tooltip = this.translate.instant('SCADENZIARIO.FILTERS.ISPAYED.DEFAULT');
                break;
        }

        return tooltip;
    }

    performSelectAccounts() {
        if (this.selectedAccounts && this.selectedAccounts.length > 0) {
            this.currentFilterCfg['Subjects'] = this.selectedAccounts;
            this.currentFilterCfg['Type'] = this.accountType;
        } else {
            delete this.currentFilterCfg['Subjects'];
        }

        this.filtriService.filterConfig = this.currentFilterCfg;
        this.table.draw();
    }

    performFilter(value, name) {
        if (!this.currentFilterCfg) {
            this.currentFilterCfg = {
                Period: 'YTD',
                Type: 1,
                Status: "2" // not payed
            };
        }
        if (!value) {
            value = '';
            delete this.currentFilterCfg[name];
        } else {
            this.currentFilterCfg[name] = value;
        }
        const key = name + ":name";
        var column = this.table.column(key);
        if (column) {
            column.search(value).draw();
        }
        else {
            console.log("Controllare la colonna")
        }

        this.filtriService.filterConfig = this.currentFilterCfg;
    }

    almoustOneSelected() {
        const almoustOne = $('#scadenziarioTable tbody td:first-child i.fas.fa-check-square');
        return almoustOne.length > 0;
    }

    checkDisable(type: string) {
        let disabled = false;
        $('#scadenziarioTable tbody td:first-child i.fas.fa-check-square').each((index: number, el: any) => {
            const tr = $(el).closest('tr');

            if (tr.hasClass('childRow')) {
                const dataId: any = tr.data('id');
                const innersList = this.data.map((item: any) => item.InnerRows).flat();
                const data: any = innersList.find((item: any) => item.Id == dataId);
                if (data) {
                    switch (type) {
                        case 'ResetPayment':
                            if (!data.IsPayed || data.IsCancelled) {
                                disabled = true;
                            }
                            break;
                        case 'Schedule_Disable':
                            if (data.IsPayed || data.IsCancelled) {
                                disabled = true;
                            }
                            break;
                        case 'Schedule_Enable':
                            if (!data.IsCancelled) {
                                disabled = true;
                            }
                            break;
                        case 'BulkPayment':
                            if (data.IsPayed || data.IsCancelled) {
                                disabled = true;
                            }
                            break;
                    }
                }

            } else {
                const data: any = this.table.row(tr).data();
                // cerco nella InnerRows
                if (data && data.InnerRows) {
                    data.InnerRows.forEach((innerRow: any) => {
                        switch (type) {
                            case 'ResetPayment':
                                if (!innerRow.IsPayed || innerRow.IsCancelled) {
                                    disabled = true;
                                }
                                break;
                            case 'Schedule_Disable':
                                if (innerRow.IsPayed || innerRow.IsCancelled) {
                                    disabled = true;
                                }
                                break;
                            case 'Schedule_Enable':
                                if (!innerRow.IsCancelled) {
                                    disabled = true;
                                }
                                break;
                            case 'BulkPayment':
                                if (innerRow.IsPayed || innerRow.IsCancelled) {
                                    disabled = true;
                                }
                                break;
                        }
                    });
                }
            }
        });

        return disabled;
    }

    async exportAsXLSX() {
        this.table.button(1).trigger();
        this.loading$.next(true);
        setTimeout(() => {
            this.ref.detectChanges();
            this.loading$.next(false);
        }, 100);
    }

    filterByType(event: any) {
        if (!this.currentFilterCfg) {
            this.currentFilterCfg = {
                Period: 'YTD',
                Type: 1,
                Status: "2" // not payed
            };
        }
        this.currentFilterCfg['CreditNote'] = event;
        this.filtriService.filterConfig = this.currentFilterCfg;
        if (this.table) this.table.draw();
    }

    filterByMode(event: any) {
        if (!this.currentFilterCfg) {
            this.currentFilterCfg = {
                Period: 'YTD',
                Type: 1,
                Status: "2" // not payed
            };
        }

        if (!Number(event) && event !== 'NoPurchase') {
            this.currentFilterCfg['DDTMode'] = event;
            delete this.currentFilterCfg['Mode'];
        } else {
            this.currentFilterCfg['Mode'] = event;
            delete this.currentFilterCfg['DDTMode'];
        }
        this.filtriService.filterConfig = this.currentFilterCfg;
        if (this.table) this.table.draw();
    }
}
