// Core
import { Component, OnInit, ChangeDetectorRef, Inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { DomSanitizer } from '@angular/platform-browser';

// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//RxJs
import { Observable, BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, startWith, debounceTime } from 'rxjs/operators';

// Services
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { CategorieProdottiService } from '@app/core/services/categorie-prodotti.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';

// Components
import { FornitoriEditComponent } from '@app/views/pages/anagrafiche/fornitori/fornitori-edit/fornitori-edit.component';
import { GruppiMerciEditComponent } from '@app/views/pages/anagrafiche/gruppi-merci/gruppi-merci-edit/gruppi-merci-edit.component';
import { CategorieMerciEditComponent } from '@app/views/pages/anagrafiche/categorie-merci/categorie-merci-edit/categorie-merci-edit.component';
import { EditRicettaComponent } from '@app/views/pages/gestione-dati/gestione-ricette/edit-ricetta/edit-ricetta.component';
import { SelectVariationDialogComponent } from '@app/views/pages/gestione-dati/gestione-merci/edit-merci/select-variation-dialog/select-variation-dialog.component';

// Utilities
import * as util from '@app/core/services/utilityfunctions';
import * as _ from 'lodash';

import { InsertNotesComponent } from '@app/views/pages/gestione-dati/gestione-ricette/edit-ricetta/insert-notes/insert-notes.component';
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { ConfigService } from '@app/core/services/config.service';
import { GestioneRicetteService } from '@app/core/services/gestione-ricette.service';

declare var $: any;


@Component({
    selector: 'kt-edit-merci',
    templateUrl: './edit-merci.component.html',
    styleUrls: ['./edit-merci.component.scss']
})
export class EditMerciComponent implements OnInit {
    merce: any;
    readOnly: boolean = false;

    private unitObserv$!: Observable<any[]>;
    private unitOrdObserv$!: Observable<any[]>;
    unit$: any;
    unitOrd$: any;

    private fornitoriObserv$!: Observable<any>;
    public fornitori$;
    private brandsObserv$!: Observable<any>;
    public brands$;
    public goodsCategory$;
    old: any;
    utility: any;
    locale!: string;
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    ready$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    fornitoriSearchAutocomplete = new FormControl();

    inputPuntiVenditaChanged: Subject<any> = new Subject<any>();

    changeVariationInput: Subject<any> = new Subject<any>();

    initialObject!: string;

    mappaInputSuppliers: any;

    maskPrice: any = [];

    htmlImportedFile: any;

    merceBrands: any;

    constructor(
        public dialogRef: MatDialogRef<EditMerciComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private translationService: TranslationService,
        public staticCollectionsService: StaticCollectionsService,
        private categorieProdottiService: CategorieProdottiService,
        private anagraficheService: AnagraficheService,
        private gestioneMerciService: GestioneMerciService,
        public roleService: RoleService,
        public dialog: MatDialog,
        private ref: ChangeDetectorRef,
        private _adapter: DateAdapter<any>,
        @Inject(DOCUMENT) private document: any,
        private excelService: ExcelService,
        private pdfService: PDFService,
        private confSrv: ConfigService,
        private gestioneRicetteService: GestioneRicetteService,
        private domSanitizer: DomSanitizer
    ) {
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
        this.utility = util;
        this.mappaInputSuppliers = {};


    }

    filteredSuppliers!: Observable<any[]>;
    currentSupplier: any;
    optScarto!: number;

    stopEvent(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    manageTabEnterKey(ev: any) {
        if (ev.code == 'Tab' && ev.target.id == 'select2Unit') {
            ev.stopPropagation();
            $('#fornitoriSearchAutocomplete').click();
            setTimeout(() => {
                $('#fornitoriSearchAutocomplete').focus();
            }, 10);
        };
        if (ev.code == 'Tab' && ev.target.id == 'select2CategorieMerci') {
            ev.stopPropagation();
            setTimeout(() => {
                $('#paramIva input').focus();
                $('#paramIva input').select();
            }, 100);
        }
        if ((ev.code == 'Enter' || ev.code == 'NumpadEnter') && $(ev.target).hasClass('copyVariationAction')) {
            ev.stopPropagation();
            $(ev.target).click();
        };
        if ((ev.code == 'Enter' || ev.code == 'NumpadEnter') && $(ev.target).hasClass('copyNomeMerceButton')) {
            ev.stopPropagation();
            $(ev.target).click();
        }
    }

    ngOnInit() {

        this.confSrv.getConfigurations().subscribe(
            r => {
                this.optScarto = r.WasteMode;
            }
        );
        document.body.addEventListener('dragover', this.stopEvent, false);
        document.body.addEventListener('drop', this.stopEvent, false);

        $('body').on('keyup', (e: any) => {
            if (e.code == 'F2') {
                e.preventDefault();
                e.stopPropagation();
                this.save();
            }
        });

        this.unitObserv$ = this.anagraficheService.getUnitList();
        this.unitOrdObserv$ = this.anagraficheService.getOrderUnitList();
        this.fornitoriObserv$ = this.gestioneMerciService.getSuppliers();
        this.brandsObserv$ = this.anagraficheService.getBrands();
        this.goodsCategory$ = this.anagraficheService.getEntity('GoodsCategory');

        combineLatest(this.fornitoriObserv$, this.unitObserv$, this.unitOrdObserv$, this.brandsObserv$, this.goodsCategory$,
            (fornitoriObserv, unitObserv, unitOrdObserv, brandsObserv, goodsCategoryObserv) => ({
                fornitoriObserv, unitObserv, unitOrdObserv, brandsObserv, goodsCategoryObserv
            }))
            .subscribe((pair: any) => {

                this.unit$ = pair.unitObserv;
                this.unitOrd$ = pair.unitOrdObserv;
                this.fornitori$ = pair.fornitoriObserv;
                this.brands$ = pair.brandsObserv;
                this.goodsCategory$ = pair.goodsCategoryObserv;

                this._adapter.setLocale('it');//this._adapter.setLocale(this.translationService.getSelectedLanguage());

                // Faccio un clone altrimenti anche se non salvo si porta dietro le modifiche
                this.merce = JSON.parse(JSON.stringify(this.data.merce));
                this.merce.Code = this.merce.MBCode;
                this.readOnly = this.data.readOnly ? this.data.readOnly : false;

                setTimeout(() => {
                    this.merceBrands = this.merce.Brands ? this.merce.Brands.map((brand: any) => brand.Id) : [];
                }, 1000);                

                if (this.readOnly) {
                    this.fornitoriSearchAutocomplete.disable();
                }

                if (this.data.html) {
                    this.prepareHtml(this.data.html);
                }

                // Gestione tab
                setTimeout(() => {
                    $('#paramNomeMerce input').focus();
                    $('#paramNomeMerce input').select();
                }, 1000);

                document.addEventListener('keydown', this.manageTabEnterKey, true);

                this.merce.WastPercentageView = this.getWaste(this.merce.WastPercentage);
                this.merce.WastPercentageView = util.formatNumber(this.merce.WastPercentageView, this.locale)

                if (this.merce && this.merce.Suppliers && this.merce.Suppliers.length > 0) {

                    this.currentSupplier = this.getFirstSupplier();
                    this.merce.Suppliers.forEach(e => {
                        if (e.Variations && e.Variations.length > 0) {
                            e.Variations.forEach(v => {
                                v.OtherUnitRatio = v.OtherUnitRatio ? util.formatNumber(v.OtherUnitRatio, this.locale, 3) : 0;
                                v.SecondaryUnitRatio = v.SecondaryUnitRatio ? util.formatNumber(v.SecondaryUnitRatio, this.locale, 3) : 0;
                                v.Price = v.Price ? util.formatNumber(v.Price, this.locale, 3) : 0;
                                v.S1 = v.S1 ? util.formatNumber(v.S1, this.locale, 2) : 0;
                                v.S2 = v.S2 ? util.formatNumber(v.S2, this.locale, 2) : 0;
                                v.S3 = v.S3 ? util.formatNumber(v.S3, this.locale, 2) : 0;
                                v.S4 = v.S4 ? util.formatNumber(v.S4, this.locale, 2) : 0;
                                v.QxC = v.QxC ? util.formatNumber(v.QxC, this.locale, 2) : 0;
                                v.SupplierId = e.Id;
                            });
                        }
                    });

                    this.initDataTableVariations();
                }

                if (this.merce.Prices && this.merce.Prices.length > 0) {
                    this.merce.Prices.forEach((item: any) => {
                        item.Price = util.parseNumber(item.Price, this.locale);
                        item.SecondaryUnitRatio = util.parseNumber(item.SecondaryUnitRatio, this.locale);
                        item.OtherUnitRatio = util.parseNumber(item.OtherUnitRatio, this.locale);
                        item.PriceUM = this.utility.formatNumber(item.Price * item.SecondaryUnitRatio, this.locale, 3); //util.formatNumber(item.Price * item.SecondaryUnitRatio, this.locale, 4);
                        item.PriceUO = this.utility.formatNumber(item.Price * item.SecondaryUnitRatio * item.OtherUnitRatio, this.locale, 3);//util.formatNumber(item.Price * item.SecondaryUnitRatio * item.OtherUnitRatio, this.locale, 4);
                        item.Price = item.Price
                        item.FormattedPrice = this.utility.formatNumber(item.Price, this.locale, 3);

                        item.OtherUnitRatio = item.OtherUnitRatio ? util.formatNumber(item.OtherUnitRatio, this.locale, 3) : 0;
                        item.SecondaryUnitRatio = item.SecondaryUnitRatio ? util.formatNumber(item.SecondaryUnitRatio, this.locale, 3) : 0;
                    });
                }

                this.initDataTableSuppliers();
                this.initDataTablePuntiVendita();

                this.initialObject = JSON.stringify(this.merce);  /// <<<< OGGETTO INIZIALE SALVATO PER CONFRONTO   

                // Verifico anche che la merce sia nella categoria di importazione
                // nel caso vado a recuperare e visualizzare il file importato

                if (this.goodsCategory$ && this.goodsCategory$.length > 0) {
                    const categoryIndex = this.goodsCategory$.findIndex((c: any) => c.Id == this.merce.CategoryId && c.IsImport);

                    if (categoryIndex >= 0) {
                        this.gestioneMerciService.getXml(this.merce.OriginDocument).toPromise()
                            .then((html: any) => {

                                this.prepareHtml(html);
                            })
                            .catch((err: any) => {
                                console.log(err);
                            });
                    }


                }

            });

        this.filteredSuppliers = this.fornitoriSearchAutocomplete.valueChanges
            .pipe(
                startWith(''),
                debounceTime(500),
                map((value: string) => this.filterSuppliers(value))
            );

        this.inputPuntiVenditaChanged
            .pipe(
                debounceTime(500),
            )
            .subscribe(model => {
                if (!model) return;
                this.changeRowEvent(model);

            });

        this.changeVariationInput
            .pipe(
                debounceTime(500)
            ).subscribe((variation: any) => {
                this.copyAllDataToCompanies(variation);
            })
    }

    ngOnDestroy() {
        document.body.removeEventListener('dragover', this.stopEvent, false);
        document.body.removeEventListener('drop', this.stopEvent, false);
        $('body').off('keyup');
        document.removeEventListener('keydown', this.manageTabEnterKey, true);
    }

    checkTableCompaniesInputDisabled() {
        let isCodeUsedInPurchase = false;
        const supplier = this.merce.Suppliers.find((supplier: any) => supplier.Id == this.currentSupplier.Id);
        if (supplier && supplier.Variations) {
            const almoustOneUsed = supplier.Variations.filter((variation: any) => variation.IsUsedInPurchase);
            isCodeUsedInPurchase = almoustOneUsed.length > 0;
        } else {
            isCodeUsedInPurchase = false;
        }
        return this.readOnly || isCodeUsedInPurchase;
    }

    getFirstSupplier() {
        let firstSupplier = undefined;
        if (this.merce.Suppliers && this.merce.Suppliers.length > 0) {
            // Controllo se è attivo showDisabled
            if (this.showDisabled) {
                firstSupplier = this.merce.Suppliers[0];
            } else if (this.data.supplierToMerge && this.data.supplierToMerge.length > 0) {
                // Siccome mi arriva un elenco di fornitori, 
                // se le'lenco è = 1 allora prendo il primo per forza
                // altrimenti devo cercare il primo che non sia già presente nell'elenco this.merce.Suppliers
                if (this.data.supplierToMerge.length == 1) {
                    firstSupplier = this.data.supplierToMerge[0];
                } else {
                    firstSupplier = this.data.supplierToMerge.find((s: any) => !this.merce.Suppliers.find((x: any) => x.Id == s.Id));
                }
            } else {
                firstSupplier = this.merce.Suppliers.find((s: any) => !s.IsDisabled);
            }

        }
        return firstSupplier;
    }

    applyWaste() {

        const _title: string = this.translate.instant('GESTIONE_MERCI.APPLY_WASTE_DIALOG.TITLE');
        const _description: string = this.translate.instant('GESTIONE_MERCI.APPLY_WASTE_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('GESTIONE_MERCI.APPLY_WASTE_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            if (confirm) {

                let w = util.parseNumber(this.merce.WastPercentageView, this.locale)
                this.gestioneMerciService.applyWaste(this.merce.Id, w).subscribe(
                    r => {
                        let message = this.translate.instant('GESTIONE_MERCI.APPLY_WASTE_DIALOG.SUCCESS');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                    },
                    (err: any) => {
                        let message = this.translate.instant('GESTIONE_MERCI.APPLY_WASTE_DIALOG.ERROR');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                    }
                );

            }

        });

    }

    prepareHtml(html: string) {
        var parser = new DOMParser();
        var htmlDoc = parser.parseFromString(html, 'text/html');

        let html$ = $(htmlDoc);
        html$.find('#fattura-elettronica > div > div > div > table tbody tr').each(
            (indexRow: number, row: HTMLElement) => {
                $(row).find('td').each(
                    (indexCol: number, col: HTMLElement) => {
                        if ($(col).text().toLowerCase().includes(this.merce.Name.toLowerCase())) {
                            html$.find(`#fattura-elettronica > div > div > div > table tbody tr:nth-child(${indexRow + 1})`).addClass('highlight');
                        }
                    }
                )
            }
        );

        this.htmlImportedFile = this.domSanitizer.bypassSecurityTrustHtml(new XMLSerializer().serializeToString(html$[0]));
    }

    getWaste(wn) {
        let w = wn / 100;
        let wReturn = 0;
        switch (this.optScarto) {
            case 0:
                wReturn = wn;
                break;
            case 1:
                wReturn = (w / (1 + w)) * 100;

                break;
            case 2:
                wReturn = (1 + w) * 100;
                break;
        }

        return wReturn;
    }

    getNetWaste(wC) {
        let w = wC / 100;
        let wReturn = 0;
        switch (this.optScarto) {
            case 0:
                wReturn = wC;
                break;
            case 1:
                wReturn = (w / (1 - w)) * 100;

                break;
            case 2:
                wReturn = (w - 1) * 100;
                break;
        }

        return wReturn;
    }

    changeRowEvent(model: any) {
        switch (model.type) {
            case 'Price':
            case 'OtherUnit':
            case 'SecondaryUnit':
            case 'OtherUnitRatio':
            case 'SecondaryUnitRatio':
                {
                    const price = util.parseNumber(model.item.Price, this.locale, 50);
                    const secondaryUnitRatio = util.parseNumber(model.item.SecondaryUnitRatio, this.locale);
                    const otherUnitRatio = util.parseNumber(model.item.OtherUnitRatio, this.locale);

                    model.item.PriceUM = this.utility.parseNumber(price * secondaryUnitRatio, this.locale, 3);
                    model.item.PriceUO = this.utility.parseNumber(price * secondaryUnitRatio * otherUnitRatio, this.locale, 3);

                    model.item.Price = price;
                    model.item.FormattedPrice = price

                    const index = this.merce.Prices.findIndex((el: any) => el.Id == model.item.Id);
                    this.merce.Prices[index].PriceUM = this.utility.formatNumber(model.item.PriceUM, this.locale, 3);
                    this.merce.Prices[index].PriceUO = this.utility.formatNumber(model.item.PriceUO, this.locale, 3);
                    this.merce.Prices[index].Price = price;
                    this.merce.Prices[index].FormattedPrice = this.utility.formatNumber(price, this.locale, 3);

                    break;
                }

            case 'PriceUM':
                {
                    const priceUM = util.parseNumber(model.item.PriceUM, this.locale, 3);
                    const secondaryUnitRatio = util.parseNumber(model.item.SecondaryUnitRatio, this.locale);
                    const otherUnitRatio = util.parseNumber(model.item.OtherUnitRatio, this.locale);

                    // secondaryUnitRatio => rapp uM / UB
                    model.item.Price = priceUM / secondaryUnitRatio;
                    model.item.FormattedPrice = this.utility.formatNumber(model.item.Price, this.locale, 3);
                    model.item.PriceUO = this.utility.formatNumber(priceUM * otherUnitRatio, this.locale, 3);
                    break;
                }

            case 'PriceUO':
                {
                    const priceUO = util.parseNumber(model.item.PriceUO, this.locale, 3);
                    const secondaryUnitRatio = util.parseNumber(model.item.SecondaryUnitRatio, this.locale);
                    const otherUnitRatio = util.parseNumber(model.item.OtherUnitRatio, this.locale);

                    model.item.PriceUM = this.utility.formatNumber(priceUO / otherUnitRatio, this.locale, 3);
                    model.item.Price = priceUO / secondaryUnitRatio / otherUnitRatio;
                    model.item.FormattedPrice = this.utility.formatNumber(model.item.Price, this.locale, 3);
                    break;
                }

        }

        this.ref.detectChanges();
    }

    private filterSuppliers(value: any): any[] {
        let filterValue = '';
        if (value.constructor === String) {
            filterValue = value.toLowerCase();
        } else if (value.constructor === Object) {
            filterValue = value.Name ? value.Name.toLowerCase() : '';
        }

        // Controllo di non tirar fuori dei fornitori già associati alla merce
        return this.fornitori$ && this.fornitori$.length ? this.fornitori$.filter((option: any) => {
            const alreadyUsed = this.merce.Suppliers.find(supplier => supplier.Id == option.Id);
            return !option.IsDisabled && option.Name.toLowerCase().includes(filterValue) && !alreadyUsed
        }) : [];
    }

    onChangeUnit() {
        let u = this.unit$.find((x: any) => x.Id == this.merce.UnitId);
        this.merce.Unit = u.Name;
    }

    onChangeVariationInput(event: any, variation: any) {
        this.changeVariationInput.next(variation);
        $(event.target).removeClass('is-invalid');
    }

    onChangeVariantionOtherUnit(event: any, variation: any) {
        let u = this.unitOrd$.find((x: any) => x.Id == variation.OtherUnitId);
        variation.OtherUnit = u.Name;
        variation.OtherUnitId = u.Id;
        this.ref.detectChanges();
        this.copyAllDataToCompanies(variation);
        $(event.target).removeClass('is-invalid');
    }

    onChangeVariantionSecondaryUnit(event: any, variation: any) {
        let u = this.unitOrd$.find((x: any) => x.Id == variation.SecondaryUnitId);
        variation.SecondaryUnit = u.Name;
        this.copyAllDataToCompanies(variation);
        $(event.target).removeClass('is-invalid');
    }

    selectElement(event: any) {
        if (!event.option.value || !event.option.value.Id) return;
        let selectedSupplier = event.option.value;
        selectedSupplier.LockPriceChange = false;
        selectedSupplier.CalculatedPrice = false;
        selectedSupplier.Variations = [];

        this.merce.Suppliers.push(selectedSupplier);

        this.fornitoriSearchAutocomplete.setValue('');

        this.initDataTableSuppliers();

        if (this.merce.Suppliers.length === 1) {
            this.selectSupplierVariations(selectedSupplier);
            this.addVariation();
        }
    }

    displayFn(product: any): string {
        if (!product) return '';
        return product.Name;
    }

    getTitle(): string {
        if (this.merce && this.merce.Id) {
            return `${this.merce.Name}`;
        }
        return this.translate.instant('GESTIONE_MERCI.EDIT.NEW');
    }

    showSupplierSelector(supplier: any, $event: any) {
        if (this.mappaInputSuppliers[supplier.Index]) return;
        const index = $($event.target).closest('tr').index();
        supplier['Index'] = index;

        this.mappaInputSuppliers = {};
        this.mappaInputSuppliers[supplier.Index] = true;

        const value = this.fornitori$.find((fornitore: any) => {
            return !supplier.SupplierId || supplier.SupplierId.toString() === fornitore.Id.toString();
        });

        if (Number(supplier.SupplierId) > 0) {
            this.fornitoriSearchAutocomplete.setValue(value);
        } else {
            this.fornitoriSearchAutocomplete.setValue('');
        }

        setTimeout(() => {
            (<any>document).getElementById("fornitoriSearchPuntivendita").select();
        }, 100);
    }

    getSupplierById(supplierId: string) {
        return this.fornitori$.find((fornitore: any) => fornitore.Id == supplierId);
    }

    performSelectSupplier(supplier: any, company: any, variationCode: string = '') {
        let newSupplier = supplier;

        let variationIndex = 0;
        if (variationCode) {
            variationIndex = newSupplier.Variations.findIndex(v => v.Code == variationCode);
        }

        //Recupero l'indice corrispondente al record selezionato
        const index = this.merce.Prices.findIndex((obj => obj.Azienda == company.Azienda));


        // Applico al record tutte le varianti
        // SupplierId
        this.merce.Prices[index].SupplierId = newSupplier.Id;
        // Unità ordine 
        this.merce.Prices[index].OtherUnitId = newSupplier.Variations[variationIndex].OtherUnitId;
        // Rapp. Unità ordine / Unità magazzino
        this.merce.Prices[index].OtherUnitRatio = '';//newSupplier.Variations[variationIndex].OtherUnitRatio;
        // Unità magazzino
        this.merce.Prices[index].SecondaryUnitId = newSupplier.Variations[variationIndex].SecondaryUnitId;
        // Rapp. unità magazzino / Unità base
        this.merce.Prices[index].SecondaryUnitRatio = '';//newSupplier.Variations[variationIndex].SecondaryUnitRatio;
        // Prezzo unità magazzino
        this.merce.Prices[index].PriceUM = newSupplier.Variations[variationIndex].Price;
        const Price = this.utility.parseNumber(newSupplier.Variations[variationIndex].Price, this.locale, 3);
        const OtherUnitRatio = this.utility.parseNumber(newSupplier.Variations[variationIndex].OtherUnitRatio, this.locale);
        const SecondaryUnitRatio = this.utility.parseNumber(newSupplier.Variations[variationIndex].SecondaryUnitRatio, this.locale);
        // Prezzo Unità base
        this.merce.Prices[index].Price = Price / SecondaryUnitRatio;
        this.merce.Prices[index].FormattedPrice = this.utility.formatNumber(Price / SecondaryUnitRatio, this.locale, 3);
        // Prezzo unità ordine
        this.merce.Prices[index].PriceUO = this.utility.formatNumber(Price * OtherUnitRatio, this.locale, 3);


        if (this.allList) {
            this.merce.Prices.forEach((e: any) => {
                e.SupplierId = newSupplier.Id;
                e.OtherUnitId = newSupplier.Variations[variationIndex].OtherUnitId;
                e.OtherUnitRatio = newSupplier.Variations[variationIndex].OtherUnitRatio;
                e.SecondaryUnitId = newSupplier.Variations[variationIndex].SecondaryUnitId;
                e.SecondaryUnitRatio = newSupplier.Variations[variationIndex].SecondaryUnitRatio;
                // Prezzo unità magazzino
                e.PriceUM = newSupplier.Variations[variationIndex].Price;
                // Prezzo Unità base
                e.Price = Price / SecondaryUnitRatio
                e.FormattedPrice = this.utility.formatNumber(Price / SecondaryUnitRatio, this.locale, 3);
                // Prezzo unità ordine
                e.PriceUO = this.utility.formatNumber(Price * OtherUnitRatio, this.locale, 3);
            });
        } else {
            this.merce.Prices[index].OtherUnitRatio = OtherUnitRatio;
            this.merce.Prices[index].SecondaryUnitRatio = SecondaryUnitRatio;
        }

        setTimeout(() => {
            this.initDataTablePuntiVendita();
        }, 100);
    }

    selectSupplier(event, company) {
        let newSupplier = event.option.value;

        // Se ho più di una variazione faccio selezionare all'utente quale vuole
        if (newSupplier.Variations && newSupplier.Variations.length > 1) {

            const dialogRef = this.dialog.open(SelectVariationDialogComponent, {
                data: {
                    variations: newSupplier.Variations,
                    unitOrd: this.unitOrd$,
                    merce: this.merce,
                    locale: this.locale
                },
                width: '90%',
                // height: '100%'
            });

            dialogRef.afterClosed().subscribe((res: any) => {
                if (_.isEmpty(res) || !res) {
                    return;
                }
                if (res.success) {

                    if (this.merce.DefaultSupplier) {
                        this.merce.Prices.forEach(company => this.performSelectSupplier(newSupplier, company, res.variation.Code));
                    } else {
                        this.performSelectSupplier(newSupplier, company, res.variation.Code);
                    }


                }
            });

        } else {
            if (this.merce.DefaultSupplier) {
                this.merce.Prices.forEach(company => this.performSelectSupplier(newSupplier, company));
            } else {
                this.performSelectSupplier(newSupplier, company);
            }
        }

    }

    tableFornitori: any;
    initDataTableSuppliers() {
        if ($.fn.dataTable.isDataTable('#tableFornitori')) {
            $('#tableFornitori').DataTable().destroy();
        }
        setTimeout(() => {
            let dtOptions: any = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: false,
                scrollY: '200px',
                scrollCollapse: true,
                columnDefs: [
                    //{ targets: 0 },	 // Name
                    { targets: [1, 2], width: '10%' }, // LockPriceChange & CalculatedPrice
                    { targets: [3], width: '10%', visibile: this.roleService.isCompanyAdminOrMore() }, // Actions
                ],
                aaSorting: [[0, 'asc']],
                autoWidth: true
            };
            this.tableFornitori = $('#tableFornitori').DataTable(dtOptions);
            this.tableFornitori.columns.adjust().draw();
        }, 250);
    }

    tablePuntiVendita: any;
    dtOptionsPuntiVendita: any;
    initDataTablePuntiVendita() {
        if ($.fn.dataTable.isDataTable('#tablePuntiVendita')) {
            $('#tablePuntiVendita').DataTable().destroy();
        }
        setTimeout(() => {
            this.dtOptionsPuntiVendita = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: false,
                scrollY: '530px',
                scrollCollapse: true,
                columnDefs: [
                    { targets: [2, 3, 4], width: '100px' }, // Prezzo Unità Base, Prezzo/Unità Mag, 	Prezzo Unità Ord
                    { targets: [5, 7], width: '100px' }, // Unità Ord, Unità Mag
                    { targets: [6, 8], width: '100px' }, // 	Rapporto Unità Ordine/Unità Magazzino, Rapposto Unità Magazzino/ Unità Base
                    { targets: [10, 11], width: '70px' }, // Qta min, Qta max
                    { targets: [12, 13], width: '30px' }, // Alias, Visibilità
                ],
                aaSorting: [[0, 'asc']],
                autoWidth: true
            };
            this.tablePuntiVendita = $('#tablePuntiVendita').DataTable(this.dtOptionsPuntiVendita);
            this.tablePuntiVendita.columns.adjust().draw();

            this.initSelect2Suppliers();
        }, 250);
    }

    initSelect2Suppliers() {
        if ($('.select2Suppliers').data('select2')) {
            (<any>$('.select2Suppliers')).select2('destroy');
        }
        (<any>$('.select2Suppliers')).select2({
            placeholder: this.translate.instant('FILTRI.GRUPPI_MERCI'),
            allowClear: true,
            closeOnSelect: true,
            minimumResultsForSearch: -1,
            containerCssClass: 'select2Single',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });
        $('.select2Suppliers').on('select2:select', (e: any) => {
            this.merce.Group = e.params.data.text;
            this.merce.GroupId = e.params.data.id;
        });
        $('.select2Suppliers').on('select2:clear', (e: any) => {
            this.merce.Group = '';
            this.merce.GroupId = '';
        });
    }

    selectSupplierVariations(supplier: any) {
        if (supplier && supplier.Variations) {
            this.currentSupplier = supplier;
            this.initDataTableVariations();
        } else {
            this.addVariation();
        }
    }

    tableVariations: any;
    hoverglassActive: boolean = false;
    initDataTableVariations() {
        this.hoverglassActive = true;
        if ($.fn.dataTable.isDataTable('#tableVariations')) {
            $('#tableVariations').DataTable().destroy();
        }
        setTimeout(() => {
            let dtOptions: any = {
                initComplete: (settings) => {
                    this.hoverglassActive = false;
                },
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: false,
                scrollY: '200px',
                scrollCollapse: true,
                columnDefs: [
                    { targets: [0] },	 // Codice
                    { targets: [1, 4], className: 'max-width-100' }, // Unità
                    { targets: [2, 10], className: 'max-width-100' }, // Rapporti
                    { targets: [3], className: 'max-width-100' }, // Prezzo
                    { targets: [5, 6, 7, 8, 9], width: '60px' }, // Sconti + QxC
                    { targets: [11], className: 'max-width-30 text-center' }, // Alias
                    { targets: [12], className: 'max-width-50' }, // Actions
                ],
                aaSorting: [[0, 'asc']],
                autoWidth: true
            };
            this.tableVariations = $('#tableVariations').DataTable(dtOptions);
            this.tableVariations.columns.adjust().draw();

            $('#tableVariations tbody').off('click');

            // Se non sono companyAdmin or More cmq è disabilitato
            $('#tableVariations tbody').on('click', 'input', (event: any) => {
                const element = $(event.target);
                const isOverridabled = element.hasClass('overridable');
                const IsDisabled = element.hasClass('disabled');
                if (isOverridabled) {
                    if (IsDisabled) {
                        element.removeClass('disabled');
                    } else {
                        element.addClass('disabled');
                        element.blur();
                    }
                }
            });

            $('#tableVariations tbody').on('blur', 'input', (event: any) => {
                const element = $(event.target);
                const isOverridabled = element.hasClass('overridable');
                const IsDisabled = element.hasClass('disabled');
                if (isOverridabled) {
                    if (!IsDisabled) {
                        element.addClass('disabled');
                    }
                }
            });

            $('#tableVariations tbody').on('click', 'select', (event: any) => {
                const element = $(event.target);
                const isOverridabled = element.hasClass('overridable');
                const IsDisabled = element.hasClass('disabled');
                if (isOverridabled) {
                    if (IsDisabled) {
                        element.removeClass('disabled');
                    } else {
                        element.addClass('disabled');
                        element.blur();
                    }
                }
            });

            $('#tableVariations tbody').on('blur', 'select', (event: any) => {
                const element = $(event.target);
                const isOverridabled = element.hasClass('overridable');
                const IsDisabled = element.hasClass('disabled');
                if (isOverridabled) {
                    if (!IsDisabled) {
                        element.addClass('disabled');
                    }
                }
            });
        }, 250);
    }

    openPopupComplement(item: any, param: string = 'Alias') {
        const dialogRef = this.dialog.open(InsertNotesComponent, {
            data: {
                note: item[param],
                title: this.translate.instant('GESTIONE_MERCI.EDIT.PARAMETERS.POPUP_NOTA.TITLE'),
                subtitle: this.translate.instant('GESTIONE_MERCI.EDIT.PARAMETERS.POPUP_NOTA.SUBTITLE')
            },
            width: '600px',
            panelClass: 'InsertNotesComponent'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            if (res.success) {
                item[param] = res.Note;
                this.ref.detectChanges();
            }
        });
    }

    addVariation() {

        let u = this.unitOrd$[0];
        if (!this.currentSupplier.Variations) {
            this.currentSupplier.Variations = [];
        }
        this.currentSupplier.Variations.push({
            Code: 'C_' + new Date().getTime(),
            OtherUnit: u.Name,
            OtherUnitId: u.Id,
            OtherUnitRatio: '',
            Price: 0,
            SecondaryUnit: u.Name,
            SecondaryUnitId: u.Id,
            SecondaryUnitRatio: '',
            Alias: '',
            SupplierId: this.currentSupplier.Id
        });
        this.initDataTableVariations();
    }

    async removeSupplier(supplierId: any) {

        if (this.merce.Id && await this.gestioneMerciService.isSupplierUsed(this.merce.Id, supplierId)) {
            let message = this.translate.instant('GESTIONE_MERCI.FORNITOREUSATO');
            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-success');
        } else {
            const _title: string = this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.DELETE_SUPPLIER.TITLE');
            const _description: string = this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.DELETE_SUPPLIER.DESC');
            const _waitDesciption: string = '';
            const _yesButton = this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.DELETE_SUPPLIER.BUTTON');
            const _noButton = this.translate.instant('COMMONS.CANCEL');

            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
            dialogRef.afterClosed().subscribe((confirm: any) => {
                if (confirm) {

                    this.layoutUtilsService.showActionNotification(this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.DELETE_SUPPLIER.SUCCESS'), MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

                    this.merce.Suppliers = this.merce.Suppliers.filter((sup: any) => sup.Id !== supplierId);

                    if (this.merce.Suppliers.length > 0) {
                        this.currentSupplier = this.getFirstSupplier();
                    } else {
                        this.currentSupplier = {};
                    }

                    this.initDataTableSuppliers();
                    this.initDataTableVariations();
                }

            });
        }
    }

    popupXml: any;
    async showXml(variation: any) {
        this.gestioneMerciService.openXmlHighlightedByProduct(this.merce.Name, this.merce.Id, variation.SupplierId, variation.Code)
            .then()
            .catch((error: any) => {
                this.layoutUtilsService.showActionNotification(this.translate.instant(error.error_code), MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-danger');
            })
    }


    async reenableVariation(event: any, supplier: any) {
        const index = $(event.target).closest('tr').index();

        const _title: string = this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.REENABLE_VARIATION.TITLE');
        const _description: string = this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.REENABLE_VARIATION.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.REENABLE_VARIATION.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(async (confirm: any) => {
            if (confirm) {
                this.layoutUtilsService.showActionNotification(this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.REENABLE_VARIATION.SUCCESS'), MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                //Non serve richiamare l'api perchè impostando Disabled = true e poi salvando lo si fa ugualmente.
                //await this.gestioneMerciService.reenableVariation(supplier.Variations[index].Id, supplier.Id);
                supplier.Variations[index].Disabled = false;
                this.initDataTableVariations();
            }

        });
    }

    async removeVariation(event: any, supplier: any) {
        const index = $(event.target).closest('tr').index();
        if (await this.gestioneMerciService.isSupplierUsed(this.merce.Id, supplier.Id, supplier.Variations[index].Code)) {
            let message = this.translate.instant('GESTIONE_MERCI.FORNITORECODICEUSATO');
            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-success');

            const _title: string = this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.DELETE_VARIATION.TITLEDISABLE');
            const _description: string = this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.DELETE_VARIATION.DESC_EXISTING');
            const _waitDesciption: string = '';
            const _yesButton = this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.DELETE_VARIATION.BUTTONDISABLE');
            const _noButton = this.translate.instant('COMMONS.CANCEL');

            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
            dialogRef.afterClosed().subscribe((confirm: any) => {
                if (confirm) {

                    this.layoutUtilsService.showActionNotification(this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.DELETE_VARIATION.SUCCESS'), MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

                    supplier.Variations[index].Disabled = true;

                    this.initDataTableVariations();


                }

            });
        } else {


            const _title: string = this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.DELETE_VARIATION.TITLE');
            const _description: string = this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.DELETE_VARIATION.DESC');
            const _waitDesciption: string = '';
            const _yesButton = this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.DELETE_VARIATION.BUTTON');
            const _noButton = this.translate.instant('COMMONS.CANCEL');

            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
            dialogRef.afterClosed().subscribe((confirm: any) => {
                if (confirm) {

                    this.layoutUtilsService.showActionNotification(this.translate.instant('GESTIONE_MERCI.EDIT.FORNITORI.DELETE_VARIATION.SUCCESS'), MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

                    supplier.Variations.splice(index, 1);

                    this.initDataTableVariations();

                    if (supplier.Variations.length === 0) {
                        //Rimuove anche il fornitore
                        this.removeSupplier(supplier.Id);
                    }
                }

            });
        }
    }

    newSupplier() {
        const item = {
            Id: '',
            SubjectType: 1
        };
        let saveMessageTranslateParam = 'ANAGRAFICHE.FORNITORI.ACTION_EDIT.ADD_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = MessageType.Create;
        const dialogRef = this.dialog.open(FornitoriEditComponent, { data: { item }, width: '900px' });
        dialogRef.afterClosed().subscribe(async (res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);

            this.gestioneMerciService.getSuppliers().subscribe((suppliers: any) => {
                this.fornitori$ = suppliers;
                const supplier = suppliers.find((s: any) => s.Id === res.item.Id);
                this.selectElement({ option: { value: supplier } });
            });
            await this.staticCollectionsService.refreshFornitori();
        });
    }

    newGroup() {
        const item = {
            Id: ''
        };
        let saveMessageTranslateParam = 'ANAGRAFICHE.GRUPPI.ACTION_EDIT.ADD_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = MessageType.Create;
        const dialogRef = this.dialog.open(GruppiMerciEditComponent, { data: { item }, width: '800px' });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
            this.anagraficheService.getEntity('GoodsGroup').toPromise().then((groups: any) => {
                this.staticCollectionsService.gruppiMerci$ = groups;
                const group = groups.find((g: any) => g.Id === res.item.Id);
                this.merce.GroupId = res.item.Id;
            });
        });
    }

    getValidSuppliers(merce) {
        return merce.Suppliers.filter((x: any) => !x.IsDisabled);
    }

    newCategory() {
        const item = {
            Id: ''
        };
        let saveMessageTranslateParam = 'ANAGRAFICHE.CATEGORIE_MERCI.ACTION_EDIT.ADD_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = MessageType.Create;
        const dialogRef = this.dialog.open(CategorieMerciEditComponent, { data: { item }, width: '600px' });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
            this.gestioneMerciService.getGoodsCategories().toPromise().then((categorie: any) => {
                this.staticCollectionsService.categorieMerci$ = categorie;
                const categoria = categorie.find((c: any) => c.Id === res.item.Id);
                this.merce.CategoryId = res.item.Id;
            });

        });
    }

    checkCompliance() {
        const disable = !this.merce.AlternateName || !this.merce.Name || !this.merce.GroupId || !this.merce.CategoryId ||
            this.merce.Prices.filter((price: any) => !price.SupplierId).length > 0;

        return disable;
    }

    checkErrorBeforeSave() {

        if (!this.merce.AlternateName) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_NOME_MERCE');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            return false;
        }

        if (!this.merce.Name) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_PRODOTTO');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            return false;
        }

        if (!this.merce.GroupId) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_GROUP');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            return false;
        }

        if (!this.merce.CategoryId) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_CATEGORY');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            return false;
        }

        if (this.merce.Prices.filter((price: any) => !price.SupplierId).length > 0) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_SUPPLIERS_DEFAULT');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            return false;
        }

        if (!this.merce.UnitId) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_UNIT');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            return false;
        }

        // Controllo che tutte le unità di misura siamo compilate (merce.Suppliers, merce.Prices)         
        const suppliersHasUnits = this.merce.Suppliers.every((s: any) => s.Variations.every(v => v.OtherUnitId >= 0));
        const suppliersHasSecondaryUnits = this.merce.Suppliers.every((s: any) => s.Variations.every(v => v.SecondaryUnitId >= 0));
        const pricesHasUnits = this.merce.Prices.every(p => p.OtherUnitId >= 0);
        const pricesHasSecondaryUnits = this.merce.Prices.every(p => p.SecondaryUnitId >= 0);

        if (!suppliersHasUnits || !suppliersHasSecondaryUnits) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_SUPPLIERS_UNITS');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            const arrayElements = $('#tableVariations tbody .select2Component').filter((i, e) => {
                return $(e).find('option:selected').text().trim() == "---";
            });
            arrayElements.each((index, element) => {
                $(element).addClass('is-invalid');
            })
            return false;
        }

        if (!pricesHasUnits || !pricesHasSecondaryUnits) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_PRICES_UNITS');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            const arrayElements = $('#tablePuntiVendita tbody .select2Component').filter((i, e) => {
                return $(e).find('option:selected').text().trim() == "---";
            });
            arrayElements.each((index, element) => {
                $(element).addClass('is-invalid');
            })
            return false;
        }

        // Verifico anche il rapporto UO/UM e rapporto UM/UB
        const suppliersHasRatioUOUM = this.merce.Suppliers.filter((s: any) => !s.IsDisabled).every((s: any) => s.Variations.every(v => this.utility.parseNumber(v.OtherUnitRatio, this.locale) > 0));
        if (!suppliersHasRatioUOUM) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_OTHER_UNIT_RATIO');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            const arrayElements = $('#tableVariations tbody .OtherUnitRatio').filter((i, e) => {
                return $(e).text() == "";
            });
            arrayElements.each((index, element) => {
                $(element).addClass('is-invalid');
            })
            return false;
        }

        const suppliersHasRatioUMUB = this.merce.Suppliers.filter((s: any) => !s.IsDisabled).every((s: any) => s.Variations.every(v => this.utility.parseNumber(v.SecondaryUnitRatio, this.locale) > 0));
        if (!suppliersHasRatioUMUB) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_SECONDARY_UNIT_RATIO');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            const arrayElements = $('#tableVariations tbody .SecondaryUnitRatio').filter((i, e) => {
                return $(e).text() == "";
            });
            arrayElements.each((index, element) => {
                $(element).addClass('is-invalid');
            })
            return false;
        }

        const pricesHasOtherUnitRatio = this.merce.Prices.every(p => this.utility.parseNumber(p.OtherUnitRatio, this.locale, 2) >= 0);
        if (!pricesHasOtherUnitRatio) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_OTHER_UNIT_RATIO');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            const arrayElements = $('#tablePuntiVendita tbody .OtherUnitRatio').filter((i, e) => {
                return $(e).val() == "";
            });
            arrayElements.each((index, element) => {
                $(element).addClass('is-invalid');
            })
            return false;
        }

        const pricesHasSecondaryUnitRatio = this.merce.Prices.every(p => this.utility.parseNumber(p.SecondaryUnitRatio, this.locale, 2) >= 0);
        if (!pricesHasSecondaryUnitRatio) {
            const message = this.translate.instant('GESTIONE_MERCI.EDIT.SAVE_CHECK_MESSAGES.MISSING_SECONDARY_UNIT_RATIO');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-danger');
            const arrayElements = $('#tablePuntiVendita tbody .SecondaryUnitRatio').filter((i, e) => {
                return $(e).val() == "";
            });
            arrayElements.each((index, element) => {
                $(element).addClass('is-invalid');
            })
            return false;
        }

        return true

    }

    save() {

        // Prova controllo coerenza dati
        if (!this.checkErrorBeforeSave()) {
            return;
        }

        // Se i dati non sono cambiati non salvo ed esco direttamente
        if (JSON.stringify(this.merce) == this.initialObject && this.merce.Id) {
            this.exit();
            return;
        }

        let w = util.parseNumber(this.merce.WastPercentageView, this.locale)
        this.merce.WastPercentage = this.getNetWaste(w);

        if (!this.merce.Brands) {
            this.merce['Brands'] = []
        }

        if (this.merce.Id) {
            //UPDATE
            const _title: string = this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.TITLE');
            const _description: string = this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.DESC');
            const _waitDesciption: string = '';
            const _yesButton = this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.BUTTON');
            const _noButton = this.translate.instant('COMMONS.CANCEL');

            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
            dialogRef.afterClosed().subscribe((expand: any) => {
                if (expand) {
                    this.loading$.next(true);
                    this.fixNumericFields(this.merce)
                    this.clearFromIds(this.merce)

                    this.gestioneMerciService.updateGoods(this.merce, this.data.linkedGoodId).subscribe(
                        (result: any) => {
                            this.loading$.next(false);

                            let message: string = '';
                            if (result.SavedId) {
                                message = this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.SUCCESS');
                                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                                this.initialObject = JSON.stringify(this.merce);
                                // Cancello il parametro merged che mi è servito per disabilitare il controllo
                                this.merce.merged = false;
                                this.goBack(this.merce);
                            } else {
                                message = this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.ERROR');
                                this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                            }
                            this.parseFields(this.merce);

                        }, (error: any) => {
                            console.log('updateGoods', error);
                            this.loading$.next(false);
                            this.parseFields(this.merce);
                        }
                    );
                }
            });
        } else {
            //SAVE
            const _title: string = this.translate.instant('GESTIONE_MERCI.SAVE_DIALOG.TITLE');
            const _description: string = this.translate.instant('GESTIONE_MERCI.SAVE_DIALOG.DESC');
            const _waitDesciption: string = '';
            const _yesButton = this.translate.instant('GESTIONE_MERCI.SAVE_DIALOG.BUTTON');
            const _noButton = this.translate.instant('COMMONS.CANCEL');

            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
            dialogRef.afterClosed().subscribe((expand: any) => {
                if (expand) {
                    this.loading$.next(true);
                    this.fixNumericFields(this.merce)
                    this.gestioneMerciService.saveGoods(this.merce).subscribe(
                        (result: any) => {
                            this.loading$.next(false);

                            let message: string = '';
                            if (result.SavedId) {
                                this.merce.Id = result.SavedId;
                                message = this.translate.instant('GESTIONE_MERCI.SAVE_DIALOG.SUCCESS');
                                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                                this.initialObject = JSON.stringify(this.merce);
                                // Cancello il parametro merged che mi è servito per disabilitare il controllo
                                this.merce.merged = false;
                                this.goBack(this.merce);
                            } else {
                                message = this.translate.instant('GESTIONE_MERCI.SAVE_DIALOG.ERROR');
                                this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                            }
                            this.parseFields(this.merce);
                        }, (error: any) => {
                            this.loading$.next(false);
                            this.parseFields(this.merce);
                        }
                    );
                }
            });
        }

        return;
    }
    /***************************************************************************************************** */
    private clearFromIds(merce) {
        merce.Suppliers.forEach((element: any) => {
            delete element.$id;
        });

        merce.Prices.forEach((element: any) => {
            delete element.$id;
        });
    }
    /***************************************************************************************************** */
    /**
     * Ritorna alla liste ricette chiudendo il popup
     */
    goBack(returnValue) {
        this.dialogRef.close(returnValue);
    }

    exit() {
        if (JSON.stringify(this.merce) == this.initialObject || !this.roleService.isCompanyAdminOrMore()) {
            this.goBack(null);
        } else {
            const _title: string = this.translate.instant('GESTIONE_MERCI.ASK_SOMETHING_CHANGED.TITLE');
            const _description: string = this.translate.instant('GESTIONE_MERCI.ASK_SOMETHING_CHANGED.DESC');
            const _waitDesciption: string = '';
            const _yesButton = this.translate.instant('GESTIONE_MERCI.ASK_SOMETHING_CHANGED.SAVE');
            const _yes2Button = this.translate.instant('GESTIONE_MERCI.ASK_SOMETHING_CHANGED.EXIT');
            const _noButton = this.translate.instant('COMMONS.CANCEL');
            const success = this.translate.instant('GESTIONE_MERCI.ASK_SOMETHING_CHANGED.SUCCESS');

            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton, _yes2Button);
            dialogRef.afterClosed().subscribe((result: any) => {
                if (result === 2) {
                    this.goBack(null);
                } else if (result) {
                    this.save();
                } else {
                    //NOP
                }
            });

        }
    }

    async checkIfUsed(event, supplier, variation) {

        if (await this.gestioneMerciService.isSupplierUsed(this.merce.Id, supplier.Id, variation.Code)) {
            let message = this.translate.instant('GESTIONE_MERCI.FORNITORECODICEUSATO');
            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-success');
            variation.Code = this.old;
        }
    }

    fixNumericFields(merce) {
        merce.WastPercentage = merce.WastPercentage ? util.parseNumber(merce.WastPercentage, this.locale) : 0;
        merce.ProductionRatio = merce.ProductionRatio ? util.parseNumber(merce.ProductionRatio, this.locale) : 0;
        if (!merce.Prices) merce['Prices'] = [];
        merce.Prices.forEach(e => {
            e.OtherUnitRatio = e.OtherUnitRatio ? util.parseNumber(e.OtherUnitRatio, this.locale) : 0;
            e.SecondaryUnitRatio = e.SecondaryUnitRatio ? util.parseNumber(e.SecondaryUnitRatio, this.locale) : 0;

            e.Price = e.Price ? util.parseNumber(e.Price, this.locale) : 0;
            e.PriceUM = e.PriceUM ? util.parseNumber(e.PriceUM, this.locale) : 0;
            e.PriceUO = e.PriceUO ? util.parseNumber(e.PriceUO, this.locale) : 0;
        });

        if (!merce.Suppliers) merce['Suppliers'] = [];
        merce.Suppliers.forEach(e => {
            e.Variations.forEach(v => {
                v.OtherUnitRatio = v.OtherUnitRatio ? util.parseNumber(v.OtherUnitRatio, this.locale) : 0;
                v.SecondaryUnitRatio = v.SecondaryUnitRatio ? util.parseNumber(v.SecondaryUnitRatio, this.locale) : 0;
                v.Price = v.Price ? util.parseNumber(v.Price, this.locale) : 0;
                v.S1 = v.S1 ? util.parseNumber(v.S1, this.locale) : 0;
                v.S2 = v.S2 ? util.parseNumber(v.S2, this.locale) : 0;
                v.S3 = v.S3 ? util.parseNumber(v.S3, this.locale) : 0;
                v.S4 = v.S4 ? util.parseNumber(v.S4, this.locale) : 0;
                v.QxC = v.QxC ? util.parseNumber(v.QxC, this.locale) : 0;
            });
        });
    }

    parseFields(merce: any) {
        if (!merce.Prices) merce['Prices'] = [];
        merce.Prices.forEach(e => {
            e.OtherUnitRatio = e.OtherUnitRatio ? util.formatNumber(e.OtherUnitRatio, this.locale, 0) : 0;
            e.SecondaryUnitRatio = e.SecondaryUnitRatio ? util.formatNumber(e.SecondaryUnitRatio, this.locale, 0) : 0;

            e.Price = e.Price ? util.formatNumber(e.Price, this.locale) : 0;
            e.PriceUM = e.PriceUM ? util.formatNumber(e.PriceUM, this.locale) : 0;
            e.PriceUO = e.PriceUO ? util.formatNumber(e.PriceUO, this.locale) : 0;
        });

        if (!merce.Suppliers) merce['Suppliers'] = [];
        merce.Suppliers.forEach(e => {
            e.Variations.forEach(v => {
                v.OtherUnitRatio = v.OtherUnitRatio ? util.formatNumber(v.OtherUnitRatio, this.locale) : 0;
                v.SecondaryUnitRatio = v.SecondaryUnitRatio ? util.formatNumber(v.SecondaryUnitRatio, this.locale) : 0;
                v.Price = v.Price ? util.formatNumber(v.Price, this.locale) : 0;
            });
        });
    }


    /* Gestione tabella calcoli */

    selectPrice(price: any) {

    }

    allList: boolean = false;

    onUnitChange(event: any, item: any, type: string) {
        item[type] = parseInt(item[type]);
        if (this.allList && item) {
            const unit = this.unitOrd$.find((unit: any) => unit.Id == item[type])
            this.merce.Prices.forEach((e: any) => {
                e[type] = unit.Id;
            });
        }
        $(event.target).removeClass('is-valid');
    }

    onInputChange(event: any, item: any, type: string) {
        if (event.keyCode !== 13) {
            if (this.allList && item) {
                this.propagateAllList(type, item);
            }

            this.inputPuntiVenditaChanged.next({
                event: event,
                item: item,
                type: type
            });
        }
    }

    propagateAllList(type, item) {
        this.merce.Prices.forEach((e: any) => {
            e[type] = item[type];
            this.changeRowEvent({
                event: '',
                item: e,
                type: type
            });
        });
    }

    calcDiscount(item: any) {
        if (!item) return 0;
        let scontoParziale = 0;

        const S1 = this.utility.parseNumber(item.S1, this.locale);
        const S2 = this.utility.parseNumber(item.S2, this.locale);
        const S3 = this.utility.parseNumber(item.S3, this.locale);
        const S4 = this.utility.parseNumber(item.S4, this.locale);

        if (S1 > 0) {
            scontoParziale = 1 - S1 / 100;
            if (S2 > 0) {
                scontoParziale *= (1 - S2 / 100);
                if (S3 > 0) {
                    scontoParziale *= (1 - S3 / 100);
                    if (S4 > 0) {
                        scontoParziale *= (1 - S4 / 100);
                    }
                }
            }
        }

        return (scontoParziale > 0) ? (1 - scontoParziale) * 100 : 0;
    }

    copyAllDataToCompanies(variation: any) {

        if(this.data.disablePrices) return;

        this.merce.Prices.forEach((price: any) => {

            // Aggiunto perchè con nuova merce il controllo sottostante 
            // non permette di proseguire in quanto non è presente l'id del Supplier.
            if (!price.SupplierId) {
                price.SupplierId = variation.SupplierId;
            }

            // http://mantis.fbmanager.com/view.php?id=855 nel doc allegato
            if (price.SupplierId != variation.SupplierId) return;

            price.OtherUnit = variation.OtherUnit;
            price.OtherUnitId = variation.OtherUnitId;
            price.OtherUnitRatio = variation.OtherUnitRatio;
            price.SecondaryUnit = variation.SecondaryUnit;
            price.SecondaryUnitId = variation.SecondaryUnitId;
            price.SecondaryUnitRatio = variation.SecondaryUnitRatio;

            //https://mantis.fbmanager.com/view.php?id=1522
            const discount = this.calcDiscount(variation);
            const variationPrice = this.utility.parseNumber(variation.Price, this.locale, 3);            

            let variationPriceDiscounted = discount > 0 ? variationPrice - (variationPrice * discount / 100) : variationPrice;

            price.PriceUM = this.utility.formatNumber(variationPriceDiscounted, this.locale, 3);

            price.Price = variationPriceDiscounted;
            price.FormattedPrice = this.utility.formatNumber(variationPriceDiscounted, this.locale, 3);

            price.SupplierId = variation.SupplierId;

            this.changeRowEvent({
                item: price,
                type: 'PriceUM'
            });
        });
    }

    /******************************************************************************
      ______                       _    __   ___       _______   __
     |  ____|                     | |   \ \ / / |     / ____\ \ / /
     | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
     |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
     | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
     |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
         | |
         |_|
    ******************************************************************************/
    exportAsXLSX() {
        this.loading$.next(true);
        let xlsx: any[] = [];
        let merges: any[] = [];
        let i: number = 0;
        let sortedList = this.merce.Prices;//this.utility.sortList(this.ricetta.Prices, 'tableCalcolo');
        let rowsStyles: any = [];
        sortedList.forEach((item: any) => {

            if (i % 2 === 0) {
                rowsStyles[i] = {
                    fgColor: { rgb: 'e6edfb' }
                };
            }

            let columns: any = {};

            columns[`${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.AZIENDA')}`] = item.Azienda;
            columns[`${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.PREZZO_UB')}`] = item.Price ? this.utility.formatNumberExcel(this.utility.parseNumber(item.Price, this.locale), 2) : '';
            columns[`${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.PREZZO_UNITA_MAGAZZINO')}`] = item.PriceUM ? this.utility.formatNumberExcel(this.utility.parseNumber(item.PriceUM, this.locale), 2) : '';
            columns[`${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.PREZZO_UNITA_ORDINE')}`] = item.PriceUO ? this.utility.formatNumberExcel(this.utility.parseNumber(item.PriceUO, this.locale), 2) : '';
            columns[`${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.UNITA_ORDINE')}`] = item.OtherUnit;
            columns[`${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.RAPPORTO_UO_UM')}`] = item.OtherUnitRatio ? this.utility.formatNumberExcel(this.utility.parseNumber(item.OtherUnitRatio, this.locale), 0) : '';
            columns[`${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.UNITA_MAGAZZINO')}`] = item.SecondaryUnit;
            columns[`${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.RAPPORTO_UM_UB')}`] = item.SecondaryUnitRatio ? this.utility.formatNumberExcel(this.utility.parseNumber(item.SecondaryUnitRatio, this.locale), 0) : '';
            columns[`${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.FORNITORE')}`] = this.getSupplierById(item.SupplierId) ? this.getSupplierById(item.SupplierId).Name : '';
            columns[`${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.QTA_MIN')}`] = '';
            columns[`${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.QTA_MAX')}`] = '';
            columns[`${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.NOME')}`] = item.Alias;
            columns[`${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.VISIBILITA')}`] = item.IsMaster ? 'true' : 'false';

            xlsx.push(columns);
            i++;
        });

        this.loading$.next(false);
        this.excelService.exportAsExcelFile(xlsx, 'editgoods', merges, [], 1, rowsStyles, true);
    }


    /******************************************************************************
        ______                       _     _____  _____  ______
       |  ____|                     | |   |  __ \|  __ \|  ____|
       | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
       |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
       | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
       |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
             | |
             |_|
    ******************************************************************************/
    exportAsPDF() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.translate.instant('GESTIONE_MERCI.VISIBILITA_MERCI') }),
                    //period: ''
                }
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                }
                result['language'] = this.translationService.getSelectedLanguage();
                result['table'].headerRows = 1;

                let widts: string[] = ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto', 'auto'];

                this.pdfService.makePdf(result, this.getPDFTableBody(), widts);
            };
        });

    }

    getPDFTableBody() {
        let body: any = [];
        let tmpRow: any = [];

        // aggiungo intestazione
        // totali Header
        tmpRow = [
            { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.AZIENDA')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.PREZZO_UB')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.PREZZO_UNITA_MAGAZZINO')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.PREZZO_UNITA_ORDINE')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.UNITA_ORDINE')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.RAPPORTO_UO_UM')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.UNITA_MAGAZZINO')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.RAPPORTO_UM_UB')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.FORNITORE')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.QTA_MIN')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.QTA_MAX')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.NOME')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_MERCI.EDIT.PUNTI_VENDITA.COLONNE.VISIBILITA')}`, style: 'tableHeaderStyle' },
        ];

        body.push(tmpRow.filter((item: any) => item.visible));

        let i: number = 0;
        let sortedList = this.merce.Prices;//this.utility.sortList(this.ricetta.Prices, 'table');
        sortedList.forEach((item: any) => {
            //this.consumptionListFiltered.forEach((item: any) => {

            tmpRow = [
                { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Azienda, style: 'tableBodyStyle' },
                { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Price ? this.utility.formatNumberExcel(this.utility.parseNumber(item.Price, this.locale), 2) : '', style: 'tableBodyStyle' },
                { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.PriceUM ? this.utility.formatNumberExcel(this.utility.parseNumber(item.PriceUM, this.locale), 2) : '', style: 'tableBodyStyle' },
                { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.PriceUO ? this.utility.formatNumberExcel(this.utility.parseNumber(item.PriceUO, this.locale), 2) : '', style: 'tableBodyStyle' },
                { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.OtherUnit, style: 'tableBodyStyle' },
                { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.OtherUnitRatio ? this.utility.formatNumberExcel(this.utility.parseNumber(item.OtherUnitRatio, this.locale), 0) : '', style: 'tableBodyStyle' },
                { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.SecondaryUnit, style: 'tableBodyStyle' },
                { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.SecondaryUnitRatio ? this.utility.formatNumberExcel(this.utility.parseNumber(item.SecondaryUnitRatio, this.locale), 0) : '', style: 'tableBodyStyle' },
                { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: this.getSupplierById(item.SupplierId) ? this.getSupplierById(item.SupplierId).Name : '', style: 'tableBodyStyle' },
                { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: '', style: 'tableBodyStyle' },
                { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: '', style: 'tableBodyStyle' },
                { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Alias, style: 'tableBodyStyle' },
                { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.IsMaster ? 'true' : 'false', style: 'tableBodyStyle' },
            ];

            body.push(tmpRow.filter((item: any) => item.visible));
            i++;
        });

        return body;
    }

    async creaProdottoVendita() {

        // const singleGood =  await this.gestioneMerciService.getSingleGoods(this.merce.Id).toPromise();
        const codeMerce = await this.gestioneRicetteService.getNextRecipeCode().toPromise();

        let newRicetta = {

            Code: codeMerce,
            Name: this.merce.Name,
            A: this.merce.A,
            B: this.merce.B,
            BaseUnitId: this.merce.BaseUnitId,
            Unit: this.unit$.find((x: any) => x.Id == this.merce.UnitId),
            UnitId: this.merce.UnitId,
            Yield: 1,
            Category: { Name: '', Id: '' },
            Type: 1,
            Ingredients: [{
                Goods: {
                    Name: this.merce.Name,
                    AlternateName: this.merce.AlternateName,
                    Code: this.merce.Code,
                    BaseUnitId: this.merce.BaseUnitId,
                    Id: this.merce.Id,
                    Price: this.merce.Price,
                    UnitId: this.merce.UnitId,
                    UnitName: this.merce.Unit,
                    A: this.merce.A,
                    B: this.merce.B
                },
                Quantity: 1,
                Unit: {
                    Name: this.merce.Unit,
                    BaseId: this.merce.BaseUnitId,
                    Id: this.merce.UnitId
                }
            }],
            Department: {
                id: ''
            },
            Prices: []
        };

        const dialogRef = this.dialog.open(EditRicettaComponent, {
            data: {
                ricetta: newRicetta
            },
            width: '100%',
            height: '100%'
        });
    }

    showDisabled: boolean = false;

    performShowDisabled(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.showDisabled = !this.showDisabled;
    }

    selectBrand(brandId: any) {
        if (!this.merce.Brands) this.merce.Brands = [];
        this.merce.Brands.push({
            Id: brandId,
            Name: this.brands$.find(b => b.Id == brandId).Name
        });
    }

    unselectBrand(brandId: any) {
        this.merce.Brands = this.merce.Brands.filter(b => b.Id !== brandId);
    }

    clearBrands(event: any) {
        this.merce.Brands = [];
    }
}
