import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API } from '@env/environment';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {

	constructor(
		private http: HttpClient,
		private switchGroupService: SwitchGroupService
	) { }

	getHeader(){
		const currentLogin = this.switchGroupService.getCurrentGroup();

		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + currentLogin.access_token
		});

		return  {url: currentLogin.endpointURL, header: httpHeaders};
	}

	getConfigurations(): Observable<any> {
		const httpHeaders = this.getHeader();

		return this.http.get<any>(`${httpHeaders.url}/api/Admin/Configurations`, { headers: httpHeaders.header });
	}

	
	postConfigurations( entityBody: any): Observable<any> {
		const httpHeaders = this.getHeader();
		return this.http.post<any>(`${httpHeaders.url}/api/Admin/Configurations`, entityBody, { headers: httpHeaders.header });
	}

	refreshAllData(): Observable<any> {
		const httpHeaders = this.getHeader();
		return this.http.post<any>(`${httpHeaders.url}/api/Admin/RefreshAllData`, null, { headers: httpHeaders.header });
	}


	testConnection(host,database,databaseUser,password): Observable<any>{
		const currentLogin = this.switchGroupService.getCurrentGroup();
	
		const httpHeaders = new HttpHeaders({
		  'Content-Type': 'application/json',
		  'Authorization': 'Bearer ' + currentLogin.access_token
		});
	
		const url =  `/api/Admin/CheckDBConnection?host=${host}&database=${database}&databaseUser=${databaseUser}&password=${password}`;
	
		return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
	  }
}
