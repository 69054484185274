import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, Input } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';

// Services
import { TranslateService } from '@ngx-translate/core';
import { FiltriService } from '@app/core/services/filtri.service';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

// Components
import { ConsumiComponent } from './consumi/consumi.component';
import { ConsumiDettagliatiComponent } from './consumi-dettagliati/consumi-dettagliati.component';
import { ConsumiSemplificatiComponent } from './consumi-semplificati/consumi-semplificati.component';

import * as _ from 'lodash';
import { ConsumiAcquistiComponent } from './consumi-acquisti/consumi-acquisti.component';
import { RoleService } from '@app/core/_base/layout';

@Component({
    selector: 'kt-analisi-consumi',
    templateUrl: './analisi-consumi.component.html',
    styleUrls: ['./analisi-consumi.component.scss']
})
export class AnalisiConsumiComponent implements OnInit, OnDestroy {
    InventoryDateType: string = 'fixed';
    unitBase: boolean = false;
    currentTabIndex!: number;
    currentFilterCfg: any;
    consumptionList: any;
    consumptionListOriginal: any;

    showInitialImage: boolean = true;

    switchGroupSubcription!: Subscription;
    filtriSubscription!: Subscription;
    // staticCollectionsSubcription!: Subscription;

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    filterChanged: Subject<any> = new Subject<any>();
    categorie: any;

    groupColumns: boolean = false;
    valueZero: boolean = true;
    ckMovimentiMagazzino: boolean = false;

    checkErr1: boolean = true;
    checkErr2: boolean = true;
    checkErr3: boolean = true;

    public consumi!: ConsumiComponent;
    @ViewChild('consumi', { static: false }) set _consumi(content: ConsumiComponent) {
        if (content) { // initially setter gets called with undefined
            this.consumi = content;
            this.manageTab();
        }
    }

    public consumiDettagliati!: ConsumiDettagliatiComponent;
    @ViewChild('consumiDettagliati', { static: false }) set _consumiDettagliati(content: ConsumiDettagliatiComponent) {
        if (content) { // initially setter gets called with undefined
            this.consumiDettagliati = content;
            this.manageTab();
        }
    }

    public consumiSemplificati!: ConsumiSemplificatiComponent;
    @ViewChild('consumiSemplificati', { static: false }) set _consumiSemplificati(content: ConsumiSemplificatiComponent) {
        if (content) { // initially setter gets called with undefined
            this.consumiSemplificati = content;
            this.manageTab();
        }
    }

    public consumiAcquisti!: ConsumiAcquistiComponent;
    @ViewChild('consumiAcquisti', { static: false }) set _consumiAcquisti(content: ConsumiAcquistiComponent) {
        if (content) { // initially setter gets called with undefined
            this.consumiAcquisti = content;
            this.manageTab();
        }
    }

    public productName!: ElementRef;
    @ViewChild('Product', { static: false }) set _productName(content: ElementRef) {
        if (content) { // initially setter gets called with undefined
            this.productName = content;
        }
    }

    public productCode!: ElementRef;
    @ViewChild('Code', { static: false }) set _productCode(content: ElementRef) {
        if (content) { // initially setter gets called with undefined
            this.productCode = content;
        }
    }

    constructor(
        private switchGroupService: SwitchGroupService,
        private filtriService: FiltriService,
        private translate: TranslateService,
        public staticCollectionsService: StaticCollectionsService,
        private ref: ChangeDetectorRef,
        public dialog: MatDialog,
        public roleService: RoleService
    ) {
        this.groupColumns = false;
    }

    ngOnInit() {
        this.categorie = [];

        document.body.classList.add('kt-aside--minimize');
        this.currentTabIndex = 0;

        this.filtriSubscription = this.filtriService.performFilter$.subscribe(
            (filterConfig: any) => {
                if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) return;
                if (filterConfig.constructor !== Object) return;
                this.showInitialImage = false;

                this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));
                if (this.valueZero && !this.currentFilterCfg['ValueZero']) {
                    this.currentFilterCfg['ValueZero'] = true;
                }

                setTimeout(() => {
                    this.manageTab();
                    this.ref.detectChanges();
                }, 100);
            }
        );
    }

    manageTab() {

        setTimeout(() => {
            switch (this.currentTabIndex) {
                case 0: {
                    if (!this.consumi) return;
                    this.consumi.currentFilterCfg = this.currentFilterCfg;
                    if (this.consumi.table) {
                        this.consumi.table.draw()
                    } else {
                        this.consumi.initDataTable();
                    }
                }
                    break;
                case 1: {
                    if (!this.consumiDettagliati) return;
                    this.consumiDettagliati.currentFilterCfg = this.currentFilterCfg;
                    if (this.consumiDettagliati.table) {
                        this.consumiDettagliati.table.draw()
                    } else {
                        this.consumiDettagliati.initDataTable();
                    }
                }
                    break;
                case 2: {
                    if (!this.consumiSemplificati) return;
                    this.consumiSemplificati.currentFilterCfg = this.currentFilterCfg;
                    if (this.consumiSemplificati.table) {
                        this.consumiSemplificati.table.draw()
                    } else {
                        this.consumiSemplificati.initDataTable();
                    }
                }
                    break;
                case 3: {
                    if (!this.consumiAcquisti) return;
                    this.consumiAcquisti.currentFilterCfg = this.currentFilterCfg;
                    if (this.consumiAcquisti.table) {
                        this.consumiAcquisti.table.draw()
                    } else {
                        this.consumiAcquisti.initDataTable();
                    }
                }
            }
        }, 100);
    }

    bestItems: any;
    bestItemsChanged(event: any) {
        if (event.keyCode === 13) {
            if (!this.currentFilterCfg) this.currentFilterCfg = {
                Period: 'YEAR',
                ValueZero: true
            };
            this.currentFilterCfg['BestItems'] = this.bestItems;
            this.currentFilterCfg['MostPurchasesdItems'] = this.bestItems;
            this.filtriService.filterConfig = this.currentFilterCfg;
            this.filtriService.performFilter$.next(this.currentFilterCfg);
        }
    }

    manageUnit() {
        switch (this.currentTabIndex) {
            case 0:
                this.consumi.table.draw();
                break;
            case 1:
                this.consumiDettagliati.table.draw()();
                break;
            case 2:
                this.consumiSemplificati.table.draw()();
                break;
            case 3:
                this.consumiAcquisti.table.draw()();
                break;
        }
    }

    showInventoryDate() {
        this.currentFilterCfg['ValueZero'] = this.valueZero;
        this.currentFilterCfg['UseRealInvetoryDate'] = this.InventoryDateType === "real";
        this.filtriService.filterConfig = this.currentFilterCfg;
        this.filtriService.performFilter$.next(this.currentFilterCfg);
    }

    /**
  * After view init
  */
    ngAfterViewInit(): void {
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.clearFilters(false);
                }
            }
        );

        this.filterChanged
            .pipe(
                debounceTime(300),
                //distinctUntilChanged()
            )
            .subscribe((model) => {
                if (model) {
                    this.currentFilterCfg[model.type] = model.value;
                    this.filtriService.filterConfig = this.currentFilterCfg;
                    this.filtriService.performFilter$.next(this.currentFilterCfg);
                }
            })


        fromEvent(this.productName.nativeElement, 'keyup')
            .pipe(
                // tslint:disable-next-line:max-line-length
                debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
                distinctUntilChanged(), // This operator will eliminate duplicate values
                tap((ev: any) => {
                    if (ev.keyCode == 13) this.onFilterChange(this.productName.nativeElement.value, 'ProductName');
                })
            )
            .subscribe();

        fromEvent(this.productCode.nativeElement, 'keyup')
            .pipe(
                // tslint:disable-next-line:max-line-length
                debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
                distinctUntilChanged(), // This operator will eliminate duplicate values
                tap((ev: any) => {
                    if (ev.keyCode == 13) this.onFilterChange(this.productCode.nativeElement.value, 'ProductCode');
                })
            )
            .subscribe();

        this.initFiltri();
    }

    showCategories: any;
    async initFiltri() {

        this.showCategories = false;
        await this.staticCollectionsService.fillStaticCollection(false);

        this.showCategories = true;
        this.ref.detectChanges();
    }

    filterByDeviation(event: any) {
        if (event.code === 'Enter' || event.code === 'NumPadEnter') {
            this.consumiDettagliati.filterErrors({ checked: event.target.value }, 'Deviation')
        }
    }

    clearFilters(actionFilter: boolean = true) {
        if (this.productName) this.productName.nativeElement.value = '';
        if (this.productCode) this.productCode.nativeElement.value = '';
        this.categorie = undefined;


        this.filtriService.clearFilters$.next(actionFilter);
        $('#table').DataTable().clear().destroy();
        this.consumptionList = undefined;
        this.consumptionListOriginal = undefined;
        this.bestItems = undefined;

        this.showInitialImage = true;

        if (this.consumi) this.consumi.initComplete = false;
        if (this.consumiDettagliati) this.consumiDettagliati.initComplete = false;
        if (this.consumiSemplificati) this.consumiSemplificati.initComplete = false;
    }

    visualizzaMovimentiMagazzino: boolean = false;
    onGroupColumns(event: any) {
        this.visualizzaMovimentiMagazzino = event.checked;
        this.consumiDettagliati.performGroupColumns(event.checked);
    }



    openColumnsDialog() {
        if (!this.isInitComplete()) return
        switch (this.currentTabIndex) {
            case 0:
                this.consumi.openColumnsDialog();
                break;
            case 1:
                this.consumiDettagliati.openColumnsDialog();
                break;
            case 2:
                this.consumiSemplificati.openColumnsDialog();
                break;
            case 3:
                this.consumiAcquisti.openColumnsDialog();
                break;
        }
    }

    isInitComplete() {
        switch (this.currentTabIndex) {
            case 0:
                return this.consumi && this.consumi.initComplete;
            case 1:
                return this.consumiDettagliati && this.consumiDettagliati.initComplete;
            case 2:
                return this.consumiSemplificati && this.consumiSemplificati.initComplete;
            case 3:
                return this.consumiAcquisti && this.consumiAcquisti.initComplete;
        }
        return false;
    }

    /**
  * On Destroy
  */
    ngOnDestroy() {
        if (this.filtriSubscription) this.filtriSubscription.unsubscribe();
        // if (this.staticCollectionsSubcription) this.staticCollectionsSubcription.unsubscribe();
        this.switchGroupSubcription.unsubscribe();

        // cancello il filtro
        this.filtriService.filterConfig = {};
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        if (tabChangeEvent.index === this.currentTabIndex || tabChangeEvent.index < 0) return;
        this.ckMovimentiMagazzino = false;
        this.currentTabIndex = tabChangeEvent.index;
        this.ref.detectChanges();
    }

    onFilterChange(event: any, type: string) {
        if (!this.currentFilterCfg) this.currentFilterCfg = {
            Period: 'YEAR',
            ValueZero: true
        };
        switch (type) {
            case 'ProductName':
                this.currentFilterCfg['ProductName'] = this.productName.nativeElement.value;
                this.filtriService.filterConfig = this.currentFilterCfg;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
                break;
            case 'ProductCode':
                this.currentFilterCfg['ProductCode'] = this.productCode.nativeElement.value;
                this.filtriService.filterConfig = this.currentFilterCfg;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
                break;
            case 'CategoryIds':
                this.currentFilterCfg['CategoryIds'] = event;
                this.filtriService.filterConfig = this.currentFilterCfg;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
                break;
        }

    }

    filter(event: any) {
        this.currentFilterCfg['ValueZero'] = event.checked;
        this.filtriService.filterConfig = this.currentFilterCfg;
        this.filtriService.performFilter$.next(this.currentFilterCfg);
    }

    hoverglassActive: boolean = false;
    manageHoverglass(event: any) {
        this.hoverglassActive = event;
        this.ref.detectChanges();
    }

    showFromYearStart() {
        this.showInitialImage = false;
        this.currentFilterCfg = { Period: 'YEAR', ValueZero: true };
        this.filtriService.filterConfig = this.currentFilterCfg;
        this.filtriService.performFilter$.next(this.currentFilterCfg);
        this.ref.detectChanges();
    }

    showSpinner(event: any) {
        this.loading$.next(event);
        this.hoverglassActive = event;
        this.ref.detectChanges();
    }

    isShowingErrors: boolean = false;
    showErrors() {
        this.isShowingErrors = !this.isShowingErrors;

        // Attivo i check per la prima volta per comprendere tutti gli errori,
        // poi se uno vuole disabilita quello che gli serve
        this.checkErr1 = this.isShowingErrors;
        this.checkErr2 = this.isShowingErrors;
        this.checkErr3 = this.isShowingErrors;

        this.consumiDettagliati.showErrors(this.isShowingErrors);
    }

    isShowingImports: boolean = false;
    showImports() {
        this.isShowingImports = !this.isShowingImports;
        this.consumiDettagliati.showImports();
    }

    isShowingTotals: boolean = false;
    showTotals() {
        this.isShowingTotals = !this.isShowingTotals;
        this.consumiDettagliati.showTotals();
    }

    /****************************************************************************
      ______                       _
     |  ____|                     | |
     | |__  __  ___ __   ___  _ __| |_
     |  __| \ \/ / '_ \ / _ \| '__| __|
     | |____ >  <| |_) | (_) | |  | |_
     |______/_/\_\ .__/ \___/|_|   \__|
                 | |
                 |_|
     ****************************************************************************/

    exportAsXLSX() {
        switch (this.currentTabIndex) {
            case 0:
                this.consumi.exportAsXLSX();
                break;
            case 1:
                this.consumiDettagliati.exportAsXLSX();
                break;
            case 2:
                this.consumiSemplificati.exportAsXLSX();
                break;
            case 3:
                this.consumiAcquisti.exportAsXLSX();
                break;
        }
    }

    exportAsPDF() {
        switch (this.currentTabIndex) {
            case 0:
                this.consumi.exportAsPDF();
                break;
            case 1:
                this.consumiDettagliati.exportAsPDF();
                break;
            case 2:
                this.consumiSemplificati.exportAsPDF();
                break;
            case 3:
                this.consumiAcquisti.exportAsPDF();
                break;
        }
    }
}
