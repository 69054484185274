import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import * as _ from 'lodash';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';

// Services
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';

import * as util from '@app/core/services/utilityfunctions';

@Component({
    selector: 'kt-storico-vendite-edit',
    templateUrl: './storico-vendite-edit.component.html',
    styleUrls: ['./storico-vendite-edit.component.scss']
})
export class StoricoVenditeEditComponent implements OnInit {

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    insertType!: string;
    company: any;
    costCenter: any;
    department: any;
    date!: Date;

    goodsOrSubrecipeNamesList: any;
    merci: any;

    listaCompanies: any;
    listaCostCenters: any;
    listaDepartment: any;

    viewLoading: boolean = false;
    hasFormErrors: boolean = false;
    errorMessage!: string;

    minDate!: Date;
    maxDate!: Date;

    item: any;

    isEdit: boolean = false;
    searchFilter: string = '';
    utility: any;
    locale!: string;
    mappaAziende = {};
    listaAziende: any = [];
    initValue: any;


    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        public staticCollectionsService: StaticCollectionsService,
        private translationService: TranslationService,
        private gestioneMerciService: GestioneMerciService
    ) {
        this.utility = util;
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
    }

    ngOnInit() {

        console.log(this.data);
        this.item = this.data && this.data.item ? this.data.item : {};
        this.initValue = JSON.stringify(this.item);
        this.company = this.item.Company.toLowerCase();
        this.item.Time = this.item.Time.replace('.', ':');
        this.item.Price = this.utility.formatNumber(this.utility.parseNumber(this.item.Price, this.locale), this.locale);
        this.item.FoodCost = this.utility.formatNumber(this.utility.parseNumber(this.item.FoodCost, this.locale), this.locale);

        this.gestioneMerciService.getProducts();

        this.loading$.next(true);
        this.gestioneMerciService.getProducts().toPromise()
            .then((productList: any) => {
                console.log('productList', productList);
                this.goodsOrSubrecipeNamesList = productList;

                this.merci = [];
                if ($('#select2Merci').data('select2')) {
                    (<any>$('#select2Merci')).select2('destroy');
                }
                (<any>$('#select2Merci')).select2({
                    placeholder: this.translate.instant('FILTRI.PRODUCTS'),
                    allowClear: true,
                    closeOnSelect: true,
                    multiple: false,
                    width: '100%',
                    containerCssClass: 'select2Single',
                    language: {
                        errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                        inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                        inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                        loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                        maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                        noResults: () => this.translate.instant('SELECT2.noResults'),
                        searching: () => this.translate.instant('SELECT2.searching')
                    }
                });
                $('#select2Merci').on('select2:select', (e: any) => {
                    this.item['ProductId'] = e.params.data.id;
                    this.item['Product'] = e.params.data.text;
                });
                $('#select2Merci').on('select2:clear', (e: any) => {
                    delete this.item['ProductId'];
                    delete this.item['Product'];
                });

                setTimeout(() => {
                    $('#select2Merci').val(this.item.ProductId).trigger('change');
                }, 250);

                this.loading$.next(false);

            });
        this.staticCollectionsService.aziende$.forEach((element: any) => {
            const name = element.Name.toLowerCase();
            const obj = { Id: name, Name: element.Name };
            this.listaAziende.push(obj);
            this.mappaAziende[name] = obj;
        });
    }

    onSubmit() {
        const currentValue = JSON.stringify(this.item);

        let action = 'none';
        this.item.FoodCost = this.utility.parseNumber(this.item.FoodCost, this.locale, 2);
        this.item.Price = this.utility.parseNumber(this.item.Price, this.locale, 2);
        this.item.Quantity = this.utility.parseNumber(this.item.Quantity, this.locale, 2);
        if (currentValue !== this.initValue) {
            this.item.Company = this.mappaAziende[this.company].Name;
            this.item.CostCenterName = this.item.CostCenter;
            this.item.CostCenterId = this.item.CostCenterId;

            action = 'save';
        }

        this.dialogRef.close({ Action: action, Object: this.item });
    }

    formNotCompleted() {
        return !this.item.Date || !this.item.Company || !this.item.CostCenterId || !this.item.FoodCost || !this.item.Price || !this.item.Quantity || !this.item.Time;
    }

}
