import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

@Directive({
    selector: '[ktFormatCurrency]'
})
export class FormatCurrencyDirective {

    private el: HTMLInputElement;

    constructor(private elementRef: ElementRef, private translationService: TranslationService) {
        this.el = this.elementRef.nativeElement;
    }

    ngOnInit() {
        this.el.value = this.parseCurrency(this.el.value.toString());
    }

    private getDecimalSeparator(locale) {
        const numberWithDecimalSeparator = 1.1;
        return Intl.NumberFormat(locale)?.
            formatToParts(numberWithDecimalSeparator)?.
            find(part => part.type === 'decimal')?.value;
    }


    formatCurrency(x: any, fractionSize: number = 2) {
        if (!x || String(x).includes('NaN')) return '';
        return x.toLocaleString(this.translationService.getSelectedLanguage(), {
            minimumFractionDigits: fractionSize,
            maximumFractionDigits: fractionSize
        });
    }

    parseCurrency(value: string, fractionSize: number = 2) {
        const PADDING = "000000";
        const DECIMAL_SEPARATOR: any = this.getDecimalSeparator(this.translationService.getSelectedLanguage())
        const THOUSANDS_SEPARATOR = DECIMAL_SEPARATOR === '.' ? ',' : '.';

        let [integer, fraction = ""] = (value || "").split(DECIMAL_SEPARATOR);

        integer = integer.replace(new RegExp(THOUSANDS_SEPARATOR, "g"), "");
        fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
            ? DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
            : "";

        return integer + fraction;
    }

    @HostListener("focus", ["$event.target.value"])
    onFocus(value) {
        this.el.value = this.parseCurrency(value); // opossite of transform
    }

    @HostListener("blur", ["$event.target.value"])
    onBlur(value) {
        this.el.value = this.formatCurrency(value);
    }


}
