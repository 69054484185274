export class SocialNetworks {
    linkedIn!: string;
	facebook!: string;
	twitter!: string;
    instagram!: string;

    clear() {
        this.linkedIn = '';
        this.facebook = '';
        this.twitter = '';
        this.instagram = '';
    }
}
