import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { RoleService } from '@app/core/_base/layout';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(
    private http: HttpClient,
    private switchGroupService: SwitchGroupService,
    private roleService: RoleService
  ) { }

  getAllIssues(): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });
    return this.http.get<any>(`${currentLogin.endpointURL}/api/Tickets?endpoint=${currentLogin.group.toLowerCase()}` , { headers: httpHeaders });
  }

  getIssue(issueId: string): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.get<any>(`${currentLogin.endpointURL}/api/Tickets?endpoint=${currentLogin.group.toLowerCase()}&id=${issueId}` , { headers: httpHeaders });
  }

  getIssueFiles(issueId: string): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.get<any>(`${currentLogin.endpointURL}/api/Tickets/Files/?endpoint=${currentLogin.group.toLowerCase()}&id=${issueId}` , { headers: httpHeaders });
  }

  newIssue(issue: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(currentLogin.endpointURL + '/api/Tickets?endpoint=' + currentLogin.group.toLowerCase(), issue, { headers: httpHeaders });
  }

  newIssueNote(issueId: string, note: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(`${currentLogin.endpointURL}/api/Tickets/Notes/?endpoint=${currentLogin.group.toLowerCase()}&id=${issueId}`, note, { headers: httpHeaders });
  }

  closeIssue(issueId: string, body: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });
    return this.http.put<any>(`${currentLogin.endpointURL}/api/Tickets?endpoint=${currentLogin.group.toLowerCase()}`, body, { headers: httpHeaders });
  }

}
