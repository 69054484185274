import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-show-xml-invoice-dialog',
  templateUrl: './show-xml-invoice-dialog.component.html',
  styleUrls: ['./show-xml-invoice-dialog.component.scss']
})
export class ShowXmlInvoiceDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    const merceRowId = document.getElementById(this.data.code);
    if (merceRowId) merceRowId.scrollIntoView();

    setTimeout(() => {
        const cdk = $('kt-show-xml-invoice-dialog').closest('div.cdk-overlay-pane');
        cdk.css('position', 'absolute');
        cdk.css('top', this.data.top);
        cdk.css('left', this.data.left);
    }, 100);
  }

  printContent(): void {
    const printContents = document.querySelector('.previewInvoice')?.innerHTML;
    if (printContents) {
      const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin?.document.open();
      const title = this.translate.instant('XML_INVOICE_DIALOG.TITLE', { 'invoiceNumber': this.data.invoiceNumber });
      popupWin?.document.write(`
        <html>
          <head>
            <title>${title}</title>
            <style>
              // Aggiungi eventuali stili personalizzati per la stampa qui
            </style>
          </head>
          <body onload="window.print();window.close()">
            ${printContents}
          </body>
        </html>`
      );
      popupWin?.document.close();
    }
  }
}
