import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import { SwitchGroupService, TranslationService } from '@app/core/_base/layout';
import { EndpointsService } from '@app/core/services/endpoints.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { UsersEditComponent } from '../users-edit/users-edit.component';
import _ from 'lodash';
import moment from 'moment';
import { PDFService } from '@app/core/services/pdf.service';

@Component({
    selector: 'kt-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

    users: any;
    selectedEndPointId: any;
    endpoint: any;
    endpoints: any;
    needRefreshEndpoints: boolean = false;
    table: any;
    PDFPreferences: any;
    locale: string = 'it';
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    viewLoading: boolean = false;

    constructor(
        private translate: TranslateService,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        private layoutUtilsService: LayoutUtilsService,
        private switchGroupService: SwitchGroupService,
        private endpointsService: EndpointsService,
        private translationService: TranslationService,
        private pdfService: PDFService
    ) {

        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
            moment.locale(this.locale)
        });
    }

    ngOnInit(): void {
        this.users = this.data.users;
        this.endpoint = this.data.endpoint;
        this.endpoints = this.data.endpoints;
        this.selectedEndPointId = this.endpoint.Id;
        this.initDataTableUsers();
    }

    initDataTableUsers(scrollPosition: number = 0) {
        if ($.fn.dataTable.isDataTable('#usersTable')) {
            $('#usersTable').DataTable().destroy();
        }
        setTimeout(() => {
            const dtOptions = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: true,
                ordering: true,
                scrollY: '300px',
                scrollCollapse: true,
                columnDefs: [
                    { targets: [4], orderable: false }
                ],
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        //text: '<i class="fa fa-file-pdf text-danger"></i>',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.hideInExport)',
                            //format: {
                            //    body: function (data, row, column, node) {
                            //        if (data.indexOf('exportText') >= 0) {
                            //            var parser = new DOMParser();
                            //            var htmlDoc = parser.parseFromString(data, 'text/html');
                            //            return $(htmlDoc).find('.exportText').text();
                            //        } else {
                            //            return data
                            //        }
                            //    }
                            //}
                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        //autoFilter: true,
                        //sheetName: 'Exported data',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.hideInExport)',
                            //format: {
                            //    body: (data, row, column, node) => {
                            //        // dalla colonna 1 in avanti formatto
                            //        if (data.indexOf('exportText') >= 0) {
                            //            var parser = new DOMParser();
                            //            var htmlDoc = parser.parseFromString(data, 'text/html');
                            //            return $(htmlDoc).find('.exportText').text();
                            //        } else {
                            //            const value = column >= 1 ? util.parseNumber(data, this.locale) : data;
                            //            return value;
                            //        }
                            //    }
                            //},
                        }
                    }
                ],
                // sort by the first column in ascending order
                order: [[0, 'asc']],
            };

            this.table = $('#usersTable').DataTable(dtOptions);

            // Ripristina la posizione dello scroll
            if (scrollPosition && scrollPosition > 0) {
                setTimeout(() => {
                    $('#usersTable').parent().scrollTop(scrollPosition);
                }, 0);
            }

        }, 100);
    }

    /**
 * Delete customer
 *
 * @param _item: CustomerModel
 */
    deleteUser(_item: any) {
        const _title: string = this.translate.instant('ENDPOINTS.DELETE_USER_SIMPLE.TITLE') + ' (' + _item.Email + ')';
        const _description: string = this.translate.instant('ENDPOINTS.DELETE_USER_SIMPLE.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('ENDPOINTS.DELETE_USER_SIMPLE.WAIT_DESCRIPTION');
        const _deleteMessage = this.translate.instant('ENDPOINTS.DELETE_USER_SIMPLE.MESSAGE');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe((res: any) => {
            if (!res) {
                return;
            }
            const groupList = this.switchGroupService.getGroupsList();
            const endPoint = groupList.find((x: any) => x.groupId == this.selectedEndPointId);
            const accessToken = endPoint.access_token;
            lastValueFrom(this.endpointsService.deleteUser(_item, this.selectedEndPointId, accessToken))
                .then((ret: any) => {
                    this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);

                    this.users = this.users.filter((x: any) => x.Email !== _item.Email);
                })
                .catch((error: any) => {
                    console.log(error);
                });
        });
    }

    duplicateUser(_item: any) {
        const payload: any = { ..._item };
        delete payload.$id;
        this.editUser(payload, 'duplicate');
    }

    async disableUser(user: any) {
        const groupList = this.switchGroupService.getGroupsList();
        let accessToken = groupList.find((x: any) => x.groupId == this.selectedEndPointId).access_token;
        this.loading$.next(true);
        await lastValueFrom(this.endpointsService.disableUser(user, this.selectedEndPointId, accessToken))
        this.needRefreshEndpoints = true;
        this.refreshEndPoints();
    }

    async enableUser(user: any) {
        const groupList = this.switchGroupService.getGroupsList();
        let accessToken = groupList.find((x: any) => x.groupId == this.selectedEndPointId).access_token;
        this.loading$.next(true);
        await lastValueFrom(this.endpointsService.enableUser(user, this.selectedEndPointId, accessToken));
        this.needRefreshEndpoints = true;
        this.refreshEndPoints();
    }

    refreshEndPoints() {
        // Salva la posizione dello scroll
        const scrollPosition = $('#usersTable').parent().scrollTop();

        lastValueFrom(this.endpointsService.getEndpoints())
            .then(
                (endpoints: any) => {
                    this.endpoint = endpoints.find((x: any) => x.Id == this.selectedEndPointId);
                    this.users = this.endpoint.Users;
                    this.endpoints = endpoints;
                    // redraw datatable
                    this.initDataTableUsers(scrollPosition);
                }
            )
            .finally(() => {
                this.loading$.next(false);
            });
    }

    exportAsXLSX(expandRows: boolean = false) {
        this.table.button(1).trigger();
    }

    exportAsPDF() {
        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {

            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('GESTIONE_FATTURE.TITLE'),
                    period: ''
                };
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                };
                result['language'] = this.translationService.getSelectedLanguage();

                this.PDFPreferences = result;

                this.table.button(0).trigger();
            };
        });
    }

    addUser() {
        const newUser = {
            Email: ''
        };
        this.editUser(newUser, 'edit');
    }

    editUser(user: any, action: string = '') {
        console.log(user);
        let saveMessageTranslateParam = 'ENDPOINTS.EDIT_USER.';
        saveMessageTranslateParam += user.Email && user.Email.length > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = user.Email && user.Email.length > 0 ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(UsersEditComponent, { 
            data: { 
                user: user, 
                endpoints: this.endpoints, 
                endpoint: this.endpoint,
                action: action
        } });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.users.push({
                Email: res.user.Data.Email,
                FullName: res.user.Data.FullName,
                Roles: res.user.Data.Groups[0].Role,
            });
            this.initDataTableUsers();
            this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
            this.needRefreshEndpoints = true;
        });
    }

    close() {
        this.dialogRef.close(this.needRefreshEndpoints);
    }
}
