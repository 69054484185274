import { Component, OnInit, Inject } from '@angular/core';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'kt-insert-notes',
  templateUrl: './insert-notes.component.html',
  styleUrls: ['./insert-notes.component.scss']
})
export class InsertNotesComponent implements OnInit {

  Note!: string;
  title!: string;
  subtitle!: string;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log('this.data', this.data);
    this.Note = this.data && this.data.note ? this.data.note : '';
    this.title = this.data && this.data.title ? this.data.title : '';
    this.subtitle = this.data && this.data.subtitle ? this.data.subtitle : '';
  }

  onSubmit() {
    this.dialogRef.close({
      success: true,
      Note: this.Note
    });
  }

}
