import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

@Directive({
    selector: '[OnlyNumber]'
})
export class OnlyNumberDirective {

    constructor(private el: ElementRef, private translationService: TranslationService) { }

    @Input() OnlyNumber: boolean = false;

    @HostListener('keydown', ['$event']) onKeyDown(event: any) {
        let e = <KeyboardEvent>event;
        if (this.OnlyNumber) {
            //188 => virgola
            //110 => decimale da tastierino
            //190 => punto
            //109 => - da tastierino numerico
            const ds = this.getDecimalSeparator(this.translationService.getSelectedLanguage());

            let acceptedChars = [46, 8, 9, 27, 13, 189, 109];

            if (ds === ',') {
                acceptedChars.push(188); // virgola
            }
            else {
                acceptedChars.push(190); // punto
            }

            // Accetto sempre e cmq il decimale del tastierino
            // poi lo modifico in base alla lingua
            acceptedChars.push(110); // NumpadDecimal

            // Se ho premuto il decimale del tastierino
            // Forzo il punto o la virgola a seconda della lingua selezionata
            // e poi blocco con preventDefault()
            if (e.code == 'NumpadDecimal') {
                event.target.value = event.target.value + ds;
                console.log('FORZO => ' + ds);
                e.preventDefault();
            }

            if (acceptedChars.indexOf(e.keyCode) !== -1 ||
                // Allow: Ctrl+A
                (e.keyCode == 65 && e.ctrlKey === true) ||
                // Allow: Ctrl+C
                (e.keyCode == 67 && e.ctrlKey === true) ||
                // Allow: Ctrl+X
                (e.keyCode == 88 && e.ctrlKey === true) ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)) {
                // let it happen, don't do anything
                return;
            }
            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        }
    }

    // @HostListener('keypress', ['$event']) onKeyPress(e: any) {
    //   const ds = this.getDecimalSeparator(this.translationService.getSelectedLanguage());
    //   var start = e.target.selectionStart;
    //   var end = e.target.selectionEnd;
    //   // get old value
    //   var oldValue = e.target.value;

    //   //Count separator occurencies
    //   var re = new RegExp(ds, 'g');
    //   var count = (oldValue.match(re) || []).length;
    //   if (count > 0 && (e.key === ds || e.keyCode === 46)) {
    //     e.target.value = oldValue;
    //     e.preventDefault();

    //   }
    //   else {
    //     if (e.keyCode === 110 || e.keyCode === 46) {
    //       // replace point and change input value
    //       var newValue = oldValue.slice(0, start) + ds + oldValue.slice(end);
    //       e.target.value = newValue;

    //       // replace cursor
    //       e.target.selectionStart = e.target.selectionEnd = start + 1;

    //       e.preventDefault();
    //     }
    //   }
    // }

    getDecimalSeparator(locale) {
        const numberWithDecimalSeparator = 1.1;
        return Intl.NumberFormat(locale)?.
            formatToParts(numberWithDecimalSeparator)?.
            find(part => part.type === 'decimal')?.
            value;
    }

    getThousandSeparator(locale) {
        const numberWithThousandSeparator = 1000;
        let x = Intl.NumberFormat(locale)
            .formatToParts(numberWithThousandSeparator);

        return Intl.NumberFormat(locale)?.
            formatToParts(numberWithThousandSeparator)?.
            find(part => part.type === 'group')?.
            value;
    }

}
