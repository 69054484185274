import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, ViewRef } from '@angular/core';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { Observable, of, BehaviorSubject, combineLatest, Subscription } from 'rxjs';

@Component({
	selector: 'kt-insert-summary',
	templateUrl: './insert-summary.component.html',
	styleUrls: ['./insert-summary.component.scss'],
	//changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsertSummaryComponent implements OnInit, OnDestroy {
	company: string = '';
	companyOrigin: string = '';
	companyDest: string = '';
	costCenter: string = '';
	costCenterOrigin: string = '';
	costCenterDest: string = '';
	department: string = '';
	order: string = '';
	supplier: string = '';
	date: string = '';
	docNumber: string = '';
	note: string = '';
	orderNumber: string = '';

	summaryUpdateSubscription!: Subscription;

	constructor(
		private magazzinoService: MagazzinoService,
		private staticCollectionsService: StaticCollectionsService,
		private router: Router,
		private translate: TranslateService,
		private ref: ChangeDetectorRef
	) { }

	ngOnDestroy() {
		this.summaryUpdateSubscription.unsubscribe();
	}

	ngOnInit() {
		this.summaryUpdateSubscription = this.magazzinoService.summaryUpdated$.subscribe(() => {
			this.company = '';
			this.companyDest = '';
			this.companyOrigin = '';
			this.costCenter = '';
			this.costCenterOrigin = '';
			this.costCenterDest = '';
			this.department = '';
			this.order = '';
			this.supplier = '';
			this.date = '';
			this.docNumber = '';
			this.note = '';
			this.orderNumber = '';
			setTimeout(() => {
				this.updateSummary();
				if (this.ref && !(this.ref as ViewRef).destroyed) {
					this.ref.detectChanges();
				}
			}, 1000);
		});

		this.updateSummary();
	}

	updateSummary() {
		const currentConfig = this.magazzinoService.getCurrentConfig();

		if (!currentConfig) {
			return;
		}

		//console.log('currentConfig', currentConfig);

		this.company = currentConfig.body.Company;
		this.companyDest = currentConfig.body.CompanyDest;
		this.companyOrigin = currentConfig.body.CompanyOrigin;
		if (currentConfig.body.CostCenterId) {
			const existsCostCenter = this.staticCollectionsService.costCenters$.find((costCenter: any) => costCenter.Id.toString() === currentConfig.body.CostCenterId.toString());
			if (existsCostCenter) {
				this.costCenter = existsCostCenter.Name;
			}
		}
		if (currentConfig.body.CostCenterIdOrigin) {
			const existsCostCenter = this.staticCollectionsService.costCenters$.find((costCenter: any) => costCenter.Id.toString() === currentConfig.body.CostCenterIdOrigin.toString());
			if (existsCostCenter) {
				this.costCenterOrigin = existsCostCenter.Name;
			}
		}
		if (currentConfig.body.CostCenterIdDest) {
			const existsCostCenter = this.staticCollectionsService.costCenters$.find((costCenter: any) => costCenter.Id.toString() === currentConfig.body.CostCenterIdDest.toString());
			if (existsCostCenter) {
				this.costCenterDest = existsCostCenter.Name;
			}
		}
		if (currentConfig.body.DepartmentId) {
			const existsDepartment = this.staticCollectionsService.repartiMagazzino$.find((department: any) => department.Id.toString() === currentConfig.body.DepartmentId.toString());
			if (existsDepartment) {
				this.department = existsDepartment.Name;
			}
		}
		if (currentConfig.body.SupplierId && this.staticCollectionsService.fornitori$) {
			const existsSupplier = this.staticCollectionsService.fornitori$.find((supplier: any) => supplier.Id.toString() === currentConfig.body.SupplierId.toString());
			if (existsSupplier) {
				this.supplier = existsSupplier.Name;
			}
		}
		this.date = currentConfig.body.Date;
		this.docNumber = currentConfig.body.DocNumber;
		this.note = currentConfig.body.Description;
		this.orderNumber = currentConfig.body.OrderNumber;
	}

	getTitle() {
		if (this.router.url.includes('Purchase')) {
			if (this.router.url.includes('itemId')) {
				return this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.TITLE_UPDATE');
			} else {
				return this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI.TITLE');
			}

		} else if (this.router.url.includes('Stock')) {
			if (this.router.url.includes('itemId')) {
				return this.translate.instant('MAGAZZINO.GIACENZE.INSERIMENTO_GIACENZE.TITLE_UPDATE');
			} else {
				return this.translate.instant('MAGAZZINO.GIACENZE.INSERIMENTO_GIACENZE.TITLE');
			}

		} else if (this.router.url.includes('Inventory')) {
			if (this.router.url.includes('itemId')) {
				return this.translate.instant('MAGAZZINO.INVENTARI.INSERIMENTO_INVENTARI.TITLE_UPDATE');
			} else {
				return this.translate.instant('MAGAZZINO.INVENTARI.INSERIMENTO_INVENTARI.TITLE');
			}

		} else if (this.router.url.includes('Adjustment')) {
			if (this.router.url.includes('itemId')) {
				return this.translate.instant('MAGAZZINO.SPRECHI.INSERIMENTO_SPRECHI.TITLE_UPDATE');
			} else {
				return this.translate.instant('MAGAZZINO.SPRECHI.INSERIMENTO_SPRECHI.TITLE');
			}

		} else if (this.router.url.includes('Transfert')) {
			if (this.router.url.includes('itemId')) {
				return this.translate.instant('MAGAZZINO.TRASFERIMENTI.INSERIMENTO_TRASFERIMENTI.TITLE_UPDATE');
			} else {
				return this.translate.instant('MAGAZZINO.TRASFERIMENTI.INSERIMENTO_TRASFERIMENTI.TITLE');
			}

		} else if (this.router.url.includes('ordini')) {
			if (this.router.url.includes('itemId')) {
				return this.translate.instant('MAGAZZINO.ORDINI.INSERIMENTO_ORDINI.TITLE_UPDATE');
			} else {
				return this.translate.instant('MAGAZZINO.ORDINI.INSERIMENTO_ORDINI.TITLE');
			}
		}
	}

	getNote(note: string) {
		if( note && note.length > 30 ) {
			return note.slice(0, 30) + '...';
		}
		return note;
	}

}
