// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';

import { AnalisiVenditeComponent } from '@app/views/pages/analisi/analisi-vendite/analisi-vendite.component';
import { AnalisiFoodcostComponent } from '@app/views/pages/analisi/analisi-foodcost/analisi-foodcost.component';
import { GestioneFattureComponent } from './views/pages/gestione-dati/gestione-fatture/gestione-fatture.component';
import { EndpointsComponent } from '@app/views/pages/endpoints/endpoints.component';
import { GestioneMerciComponent } from '@app/views/pages/gestione-dati/gestione-merci/gestione-merci.component';
// Anagrafiche
import { FornitoriComponent } from '@app/views/pages/anagrafiche/fornitori/fornitori.component';
import { UnitComponent } from '@app/views/pages/anagrafiche/unit/unit.component';
import { OrderUnitComponent } from '@app/views/pages/anagrafiche/order-unit/order-unit.component';
import { RepartiComponent } from '@app/views/pages/anagrafiche/reparti/reparti.component';
import { RazioniComponent } from '@app/views/pages/anagrafiche/razioni/razioni.component';
import { BrandsComponent } from '@app/views/pages/anagrafiche/brands/brands.component';
import { CostCentersComponent } from '@app/views/pages/anagrafiche/cost-centers/costcenters.component';
import { WastetypesComponent } from './views/pages/anagrafiche/wastetypes/wastetypes.component';
import { GruppiComponent } from '@app/views/pages/anagrafiche/gruppi/gruppi.component';
import { CategorieMerciComponent } from '@app/views/pages/anagrafiche/categorie-merci/categorie-merci.component';
import { CategorieProdottiComponent } from '@app/views/pages/anagrafiche/categorie-prodotti/categorie-prodotti.component';
import { GruppiMerciComponent } from './views/pages/anagrafiche/gruppi-merci/gruppi-merci.component';

// Magazzino
import { AcquistiComponent } from '@app/views/pages/magazzino/acquisti/acquisti.component';
import { TrasferimentiComponent } from '@app/views/pages/magazzino/trasferimenti/trasferimenti.component';
import { SprechiComponent } from '@app/views/pages/magazzino/sprechi/sprechi.component';
import { InventariComponent } from '@app/views/pages/magazzino/inventari/inventari.component';
import { GiacenzeComponent } from '@app/views/pages/magazzino/giacenze/giacenze.component';
import { OrdiniComponent } from '@app/views/pages/magazzino/ordini/ordini.component';
import { StatisticheComponent } from '@app/views/pages/magazzino/statistiche/statistiche.component';
import { InserimentoComuneComponent } from '@app/views/pages/magazzino/inserimento-comune/inserimento-comune.component';
import { InserimentoOrdiniComponent } from '@app/views/pages/magazzino/ordini/inserimento-ordini/inserimento-ordini.component';

import { OrdiniInterniComponent } from '@app/views/pages/magazzino/ordini-interni/ordini-interni.component';
import { InserimentoOrdiniInterniComponent } from '@app/views/pages/magazzino/ordini-interni/inserimento-ordini/inserimento-ordini.component';

import { OrdiniSemplificatiComponent } from '@app/views/pages/magazzino/ordini-semplificati/ordini-semplificati.component';
import { InserimentoOrdiniSemplificatiComponent } from '@app/views/pages/magazzino/ordini-semplificati/inserimento-ordini/inserimento-ordini.component';

import { AnalisiConsumiComponent } from '@app/views/pages/analisi/analisi-consumi/analisi-consumi.component';
import { StoricoVenditeComponent } from '@app/views/pages/analisi/storico-vendite/storico-vendite.component';

import { CompanyUsersComponent } from '@app/views/pages/configurazione/company-users/company-users.component';
import { StoresComponent } from '@app/views/pages/configurazione/stores-component/stores-component';
import { EndpointSettingsComponent } from '@app/views/pages/configurazione/endpoint-settings-component/endpoint-settings-component';

import { ImportexportComponent } from '@app/views/pages/manutenzione/importexport/importexport.component';
import { TasksComponent } from './views/pages/manutenzione/tasks/tasks.component';

import { VenditeManualiComponent } from '@app/views/pages/gestione-dati/vendite-manuali/vendite-manuali.component';

// Support
import { SupportComponent } from '@app/views/pages/support/support.component';
import { EditIssueComponent } from '@app/views/pages/support/edit-issue/edit-issue.component';


// Ricette
import { GestioneRicetteComponent } from '@app/views/pages/gestione-dati/gestione-ricette/gestione-ricette.component';
import { EditRicettaComponent } from '@app/views/pages/gestione-dati/gestione-ricette/edit-ricetta/edit-ricetta.component';

// Conto Economico
import { ContoEconomicoComponent } from './views/pages/amministrazione/conto-economico/conto-economico/conto-economico.component';
import { DashboardComponent } from './views/pages/amministrazione/conto-economico/dashboard/dashboard.component';
import { DdtComponent } from './views/pages/magazzino/ddt/ddt.component';
import { AnalisiPrezziFornitoriComponent } from './views/pages/analisi/analisi-prezzi-fornitori/analisi-prezzi-fornitori.component';
import { EstrazioneDatiComponent } from './views/pages/analisi/estrazione-dati/estrazione-dati.component';
import { AnalisiVariazionePrezziComponent } from './views/pages/analisi/analisi-variazione-prezzi/analisi-variazione-prezzi.component';
import { ScadenziarioComponent } from './views/pages/amministrazione/scadenziario/scadenziario.component';
import { LogsComponent } from './views/pages/manutenzione/logs/logs.component';

const routes: Routes = [
    { path: 'auth', loadChildren: () => import('@app/views/pages/auth/auth.module').then(m => m.AuthModule) },

    {
        path: '',
        component: BaseComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('@app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'analisi/analisi-vendite',
                component: AnalisiVenditeComponent
            },
            {
                path: 'analisi/analisi-consumi',
                component: AnalisiConsumiComponent
            },
            {
                path: 'analisi/analisi-foodcost',
                component: AnalisiFoodcostComponent
            },
            {
                path: 'storico-vendite',
                component: StoricoVenditeComponent
            },
            {
                path: 'analisi/analisi-prezzi-fornitori',
                component: AnalisiPrezziFornitoriComponent
            },
            {
                path: 'analisi/estrazione-dati',
                component: EstrazioneDatiComponent
            },
            {
                path: 'analisi/variazione-prezzi',
                component: AnalisiVariazionePrezziComponent
            },
            {
                path: 'endpoints',
                component: EndpointsComponent
            },
            {
                path: 'magazzino/gestione-merci',
                component: GestioneMerciComponent
            },
            {
                path: 'gestione-ricette',
                component: GestioneRicetteComponent
            },
            {
                path: 'gestione-ricette/edit',
                component: EditRicettaComponent
            },
            {
                path: 'vendite-manuali',
                component: VenditeManualiComponent
            },
            {
                path: 'rettifiche-vendita',
                component: VenditeManualiComponent
            },
            {
                path: 'prodotti-finiti',
                component: VenditeManualiComponent
            },
            {
                path: 'inventario',
                component: VenditeManualiComponent
            },
            {
                path: 'gestione-dati/gestione-fatture',
                component: GestioneFattureComponent
            },
            {
                path: 'magazzino/acquisti',
                component: AcquistiComponent
            },
            {
                path: 'magazzino/inserimento-comune',
                component: InserimentoComuneComponent
            },
            {
                path: 'magazzino/inserimento-ordini',
                component: InserimentoOrdiniComponent
            },
            {
                path: 'magazzino/inserimento-ordini-interni',
                component: InserimentoOrdiniInterniComponent
            },
            {
                path: 'magazzino/inserimento-ordini-semplificati',
                component: InserimentoOrdiniSemplificatiComponent
            },
            {
                path: 'magazzino/ddt',
                component: DdtComponent
            },
            {
                path: 'magazzino/ordini',
                component: OrdiniComponent
            },
            {
                path: 'magazzino/ordini-interni',
                component: OrdiniInterniComponent
            },
            {
                path: 'magazzino/ordini-semplificati',
                component: OrdiniSemplificatiComponent
            },
            {
                path: 'magazzino/trasferimenti',
                component: TrasferimentiComponent
            },
            {
                path: 'magazzino/sprechi',
                component: SprechiComponent
            },
            {
                path: 'magazzino/inventari',
                component: InventariComponent
            },
            {
                path: 'magazzino/statistiche',
                component: StatisticheComponent
            },
            {
                path: 'magazzino/giacenze',
                component: GiacenzeComponent
            },
            {
                path: 'fornitori',
                component: FornitoriComponent
            },
            {
                path: 'unita-misura',
                component: UnitComponent
            },
            {
                path: 'unita-secondarie',
                component: OrderUnitComponent
            },
            {
                path: 'reparti',
                component: RepartiComponent
            },
            {
                path: 'razioni',
                component: RazioniComponent
            },
            {
                path: 'categorie-merci',
                component: CategorieMerciComponent
            },
            {
                path: 'categorie-prodotti',
                component: CategorieProdottiComponent
            },
            {
                path: 'centri-costo',
                component: CostCentersComponent
            },
            {
                path: 'wastetypes',
                component: WastetypesComponent
            },
            {
                path: 'brands',
                component: BrandsComponent
            },
            {
                path: 'gruppi',
                component: GruppiComponent
            },
            {
                path: 'gruppi-merci',
                component: GruppiMerciComponent
            },
            {
                path: 'companyUsers',
                component: CompanyUsersComponent
            },
            {
                path: 'stores',
                component: StoresComponent
            },
            {
                path: 'endpointSettings',
                component: EndpointSettingsComponent
            },
            {
                path: 'manutenzione/importexport',
                component: ImportexportComponent
            },
            {
                path: 'manutenzione/tasks',
                component: TasksComponent
            },
            {
                path: 'manutenzione/logs',
                component: LogsComponent
            },
            {
                path: 'support',
                component: SupportComponent
            },
            {
                path: 'support/edit-issue',
                component: EditIssueComponent
            },
            {
                path: 'amministrazione/conto-economico',
                component: ContoEconomicoComponent
            },
            {
                path: 'amministrazione/dashboard',
                component: DashboardComponent
            },
            {
                path: 'amministrazione/scadenziario',
                component: ScadenziarioComponent
            },
            {
                path: 'error/403',
                component: ErrorPageComponent,
                data: {
                    'type': 'error-v6',
                    'code': 403,
                    'title': '403... Access forbidden',
                    'desc': 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
                }
            },
            { path: 'error/:type', component: ErrorPageComponent },
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
        ]
    },

    { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'corrected' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
