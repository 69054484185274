import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { UsersService } from '@app/core/services/users..service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
declare var require: any;
var languageCodesJson = require('./languageCodes.json');

import { MbSelect2Component } from '@app/mb-components/mb-select2/mb-select2.component';
import { RoleService } from '@app/core/_base/layout';

@Component({
    selector: 'kt-company-users',
    templateUrl: './company-users.component.html',
    styleUrls: ['./company-users.component.scss']
})
export class CompanyUsersComponent implements OnInit {

    users: any[] = [];
    roles: any[] = [];
    stores: any[] = [];
    permissions: any[] = [];
    selectedUser: any;
    selectedRole: any;
    selectedStores: any[] = [];
    TicketUserName: string = '';
    OrderSenderMail: string = '';
    OrderSenderName: string = '';
    isTraining: boolean = false;
    CreateGoods: boolean = false;
    CheckInventory: boolean = false;
    ClosingInventory: boolean = false;
    ViewStock: boolean = false;
    SendOrderMail: boolean = false;

    Dashboard: boolean = false;

    ProductMix: boolean = false;
    FoodCostAnalisys: boolean = false;
    ConsumptionAnalysis: boolean = false;
    Forecasting: boolean = false;
    SellingHistory: boolean = false;


    Goods: boolean = false;
    Recipe: boolean = false;
    HandSellings: boolean = false;
    SellingAdjustments: boolean = false;
    FinishedProducts: boolean = false;
    InvoicesManagement: boolean = false;

    Order: boolean = false;
    InnerOrder: boolean = false;
    SimplifiedOrder: boolean = false;
    Ddt: boolean = false;
    Purchase: boolean = false;
    Inventory: boolean = false;
    Transfert: boolean = false;
    Adjustment: boolean = false;
    Stock: boolean = false;
    DateStock: boolean = false;
    Statistics: boolean = false;
    LocalExportInventory: boolean = false;
    EditCE: boolean = false;
    DeleteCE: boolean = false;
    Schedule: boolean = false;

    userSettings: any;

    private rolesObserv$!: Observable<any[]>;
    private storesObserv$!: Observable<any[]>;

    languageCodesList: any;

    private select2Language!: MbSelect2Component;
    @ViewChild('select2Language', { static: false }) set _setLanguage(content: MbSelect2Component) {
        if (content) { // initially setter gets called with undefined
            this.select2Language = content;
            this.select2Language.setValue('it');
        }
    }

    constructor(private srv: UsersService,
        private translate: TranslateService,
        private translateSrv: TranslationService,
        private layoutUtilsService: LayoutUtilsService,
        public roleService: RoleService
    ) {

        this.languageCodesList = [];
        Object.keys(languageCodesJson).forEach(key => {
            this.languageCodesList.push({
                code: key,
                description: languageCodesJson[key]
            });
        });

    }

    ngOnInit() {
        this.rolesObserv$ = this.srv.getRoles();
        this.storesObserv$ = this.srv.getStores();


        combineLatest(this.rolesObserv$, this.storesObserv$,
            (
                rolesObserv, storesObserv
            ) => ({
                rolesObserv, storesObserv
            }))
            .subscribe((pair: any) => {
                this.roles = pair.rolesObserv;
                this.stores = pair.storesObserv;

                this.loadUsers(undefined);

            });


    }

    onSelect(item) {
        if (!item) return;
        this.selectedUser = item;
        this.selectedRole = this.roles.find((x: any) => x.Name === item.Role);
        this.selectedStores = [];
        item.Stores.forEach((element: any) => {
            this.selectedStores.push(element);
        });

        this.isTraining = this.GetPermission('IsTraining', item.Permissions);

        this.CreateGoods = this.GetPermission('CreateGoods', item.Permissions);

        this.CheckInventory = this.GetPermission('CheckInventory', item.Permissions);

        this.ClosingInventory = this.GetPermission('ClosingInventory', item.Permissions);

        this.ViewStock = this.GetPermission('ViewStock', item.Permissions);

        this.SendOrderMail = this.GetPermission('SendOrderMail', item.Permissions);

        this.Dashboard = this.GetPermission('Dashboard', item.Permissions);


        //--------------  MENU ANALISI ------------------------------------------
        this.ProductMix = this.GetPermission('ProductMix', item.Permissions);

        this.FoodCostAnalisys = this.GetPermission('FoodCostAnalisys', item.Permissions);

        this.ConsumptionAnalysis = this.GetPermission('ConsumptionAnalysis', item.Permissions);

        this.Forecasting = this.GetPermission('Forecasting', item.Permissions);

        this.SellingHistory = this.GetPermission('SellingHistory', item.Permissions);


        //--------------  MENU DATI ------------------------------------------

        this.Goods = this.GetPermission('Goods', item.Permissions);

        this.Recipe = this.GetPermission('Recipe', item.Permissions);

        this.HandSellings = this.GetPermission('HandSellings', item.Permissions);

        this.SellingAdjustments = this.GetPermission('SellingAdjustments', item.Permissions);

        this.InvoicesManagement = this.GetPermission('InvoicesManagement', item.Permissions);

        this.FinishedProducts = this.GetPermission('FinishedProducts', item.Permissions);


        //--------------  MENU MAGAZZINO ------------------------------------------

        this.Order = this.GetPermission('Order', item.Permissions);

        this.InnerOrder = this.GetPermission('InnerOrder', item.Permissions);

        this.SimplifiedOrder = this.GetPermission('SimplifiedOrder', item.Permissions);

        this.Ddt = this.GetPermission('Ddt', item.Permissions);

        this.Purchase = this.GetPermission('Purchase', item.Permissions);

        this.Inventory = this.GetPermission('Inventory', item.Permissions);

        this.Transfert = this.GetPermission('Transfert', item.Permissions);

        this.Adjustment = this.GetPermission('Adjustment', item.Permissions);

        this.Stock = this.GetPermission('Stock', item.Permissions);

        this.DateStock = this.GetPermission('DateStock', item.Permissions);

        this.Statistics = this.GetPermission('Statistics', item.Permissions);

        this.LocalExportInventory = this.GetPermission('LocalExportInventory', item.Permissions);

        this.EditCE = this.GetPermission('EditCE', item.Permissions);

        this.DeleteCE = this.GetPermission('DeleteCE', item.Permissions);

        this.Schedule = this.GetPermission('Schedule', item.Permissions);

        //--------------  INFORMAZIONI GENERALI ------------------------------------------
        this.TicketUserName = item.TicketUserName;
        this.OrderSenderMail = item.OrderSenderMail;
        this.OrderSenderName = item.OrderSenderName;

        //--------------  IMPOSTAZIONI UTENTE ------------------------------------------


        this.srv.getUserSettings().toPromise()
            .then((settings: any) => {
                console.log(settings);
                this.userSettings = settings;
            });
    }

    GetPermission(key: string, permissions: any) {
        let permission = false;
        const found = permissions.find((x: any) => x.Id === key);
        if (found) {
            permission = found.Value > 0;
        }

        return permission;
    }


    loadUsers(selectedUserMail) {
        this.srv.getUsers().subscribe(
            r => {
                this.users = r;
                if (selectedUserMail) {
                    this.onSelect(this.users.find((x: any) => x.Email === selectedUserMail));

                } else {
                    this.onSelect(this.users[0]);
                }

            }
        );
    }

    getTranslation(type, key) {
        const lanKey = type + '.' + key;
        return this.translate.instant(lanKey);
    }

    saveUser() {
        let usr: any = {
            Id: this.selectedUser.Id,
            Email: this.selectedUser.Email,
            Role: this.selectedRole.Name,
            Stores: [],
            Permissions: []
        };

        if (this.isTraining) {
            usr.Permissions.push({ Id: 'IsTraining', Value: 1 });
        }

        if (this.CreateGoods) {
            usr.Permissions.push({ Id: 'CreateGoods', Value: 1 });
        }

        if (this.CheckInventory) {
            usr.Permissions.push({ Id: 'CheckInventory', Value: 1 });
        }

        if (this.ClosingInventory) {
            usr.Permissions.push({ Id: 'ClosingInventory', Value: 1 });
        }

        if (this.ViewStock) {
            usr.Permissions.push({ Id: 'ViewStock', Value: 1 });
        }

        if (this.SendOrderMail) {
            usr.Permissions.push({ Id: 'SendOrderMail', Value: 1 });
        }


        if (this.Dashboard) {
            usr.Permissions.push({ Id: 'Dashboard', Value: 1 });
        }


        //--------------  MENU ANALISI ------------------------------------------
        if (this.ProductMix) {
            usr.Permissions.push({ Id: 'ProductMix', Value: 1 });
        }

        if (this.FoodCostAnalisys) {
            usr.Permissions.push({ Id: 'FoodCostAnalisys', Value: 1 });
        }

        if (this.ConsumptionAnalysis) {
            usr.Permissions.push({ Id: 'ConsumptionAnalysis', Value: 1 });
        }

        if (this.Forecasting) {
            usr.Permissions.push({ Id: 'Forecasting', Value: 1 });
        }

        if (this.SellingHistory) {
            usr.Permissions.push({ Id: 'SellingHistory', Value: 1 });
        }



        //--------------  MENU DATI ------------------------------------------

        if (this.Goods) {
            usr.Permissions.push({ Id: 'Goods', Value: 1 });
        }

        if (this.Recipe) {
            usr.Permissions.push({ Id: 'Recipe', Value: 1 });
        }

        if (this.HandSellings) {
            usr.Permissions.push({ Id: 'HandSellings', Value: 1 });
        }

        if (this.SellingAdjustments) {
            usr.Permissions.push({ Id: 'SellingAdjustments', Value: 1 });
        }

        if (this.InvoicesManagement) {
            usr.Permissions.push({ Id: 'InvoicesManagement', Value: 1 })
        }

        if (this.FinishedProducts) {
            usr.Permissions.push({ Id: 'FinishedProducts', Value: 1 });
        }


        //--------------  MENU MAGAZZINO ------------------------------------------

        if (this.Order) {
            usr.Permissions.push({ Id: 'Order', Value: 1 });
        }

        if (this.InnerOrder) {
            usr.Permissions.push({ Id: 'InnerOrder', Value: 1 });
        }

        if (this.SimplifiedOrder) {
            usr.Permissions.push({ Id: 'SimplifiedOrder', Value: 1 });
        }

        if (this.Ddt) {
            usr.Permissions.push({ Id: 'Ddt', Value: 1 });
        }

        if (this.Purchase) {
            usr.Permissions.push({ Id: 'Purchase', Value: 1 });
        }

        if (this.Inventory) {
            usr.Permissions.push({ Id: 'Inventory', Value: 1 });
        }

        if (this.Transfert) {
            usr.Permissions.push({ Id: 'Transfert', Value: 1 });
        }

        if (this.Adjustment) {
            usr.Permissions.push({ Id: 'Adjustment', Value: 1 });
        }

        if (this.Stock) {
            usr.Permissions.push({ Id: 'Stock', Value: 1 });
        }

        if (this.DateStock) {
            usr.Permissions.push({ Id: 'DateStock', Value: 1 });
        }


        if (this.Statistics) {
            usr.Permissions.push({ Id: 'Statistics', Value: 1 });
        }

        if (this.LocalExportInventory) {
            usr.Permissions.push({ Id: 'LocalExportInventory', Value: 1 });
        }

        if (this.EditCE) {
            usr.Permissions.push({ Id: 'EditCE', Value: 1 });
        }

        if (this.DeleteCE) {
            usr.Permissions.push({ Id: 'DeleteCE', Value: 1 });
        }

        if (this.Schedule) {
            usr.Permissions.push({ Id: 'Schedule', Value: 1 });
        }

        this.selectedStores.forEach((element: any) => {
            usr.Stores.push(element);
        });

        usr.TicketUserName = this.TicketUserName;
        usr.OrderSenderMail = this.OrderSenderMail;
        usr.OrderSenderName = this.OrderSenderName;

        this.srv.postUser(usr).subscribe(
            r => {
                let message = this.getTranslation('USERS', 'UtenteSalvato');
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                this.loadUsers(this.selectedUser.Email);

            }
        );
    }


}
