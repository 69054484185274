import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription, from } from 'rxjs';
import { map, debounceTime, tap, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

// Services
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { LayoutConfigService } from '@app/core/_base/layout';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

// Material
import { MatDialog } from '@angular/material/dialog';

// Components
import { InserimentoOrdiniPopupComponent } from '@app/views/pages/magazzino/ordini/inserimento-ordini-popup/inserimento-ordini-popup.component';
import { EditNotesComponent } from '@app/views/pages/magazzino/components/edit-notes/edit-notes.component';
import { EditMerciComponent } from '@app/views/pages/gestione-dati/gestione-merci/edit-merci/edit-merci.component';
import { DiscountsEditDialogComponent } from '@app/views/partials/content/crud/discounts-edit-dialog/discounts-edit-dialog.component';

// Utilities
import * as _ from 'lodash';
import * as util from '@app/core/services/utilityfunctions';

enum COLUMNS_INDEX {
    POSITION = 0,
    CATEGORIA = 1,
    CODICE = 2,
    PRODOTTO = 3,
    QUANTITA_UNITA_ORDINE = 4,
    UNITA_ORDINE = 5,
    QUANTITA_UNITA_MAGAZZINO = 6,
    GIACENZE = 7,
    UNITA_MAGAZZINO = 8,
    QUANTITA_UNITA_BASE = 9,
    UNITA_BASE = 10,
    PREZZO_UNITA_ORDINE = 11,
    SCONTI = 12,
    TOTALE = 13,
    POSIZIONE = 14,
    ACTIONS = 15,
    ID = 16
}

declare var $: any;

@Component({
    selector: 'kt-inserimento-ordini',
    templateUrl: './inserimento-ordini.component.html',
    styleUrls: ['./inserimento-ordini.component.scss', '../../common-styles.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InserimentoOrdiniComponent implements OnInit {

    @ViewChild('ProductOrCode', { static: true }) ProductOrCode!: ElementRef;
    @ViewChild('SogliaVariazionePrezzoPerc', { static: true }) SogliaVariazionePrezzoPerc!: ElementRef;
    currentConfig: any;
    referral!: string;
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    dataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    // Filtri
    listaCategorie: any;
    listaPosizioni: any;
    categoriaFiltro: any = '';
    posizioneFiltro: string = '';
    productOrCode!: string;
    hidePricesZero: boolean = false;
    qtyHigherZero: boolean = false;
    groupByQty: boolean = true;
    hideBase: boolean = true;
    smallScreen: boolean = false;
    // Tablella
    lista: any;
    listaFiltered: any;
    table: any;
    locale!: string;
    // Input
    inputChanged: Subject<any> = new Subject<any>();
    filterChanged: Subject<any> = new Subject<any>();

    isAcquisto: boolean = false;
    itemId: any; // Modalità edit

    restoreMap = {};
    utility: any;

    hoverglassActive: boolean = false;
    enablePopup: boolean = false;

    mappaSelect: any = {};

    warehouseWorker!: Worker;

    switchGroupSubcription!: Subscription;

    needFilter: boolean = false;

    constructor(
        private magazzinoService: MagazzinoService,
        private translate: TranslateService,
        private layoutConfigService: LayoutConfigService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public staticCollectionsService: StaticCollectionsService,
        private ref: ChangeDetectorRef,
        public dialog: MatDialog,
        public roleService: RoleService,
        private gestioneMerciService: GestioneMerciService,
        private switchGroupService: SwitchGroupService
    ) {
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
        this.utility = util;

        this.filterChanged
            .pipe(
                debounceTime(300),
                tap(() => {
                    this.needFilter = true;
                    this.table.draw();
                })
            )
            .subscribe();

        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.goBack();
                }
            }
        );

        $.fn.dataTable.ext.search.push(
            (settings, data, dataIndex) => {
                let acceptRow: boolean = true;

                // Se il campo codice è vuoto vuol dire che è l'ultima riga
                //if (data[1].length == 0) return true;

                if (!this.lista || this.lista.length == 0) return true;

                // Se non c' bisogno di filtrare ritorno tutto
                if (!this.needFilter) return true;

                const rowItem = this.lista.find((f: any) => f.Id == data[16]);

                if (this.hidePricesZero) {
                    //const val = this.utility.parseNumber($(`#table tbody>tr:nth-child(${dataIndex})`).attr('data-price'), this.locale);
                    const val = rowItem && rowItem.Price ? this.utility.parseNumber(rowItem.Price) : 0;
                    if (!(val > 0)) {
                        acceptRow = false;
                    }
                }

                if (this.qtyHigherZero) {
                    //const val = this.utility.parseNumber($(`#table tbody>tr:nth-child(${dataIndex})`).attr('data-quantity'), this.locale);
                    const val = rowItem && rowItem.Quantity ? this.utility.parseNumber(rowItem.Quantity) : 0;
                    if (!(val > 0)) {
                        acceptRow = false;
                    }
                }

                if (this.posizioneFiltro) {
                    if (data[0].toLowerCase() !== this.posizioneFiltro.toLowerCase()) {
                        acceptRow = false;
                    }
                }

                if (this.categoriaFiltro) {
                    if (data[1].toLowerCase() !== this.categoriaFiltro.trim().toLowerCase()) {
                        acceptRow = false;
                    }
                }

                if (this.productOrCode) {
                    if (
                        data[2].toLowerCase().indexOf(this.productOrCode.toLowerCase()) < 0 &&
                        data[3].toLowerCase().indexOf(this.productOrCode.toLowerCase()) < 0
                    ) {
                        acceptRow = false;
                    }
                }
                return acceptRow;

            }
        );
    }

    goods: any;
    //onlyEnabledGoods: any;
    async ngOnInit() {

        if (typeof Worker !== 'undefined') {
            this.warehouseWorker = new Worker(new URL('../../../../../workers/warehouse.worker', import.meta.url));
            this.warehouseWorker.onmessage = (response: any) => {
                switch (response.data.operation) {
                    case 'updateRowCalculationOrders':

                        // Aggiorno l'attuale lista filtrata
                        if (response.data.indexLista < this.lista.length) {
                            this.lista[response.data.indexLista].QuantitaMagazzino = response.data.item.QuantitaMagazzino;
                            this.lista[response.data.indexLista].QuantitaUnitaBase = response.data.item.QuantitaUnitaBase;
                            this.lista[response.data.indexLista].Discount = response.data.item.Discount;
                            this.lista[response.data.indexLista].Totale = response.data.item.Totale;
                        }

                        this.ref.detectChanges();
                        break;
                }
            };
        }

        this.currentConfig = this.magazzinoService.getCurrentConfig();

        this.activatedRoute.queryParams.subscribe(async (params: any) => {
            this.referral = params.referral ? params.referral : '/';

            if (!this.currentConfig) {
                this.router.navigate([`${this.referral}`], { queryParams: {} });
                return;
            }

            this.itemId = params.itemId;

            this.loading$.next(true);
            let filter = {
                // 	FBType: 6, //NON CI VUOLE IL FILTRO SUL TIPO DI MERCE NEGLI ORDINI
            }
            if (this.currentConfig) {
                filter['SupplierIds'] = [this.currentConfig.body.SupplierId];
                filter['OnlyVisibleForCompany'] = true;
                filter['Companies'] = [];
                filter['Companies'].push(this.currentConfig.body.Company);
            }
            filter['EnableState'] = 2;
            //filter['VisibleState'] = 'yes';
            filter['HideExcluded'] = true;
            filter['HideInvisible'] = true;
            this.goods = await this.gestioneMerciService.getGoods(filter, false).toPromise();

            if (this.itemId) {
                if(this.getRows(this.currentConfig.items)) {
                    this.initDataTable();
                    this.loading$.next(false);
                    this.dataReady$.next(true);
                    this.editPopup(this.currentConfig.body);
                }
            } else {
                this.initInserimento();
            }
        });

        $.fn.dataTable.ext.order['dom-text-numeric'] = (settings, col) => {
            return this.table.column(col, { order: 'index' }).nodes().map(function (td, i) {
                var content: any = $(td).html();
                if (content.indexOf('<input') >= 0) {
                    content = $('input', td).val();
                } else if (content.indexOf('<!--') >= 0 && content.indexOf('-->') > 0) {
                    content = content.substring(content.indexOf('-->') + 3);
                    content = content.replace('.', '').replace(',', '.');
                }
                return Number(content);
            });
        }

        $.extend((<any>$.fn).dataTableExt.oSort, {
            "input-num-desc": (a, b) => {
                const elementA = this.createElementFromHTML(a);
                const valueA = $(elementA).attr('ng-reflect-model') ? Number($(elementA).attr('ng-reflect-model')) : 0;
                const elementB = this.createElementFromHTML(b);
                const valueB = $(elementB).attr('ng-reflect-model') ? Number($(elementB).attr('ng-reflect-model')) : 0;
                return ((valueA < valueB) ? 1 : ((valueA > valueB) ? -1 : 0));
            },
            "input-num-asc": (a, b) => {
                const elementA = this.createElementFromHTML(a);
                const valueA = $(elementA).attr('ng-reflect-model') ? Number($(elementA).attr('ng-reflect-model')) : 0;
                const elementB = this.createElementFromHTML(b);
                const valueB = $(elementB).attr('ng-reflect-model') ? Number($(elementB).attr('ng-reflect-model')) : 0;
                return ((valueA < valueB) ? -1 : ((valueA > valueB) ? 1 : 0));
            }
        });

    }

    performFilter() {
        this.ref.detectChanges();
        this.needFilter = true;
        this.table.draw();
    }

    clearFilters() {
        this.hidePricesZero = false;
        this.qtyHigherZero = false;
        this.productOrCode = '';
        this.categoriaFiltro = '';
        this.posizioneFiltro = '';
        this.groupByQty = true;
        this.hideBase = true;
        this.needFilter = true;
        this.table.draw();
    }

    calcDiscount(item) {

        // Se esiste già uno sconto impostato prendo quello sull'ordine e non sulla merce
        // MA se sono in modifica e non ci sono gli sconti impostati, torno zero
        let good = (item.S1 && item.S1 > 0) || (item.S2 && item.S2 > 0) || (item.S3 && item.S3 > 0) || (item.S4 && item.S4 > 0) ? item : (this.itemId ? undefined : this.goods.find((good: any) => good.Id == item.Id));

        let scontoParziale = 0;
        if (good && good.S1 > 0) {
            scontoParziale = 1 - good.S1 / 100;
            if (good.S2 > 0) {
                scontoParziale *= (1 - good.S2 / 100);
                if (good.S3 > 0) {
                    scontoParziale *= (1 - good.S3 / 100);
                    if (good.S4 > 0) {
                        scontoParziale *= (1 - good.S4 / 100);
                    }
                }
            }
        }

        return (scontoParziale > 0) ? 1 - scontoParziale : 0;
    }

    getRows(results: any) {
        if (results.length == 0) {
            //TODO --> INSERIRE POPUP CON MESSAGGIO "Nessuna merce disponibile" per il fornitore selezionato

            const _title: string = this.translate.instant('COMMONS.ATTENTION');
            const _description: string = this.translate.instant('COMMONS.NO_GOODS_FOR_SUPPLIER');
            const _yesButton = this.translate.instant('COMMONS.OK');
        
            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, '', _yesButton);
            dialogRef.afterClosed().subscribe(expand => {
                this.router.navigate([`${this.referral}`], { queryParams: {} });
            });
            return false;
        }
        this.listaCategorie = [];
        this.listaPosizioni = [];
        results.forEach((item: any) => {

            // Calcolo lo sconto
            item['Discount'] = this.calcDiscount(item);

            let exists = this.listaCategorie.find(category => category.Id === item.CategoryId);
            if (!exists) {
                this.listaCategorie.push({
                    Id: item.CategoryId,
                    Name: item.Category
                });
            }
            if (!item.Position) item.Position = "0";
            exists = this.listaPosizioni.findIndex(position => position === item.Position.trim());
            if (exists < 0) {
                this.listaPosizioni.push(item.Position.trim());
            }

            if (this.currentConfig.prePop) {
                const prepop = this.currentConfig.prePop.Items.find(p => p.Id.toString() === item.Id.toString() && p.Code.toLowerCase() === item.Code.toLowerCase());

                if (prepop) {

                    let qOrder = parseFloat(prepop.Quantity.toString()) ? parseFloat(prepop.Quantity.toString()) : 0;
                    item.QuantitaMagazzino = qOrder * parseFloat(item.OrderUnitRatio.toString())
                    item.QuantitaUnitaBase = parseFloat(item.QuantitaMagazzino.toString()) * parseFloat(item.SecondaryUnitRatio.toString());

                    item.Price = parseFloat(prepop.Price.toString()) ? parseFloat(prepop.Price.toString()) : parseFloat(item.Price.toString());
                    item.Totale = parseFloat(qOrder.toString()) * parseFloat(item.Price.toString()) * parseFloat(item.OrderUnitRatio.toString());

                    if (item['Discount'] > 0) {
                        item.Totale = item.Totale - (item.Totale * item.Discount);
                    }

                    //this.qtyHigherZero = true;
                    //this.groupByQty = true;
                    //this.hideBase = true;
                    //this.needFilter = true;

                }
            } else {
                item.QuantitaMagazzino = undefined;
                item.QuantitaUnitaBase = undefined;
                item.Quantity = undefined;
                if (item['Discount'] > 0) {
                    item.Totale = item.Totale - (item.Totale * item.Discount);
                }
            }

            //item.Price = util.formatNumber(item.Price, this.locale, 3);

            //item.InitialPrice = item.Price;
            item['CostCenter'] = this.currentConfig.body.CostCenterId ? this.currentConfig.body.CostCenterId : undefined;

            // Formattazione
            item.Price = util.formatNumber(item.Price, this.locale, 2);
        });
        this.lista = results;
        this.lista.forEach((element: any) => {
            element.UniqueId = element.Category + "|" + element.Id + "|" + element.Code;
        });

        if (this.itemId) this.addNewEmptyIngredient();

        return true;
    }

    initInserimento() {
        this.loading$.next(true);
        this.dataReady$.next(false);
        this.ref.detectChanges();
        this.magazzinoService.populate(this.currentConfig.body).subscribe(
            (results: any) => {
                const result = this.getRows(results);
                if(result) {
                    this.initDataTable();
                    this.loading$.next(false);
                    this.dataReady$.next(true);
                }

            }, (error: any) => {
                console.log(error);
                this.loading$.next(false);
                this.router.navigate([`${this.referral}`], { queryParams: {} });
            }
        )

    }

    createElementFromHTML(htmlString) {
        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();

        // Change this to div.childNodes to support multiple top-level nodes
        return div.firstChild;
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
    }

    ngOnDestroy() {
        this.switchGroupSubcription.unsubscribe();
        this.lista = undefined;
    }

    checkHideColumns(adjus: boolean = true) {
        let colStock = this.table.column(COLUMNS_INDEX.GIACENZE);

        if (this.groupByQty) {
            if (colStock) colStock.visible(false);
        } else {
            if (colStock) colStock.visible(true);
        }

        let colQtaUnitaBase = this.table.column(COLUMNS_INDEX.QUANTITA_UNITA_BASE);
        let colUnita = this.table.column(COLUMNS_INDEX.UNITA_BASE);

        if (this.hideBase) {
            if (colQtaUnitaBase) colQtaUnitaBase.visible(false);
            if (colUnita) colUnita.visible(false);
        } else {
            if (colQtaUnitaBase) colQtaUnitaBase.visible(true);
            if (colUnita) colUnita.visible(true);
        }
        if (adjus) this.table.columns.adjust().draw();
    }

    performSmallScreen() {
        this.table.columns([COLUMNS_INDEX.CATEGORIA, COLUMNS_INDEX.CODICE, COLUMNS_INDEX.TOTALE]).visible(!this.smallScreen);
        this.ref.detectChanges();
    }

    initDataTable(notClear: boolean = false) {
        this.hoverglassActive = true;
        if ($.fn.dataTable.isDataTable('#table')) {
            this.ref.reattach();
            if (notClear) {
                $('#table').DataTable().destroy();
            } else {
                $('#table').DataTable().clear().destroy();
            }
            this.ref.detectChanges();
        }
        setTimeout(() => {
            let dtOptions: any = {
                initComplete: () => {
                    this.hoverglassActive = false;
                    this.ref.detectChanges();
                },
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                dom: 'lrtip',
                searching: true,
                ordering: true,
                orderCellsTop: true,
                scrollY: 'calc(100vh - 400px)',
                scrollCollapse: true,
                columnDefs: [
                    { targets: [COLUMNS_INDEX.POSITION], visible: false, data: 'pos' },

                    { targets: [COLUMNS_INDEX.CATEGORIA], width: '100px', className: "pre word-break" }, // Categoria
                    { targets: [COLUMNS_INDEX.CODICE], width: '70px' }, // Codice
                    { targets: [COLUMNS_INDEX.QUANTITA_UNITA_BASE, COLUMNS_INDEX.QUANTITA_UNITA_MAGAZZINO, COLUMNS_INDEX.QUANTITA_UNITA_ORDINE], width: '60px' }, // Quantità Unità Base, Quantità Unità Magazzino, Quantità Unità Ordine

                    { targets: [COLUMNS_INDEX.QUANTITA_UNITA_BASE, COLUMNS_INDEX.UNITA_BASE], visible: !this.hideBase }, // Quantità Unità Base, Quantità Unità Magazzino, Quantità Unità Ordine

                    { targets: [COLUMNS_INDEX.UNITA_BASE, COLUMNS_INDEX.UNITA_MAGAZZINO, COLUMNS_INDEX.UNITA_ORDINE], orderable: false, width: '60px' }, // Unità Base, Unità Magazzino, Unità Ordine

                    { targets: [COLUMNS_INDEX.GIACENZE], width: '80px', visible: !this.groupByQty }, // Giacenze
                    { targets: [COLUMNS_INDEX.QUANTITA_UNITA_ORDINE], orderable: true, orderDataType: 'dom-text-numeric' },

                    { targets: [COLUMNS_INDEX.PREZZO_UNITA_ORDINE], width: '60px' }, // Prezzo Unità Ordine
                    { targets: [COLUMNS_INDEX.SCONTI], width: '50px' }, // Sconti
                    { targets: [COLUMNS_INDEX.TOTALE], width: '50px' }, // Totale
                    { targets: [COLUMNS_INDEX.POSIZIONE], width: '60px', visible: false }, // Posizione
                    { targets: [COLUMNS_INDEX.ACTIONS], orderable: false, width: '50px' }, // Actions
                    { targets: [COLUMNS_INDEX.ID], visible: false }, // Actions
                ],
                rowReorder: {
                    dataSrc: 'pos'
                },
                aaSorting: [[COLUMNS_INDEX.POSITION, 'asc'], [COLUMNS_INDEX.CATEGORIA, 'asc']],
                // order: [[0, 'asc'], [1, 'asc']],
                autoWidth: false
            };

            this.table = $('#table').DataTable(dtOptions);

            this.table.on('row-reorder', (e, diff, edit) => {
                var result = 'Reorder started on row: ' + edit.triggerRow.data()[1] + '<br>';
                for (var i = 0, ien = diff.length; i < ien; i++) {
                    const from = diff[i].oldData;
                    const to = diff[i].newData;
                    let index = this.lista.findIndex((i: any) => i.pos == from);
                    if (index >= 0) this.lista[index].pos = to;
                }
            });

            this.table.on('search.dt', () => {
                setTimeout(() => {
                    $('table tbody>tr:first-child input.input_quantita').focus();
                    $('table tbody>tr:first-child input.input_quantita').select();
                    this.ref.detectChanges();
                }, 1000);
            });

            $('#table td.hasInput').off();
            $('#table td.hasInput').on({
                click: (ev: any) => {
                    const element = $(ev.target);
                    const parentRow = element.closest('tr');
                    $(parentRow).addClass('focusedRow');
                    // this.triggerClickToShowInput(element);
                    setTimeout(() => {
                        $(element).find('input').select();
                        $(element).find('input').focus();
                    });
                },
                focusout: (ev: any) => {
                    const element = $(ev.target);
                    const parentRow = element.closest('tr');
                    $(parentRow).removeClass('focusedRow');
                }
            });

            $('#table tbody input').off();
            $('#table tbody input').on({
                keydown: (event: KeyboardEvent) => {
                    if (event.code == 'Tab') {
                        setTimeout(() => {
                            const className = $(event.target).closest('td').hasClass('td_quantita') ? 'td_quantita' : 'td_price';
                            const target: any = $(event.target);
                            target.closest('tr').next('tr').find(`.${className} input`).focus();
                            target.closest('tr').next('tr').find(`.${className} input`).select();
                        }, 10);

                    } else if (event.code === 'Enter' || event.code == 'NumpadEnter') {
                        event.preventDefault();
                        event.stopPropagation();
                        $('#productOrCode').focus();
                        $('#productOrCode').select();
                    }
                }
            });

            this.table.columns.adjust().draw();

            setTimeout(() => {
                this.ref.detach();
            }, 1000);

        }, 250);

    }

    onFilterChange(event: any) {
        if (event.code === 'Enter' || event.code == 'NumpadEnter') {
            this.filterChanged.next(true);
        }
    }

    onInputChange(item: any) {
        this.warehouseWorker.postMessage({
            operation: 'updateRowCalculationOrders',
            item: item,
            lista: this.lista,
            listaFiltered: this.lista,
            locale: this.locale
        });
    }

    getSumQty() {
        let tot: number = 0;
        if (this.lista && this.lista.length > 0) {
            this.lista.forEach(item => {
                if (item.Quantity) {
                    tot += util.parseNumber(item.Quantity, this.locale)
                }
            });
        }
        return tot;
    }

    getSumTotale() {
        let tot: number = 0;
        if (this.lista && this.lista.length > 0) {
            this.lista.forEach(item => {
                if (item.Totale) {
                    tot += util.parseNumber(item.Totale, this.locale)
                }
            });
        }
        return tot;
    }

    IsSaveDisabled() {
        //console.log('IsSaveDisabled');
        let disabled = true;
        if (this.lista) {
            this.lista.forEach((x: any) => {
                if (x.flag || x.QuantitaUnitaBase) {
                    disabled = false;
                    return;
                }
            });
        }

        return disabled;
    }

    cleanRow(item: any) {
        item.QuantitaUnitaBase = undefined;
        item.QuantitaMagazzino = undefined;
        item.Discount = undefined;
        item.Totale = undefined;
        item.Quantity = undefined;
        item.Price = undefined;
        this.ref.detectChanges();
    }

    cleanAllRow() {

        const _title: string = this.translate.instant('MAGAZZINO.ORDINI.INSERIMENTO_ORDINI.CLEAR_ALL.CLEAR_ALL_TITLE');
        const _description: string = this.translate.instant('MAGAZZINO.ORDINI.INSERIMENTO_ORDINI.CLEAR_ALL.CLEAR_ALL_SUBTITLE');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('COMMONS.SAVE');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((expand: any) => {
            if (expand) {
                this.lista.forEach((item: any) => {
                    this.cleanRow(item);
                })
                // this.ref.detectChanges();
            }
        });

    }

    insertNote(item: any) {
        const dialogRef = this.dialog.open(EditNotesComponent, {
            data: { item },
            width: '600px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            console.log(res);
            if (res.success && res.Note) {
                item.Note = res.Note;
                this.ref.detectChanges();
            }

        });
    }

    async saveAsk() {

        const dangerRow = this.lista.filter((item: any) => item.Quantity && !item.Totale);

        if (dangerRow.length > 0) {


            this.lista = this.lista.sort((a: any, b: any) => {
                return (a.Quantity && !a.Totale) ? -1 : 1
            });

            this.ref.detectChanges();

            const _title: string = this.translate.instant('MAGAZZINO.ORDINI.INSERIMENTO_ORDINI.DANGER_TITLE');
            const _description: string = this.translate.instant('MAGAZZINO.ORDINI.INSERIMENTO_ORDINI.DANGER_ASK');
            const _waitDesciption: string = '';
            const _yesButton = this.translate.instant('COMMONS.SAVE');
            const _noButton = this.translate.instant('COMMONS.CANCEL');

            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
            dialogRef.afterClosed().subscribe(async (expand: any) => {
                if (expand) {
                    const choise = await this.editPopup(this.currentConfig.body, false, true).toPromise();
                    if (choise && choise.success) {
                        this.magazzinoService.setCurrentConfig(choise);
                        this.save();
                        this.enablePopup = true;
                        this.ref.detectChanges();
                    }
                }
            });

        } else {
            const choise = await this.editPopup(this.currentConfig.body, false, true).toPromise();
            if (choise && choise.success) {
                this.magazzinoService.setCurrentConfig(choise);
                this.currentConfig = this.magazzinoService.getCurrentConfig();
                this.save();
                this.enablePopup = true;
                this.ref.detectChanges();
            }
        }


    }

    save() {
        this.loading$.next(true);
        let body: any[] = [];

        const productList = this.lista.filter((item: any) => {
            return util.parseNumber(item.QuantitaMagazzino, this.locale) > 0 || item.flag;
        });

        console.log(this.lista, productList);

        productList.forEach((item: any) => {
            console.log(item);

            let itemTmp = {
                Company: this.currentConfig.body.Company,
                SupplierId: Number(this.currentConfig.body.SupplierId),
                OrderId: this.currentConfig.body.OrderId ? this.currentConfig.body.OrderId : null,
                Date: this.currentConfig.body.Date,
                GoodId: Number(item.Id),
                Quantity: item.Quantity ? util.parseNumber(item.Quantity, this.locale) : 0,
                Price: util.parseNumber(item.Price, this.locale),
                Code: item.Code,
                Description: this.currentConfig.body.Description,
                Note: item.Note,
                S1: item.S1,
                S2: item.S2,
                S3: item.S3,
                S4: item.S4,
                Discount: item.Discount,
                Total: item.Totale,

                DepartmentId: item.DepartmentId,
                NomeMerce: item.NomeMerce,
                DocumentNumber: item.DocumentNumber
            };

            var costCenter;
            if (this.itemId) {
                costCenter = this.staticCollectionsService.costCenters$.find((cc: any) => (cc.Id && item.CostCenterId) ? cc.Id.toString() === item.CostCenterId.toString() : false);
            } else {
                costCenter = this.staticCollectionsService.costCenters$.find((cc: any) => (cc.Id && item.CostCenter) ? cc.Id.toString() === item.CostCenter.toString() : false);
            }
            //var costCenter = this.staticCollectionsService.costCenters$.find((cc: any) => cc.Id.toString() === item.CostCenterId.toString());
            if (costCenter && costCenter.$id) delete costCenter.$id;
            if (costCenter && !costCenter.Name) {
                costCenter.Name = '---';
            }
            itemTmp['CostCenter'] = costCenter;


            if (this.itemId) {
                itemTmp['Id'] = this.itemId;
            }

            body.push(itemTmp);
        });

        //console.log('save', 'body', body, JSON.stringify(body));

        if (this.itemId) {
            this.magazzinoService.update('Order', body).toPromise()
                .then((result: any) => {
                    console.log(result);
                    let message: string = '';
                    if (result.Msg === 'Ok') {
                        message = this.translate.instant('MAGAZZINO.MODIFICHE.MODIFICA_OK');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                        productList.forEach((element: any) => {
                            this.cleanRow(element);
                        });
                        this.restoreMap = {};
                    } else {
                        message = this.translate.instant('MAGAZZINO.MODIFICHE.MODIFICA_ERROR');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                    }
                })
                .finally(() => {
                    this.loading$.next(false);
                    this.ref.detectChanges();
                });
        } else {
            this.magazzinoService.save('Order', body).toPromise()
                .then((result: any) => {
                    // console.log(result);
                    let message: string = '';
                    if (result.Msg === 'Ok') {
                        message = this.translate.instant('MAGAZZINO.INSERIMENTI.INSERIMENTO_OK');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                        productList.forEach((element: any) => {
                            this.cleanRow(element);
                        });
                        this.restoreMap = {};
                    } else {
                        message = this.translate.instant('MAGAZZINO.INSERIMENTI.INSERIMENTO_ERROR');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                    }
                })
                .finally(() => {
                    this.loading$.next(false);
                    this.ref.detectChanges();
                });
        }

    }

    getSaveCaption() {
        return this.translate.instant('MAGAZZINO.ORDINI.INSERIMENTO_ORDINI.SALVA');
    }

    getPopupCaption() {
        return this.translate.instant('MAGAZZINO.ORDINI.TOOLBAR.NEW');
    }

    getGoBackCaption() {
        return this.translate.instant('MAGAZZINO.ORDINI.TITLE');
    }

    goBack() {
        this.router.navigate([`${this.referral}`], { queryParams: {} });
    }

    openPopup() {
        const newEndpoint = {};
        this.editPopup(newEndpoint, true);
    }

    editPopup(endpoint: any, forceReload: boolean = false, convertToPromise: boolean = false) {
        const dialogRef = this.dialog.open(InserimentoOrdiniPopupComponent, {
            data: { endpoint },
            width: '600px'

        });
        if (convertToPromise) {
            return dialogRef.afterClosed();
        } else {
            dialogRef.afterClosed().subscribe((res: any) => {
                if (res && res.success && res.body) {
                    this.magazzinoService.setCurrentConfig(res);
                    this.currentConfig = res;
                    if (forceReload || !this.itemId) {
                        this.lista = [];
                        $('#table').DataTable().destroy();
                        this.initInserimento();
                    }
                    this.magazzinoService.summaryUpdated$.next(true);
                    this.ref.detectChanges();

                    this.ProductOrCode.nativeElement.focus();
                    this.ProductOrCode.nativeElement.select();
                } else if (this.itemId) {
                    this.magazzinoService.summaryUpdated$.next(true);
                    this.ref.detectChanges();

                    this.ProductOrCode.nativeElement.focus();
                    this.ProductOrCode.nativeElement.select();
                }
            });

            return from('');
        }
    }

    sortType: string = 'cat_cod';
    sort() {
        switch (this.sortType) {
            case 'cat_cod':
                this.table.columns([0, 1]).order('asc').draw();
                break;
            case 'cat_prod':
                this.table.columns([0, 2]).order('asc').draw();
                break;
        }
    }

    /***************************************************************************************************** */
    prodottiFiltrati!: Observable<any[]>;
    autocompleteDescription = new FormControl();
    showAutocompleteDescription(item: any, $event: any) {
        if (this.mappaSelect[item.Id]) return;

        const index = $($event.target).closest('tr').index();

        item['IndexSelect'] = index;

        this.mappaSelect = {};
        this.mappaSelect[item.IndexSelect] = true;

        this.prodottiFiltrati = this.autocompleteDescription.valueChanges
            .pipe(
                startWith(''),
                map(value => this.filterGoods(value)),
                tap(() => this.ref.detectChanges()) // Serve per aggiornare l'interfaccia, perchè cè il detach
            );

        const model = this.goods;

        const value = model.find((prodotto: any) => {
            return !item.Id || item.Id.toString() === prodotto.Id.toString();
        });

        if (Number(item.Id) > 0) {
            this.autocompleteDescription.setValue(value);
        } else {
            this.autocompleteDescription.setValue('');
        }

        setTimeout(() => {
            //(<any>document).getElementById("matAutoCompleteInput").select();
            $('#matAutoCompleteInput').select();
            this.ref.detectChanges();
        }, 100);
        this.ref.detectChanges();
    }
    /***************************************************************************************************** */
    private filterGoods(value: any) {
        if (!value || value.constructor !== String) {
            return this.goods;
        } else {
            return this.goods.filter((prodotto: any) => {
                return prodotto.Name.toLowerCase().includes(value.toLowerCase())
            });
        }
    }
    /***************************************************************************************************** */
    addNewEmptyIngredient() {
        // Se per caso ho già un ultima riga vuota non la metto
        if (this.lista &&
            this.lista[this.lista.length - 1] &&
            this.lista[this.lista.length - 1].Name.length === 0) return;

        let maxPosition = 0;
        this.lista.forEach((element: any) => {
            maxPosition = element.pos > maxPosition ? element.pos : maxPosition;
        });

        this.lista.push({
            // Id: '',
            pos: maxPosition + 1,
            Category: '',
            Code: '',
            AlternateName: '',
            Name: '',
            QuantitaUnitaBase: 0,
            QuantitaMagazzino: 0,
            Stock: 0,
            Unit: '',
            SecondaryUnit: '',
            SecondaryUnitRatio: 1,
            Quantity: 0,
            OrderUnit: '',
            OrderUnitRatio: 1,
            Price: 0,
            Totale: 0,
            Position: '',
            Note: ''
        });
    }
    /***************************************************************************************************** */
    /**
     * Selezione nuovo ingrediente 
     * @param event 
     * @param ingredient 
     */
    selectNewIngredient(event: any, ingredient: any) {
        // const row = $('#select_description').closest('tr').index() + 1;

        let newIngredient = event.option.value;

        // console.log('newIngredient', newIngredient);

        const index = this.lista.findIndex((obj => obj.UniqueId == ingredient.UniqueId));

        let maxPosition = 0;
        this.lista.forEach((element: any) => {
            maxPosition = element.pos > maxPosition ? element.pos : maxPosition;
        });

        // Sostituisco l'attuale ingrediente con quello nuovo
        this.lista[index].pos = maxPosition + 1;
        this.lista[index].Category = newIngredient.Category;
        this.lista[index].CategoryId = newIngredient.CategoryId;
        this.lista[index].Code = newIngredient.Code;
        this.lista[index].Name = newIngredient.Name;
        this.lista[index].AlternateName = newIngredient.Name;

        /* DA VERIFICARE */
        this.lista[index].Unit = this.getBaseUnit(newIngredient).Name;
        this.lista[index].UnitId = this.getBaseUnit(newIngredient).Id;
        this.lista[index].SecondaryUnit = newIngredient.SecondaryUnit;
        this.lista[index].SecondaryUnitId = newIngredient.SecondaryUnitId;
        this.lista[index].SecondaryUnitRatio = newIngredient.SecondaryUnitRatio;
        this.lista[index].OrderUnit = newIngredient.OtherUnit;
        this.lista[index].OrderUnitId = newIngredient.OtherUnitId;
        this.lista[index].OrderUnitRatio = newIngredient.OtherUnitRatio;
        /*****************/

        this.lista[index].Id = newIngredient.Id;
        this.lista[index].Price = util.formatNumber(newIngredient.Price, this.locale, 2);
        this.lista[index].UniqueId = newIngredient.Category + "|" + newIngredient.Id + "|" + newIngredient.Code

        this.lista[index].S1 = newIngredient.S1;
        this.lista[index].S2 = newIngredient.S2;
        this.lista[index].S3 = newIngredient.S3;
        this.lista[index].S4 = newIngredient.S4;

        this.lista[index]['Discount'] = this.calcDiscount(newIngredient);

        // Se ho utilizzato l'ultima riga vuota allora ne aggiungo un altra.      
        this.addNewEmptyIngredient();

        // console.log(this.lista);
        this.mappaSelect = {};
        // $('#table').DataTable().destroy();
        // setTimeout(() => {
        this.initDataTable(true);
        setTimeout(() => {
            // $(`#table tr:nth-child(${row}) .qty_input`).select();
            var $scrollBody = $(this.table.table().node()).parent();
            $scrollBody.scrollTop($scrollBody.get(0).scrollHeight);
            this.ref.detectChanges();
        }, 1000);
        // }, 1000);
    }
    /***************************************************************************************************** */
    /**
     * Gets base unit according to selected unit
     * @param newIngredient 
     */
    getBaseUnit(newIngredient: any): any {

        const unit = this.staticCollectionsService.unit$.find((unit: any) => unit.Id === newIngredient.UnitId);
        const baseUnit = this.staticCollectionsService.unit$.find((u: any) => u.Id === unit.MainUnitId)
        let u: any = unit;
        if (baseUnit.Name.toLowerCase() === 'kg' && unit.Name.toLowerCase() !== 'g') {
            u = this.staticCollectionsService.unit$.find((u: any) => u.Name.toLowerCase() === 'g');
        } else if (baseUnit.Name.toLowerCase() === 'lt' && unit.Name.toLowerCase() !== 'ml') {
            u = this.staticCollectionsService.unit$.find((u: any) => u.Name.toLowerCase() === 'ml');
        } else {
            u = unit;
        }

        return {
            Id: u.Id,
            Name: u.Name,
            BaseId: u.MainUnitId,
            //BaseUnitName: u.Name,
            A: u.A,
            B: u.B
        };

    }
    /***************************************************************************************************** */
    displayFn(product: any): string {
        if (!product) return '';
        return product.Name;
    }

    getDiscount(item: any, key: string) {
        if (item && item[key]) return item[key];
        if (!this.goods || this.goods.length == 0) return;
        const good = this.goods.find((good: any) => good.Id == item.Id);
        return good && good[key] ? good[key] : 0;
    }

    editMerce(ingredient) {
        this.gestioneMerciService.getSingleGoods(ingredient.Id).subscribe(
            (result: any) => {
                const dialogRef = this.dialog.open(EditMerciComponent, {
                    data: {
                        merce: result
                    },
                    width: '100%',
                    height: '100%'
                });

                dialogRef.afterClosed().subscribe((res: any) => {
                    // console.log(res);
                    if (res && res.Prices && res.Prices.length > 0) {

                        ingredient.Name = res.Name;
                        ingredient.AlternateName = res.AlternateName;

                        // recupero le info sugli sconti se ci sono
                        const supplierInfo = res.Suppliers.find((s: any) => s.Id == this.currentConfig.body.SupplierId);

                        // Se lo trovo
                        if (supplierInfo) {
                            // cerco la variazione
                            const variationInfo = supplierInfo.Variations.find(v => v.Code === ingredient.Code)
                            // Se la trovo
                            if (variationInfo) {
                                // Recupero gli sconti se ci sono e li copio in this.goods nel item corretto
                                const goodIndex = this.goods.findIndex((good: any) => good.Id == res.Id);
                                this.goods[goodIndex].S1 = this.utility.parseNumber(variationInfo.S1, this.locale);
                                this.goods[goodIndex].S2 = this.utility.parseNumber(variationInfo.S2, this.locale);
                                this.goods[goodIndex].S3 = this.utility.parseNumber(variationInfo.S3, this.locale);
                                this.goods[goodIndex].S4 = this.utility.parseNumber(variationInfo.S4, this.locale);
                                // Li copio anche nell'ingredient, così si vedono subito in tabella.
                                ingredient.S1 = this.utility.parseNumber(variationInfo.S1, this.locale);
                                ingredient.S2 = this.utility.parseNumber(variationInfo.S2, this.locale);
                                ingredient.S3 = this.utility.parseNumber(variationInfo.S3, this.locale);
                                ingredient.S4 = this.utility.parseNumber(variationInfo.S4, this.locale);
                                ingredient['Discount'] = this.calcDiscount(ingredient);
                            }
                        }

                        const priceInfo = res.Prices.find((supplier: any) => supplier.SupplierId == this.currentConfig.body.SupplierId);
                        if (priceInfo) {
                            ingredient.OrderUnit = priceInfo.OtherUnit;
                            ingredient.OrderUnitId = priceInfo.OtherUnitId;
                            ingredient.OrderUnitRatio = this.utility.parseNumber(priceInfo.OtherUnitRatio, this.locale);
                            ingredient.Price = this.utility.parseNumber(priceInfo.PriceUM, this.locale);
                            ingredient.SecondaryUnit = priceInfo.SecondaryUnit;
                            ingredient.SecondaryUnitId = priceInfo.SecondaryUnitId;
                            ingredient.SecondaryUnitRatio = this.utility.parseNumber(priceInfo.SecondaryUnitRatio, this.locale);

                            if (this.utility.parseNumber(ingredient.Quantity, this.locale) > 0) {
                                ingredient.QuantitaMagazzino = this.utility.parseNumber(ingredient.Quantity.toString(), this.locale) * parseFloat(ingredient.OrderUnitRatio.toString());
                                ingredient.QuantitaUnitaBase = parseFloat(ingredient.QuantitaMagazzino.toString()) * parseFloat(ingredient.SecondaryUnitRatio.toString());
                                ingredient.Totale = this.utility.parseNumber(ingredient.Quantity.toString(), this.locale) * parseFloat(ingredient.Price.toString()) * parseFloat(ingredient.OrderUnitRatio.toString());
                                if (ingredient.Discount > 0) {
                                    ingredient.Totale = ingredient.Totale - (ingredient.Totale * ingredient.Discount);
                                }
                            }
                        }

                        setTimeout(() => {
                            this.table.row(ingredient.IndexSelect).invalidate().draw();
                            this.ref.detectChanges();
                        }, 100);

                        // }, () => {
                        // 	// this.loading$.next(false);
                        // });

                    }
                });
            }
        );

    }

    editSconto(item) {

        let itemTmp = JSON.parse(JSON.stringify(item));
        itemTmp.S1 = this.getDiscount(itemTmp, 'S1');
        itemTmp.S2 = this.getDiscount(itemTmp, 'S2');
        itemTmp.S3 = this.getDiscount(itemTmp, 'S3');
        itemTmp.S4 = this.getDiscount(itemTmp, 'S4');

        const dialogRef = this.dialog.open(DiscountsEditDialogComponent, {
            data: {
                item: itemTmp,
                locale: this.locale
            },
            //width: '100%',
            //height: '100%'
        });

        dialogRef.afterClosed().subscribe((res: any) => {
            console.log(res);
            if (res) {
                item.S1 = res.S1;
                item.S2 = res.S2;
                item.S3 = res.S3;
                item.S4 = res.S4;
                item.Discount = res.Discount;

                // Effettuo i calcoli
                this.onInputChange(item);

                this.ref.detectChanges();
            }
        });
    }

    forceSelectAll(event: any) {
        $(event.target).select();
    }

    checkDangerRow(ingredient: any) {
        //const danger = this.utility.parseNumber(ingredient.Quantity, this.locale) > 0 && this.utility.parseNumber(ingredient.Totale, this.locale) == 0;
        //return danger;
        return false;
    }

    //showTooltip(show: boolean, tooltip: string) {
    //    if (show) {
    //        this[tooltip].show();
    //    } else if (!show) {
    //        this[tooltip].hide();
    //    }
    //    setTimeout(() => {
    //        this.ref.detectChanges();
    //    }, 10);
    //}

    getTranslate(string: string) {
        return this.translate.instant(string);
    }

    showActionTooltip(event: MouseEvent, show: boolean, tooltip: string = '') {
        if (show && !tooltip) return;

        if (show) {
            $('#tooltip').text(tooltip);
            const positionX = event.clientX - 100;
            const positionY = event.clientY - 50;
            $('#tooltip').css('top', `${positionY}px`);
            $('#tooltip').css('left', `${positionX}px`);
            $('#tooltip').show();
        } else {
            $('#tooltip').text();
            $('#tooltip').hide();
        }

        setTimeout(() => {
            this.ref.detectChanges();
        }, 10);
    }
}
