import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

@Injectable({
  providedIn: 'root'
})
export class VenditeManualiService {

  constructor(
    private switchGroupService: SwitchGroupService,
    private http: HttpClient
  ) { }

  get(filter: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Selling/GetProducts`
    return this.http.post<any>(currentLogin.endpointURL + url, filter, { headers: httpHeaders });
  }

  save(body): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Selling/Save`
    return this.http.post<any>(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }
}
