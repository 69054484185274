// Angular
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
// RxJS
import { Subject } from 'rxjs';

@Injectable()
export class MenuConfigService {
	// Public properties
	onConfigUpdated$: Subject<any>;
	// Private properties
	private menuConfig: any;

	onEndpointUpdated$!: Subject<boolean>;

	/**
	 * Service Constructor
	 */
	constructor() {
		// register on config changed event and set default config
		this.onConfigUpdated$ = new Subject();
	}

	/**
	 * Returns the menuConfig
	 */
	getMenus() {
		return this.menuConfig;
	}

	/**
	 * Load config
	 *
	 * @param config: any
	 */
	loadConfigs(config: any) { 
		
		if (this.menuConfig) {

			for (let index = 0; index < config.aside.items.length; index++) {
				this.menuConfig.aside.items[index].permission = config.aside.items[index].permission ? config.aside.items[index].permission : false;
				if (config.aside.items[index].submenu) {
					for (let indexSub = 0; indexSub < config.aside.items[index].submenu.length; indexSub++) {
						this.menuConfig.aside.items[index].submenu[indexSub].permission = config.aside.items[index].submenu[indexSub].permission ? config.aside.items[index].submenu[indexSub].permission : false;
					}
				}
			}

		} else {
			this.menuConfig = config;
		}
		this.onConfigUpdated$.next(this.menuConfig);
	}
}
