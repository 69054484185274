import { Injectable } from '@angular/core';
import { SwitchGroupService } from '../_base/layout';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstrazioneDatiService {

  constructor(
    private http: HttpClient,
    private switchGroupService: SwitchGroupService
  ) { }

  getTimeSlotReport(config: any, withTax: boolean = false): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(currentLogin.endpointURL + `/api/Selling/TimeSlotReport?withTax=${withTax}`, config , { headers: httpHeaders });
  }
}
