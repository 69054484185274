import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContoEconomicoDetailsComponent } from '../../conto-economico-details/conto-economico-details.component';
import { ContoEconomicoEditComponent } from '../../conto-economico-edit/conto-economico-edit.component';
import * as util from '@app/core/services/utilityfunctions';
import { RoleService, SwitchGroupService, TranslationService } from '@app/core/_base/layout';
import { PDFService } from '@app/core/services/pdf.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FiltriService } from '@app/core/services/filtri.service';
import moment from 'moment';
import * as XLSX from '@sheet/core';

@Component({
    selector: 'kt-standard-view',
    templateUrl: './standard-view.component.html',
    styleUrls: ['./standard-view.component.scss']
})
export class StandardViewComponent implements OnInit {
    view: string = 'STANDARD';

    @Input() filters: any = {};
    @Output() onClickGraph = new EventEmitter<any>();
    locale: any = {};
    showTotals: boolean = true;
    currentFilterCfg: any = {};
    utility: any;
    expandRows: boolean = false;
    lastRowsExpanded: any = [];

    filtriServiceSubscription!: Subscription;

    constructor(
        private ref: ChangeDetectorRef,
        public dialog: MatDialog,
        private roleService: RoleService,
        private switchGroupService: SwitchGroupService,
        private pdfService: PDFService,
        private translate: TranslateService,
        public filtriService: FiltriService,
        private translationService: TranslationService
    ) {
        this.utility = util;

        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
            moment.locale(this.locale)
        });
    }

    ngOnDestroy() {
        this.filtriServiceSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.checkScroll();

        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            async (filterConfig: any) => {
                // Se ho un filtro memorizzato lo recupero
                if (localStorage.getItem('currentFilterCfg')) {
                    try {
                        this.currentFilterCfg = JSON.parse(localStorage.getItem('currentFilterCfg') || '');
                        localStorage.removeItem('currentFilterCfg');
                    } catch (error) {
                        localStorage.removeItem('currentFilterCfg');
                        return;
                    }
                    //return;
                } else {
                    if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) {
                        return;
                    };
                    if (filterConfig.constructor !== Object) {
                        return;
                    };

                    if (JSON.stringify(filterConfig) == JSON.stringify(this.currentFilterCfg)) {
                        return;
                    }

                    this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));
                }

                //
                if (this.filters.filterCompanies && this.filters.filterCompanies.length > 0) {
                    this.currentFilterCfg['Companies'] = this.filters.filterCompanies;
                } else {
                    delete this.currentFilterCfg.Companies;
                }

                if (this.filters.filtercostCenters && this.filters.filtercostCenters.length > 0) {
                    this.currentFilterCfg['CostCenterIds'] = this.filters.filtercostCenters;
                } else {
                    delete this.currentFilterCfg.CostCenterIds;
                }

                if (!this.table) {
                    this.initDataTable();
                } else {
                    this.table.draw();
                }

            }
        );
    }

    checkScroll() {
        const wHeight: any = $(window).height();
        const tHeight: any = $("#ceTable").height();
        if (wHeight < tHeight) {
            $("#scrollUp").show();
        } else {
            $("#scrollUp").hide();
        }
    }

    clearFilters(actionFilter: boolean = true, redrawTable: boolean = true) {
        this.filtriService.clearFilters$.next(actionFilter);
        this.showTotals = true;
        this.currentFilterCfg = {
            Period: 'year',
            fromLocal: true,
            TimeInterval: 'none',
            DayOfWeek: 0
        };
        this.lastRowsExpanded = [];
        if (redrawTable) this.table.draw();
    }

    training: number = 0;
    showTraining(show: boolean = false) {
        this.training = show ? 2 : 0;
        // 0/3 fa vedere tutto insieme
        // 1 Non fa vedere training
        // 2 Fa vedere solo training
        this.table.draw();
    }

    table: any;
    PDFPreferences: any;
    initDataTable() {
        if ($.fn.dataTable.isDataTable('#ceTable')) {
            $('#ceTable').DataTable().destroy();
        }

        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();

            const dtOptions: any = {
                //orderCellsTop: true,
                drawCallback: () => {
                    if (this.lastRowsExpanded.length >= 0) {
                        this.lastRowsExpanded.forEach((row: any) => {
                            $(`#ceTable tbody tr:nth-child(${row + 1}) td.parent-row`).trigger('click');
                        })
                    }

                    this.checkScroll();

                    if (this.filters.PLCategoryName) {
                        setTimeout(() => {
                            // Cerco il primo elemento che contiene this.filters.PLCategoryName
                            const riga = $(`td[data-categoryname="${this.filters.PLCategoryName}" i]`).closest('tr');
                            if (riga.length > 0) riga[0].scrollIntoView();
                        }, 100);
                    }

                    if (this.showTotals) {
                        $('tr.children').hide();
                    }
                },
                createdRow: function (row, data, dataIndex) {
                    if (data.IsCover) {
                        $(row).addClass('rowCover');
                        if (data.IsSectionTotal) {
                            $(row).addClass('rowCoverTotal');
                        }
                        $(row).attr('data-row', JSON.stringify(data));
                    }
                    if (data.IsTotal) {
                        $(row).addClass('rowTotal');
                        if (data.IsSection) {
                            $(row).addClass('rowSection');
                        }
                        $(row).attr('data-row', JSON.stringify(data));
                    } else {
                        $(row).attr('data-row', JSON.stringify(data));
                    }
                },
                rowCallback: (row, data, index) => {
                    if (!this.filters.ck_showCover && data.ItemType == 'GenericCover') {
                        $(row).hide();
                    }

                    if (this.showTotals && !(data.IsTotal && data.IsSection)) {
                        $(row).hide();
                    }

                    // Controllo se ho effettuato una ricerca per terzo livello
                    if (this.filters.PLCategoryName && data.Children.length == 0 && !data.IsCover && !data.IsTotal) {
                        $(row).hide();
                    } else if (this.filters.PLCategoryName && data.Children.length > 0 && data.ItemType !== 'GenericCover') {
                        this.table.row($(row)).child(this.format(data)).show();
                    }
                },
                destroy: true,
                paging: false,
                searching: true,
                dom: 'lrtip',
                ordering: true,
                scrollX: true,
                scrollY: 'calc(100vh - 300px)',
                scrollCollapse: true,
                autoWidth: true,
                order: [],
                processing: true,
                serverSide: true,
                ajax: {
                    url: currentLogin.endpointURL + "/api/PL/DataTable",
                    type: "POST",
                    data: (d: any) => {
                        d.filters = this.currentFilterCfg;
                        d.filters.PLCategoryName = this.filters.PLCategoryName;
                        d.filters.Training = this.training;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        // Se sto filtrando per nome categoria, non posso vedere tutto collassato
                        if (this.filters.PLCategoryName) {
                            this.showTotals = false;
                        }
                        return response.data;
                    }
                },
                columnDefs: [
                    {
                        targets: 0,
                        className: 'parent-row hideInExport',
                        width: '20px',
                        orderable: false,
                        render: (data, type, p) => {
                            return !p.IsTotal && p.Children && p.Children.length > 0 ? '<i class="fas expander pointer"></i>' : '';
                        }
                    },

                    {
                        targets: 1, data: 'Name', name: 'Name', className: '', render: (data, boh, p) => {
                            let classClickGraph: string = !this.showTotals ? 'clickGraph' : '';
                            return `<div class="overflow-ellipses exportText ${classClickGraph}" title="${data}">${data}</div>`;
                        }
                    },

                    {
                        targets: 2, data: 'CurrentValue', name: 'CurrentValue',
                        class: 'text-right', visible: !this.filters.ck_lordo,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },
                    {
                        targets: 3, data: 'CurrentGrossValue', name: 'CurrentGrossValue',
                        class: 'text-right', visible: this.filters.ck_lordo,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },
                    {
                        targets: 4, data: 'CurrentValuePercAbs', name: 'CurrentValuePercAbs', class: 'text-right',
                        visible: this.filters.ck_abs,
                        render: (data, type, p) => {
                            if (this.filters.ck_lordo) {
                                return this.utility.formatNumber(p.CurrentValuePercAbsGross, this.locale);
                            } else {
                                return this.utility.formatNumber(data, this.locale);
                            }
                        }
                    },
                    {
                        targets: 5, data: 'CurrentValuePercRel', name: 'CurrentValuePercRel', class: 'text-right',
                        visible: !this.filters.ck_abs,
                        render: (data, type, p) => {
                            if (this.filters.ck_lordo) {
                                return this.utility.formatNumber(p.CurrentValuePercRelGross, this.locale);
                            } else {
                                return this.utility.formatNumber(data, this.locale);
                            }
                        }
                    },
                    {
                        targets: 6, data: 'CurrentValuePerCoperto', name: 'CurrentValuePerCoperto', class: 'text-right',
                        visible: this.filters.ck_cover,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },
                    {
                        targets: 7, data: 'BudgetValue', name: 'BudgetValue',
                        class: 'text-right', visible: !this.filters.ck_lordo && this.filters.ck_budget,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },
                    {
                        targets: 8, data: 'BudgetGrossValue', name: 'BudgetGrossValue',
                        class: 'text-right', visible: this.filters.ck_lordo && this.filters.ck_budget,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },
                    {
                        targets: 9, data: 'CurrentValueBudgetPercAbs', name: 'CurrentValueBudgetPercAbs', class: 'text-right', visible: this.filters.ck_abs && this.filters.ck_budget,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },
                    {
                        targets: 10, data: 'CurrentValueBudgetPercRel', name: 'CurrentValueBudgetPercRel', class: 'text-right', visible: !this.filters.ck_abs && this.filters.ck_budget,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },

                    {
                        targets: 11, data: 'CurrentValueBudgetPerCoperto',
                        name: 'CurrentValueBudgetPerCoperto',
                        visible: this.filters.ck_cover && this.filters.ck_budget, class: 'text-right',
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },

                    {
                        targets: 12, data: 'PreviousValue', name: 'PreviousValue',
                        class: 'text-right', visible: !this.filters.ck_lordo && this.filters.ck_prevyear,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },
                    {
                        targets: 13, data: 'PreviousGrossValue', name: 'PreviousGrossValue',
                        class: 'text-right', visible: this.filters.ck_lordo && this.filters.ck_prevyear,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },
                    {
                        targets: 14, data: 'PreviousValuePercAbs', name: 'PreviousValuePercAbs', class: 'text-right', visible: this.filters.ck_abs && this.filters.ck_prevyear,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },
                    {
                        targets: 15, data: 'PreviousValuePercRel', name: 'PreviousValuePercRel', class: 'text-right', visible: !this.filters.ck_abs && this.filters.ck_prevyear,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },
                    {
                        targets: 16, data: 'PreviousValuePerCoperto', name: 'PreviousValuePerCoperto',
                        visible: this.filters.ck_cover && this.filters.ck_prevyear,
                        class: 'text-right',
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        }
                    },
                    {
                        targets: 17, data: 'BudgetGrossDeviation', name: 'BudgetGrossDeviation', // Scostamento
                        class: 'text-right', visible: this.filters.scostamento && this.filters.ck_budget,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        },
                        //width: '100px'
                    },
                    {
                        targets: 18, data: 'BudgetDeviation', name: 'BudgetDeviation', // Scostamento
                        class: 'text-right', visible: this.filters.scostamento && this.filters.ck_budget,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        },
                        //width: '100px'
                    },
                    {
                        targets: 19, data: 'BudgetGrossDelta', name: 'BudgetGrossDelta', // Differenza
                        class: 'text-right', visible: !this.filters.scostamento && this.filters.ck_budget,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        },
                    },
                    {
                        targets: 20, data: 'BudgetDelta', name: 'BudgetDelta', // Differenza
                        class: 'text-right', visible: !this.filters.scostamento && this.filters.ck_budget,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        },
                    },
                    {
                        targets: 21, data: 'PreviousGrossDeviation', name: 'PreviousGrossDeviation',   // Scostamento
                        class: 'text-right', visible: this.filters.scostamento && this.filters.ck_prevyear,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        },
                        //width: '100px'
                    },
                    {
                        targets: 22, data: 'PreviousDeviation', name: 'PreviousDeviation',   // Scostamento
                        class: 'text-right', visible: this.filters.scostamento && this.filters.ck_prevyear,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        },
                        //width: '100px'
                    },
                    {
                        targets: 23, data: 'PreviousGrossDelta', name: 'PreviousGrossDelta', // Differenza
                        class: 'text-right', visible: !this.filters.scostamento && this.filters.ck_prevyear,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        },
                    },
                    {
                        targets: 24, data: 'PreviousDelta', name: 'PreviousDelta', // Differenza
                        class: 'text-right', visible: !this.filters.scostamento && this.filters.ck_prevyear,
                        render: (data) => {
                            return this.utility.formatNumber(data, this.locale);
                        },
                    },
                    // Serve per evitare di riceve avvisi quando arrivano campi a null
                    {
                        defaultContent: '-',
                        targets: '_all'
                    }
                ],
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        //text: '<i class="fa fa-file-pdf text-danger"></i>',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.hideInExport)',
                            format: {
                                body: function (data, row, column, node) {
                                    if (data.indexOf('exportText') >= 0) {
                                        var parser = new DOMParser();
                                        var htmlDoc = parser.parseFromString(data, 'text/html');
                                        return $(htmlDoc).find('.exportText').text();
                                    } else {
                                        return data
                                    }
                                }
                            }
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, ['auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            //doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
                            //doc.defaultStyle = docDefinition.defaultStyle;
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;

                            doc.styles = docDefinition.styles;

                            var rowData = this.table.rows({ order: 'applied', search: 'applied' }).data();

                            // export row child
                            //http://live.datatables.net/hepopote/1/edit

                            if (this.expandRows) {
                                var headerLines = 0;  // Offset for accessing rowData array

                                var newBody: any = []; // this will become our new body (an array of arrays(lines))
                                //Loop over all lines in the table
                                doc.content[1].table.body.forEach((line, i) => {

                                    if (line[0].style !== 'tableHeader' && line[0].style !== 'tableFooter') {
                                        line[1]['style'] = rowData[i - headerLines].IsTotal ? 'tableBodyTotal' : 'tableBodyParent';
                                        for (let index = 2; index < line.length; index++) {
                                            line[index]['alignment'] = 'right';
                                            line[index]['style'] = rowData[i - headerLines].IsTotal ? 'tableBodyTotal' : 'tableBodyParent';
                                        }
                                    }

                                    // Remove detail-control column
                                    newBody.push(line.slice(1));

                                    if (line[0].style !== 'tableHeader' && line[0].style !== 'tableFooter') {

                                        var data = rowData[i - headerLines];
                                        // Append child data, matching number of columns in table
                                        if (data.Children && data.Children.length > 0) {

                                            data.Children.forEach(child => {

                                                let columnsValues: any = [{ text: '- ' + child.Name, style: 'tableBodyStyle' }];

                                                if (this.table.column(2).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.CurrentValue, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(3).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.CurrentGrossValue, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(4).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.CurrentValuePercAbs, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(5).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.CurrentValuePercRel, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(6).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.CurrentValuePerCoperto, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(7).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.BudgetGrossValue, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(8).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.BudgetValue, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(9).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.CurrentValueBudgetPercAbs, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(10).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.CurrentValueBudgetPercRel, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(11).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.CurrentValueBudgetPerCoperto, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(12).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.PreviousValue, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(13).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.PreviousGrossValue, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(14).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.PreviousValuePercAbs, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(15).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.PreviousValuePercRel, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(16).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.PreviousValuePerCoperto, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(17).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.BudgetGrossDeviation, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(18).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.BudgetDeviation, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(19).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.BudgetGrossDelta, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(20).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.BudgetDelta, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(21).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.PreviousGrossDeviation, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(22).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.PreviousDeviation, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(23).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.PreviousGrossDelta, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                if (this.table.column(24).visible()) {
                                                    columnsValues.push({ text: this.utility.formatNumber(child.PreviousDelta, this.locale), alignment: 'right', style: 'tableBodyStyle' });
                                                }

                                                columnsValues.push({ text: '', style: 'tableBodyStyle' });

                                                //newBody.push(
                                                //    [
                                                //        { text: '- ' + child.Name, style: 'tableBodyStyle' },
                                                //    ]
                                                //);


                                                //newBody = newBody.concat(columnsValues);

                                                newBody.push(columnsValues);

                                            })
                                        }

                                    } else {
                                        headerLines++;
                                    }

                                });

                                doc.content[1].table.body = newBody;
                            } else {
                                // Sostituisco il text danger con colore rosso
                                doc.content[1].table.body.forEach((line, i) => {
                                    if (i > 0 && rowData[i - 1].IsTotal) {
                                        line.forEach(col => col.style = 'tableBodyTotal');
                                    }
                                });
                            }


                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        //autoFilter: true,
                        //sheetName: 'Exported data',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.hideInExport)',
                            format: {
                                body: (data, row, column, node) => {
                                    // dalla colonna 1 in avanti formatto
                                    if (data.indexOf('exportText') >= 0) {
                                        var parser = new DOMParser();
                                        var htmlDoc = parser.parseFromString(data, 'text/html');
                                        return $(htmlDoc).find('.exportText').text();
                                    } else {
                                        const value = column >= 1 ? this.utility.parseNumber(data, this.locale) : data;
                                        return value;
                                    }
                                }
                            },
                        },
                        customize: async (xlsx: any) => {

                            XLSX.SSF.setlocale("de-DE");

                            var sSh = xlsx.xl['styles.xml'];
                            var lastXfIndex = $('cellXfs xf', sSh).length - 1;
                            // Bold Text - Yellow
                            var s1 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"0\" fontId=\"2\" fillId=\"6\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" />';
                            // Bold Text - Yellow - Alignment Right
                            var s2 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"4\" fontId=\"2\" fillId=\"6\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" xfId="0" applyNumberFormat="1"><alignment horizontal=\"right\"/></xf>';
                            // Bold Text - Alignment Right
                            var s3 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"4\" fontId=\"2\" fillId=\"0\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" xfId="0" applyNumberFormat="1"><alignment horizontal=\"right\"/></xf>';
                            sSh.childNodes[0].childNodes[5].innerHTML = sSh.childNodes[0].childNodes[5].innerHTML + s1 + s2 + s3;
                            const fillYellow = '<fill xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\"><patternFill patternType=\"solid\"><fgColor rgb=\"FFF1E8C1\" /><bgColor indexed=\"64\" /></patternFill></fill>';
                            sSh.childNodes[0].childNodes[2].innerHTML = sSh.childNodes[0].childNodes[2].innerHTML + fillYellow;
                            var boldTextYellowFillIndex = lastXfIndex + 1;
                            var boldTextYellowFillAlignRightIndex = lastXfIndex + 2;
                            var boldTextAlignRightIndex = lastXfIndex + 3;

                            // Get sheet.
                            var sheet = xlsx.xl.worksheets['sheet1.xml'];

                            // Get a clone of the sheet data.        
                            var sheetData = $('sheetData', sheet).clone();

                            // Clear the current sheet data for appending rows.
                            $('sheetData', sheet).empty();

                            // Row index from last column.
                            var DT_row = 0;        // Row count in Excel sheet.

                            var rowCount = 1;

                            // Itereate each row in the sheet data.
                            $(sheetData).children().each((index, element) => {

                                // Don't process row if its the header row.
                                if (index > 1) {

                                    // Get row
                                    var row: any = $(element);

                                    // Set the Excel row attr to the current Excel row count.
                                    row.attr('r', rowCount);

                                    // Iterate each cell in the row to change the rwo number.
                                    row.children().each((index, element) => {
                                        var cell = $(element);

                                        // Set each cell's row value.
                                        var rc: any = cell.attr('r');
                                        rc = rc.replace(/\d+$/, "") + rowCount;
                                        cell.attr('r', rc);

                                    });

                                    // Get the row HTML and append to sheetData.
                                    row = row[0].outerHTML;
                                    //console.log(row);
                                    $('sheetData', sheet).append(row);
                                    // Get the child data - could be any data attached to the row.
                                    const rowData = this.table.row(DT_row, { search: 'none', order: 'index' }).data();

                                    // Applico lo style ai parents
                                    //https://datatables.net/reference/button/excelHtml5
                                    if (rowData && rowData.IsTotal && rowData.IsSection) {
                                        // Imposto tutta la riga con lo stile boldTextYellowFillAlignRightIndex
                                        $(`row:nth-child(${rowCount}) c`, sheet).attr('s', boldTextYellowFillAlignRightIndex);
                                        // Imposto la prima cella con lo stile boldTextYellowFillIndex
                                        $(`row c[r="A${rowCount}"]`, sheet).attr('s', boldTextYellowFillIndex);
                                    } else if (rowData && rowData.IsTotal) {
                                        // Imposto la prima cella con lo stile bold
                                        $(`row c[r="A${rowCount}"]`, sheet).attr('s', '2');
                                        // Imposto il resto della riga con lo stile grassetto
                                        $(`row:nth-child(${rowCount}) c:gt(0)`, sheet).attr('s', boldTextAlignRightIndex);
                                    } else if (rowData) {
                                        // Imposto tutta la riga con l'allineamento a destra
                                        $(`row:nth-child(${rowCount}) c`, sheet).attr('s', '64');
                                        // Imposto la prima cella con l'allineamento a sinistra
                                        $(`row c[r="A${rowCount}"]`, sheet).attr('s', '50');
                                    } else {
                                        return;
                                    }

                                    rowCount++;

                                    if (this.expandRows) {
                                        const childData = rowData && rowData.Children ? rowData.Children : [];
                                        // The child data is an array of rows
                                        for (let c = 0; c < childData.length; c++) {
                                            // Get row data.
                                            const child = childData[c];

                                            const wordsColumns = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y'];
                                            let columnsValues: any = [];

                                            if (this.table.column(2).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.CurrentValue, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(3).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.CurrentGrossValue, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(4).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.CurrentValuePercAbs, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(5).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.CurrentValuePercRel, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(6).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.CurrentValuePerCoperto, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(7).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.BudgetGrossValue, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(8).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.BudgetValue, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(9).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.CurrentValueBudgetPercAbs, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(10).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.CurrentValueBudgetPercRel, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(11).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.CurrentValueBudgetPerCoperto, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(12).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.PreviousValue, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(13).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.PreviousGrossValue, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(14).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.PreviousValuePercAbs, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(15).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.PreviousValuePercRel, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(16).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.PreviousValuePerCoperto, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(17).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.BudgetGrossDeviation, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(18).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.BudgetDeviation, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(19).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.BudgetGrossDelta, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(20).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.BudgetDelta, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(21).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.PreviousGrossDeviation, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(22).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.PreviousDeviation, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(23).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.PreviousGrossDelta, this.locale, 2)}</v></c>`)
                                            }

                                            if (this.table.column(24).visible()) {
                                                columnsValues.push(`<c t="n" r="${wordsColumns[columnsValues.length]}${rowCount}" s="64"><v>${this.utility.parseNumber(child.PreviousDelta, this.locale, 2)}</v></c>`)
                                            }

                                            const childRow = `<row r="${rowCount}">
                                          <c t="inlineStr" r="A${rowCount}" s="52"><is><t>${child.Name.replace('&', 'o')}</t></is></c>
                                          ${columnsValues.join(' ')}
                                        </row>`;

                                            // Append row to sheetData.
                                            try {
                                                $('sheetData', sheet).append(childRow);
                                                // Allineo la child row a destra
                                                $('row:nth-child(' + rowCount + ') c', sheet).attr('s', '52');
                                                rowCount++; // Inc excelt row counter.                                           
                                            } catch (error) {
                                                console.log(error, childRow);
                                            }
                                        }
                                    }

                                    DT_row++;

                                    // Just append the header row and increment the excel row counter.
                                } else {
                                    var row: any = $(element);

                                    row = row[0].outerHTML;
                                    $('sheetData', sheet).append(row);
                                    rowCount++;
                                }
                            });

                        }
                    }
                ]
            };

            this.table = $('#ceTable').DataTable(dtOptions);

            // Add event listener for opening and closing details
            $('#ceTable tbody').off('click');

            $('#ceTable tbody').on('click', 'td.parent-row', (event: any) => {
                const tr = $(event.target).closest('tr');
                const row = this.table.row(tr);

                if (row.child.isShown()) {
                    this.lastRowsExpanded = this.lastRowsExpanded.filter((row: any) => row != tr.index());
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('shown');
                } else {

                    if (!this.lastRowsExpanded.includes(tr.index()))
                        this.lastRowsExpanded.push(tr.index());

                    // Open this row
                    row.child(this.format(row.data())).show();
                    tr.addClass('shown');
                }
            });

            $('#ceTable tbody').on('click', 'a.add_ce', (event: any) => {
                const tr = $(event.target).closest('tr');
                //const row = this.table.row(tr);
                const data = tr.data('row');

                if (data) {
                    this.addCE(data);
                }
            });

            $('#ceTable tbody').on('click', 'a.detail_ce', (event: any) => {
                const tr = $(event.target).closest('tr');
                //const row = this.table.row(tr);
                const data = tr.data('row');

                if (data) {
                    this.detailCE(data);
                }
            });

            $('#ceTable tbody').on('click', '.clickGraph', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data = tr.data('row');
                if (data) {
                    this.onClickGraph.emit(data);
                }
            });

        }, 100);

    }

    filterByPLName($event: KeyboardEvent) {
        if ($event.code == 'Enter') {
            this.table.draw();
        }
    }

    refreshColumnsVisibility(event: any, filter: string) {

        if (filter == 'ck_showCover') {
            this.table.draw();
            return;
        }

        // CurrentValue
        this.table.columns([2]).visible(!this.filters.ck_lordo);
        // CurrentGrossValue
        this.table.columns([3]).visible(this.filters.ck_lordo);
        // CurrentValuePercAbs
        this.table.columns([4]).visible(this.filters.ck_abs);
        // CurrentValuePercRel
        this.table.columns([5]).visible(!this.filters.ck_abs);
        // CurrentValuePerCoperto
        this.table.columns([6]).visible(this.filters.ck_cover);
        // BudgetValue
        this.table.columns([7]).visible(!this.filters.ck_lordo && this.filters.ck_budget);
        // BudgetGrossValue
        this.table.columns([8]).visible(this.filters.ck_lordo && this.filters.ck_budget);
        // CurrentValueBudgetPercAbs
        this.table.columns([9]).visible(this.filters.ck_budget && this.filters.ck_abs);
        // CurrentValueBudgetPercRel
        this.table.columns([10]).visible(this.filters.ck_budget && !this.filters.ck_abs);
        // CurrentValueBudgetPerCoperto
        this.table.columns([11]).visible(this.filters.ck_cover && this.filters.ck_budget);
        // PreviousValue
        this.table.columns([12]).visible(!this.filters.ck_lordo && this.filters.ck_prevyear);
        // PreviousGrossValue
        this.table.columns([13]).visible(this.filters.ck_lordo && this.filters.ck_prevyear);
        // PreviousValuePercAbs
        this.table.columns([14]).visible(this.filters.ck_prevyear && this.filters.ck_abs);
        // PreviousValuePercRel
        this.table.columns([15]).visible(this.filters.ck_prevyear && !this.filters.ck_abs);
        // PreviousValuePerCoperto
        this.table.columns([16]).visible(this.filters.ck_cover && this.filters.ck_prevyear);
        //BudgetGrossDeviation
        this.table.columns([17]).visible(this.filters.ck_budget && this.filters.scostamento && this.filters.ck_lordo);
        //BudgetDeviation
        this.table.columns([18]).visible(this.filters.ck_budget && this.filters.scostamento && !this.filters.ck_lordo);
        // BudgetGrossDelta
        this.table.columns([19]).visible(this.filters.ck_budget && !this.filters.scostamento && this.filters.ck_lordo);
        // BudgetDelta
        this.table.columns([20]).visible(this.filters.ck_budget && !this.filters.scostamento && !this.filters.ck_lordo);
        // PreviousGrossDeviation
        this.table.columns([21]).visible(this.filters.ck_prevyear && this.filters.scostamento && this.filters.ck_lordo);
        // PreviousDeviation
        this.table.columns([22]).visible(this.filters.ck_prevyear && this.filters.scostamento && !this.filters.ck_lordo);
        // PreviousGrossDelta
        this.table.columns([23]).visible(this.filters.ck_prevyear && !this.filters.scostamento && this.filters.ck_lordo);
        // PreviousDelta
        this.table.columns([24]).visible(this.filters.ck_prevyear && !this.filters.scostamento && !this.filters.ck_lordo);

        // Forzo a true così poi mi collassa tutte le righe
        this.expandAll = true;
        this.expandAllRows();

    }

    expandAll: boolean = false;
    expandAllRows() {
        this.expandAll = !this.expandAll;
        this.lastRowsExpanded = [];
        const trList = $('#ceTable tbody tr');
        trList.each((index: number, tr: any) => {
            const row = this.table.row(tr);

            if (!this.expandAll) {
                // This row is already open - close it
                row.child.hide();
                $(tr).removeClass('shown');
            } else {

                if (!this.lastRowsExpanded.includes(index))
                    this.lastRowsExpanded.push(index);

                // Open this row
                const data = row.data();
                if (data) {
                    row.child(this.format(row.data())).show();
                    $(tr).addClass('shown');
                }
            }
        });


    }

    /* Formatting function for row details - modify as you need */
    format(row: any) {
        if (!row) return;
        let subRows: string = '';

        row.Children.forEach(d => {

            const addCe = this.roleService.hasPermission('EditCE') ? '&nbsp;<a href="javascript:;" class="add_ce"><i class="fas fa-plus"></i></a>' : '';

            subRows += `<tr id="${d.Id}" data-row='${JSON.stringify(d)}' class="children">
                   <td></td>
                   <td class="text-right" data-categoryname="${d.Name}" data-toggle="tooltip" title="${d.Name}">
                        <div class="display-flex-space-between">
                            <div class="actions">
                                ${addCe}&nbsp;<a href="javascript:;" class="detail_ce"><i class="fas fa-search"></i></a>
                            </div> 
                            <span class="clickGraph">
                                ${d.Name}
                            </span>
                        </div>
                   </td>
                   <td class="text-right ${!this.table.column(2).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.CurrentValue, this.locale)}</td>
                   <td class="text-right ${!this.table.column(3).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.CurrentGrossValue, this.locale)}</td>
    
                   <td class="text-right ${!this.table.column(4).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.CurrentValuePercAbs, this.locale)}</td>
                   <td class="text-right ${!this.table.column(5).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.CurrentValuePercRel, this.locale)}</td>
    
                   <td class="text-right ${!this.table.column(6).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.CurrentValuePerCoperto, this.locale)}</td>
    
                   <td class="text-right ${!this.table.column(7).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.BudgetGrossValue, this.locale)}</td>
                   <td class="text-right ${!this.table.column(8).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.BudgetValue, this.locale)}</td>
    
                   <td class="text-right ${!this.table.column(9).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.CurrentValueBudgetPercAbs, this.locale)}</td>
                   <td class="text-right ${!this.table.column(10).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.CurrentValueBudgetPercRel, this.locale)}</td>
    
                   <td class="text-right ${!this.table.column(11).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.CurrentValueBudgetPerCoperto, this.locale)}</td>
                       
                   <td class="text-right ${!this.table.column(12).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.PreviousValue, this.locale)}</td>
                   <td class="text-right ${!this.table.column(13).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.PreviousGrossValue, this.locale)}</td>
    
                   <td class="text-right ${!this.table.column(14).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.PreviousValuePercAbs, this.locale)}</td>
                   <td class="text-right ${!this.table.column(15).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.PreviousValuePercRel, this.locale)}</td>
    
                   <td class="text-right ${!this.table.column(16).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.PreviousValuePerCoperto, this.locale)}</td>
    
                   <td class="text-right ${!this.table.column(17).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.BudgetGrossDeviation, this.locale)}</td>
                   <td class="text-right ${!this.table.column(18).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.BudgetDeviation, this.locale)}</td>
    
                   <td class="text-right ${!this.table.column(19).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.BudgetGrossDelta, this.locale)}</td>
                   <td class="text-right ${!this.table.column(20).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.BudgetDelta, this.locale)}</td>
    
                   <td class="text-right ${!this.table.column(21).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.PreviousGrossDeviation, this.locale)}</td>
                   <td class="text-right ${!this.table.column(22).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.PreviousDeviation, this.locale)}</td>
    
                   <td class="text-right ${!this.table.column(23).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.PreviousGrossDelta, this.locale)}</td>
                   <td class="text-right ${!this.table.column(24).visible() ? 'hidden' : ''}">${this.utility.formatNumber(d.PreviousDelta, this.locale)}</td>
                </tr>`;
        });

        return $(subRows);
    }

    async addCE(item: any) {
        this.dialog
            .open(ContoEconomicoEditComponent, {
                width: '800px',
                data: {
                    item: item,
                    IsTraining: this.training
                }
            }).afterClosed().subscribe((res: any) => {
                if (res) {
                    this.table.draw();
                    setTimeout(() => {
                        const element: any = document.getElementById(item.Id);
                        element.scrollIntoView();
                        this.ref.detectChanges();
                    }, 500);
                }
            });
    }

    async detailCE(item: any) {
        this.dialog
            .open(ContoEconomicoDetailsComponent, {
                data: {
                    item: item,
                    currentFilterCfg: this.currentFilterCfg,
                    locale: this.locale,
                    training: this.training
                },
                width: '1200px'
            }).afterClosed().subscribe((res: any) => {
                if (res) {
                    this.table.draw();
                    setTimeout(() => {
                        const element: any = document.getElementById(item.Id);
                        element.scrollIntoView();
                        this.ref.detectChanges();
                    }, 500);
                }
            });
    }

    exportAsXLSX(expandRows: boolean = false) {
        this.expandRows = expandRows === true;
        this.table.button(1).trigger();
    }

    exportAsPDF(PDFPreferences: any) {
        this.PDFPreferences = PDFPreferences;
        this.expandRows = PDFPreferences.explode;
        this.table.button(0).trigger();
    }

    performShowTotals(forcedValue: boolean = false) {
        this.showTotals = forcedValue ? forcedValue : !this.showTotals;
        if (!forcedValue) { // Fa già l'initDatatable dal SUbscription del filterService
            this.table.draw();
        }
    }
}
