import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FiltriService } from '@app/core/services/filtri.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { PDFService } from '@app/core/services/pdf.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import * as util from '@app/core/services/utilityfunctions';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import { TranslationService } from '@app/core/_base/layout';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { BehaviorSubject } from 'rxjs';
import { DdtEditComponent } from '@app/views/pages/magazzino/ddt/ddt-edit/ddt-edit.component';

declare var $: any;

@Component({
    selector: 'kt-link-ddt',
    templateUrl: './link-ddt.component.html',
    styleUrls: ['./link-ddt.component.scss']
})
export class LinkDDTComponent implements OnInit {

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    utility: any;
    locale!: string;
    availableDDTs: any;
    availableFilteredDDts: any;
    selectedDDTs: any;
    totSelectedDDT: number = 0;
    showOnlyExists: boolean = false;

    availableDdtTable: any;
    selectedDdtTable: any;
    PDFPreferences: any;

    html!: SafeHtml;

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        public magazzinoService: MagazzinoService,
        private translationService: TranslationService,
        private translate: TranslateService,
        private layoutUtilsService: LayoutUtilsService,
        public filtriService: FiltriService,
        private pdfService: PDFService,
        private domSanitizer: DomSanitizer,
        private gestioneMerciService: GestioneMerciService
    ) {
        this.utility = util;

        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });

        $.expr[':'].contains = function (a, i, m) {
            return $(a).text().toUpperCase()
                .indexOf(m[3].toUpperCase()) >= 0;
        };
    }

    async ngOnInit() {
        const supplierId = this.data.invoice.SupplierId;
        this.availableDDTs = [...this.data.invoice.AvailableDDT];
        this.availableFilteredDDts = [...this.data.invoice.AvailableDDT];
        this.selectedDDTs = [...this.data.invoice.LinkedDDT];
        this.prepareHtml(this.data.html);

        this.refreshTotSelectedDDT();
    }

    prepareHtml(html: string) {
        var parser = new DOMParser();
        var htmlDoc = parser.parseFromString(html, 'text/html');

        let html$ = $(htmlDoc);

        const $table = html$.find('#fattura-elettronica table.tbFoglio:contains(\'Cod. articolo\')').closest('table');

        // Prima imposto tutte le righe per i ddt esistenti
        this.availableDDTs.forEach((ddt: any, index: number) => {
            let $tr = $table.find(`td:contains("ddt"):contains(\'${ddt.Number}\')`).closest('tr');
            if ($tr && $tr.length > 0) {
                this.availableDDTs[index]['highlight'] = true;
                $tr.css('background-color', 'rgba(255, 221, 69, 1)');
                $tr.attr('id', ddt.Number);
            }
        });

        this.selectedDDTs.forEach((ddt: any, index: number) => {
            let $tr = $table.find(`td:contains("ddt"):contains(\'${ddt.Number}\')`).closest('tr');
            if ($tr && $tr.length > 0) {
                this.selectedDDTs[index]['highlight'] = true;
                $tr.css('background-color', 'rgba(255, 221, 69, 1)');
                $tr.attr('id', ddt.Number);
            }
        });

        // Poi seleziono tutti i DDT che non esistono e li coloro di rosso nella fattura.
        $table.find(`td:contains("ddt")`).each((index: number, ddt: any) => {
            const $tr = $(ddt).closest('tr');
            // Siccome l'id lo imposto solo se esiste un ddt
            const ddtNumber = $tr.attr('id');
            if (!ddtNumber) {
                $tr.css('background-color', 'rgba(253, 57, 122, 1)');
            }
        });

        // Scorro tutte le righe della fattura e segno i totali dei DDT
        let lastDdtRowIndex = 0;
        let lastDdt: string = '';
        let total: number = 0;
        $table.find(`tr`).each((index: number, tr: any) => {

            if ($(tr).find(`td:contains("ddt")`).length > 0) {                
                if (index > 0 && $(tr).find(`td:nth-child(2)`).text() !== lastDdt && lastDdt!== '') {
                    $table.find(`tr:nth-child(${lastDdtRowIndex}) td:nth-child(8)`).html(`<div class="import text-bold">${this.utility.formatNumber(total, this.locale, 2)}</div>`);
                    total = 0;
                }
                lastDdt = $(tr).find(`td:nth-child(2)`).text();
                lastDdtRowIndex = index;
            } else {
                // sommo
                const price = $(tr).find(`td:nth-child(8)`).text().trim();
                const descr = $(tr).find(`td:nth-child(2)`).text().trim();
                total += this.utility.parseNumber(price, this.locale, 2);
            }

        });

        if (total > 0) {
            $table.find(`tr:nth-child(${lastDdtRowIndex}) td:nth-child(8)`).html(`<div class="import text-bold">${this.utility.formatNumber(total, this.locale, 2)}</div>`);
        }

        this.html = this.domSanitizer.bypassSecurityTrustHtml(new XMLSerializer().serializeToString(html$[0]));
    }

    initDataTables() {
        if ($.fn.dataTable.isDataTable('#availableDdtTable')) {
            $('#availableDdtTable').DataTable().destroy();
        }

        if ($.fn.dataTable.isDataTable('#selectedDdtTable')) {
            $('#selectedDdtTable').DataTable().destroy();
        }

        setTimeout(() => {
            this.availableDdtTable = $('#availableDdtTable').DataTable({
                destroy: true,
                paging: false,
                searching: false,
                ordering: false,
                scrollY: 'calc(100vh - 450px)',
                scrollCollapse: true,
                autoWidth: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                columnDefs: [
                    { targets: [5], className: 'actions' }
                ],
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        //text: '<i class="fa fa-file-pdf text-danger"></i>',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)',
                            format: {
                                body: (data, row, column, node) => {

                                    if (data.indexOf('exportableTooltip') >= 0) {
                                        var parser = new DOMParser();
                                        var html = parser.parseFromString(data, 'text/html');
                                        let html$ = $(html);
                                        data = html$.find(`.exportableTooltip`).attr("title");
                                    }

                                    return data;
                                }
                            }
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, ['auto', 'auto', 'auto', '*'], '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            //doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
                            //doc.defaultStyle = docDefinition.defaultStyle;
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;

                            doc.styles = docDefinition.styles;
                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        //autoFilter: true,
                        //sheetName: 'Exported data',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)',
                            format: {
                                body: (data, row, column, node) => {

                                    if (data.indexOf('exportableTooltip') >= 0) {
                                        var parser = new DOMParser();
                                        var html = parser.parseFromString(data, 'text/html');
                                        let html$ = $(html);
                                        data = html$.find(`.exportableTooltip`).attr("title");
                                    }

                                    return '\0' + data;
                                }
                            }
                        }
                    }
                ]
            });

            this.selectedDdtTable = $('#selectedDdtTable').DataTable({
                destroy: true,
                paging: false,
                searching: false,
                ordering: false,
                scrollY: 'calc(100vh - 450px)',
                scrollCollapse: true,
                autoWidth: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                columnDefs: [
                    { targets: 0, className: 'actions' }
                ],
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        //text: '<i class="fa fa-file-pdf text-danger"></i>',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)',
                            format: {
                                body: (data, row, column, node) => {

                                    if (data.indexOf('exportableTooltip') >= 0) {
                                        var parser = new DOMParser();
                                        var html = parser.parseFromString(data, 'text/html');
                                        let html$ = $(html);
                                        data = html$.find(`.exportableTooltip`).attr("title");
                                    }

                                    return data;
                                }
                            }
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, ['auto', 'auto', 'auto', '*'], '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            //doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
                            //doc.defaultStyle = docDefinition.defaultStyle;
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;

                            doc.styles = docDefinition.styles;
                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        //autoFilter: true,
                        //sheetName: 'Exported data',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)',
                            format: {
                                body: (data, row, column, node) => {

                                    if (data.indexOf('exportableTooltip') >= 0) {
                                        var parser = new DOMParser();
                                        var html = parser.parseFromString(data, 'text/html');
                                        let html$ = $(html);
                                        data = html$.find(`.exportableTooltip`).attr("title");
                                    }

                                    return '\0' + data;
                                }
                            }
                        }
                    }
                ]
            });
        }, 100);
    }

    exportAsXLSX(table: string) {
        const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
        const _description: string = this.translate.instant('EXPORT_XLSX.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');
        const _success = this.translate.instant('EXPORT_XLSX.MESSAGE');
        const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');
        const _noButton = this.translate.instant('EXPORT_XLSX.NOBUTTON');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(expand => {
            this[table].button(1).trigger();
        });
    }

    exportAsPDF(table: string) {
        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
            explodeRows: this.translate.instant('EXPORT_PDF.EXPLODE_ROWS')
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {

            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('GESTIONE_FATTURE.TITLE'),
                    period: this.filtriService.getCurrentPeriod()
                };
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                };
                result['language'] = this.translationService.getSelectedLanguage();

                this.PDFPreferences = result;

                this[table].button(0).trigger();
            };
        });
    }

    rangeSelected: any;
    OnSelectRange(range: any) {
        this.rangeSelected = range;
        if (!range) {
            this.availableFilteredDDts = [...this.availableDDTs];
        } else {
            this.availableFilteredDDts = this.availableDDTs.filter(ddt => {
                const alreadySelected = this.selectedDDTs.findIndex(selectedDdt => selectedDdt.Id == ddt.Id);
                const start = range.dateStart.startOf('month');
                const end = range.dateEnd.endOf('month');
                const ddtdate = moment(ddt.Date);
                const res = moment(ddt.Date).isBetween(range.dateStart.startOf('month'), range.dateEnd.endOf('month'), null, '[]');
                return alreadySelected == -1 && moment(ddt.Date).isBetween(range.dateStart.startOf('month'), range.dateEnd.endOf('month'), null, '[]');
            });
        }
    }

    async link(force: boolean = false) {
        if (this.selectedDDTs || this.selectedDDTs.length > 0) {
            this.magazzinoService.linkDDTs(this.data.invoice.Id, this.selectedDDTs.map(ddt => ddt.Id))
                .toPromise()
                .then(async () => {
                    if (force) {
                        await this.magazzinoService.forceCheckDDT(this.data.invoice.Id, true, this.data.invoice.ForceCheckDDTNote).toPromise();
                        this.dialogRef.close({ success: true });
                    }
                    else {
                        await this.magazzinoService.forceCheckDDT(this.data.invoice.Id).toPromise();
                        this.dialogRef.close({ success: true });
                    }
                })
                .catch(() => {
                    this.dialogRef.close({ success: false });
                });
        }
    }

    selectAllDdt() {
        // Se è attivo il filtro per visualizzare solo quelli esistenti nella fattura allora filtro solo quelli
        this.selectedDDTs = this.selectedDDTs.concat([...this.availableFilteredDDts.filter((ddt: any) => this.showOnlyExists ? ddt.highlight : true)]);
        // Se è attivo il filtro per visualizzare solo quelli esistenti nella fattura allora prenso i rimanenti, altrimenti svuoto tutto
        this.availableFilteredDDts = this.showOnlyExists ? this.availableFilteredDDts.filter((ddt: any) => !ddt.highlight) : [];
        this.refreshTotSelectedDDT();
    }

    selectDdt(ddt: any) {
        const availableIndex = this.availableFilteredDDts.findIndex(avddt => avddt.Id == ddt.Id);
        if (availableIndex >= 0) {
            this.availableFilteredDDts.splice(availableIndex, 1);
            this.selectedDDTs.push(ddt);
            this.refreshTotSelectedDDT();
        }
    }

    deselectAllDdt() {
        this.availableFilteredDDts = this.availableFilteredDDts.concat(JSON.parse(JSON.stringify(this.selectedDDTs)));
        this.selectedDDTs = [];
        this.refreshTotSelectedDDT();
    }

    deselectDdt(ddt: any) {
        const selectedIndex = this.selectedDDTs.findIndex(avddt => avddt.Id == ddt.Id);
        if (selectedIndex >= 0) {
            this.selectedDDTs.splice(selectedIndex, 1);
            this.availableFilteredDDts.push(ddt);
            this.refreshTotSelectedDDT();
        }
    }

    refreshTotSelectedDDT() {
        this.totSelectedDDT = 0;
        this.selectedDDTs.forEach(ddt => {
            this.totSelectedDDT += ddt.Amount;
        });

        this.initDataTables();
    }

    async downloadXml() {
        this.loading$.next(true);
        this.pdfService.htmlToPdf(this.html['changingThisBreaksApplicationSecurity'], this.data.invoice.FileName).then(() => this.loading$.next(false));
    }

    addNewDdt() {
        const dialogRef = this.dialog.open(DdtEditComponent, {
            data: {
                item: {
                    Store: this.data.invoice.Store,
                    SupplierId: this.data.invoice.SupplierId
                }
            },
            width: '500px'
        });

        dialogRef.afterClosed().subscribe((res: any) => {
            setTimeout(() => {
                if (res) {
                    this.availableDDTs.push(res.item);
                    this.OnSelectRange(this.rangeSelected)
                    let message = this.translate.instant('GESTIONE_MERCI.SAVE_DIALOG.SUCCESS');
                    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                }
            }, 100);
        });
    }
}

