import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ElementRef, ViewChild, Input } from '@angular/core';
import { BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import { FiltriService } from '@app/core/services/filtri.service';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { TranslateService } from '@ngx-translate/core';
// Layout
import { LayoutConfigService } from '@app/core/_base/layout';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

// Material
import { MatDialog } from '@angular/material/dialog';
import { CategorieMerciEditComponent } from '@app/views/pages/anagrafiche/categorie-merci/categorie-merci-edit/categorie-merci-edit.component';
import * as _ from 'lodash';

import { RoleService } from '@app/core/_base/layout/services/role.service';
import * as util from '@app/core/services/utilityfunctions';

@Component({
    selector: 'kt-categorie-merci',
    templateUrl: './categorie-merci.component.html',
    styleUrls: ['./categorie-merci.component.scss', '../common-styles.scss']
})
export class CategorieMerciComponent implements OnInit, OnDestroy, AfterViewInit {

    lista: any;
    listaFiltered: any;
    listaSubscribtions!: Subscription;
    switchGroupSubcription!: Subscription;

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    dataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    table: any;
    currentFilterCfg: any;

    private searchInput!: ElementRef;
    @ViewChild('searchInput', { static: false }) set _set(content: ElementRef) {
        if (content) { // initially setter gets called with undefined
            this.searchInput = content;
        }
    }
    utility: any;
    plStructure: any;
    PDFPreferences: any;

    constructor(
        public dialog: MatDialog,
        private filtriService: FiltriService,
        private anagraficheService: AnagraficheService,
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private layoutConfigService: LayoutConfigService,
        private excelService: ExcelService,
        private pdfService: PDFService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private switchGroupService: SwitchGroupService,
        public roleService: RoleService
    ) {
        this.utility = util;
    }

    async ngOnInit() {
        document.body.classList.add('kt-aside--minimize');
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.refreshTable();
                }
            }
        );

        this.plStructure = await this.anagraficheService.getPLStructure().toPromise();

        this.refreshTable();
    }

    ngAfterViewInit() {
        // fromEvent(this.searchInput.nativeElement, 'keyup')
        // .pipe(
        // 	// tslint:disable-next-line:max-line-length
        // 	debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        // 	distinctUntilChanged(), // This operator will eliminate duplicate values
        // 	tap(() => {
        // 		this.filter(this.searchInput.nativeElement.value);
        // 	})
        // )
        // .subscribe();

        fromEvent(this.searchInput.nativeElement, 'keydown').subscribe(
            (event: any) => {
                if (event.keyCode === 13) {
                    this.filter(this.searchInput.nativeElement.value);
                }
            }
        );
    }

    ngOnDestroy() {
        if (this.listaSubscribtions) this.listaSubscribtions.unsubscribe();
        this.switchGroupSubcription.unsubscribe();
    }

    getTitle() {
        return this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.TITLE');
    }

    filter(searchInput: string) {
        if (searchInput) {
            this.listaFiltered = this.lista.filter((item: any) => {
                return (item.Name && item.Name.toLowerCase().includes(searchInput.toLocaleLowerCase())) ||
                    (item.Code && item.Code.toLowerCase().includes(searchInput.toLocaleLowerCase())) ||
                    (item.PLCode && item.PLCode.toLowerCase().includes(searchInput.toLocaleLowerCase())) ||
                    (item.InventoryCode && item.InventoryCode.toLowerCase().includes(searchInput.toLocaleLowerCase())) ||
                    (item.FBType && item.FBType.toLowerCase().includes(searchInput.toLocaleLowerCase()));
            });
        } else {
            this.listaFiltered = JSON.parse(JSON.stringify(this.lista));
        }

        this.ref.detectChanges();
    }

    refreshTable() {
        if (this.listaSubscribtions) this.listaSubscribtions.unsubscribe();
        this.loading$.next(true);
        this.filtriService.readOnly$.next(true);
        if ($.fn.dataTable.isDataTable('#table')) {
            $('#table').DataTable().destroy();
        }
        this.listaSubscribtions = this.anagraficheService.getEntity('GoodsCategory')
            .subscribe((ret: any) => {
                console.log('lista', ret);

                //ret.forEach(item => {
                //    item.LinkedProfittAndLossIds.forEach(pl => {
                //        pl.Name = this.plStructure.find((el: any) => el.Id == pl.Id).Name;
                //    });
                //});

                this.lista = ret;
                this.listaFiltered = JSON.parse(JSON.stringify(this.lista));
                this.loading$.next(false);
                this.filtriService.readOnly$.next(false);
                this.initTable();
            });
    }

    initTable() {
        setTimeout(() => {
            let dtOptions: any = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: true,
                scrollY: 'calc(100vh - 300px)',
                scrollCollapse: true,
                autoWidth: true,
                aaSorting: [[1, 'asc']],
                columnDefs: [
                    { targets: [1, 3], visible: false },
                    { targets: 5, orderable: false },
                    { targets: 6, orderable: false },
                    { targets: 7, orderable: false }
                ],
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        //text: '<i class="fa fa-file-pdf text-danger"></i>',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)',
                            format: {
                                body: function (data, row, column, node) {
                                    if (column >= 3 && column <= 5) {
                                        // f0c8 => square
                                        // f14a => square checked
                                        if (data.indexOf('mat-checkbox-checked') >= 0) {
                                            data = String.fromCharCode(parseInt('0x' + 'f14a'));
                                        } else {
                                            data = String.fromCharCode(parseInt('0x' + 'f0c8'));
                                        }
                                    }

                                    return data;

                                }
                            }
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, ['auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            //doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
                            //doc.defaultStyle = docDefinition.defaultStyle;
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;

                            doc.styles = docDefinition.styles;

                            doc.content[1].table.body.forEach((row, index) => {
                                if (index > 0) {
                                    row.forEach((field, col) => {
                                        if (col >= 3) {
                                            if (field.style.indexOf('Odd') >= 0) {
                                                field.style = 'fontAwesomeOdd';
                                            } else {
                                                field.style = 'fontAwesome';
                                            }
                                        }
                                    })

                                }
                            });
                            console.log(doc.content[1].table);
                        }
                    }
                ]
            };
            this.table = $('#table').DataTable(dtOptions);
            this.table.columns.adjust().draw();
        }, 100);
    }

    /** ACTIONS */
    deleteEntityItem(_item: any) {
        const _title: string = this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.ACTION_DELETE.TITLE');
        const _description: string = this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.ACTION_DELETE.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.ACTION_DELETE.WAIT_DESCRIPTION');
        const _deleteMessage = this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.ACTION_DELETE.MESSAGE');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe((res: any) => {
            if (!res) {
                return;
            }

            this.anagraficheService.deleteEntity('GoodsCategory', _item).subscribe(
                (ret: any) => {
                    this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
                    this.refreshTable();
                }, (error: any) => {
                    console.log(error);
                }
            )
        });
    }

    addEntityItem() {
        const newEntityItem = {
            Id: ''
        };
        this.editEntityItem(newEntityItem);
    }

    editEntityItem(item: any) {
        let saveMessageTranslateParam = 'ANAGRAFICHE.CATEGORIE_MERCI.ACTION_EDIT.';
        saveMessageTranslateParam += item.Id > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = item.Id.length > 0 ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(CategorieMerciEditComponent, { data: { item }, width: '600px' });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
            this.refreshTable();
        });
    }

    /******************************************************************************
      ______                       _    __   ___       _______   __
     |  ____|                     | |   \ \ / / |     / ____\ \ / /
     | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
     |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
     | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
     |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
           | |
           |_|
    ******************************************************************************/

    exportAsXLSX() {

        this.loading$.next(true);
        let xlsx: any[] = [];
        let merges: any[] = [];
        let i: number = 0;
        let sortedList = this.utility.sortList(this.listaFiltered, 'table');
        sortedList.forEach((item: any) => {
            //this.listaFiltered.forEach((item: any) => {
            xlsx.push({
                [`${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.NAME')}`]: item.Name ? item.Name.toUpperCase() : '',
                [`${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.CODE')}`]: item.Code ? item.Code.toUpperCase() : '',
                [`${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.PL_CODE')}`]: item.PLCode ? item.PLCode.toUpperCase() : '',
                [`${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.INVENTORY_CODE')}`]: item.InventoryCode ? item.InventoryCode.toUpperCase() : '',
                [`${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.FB_TYPE')}`]: item.FBType ? item.FBType.toUpperCase() : '',
                [`${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.IS_EXPORTABLE')}`]: item.IsExportable ? 'TRUE' : 'FALSE',
            });
            i++;
        });

        const columnsInfo = [
            { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { enabled: false, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { enabled: false, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } }
        ];

        this.loading$.next(false);
        this.excelService.exportAsExcelFile(xlsx, 'categories', merges, columnsInfo, 1, [], true);
    }

    /******************************************************************************
      ______                       _     _____  _____  ______
     |  ____|                     | |   |  __ \|  __ \|  ____|
     | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
     |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
     | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
     |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
                 | |
                 |_|
    ******************************************************************************/

    exportAsPDF() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('GESTIONE_FATTURE.TITLE'),
                    period: this.filtriService.getCurrentPeriod()
                };
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                };
                result['language'] = this.translationService.getSelectedLanguage();

                this.PDFPreferences = result;

                this.table.button(0).trigger();
            };
            //if (result) {
            //    result['header'] = {
            //        export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.getTitle() }),
            //        //period: ''
            //    }
            //    result['footer'] = {
            //        printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
            //        page: this.translate.instant('EXPORT_PDF.PAGE'),
            //        of: this.translate.instant('EXPORT_PDF.OF')
            //    }
            //    result['language'] = this.translationService.getSelectedLanguage();
            //    result['table'].headerRows = 1;
            //    this.pdfService.makePdf(result, this.getPDFTableBody(), ['*', 'auto', 'auto', 'auto'])
            //};
        });

    }

    getPDFTableBody() {
        let body: any = [];

        // aggiungo intestazione
        // totali Header
        body.push([
            { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.NAME')}`, style: 'tableHeaderStyle' },
            //{ alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.CODE')}`, style: 'tableHeaderStyle' },
            { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.PL_CODE')}`, style: 'tableHeaderStyle' },
            //{ alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.INVENTORY_CODE')}`, style: 'tableHeaderStyle' },
            { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.FB_TYPE')}`, style: 'tableHeaderStyle' },
            { alignment: 'center', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.IS_EXPORTABLE')}`, style: 'tableHeaderStyle' },
        ]);

        let i: number = 0;
        let sortedList = this.utility.sortList(this.listaFiltered, 'table');
        sortedList.forEach((item: any) => {
            //this.listaFiltered.forEach((item: any) => {
            body.push([
                { alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Name ? item.Name.toUpperCase() : ' ', style: 'tableHeaderStyle' },
                //{ alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Code ? item.Code.toUpperCase() : ' ', style: 'tableHeaderStyle' },
                { alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.PLCode ? item.PLCode.toUpperCase() : ' ', style: 'tableHeaderStyle' },
                //{ alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.InventoryCode ? item.InventoryCode.toUpperCase() : ' ', style: 'tableHeaderStyle' },
                { alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.FBType ? item.FBType.toUpperCase() : ' ', style: 'tableHeaderStyle' },
                { alignment: 'center', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.IsExportable ? 'TRUE' : 'FALSE', style: 'tableHeaderStyle' },
            ]);
            i++;
        });

        return body;
    }

}
