export  class ExternalCodeExample {
	beforeCodeTitle!: string;
	beforeCodeDescription!: string;
	htmlCode!: string;
	tsCode!: string;
	cssCode!: string;
	scssCode!: string;
	viewCode!: string;
	codeTitle!: string;
	afterCodeTitle!: string;
	afterCodeDescription!: string;
	sourceLink!: string;
	isCodeVisible: boolean = true;
	isExampleExpanded: boolean = false;
}
