// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable, of, map, catchError } from 'rxjs';
// Auth reducers and selectors
import { UsersService } from '@app/core/services/users..service';
import { AuthService } from '../_services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private userSrv: UsersService,
        private authService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        let currentUser = this.authService.currentUserValue;
        //if(!currentUser){
        //    currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
        //}
          
        if (currentUser) {
            return this.userSrv.getPermissions()
                .pipe(
                    map((res: any) => {
                        currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
                        currentUser.Permissions = res;
                        localStorage.setItem('currentUser', JSON.stringify(currentUser));
                        return true;
                    }),
                    catchError(() => {
                        this.router.navigateByUrl('/auth/login');
                        localStorage.removeItem('currentUser');
                        localStorage.removeItem('currentIndexGroup');
                        localStorage.removeItem('currentDB');
                        return of(false);
                    })
                );
        } else {
            this.router.navigateByUrl('/auth/login');
            localStorage.removeItem('currentUser');
            localStorage.removeItem('currentIndexGroup');
            localStorage.removeItem('currentDB');
            return of(false);
        }
    }
}
