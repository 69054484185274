import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, Subject } from 'rxjs';
import * as util from '@app/core/services/utilityfunctions';
import { TranslationService } from '@app/core/_base/layout';

@Component({
    selector: 'kt-map-structure',
    templateUrl: './map-structure.component.html',
    styleUrls: ['./map-structure.component.scss']
})
export class MapStructureComponent implements OnInit {
    changeVariationInput: Subject<any> = new Subject<any>();
    totDistribution: number = 0;
    utility: any;
    locale!: string;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        private translationService: TranslationService
    ) { 
        this.utility = util;
        this.translationService.performSwitchLanguage.subscribe((lang) => {
			this.locale = lang;
		});
        this.changeVariationInput
        .pipe(
            debounceTime(500)
        ).subscribe((variation: any) => {
            this.updateDistribution();
        })
    }

    ngOnInit(): void {
    }

    updateDistribution() { 
        this.totDistribution = 0;
        this.data.PlItems.forEach((item: any) => {
            this.totDistribution += parseFloat(item.Percentage);
        });
    }

    onChangeDistribution() {
        this.changeVariationInput.next(true);
    }

    calcValue(item: any) {
        const value = this.data.check.TotalAmount*this.utility.parseNumber(item.Percentage, this.locale)/100;
        return this.utility.formatNumber(value, this.locale, 2, true, true);
    }

}
