import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { API } from '@env/environment';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { Observable, of, BehaviorSubject, combineLatest } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ManutenzioneService {

    private _currentConfig: any;
    summaryUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClient,
        private switchGroupService: SwitchGroupService
    ) { }

    getImportTypes(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + `/api/Import/Types`, { headers: httpHeaders });
    }

    getImportStatus(t): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Action': 'upload',
            'Authorization': 'Bearer ' + currentLogin.access_token

        });
        const url = `/api/Import/LogStatus?type=${t}`;
        return this.http.get(currentLogin.endpointURL + url, { headers: httpHeaders })
            .pipe(
                map((result: any) => {

                    return result;
                })
            );
    }



    unlock(type): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Action': 'upload',
            'Authorization': 'Bearer ' + currentLogin.access_token

        });
        const url = `/api/Entity/UnlockImporting?type=${type}`;
        return this.http.get(currentLogin.endpointURL + url, { headers: httpHeaders })
            .pipe(
                map((result: any) => {

                    return result;
                })
            );
    }

    getCompanies(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + `/api/Import/Companies`, { headers: httpHeaders });
    }

    getAllFiles(type): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + `/api/Import/GetAllFiles?type=${type}`, { headers: httpHeaders });
    }

    getRejectedFiles(type): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + `/api/Import/getRejectedFiles?type=${type}`, { headers: httpHeaders });
    }

    importAllFiles(type): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + `/api/Import/ImportAllFiles?type=${type}`, { headers: httpHeaders });
    }

    importRejectedFiles(type): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + `/api/Import/importRejectedFiles?type=${type}`, { headers: httpHeaders });
    }




    getAuthors(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + `/api/Import/Authors`, { headers: httpHeaders });
    }

    importFile(fileType: string, file: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Import/Import/?type=${fileType}`;

        const formData = new FormData();
        formData.append('file', file);

        return this.http.post<any>(currentLogin.endpointURL + url, formData, { headers: httpHeaders });
    }

    importFromZucchetti(dateStart, dateEnd) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Import/ImportZucchetti/`;

        var obj = {
            Start: dateStart.format('YYYY-MM-DD'),
            End: dateEnd.format('YYYY-MM-DD')
        }

        return this.http.post<any>(currentLogin.endpointURL + url, obj, { headers: httpHeaders });
    }

    importFromZucchetti2(dateStart, dateEnd, companies) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Import/ImportZucchetti2/`;

        var obj = {
            Start: dateStart.format('YYYY-MM-DD'),
            End: dateEnd.format('YYYY-MM-DD'),
            Companies: companies
        }

        return this.http.post<any>(currentLogin.endpointURL + url, obj, { headers: httpHeaders });
    }

    importIPratico(dateStart, dateEnd, companies) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Import/ImportIPratico/`;

        var obj = {
            Start: dateStart.format('YYYY-MM-DD'),
            End: dateEnd.format('YYYY-MM-DD'),
            Companies: companies
        }

        return this.http.post<any>(currentLogin.endpointURL + url, obj, { headers: httpHeaders });
    }

    importILGLU(dateStart, dateEnd, companies) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Import/ImportILGLU/`;

        var obj = {
            Start: dateStart.format('YYYY-MM-DD'),
            End: dateEnd.format('YYYY-MM-DD'),
            Companies: companies
        }

        return this.http.post<any>(currentLogin.endpointURL + url, obj, { headers: httpHeaders });
    }

    importIPraticoProducts() {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Import/importIPraticoProducts/`;

        var obj = {};

        return this.http.post<any>(currentLogin.endpointURL + url, obj, { headers: httpHeaders });
    }

    importILGLUProducts() {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Import/importILGLUProducts/`;

        var obj = {};

        return this.http.post<any>(currentLogin.endpointURL + url, obj, { headers: httpHeaders });
    }

    

    importTilby(dateStart, dateEnd, companies) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Import/ImportTilby/`;

        var obj = {
            Start: dateStart.format('YYYY-MM-DD'),
            End: dateEnd.format('YYYY-MM-DD'),
            Companies: companies
        }

        return this.http.post<any>(currentLogin.endpointURL + url, obj, { headers: httpHeaders });
    }

    assignFCn(dateStart, dateEnd, companies, products) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Recipe/AssignFoodCost/`;

        var obj = {
            Start: dateStart.format('YYYY-MM-DD'),
            End: dateEnd.format('YYYY-MM-DD'),
            Stores: companies,
            Products: products
        }

        return this.http.post<any>(currentLogin.endpointURL + url, obj, { headers: httpHeaders });
    }

    compareTCPOS(dateStart, dateEnd, companies) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Import/TCPosCompare/`;

        var obj = {
            Start: dateStart.format('YYYY-MM-DD'),
            End: dateEnd.format('YYYY-MM-DD'),
            Stores: companies
        }
        const options: any = {
            headers: new HttpHeaders({ 'Action': 'download', 'Authorization': 'Bearer ' + currentLogin.access_token }),
            observe: 'response',
            responseType: 'blob'
        };

        return this.http.post<any>(currentLogin.endpointURL + url, obj, options);
    }



    callConsumption(dateStart, dateEnd, companies, products) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Consumption/Recalculate/`;

        var obj = {
            Start: dateStart.format('YYYY-MM-DD'),
            End: dateEnd.format('YYYY-MM-DD'),
            Stores: companies,
            Products: products
        }

        return this.http.post<any>(currentLogin.endpointURL + url, obj, { headers: httpHeaders });
    }

    unlockImporting(type: string) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Entity/UnlockImporting?type=${type}`;

        console.log(currentLogin.endpointURL + url)

        return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
    }

    callStock(dateStart, dateEnd, companies) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Stock/Recalculate/`;

        var obj = {

            Stores: companies
        }

        return this.http.post<any>(currentLogin.endpointURL + url, obj, { headers: httpHeaders });
    }

    getImportedFiles(body: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Import/Files`;
        return this.http.post<any>(currentLogin.endpointURL + url, body, { headers: httpHeaders });
    }

    getImportedErrors(body: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Import/ErrorLog`;
        return this.http.post<any>(currentLogin.endpointURL + url, body, { headers: httpHeaders });
    }

    getImportLog(importId: string): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Import/Logs?logId=${importId}`;
        return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
    }

    getLogFile(type: string = 'General', date: string = '') {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const dateParam = date ? `&date=${date}` : '';

        const url = `/api/Admin/LogFile?type=${type}${dateParam}`;
        return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
    }

    downloadLog(type: string = 'General', date: string = '') {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpOptions: any = {
            headers: new HttpHeaders({
                'Content-Type': 'application/zip',
                'Authorization': 'Bearer ' + currentLogin.access_token,
                'Accept': 'application/zip, application/xhtml+xml, */*',
            }),
            responseType: 'arraybuffer'
        };

        const dateParam = date ? `&date=${date}` : '';

        const url = `/api/Admin/DownloadLog?type=${type}${dateParam}`;
        return this.http.get<any>(currentLogin.endpointURL + url, httpOptions);
    }

}
