import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { TranslateService } from '@ngx-translate/core';
import { ScadenziarioService } from '../scadenziario.service';
import * as util from '@app/core/services/utilityfunctions';
import moment from 'moment';

@Component({
    selector: 'kt-scadenziario-pay',
    templateUrl: './scadenziario-pay.component.html',
    styleUrls: ['./scadenziario-pay.component.scss']
})
export class ScadenziarioPayComponent implements OnInit {

    @Output() onLoading = new EventEmitter<boolean>(false);
    bankOperationsList: any;
    scadenza: any;
    locale!: string;
    isPartial: boolean = false;
    amount: number = 0;
    partialAmount: number = 0;
    utility: any;
    paymentDate: any;
    nextPaymentDate: any;
    bankOperation: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public staticCollectionsService: StaticCollectionsService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private layoutUtilsService: LayoutUtilsService,
        private ref: ChangeDetectorRef,
        private scadenziarioService: ScadenziarioService,
        public dialogRef: MatDialogRef<any>
    ) {
        this.utility = util;
    }

    ngOnInit(): void {
        this.bankOperationsList = this.data.bankOperationsList;
        this.scadenza = this.data.scadenza;
        this.locale = this.data.locale;

        setTimeout(() => {
            this.bankOperation = this.scadenza.OperationId;
        }, 100);        

        this.amount = this.scadenza.Credit > 0 ? this.scadenza.Credit : this.scadenza.Debit;
    }

    getTitle(): string {
        return this.translate.instant('SCADENZIARIO.PAY_DIALOG.TITLE');
    }


    isCustomer(type: number) {
        return (type & 2) !== 0;
    }

    checkDisabled(): boolean {
        return !this.paymentDate || !this.bankOperation || (this.isPartial && (!this.partialAmount || !this.nextPaymentDate));
    }

    onSubmit() {
        const _title: string = this.translate.instant('SCADENZIARIO.PAY_DIALOG.TITLE');
        const _description: string = this.translate.instant('SCADENZIARIO.PAY_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('SCADENZIARIO.PAY_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            if (confirm) {
                this.onLoading.emit(true);
                const payload = {
                    Id: this.scadenza.Id,
                    PaymentDate: moment(this.paymentDate.startDate).format('YYYY-MM-DDT00:00:00.000Z'),
                    PayedAmount: this.isPartial ? Math.abs(this.utility.parseNumber(this.partialAmount, this.locale)) : Math.abs(this.amount),
                    NextScheduleDate: this.isPartial && this.nextPaymentDate.startDate? moment(this.nextPaymentDate.startDate).format('YYYY-MM-DDT00:00:00.000Z') : '',
                    OperationId: this.bankOperation
                };
                this.scadenziarioService.payScadenza(payload).toPromise()
                    .then(() => {
                        const message = this.translate.instant('SCADENZIARIO.PAY_DIALOG.SUCCESS');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                        this.dialogRef.close({ success: true });
                    })
                    .catch(() => {
                        const message = this.translate.instant('SCADENZIARIO.PAY_DIALOG.ERROR');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                    })
                    .finally(() => this.onLoading.emit(false));

            }
        })
    }

}
