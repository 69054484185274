import { Component, Input, OnInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContoEconomicoDetailsComponent } from '../../conto-economico-details/conto-economico-details.component';
import { ContoEconomicoEditComponent } from '../../conto-economico-edit/conto-economico-edit.component';
import * as util from '@app/core/services/utilityfunctions';
import { SwitchGroupService, TranslationService } from '@app/core/_base/layout';
import { TranslateService } from '@ngx-translate/core';
import { FiltriService } from '@app/core/services/filtri.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import moment from 'moment';
import * as XLSX from '@sheet/core';
declare var window: any;;

@Component({
    selector: 'kt-daily-view',
    templateUrl: './daily-view.component.html',
    styleUrls: ['./daily-view.component.scss']
})
export class DailyViewComponent implements OnInit {
    view: string = 'DAILY';
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    @Input() filters: any = {};
    @Output() onClickGraph = new EventEmitter<any>();
    locale!: string;
    currentFilterCfg: any = {};
    currentYear!: number;
    previousYear!: number;
    utility: any;
    lastRowsExpanded: any = [];

    filtriServiceSubscription!: Subscription;
    totals: any;

    public monthNames: string[] = [
        this.translate.instant('monthNames.1'),
        this.translate.instant('monthNames.2'),
        this.translate.instant('monthNames.3'),
        this.translate.instant('monthNames.4'),
        this.translate.instant('monthNames.5'),
        this.translate.instant('monthNames.6'),
        this.translate.instant('monthNames.7'),
        this.translate.instant('monthNames.8'),
        this.translate.instant('monthNames.9'),
        this.translate.instant('monthNames.10'),
        this.translate.instant('monthNames.11'),
        this.translate.instant('monthNames.12')
    ];

    constructor(
        private ref: ChangeDetectorRef,
        public dialog: MatDialog,
        private switchGroupService: SwitchGroupService,
        private translate: TranslateService,
        public filtriService: FiltriService,
        private translationService: TranslationService
    ) {
        this.utility = util;

        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
            moment.locale(this.locale)
        });
    }

    ngOnDestroy() {
        this.filtriServiceSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.checkScroll();
        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            async (filterConfig: any) => {
                // Se ho un filtro memorizzato lo recupero
                if (localStorage.getItem('currentFilterCfg')) {
                    try {
                        this.currentFilterCfg = JSON.parse(localStorage.getItem('currentFilterCfg') || '');
                        localStorage.removeItem('currentFilterCfg');
                    } catch (error) {
                        localStorage.removeItem('currentFilterCfg');
                        return;
                    }
                    //return;
                } else {
                    if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) {
                        return;
                    };
                    if (filterConfig.constructor !== Object) {
                        return;
                    };

                    if (JSON.stringify(filterConfig) == JSON.stringify(this.currentFilterCfg)) {
                        return;
                    }

                    this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));
                }

                //
                if (this.filters.filterCompanies && this.filters.filterCompanies.length > 0) {
                    this.currentFilterCfg['Companies'] = this.filters.filterCompanies;
                } else {
                    delete this.currentFilterCfg.Companies;
                }

                if (this.filters.filtercostCenters && this.filters.filtercostCenters.length > 0) {
                    this.currentFilterCfg['CostCenterIds'] = this.filters.filtercostCenters;
                } else {
                    delete this.currentFilterCfg.CostCenterIds;
                }

                //if (!this.currentFilterCfg.Period && !this.currentFilterCfg.DateFilter) {
                this.currentYear = this.getYearByFilter();
                this.previousYear = this.currentYear - 1;
                this.currentFilterCfg.DateFilter = { Start: `${this.currentYear}-01-01`, End: `${this.currentYear}-12-31` };
                //}

                if (!this.table) {
                    this.initDataTable();
                } else {
                    //// Trick per cambiare l'anno nell'intestazione della colonna
                    //this.table.columns().every((index: number) => {
                    //    const header = this.table.columns([index]).header().to$().text();
                    //    const checkArrayCols = header.split(' ');
                    //    // Se sono dalla colonna 2 in avant
                    //    // Se l'intestazione è composta da 2 parole
                    //    // Se la seconda parola è un numero
                    //    if (index > 1 && checkArrayCols && checkArrayCols.length > 1 && !isNaN(checkArrayCols[1])) {
                    //        checkArrayCols[1] = this.getYearByFilter()
                    //        this.table.columns([index]).header().to$().text(checkArrayCols.join(' '));
                    //    }

                    //})
                    this.table.draw();
                }

            }
        );

        $(window).on('resize', function () {
            setTimeout(() => {
                if (this.table) this.syncFixedHeaderColumns(this.table);
            }, 1000);
        });
    }

    checkScroll() {
        const wHeight: any = $(window).height();
        const tHeight: any = $("#ceTable").height();
        if (wHeight < tHeight) {
            $("#scrollUp").show();
        } else {
            $("#scrollUp").hide();
        }
    }

    getYearByFilter() {
        let currentYear = moment().year();
        if (this.filtriService.filterConfig.Period) {
            switch (this.filtriService.filterConfig.Period.toLowerCase()) {
                case 'onlyyesterday':
                case 'yesterday':
                    currentYear = moment().subtract(1, 'day').year();
                    break;
                case 'year':
                    currentYear = moment().startOf('year').year();
                    break;
                case 'month':
                    currentYear = moment().subtract(1, 'month').year();
                    break;
                case 'week':
                    currentYear = moment().startOf('week').year();
                    break;
            }
        } else if (this.filtriService.filterConfig.DateFilter) {
            currentYear = moment(this.filtriService.filterConfig.DateFilter.Start, 'YYYY-MM-DD').year();
        }

        return currentYear;

        //return moment().startOf('year').year();
    }

    clearFilters(actionFilter: boolean = true, redrawTable: boolean = true) {
        this.filtriService.clearFilters$.next(actionFilter);
        const currentYear = this.getYearByFilter();
        this.currentFilterCfg = {
            DateFilter: { Start: `${currentYear}-01-01`, End: `${currentYear}-12-31` },
            fromLocal: true,
            TimeInterval: 'none',
            DayOfWeek: 0
        };
        this.lastRowsExpanded = [];
        this.ref.detectChanges();
        this.filters.ck_prevyear
        if (redrawTable) this.table.draw();
    }

    training: number = 0;
    showTraining(show: boolean = false) {
        this.training = show ? 2 : 0;
        // 0/3 fa vedere tutto insieme
        // 1 Non fa vedere training
        // 2 Fa vedere solo training
        this.table.draw();
    }

    generateColumnDefs(): any[] {
        const numMonths = 12;
        const columns: any = [
            // Definizione per la colonna del nome del giorno
            {
                targets: 0,
                data: 'Name',
                name: 'Name',
                render: (data, type, p) => `${p.Day}`,
                className: 'dayCol'
            }
        ];

        // Colonne per i totali
        let totalStartIndex = 0;
        columns.push(
            {
                targets: ++totalStartIndex,
                data: 'Columns',
                name: 'Columns',
                render: (data, type, p) => this.getAmount(data ? data[0] : 0, false),
                className: 'text-right dayCol'
            },
            {
                targets: ++totalStartIndex,
                data: 'Columns',
                name: 'Columns',
                render: (data, type, p) => this.getCover(data ? data[0] : 0, false),
                className: 'text-right dayCol',
                visible: this.filters.ck_showCover
            },
            {
                targets: ++totalStartIndex,
                data: 'Columns',
                name: 'Columns',
                render: (data, type, p) => this.getAvg(data ? data[0] : 0, false),
                className: 'endGroupCol text-right dayCol'
            },
            {
                targets: ++totalStartIndex,
                data: 'Columns',
                name: 'Columns',
                render: (data, type, p) => this.getAmount(data ? data[0] : 0, true),
                className: 'text-right dayCol',
                visible: this.filters.ck_prevyear
            },
            {
                targets: ++totalStartIndex,
                data: 'Columns',
                name: 'Columns',
                render: (data, type, p) => this.getCover(data ? data[0] : 0, true),
                className: 'text-right dayCol',
                visible: this.filters.ck_prevyear && this.filters.ck_showCover
            },
            {
                targets: ++totalStartIndex,
                data: 'Columns',
                name: 'Columns',
                render: (data, type, p) => this.getAvg(data ? data[0] : 0, true),
                className: 'endGroupCol text-right dayCol',
                visible: this.filters.ck_prevyear
            }
        );

        // Genera colonne per ogni mese
        for (let i = 0; i < numMonths; i++) {
            columns.push(
                {
                    targets: i * 6 + totalStartIndex + 1,
                    data: 'Columns',
                    name: 'Columns',
                    render: (data, type, p) => {
                        return this.getAmount(data[i + 1])
                    },
                    className: 'text-right'
                },
                {
                    targets: i * 6 + totalStartIndex + 2,
                    data: 'Columns',
                    name: 'Columns',
                    render: (data, type, p) => this.getCover(data[i + 1]),
                    className: 'text-right',
                    visible: this.filters.ck_showCover
                },
                {
                    targets: i * 6 + totalStartIndex + 3,
                    data: 'Columns',
                    name: 'Columns',
                    render: (data, type, p) => this.getAvg(data[i + 1]),
                    className: 'endGroupCol text-right'
                },
                {
                    targets: i * 6 + totalStartIndex + 4,
                    data: 'Columns',
                    name: 'Columns',
                    render: (data, type, p) => this.getAmount(data[i + 1], true),
                    className: 'text-right',
                    visible: this.filters.ck_prevyear
                },
                {
                    targets: i * 6 + totalStartIndex + 5,
                    data: 'Columns',
                    name: 'Columns',
                    render: (data, type, p) => this.getCover(data[i + 1], true),
                    className: 'text-right',
                    visible: this.filters.ck_prevyear && this.filters.ck_showCover
                },
                {
                    targets: i * 6 + totalStartIndex + 6,
                    data: 'Columns',
                    name: 'Columns',
                    render: (data, type, p) => this.getAvg(data[i + 1], true),
                    className: 'endGroupCol text-right',
                    visible: this.filters.ck_prevyear
                }
            );
        }

        // Colonne di default per evitare avvisi su campi null
        columns.push({
            defaultContent: '-',
            targets: '_all'
        });

        console.log(columns)

        return columns;
    }

    // Restituisce il numero di colonne fisse dei totali in base ai filtri
    getFixedCols() {
        let colsSpan = this.filters.ck_prevyear ? 6 : 3;
        colsSpan = this.filters.ck_showCover ? colsSpan : (this.filters.ck_prevyear ? colsSpan - 2 : colsSpan - 1);
        return colsSpan;
    }

    // Restituisce il colspan dell'header anno in base ai filtri
    getColsSpanYears() {
        return this.filters.ck_showCover ? 3 : 2;
    }

    table: any;
    PDFPreferences: any;
    initDataTable() {
        if ($.fn.dataTable.isDataTable('#ceTable')) {
            $('#ceTable').DataTable().destroy();
        }


        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();

            const dtOptions: any = {
                createdRow: function (row, data, dataIndex) {
                    if (data.Day == 'Total') {
                        $(row).addClass('totalRow');
                    }
                },
                drawCallback: () => {
                    setTimeout(() => {
                        this.syncFixedHeaderColumns(this.table);
                    }, 100);
                },
                destroy: true,
                paging: false,
                searching: true,
                dom: 'lrtip',
                ordering: false,
                scrollX: true,
                scrollY: 'calc(100vh - 300px)',
                scrollCollapse: true,
                autoWidth: true,
                order: [],
                processing: true,
                serverSide: true,
                fixedColumns: {
                    leftColumns: this.getFixedCols() + 1,
                },
                ajax: {
                    url: currentLogin.endpointURL + "/api/PL/Daily",
                    type: "POST",
                    data: (d: any) => {
                        d.filters = this.currentFilterCfg;
                        d.filters.Training = this.training;
                        d.Companies = this.currentFilterCfg.Companies || [];
                        d.CostCenterIds = this.currentFilterCfg.CostCenterIds || [];
                        d.DateFilter = this.currentFilterCfg.DateFilter || {
                            Start: `${this.filters.selecterYear}-01-01`,
                            End: `${this.filters.selecterYear}-12-31`
                        }
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        //return response.Rows;
                        // Invertire response.Rows[0] con response.Rows[31] per avere i totali in cima
                        const rows = response.Rows;
                        const totals = rows.pop();
                        //rows.unshift(totals);

                        this.totals = totals.Columns;

                        this.ref.detectChanges();

                        //rows[0].Day = this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.TOTAL');

                        // Inserisco i totali nelle prime colonne per avere i totali a sinistra
                        // quindi prendo le ultime 2 colonne e le metto all'inizio
                        rows.forEach(row => {
                            row.Columns.unshift(row.Columns.pop());
                        });

                        return rows;
                    }
                },
                columnDefs: this.generateColumnDefs(),
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)'
                        },
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        //autoFilter: true,
                        //sheetName: 'Exported data',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)',
                            format: {
                                body: (data, row, column, node) => {
                                    if (data.indexOf('exportText') >= 0) {
                                        var parser = new DOMParser();
                                        var htmlDoc = parser.parseFromString(data, 'text/html');
                                        const htmlValue = $(htmlDoc).find('.exportText').text();
                                        // dalla colonna 1 in avanti formatto
                                        const value = column >= 1 ? this.utility.parseNumber(htmlValue, this.locale) : htmlValue;
                                        return value;
                                    } else {
                                        // dalla colonna 1 in avanti formatto
                                        const value = column >= 1 ? this.utility.parseNumber(data, this.locale) : data;
                                        return value;
                                    }
                                }
                            },
                        },
                        customize: async (xlsx: any) => {

                            XLSX.SSF.setlocale("de-DE");

                            var sSh = xlsx.xl['styles.xml'];
                            var lastXfIndex = $('cellXfs xf', sSh).length - 1;
                            // Bold Text - Yellow
                            var s1 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"0\" fontId=\"2\" fillId=\"6\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" />';
                            // Bold Text - Yellow - Alignment Right
                            var s2 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"4\" fontId=\"2\" fillId=\"6\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" xfId="0" applyNumberFormat="1"><alignment horizontal=\"right\"/></xf>';
                            // Bold Text - Alignment Right
                            var s3 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"4\" fontId=\"2\" fillId=\"0\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" xfId="0" applyNumberFormat="1"><alignment horizontal=\"right\"/></xf>';
                            // Esempio di definizione dello stile con allineamento al centro
                            var s4 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"0\" fontId=\"2\" fillId=\"6\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" xfId="0" applyAlignment="1"><alignment horizontal=\"center\"/></xf>';

                            sSh.childNodes[0].childNodes[5].innerHTML = sSh.childNodes[0].childNodes[5].innerHTML + s1 + s2 + s3 + s4;
                            const fillYellow = '<fill xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\"><patternFill patternType=\"solid\"><fgColor rgb=\"FFF1E8C1\" /><bgColor indexed=\"64\" /></patternFill></fill>';
                            sSh.childNodes[0].childNodes[2].innerHTML = sSh.childNodes[0].childNodes[2].innerHTML + fillYellow;
                            var boldTextYellowFillIndex = lastXfIndex + 1;
                            var boldTextYellowFillAlignRightIndex = lastXfIndex + 2;
                            var boldTextAlignRightIndex = lastXfIndex + 3;
                            var centeredTextIndex = lastXfIndex + 4;

                            // Get sheet.
                            var sheet = xlsx.xl.worksheets['sheet1.xml'];

                            // Get a clone of the sheet data.        
                            var sheetData = $('sheetData', sheet).clone();

                            // Clear the current sheet data for appending rows.
                            $('sheetData', sheet).empty();

                            var rowCount = 1;

                            const numToAlpha = (num: number) => {
                                var alpha = '';
                                for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
                                    alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
                                }
                                return alpha;
                            }

                            const changeRowIndex = (rowContent, newRowIndex) => {
                                // Modifica il numero di riga nel tag <row>
                                var updatedRow = rowContent.replace(/<row r="\d+"/, `<row r="${newRowIndex}"`);
                                // Modifica tutti gli indici di riga nei tag <c>
                                updatedRow = updatedRow.replace(/ r="([A-Z]+)\d+"/g, ` r="$1${newRowIndex}"`);
                                return updatedRow;
                            }

                            let totalsColumns = this.filters.ck_prevyear ? 6 : 3;
                            totalsColumns = this.filters.ck_showCover ? totalsColumns : totalsColumns - 1;

                            const isNumeric = (value: string): boolean => {
                                // Controlla se il valore è un numero (considerando anche le virgole e i punti)
                                return !isNaN(parseFloat(value.replace(/\./g, '').replace(',', '.')));
                            }

                            const convertToNumeric = (rowContent: string): string => {
                                // Match all inlineStr cells
                                return rowContent.replace(/<c t="inlineStr" r="([A-Z]+\d+)" s="\d+"><is><t[^>]*>([^<]+)<\/t><\/is><\/c>/g,
                                    (match, cellReference, cellValue) => {
                                        // Check if the cellValue is a number
                                        if (isNumeric(cellValue)) {
                                            // Remove commas for numeric conversion and convert to a valid number format
                                            const numericValue = cellValue.replace(/\./g, '').replace(',', '.');
                                            return `<c r="${cellReference}" s="64"><v>${numericValue}</v></c>`;
                                        }
                                        // If not numeric, return the original cell
                                        return match;
                                    }
                                );
                            };

                            // Itereate each row in the sheet data.
                            $(sheetData).children().each((index, element) => {

                                var row: any = $(element);
                                row = row[0].outerHTML;

                                // in 0, 1 c'è header + intestazione
                                if (index > 1) {

                                    // Get row
                                    var row: any = $(element);

                                    // Set the Excel row attr to the current Excel row count.
                                    row.attr('r', rowCount);

                                    const columnsLength = row.children().length;

                                    // Iterate each cell in the row to change the row number.
                                    row.children().each((index, element) => {
                                        var cell = $(element);
                                        // Set each cell's row value.
                                        var rc: any = cell.attr('r');
                                        rc = rc.replace(/\d+$/, "") + rowCount;
                                        cell.attr('r', rc);

                                        // Verifico se il valore è un numero, nel caso lo imposto come stile 64
                                        if (!cell.attr('s')) {
                                            var cellValue = cell.find('v').text();
                                            if (isNumeric(cellValue)) {
                                                cell.attr('s', 64);
                                            }
                                        }
                                    });

                                    // Get the row HTML and append to sheetData.
                                    row = row[0].outerHTML;
                                    $('sheetData', sheet).append(row);
                                    rowCount++;

                                    // Aggiungo 1 riga con il mese e 1 riga con l'anno
                                } else if (index == 1) {

                                    // Usa l'indice del nuovo stile quando crei le celle per i mesi
                                    var rowMonths = '<row r="' + rowCount + '">';
                                    rowMonths += '<c r="A' + rowCount + '" s="' + boldTextYellowFillIndex + '"></c>';

                                    for (let i = 0; i < 12; i++) {
                                        const monthName = this.translate.instant(`monthNames.${i + 1}`);
                                        const startCol = numToAlpha(i * totalsColumns + 1);
                                        const endCol = numToAlpha((i + 1) * totalsColumns);
                                        rowMonths += `<c t="inlineStr" r="${startCol}${rowCount}" s="${boldTextYellowFillIndex}"><is><t xml:space="preserve">${monthName}</t></is></c>`;

                                        // Aggiungi celle vuote necessarie per il merge
                                        for (let j = 1; j < totalsColumns; j++) {
                                            rowMonths += '<c r="' + numToAlpha(i * totalsColumns + 1 + j) + rowCount + '" s="' + boldTextYellowFillIndex + '"></c>';
                                        }

                                        // Aggiungi l'elemento mergeCell per unire le celle
                                        $('mergeCells', sheet).append('<mergeCell ref="' + startCol + rowCount + ':' + endCol + rowCount + '"/>');
                                    }
                                    rowMonths += '</row>';
                                    $('sheetData', sheet).append(rowMonths);
                                    rowCount++;

                                    var rowYears = '<row r="' + rowCount + '">';
                                    rowYears += '<c r="A' + rowCount + '"  s="' + boldTextYellowFillIndex + '"></c>';  // Cella vuota senza inlineStr

                                    // Se ck_prevyear è false, mostra solo l'anno corrente con colspan = totalsColumns
                                    if (!this.filters.ck_prevyear) {
                                        for (let i = 0; i < 12; i++) {
                                            const startCol = numToAlpha(i * totalsColumns + 1);
                                            const endCol = numToAlpha((i + 1) * totalsColumns);

                                            rowYears += `<c t="inlineStr" r="${startCol + rowCount}" s="${boldTextYellowFillIndex}"><is><t xml:space="preserve">${this.currentYear}</t></is></c>`;

                                            // Aggiungi celle vuote necessarie per il merge, senza inlineStr
                                            for (let j = 1; j < totalsColumns; j++) {
                                                rowYears += `<c r="${numToAlpha(i * totalsColumns + 1 + j) + rowCount}" s="${boldTextYellowFillIndex}"></c>`;
                                            }

                                            // Aggiungi l'elemento mergeCell per unire le celle
                                            $('mergeCells', sheet).append(`<mergeCell ref="${startCol + rowCount}:${endCol + rowCount}"/>`);
                                        }
                                    } else {
                                        // Se ck_prevyear è true, mostra sia l'anno corrente che quello precedente con colspan = totalsColumns / 2
                                        const halfColumns = Math.floor(totalsColumns / 2);

                                        for (let i = 0; i < 12; i++) {
                                            const startColCurrent = numToAlpha(i * totalsColumns + 1);
                                            const endColCurrent = numToAlpha(i * totalsColumns + halfColumns);
                                            rowYears += `<c t="inlineStr" r="${startColCurrent + rowCount}" s="${boldTextYellowFillIndex}"><is><t xml:space="preserve">${this.currentYear}</t></is></c>`;

                                            // Aggiungi celle vuote per il merge dell'anno corrente, senza inlineStr
                                            for (let j = 1; j < halfColumns; j++) {
                                                rowYears += `<c r="${numToAlpha(i * totalsColumns + 1 + j) + rowCount}" s="${boldTextYellowFillIndex}"></c>`;
                                            }

                                            // Aggiungi l'elemento mergeCell per unire le celle dell'anno corrente
                                            $('mergeCells', sheet).append(`<mergeCell ref="${startColCurrent + rowCount}:${endColCurrent + rowCount}"/>`);

                                            const startColPrev = numToAlpha(i * totalsColumns + halfColumns + 1);
                                            const endColPrev = numToAlpha((i + 1) * totalsColumns);
                                            rowYears += `<c t="inlineStr" r="${startColPrev + rowCount}" s="${boldTextYellowFillIndex}"><is><t xml:space="preserve">${this.previousYear}</t></is></c>`;

                                            // Aggiungi celle vuote per il merge dell'anno precedente, senza inlineStr
                                            for (let j = halfColumns + 1; j < totalsColumns; j++) {
                                                rowYears += `<c r="${numToAlpha(i * totalsColumns + 1 + j) + rowCount}" s="${boldTextYellowFillIndex}"></c>`;
                                            }

                                            // Aggiungi l'elemento mergeCell per unire le celle dell'anno precedente
                                            $('mergeCells', sheet).append(`<mergeCell ref="${startColPrev + rowCount}:${endColPrev + rowCount}"/>`);
                                        }
                                    }

                                    rowYears += '</row>';
                                    $('sheetData', sheet).append(rowYears);
                                    rowCount++;

                                    // Aggiungo le intestazioni delle colonne

                                    var rowIntestazioni = `<row r="${rowCount}">`;
                                    rowIntestazioni += `<c r="A${rowCount}" s="${boldTextYellowFillAlignRightIndex}"></c>`;  // Cella vuota senza inlineStr

                                    if (totalsColumns == 6) {
                                        rowIntestazioni += `<c t="inlineStr" r="B${rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.AMOUNT')}</t></is></c>`;
                                        rowIntestazioni += `<c t="inlineStr" r="C${rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.COVERS')}</t></is></c>`;
                                        rowIntestazioni += `<c t="inlineStr" r="D${rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.AVG')}</t></is></c>`;
                                        rowIntestazioni += `<c t="inlineStr" r="E${rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.AMOUNT')}</t></is></c>`;
                                        rowIntestazioni += `<c t="inlineStr" r="F${rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.COVERS')}</t></is></c>`;
                                        rowIntestazioni += `<c t="inlineStr" r="G${rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.AVG')}</t></is></c>`;
                                    } else if (totalsColumns == 3) {
                                        rowIntestazioni += `<c t="inlineStr" r="B${rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.AMOUNT')}</t></is></c>`;
                                        rowIntestazioni += `<c t="inlineStr" r="C${rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.COVERS')}</t></is></c>`;
                                        rowIntestazioni += `<c t="inlineStr" r="D${rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.AVG')}</t></is></c>`;
                                    } else {
                                        rowIntestazioni += `<c t="inlineStr" r="B${rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.AMOUNT')}</t></is></c>`;
                                        rowIntestazioni += `<c t="inlineStr" r="C${rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.COVERS')}</t></is></c>`;
                                    }

                                    for (let i = 0; i < 12; i++) {
                                        const startCol = i * totalsColumns + totalsColumns;

                                        if (totalsColumns == 6) {
                                            rowIntestazioni += `<c t="inlineStr" r="${numToAlpha(startCol + 1) + rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.AMOUNT')}</t></is></c>`;
                                            rowIntestazioni += `<c t="inlineStr" r="${numToAlpha(startCol + 2) + rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.COVERS')}</t></is></c>`;
                                            rowIntestazioni += `<c t="inlineStr" r="${numToAlpha(startCol + 3) + rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.AVG')}</t></is></c>`;
                                            rowIntestazioni += `<c t="inlineStr" r="${numToAlpha(startCol + 4) + rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.AMOUNT')}</t></is></c>`;
                                            rowIntestazioni += `<c t="inlineStr" r="${numToAlpha(startCol + 5) + rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.COVERS')}</t></is></c>`;
                                            rowIntestazioni += `<c t="inlineStr" r="${numToAlpha(startCol + 6) + rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.AVG')}</t></is></c>`;
                                        } else if (totalsColumns == 3) {
                                            rowIntestazioni += `<c t="inlineStr" r="${numToAlpha(startCol + 1) + rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.AMOUNT')}</t></is></c>`;
                                            rowIntestazioni += `<c t="inlineStr" r="${numToAlpha(startCol + 2) + rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.COVERS')}</t></is></c>`;
                                            rowIntestazioni += `<c t="inlineStr" r="${numToAlpha(startCol + 3) + rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.AVG')}</t></is></c>`;
                                        } else {
                                            rowIntestazioni += `<c t="inlineStr"  r="${numToAlpha(startCol + 1) + rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.AMOUNT')}</t></is></c>`;
                                            rowIntestazioni += `<c t="inlineStr" r="${numToAlpha(startCol + 2) + rowCount}" s="${boldTextYellowFillAlignRightIndex}"><is><t xml:space="preserve">${this.translate.instant('CONTO_ECONOMICO.DAILY_VIEW.COLUMNS.COVERS')}</t></is></c>`;
                                        }
                                    }

                                    rowIntestazioni += '</row>';
                                    $('sheetData', sheet).append(rowIntestazioni);
                                    rowCount++;





                                    // A questo punto arrivano i totali, che sono l'ultima riga dell'intestazione della tabella
                                    var row: any = $(element);
                                    row = row[0].outerHTML;

                                    // Convert only numeric inlineStr cells to numeric format
                                    row = convertToNumeric(row);

                                    row = changeRowIndex(row, 5);
                                    $('sheetData', sheet).append(row);
                                    rowCount++;

                                } else {
                                    var row: any = $(element);
                                    row = row[0].outerHTML;
                                    $('sheetData', sheet).append(row);
                                    rowCount++;
                                }
                            });

                            // Ll'ultima riga tutta evidenziata
                            $(`row:nth-child(${rowCount - 1}) c`, sheet).attr('s', boldTextYellowFillAlignRightIndex);

                            // stampo nella console tutto l'xml
                            console.log($('sheetData', sheet)[0].innerHTML);

                            console.log($('sheetData', sheet));
                        }
                    }
                ]
            };

            this.table = $('#ceTable').DataTable(dtOptions);

        }, 100);

    }

    filterByPLName($event: KeyboardEvent) {
        if ($event.code == 'Enter') {
            this.table.draw();
        }
    }

    syncFixedHeaderColumns = (table) => {
        var headerRows = $('.dataTables_scrollHead thead tr');
        var fixedHeaderCells = headerRows.last().children('th');

        // Determina quante colonne sono fisse dopo la prima colonna
        const fixedCols = this.getFixedCols();

        // Itera attraverso le righe dell'header
        headerRows.each((rowIndex, row) => {
            if (rowIndex < headerRows.length - 1) {
                var cells = $(row).children('th');
                var currentColIndex = 0;

                cells.each((colIndex, cell) => {
                    if (currentColIndex <= fixedCols) {
                        var fixedCell = $(fixedHeaderCells[currentColIndex]);
                        var left = fixedCell.css('left');
                        var position = fixedCell.css('position');
                        var className = fixedCell.attr('class');

                        if (className) {
                            // Applica gli stili e le classi alla cella corrente
                            $(cell).css({
                                'left': left,
                                'position': position
                            }).addClass(className);

                        }
                    }

                    // Incrementa l'indice delle colonne in base al colspan della cella corrente
                    currentColIndex += parseInt($(cell).attr('colspan') || "1");
                });
            }
        });
    }


    refreshColumnsVisibility() {
        this.loading$.next(true);
        this.ref.detectChanges();

        setTimeout(() => {
            const numColsPerMonth = 6;
            const numMonths = 12;
            const totalCols = numColsPerMonth * numMonths;

            // Colonna fissa per il nome del giorno
            this.table.column(0).visible(true);

            // Nascondi tutte le colonne tranne la prima
            for (let i = 1; i <= totalCols + 6; i++) {
                this.table.column(i).visible(false);
            }

            // Mostra le colonne base per ogni mese (Amount, Avg)
            for (let i = 0; i < numMonths; i++) {
                this.table.column(i * numColsPerMonth + 1).visible(true); // Amount Current
                this.table.column(i * numColsPerMonth + 3).visible(true); // Avg Current
            }

            // Mostra/nascondi colonne "Cover" corrente e precedente
            if (this.filters.ck_showCover) {
                for (let i = 0; i < numMonths; i++) {
                    this.table.column(i * numColsPerMonth + 2).visible(true); // Cover Current
                }
            }

            // Mostra/nascondi colonne degli anni precedenti
            if (this.filters.ck_prevyear) {
                for (let i = 0; i < numMonths; i++) {
                    this.table.column(i * numColsPerMonth + 4).visible(true); // Amount Previous
                    this.table.column(i * numColsPerMonth + 6).visible(true); // Avg Previous
                    if (this.filters.ck_showCover) {
                        this.table.column(i * numColsPerMonth + 5).visible(true); // Cover Previous
                    }
                }

                // Colonne Totali per l'anno precedente
                this.table.column(totalCols + 4).visible(true); // Totals Amount Previous
                this.table.column(totalCols + 6).visible(true); // Totals Avg Previous
                if (this.filters.ck_showCover) {
                    this.table.column(totalCols + 5).visible(true); // Totals Cover Previous
                }
            }

            // Colonne Totali per l'anno corrente
            this.table.column(totalCols + 1).visible(true); // Totals Amount Current
            this.table.column(totalCols + 3).visible(true); // Totals Avg Current
            if (this.filters.ck_showCover) {
                this.table.column(totalCols + 2).visible(true); // Totals Cover Current
            }

            this.loading$.next(false);
            this.ref.detectChanges();
            setTimeout(() => {
                this.table.columns.adjust().fixedColumns().left(this.getFixedCols() + 1).draw(false);
            }, 100);
        }, 100);
    }

    getAmount(values: any, prec: boolean = false) {
        if (this.filters.ck_lordo) {
            return prec ? this.utility.formatNumber(values.PreviousGrossAmount, this.locale) : this.utility.formatNumber(values.CurrentGrossAmount, this.locale)
        } else {
            return prec ? this.utility.formatNumber(values?.PreviousAmount, this.locale) : this.utility.formatNumber(values?.CurrentAmount, this.locale)
        }
    }

    getCover(values: any, prec: boolean = false) {
        return prec ? this.utility.formatNumber(values?.PreviousCovers, this.locale) : this.utility.formatNumber(values?.CurrentCovers, this.locale);
        //if (this.filters.ck_lordo) {
        //    return prec ? this.utility.formatNumber(values.PreviousCovers, this.locale) : this.utility.formatNumber(values.CurrentCovers, this.locale)
        //} else {
        //    return prec ? this.utility.formatNumber(values?.PreviousCovers, this.locale) : this.utility.formatNumber(values?.CurrentCovers, this.locale)
        //}
    }

    getAvg(values: any, prec: boolean = false) {
        return prec ? this.utility.formatNumber(values?.PreviousAvg, this.locale) : this.utility.formatNumber(values?.CurrentAvg, this.locale)
        //if (this.filters.ck_lordo) {
        //    return prec ? this.utility.formatNumber(values.PreviousGrossAmount, this.locale) : this.utility.formatNumber(values.CurrentGrossAmount, this.locale)
        //} else {
        //    return prec ? this.utility.formatNumber(values?.PreviousAvg, this.locale) : this.utility.formatNumber(values?.CurrentAvg, this.locale)
        //}
    }

    async addCE(item: any) {
        this.dialog
            .open(ContoEconomicoEditComponent, {
                width: '800px',
                data: {
                    item: item
                }
            }).afterClosed().subscribe((res: any) => {
                if (res) {
                    this.table.draw();
                    setTimeout(() => {
                        const element: any = document.getElementById(item.Id);
                        element.scrollIntoView();
                        this.ref.detectChanges();
                    }, 500);
                }
            });
    }

    async detailCE(item: any) {
        this.dialog
            .open(ContoEconomicoDetailsComponent, {
                data: {
                    item: item,
                    currentFilterCfg: this.currentFilterCfg,
                    locale: this.locale,
                    training: this.training
                },
                width: '1200px'
            }).afterClosed().subscribe((res: any) => {
                if (res) {
                    this.table.draw();
                    setTimeout(() => {
                        const element: any = document.getElementById(item.Id);
                        element.scrollIntoView();
                        this.ref.detectChanges();
                    }, 500);
                }
            });
    }

    exportAsXLSX() {
        this.table.button(1).trigger();
    }

    exportAsPDF(PDFPreferences: any) {
        this.PDFPreferences = PDFPreferences;
        this.table.button(0).trigger();
    }

    performShowTotals(forcedValue: boolean = false) {
        if (!forcedValue) { // Fa già l'initDatatable dal SUbscription del filterService
            this.table.draw();
        }
    }
}
