import { Component, OnInit, Inject } from '@angular/core';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

@Component({
    selector: 'kt-select-company',
    templateUrl: './select-company.component.html',
    styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent implements OnInit {

    company!: string;

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        public staticCollectionsService: StaticCollectionsService
    ) { }

    ngOnInit() {
    }

    onSubmit() {
        this.dialogRef.close(this.company);
    }

}

