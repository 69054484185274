import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { EstrazioneDatiService } from '@app/core/services/estrazione-dati.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'kt-estrazione-dati',
  templateUrl: './estrazione-dati.component.html',
  styleUrls: ['./estrazione-dati.component.scss']
})
export class EstrazioneDatiComponent implements OnInit {

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dateStart: any;
  dateEnd: any;
  selectedCompanies: any;
  withTax: boolean = false;

  constructor(
    public staticCollectionsService: StaticCollectionsService,
    private estrazioneDatiService: EstrazioneDatiService,
    private ref: ChangeDetectorRef,
    private translate: TranslateService,
    private layoutUtilsService: LayoutUtilsService
  ) {
    document.body.classList.add('kt-aside--minimize');
  }

  async ngOnInit() {
    this.selectedCompanies = undefined;  
    this.loading$.next(true);
    const ready = this.staticCollectionsService.collectionReady();
    await this.staticCollectionsService.fillStaticCollection(!ready);
    this.loading$.next(false);

    this.ref.detectChanges();
  }

  exportExcel() {
    this.loading$.next(true);
    let payload: any = {
      DateFilter: {
        Start: this.dateStart.toISOString(true),
        End: this.dateEnd.toISOString(true)
      }
    };

    if (this.selectedCompanies) {
      payload.Companies = this.selectedCompanies
    }

    this.estrazioneDatiService.getTimeSlotReport(payload, this.withTax).toPromise()
    .then((result) => {
      const _success = this.translate.instant('ESTRAZIONE_DATI.EXTRACT_SUCCESS');
      this.layoutUtilsService.showActionNotification(_success, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
    })
    .catch((err: any) => {
      console.log(err);
    })
    .finally(() => {
      this.loading$.next(false);
    })

  }

}
