import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RoleService {

    private _currentRole!: string;

    constructor(
        public router: Router,
    ) { }

    checkWritePermission(role: string) {
        this._currentRole = role;
    }

    isCompanyAdminOrMore() {
        return ['SuperAdmin', 'CompanyAdmin'].includes(this._currentRole);
    }

    isStoreManagerOrMore() {
        return ['SuperAdmin', 'CompanyAdmin', 'StoreManager'].includes(this._currentRole);
    }

    isSuperAdmin() {
        return ['SuperAdmin'].includes(this._currentRole);
    }

    isStoreManager() {
        return ['StoreManager'].includes(this._currentRole);
    }

    isUserOrMore() {
        return ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User'].includes(this._currentRole);
    }

    canWrite() {
        //this._currentRole="ReadonlyUser";
        let url: string = this.router.url.split('?')[0];
        let canwrite: boolean = false;
        switch (url) {
            case '/magazzino/inserimento-comune':
                canwrite = ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User'].includes(this._currentRole);
                break;
            case '/magazzino/inserimento-ordini':
            case '/magazzino/inserimento-ordini-semplificati':
                canwrite = ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User'].includes(this._currentRole);

                break;
            case '/magazzino/inserimento-ordini-interni':
                canwrite = ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User'].includes(this._currentRole);

                break;
            case '/magazzino/ordini':
            case '/magazzino/ordini-semplificati':
                canwrite = ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User'].includes(this._currentRole);
                break;
            case '/magazzino/ordini-interni':
                canwrite = ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User'].includes(this._currentRole);
                break;
            case '/magazzino/gestione-merci':
                canwrite = ['SuperAdmin', 'CompanyAdmin', 'StoreManager'].includes(this._currentRole);
                break;
            case '/gestione-dati/gestione-fatture':
            case '/magazzino/ddt':
            case '/magazzino/acquisti':
                canwrite = ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User'].includes(this._currentRole);
                break;
            case '/magazzino/inventari':
                canwrite = ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User'].includes(this._currentRole);
                break;
            case '/magazzino/trasferimenti':
                canwrite = ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User'].includes(this._currentRole);
                break;
            case '/magazzino/sprechi':
                canwrite = ['SuperAdmin', 'CompanyAdmin', 'StoreManager', 'User'].includes(this._currentRole);
                break;
            case '/magazzino/giacenze':
                canwrite = ['SuperAdmin', 'CompanyAdmin', 'StoreManager'].includes(this._currentRole);
                break;
            case '/magazzino/alldata':
                canwrite = ['SuperAdmin', 'CompanyAdmin', 'StoreManager'].includes(this._currentRole);
                break;
            case '/magazzino/statistiche':
                canwrite = ['SuperAdmin', 'CompanyAdmin', 'StoreManager'].includes(this._currentRole);
                break;

            case '/brands':
                canwrite = ['SuperAdmin', 'CompanyAdmin'].includes(this._currentRole);
                break;
            case '/centri-costo':
                canwrite = ['SuperAdmin', 'CompanyAdmin'].includes(this._currentRole);
                break;
            case '/wastetypes':
                canwrite = ['SuperAdmin'].includes(this._currentRole);
                break;
            case '/categorie-merci':
                canwrite = ['SuperAdmin', 'CompanyAdmin'].includes(this._currentRole);
                break;
            case '/fornitori':
                canwrite = ['SuperAdmin', 'CompanyAdmin'].includes(this._currentRole);
                break;
            case '/categorie-prodotti':
                canwrite = ['SuperAdmin', 'CompanyAdmin'].includes(this._currentRole);
                break;
            case '/gruppi':
                canwrite = ['SuperAdmin', 'CompanyAdmin'].includes(this._currentRole);
                break;
            case '/gruppi-merci':
                canwrite = ['SuperAdmin', 'CompanyAdmin'].includes(this._currentRole);
                break;
            case '/reparti':
                canwrite = ['SuperAdmin', 'CompanyAdmin'].includes(this._currentRole);
                break;
            case '/razioni':
                canwrite = ['SuperAdmin', 'CompanyAdmin'].includes(this._currentRole);
                break;
            case '/unita-misura':
                canwrite = ['SuperAdmin', 'CompanyAdmin'].includes(this._currentRole);
                break;
            case '/unita-secondarie':
                canwrite = ['SuperAdmin', 'CompanyAdmin'].includes(this._currentRole);
                break;

        }

        return canwrite;
    }

    hasPermission(permission: string) {
        try {
            const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
            return currentUser.Permissions[permission] || this.isSuperAdmin();
        } catch (error) {
            return false
        }
    }

}
