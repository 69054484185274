import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SwitchGroupService, TranslationService } from '@app/core/_base/layout';
import { FiltriService } from '@app/core/services/filtri.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as util from '@app/core/services/utilityfunctions';
import { PDFService } from '@app/core/services/pdf.service';
import { MatDialog } from '@angular/material/dialog';
import { DettaglioComponent } from './dettaglio/dettaglio.component';
import { EditMerciComponent } from '../../gestione-dati/gestione-merci/edit-merci/edit-merci.component';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { Router } from '@angular/router';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { AvpAlertComponent } from './avp-alert/avp-alert.component';

@Component({
    selector: 'kt-analisi-variazione-prezzi',
    templateUrl: './analisi-variazione-prezzi.component.html',
    styleUrls: ['./analisi-variazione-prezzi.component.scss']
})
export class AnalisiVariazionePrezziComponent implements OnInit {

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    CategoryIds: any;
    table: any
    fornitori: any;
    productOrCode: string = '';
    currentFilterCfg: any;

    filtriServiceSubscription!: Subscription;
    translationServiceSubscription!: Subscription;
    locale: string = 'it';

    PDFPreferences: any;

    productStartsWith = -1;

    constructor(
        public staticCollectionsService: StaticCollectionsService,
        private ref: ChangeDetectorRef,
        private filtriService: FiltriService,
        private switchGroupService: SwitchGroupService,
        private translate: TranslateService,
        private translationService: TranslationService,
        private pdfService: PDFService,
        public dialog: MatDialog,
        private gestioneMerciService: GestioneMerciService,
        private router: Router,
        private layoutUtilsService: LayoutUtilsService
    ) {
        this.translationServiceSubscription = this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
    }

    ngOnDestroy(): void {
        this.filtriServiceSubscription.unsubscribe();
        this.translationServiceSubscription.unsubscribe();
    }

    ngOnInit(): void {
        document.body.classList.add('kt-aside--minimize');

        this.staticCollectionsService.fillStaticCollection(false);

        this.fornitori = [];
        this.productOrCode = '';

        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            (filterConfig: any) => {

                // Se ho un filtro memorizzato lo recupero

                if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) {
                    return
                };
                if (filterConfig.constructor !== Object) {
                    return
                };
                this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

                if (this.table) {
                    this.table.draw()
                } else {
                    this.initDataTable();
                }
            }
        );

    }

    clearFilters(actionFilter: boolean = true) {
        this.table = undefined;
        this.fornitori = [];
        this.productOrCode = '';
        this.filtriService.clearFilters$.next(actionFilter);
        $('#table').DataTable().clear().destroy();
        if (this.currentFilterCfg && this.currentFilterCfg['ProductOrCode']) delete this.currentFilterCfg['ProductOrCode'];
        if (this.currentFilterCfg && this.currentFilterCfg['SupplierIds']) delete this.currentFilterCfg['SupplierIds'];
        if (this.currentFilterCfg && this.currentFilterCfg['CategoryIds']) delete this.currentFilterCfg['CategoryIds'];
        this.ref.detectChanges();
    }

    expandAllRowsExcel: boolean = false;
    exportAsXLSX() {
        const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
        const _description: string = this.translate.instant('EXPORT_XLSX.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');
        const _success = this.translate.instant('EXPORT_XLSX.MESSAGE');
        const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');
        const _yesButton2 = this.translate.instant('EXPORT_XLSX.NOBUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton, _yesButton2);
        dialogRef.afterClosed().subscribe((expand: boolean) => {
            if (expand) {
                this.expandAllRowsExcel = expand;
                this.table.buttons(1).trigger();
            }
        });
    }

    exportAsPDF() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: true,
            explodeRows: this.translate.instant('EXPORT_PDF.EXPLODE_ROWS')
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('ANALISI_VARIAZIONE_PREZZI.TITLE'),
                    period: this.filtriService.getCurrentPeriod()
                };
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                };
                result['language'] = this.translationService.getSelectedLanguage();

                this.PDFPreferences = result;

                this.table.button(0).trigger();
            };
        });
    }

    expandAll: boolean = false;
    expandAllRows() {
        this.expandAll = !this.expandAll;
        const trList = $('#table tbody tr');
        trList.each((index: number, tr: any) => {
            const row = this.table.row(tr);
            if (!this.expandAll) {
                // This row is already open - close it
                row.child.hide();
                $(tr).removeClass('shown');
            } else {
                // Open this row
                row.child(this.format(row.data())).show();
                $(tr).addClass('shown');
            }
        });
    }

    initDataTable() {
        if ($.fn.dataTable.isDataTable('#table')) {
            $('#table').DataTable().destroy();
        }
        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();
            let dtOptions: any = {
                orderCellsTop: true,
                destroy: true,
                paging: true,
                searching: true,
                dom: 'rt<"paginator">',
                ordering: true,
                scrollY: 'calc(100vh - 380px)',
                scrollCollapse: true,
                autoWidth: true,
                order: [],
                processing: true,
                serverSide: true,
                ajax: {
                    url: currentLogin.endpointURL + "/api/Warehouse/PriceEvolutionDataTable",
                    type: "POST",
                    data: (d: any) => {
                        d.filters = this.currentFilterCfg;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        this.pagination.TotalRows = response.recordsFiltered;
                        this.ref.detectChanges();
                        return response.data;
                    }
                },
                
                
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                
                columnDefs: [
                    {
                        targets: 0,
                        className: 'parent-row hideInExport',
                        width: '20px',
                        orderable: false,
                        render: (data, type, p) => {
                            return p.Suppliers && p.Suppliers.length > 0 ? '<i class="fas expander pointer"></i>' : '';
                        }
                    },
                    {
                        targets: 1, data: 'Code', name: 'Code', render: (data, type, p, meta) => {
                            return `<div class="overflow-ellipses exportText" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }, className: 'max-width-150'
                    },
                    {
                        targets: 2, data: 'Name', name: 'Name', render: (data, type, p, meta) => {
                            return `<div class="display-flex-left"><a href="javascript:;" data-id="${p.Id}" name="goToAnalisiPrezziFornitore"><i class="fas fa-chart-area"></i></a>&nbsp;
                            <div class="overflow-ellipses"><a href="javascript:;" data-id="${p.Id}" name="openMerce" class="exportText" data-toggle="tooltip" title="${data}">${data}</a></div></div>`;
                        }, className: 'linktoAction max-width-450'
                    },
                    {
                        targets: 3, data: 'Category', name: 'Category', render: (data, type, p, meta) => {
                            return `<div class="overflow-ellipses exportText" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }, className: 'max-width-150'
                    },
                    {
                        targets: 4, data: 'Min', name: 'Min', render: (data, type, p, meta) => {
                            return util.formatNumber(data, this.locale, 2, true)
                        }, width: '60px', className: 'text-right numeric'
                    },
                    {
                        targets: 5, data: 'Average', name: 'Average', render: (data, type, p, meta) => {
                            return util.formatNumber(data, this.locale, 2, true)
                        }, width: '60px', className: 'text-right numeric'
                    },
                    {
                        targets: 6, data: 'Max', name: 'Max', render: (data, type, p, meta) => {
                            return util.formatNumber(data, this.locale, 2, true)
                        }, width: '60px', className: 'text-right numeric'
                    }
                ],
                aaSorting: [],
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        //text: '<i class="fa fa-file-pdf text-danger"></i>',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.excludeFromExport)',
                            //format: {
                            //    body: function (data, row, column, node) {
                            //        if (column == 4) {
                            //            data = data.replace(/<i class="fas fa-coins" style="padding-left:10px;" title="Credit"><\/i>/g, '');
                            //        }
                            //        if (column === 7) {
                            //            data = data.replace(/<br>/g, '\n');
                            //        }

                            //        if (data.indexOf('exportableTooltip') >= 0) {
                            //            var parser = new DOMParser();
                            //            var html = parser.parseFromString(data, 'text/html');
                            //            let html$ = $(html);
                            //            data = html$.find(`.exportableTooltip`).attr("title");
                            //        }

                            //        return data;
                            //    }
                            //}
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            const columns = doc.content[1].table.body.length > 0 ? doc.content[1].table.body[0].length : 0;

                            const arrayCols = Array(columns).fill('auto');
                            arrayCols[2] = '*'; // La colonna prodotto la metto espandibile al massimo

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, arrayCols, '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
                            ////doc.defaultStyle = docDefinition.defaultStyle;
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;
                            doc.styles = docDefinition.styles;

                            // Imposto gli allineamenti
                            var rowCount = doc.content[1].table.body.length;
                            for (let i = 0; i < rowCount; i++) {
                                doc.content[1].table.body[i].forEach((col: any, index: number) => {
                                    const column = this.table.column(index);
                                    if (column.header().className.includes('text-right')) {
                                        doc.content[1].table.body[i][index].alignment = 'right';
                                    }
                                });
                            }

                            if (this.PDFPreferences.explode) {

                                var rowData = this.table.rows({ order: 'applied', search: 'applied' }).data();

                                var headerLines = 0;  // Offset for accessing rowData array

                                var newBody: any = []; // this will become our new body (an array of arrays(lines))

                                //Loop over all lines in the table
                                doc.content[1].table.body.forEach((line, i) => {

                                    if (line[0].style !== 'tableHeader') {
                                        line[0]['style'] = 'tableBodyParent';
                                        line[1]['style'] = 'tableBodyParent';
                                        for (let index = 2; index < line.length; index++) {
                                            line[index]['alignment'] = 'right';
                                            line[index]['style'] = 'tableBodyParent';
                                        }
                                    }

                                    // Aggiungo la riga di primo livello
                                    newBody.push(line);

                                    if (line[0].style !== 'tableHeader') {

                                        var data = rowData[i - headerLines];
                                        // Append child data, matching number of columns in table
                                        if (data.Suppliers && data.Suppliers.length > 0) {

                                            // Aggiungo l'intestazione
                                            let subHeader: any = [];
                                            subHeader.push({ text: '', style: 'tableBodySubHeader' });
                                            subHeader.push({ text: this.translate.instant('ANALISI_VARIAZIONE_PREZZI.COLUMNS.SUPPLIER'), style: 'tableBodySubHeader', alignment: 'right' });
                                            subHeader.push({ text: '', style: 'tableBodySubHeader' });
                                            subHeader.push({ text: this.translate.instant('ANALISI_VARIAZIONE_PREZZI.COLUMNS.AMOUNT'), style: 'tableBodySubHeader' });
                                            subHeader.push({ text: this.translate.instant('ANALISI_VARIAZIONE_PREZZI.COLUMNS.MIN'), style: 'tableBodySubHeader' });
                                            subHeader.push({ text: this.translate.instant('ANALISI_VARIAZIONE_PREZZI.COLUMNS.AVG'), style: 'tableBodySubHeader' });
                                            subHeader.push({ text: this.translate.instant('ANALISI_VARIAZIONE_PREZZI.COLUMNS.MAX'), style: 'tableBodySubHeader' });
                                            newBody.push(subHeader);

                                            data.Suppliers.forEach(child => {

                                                let columnsValues: any = [
                                                    { text: '-', style: 'tableBodySubStyle' },
                                                ];

                                                const totColumns = 7;
                                                for (var col = 1; col < totColumns; col++) {
                                                    let text = '', alignment = 'left';
                                                    switch (col) {
                                                        case 1:
                                                            alignment = 'right';
                                                            text = child.Code;
                                                            break;
                                                        case 2:
                                                            text = child.Supplier;
                                                            break;
                                                        case 3:
                                                            alignment = 'right';
                                                            text = util.formatNumber(child.Amount, this.locale);
                                                            break;
                                                        case 4:
                                                            alignment = 'right';
                                                            text = util.formatNumber(child.Min, this.locale);
                                                            break;
                                                        case 5:
                                                            alignment = 'right';
                                                            text = util.formatNumber(child.Average, this.locale);
                                                            break;
                                                        case 6:
                                                            alignment = 'right';
                                                            text = util.formatNumber(child.Max, this.locale);
                                                            break;
                                                    }

                                                    columnsValues.push({ text: text, alignment: alignment, style: 'tableBodySubStyle' });
                                                }

                                                newBody.push(columnsValues);

                                            })
                                        }

                                    } else {
                                        headerLines++;
                                    }

                                });

                                doc.content[1].table.body = newBody;
                            }
                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal;
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.pdfHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        //autoFilter: true,
                        //sheetName: 'Exported data',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.excludeFromExport)',
                            format: {
                                body: (data, row, column, node) => {

                                    if (data.indexOf('exportText') >= 0) {
                                        var parser = new DOMParser();
                                        var htmlDoc = parser.parseFromString(data, 'text/html');
                                        data = $(htmlDoc).find('.exportText').text();
                                    }

                                    if (node.className.includes('numeric')) {
                                        return util.parseNumber(data, this.locale);
                                    } else {
                                        return '\0' + data
                                    }
                                }
                            },
                            orthogonal: 'export'
                        },
                        customize: async (xlsx: any) => {
                            let sheet = xlsx.xl.worksheets['sheet1.xml'];

                            const visibleColumns = this.table.columns().visible().toArray();

                            const numToAlpha = (num: number) => {
                                var alpha = '';
                                for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
                                    alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
                                }
                                return alpha;
                            }

                            visibleColumns.forEach((visible: boolean, index: number) => {
                                if (visible) {
                                    const column = this.table.column(index);
                                    if (column.header().className.includes('numeric')) {
                                        const alpha = numToAlpha(index);
                                        $(`row c[r^="${alpha}"]`, sheet).attr('s', '64');
                                    }
                                }
                            });

                        },

                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.excelHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    }
                ]
            };

            this.table = $('#table').DataTable(dtOptions);

            $('tbody').off('click');

            $('tbody').on('click', 'td.parent-row', (event: any) => {
                const tr = $(event.target).closest('tr');
                const row = this.table.row(tr);

                if (row.child.isShown()) {
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('shown');
                } else {

                    // Open this row
                    row.child(this.format(row.data())).show();
                    tr.addClass('shown');
                    tr.addClass('shown');
                }
            });

            $('tbody').on('click', '.linktoAction', async (event: any) => {
                const tr = $(event.target).closest('tr');
                const a = $(event.target).closest('a');
                const data = this.table.row(tr.index()).data();

                switch (a.attr('name')) {
                    case 'openMerce':
                        this.openMerce(data);
                        break;
                    case 'goToAnalisiPrezziFornitore':
                        this.goToAnalisiPrezziFornitore(data);
                        break;

                }

            });

            $('tbody tr.children td.supplier-row').off('click');
            $('tbody').on('click', 'tr.children td.supplier-row', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data = tr.data('row');
                const name = tr.data('name');
                this.openDetail(data, name);
            });

        }, 100);
    }

    format(row: any) {
        if (!row) return;
        let subRows: string = '';

        subRows += `<tr class="children">
            <th></th>
            <th>${this.translate.instant('ANALISI_VARIAZIONE_PREZZI.COLUMNS.SUPPLIER')}</th>
            <th></th>
            <th class="text-right">${this.translate.instant('ANALISI_VARIAZIONE_PREZZI.COLUMNS.AMOUNT')}</th>            
            <th class="text-right">${this.translate.instant('ANALISI_VARIAZIONE_PREZZI.COLUMNS.MIN')}</th>
            <th class="text-right">${this.translate.instant('ANALISI_VARIAZIONE_PREZZI.COLUMNS.AVG')}</th>
            <th class="text-right">${this.translate.instant('ANALISI_VARIAZIONE_PREZZI.COLUMNS.MAX')}</th>
        </tr>`;

        row.Suppliers.forEach(d => {
            const expand = d.Prices && d.Prices.length > 0 ? '<i class="fas expander pointer"></i>' : '';
            subRows += `<tr id="${d.SupplierId}" data-row='${JSON.stringify(d)}' data-name='${row.Name}' class="children">
                   <td class="supplier-row text-right">${expand}</td>
                   <td>${d.Code}</td>
                   <td>${d.Supplier}</td>
                   <td class="text-right">${util.formatNumber(d.Amount, this.locale)}</td>                   
                   <td class="text-right">${util.formatNumber(d.Min, this.locale)}</td>
                   <td class="text-right">${util.formatNumber(d.Average, this.locale)}</td>
                   <td class="text-right">${util.formatNumber(d.Max, this.locale)}</td>
                </tr>`;
        });

        return $(subRows);
    }

    goToAnalisiPrezziFornitore(data: any) {
        this.router.navigate(['/analisi/analisi-prezzi-fornitori'], { queryParams: { ProductId: data.Id } });
    }

    pagination: any = {
        TotalRows: 0,
        pageSize: 10,
        pageIndex: 0
    }

    changePagination(event: any) {
        if (this.pagination.pageSize != event.pageSize) {
            this.pagination.pageSize = event.pageSize;
            this.pagination.pageIndex = 0;
            this.table.page.len(this.pagination.pageSize).draw();
            this.table.page(0).draw('page');
        } else if (this.pagination.pageIndex != event.pageIndex) {
            this.pagination.pageIndex = event.pageIndex;
            this.table.page(this.pagination.pageIndex).draw('page');
        }
    }

    async openDetail(item: any, name: string) {
        const dialogRef = this.dialog
            .open(DettaglioComponent, {
                data: {
                    item: item,
                    name: name,
                    locale: this.locale
                },
                width: '1000px'
            });
    }

    filter(value: any, field: string) {
        this.currentFilterCfg[field] = value;
        this.table.draw();
    }

    manageKeydown(event: any) {
        if (event.code === 'Enter' || event.code == 'NumpadEnter') {
            this.filter(event.target['value'], 'ProductOrCode')
        }
    }

    async openMerce(item: any) {
        const merce = await this.gestioneMerciService.getSingleGoods(item.Id).toPromise();

        this.dialog
            .open(EditMerciComponent, {
                data: {
                    merce: merce,
                    readOnly: true
                },
                width: '100%',
                height: '100%'
            });
    }

    showAlert() {

        this.dialog
            .open(AvpAlertComponent, {
                width: '1000px',
                data: {
                    currentFilterCfg: this.currentFilterCfg,
                    locale: this.locale
                },
            });
    }
}
