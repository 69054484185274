import { Component, OnInit, Inject } from '@angular/core';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

import * as moment from 'moment';

@Component({
  selector: 'kt-forza-date-inizio',
  templateUrl: './forza-date-inizio.component.html',
  styleUrls: ['./forza-date-inizio.component.scss']
})
export class ForzaDateInizioComponent implements OnInit {

  title!: string;
  subtitle!: string;
  forceBtn!: string;

  date: Date = new Date();

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    console.log('this.data', this.data);
    this.forceBtn = this.data && this.data.forceBtn ? this.data.forceBtn : 'FORZA';
    this.title = this.data && this.data.title ? this.data.title : '';
    this.subtitle = this.data && this.data.subtitle ? this.data.subtitle : '';
  }

  onSubmit() {
    this.dialogRef.close({
      success: true,
      date: moment(this.date).format('YYYY-MM-DDTHH:mm:ss')
    });
  }

}
