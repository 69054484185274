import { Component, Input, OnInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContoEconomicoDetailsComponent } from '../../conto-economico-details/conto-economico-details.component';
import { ContoEconomicoEditComponent } from '../../conto-economico-edit/conto-economico-edit.component';
import * as util from '@app/core/services/utilityfunctions';
import { RoleService, SwitchGroupService, TranslationService } from '@app/core/_base/layout';
import { PDFService } from '@app/core/services/pdf.service';
import { TranslateService } from '@ngx-translate/core';
import { FiltriService } from '@app/core/services/filtri.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import moment from 'moment';
import * as XLSX from '@sheet/core';
declare var window: any;

@Component({
    selector: 'kt-monthly-view',
    templateUrl: './monthly-view.component.html',
    styleUrls: ['./monthly-view.component.scss']
})
export class MonthlyViewComponent implements OnInit {
    view: string = 'MONTHLY';

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    @Input() filters: any = {};
    @Output() onClickGraph = new EventEmitter<any>();
    locale!: string;
    showTotals: boolean = false;
    currentFilterCfg: any = {};
    utility: any;
    expandRows: boolean = false;
    lastRowsExpanded: any = [];

    filtriServiceSubscription!: Subscription;

    constructor(
        private ref: ChangeDetectorRef,
        public dialog: MatDialog,
        private roleService: RoleService,
        private switchGroupService: SwitchGroupService,
        private pdfService: PDFService,
        private translate: TranslateService,
        public filtriService: FiltriService,
        private translationService: TranslationService
    ) {
        this.utility = util;

        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
            moment.locale(this.locale)
        });
    }

    ngOnDestroy() {
        this.filtriServiceSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.checkScroll();
        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            async (filterConfig: any) => {
                // Se ho un filtro memorizzato lo recupero
                if (localStorage.getItem('currentFilterCfg')) {
                    try {
                        this.currentFilterCfg = JSON.parse(localStorage.getItem('currentFilterCfg') || '');
                        localStorage.removeItem('currentFilterCfg');
                    } catch (error) {
                        localStorage.removeItem('currentFilterCfg');
                        return;
                    }
                    //return;
                } else {
                    if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) {
                        return;
                    };
                    if (filterConfig.constructor !== Object) {
                        return;
                    };

                    if (JSON.stringify(filterConfig) == JSON.stringify(this.currentFilterCfg)) {
                        return;
                    }

                    this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));
                }

                //
                if (this.filters.filterCompanies && this.filters.filterCompanies.length > 0) {
                    this.currentFilterCfg['Companies'] = this.filters.filterCompanies;
                } else {
                    delete this.currentFilterCfg.Companies;
                }

                if (this.filters.filtercostCenters && this.filters.filtercostCenters.length > 0) {
                    this.currentFilterCfg['CostCenterIds'] = this.filters.filtercostCenters;
                } else {
                    delete this.currentFilterCfg.CostCenterIds;
                }

                //if (!this.currentFilterCfg.Period && !this.currentFilterCfg.DateFilter) {
                const currentYear = this.getYearByFilter();
                this.currentFilterCfg.DateFilter = { Start: `${currentYear}-01-01`, End: `${currentYear}-12-31` };
                //}

                if (!this.table) {
                    this.initDataTable();
                } else {
                    // Trick per cambiare l'anno nell'intestazione della colonna
                    this.table.columns().every((index: number) => {
                        const header = this.table.columns([index]).header().to$().text();
                        const checkArrayCols = header.split(' ');
                        // Se sono dalla colonna 2 in avant
                        // Se l'intestazione è composta da 2 parole
                        // Se la seconda parola è un numero
                        if (index > 1 && checkArrayCols && checkArrayCols.length > 1 && !isNaN(checkArrayCols[1])) {
                            checkArrayCols[1] = this.getYearByFilter()
                            this.table.columns([index]).header().to$().text(checkArrayCols.join(' '));
                        }

                    })
                    this.table.draw();
                }

            }
        );
    }

    checkScroll() {
        const wHeight: any = $(window).height();
        const tHeight: any = $("#ceTable").height();
        if (wHeight < tHeight) {
            $("#scrollUp").show();
        } else {
            $("#scrollUp").hide();
        }
    }

    getYearByFilter() {
        let currentYear = moment().year();
        if (this.filtriService.filterConfig.Period) {
            switch (this.filtriService.filterConfig.Period.toLowerCase()) {
                case 'onlyyesterday':
                case 'yesterday':
                    currentYear = moment().subtract(1, 'day').year();
                    break;
                case 'year':
                    currentYear = moment().startOf('year').year();
                    break;
                case 'month':
                    currentYear = moment().subtract(1, 'month').year();
                    break;
                case 'week':
                    currentYear = moment().startOf('week').year();
                    break;
            }
        } else if (this.filtriService.filterConfig.DateFilter) {
            currentYear = moment(this.filtriService.filterConfig.DateFilter.Start, 'YYYY-MM-DD').year();
        }

        return currentYear;

        //return moment().startOf('year').year();
    }

    clearFilters(actionFilter: boolean = true, redrawTable: boolean = true) {
        this.filtriService.clearFilters$.next(actionFilter);
        this.showTotals = false;
        const currentYear = this.getYearByFilter();
        this.currentFilterCfg = {
            DateFilter: { Start: `${currentYear}-01-01`, End: `${currentYear}-12-31` },
            fromLocal: true,
            TimeInterval: 'none',
            DayOfWeek: 0
        };
        this.lastRowsExpanded = [];
        this.ref.detectChanges();
        this.filters.ck_prevyear
        if (redrawTable) this.table.draw();
    }

    training: number = 0;
    showTraining(show: boolean = false) {
        this.training = show ? 2 : 0;
        // 0/3 fa vedere tutto insieme
        // 1 Non fa vedere training
        // 2 Fa vedere solo training
        this.table.draw();
    }

    getFixedCols() {
        return this.showTotals ? 0 : (this.filters.ck_prevyear ? 7 : 4);
    }

    table: any;
    PDFPreferences: any;
    initDataTable() {
        if ($.fn.dataTable.isDataTable('#ceTable')) {
            $('#ceTable').DataTable().destroy();
        }

        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();

            const dtOptions: any = {
                drawCallback: () => {
                    if (this.lastRowsExpanded.length >= 0) {
                        this.lastRowsExpanded.forEach((row: any) => {
                            $(`#ceTable tbody tr:nth-child(${row + 1}) td.parent-row`).trigger('click');
                        })
                    }

                    this.checkScroll();

                    if (this.filters.PLCategoryName) {
                        setTimeout(() => {
                            // Cerco il primo elemento che contiene PLCategoryName
                            const riga = $(`td[data-categoryname="${this.filters.PLCategoryName}" i]`).closest('tr');
                            if (riga.length > 0) riga[0].scrollIntoView();
                        }, 100);
                    }

                    if (this.showTotals) {
                        $('tr.children').hide();
                    }

                    this.loading$.next(false);

                    setTimeout(() => {
                        this.table.columns.adjust().fixedColumns().left(this.getFixedCols());
                    }, 100);

                },
                createdRow: function (row, data, dataIndex) {
                    if (data.IsCover) {
                        $(row).addClass('rowCover');
                        if (data.IsSectionTotal) {
                            $(row).addClass('rowCoverTotal');
                        }
                    }
                    if (data.IsTotal) {
                        $(row).addClass('rowTotal');
                        if (data.IsSection) {
                            $(row).addClass('rowSection');
                        }
                    } else {
                        $(row).attr('data-row', JSON.stringify(data));
                    }
                },
                rowCallback: (row, data, index) => {
                    if (!this.filters.ck_showCover && data.ItemType == 'GenericCover') {
                        $(row).hide();
                    }

                    if (this.showTotals && !(data.IsTotal && data.IsSection)) {
                        $(row).hide();
                    }

                    // Controllo se ho effettuato una ricerca per terzo livello
                    if (this.filters.PLCategoryName && data.Children.length == 0 && !data.IsCover && !data.IsTotal) {
                        $(row).hide();
                    } else if (this.filters.PLCategoryName && data.Children.length > 0 && data.ItemType !== 'GenericCover') {
                        this.table.row($(row)).child(this.format(data, $(row))).show();
                    }
                },
                destroy: true,
                paging: false,
                searching: true,
                dom: 'lrtip',
                ordering: false,
                scrollX: true,
                scrollY: 'calc(100vh - 300px)',
                scrollCollapse: true,
                autoWidth: true,
                order: [],
                processing: true,
                serverSide: true,
                fixedColumns: {
                    leftColumns: this.filters.ck_prevyear ? 7 : 4
                },
                ajax: {
                    url: currentLogin.endpointURL + "/api/PL/Monthly",
                    type: "POST",
                    data: (d: any) => {
                        d.filters = this.currentFilterCfg;
                        d.PLCategoryName = this.filters.PLCategoryName;
                        d.filters.Training = this.training;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        // Se sto filtrando per nome categoria, non posso vedere tutto collassato
                        if (this.filters.PLCategoryName) {
                            this.showTotals = false;
                        }
                        return response.data;
                    }
                },
                columnDefs: [
                    {
                        targets: 0,
                        className: 'parent-row actions',
                        width: '20px',
                        orderable: false,
                        render: (data, type, p, meta) => {
                            return !p.IsTotal && p.Children && p.Children.length > 0 ? '<i class="fas expander pointer"></i>' : '';
                        }
                    },
                    // Name
                    {
                        targets: 1, data: 'Name', name: 'Name', render: (data, type, p) => {
                            let classClickGraph: string = !this.showTotals && !p.IsCover && !p.IsTotal ? 'clickGraph' : '';
                            return `<div class="overflow-ellipses exportText ${classClickGraph}" title="${data}">${data}</div>`;
                        }
                    },
                    {   // Totals #2
                        targets: 2,
                        className: 'text-right',
                        render: (data, type, p) => {
                            const values = p.Columns[0].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
                        },
                        title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.TOTALS') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).set('month', 0).format('YYYY')) + '</div>'
                    },
                    {   // Totals % #3
                        targets: 3,
                        className: 'text-right' + (!this.filters.ck_prevyear ? ' last-fixed-col-left' : ''),
                        render: (data, type, p) => {
                            const values = p.Columns[0].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
                        },
                        title: '<div class="overflow-ellipses-60 exportText">%</div>'
                    },
                    {   // Totals Prec #4
                        targets: 4,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[0].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
                        },
                        title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.TOTALS') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 0).format('YYYY')) + '</div>'
                    },
                    {   // Totals Prec % #5
                        targets: 5,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[0].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
                        },
                        title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },
                    {   // Totals Differenza #6
                        targets: 6,
                        className: 'text-right' + (this.filters.ck_prevyear ? ' last-fixed-col-left' : ''),
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[0].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
                        },
                        title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.DIFF_TOTALS') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 0).format('YYYY')) + '</div>'
                    },

                    // GENNAIO

                    {   // Gennaio #7
                        targets: 7,
                        className: 'text-right',
                        render: (data, type, p) => {
                            const values = p.Columns[1].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).set('month', 0).format('MMM YYYY')) + '</div>'
                    },

                    {   // Gennaio Percentuale #8
                        targets: 8,
                        className: 'text-right',
                        render: (data, type, p) => {
                            const values = p.Columns[1].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Gennaio Precedente #9
                        targets: 9,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[1].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 0).format('MMM YYYY')) + '</div>'
                    },

                    {   // Gennaio Precedente Percentuale #10
                        targets: 10,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[1].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Gennaio Differenza #11
                        targets: 11,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[1].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.DIFFERENZA') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 0).format('MMM YYYY')) + '</div>'
                    },

                    // FEBBRAIO

                    {   // Febbraio
                        targets: 12,
                        className: 'text-right zebra',
                        render: (data, type, p) => {
                            const values = p.Columns[2].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).set('month', 1).format('MMM YYYY')) + '</div>'
                    },

                    {   // Febbraio Percentuale
                        targets: 13,
                        className: 'text-right zebra',
                        render: (data, type, p) => {
                            const values = p.Columns[2].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Febbraio Precedente
                        targets: 14,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[2].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 1).format('MMM YYYY')) + '</div>'
                    },

                    {   // Febbraio Precedente Percentuale
                        targets: 15,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[2].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Febbraio Differenza #16
                        targets: 16,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[2].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.DIFFERENZA') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 1).format('MMM')) + '</div>'
                    },

                    // MARZO from 14

                    {   // Marzo
                        targets: 17,
                        className: 'text-right',
                        render: (data, type, p) => {
                            const values = p.Columns[3].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).set('month', 2).format('MMM YYYY')) + '</div>'
                    },

                    {   // Marzo Percentuale
                        targets: 18,
                        className: 'text-right',
                        render: (data, type, p) => {
                            const values = p.Columns[3].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Marzo Precedente
                        targets: 19,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[3].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 2).format('MMM YYYY')) + '</div>'
                    },

                    {   // Marzo Precedente Percentuale
                        targets: 20,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[3].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Marzo Differenza #21
                        targets: 21,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[3].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.DIFFERENZA') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 2).format('MMM')) + '</div>'
                    },

                    // APRILE from 19

                    {   // Aprile
                        targets: 22,
                        className: 'text-right zebra',
                        render: (data, type, p) => {
                            const values = p.Columns[4].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).set('month', 3).format('MMM YYYY')) + '</div>'
                    },

                    {   // Aprile Percentuale
                        targets: 23,
                        className: 'text-right zebra',
                        render: (data, type, p) => {
                            const values = p.Columns[4].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Aprile Precedente
                        targets: 24,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[4].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 3).format('MMM YYYY')) + '</div>'
                    },

                    {   // Aprile Precedente Percentuale
                        targets: 25,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[4].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Aprile Differenza #26
                        targets: 26,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[2].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.DIFFERENZA') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 3).format('MMM')) + '</div>'
                    },

                    // MAGGIO from 27

                    {   // Maggio
                        targets: 27,
                        className: 'text-right',
                        render: (data, type, p) => {
                            const values = p.Columns[5].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).set('month', 4).format('MMM YYYY')) + '</div>'
                    },

                    {   // Maggio Percentuale
                        targets: 28,
                        className: 'text-right',
                        render: (data, type, p) => {
                            const values = p.Columns[5].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Maggio Precedente
                        targets: 29,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[5].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 4).format('MMM YYYY')) + '</div>'
                    },

                    {   // Maggio Precedente Percentuale
                        targets: 30,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[5].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Maggio Differenza #31
                        targets: 31,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[5].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.DIFFERENZA') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 4).format('MMM')) + '</div>'
                    },

                    // GIUGNO from 32

                    {   // Giugno
                        targets: 32,
                        className: 'text-right zebra',
                        render: (data, type, p) => {
                            const values = p.Columns[6].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).set('month', 5).format('MMM YYYY')) + '</div>'
                    },

                    {   // Giugno Percentuale
                        targets: 33,
                        className: 'text-right zebra',
                        render: (data, type, p) => {
                            const values = p.Columns[6].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Giugno Precedente
                        targets: 34,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[6].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 5).format('MMM YYYY')) + '</div>'
                    },

                    {   // Giugno Precedente Percentuale
                        targets: 35,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[6].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Giugno Differenza #36
                        targets: 36,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[6].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.DIFFERENZA') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 5).format('MMM')) + '</div>'
                    },

                    // LUGLIO

                    {   // Luglio from 37
                        targets: 37,
                        className: 'text-right',
                        render: (data, type, p) => {
                            const values = p.Columns[7].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).set('month', 6).format('MMM YYYY')) + '</div>'
                    },

                    {   // Luglio Percentuale
                        targets: 38,
                        className: 'text-right',
                        render: (data, type, p) => {
                            const values = p.Columns[7].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Luglio Precedente
                        targets: 39,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[7].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 6).format('MMM YYYY')) + '</div>'
                    },

                    {   // Luglio Precedente Percentuale
                        targets: 40,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[7].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Luglio Differenza #41
                        targets: 41,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[7].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.DIFFERENZA') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 6).format('MMM')) + '</div>'
                    },

                    // AGOSTO

                    {   // Agosto from 42
                        targets: 42,
                        className: 'text-right zebra',
                        render: (data, type, p) => {
                            const values = p.Columns[8].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).set('month', 7).format('MMM YYYY')) + '</div>'
                    },

                    {   // Agosto Percentuale
                        targets: 43,
                        className: 'text-right zebra',
                        render: (data, type, p) => {
                            const values = p.Columns[8].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Agosto Precedente
                        targets: 44,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[8].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 7).format('MMM YYYY')) + '</div>'
                    },

                    {   // Agosto Precedente Percentuale
                        targets: 45,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[8].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Agosto Differenza #46
                        targets: 46,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[2].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.DIFFERENZA') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 7).format('MMM')) + '</div>'
                    },

                    // SETTEMBRE from 47

                    {   // Settembre
                        targets: 47,
                        className: 'text-right',
                        render: (data, type, p) => {
                            const values = p.Columns[9].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).set('month', 8).format('MMM YYYY')) + '</div>'
                    },

                    {   // Settembre Percentuale
                        targets: 48,
                        className: 'text-right',
                        render: (data, type, p) => {
                            const values = p.Columns[9].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Settembre Precedente
                        targets: 49,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[9].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 8).format('MMM YYYY')) + '</div>'
                    },

                    {   // Settembre Precedente Percentuale
                        targets: 50,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[9].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Settembre Differenza #51
                        targets: 51,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[9].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.DIFFERENZA') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 8).format('MMM')) + '</div>'
                    },

                    // OTTOBRE

                    {   // Ottobre from 52
                        targets: 52,
                        className: 'text-right zebra',
                        render: (data, type, p) => {
                            const values = p.Columns[10].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).set('month', 9).format('MMM YYYY')) + '</div>'
                    },

                    {   // Ottobre Percentuale
                        targets: 53,
                        className: 'text-right zebra',
                        render: (data, type, p) => {
                            const values = p.Columns[10].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Ottobre Precedente
                        targets: 54,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[10].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 9).format('MMM YYYY')) + '</div>'
                    },

                    {   // Ottobre Precedente Percentuale
                        targets: 55,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[10].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Ottobre Differenza #56
                        targets: 56,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[10].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.DIFFERENZA') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 9).format('MMM')) + '</div>'
                    },

                    // NOVEMBRE

                    {   // Novembre from 57
                        targets: 57,
                        className: 'text-right',
                        render: (data, type, p) => {
                            const values = p.Columns[11].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).set('month', 10).format('MMM YYYY')) + '</div>'
                    },

                    {   // Novembre Percentuale
                        targets: 58,
                        className: 'text-right',
                        render: (data, type, p) => {
                            const values = p.Columns[11].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Novembre Precedente
                        targets: 59,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[11].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 10).format('MMM YYYY')) + '</div>'
                    },

                    {   // Novembre Precedente Percentuale
                        targets: 60,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[11].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Novembre Differenza #61
                        targets: 61,
                        className: 'text-right',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[11].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.DIFFERENZA') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 10).format('MMM')) + '</div>'
                    },

                    // DICEMBRE

                    {   // Dicembre from 62
                        targets: 62,
                        className: 'text-right zebra',
                        render: (data, type, p) => {
                            const values = p.Columns[12].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values)}">${this.getValue(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).set('month', 11).format('MMM YYYY')) + '</div>'
                    },

                    {   // Dicembre Percentuale
                        targets: 63,
                        className: 'text-right zebra',
                        render: (data, type, p) => {
                            const values = p.Columns[12].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values)}">${this.getPerc(values)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Dicembre Precedente
                        targets: 64,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[12].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getValue(values, true)}">${this.getValue(values, true)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 11).format('MMM YYYY')) + '</div>'
                    },

                    {   // Dicembre Precedente Percentuale
                        targets: 65,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[12].SubColumns;
                            return `<div class="overflow-ellipses-60 exportText" title="${this.getPerc(values, true)}">${this.getPerc(values, true)}</div>`;
                        }, title: `<div class="overflow-ellipses-60 exportText">${(this.filters.ck_abs ? "% abs" : "% rel")}</div>`
                    },

                    {   // Dicembre Differenza #66
                        targets: 66,
                        className: 'text-right zebra',
                        visible: this.filters.ck_prevyear,
                        render: (data, type, p) => {
                            const values = p.Columns[12].SubColumns;
                            return `<div class="overflow-ellipses-100 exportText" title="${this.getDiff(values)}">${this.getDiff(values)}</div>`;
                        }, title: '<div class="overflow-ellipses-100 exportText">' + this.translate.instant('CONTO_ECONOMICO.MONTHLY_VIEW.COLUMNS.DIFFERENZA') + " " + this.utility.capitalize(moment().set('year', this.getYearByFilter()).subtract(1, 'years').set('month', 11).format('MMM')) + '</div>'
                    },

                    // Serve per evitare di riceve avvisi quando arrivano campi a null
                    {
                        defaultContent: '-',
                        targets: '_all'
                    }
                ],
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)'
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, ['auto', '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            //doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
                            //doc.defaultStyle = docDefinition.defaultStyle;
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = 'landscape'; //docDefinition.pageOrientation;
                            doc.pageSize = this.filters.ck_prevyear ? 'A2' : 'A3'; //docDefinition.pageSize;

                            doc.styles = docDefinition.styles;

                            Object.keys(doc.styles).forEach(key => {
                                if (key.toLowerCase().indexOf('table') >= 0) {
                                    doc.styles[key].fontSize = this.filters.ck_prevyear ? 3.5 : 7;
                                }
                            })

                            var rowData = this.table.rows({ order: 'applied', search: 'applied' }).data();

                            // export row child
                            //http://live.datatables.net/hepopote/1/edit

                            if (this.expandRows) {

                                var headerLines = 0;  // Offset for accessing rowData array

                                var newBody: any = []; // this will become our new body (an array of arrays(lines))
                                //Loop over all lines in the table
                                doc.content[1].table.body.forEach((line, i) => {

                                    if (line[0].style !== 'tableHeader' && line[0].style !== 'tableFooter') {
                                        line[0]['style'] = rowData[i - headerLines].IsTotal ? 'tableBodyTotal' : 'tableBodyParent';
                                        for (let index = 1; index < line.length; index++) {
                                            line[index]['alignment'] = 'right';
                                            line[index]['style'] = rowData[i - headerLines].IsTotal ? 'tableBodyTotal' : 'tableBodyParent';
                                        }
                                    }

                                    // Remove detail-control column
                                    newBody.push(line);

                                    if (line[0].style !== 'tableHeader' && line[0].style !== 'tableFooter') {

                                        var data = rowData[i - headerLines];
                                        // Append child data, matching number of columns in table
                                        if (data.Children && data.Children.length > 0) {

                                            data.Children.forEach(child => {

                                                const columns = child.Columns;

                                                let columnsValues: any = [
                                                    { text: '- ' + child.Name, style: 'tableBodyStyle' },
                                                ];

                                                for (var col = 1; col < 66; col++) {

                                                    const month = Math.floor((col - 1) / 5);
                                                    if (this.table.column(col + 1).visible()) {
                                                        let text = '';
                                                        if ((col - 1) % 5 == 0) {
                                                            text = this.getValue(columns[month].SubColumns);
                                                        } else if ((col - 1) % 5 == 1) {
                                                            text = this.getPerc(columns[month].SubColumns);
                                                        } else if ((col - 1) % 5 == 2) {
                                                            text = this.getValue(columns[month].SubColumns, true);
                                                        } else if ((col - 1) % 5 == 3) {
                                                            text = this.getPerc(columns[month].SubColumns, true);
                                                        } else if ((col - 1) % 5 == 4) {
                                                            text = this.getDiff(columns[month].SubColumns);
                                                        }

                                                        columnsValues.push({ text: text, alignment: 'right', style: 'tableBodyStyle' });
                                                    }
                                                }

                                                newBody.push(columnsValues);

                                            })
                                        }

                                    } else {
                                        headerLines++;
                                    }

                                });

                                doc.content[1].table.body = newBody;
                            } else {
                                // Sostituisco il text danger con colore rosso
                                doc.content[1].table.body.forEach((line, i) => {
                                    if (i > 0 && rowData[i - 1].IsTotal) {
                                        line.forEach(col => col.style = 'tableBodyTotal');
                                    }
                                });
                            }


                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        //autoFilter: true,
                        //sheetName: 'Exported data',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)',
                            format: {
                                body: (data, row, column, node) => {
                                    if (data.indexOf('exportText') >= 0) {
                                        var parser = new DOMParser();
                                        var htmlDoc = parser.parseFromString(data, 'text/html');
                                        const htmlValue = $(htmlDoc).find('.exportText').text();
                                        // dalla colonna 1 in avanti formatto
                                        const value = column >= 1 ? this.utility.parseNumber(htmlValue, this.locale) : htmlValue;
                                        return value;
                                    } else {
                                        // dalla colonna 1 in avanti formatto
                                        const value = column >= 1 ? this.utility.parseNumber(data, this.locale) : data;
                                        return value;
                                    }
                                }
                            },
                        },
                        customize: async (xlsx: any) => {

                            XLSX.SSF.setlocale("de-DE");

                            var sSh = xlsx.xl['styles.xml'];
                            var lastXfIndex = $('cellXfs xf', sSh).length - 1;
                            // Bold Text - Yellow
                            var s1 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"0\" fontId=\"2\" fillId=\"6\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" />';
                            // Bold Text - Yellow - Alignment Right
                            var s2 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"4\" fontId=\"2\" fillId=\"6\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" xfId="0" applyNumberFormat="1"><alignment horizontal=\"right\"/></xf>';
                            // Bold Text - Alignment Right
                            var s3 = '<xf xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\" numFmtId=\"4\" fontId=\"2\" fillId=\"0\" borderId=\"0\" applyFont=\"1\" applyFill=\"1\" applyBorder=\"1\" xfId="0" applyNumberFormat="1"><alignment horizontal=\"right\"/></xf>';

                            sSh.childNodes[0].childNodes[5].innerHTML = sSh.childNodes[0].childNodes[5].innerHTML + s1 + s2 + s3;
                            const fillYellow = '<fill xmlns=\"http://schemas.openxmlformats.org/spreadsheetml/2006/main\"><patternFill patternType=\"solid\"><fgColor rgb=\"FFF1E8C1\" /><bgColor indexed=\"64\" /></patternFill></fill>';
                            sSh.childNodes[0].childNodes[2].innerHTML = sSh.childNodes[0].childNodes[2].innerHTML + fillYellow;
                            var boldTextYellowFillIndex = lastXfIndex + 1;
                            var boldTextYellowFillAlignRightIndex = lastXfIndex + 2;
                            var boldTextAlignRightIndex = lastXfIndex + 3;

                            // Get sheet.
                            var sheet = xlsx.xl.worksheets['sheet1.xml'];

                            // Get a clone of the sheet data.        
                            var sheetData = $('sheetData', sheet).clone();

                            // Clear the current sheet data for appending rows.
                            $('sheetData', sheet).empty();

                            // Row index from last column.
                            var DT_row = 0;        // Row count in Excel sheet.

                            var rowCount = 1;

                            const numToAlpha = (num: number) => {
                                var alpha = '';
                                for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
                                    alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
                                }
                                return alpha;
                            }

                            // Itereate each row in the sheet data.
                            $(sheetData).children().each((index, element) => {

                                // Don't process row if its the header row.
                                if (index > 1) {

                                    // Get row
                                    var row: any = $(element);

                                    // Set the Excel row attr to the current Excel row count.
                                    row.attr('r', rowCount);

                                    // Iterate each cell in the row to change the rwo number.
                                    row.children().each((index, element) => {
                                        var cell = $(element);

                                        // Set each cell's row value.
                                        var rc: any = cell.attr('r');
                                        rc = rc.replace(/\d+$/, "") + rowCount;
                                        cell.attr('r', rc);

                                    });

                                    // Get the row HTML and append to sheetData.
                                    row = row[0].outerHTML;
                                    $('sheetData', sheet).append(row);
                                    // Get the child data - could be any data attached to the row.
                                    const rowData = this.table.row(DT_row, { search: 'none', order: 'index' }).data();

                                    // Applico lo style ai parent
                                    //https://datatables.net/reference/button/excelHtml5
                                    if (rowData && rowData.IsTotal && rowData.IsSection) {
                                        // Imposto tutta la riga con lo stile boldTextYellowFillAlignRightIndex
                                        $(`row:nth-child(${rowCount}) c`, sheet).attr('s', boldTextYellowFillAlignRightIndex);
                                        // Imposto la prima cella con lo stile boldTextYellowFillIndex
                                        $(`row c[r="A${rowCount}"]`, sheet).attr('s', boldTextYellowFillIndex);
                                    } else if (rowData && rowData.IsTotal) {
                                        // Imposto la prima cella con lo stile bold
                                        $(`row c[r="A${rowCount}"]`, sheet).attr('s', '2');
                                        // Imposto il resto della riga con lo stile grassetto
                                        $(`row:nth-child(${rowCount}) c:gt(0)`, sheet).attr('s', boldTextAlignRightIndex);
                                    } else if (rowData) {
                                        // Imposto tutta la riga con l'allineamento a destra
                                        $(`row:nth-child(${rowCount}) c`, sheet).attr('s', '64');
                                        // Imposto la prima cella con l'allineamento a sinistra
                                        $(`row c[r="A${rowCount}"]`, sheet).attr('s', '50');
                                    } else {
                                        return;
                                    }

                                    rowCount++;

                                    if (this.expandRows) {
                                        const childData = rowData && rowData.Children ? rowData.Children : [];
                                        // The child data is an array of rows
                                        for (let c = 0; c < childData.length; c++) {
                                            // Get row data.
                                            const child = childData[c];

                                            let columnsValues: any = [];
                                            const columns = child.Columns;

                                            // uso effectiveColIndex perchè quando salto una colonna perchè è 
                                            // inivisibile, in realtà sull'excel non la salto, e la numerazione deve essere sequenziale
                                            let effectiveColIndex = 1;
                                            for (var col = 1; col < 66; col++) {
                                                const month = Math.floor((col - 1) / 5);
                                                if (this.table.column(col + 1).visible()) {
                                                    let text = '';
                                                    if ((col - 1) % 5 == 0) {
                                                        text = this.getValueExcel(columns[month].SubColumns);
                                                    } else if ((col - 1) % 5 == 1) {
                                                        text = this.getPercExcel(columns[month].SubColumns);
                                                    } else if ((col - 1) % 5 == 2) {
                                                        text = this.getValueExcel(columns[month].SubColumns, true);
                                                    } else if ((col - 1) % 5 == 3) {
                                                        text = this.getPercExcel(columns[month].SubColumns, true);
                                                    } else if ((col - 1) % 5 == 4) {
                                                        text = this.getDiffExcel(columns[month].SubColumns);
                                                    }

                                                    columnsValues.push(`<c t="n" r="${numToAlpha(effectiveColIndex++)}${rowCount}" s="64"><v>${text}</v></c>`);
                                                }
                                            }

                                            const childRow = `<row r="${rowCount}">
                                          <c t="inlineStr" r="A${rowCount}" s="52"><is><t>${child.Name.replace('&', 'o')}</t></is></c>
                                          ${columnsValues.join(' ')}
                                        </row>`;

                                            // Append row to sheetData.
                                            try {
                                                $('sheetData', sheet).append(childRow);
                                                // Allineo la child row a destra
                                                //$('row:nth-child(' + rowCount + ') c', sheet).attr('s', '52');
                                                rowCount++; // Inc excelt row counter.                                           
                                            } catch (error) {
                                                console.log(error, childRow);
                                            }
                                        }
                                    }

                                    DT_row++;

                                    // Just append the header row and increment the excel row counter.
                                } else {
                                    var row: any = $(element);
                                    row = row[0].outerHTML;
                                    $('sheetData', sheet).append(row);
                                    rowCount++;
                                }
                            });

                            console.log($('sheetData', sheet)[0].innerHTML);

                        }
                    }
                ]
            };

            this.table = $('#ceTable').DataTable(dtOptions);

            window.table = this.table;

            // Add event listener for opening and closing details
            $('#ceTable tbody').off('click');

            $('#ceTable tbody').on('click', 'td.parent-row', (event: any) => {
                const tr = $(event.target).closest('tr');
                const row = this.table.row(tr);

                if (row.child.isShown()) {
                    this.lastRowsExpanded = this.lastRowsExpanded.filter((row: any) => row != tr.index());
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('shown');
                } else {

                    // Devo fare così prerchè con lefixedColumns non riesce a prendere i dati,
                    const rowData = tr.data('row');//row.data();
                    // Open this row
                    if (rowData) {
                        if (!this.lastRowsExpanded.includes(tr.index()))
                            this.lastRowsExpanded.push(tr.index());

                        row.child(this.format(rowData, tr)).show();
                        tr.addClass('shown');
                    }
                }

                this.table.columns.adjust().fixedColumns().left(this.getFixedCols());
            });

            $('#ceTable tbody').on('click', 'a.add_ce', (event: any) => {
                const tr = $(event.target).closest('tr');
                //const row = this.table.row(tr);
                const data = tr.data('row');

                if (data) {
                    this.addCE(data);
                }
            });

            $('#ceTable tbody').on('click', 'a.detail_ce', (event: any) => {
                const tr = $(event.target).closest('tr');
                //const row = this.table.row(tr);
                const data = tr.data('row');

                if (data) {
                    this.detailCE(data);
                }
            });

            $('#ceTable tbody').on('click', '.clickGraph', (event: any) => {
                const tr = $(event.target).closest('tr');
                const data = tr.data('row');
                if (data) {
                    this.onClickGraph.emit(data);
                }
            });

        }, 100);

    }

    filterByPLName($event: KeyboardEvent) {
        if ($event.code == 'Enter') {
            this.table.draw();
        }
    }

    refreshColumnsVisibility(event: any, filter: string) {
        if (filter == 'ck_prevyear') {
            this.table.columns([4, 5, 6, 9, 10, 11, 14, 15, 16, 19, 20, 21, 24, 25, 26, 29, 30, 31, 34, 35, 36, 39, 40, 41, 44, 45, 46, 49, 50, 51, 54, 55, 56, 59, 60, 61, 64, 65, 66]).visible(this.filters.ck_prevyear);

            if (this.expandAll) {
                this.expandAllRows();
                setTimeout(() => {
                    this.expandAllRows();
                }, 100);
            } else {
                this.table.fixedColumns().left(this.getFixedCols());
            }

            //this.ref.detectChanges();
        } else if (filter == 'ck_abs') {
            this.table.rows().invalidate().draw();
            let text = '% rel';
            if (event) {
                text = '% abs';
            }
            this.table.columns([3, 5, 8, 10, 13, 15, 18, 20, 23, 25, 28, 30, 33, 35, 38, 40, 43, 45, 48, 50, 53, 55, 58, 60, 63, 65]).header().to$().text(text);

        } else {
            this.table.rows().invalidate().draw();
        }

        $('table td').removeClass('last-fixed-col-left');
        if (this.filters.ck_prevyear) {
            $('table td.dtfc-fixed-left:nth-child(7)').addClass('last-fixed-col-left');
        } else {
            $('table td.dtfc-fixed-left:nth-child(4)').addClass('last-fixed-col-left');
        }
    }

    expandAll: boolean = false;
    expandAllRows() {
        this.expandAll = !this.expandAll;
        this.lastRowsExpanded = [];
        const trList = $('#ceTable tbody tr');
        trList.each((index: number, tr: any) => {
            const row = this.table.row(tr);

            if (!this.expandAll) {
                // This row is already open - close it
                row.child.hide();
                $(tr).removeClass('shown');
            } else {

                if (!this.lastRowsExpanded.includes(index))
                    this.lastRowsExpanded.push(index);

                // Open this row
                const data = $(tr).data('row');
                if (data) {
                    row.child(this.format(row.data(), $(tr))).show();
                    $(tr).addClass('shown');
                }
            }
        });

        setTimeout(() => {
            this.table.fixedColumns().left(this.getFixedCols());
            this.table.columns.adjust();
            setTimeout(() => {
                this.table.fixedColumns().left(this.getFixedCols());
            }, 1000);
        }, 100);
    }

    getValue(values: any, prec: boolean = false) {
        if (this.filters.ck_lordo) {
            return prec ? this.utility.formatNumber(values.PreviousGrossValue, this.locale) : this.utility.formatNumber(values.CurrentGrossValue, this.locale)
        } else {
            return prec ? this.utility.formatNumber(values.PreviousValue, this.locale) : this.utility.formatNumber(values.CurrentValue, this.locale)
        }
    }

    getPerc(values: any, prec: boolean = false) {
        if (this.filters.ck_lordo) {
            if (this.filters.ck_abs) {
                if (this.filters.percTotale) {
                    return prec ? this.utility.formatNumber(values.PreviousValuePercAbsGrossTotal, this.locale) : this.utility.formatNumber(values.CurrentValuePercAbsGrossTotal, this.locale)
                }
                else {
                    return prec ? this.utility.formatNumber(values.PreviousValuePercAbsGross, this.locale) : this.utility.formatNumber(values.CurrentValuePercAbsGross, this.locale)
                }

            } else {
                if (this.filters.percTotale) {
                    return prec ? this.utility.formatNumber(values.PreviousValuePercRelGrossTotal, this.locale) : this.utility.formatNumber(values.CurrentValuePercRelGrossTotal, this.locale)
                }
                else {
                    return prec ? this.utility.formatNumber(values.PreviousValuePercRelGross, this.locale) : this.utility.formatNumber(values.CurrentValuePercRelGross, this.locale)
                }

            }
        } else {
            if (this.filters.ck_abs) {
                if (this.filters.percTotale) {
                    return prec ? this.utility.formatNumber(values.PreviousValuePercAbsTotal, this.locale) : this.utility.formatNumber(values.CurrentValuePercAbsTotal, this.locale)
                }
                else {
                    return prec ? this.utility.formatNumber(values.PreviousValuePercAbs, this.locale) : this.utility.formatNumber(values.CurrentValuePercAbs, this.locale)
                }

            } else {
                if (this.filters.percTotale) {
                    return prec ? this.utility.formatNumber(values.PreviousValuePercRelTotal, this.locale) : this.utility.formatNumber(values.CurrentValuePercRelTotal, this.locale)
                }
                else {
                    return prec ? this.utility.formatNumber(values.PreviousValuePercRel, this.locale) : this.utility.formatNumber(values.CurrentValuePercRel, this.locale)
                }

            }
        }
    }

    getDiff(values: any) {
        if (this.filters.ck_lordo) {
            if (this.filters.scostamento) {
                return this.utility.formatNumber(values.PreviousGrossDeviation, this.locale)
            } else {
                return this.utility.formatNumber(values.PreviousGrossDelta, this.locale)
            }
        } else {
            if (this.filters.scostamento) {
                return this.utility.formatNumber(values.PreviousDeviation, this.locale)
            } else {
                return this.utility.formatNumber(values.PreviousDelta, this.locale)
            }
        }
    }

    getValueExcel(values: any, prec: boolean = false) {
        if (this.filters.ck_lordo) {
            return prec ? this.utility.parseNumber(values.PreviousGrossValue, this.locale, 2) : this.utility.parseNumber(values.CurrentGrossValue, this.locale, 2)
        } else {
            return prec ? this.utility.parseNumber(values.PreviousValue, this.locale, 2) : this.utility.parseNumber(values.CurrentValue, this.locale, 2)
        }
    }

    getPercExcel(values: any, prec: boolean = false) {
        if (this.filters.ck_lordo) {
            if (this.filters.ck_abs) {
                if (this.filters.percTotale) {
                    return prec ? this.utility.parseNumber(values.PreviousValuePercAbsGrossTotal, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercAbsGrossTotal, this.locale, 2)
                }
                else {
                    return prec ? this.utility.parseNumber(values.PreviousValuePercAbsGross, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercAbsGross, this.locale, 2)
                }

            } else {
                if (this.filters.percTotale) {
                    return prec ? this.utility.parseNumber(values.PreviousValuePercRelGrossTotal, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercRelGrossTotal, this.locale, 2)
                }
                else {
                    return prec ? this.utility.parseNumber(values.PreviousValuePercRelGross, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercRelGross, this.locale, 2)
                }

            }
        } else {
            if (this.filters.ck_abs) {
                if (this.filters.percTotale) {
                    return prec ? this.utility.parseNumber(values.PreviousValuePercAbsTotal, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercAbsTotal, this.locale, 2)
                }
                else {
                    return prec ? this.utility.parseNumber(values.PreviousValuePercAbs, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercAbs, this.locale, 2)
                }

            } else {
                if (this.filters.percTotale) {
                    return prec ? this.utility.parseNumber(values.PreviousValuePercRelTotal, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercRelTotal, this.locale, 2)
                }
                else {
                    return prec ? this.utility.parseNumber(values.PreviousValuePercRel, this.locale, 2) : this.utility.parseNumber(values.CurrentValuePercRel, this.locale, 2)
                }

            }
        }
    }

    getDiffExcel(values: any) {
        if (this.filters.ck_lordo) {
            if (this.filters.scostamento) {
                return this.utility.parseNumber(values.PreviousGrossDeviation, this.locale, 2)
            } else {
                return this.utility.parseNumber(values.PreviousGrossDelta, this.locale, 2)
            }
        } else {
            if (this.filters.scostamento) {
                return this.utility.parseNumber(values.PreviousDeviation, this.locale, 2)
            } else {
                return this.utility.parseNumber(values.PreviousDelta, this.locale, 2)
            }
        }
    }

    /* Formatting function for row details - modify as you need */
    format(row: any, trElement: any) {
        if (!row) return;
        let subRows: string = '';

        const col0Style = trElement.find('td:nth-child(1)').attr('style');
        const col1Style = trElement.find('td:nth-child(2)').attr('style');
        const col2Style = trElement.find('td:nth-child(3)').attr('style');
        const col3Style = trElement.find('td:nth-child(4)').attr('style');
        const col4Style = trElement.find('td:nth-child(5)').attr('style');
        const col5Style = trElement.find('td:nth-child(6)').attr('style');
        const col6Style = trElement.find('td:nth-child(7)').attr('style');

        row.Children.forEach((child: any) => {

            const columns = child.Columns;

            subRows += `<tr id='${child.Id}' data-rowid='${row.Id}' class="children" data-row='${JSON.stringify(columns[0].SubColumns)}'>`;
            // Icona +/-
            subRows += '<td class="dtfc-fixed-left" style="' + col0Style + '"></td>';
            // Nome sottocategoria CE
            subRows += `<td class="text-right dtfc-fixed-left" style="${col1Style}" data-categoryname="${child.Name}">
                            <span class="clickGraph">
                                ${child.Name}
                            </span>
                        </td>`;
            // Totale
            subRows += '<td class="text-right dtfc-fixed-left" style="' + col2Style + '">' + this.getValue(columns[0].SubColumns) + ' </td>';
            // Totale %
            if (this.table.column(6).visible()) {
                subRows += '<td class="text-right" style="' + col3Style + '">' + this.getPerc(columns[0].SubColumns) + ' </td>';
            } else {
                subRows += '<td class="text-right last-fixed-col-left" style="' + col3Style + '">' + this.getPerc(columns[0].SubColumns) + ' </td>';
            }
            //Totale prec
            if (this.table.column(4).visible()) subRows += '<td class="text-right dtfc-fixed-left" style="' + col4Style + '">' + this.getValue(columns[0].SubColumns, true) + '</td>';
            //Totale prec %
            if (this.table.column(5).visible()) subRows += '<td class="text-right dtfc-fixed-left" style="' + col5Style + '">' + this.getPerc(columns[0].SubColumns, true) + '</td>';
            // Diff Totale
            if (this.table.column(6).visible()) subRows += '<td class="text-right last-fixed-col-left" style="' + col6Style + '">' + this.getDiff(columns[0].SubColumns) + ' </td>';

            // Gennaio
            subRows += '<td class="text-right">' + this.getValue(columns[1].SubColumns) + ' </td>';
            subRows += '<td class="text-right">' + this.getPerc(columns[1].SubColumns) + ' </td>';
            if (this.table.column(9).visible()) subRows += '<td class="text-right">' + this.getValue(columns[1].SubColumns, true) + '</td>';
            if (this.table.column(10).visible()) subRows += '<td class="text-right">' + this.getPerc(columns[1].SubColumns, true) + '</td>';
            if (this.table.column(11).visible()) subRows += '<td class="text-right">' + this.getDiff(columns[1].SubColumns) + ' </td>';
            // Febbraio
            subRows += '<td class="text-right">' + this.getValue(columns[2].SubColumns) + ' </td>';
            subRows += '<td class="text-right">' + this.getPerc(columns[2].SubColumns) + ' </td>';
            if (this.table.column(14).visible()) subRows += '<td class="text-right">' + this.getValue(columns[2].SubColumns, true) + '</td>';
            if (this.table.column(15).visible()) subRows += '<td class="text-right">' + this.getPerc(columns[2].SubColumns, true) + '</td>';
            if (this.table.column(16).visible()) subRows += '<td class="text-right">' + this.getDiff(columns[2].SubColumns) + ' </td>';
            // Marzo
            subRows += '<td class="text-right">' + this.getValue(columns[3].SubColumns) + ' </td>';
            subRows += '<td class="text-right">' + this.getPerc(columns[3].SubColumns) + ' </td>';
            if (this.table.column(19).visible()) subRows += '<td class="text-right">' + this.getValue(columns[3].SubColumns, true) + '</td>';
            if (this.table.column(20).visible()) subRows += '<td class="text-right">' + this.getPerc(columns[3].SubColumns, true) + '</td>';
            if (this.table.column(21).visible()) subRows += '<td class="text-right">' + this.getDiff(columns[3].SubColumns) + ' </td>';
            // Aprile
            subRows += '<td class="text-right">' + this.getValue(columns[4].SubColumns) + ' </td>';
            subRows += '<td class="text-right">' + this.getPerc(columns[4].SubColumns) + ' </td>';
            if (this.table.column(24).visible()) subRows += '<td class="text-right">' + this.getValue(columns[4].SubColumns, true) + '</td>';
            if (this.table.column(25).visible()) subRows += '<td class="text-right">' + this.getPerc(columns[4].SubColumns, true) + '</td>';
            if (this.table.column(26).visible()) subRows += '<td class="text-right">' + this.getDiff(columns[4].SubColumns) + ' </td>';
            // Maggio
            subRows += '<td class="text-right">' + this.getValue(columns[5].SubColumns) + ' </td>';
            subRows += '<td class="text-right">' + this.getPerc(columns[5].SubColumns) + ' </td>';
            if (this.table.column(29).visible()) subRows += '<td class="text-right">' + this.getValue(columns[5].SubColumns, true) + '</td>';
            if (this.table.column(30).visible()) subRows += '<td class="text-right">' + this.getPerc(columns[5].SubColumns, true) + '</td>';
            if (this.table.column(31).visible()) subRows += '<td class="text-right">' + this.getDiff(columns[5].SubColumns) + ' </td>';
            // Giugno
            subRows += '<td class="text-right">' + this.getValue(columns[6].SubColumns) + ' </td>';
            subRows += '<td class="text-right">' + this.getPerc(columns[6].SubColumns) + ' </td>';
            if (this.table.column(34).visible()) subRows += '<td class="text-right">' + this.getValue(columns[6].SubColumns, true) + '</td>';
            if (this.table.column(35).visible()) subRows += '<td class="text-right">' + this.getPerc(columns[6].SubColumns, true) + '</td>';
            if (this.table.column(36).visible()) subRows += '<td class="text-right">' + this.getDiff(columns[6].SubColumns) + ' </td>';
            // Luglio
            subRows += '<td class="text-right">' + this.getValue(columns[7].SubColumns) + ' </td>';
            subRows += '<td class="text-right">' + this.getPerc(columns[7].SubColumns) + ' </td>';
            if (this.table.column(39).visible()) subRows += '<td class="text-right">' + this.getValue(columns[7].SubColumns, true) + '</td>';
            if (this.table.column(40).visible()) subRows += '<td class="text-right">' + this.getPerc(columns[7].SubColumns, true) + '</td>';
            if (this.table.column(41).visible()) subRows += '<td class="text-right">' + this.getDiff(columns[7].SubColumns) + ' </td>';
            // Agosto
            subRows += '<td class="text-right">' + this.getValue(columns[8].SubColumns) + ' </td>';
            subRows += '<td class="text-right">' + this.getPerc(columns[8].SubColumns) + ' </td>';
            if (this.table.column(44).visible()) subRows += '<td class="text-right">' + this.getValue(columns[8].SubColumns, true) + '</td>';
            if (this.table.column(45).visible()) subRows += '<td class="text-right">' + this.getPerc(columns[8].SubColumns, true) + '</td>';
            if (this.table.column(46).visible()) subRows += '<td class="text-right">' + this.getDiff(columns[8].SubColumns) + ' </td>';
            // Settembre
            subRows += '<td class="text-right">' + this.getValue(columns[9].SubColumns) + ' </td>';
            subRows += '<td class="text-right">' + this.getPerc(columns[9].SubColumns) + ' </td>';
            if (this.table.column(49).visible()) subRows += '<td class="text-right">' + this.getValue(columns[9].SubColumns, true) + '</td>';
            if (this.table.column(50).visible()) subRows += '<td class="text-right">' + this.getPerc(columns[9].SubColumns, true) + '</td>';
            if (this.table.column(51).visible()) subRows += '<td class="text-right">' + this.getDiff(columns[9].SubColumns) + ' </td>';
            // Ottobre
            subRows += '<td class="text-right">' + this.getValue(columns[10].SubColumns) + ' </td>';
            subRows += '<td class="text-right">' + this.getPerc(columns[10].SubColumns) + ' </td>';
            if (this.table.column(54).visible()) subRows += '<td class="text-right">' + this.getValue(columns[10].SubColumns, true) + '</td>';
            if (this.table.column(55).visible()) subRows += '<td class="text-right">' + this.getPerc(columns[10].SubColumns, true) + '</td>';
            if (this.table.column(56).visible()) subRows += '<td class="text-right">' + this.getDiff(columns[10].SubColumns) + ' </td>';
            // Novembre
            subRows += '<td class="text-right">' + this.getValue(columns[11].SubColumns) + ' </td>';
            subRows += '<td class="text-right">' + this.getPerc(columns[11].SubColumns) + ' </td>';
            if (this.table.column(50).visible()) subRows += '<td class="text-right">' + this.getValue(columns[11].SubColumns, true) + '</td>';
            if (this.table.column(60).visible()) subRows += '<td class="text-right">' + this.getPerc(columns[11].SubColumns, true) + '</td>';
            if (this.table.column(61).visible()) subRows += '<td class="text-right">' + this.getDiff(columns[11].SubColumns) + ' </td>';
            // Dicembre
            subRows += '<td class="text-right">' + this.getValue(columns[12].SubColumns) + ' </td>';
            subRows += '<td class="text-right">' + this.getPerc(columns[12].SubColumns) + ' </td>';
            if (this.table.column(64).visible()) subRows += '<td class="text-right">' + this.getValue(columns[12].SubColumns, true) + '</td>';
            if (this.table.column(65).visible()) subRows += '<td class="text-right">' + this.getPerc(columns[12].SubColumns, true) + '</td>';
            if (this.table.column(66).visible()) subRows += '<td class="text-right">' + this.getDiff(columns[12].SubColumns) + ' </td>';

            subRows += '</tr>';
        });

        return $(subRows);
    }

    async addCE(item: any) {
        this.dialog
            .open(ContoEconomicoEditComponent, {
                width: '800px',
                data: {
                    item: item
                }
            }).afterClosed().subscribe((res: any) => {
                if (res) {
                    this.table.draw();
                    setTimeout(() => {
                        const element: any = document.getElementById(item.Id);
                        element.scrollIntoView();
                        this.ref.detectChanges();
                    }, 500);
                }
            });
    }

    async detailCE(item: any) {
        this.dialog
            .open(ContoEconomicoDetailsComponent, {
                data: {
                    item: item,
                    currentFilterCfg: this.currentFilterCfg,
                    locale: this.locale,
                    training: this.training
                },
                width: '1200px'
            }).afterClosed().subscribe((res: any) => {
                if (res) {
                    this.table.draw();
                    setTimeout(() => {
                        const element: any = document.getElementById(item.Id);
                        element.scrollIntoView();
                        this.ref.detectChanges();
                    }, 500);
                }
            });
    }

    exportAsXLSX(expandRows: any) {
        this.expandRows = expandRows === true;
        this.table.button(1).trigger();
    }

    exportAsPDF(PDFPreferences: any) {
        this.PDFPreferences = PDFPreferences;
        this.expandRows = PDFPreferences.explode;
        this.table.button(0).trigger();
    }

    performShowTotals(forcedValue: boolean = false) {
        this.showTotals = forcedValue ? forcedValue : !this.showTotals;
        if (!forcedValue) { // Fa già l'initDatatable dal SUbscription del filterService
            this.table.draw();
        }
    }
}

