import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { QueryParamsModel } from '@app/core/_base/crud/models/query-models/query-params.model';
import { QueryResultsModel } from '@app/core/_base/crud/models/query-models/query-results.model';
import { HttpUtilsService } from '@app/core/_base/crud/utils/http-utils.service';
import { API } from '@env/environment';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

@Injectable({
    providedIn: 'root'
})
export class EndpointsService {

    constructor(
        private http: HttpClient,
        private switchGroupService: SwitchGroupService
    ) { }


    // READ
    getEndpoints(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
            'email': currentEmail,
            'group': currentLogin.groupId
        });

        return this.http.get<any>(API.API_URL + '/Group', { headers: httpHeaders });
    }

    createEndpoint(endpoint: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
            'email': currentEmail,
            'group': currentLogin.groupId
        });

        console.log('createEndpoint', endpoint);

        return this.http.post<any>(API.API_URL + '/Group', endpoint, { headers: httpHeaders });
    }

    updateEndpoint(endpoint: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
            'email': currentEmail,
            'group': currentLogin.groupId
        });

        console.log('updateEndpoint', endpoint);

        return this.http.put<any>(API.API_URL + '/Group/' + endpoint.Id, endpoint, { headers: httpHeaders });
    }

    refreshEndpoints(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
            'email': currentEmail,
            'group': currentLogin.groupId
        });

        return this.http.get<any>(API.API_URL + '/Check/Refresh', { headers: httpHeaders });
    }

    deploy(endpoint): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
            'email': currentEmail,
            'group': currentLogin.groupId
        });

        return this.http.get<any>(API.API_URL + '/Check/ForceDeploy?e=' + endpoint, { headers: httpHeaders });
    }

    weather(endpoint, from, to) {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
            'email': currentEmail,
            'group': currentLogin.groupId
        });

        let d = {
            EndPointId: endpoint,
            From: from,
            To: to
        }

        return this.http.post<any>(API.API_URL + '/Check/Weather', d, { headers: httpHeaders });
    }

    lastLogZip(endpoint): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();
        const httpOptions: any = {
            headers: new HttpHeaders({
                'Content-Type': 'application/zip',
                'Authorization': 'Bearer ' + currentLogin.access_token,
                'Accept': 'application/zip, application/xhtml+xml, */*',
                'email': currentEmail,
                'group': currentLogin.groupId
            }),
            responseType: 'arraybuffer'
        };

        return this.http.get<any>(API.API_URL + '/Check/LogZip?id=' + endpoint, httpOptions);
    }

    lastLog(endpoint): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();
        const httpOptions: any = {
            headers: new HttpHeaders({
                'Content-Type': 'text/plain',
                'Authorization': 'Bearer ' + currentLogin.access_token,
                'Accept': 'text/plain, application/xhtml+xml, */*',
                'email': currentEmail,
                'group': currentLogin.groupId
            }),
            responseType: 'text'
        };
        // const httpHeaders = new HttpHeaders({
        //   'Content-Type': 'application/json',
        //   'Authorization': 'Bearer ' + currentLogin.access_token,
        //   'email': currentEmail,
        //   'group': currentLogin.groupId
        // });

        return this.http.get<any>(API.API_URL + '/Check/LastLog?id=' + endpoint, httpOptions);
    }

    getDeployVersion(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
            'email': currentEmail,
            'group': currentLogin.groupId
        });

        return this.http.get<any>(API.API_URL + '/Check/DeployVersion', { headers: httpHeaders });
    }

    deleteEndpoint(endpoint: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
            'email': currentEmail,
            'group': currentLogin.groupId
        });

        return this.http.delete<any>(API.API_URL + '/Group/' + endpoint.Id, { headers: httpHeaders });
    }

    createUser(user: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
            'email': currentEmail,
            'group': currentLogin.groupId
        });

        console.log('createUser', user);

        return this.http.post<any>(API.API_URL + '/Users', user, { headers: httpHeaders });
    }

    updateUser(user: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
            'email': currentEmail,
            'group': currentLogin.groupId
        });

        return this.http.put<any>(API.API_URL + '/Users/' + user.Data.Email, user, { headers: httpHeaders });
    }

    duplicateUser(payload: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
            'email': currentEmail,
            'group': currentLogin.groupId
        });

        return this.http.post<any>(API.API_URL + '/Users/Duplicate', payload, { headers: httpHeaders });
    }

    disableUser(user: any, groupId: any, token: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();
        let body = this.CreateBody(groupId, token, user.Email);

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
            'email': currentEmail,
            'group': currentLogin.groupId
        });

        let obj = {
            headers: httpHeaders,
            body: body
        };

        return this.http.put<any>(API.API_URL + API.API_USERS + '/EnableDisable?enable=false', body, { headers: httpHeaders });
    }

    enableUser(user: any, groupId: any, token: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();
        let body = this.CreateBody(groupId, token, user.Email);

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
            'email': currentEmail,
            'group': currentLogin.groupId
        });

        let obj = {
            headers: httpHeaders,
            body: body
        };

        return this.http.put<any>(API.API_URL + API.API_USERS + '/EnableDisable?enable=true', body, { headers: httpHeaders });
    }

    CreateBody(groupId, token, mail) {

        const currentEmail = this.switchGroupService.getCurrentEmail();
        let body = {
            Authorization: {
                Email: currentEmail,
                Authorizations: [{
                    Id: groupId,
                    Token: token
                }

                ]
            },
            Data: {
                Email: mail,
                Groups: [{
                    Id: groupId
                }]
            }

        }
        return body;
    }

    deleteUser(user: any, groupId: any, token: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const currentEmail = this.switchGroupService.getCurrentEmail();
        let body = this.CreateBody(groupId, token, user.Email);

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
            'email': currentEmail,
            'group': currentLogin.groupId
        });

        let obj = {
            headers: httpHeaders,
            body: body
        };
        return this.http.delete<any>(API.API_URL + API.API_USERS, obj);
    }

    getIntegrationsByEndpoint(endpoint: any): Observable<any> {
        const currentLogin = this.switchGroupService.getGroupByEndPoint(endpoint.ApiEndpointURL);

        if(!currentLogin || !currentLogin.access_token) return of(null);

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
        });

        return this.http.get<any>(currentLogin.endpointURL + '/api/Admin/Integrations', { headers: httpHeaders });
    }

    updateIntegrations(paylaod: any, endpoint: any): Observable<any> {
        const currentLogin = this.switchGroupService.getGroupByEndPoint(endpoint.ApiEndpointURL);

        if(!currentLogin || !currentLogin.access_token) return of(null);

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token,
        });

        return this.http.post<any>(currentLogin.endpointURL + '/api/Admin/Integrations', paylaod, { headers: httpHeaders });

    }

}
