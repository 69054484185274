import { Component, Inject, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizedDateTimePipe } from '@app/core/pipes/localized-date-time.pipe';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { ContoEconomicoService } from '../conto-economico.service';
import * as util from '@app/core/services/utilityfunctions';
import { TranslationService } from '@app/core/_base/layout';
import moment from 'moment';
import * as _ from 'lodash';
import { MbTimeDistributionComponent } from '@app/mb-components/mb-time-distribution/mb-time-distribution.component';
import { MatInput } from '@angular/material/input';
import { TranslateService } from '@ngx-translate/core';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import { FornitoriEditComponent } from '@app/views/pages/anagrafiche/fornitori/fornitori-edit/fornitori-edit.component';

@Component({
    selector: 'kt-conto-economico-edit',
    templateUrl: './conto-economico-edit.component.html',
    styleUrls: ['./conto-economico-edit.component.scss']
})
export class ContoEconomicoEditComponent implements OnInit {

    @ViewChild('dateInput', {
        read: MatInput
    }) dateInput!: MatInput;

    plItem: any;
    viewLoading: boolean = false;
    formPL: any = {};
    isEdit: boolean = false;
    startDate!: string;
    locale!: string;
    description!: string;

    IsTraining: boolean = false;

    // Selezione tipo utente
    accountTypeDlg: number = 1;
    accountList: any;
    selectedAccount: any;

    constructor(
        private contoEconomicoService: ContoEconomicoService,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public staticCollectionsService: StaticCollectionsService,
        private localizedDateTimePipe: LocalizedDateTimePipe,
        private translationService: TranslationService,
        public dialog: MatDialog,
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private layoutUtilsService: LayoutUtilsService
    ) {
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });

        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.plItem = this.data.item;
        this.isEdit = this.data.isEdit;
        this.IsTraining = this.data.IsTraining ? this.data.IsTraining : false;
        this.formPL['CostCenter'] = undefined;
        this.formPL['Store'] = undefined;
        this.formPL['selectedAccount'] = undefined;
        this.ref.detectChanges();
    }

    ngAfterViewInit() {
        if (this.isEdit) {
            const cc = this.staticCollectionsService.costCenters$.find((c: any) => c.Id == this.plItem.CostCenterId);
            this.formPL['Amount'] = util.formatNumber(this.plItem.Amount, this.locale);
            this.formPL['Description'] = this.plItem.Description;
            this.formPL['CostCenter'] = cc;
            this.formPL['Store'] = this.plItem.Store;
            this.formPL['selectedAccount'] = this.plItem.SupplierId;
            this.formPL['DocNum'] = this.plItem.DocNum;
            let d = new Date(this.plItem.Date);
            this.startDate = this.localizedDateTimePipe.transform(d, 'yyyy-MM-dd');
            this.formPL.Date = moment(this.startDate);
        }

        setTimeout(() => {
            this.changeAccountType(this.accountTypeDlg);
        }, 1000);
    }

    addNewAccount() {
        const item = {
            Id: '',
            SubjectType: this.accountTypeDlg
        };
        let saveMessageTranslateParam = 'ANAGRAFICHE.FORNITORI.ACTION_EDIT.ADD_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = MessageType.Create;
        const dialogRef = this.dialog.open(FornitoriEditComponent, { data: { item }, width: '800px' });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            //this.ref.detectChanges();
            this.staticCollectionsService.fornitori$ = undefined;
            setTimeout(() => {
                this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
                this.staticCollectionsService.fillStaticCollection(true).then(() => {
                    this.formPL['selectedAccount'] = res.item.toString();
                    this.ref.detectChanges();
                });
            }, 1000);
        });
    }

    getTitle() {
        return this.plItem ? this.plItem.Name : '';
    }

    getPlaceholderAmount() {
        return this.plItem && this.plItem.IsCover ? this.translate.instant('CONTO_ECONOMICO.EDIT.FORM.COVER_AMOUNT') : this.translate.instant('CONTO_ECONOMICO.EDIT.FORM.AMOUNT');
    }

    formInvalid() {
        //console.log(this.formPL);
        return !this.formPL.Date || !this.formPL.Amount || !this.formPL.Store || !this.formPL.CostCenter;
    }

    dateChange(event: any) {
        //console.log(event);
        this.formPL.Date = event.value;
    }

    forceStay: boolean = false;
    async onSubmit(forceStay: boolean = false) {
        this.viewLoading = true;

        const payload: any = {
            Amount: util.parseNumber(this.formPL.Amount, this.locale),
            Date: this.formPL.Date.format('YYYY-MM-DD'),
            StructureId: this.isEdit ? this.plItem.StructureId : this.plItem.Id,
            Store: this.formPL.Store,
            CostCenterId: this.formPL.CostCenter.Id,
            Description: this.formPL.Description,
            SupplierId: this.formPL.selectedAccount,
            DocNum: this.formPL.DocNum,
            IsTraining: this.IsTraining,
            IsCover: this.plItem.IsCover
        }
        
        let result = false;

        try {
            if (this.isEdit) {
                payload['Id'] = this.plItem.Id;
                await this.contoEconomicoService.updatePL(payload).toPromise();
            } else {
                if (this.distributionPayload && this.distributionPayload.length > 0) {
                    delete payload.Amount;
                    delete payload.Date;
                    payload['Items'] = this.distributionPayload;
                    await this.contoEconomicoService.savePLMultiple(payload).toPromise();
                } else {
                    await this.contoEconomicoService.savePL(payload).toPromise();
                }
            }

            this.formPL['Amount'] = undefined;
            this.formPL['Description'] = undefined;
            this.formPL['CostCenter'] = undefined;
            this.formPL['Store'] = undefined;
            this.formPL['selectedAccount'] = undefined;
            this.formPL['DocNum'] = undefined;
            this.formPL['Date'] = undefined;
            this.startDate = '';
            this.dateInput.value = '';

            result = true;
        } catch (error) {
            result = false;

        } finally {
            this.viewLoading = false;
            this.forceStay = result;
            if (!forceStay) {
                this.dialogRef.close(result);
            }
        }
    }

    distributionPayload: any;
    SplitPurchase: boolean = false;
    timeDistribution() {

        const invoice: any = {
            PLAmount: this.formPL.Amount,
            Date: this.formPL.Date
        };

        if (this.distributionPayload && this.distributionPayload.length > 0) {
            //const amount = this.distributionPayload.reduce((accumulator, currentValue) => accumulator + currentValue.Amount,
            //0)
            invoice.PLSplits = this.distributionPayload.map(distr => {
                return {
                    Date: distr.Date,
                    Amount: distr.Amount
                }
            })
        }

        const dialogRef = this.dialog.open(MbTimeDistributionComponent, {
            data: {
                invoice: invoice
            },
            panelClass: 'MbTimeDistributionComponent'
        });

        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            if (res.success && res.body) {

                this.distributionPayload = [];

                this.SplitPurchase = res.SplitPurchase ? res.SplitPurchase : false;

                res.body.forEach((item: any) => {
                    this.distributionPayload.push({
                        Date: item.date,
                        Amount: item.amount,
                        Percentage: item.perc
                    })
                });

            }
        });
    }

    getDistributionTooltip() {
        if (this.distributionPayload && this.distributionPayload.length > 0) {

            let tooltip = "";
            this.distributionPayload.forEach(distr => {
                tooltip += `${this.localizedDateTimePipe.transform(distr.Date, undefined, true)}: ${util.formatNumber(distr.Percentage, this.locale, 2)}% \n`;
            });
            return tooltip;

        } else return '';
    }


    changeAccountType(eventy: any) {
        this.selectedAccount = undefined;
        this.accountList = undefined;
        setTimeout(() => {
            switch (eventy) {
                case 1:
                    this.accountList = this.staticCollectionsService.fornitori$;
                    break;
                case 2:
                    this.accountList = this.staticCollectionsService.clientiTutti$;
                    break;
                case 4:
                    this.accountList = this.staticCollectionsService.dipendentiTutti$;
                    break;
            }
        }, 100);
    }

    getPlaceholder() {
        let placeholder = "";
        switch (this.accountTypeDlg) {
            case 1:
                placeholder = this.translate.instant('FILTRI.FORNITORE_PLACEHOLDER');
                break;
            case 2:
                placeholder = this.translate.instant('FILTRI.CLIENTE_PLACEHOLDER');
                break;
            case 4:
                placeholder = this.translate.instant('FILTRI.DIPENDENTE_PLACEHOLDER');
                break;
        }

        return placeholder;
    }
}
