export class PageConfig {
	public defaults: any = {
		dashboard: {
			page: {
				'title': 'Dashboard',
				'desc': 'Latest updates and statistic charts'
			},
		},
		'analisi/analisi-vendite': {
			page: {
				title: 'Analisi Vendite',
				desc: ''
			}
		},
		'analisi/analisi-foodcost': {
			page: {
				title: 'Analisi Food Cost',
				desc: ''
			}
		},
        'analisi/gestione-fatture': {
			page: {
				title: 'Gestione Fatture',
				desc: ''
			}
		},
		endpoints: {
			page: {
				title: 'Endpoints',
				desc: ''
			}
		},
		'gestione-merci': {
			page: {
				title: 'Gestione Merci',
				desc: ''
			}
		},
		'categorie-merci': {
			page: {
				title: 'Categorie Merci',
				desc: ''
			}
		},
		'categorie-prodotti': {
			page: {
				title: 'Categorie Prodotti',
				desc: ''
			}
		},
		builder: {
			page: {title: 'Layout Builder', desc: ''}
		},
		header: {
			actions: {
				page: {title: 'Actions', desc: 'Actions example page'}
			}
		},
		profile: {
			page: {title: 'User Profile', desc: ''}
		},
		error: {
			404: {
				page: {title: '404 Not Found', desc: '', subheader: false}
			},
			403: {
				page: {title: '403 Access Forbidden', desc: '', subheader: false}
			}
		}
	};

	public get configs(): any {
		return this.defaults;
	}
}
