import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API } from '@env/environment';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

@Injectable({
	providedIn: 'root'
})

export class AnalisiFoodCostService {

	constructor(
		private http: HttpClient,
		private switchGroupService: SwitchGroupService
	) { }

	listFoodCosts(config: any): Observable<any> {
		const currentLogin = this.switchGroupService.getCurrentGroup();

		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json; charset=utf-8',
			'Authorization': 'Bearer ' + currentLogin.access_token
		});

		return this.http.post<any>(currentLogin.endpointURL + '/api/FoodCostAnalysis', config, { headers: httpHeaders });
	}

	listFoodCostsDetailed(config: any): Observable<any> {
		const currentLogin = this.switchGroupService.getCurrentGroup();

		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json; charset=utf-8',
			'Authorization': 'Bearer ' + currentLogin.access_token
		});

		return this.http.post<any>(currentLogin.endpointURL + '/api/FoodCostAnalysis/Details', config, { headers: httpHeaders });
	}
}
