import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'kt-analisi-prezzi-fornitori-dialog',
  templateUrl: './analisi-prezzi-fornitori-dialog.component.html',
  styleUrls: ['./analisi-prezzi-fornitori-dialog.component.scss']
})
export class AnalisiPrezziFornitoriDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {}

}
