// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

// Translate Module
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'kt-order-unit-edit',
    templateUrl: './order-unit-edit.component.html',
    styleUrls: ['./order-unit-edit.component.scss']
})
export class OrderUnitEditComponent implements OnInit {

    // Public properties
    content: any;
    contentForm!: FormGroup;
    hasFormErrors: boolean = false;
    viewLoading: boolean = false;
    editMode: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private anagraficheService: AnagraficheService,
        private switchGroupService: SwitchGroupService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.content = this.data.item;
        this.editMode = this.content.Id && this.content.Id > 0;

        $('body').on('keyup', (e: any) => {
            if (e.code == 'F2') {
                e.preventDefault();
                e.stopPropagation();
                this.onSubmit();
            }
        });

        this.createForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        $('body').off('keyup');
    }

    /**
     * Close alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    createForm() {
        this.contentForm = this.fb.group({
            Name: [this.content.Name, Validators.required],
            Alternate: [this.content.Alternate, null],
        });
    }

    /**
    * Returns page title
    */
    getTitle(): string {
        if (this.editMode) {
            return this.translate.instant('COMMONS.EDIT') + ` '${this.content.Name}'`;
        }
        return this.translate.instant('ANAGRAFICHE.ORDER_UNIT.NEW_ENTITY');
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.contentForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    /** ACTIONS */

    /**
     * Returns prepared customer
     */
    prepareItem(): any {
        const controls = this.contentForm.controls;
        let prepareJson = {
            Name: controls['Name'].value,
            Alternate: controls['Alternate'].value,
        };
        if (this.editMode) {
            prepareJson['Id'] = this.content.Id;
        }
        return prepareJson;
    }

    /**
     * On Submit
     */
    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.contentForm.controls;
        /** check form */
        if (this.contentForm.invalid) {
            Object.keys(controls).forEach(controlName => {
                controls[controlName].markAsTouched();
                console.log(controlName);
            });
            this.hasFormErrors = true;
            return;
        }

        const editedItem = this.prepareItem();
        console.log('prepareItem', editedItem);
        if (this.editMode) {
            this.updateEntityItem(editedItem);
        } else {
            this.createEntityItem(editedItem);
        }
    }

    /**
     * Update customer
     *
     * @param _customer: CustomerModel
     */
    updateEntityItem(item: any) {
        lastValueFrom(this.anagraficheService.putEntity('OrderUnit', item))
            .then((ret: any) => {
                this.dialogRef.close({ item, isEdit: true });
            }, (error: any) => {
                this.dialogRef.close(null);
            });
    }

    /**
     * Update customer
     *
     * @param _customer: CustomerModel
     */
    createEntityItem(item: any) {
        lastValueFrom(this.anagraficheService.postEntity('OrderUnit', item))
            .then((ret: any) => {
                this.dialogRef.close({ item, isEdit: false });
            }, (error: any) => {
                this.dialogRef.close(null);
            });
    }

}
