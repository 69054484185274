import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizedDateTimePipe } from '@app/core/pipes/localized-date-time.pipe';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { LayoutConfigService, SwitchGroupService, TranslationService } from '@app/core/_base/layout';
import * as util from '@app/core/services/utilityfunctions';
import { TranslateService } from '@ngx-translate/core';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import moment from 'moment';
import { PDFService } from '@app/core/services/pdf.service';
import { BehaviorSubject } from 'rxjs';
import { Chart } from 'chart.js';
import { DatePipe } from '@angular/common';

declare var window: any;

@Component({
    selector: 'kt-avp-alert',
    templateUrl: './avp-alert.component.html',
    styleUrls: ['./avp-alert.component.scss']
})
export class AvpAlertComponent implements OnInit {

    pagination: any = {
        TotalRows: 0,
        pageSize: 10,
        pageIndex: 0
    }

    maxValuesPerPage: number = 6;
    maxValuesPerPageTmp: number = 6;
    excludePagination: boolean = false;

    avpAlerts: any;
    currentFilterCfg: any;
    utility: any;
    locale!: string;
    table: any;
    totRows!: number;
    PDFPreferences: any;
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public staticCollectionsService: StaticCollectionsService,
        private switchGroupService: SwitchGroupService,
        private localizedDateTimePipe: LocalizedDateTimePipe,
        private translate: TranslateService,
        public dialog: MatDialog,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private pdfService: PDFService,
    ) {
        this.utility = util;
        this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.currentFilterCfg = this.data.currentFilterCfg;
        this.locale = this.data.locale;

        this.initDataTable();

        $.fn.dataTable.ext.order['localized_date'] = (settings, col) => {
            if (!this.table) return false;
            return this.table.column(col, { order: 'index' }).nodes().map(function (td, i) {
                const date: any = $(td).html();
                const rest = moment(date, 'DD/MM/YYYY').unix();
                return rest;
            });
        }
    }

    filter() {
        this.table.draw();
    }

    exportAsXLSX() {
        const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
        const _description: string = this.translate.instant('EXPORT_XLSX.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');
        const _success = this.translate.instant('EXPORT_XLSX.MESSAGE');
        const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(expand => {
            if (expand) this.table.button(1).trigger();
        });
    }

    exportAsPDF(expand: boolean = false) {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {

            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('GESTIONE_FATTURE.TITLE'),
                    period: ''
                };
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                };
                result['language'] = this.translationService.getSelectedLanguage();

                this.PDFPreferences = result;

                this.table.button(0).trigger();
            };
        });
    }

    firstTime: boolean = true;
    initDataTable() {
        if ($.fn.dataTable.isDataTable('#avp_alert')) {
            $('#avp_alert').DataTable().destroy();
        }

        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();

            const dtOptions: any = {
                processing: true,
                serverSide: true,
                ajax: {
                    url: currentLogin.endpointURL + "/api/Warehouse/PriceVariations",
                    type: "POST",
                    data: (d: any) => {
                        d.filters = this.currentFilterCfg;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        this.pagination.TotalRows = response.recordsFiltered;
                        return response.data;
                    }
                },
                destroy: true,
                dom: 'rt<"paginator">',
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: true,
                searching: true,
                ordering: true,
                scrollX: true,
                scrollY: '300px',
                scrollCollapse: true,
                autoWidth: false,
                order: [[5, 'desc']],
                columnDefs: [
                    {
                        targets: 0, data: 'Date', name: 'Date', render: (data, type, p, meta) => {
                            return this.localizedDateTimePipe.transform(data, undefined, true);
                        }, orderDataType: 'localized_date', width: '80px'
                    },
                    {
                        targets: 1, data: 'Supplier', name: 'Supplier', render: (data, type, p, meta) => {
                            return `<div class="overflow-ellipses exportText" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }, className: 'max-width-100'
                    },
                    {
                        targets: 2, data: 'Code', name: 'Code', render: (data, type, p, meta) => {
                            return `<div class="overflow-ellipses exportText" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }, className: 'max-width-100'
                    },
                    {
                        targets: 3, data: 'Name', name: 'Name', render: (data, type, p, meta) => {
                            return `<div class="overflow-ellipses exportText" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }, className: 'max-width-200'
                    },
                    {
                        targets: 4, data: 'Last', name: 'Last', render: (data, type, p, meta) => {
                            return util.formatNumber(data, this.locale);
                        }, width: '100px'
                    },
                    {
                        targets: 5, data: 'Variations', name: 'Variations', render: (data, type, p, meta) => {
                            return util.formatNumber(data, this.locale);
                        }, width: '100px'
                    },
                    {
                        targets: 6, data: 'PercVariations', name: 'PercVariations', render: (data, type, p, meta) => {
                            return util.formatNumber(data, this.locale);
                        }, width: '100px'
                    },
                    // Serve per evitare di riceve avvisi quando arrivano campi a null
                    {
                        defaultContent: '-',
                        targets: '_all'
                    }
                ],
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdfHtml5',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)',
                        },
                        customize: (doc: any) => {
                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, ['auto', 'auto', 'auto', 'auto'], '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;
                            console.log(doc.styles);

                            doc.styles = docDefinition.styles;

                            doc.content[1].table.body.forEach(function (line, i) {
                                const length = doc.content[1].table.body[i].length;
                                doc.content[1].table.body[i][length - 3].alignment = 'right';
                                doc.content[1].table.body[i][length - 2].alignment = 'right';
                                doc.content[1].table.body[i][length - 1].alignment = 'right';
                            });

                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.pdfHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            format: {
                                body: (data, row, column, node) => {
                                    if (data.indexOf('exportText') >= 0) {
                                        var parser = new DOMParser();
                                        var htmlDoc = parser.parseFromString(data, 'text/html');
                                        return $(htmlDoc).find('.exportText').text();
                                    } else {
                                        return column >= 4 ? this.utility.parseNumber(data, this.locale, 2) : data;
                                    }
                                }
                            },
                            columns: ':visible:not(.actions)',
                            orthogonal: 'export'
                        },
                        customize: async (xlsx: any) => {
                            // Get sheet.
                            var sheet = xlsx.xl.worksheets['sheet1.xml'];
                            // Nelle colonne B/C/D, dalla riga 3 in avanti importi lo stile 64
                            $(`row c[r^="E2"]`, sheet).attr('s', '64');
                            $(`row c[r^="F2"]`, sheet).attr('s', '64');
                            $(`row c[r^="G2"]`, sheet).attr('s', '64');
                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.excelHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    }
                ]
            };

            this.table = $('#avp_alert').DataTable(dtOptions);
            window.table = this.table;
        }, 100);
    }

    changePagination(event: any) {
        if (this.pagination.pageSize != event.pageSize) {
            this.pagination.pageSize = event.pageSize;
            this.pagination.pageIndex = 0;
            this.table.page.len(this.pagination.pageSize).draw();
            this.table.page(0).draw('page');
        } else if (this.pagination.pageIndex != event.pageIndex) {
            this.pagination.pageIndex = event.pageIndex;
            this.table.page(this.pagination.pageIndex).draw('page');
            console.log(this.pagination.pageIndex);
        }
    }
}

