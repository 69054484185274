import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BulkActionsService {

  constructor(
    private http: HttpClient,
    private switchGroupService: SwitchGroupService
  ) { }

  massiveActions(body: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Entity/MassiveActions`;
    return this.http.post<any>(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }
}
