import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { FiltriService } from '@app/core/services/filtri.service';
import { AnalisiFoodCostService } from '@app/core/services/analisi-foodcost.service';
import { TranslateService } from '@ngx-translate/core';
// Layout
import { LayoutConfigService } from '@app/core/_base/layout';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

import * as util from '@app/core/services/utilityfunctions';

interface JQuery {
    DataTable(): void;
    dataTableExt(): void;
}

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { FoodCostDetailComponent } from './food-cost-detail/food-cost-detail.component';
import { MatDialog } from '@angular/material/dialog';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'kt-analisi-foodcost',
    templateUrl: './analisi-foodcost.component.html',
    styleUrls: ['./analisi-foodcost.component.scss']
})
export class AnalisiFoodcostComponent implements OnInit, OnDestroy {
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    dataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    tableFoodCost: any;
    currentFilterCfg: any;
    foodCostData: any = undefined;
    // TimeSlots
    foodCostSubscribtions!: Subscription;
    switchGroupSubcription!: Subscription;
    filtriServiceSubscription!: Subscription;

    showNetValue: string = '';

    utility: any;
    locale!: string;
    mappaInput: any = {};

    currentView = 'standard';
    ck_lordo: boolean = false;
    ck_excludeInventory: boolean = false;

    constructor(
        private filtriService: FiltriService,
        private analisiFoodCostService: AnalisiFoodCostService,
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private layoutConfigService: LayoutConfigService,
        private excelService: ExcelService,
        private pdfService: PDFService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private switchGroupService: SwitchGroupService,
        private dialog: MatDialog
    ) {
        this.utility = util;
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
    }

    /**
 * After view init
 */
    ngAfterViewInit(): void {
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {

                    this.currentView = 'standard';

                    if ($.fn.dataTable.isDataTable('#tableFoodCost')) {
                        $('#tableFoodCost').DataTable().clear().destroy();
                    }

                    if ($.fn.dataTable.isDataTable('#tableFoodCostDetailed')) {
                        $('#tableFoodCostDetailed').DataTable().clear().destroy();
                    }

                    this.foodCostData = undefined;
                    this.dataReady$.next(false);
                }
            }
        );
    }

    /**
       * On Destroy
       */
    ngOnDestroy() {
        if (this.tableFoodCost) this.tableFoodCost.destroy();
        if (this.foodCostSubscribtions) this.foodCostSubscribtions.unsubscribe();
        this.switchGroupSubcription.unsubscribe();
        this.filtriServiceSubscription.unsubscribe();

        // cancello il filtro
        this.filtriService.filterConfig = {};
    }

    reInitTable() {
        if (this.foodCostSubscribtions) this.foodCostSubscribtions.unsubscribe();
        this.loading$.next(true);
        this.filtriService.readOnly$.next(true);

        if (this.currentView == 'standard' && $.fn.dataTable.isDataTable('#tableFoodCost')) {
            $('#tableFoodCost').DataTable().destroy();
        } else if (this.currentView == 'detailed' && $.fn.dataTable.isDataTable('#tableFoodCostDetailed')) {
            $('#tableFoodCostDetailed').DataTable().destroy();
        }

        this.currentFilterCfg['ExcludeInventory'] = this.ck_excludeInventory;

        this.foodCostSubscribtions = this.analisiFoodCostService.listFoodCosts(this.currentFilterCfg)
            .subscribe((ret: any) => {
                ret.Months.forEach((month: any) => {
                    month.Food.StaffMeals = this.utility.formatNumber(month.Food.StaffMeals, this.locale, 2);
                    month.Beverage.StaffMeals = this.utility.formatNumber(month.Beverage.StaffMeals, this.locale, 2);
                    month.Food.OfferedMeals = this.utility.formatNumber(month.Food.OfferedMeals, this.locale, 2);
                    month.Beverage.OfferedMeals = this.utility.formatNumber(month.Beverage.OfferedMeals, this.locale, 2);
                });
                this.foodCostData = ret;
                this.loading$.next(false);
                this.filtriService.readOnly$.next(false);
                if (this.currentView == 'standard') {
                    this.initTableFoodCost();
                } else {
                    this.initTableFoodCostDetailed();
                }
            });
    }

    showDetailed() {
        this.currentView = this.currentView == 'standard' ? 'detailed' : 'standard';

        if (this.currentView == 'standard' && $.fn.dataTable.isDataTable('#tableFoodCostDetailed')) {
            $('#tableFoodCostDetailed').DataTable().clear().destroy();
        } else if (this.currentView == 'detailed' && $.fn.dataTable.isDataTable('#tableFoodCost')) {
            $('#tableFoodCost').DataTable().clear().destroy();
        }

        this.reInitTable();
    }

    ngOnInit() {
        document.body.classList.add('kt-aside--minimize');
        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            (filterConfig: any) => {


                if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) return;
                if (filterConfig.constructor !== Object) return;

                this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));
                this.reInitTable();
            }
        );

        $.extend((<any>$.fn).dataTableExt.oSort, {
            "string-num-desc": (a, b) => {
                var x = (a == "-") ? 0 : a.replace('.', "").replace('&nbsp;', "").replace(/,/, ".");
                var y = (b == "-") ? 0 : b.replace('.', "").replace('&nbsp;', "").replace(/,/, ".");
                x = parseFloat(x);
                y = parseFloat(y);
                return ((x < y) ? 1 : ((x > y) ? -1 : 0));
            },
            "string-num-asc": (a, b) => {
                var x = (a == "-") ? 0 : a.replace('.', "").replace('&nbsp;', "").replace(/,/, ".");
                var y = (b == "-") ? 0 : b.replace('.', "").replace('&nbsp;', "").replace(/,/, ".");
                x = parseFloat(x);
                y = parseFloat(y);
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
        });
    }

    triggerClickToShowInput(el: any) {

        const parentRow = el.closest('tr');
        $(parentRow).addClass('focusedRow');

        let element = $(el);
        if (element.is("input")) return;
        if (element.is("span")) {
            element = element.parent();
        };
        this.mappaInput = {};
        const col = element.index();
        const colName = element.closest('td').data('colname');
        const index = element.closest('tr').data('index');
        this.mappaInput[index] = {};
        this.mappaInput[index][colName] = col;

        this.ref.detectChanges();

        setTimeout(() => {
            $(element).find('input').select();
            $(element).find('input').focus();
        }, 100);
    }

    openFilter() {
        this.filtriService.triggerOpenFilter$.next(true);
    }

    getTotStaffMealsSum(type: string, exportXLSX: boolean = false) {
        let tot: number = 0;
        switch (type) {
            case 'Beverage':
            case 'Food':
                this.foodCostData.Months.forEach((month: any) => {
                    tot += Number(month[type].StaffMeals.toString().replace(',', '.'));
                });
                break;
            case 'FoodBevarage':
                this.foodCostData.Months.forEach((month: any) => {
                    tot += Number(month['Beverage'].StaffMeals.toString().replace(',', '.'));
                    tot += Number(month['Food'].StaffMeals.toString().replace(',', '.'));
                });
                break;
        }
        return exportXLSX ? this.utility.formatNumberExcel(tot) : this.utility.formatNumber(tot, this.locale, 2);
    }

    getTotOfferedMealsSum(type: string, exportXLSX: boolean = false) {
        let tot: number = 0;
        switch (type) {
            case 'Beverage':
            case 'Food':
                this.foodCostData.Months.forEach((month: any) => {
                    tot += Number(month[type].OfferedMeals.toString().replace(',', '.'));
                });
                break;
            case 'FoodBevarage':
                this.foodCostData.Months.forEach((month: any) => {
                    tot += Number(month['Beverage'].OfferedMeals.toString().replace(',', '.'));
                    tot += Number(month['Food'].OfferedMeals.toString().replace(',', '.'));
                });
                break;
        }
        return exportXLSX ? this.utility.formatNumberExcel(tot) : this.utility.formatNumber(tot, this.locale);
    }

    getStaffMealsSum(i: number, exportXLSX: boolean = false) {
        const k = this.currentView == 'standard' ? 5 : 6;
        const currentMonth = Math.floor(i / k);
        const food = this.foodCostData.Months[currentMonth].Food.StaffMeals.toString().replace(',', '.');
        const beverage = this.foodCostData.Months[currentMonth].Beverage.StaffMeals.toString().replace(',', '.');
        return exportXLSX ? this.utility.formatNumberExcel(Number(food) + Number(beverage)) : this.utility.formatNumber(Number(food) + Number(beverage), this.locale);
    }

    getOfferedMealsSum(i: number, exportXLSX: boolean = false) {
        const k = this.currentView == 'standard' ? 5 : 6;
        const currentMonth = Math.floor(i / k);
        const food = this.foodCostData.Months[currentMonth].Food.OfferedMeals.toString().replace(',', '.');
        const beverage = this.foodCostData.Months[currentMonth].Beverage.OfferedMeals.toString().replace(',', '.');
        return exportXLSX ? this.utility.formatNumberExcel(Number(food) + Number(beverage)) : this.utility.formatNumber(Number(food) + Number(beverage), this.locale);
    }

    getStaffMealsPerc(i: number, exportXLSX: boolean = false) {
        const currentMonth = Math.floor(i / 5);
        const moduloRiga = i % 5;
        let result: number = 0;
        const type = this.ck_lordo ? 'Gross' : 'Net';
        switch (moduloRiga) {
            case 2: // F&B
                const fbStaffMealsSum = Number(this.getStaffMealsSum(i, true));
                const totRevenue = this.foodCostData.Months[currentMonth].TotaleRevenue[type];
                result = fbStaffMealsSum / totRevenue * 100;
                break;
            case 3: // FOOD
                const foodStaffMealsSum = Number(this.foodCostData.Months[this.getCurrentMonth(i)].Food.StaffMeals.toString().replace(',', '.'));
                const foodRevenue = this.foodCostData.Months[currentMonth].FoodRevenue[type];
                result = foodStaffMealsSum / foodRevenue * 100;
                break;
            case 4: // BEVERAGE
                const beverageStaffMealsSum = Number(this.foodCostData.Months[this.getCurrentMonth(i)].Beverage.StaffMeals.toString().replace(',', '.'));
                const beverageRevenue = this.foodCostData.Months[currentMonth].BeverageRevenue[type];
                result = beverageStaffMealsSum / beverageRevenue * 100;
                break;
        }
        return moduloRiga == 1 ? this.getStandardHeaderByColumnIndex(12) : (exportXLSX ? this.utility.formatNumberExcel(result) : this.utility.formatNumber(result, this.locale));
    }

    getOfferedMealsPerc(i: number, exportXLSX: boolean = false) {
        const currentMonth = Math.floor(i / 5);
        const moduloRiga = i % 5;
        let result: number = 0;
        const type = this.ck_lordo ? 'Gross' : 'Net';
        switch (moduloRiga) {
            case 2: // F&B
                const fbStaffMealsSum = Number(this.getOfferedMealsSum(i, true));
                const totRevenue = this.foodCostData.Months[currentMonth].TotaleRevenue[type];
                result = fbStaffMealsSum / totRevenue * 100;
                break;
            case 3: // FOOD
                const foodStaffMealsSum = Number(this.foodCostData.Months[this.getCurrentMonth(i)].Food.OfferedMeals.toString().replace(',', '.'));
                const foodRevenue = this.foodCostData.Months[currentMonth].FoodRevenue[type];
                result = foodStaffMealsSum / foodRevenue * 100;
                break;
            case 4: // BEVERAGE
                const beverageStaffMealsSum = Number(this.foodCostData.Months[this.getCurrentMonth(i)].Beverage.OfferedMeals.toString().replace(',', '.'));
                const beverageRevenue = this.foodCostData.Months[currentMonth].BeverageRevenue[type];
                result = beverageStaffMealsSum / beverageRevenue * 100;
                break;
        }
        return moduloRiga == 1 ? this.getStandardHeaderByColumnIndex(14) : exportXLSX ? this.utility.formatNumberExcel(result) : this.utility.formatNumber(result, this.locale);
    }

    getTotOfferedMealsPerc(type: string, exportXLSX: boolean = false) {
        let tot: number = 0;
        const typeRevenue = this.ck_lordo ? 'Gross' : 'Net';
        switch (type) {
            case 'FoodBevarage': // F&B
                this.foodCostData.Months.forEach((month: any) => {
                    const food = month.Food.OfferedMeals.toString().replace(',', '.');
                    const beverage = month.Beverage.OfferedMeals.toString().replace(',', '.');
                    const fbStaffMealsSum = Number(food) + Number(beverage);
                    const totRevenue = month.TotaleRevenue[typeRevenue];
                    tot += fbStaffMealsSum / totRevenue * 100;
                });
                break;
            case 'Food': // FOOD
                this.foodCostData.Months.forEach((month: any) => {
                    const foodStaffMealsSum = Number(month[type].OfferedMeals.toString().replace(',', '.'));
                    const foodRevenue = month.FoodRevenue[typeRevenue];
                    tot += foodStaffMealsSum / foodRevenue * 100;
                });
                break;
            case 'Beverage': // BEVERAGE
                this.foodCostData.Months.forEach((month: any) => {
                    const beverageStaffMealsSum = Number(month[type].OfferedMeals.toString().replace(',', '.'));
                    const beverageRevenue = month.BeverageRevenue[typeRevenue];
                    tot += beverageStaffMealsSum / beverageRevenue * 100;
                });
                break;
        }
        return exportXLSX ? this.utility.formatNumberExcel(tot / this.foodCostData.Months.length) : this.utility.formatNumber(tot / this.foodCostData.Months.length, this.locale);
    }

    getTotStaffMealsPerc(type: string, exportXLSX: boolean = false) {
        let tot: number = 0;
        const typeRevenue = this.ck_lordo ? 'Gross' : 'Net';
        switch (type) {
            case 'FoodBevarage': // F&B
                this.foodCostData.Months.forEach((month: any) => {
                    const food = month.Food.StaffMeals.toString().replace(',', '.');
                    const beverage = month.Beverage.StaffMeals.toString().replace(',', '.');
                    const fbStaffMealsSum = Number(food) + Number(beverage);
                    const totRevenue = month.TotaleRevenue[typeRevenue];
                    tot += fbStaffMealsSum / totRevenue * 100;
                });
                break;
            case 'Food': // FOOD
                this.foodCostData.Months.forEach((month: any) => {
                    const foodStaffMealsSum = Number(month[type].StaffMeals.toString().replace(',', '.'));
                    const foodRevenue = month.FoodRevenue[typeRevenue];
                    tot += foodStaffMealsSum / foodRevenue * 100;
                });
                break;
            case 'Beverage': // BEVERAGE
                this.foodCostData.Months.forEach((month: any) => {
                    const beverageStaffMealsSum = Number(month[type].StaffMeals.toString().replace(',', '.'));
                    const beverageRevenue = month.BeverageRevenue[typeRevenue];
                    tot += beverageStaffMealsSum / beverageRevenue * 100;
                });
                break;
        }
        return exportXLSX ? this.utility.formatNumberExcel(tot / this.foodCostData.Months.length) : this.utility.formatNumber(tot / this.foodCostData.Months.length, this.locale);
    }

    // Solo dettagliata
    getUsedGoodsValueSum(i: number, exportXLSX: boolean = false) {
        let element = (this.currentView == 'standard') ? 'UsedGoodsValue' : 'UsedGoodsValue2';
        const moduloRiga = i % 7;
        let result: number = 0;
        const currentMonth = Math.floor(i / 7);
        switch (moduloRiga) {
            case 1: // F&B (Standard) - Headers (Detailed)
                result = (this.currentView == 'standard') ? this.foodCostData.Months[currentMonth].FoodBevarage[element + this.showNetValue] : this.getDetailedHeaderByColumnIndex(8);
                break;
            case 2: // FOOD (Standard) - Total (Detailed)
                result = (this.currentView == 'standard') ? this.foodCostData.Months[currentMonth].Food[element + this.showNetValue] : this.foodCostData.Months[currentMonth].All[element + this.showNetValue];
                break;
            case 3: // BEVERAGE (Standard) - F&B (Detailed)
                result = (this.currentView == 'standard') ? this.foodCostData.Months[currentMonth].Beverage[element + this.showNetValue] : this.foodCostData.Months[currentMonth].FoodBevarage[element + this.showNetValue];
                break;
            case 4: // FOOD (Deatiled)
                result = this.foodCostData.Months[currentMonth].Food[element + this.showNetValue];
                break;
            case 5: // BEVERAGE (Deatiled)
                result = this.foodCostData.Months[currentMonth].Beverage[element + this.showNetValue];
                break;
            case 6: // OTHER (Deatiled)
                result = this.foodCostData.Months[currentMonth].Other[element + this.showNetValue];
                break;
        }

        return moduloRiga > 1 ? (exportXLSX ? this.utility.formatNumberExcel(result) : this.utility.formatNumber(result, this.locale)) : result;
    }

    getActualFoodCost(i: number, exportXLSX: boolean = false) {
        const moduloRiga = i % 5;
        let result: number = 0;
        const currentMonth = Math.floor(i / 5);
        let finalFoodCost: number = 0, staffMealsSum: number = 0, totRevenue: number = 0,
            staffMealsPerc: number = 0, offeredMealsSum: number = 0, offeredMealsPerc: number = 0;
        const typeRevenue = this.ck_lordo ? 'Gross' : 'Net';
        switch (moduloRiga) {
            case 2: // F&B
                finalFoodCost = this.foodCostData.Months[currentMonth].FoodBevarage.FinalFoodCost - this.foodCostData.Months[currentMonth].FoodBevarage.WastePerc;

                staffMealsSum = Number(this.getStaffMealsSum(i, true));
                totRevenue = this.foodCostData.Months[currentMonth].TotaleRevenue[typeRevenue];
                staffMealsPerc = staffMealsSum / totRevenue;

                offeredMealsSum = Number(this.getOfferedMealsSum(i, true));
                totRevenue = this.foodCostData.Months[currentMonth].TotaleRevenue[typeRevenue];
                offeredMealsPerc = offeredMealsSum / totRevenue;

                result = (finalFoodCost - staffMealsPerc - offeredMealsPerc) * 100;
                break;
            case 3: // FOOD
                finalFoodCost = this.foodCostData.Months[currentMonth].Food.FinalFoodCost - this.foodCostData.Months[currentMonth].Food.WastePerc;

                staffMealsSum = Number(this.getStaffMealsSum(i, true));
                totRevenue = this.foodCostData.Months[currentMonth].FoodRevenue[typeRevenue];
                staffMealsPerc = staffMealsSum / totRevenue;

                offeredMealsSum = Number(this.getOfferedMealsSum(i, true));
                totRevenue = this.foodCostData.Months[currentMonth].FoodRevenue[typeRevenue];
                offeredMealsPerc = offeredMealsSum / totRevenue;

                result = (finalFoodCost - staffMealsPerc - offeredMealsPerc) * 100;
                break;
            case 4: // BEVERAGE
                finalFoodCost = this.foodCostData.Months[currentMonth].Beverage.FinalFoodCost - this.foodCostData.Months[currentMonth].Beverage.WastePerc;

                staffMealsSum = Number(this.getStaffMealsSum(i, true));
                totRevenue = this.foodCostData.Months[currentMonth].BeverageRevenue[typeRevenue];
                staffMealsPerc = staffMealsSum / totRevenue;

                offeredMealsSum = Number(this.getOfferedMealsSum(i, true));
                totRevenue = this.foodCostData.Months[currentMonth].BeverageRevenue[typeRevenue];
                offeredMealsPerc = offeredMealsSum / totRevenue;

                result = (finalFoodCost - staffMealsPerc - offeredMealsPerc) * 100;
                break;
        }

        return moduloRiga == 1 ? this.getStandardHeaderByColumnIndex(15) : exportXLSX ? this.utility.formatNumberExcel(result) : this.utility.formatNumber(result, this.locale);
    }

    getFoodCostPerc(i: number, exportXLSX: boolean = false) {
        const moduloRiga = i % 7;
        let result: any;
        const currentMonth = Math.floor(i / 7);
        const type: string = this.ck_lordo ? 'Gross' : '';
        switch (moduloRiga) {
            case 1: // Headers (Detailed)              
                result = this.getDetailedHeaderByColumnIndex(9);
                break;
            case 2: // Total (Detailed)
                result = this.foodCostData.Months[currentMonth].All['FinalFoodCost2' + type] * 100;
                break;
            case 3: // F&B (Detailed)
                result = this.foodCostData.Months[currentMonth].FoodBevarage['FinalFoodCost2' + type] * 100;
                break;
            case 4: // FOOD
                result = this.foodCostData.Months[currentMonth].Food['FinalFoodCost2' + type] * 100;
                break;
            case 5: // BEVERAGE
                result = this.foodCostData.Months[currentMonth].Beverage['FinalFoodCost2' + type] * 100;
                break;
            case 6: // OTHER
                result = this.foodCostData.Months[currentMonth].Other['FinalFoodCost2' + type] * 100;
                break;
        }

        return moduloRiga > 1 ? (exportXLSX ? this.utility.formatNumberExcel(result) : this.utility.formatNumber(result, this.locale)) : result;
    }

    getTotActualFoodCost(type: string, exportXLSX: boolean = false) {
        let tot: number = 0;
        let finalFoodCost, food, beverage, staffMealsSum, totRevenue, staffMealsPerc, offeredMealsSum, offeredMealsPerc;
        const typeRevenue = this.ck_lordo ? 'Gross' : 'Net';
        switch (type) {
            case 'FoodBevarage': // F&B
                this.foodCostData.Months.forEach((month: any) => {
                    finalFoodCost = month.FoodBevarage.FinalFoodCost - month.FoodBevarage.WastePerc;

                    food = month.Food.StaffMeals.toString().replace(',', '.');
                    beverage = month.Beverage.StaffMeals.toString().replace(',', '.');
                    staffMealsSum = Number(food) + Number(beverage);
                    totRevenue = month.TotaleRevenue[typeRevenue];
                    staffMealsPerc = staffMealsSum / totRevenue;

                    food = month.Food.OfferedMeals.toString().replace(',', '.');
                    beverage = month.Beverage.OfferedMeals.toString().replace(',', '.');
                    offeredMealsSum = Number(food) + Number(beverage);
                    totRevenue = month.TotaleRevenue[typeRevenue];
                    offeredMealsPerc = offeredMealsSum / totRevenue;

                    tot += (finalFoodCost + staffMealsPerc + offeredMealsPerc) * 100;
                });
                break;
            case 'Food': // FOOD
                this.foodCostData.Months.forEach((month: any) => {
                    finalFoodCost = month.Food.FinalFoodCost - month.Food.WastePerc;

                    food = month.Food.StaffMeals.toString().replace(',', '.');
                    beverage = month.Beverage.StaffMeals.toString().replace(',', '.');
                    staffMealsSum = Number(food) + Number(beverage);
                    totRevenue = month.FoodRevenue[typeRevenue];
                    staffMealsPerc = staffMealsSum / totRevenue;

                    food = month.Food.OfferedMeals.toString().replace(',', '.');
                    beverage = month.Beverage.OfferedMeals.toString().replace(',', '.');
                    offeredMealsSum = Number(food) + Number(beverage);
                    totRevenue = month.FoodRevenue[typeRevenue];
                    offeredMealsPerc = offeredMealsSum / totRevenue;

                    tot += (finalFoodCost + staffMealsPerc + offeredMealsPerc) * 100;
                });
                break;
            case 'Beverage': // BEVERAGE
                this.foodCostData.Months.forEach((month: any) => {
                    finalFoodCost = month.Beverage.FinalFoodCost - month.Beverage.WastePerc;

                    food = month.Food.StaffMeals.toString().replace(',', '.');
                    beverage = month.Beverage.StaffMeals.toString().replace(',', '.');
                    staffMealsSum = Number(food) + Number(beverage);
                    totRevenue = month.BeverageRevenue[typeRevenue];
                    staffMealsPerc = staffMealsSum / totRevenue;

                    food = month.Food.OfferedMeals.toString().replace(',', '.');
                    beverage = month.Beverage.OfferedMeals.toString().replace(',', '.');
                    offeredMealsSum = Number(food) + Number(beverage);
                    totRevenue = month.BeverageRevenue[typeRevenue];
                    offeredMealsPerc = offeredMealsSum / totRevenue;

                    tot += (finalFoodCost + staffMealsPerc + offeredMealsPerc) * 100;
                });
                break;
        }
        return exportXLSX ? this.utility.formatNumberExcel(tot / this.foodCostData.Months.length) : this.utility.formatNumber(tot / this.foodCostData.Months.length, this.locale);
    }

    getTotFoodCostPerc(typeRow: string, exportXLSX: boolean = false) {
        const type: string = this.ck_lordo ? 'Gross' : '';
        const tot: number = this.foodCostData.Total[typeRow]['FinalFoodCost2' + type];
        return exportXLSX ? this.utility.formatNumberExcel(tot) : this.utility.formatNumber(tot, this.locale);
    }

    getStandardHeaderByColumnIndex(columnIndex: number) {
        let header: string = '';
        switch (columnIndex) {
            case 0:
                header = ''
                break;
            case 1:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')
                break;
            case 2:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')
                break;
            case 3:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')
                break;
            case 4:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')
                break;
            case 5:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')
                break;
            case 6:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.SELLING_FOODCOST')
                break;
            case 7:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_FOODCOST')
                break;
            case 8:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.DIFFERENCE2')
                break;
            case 9:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')
                break;
            case 10:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE_PERC')
                break;
            case 11:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')
                break;
            case 12:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS_PERC')
                break;
            case 13:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')
                break;
            case 14:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS_PERC')
                break;
            case 15:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.ACTUAL_FOODCOST')
                break;
        }
        return header;
    }

    getDetailedHeaderByColumnIndex(columnIndex: number) {
        let header: string = '';
        switch (columnIndex) {
            case 0:
                header = ''
                break;
            case 1:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')
                break;
            case 2:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')
                break;
            case 3:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')
                break;
            case 4:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')
                break;
            case 5:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')
                break;
            case 6:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')
                break;
            case 7:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')
                break;
            case 8:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')
                break;
            case 9:
                header = this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOODCOST_PERC')
                break;
        }
        return header;
    }

    initTableFoodCostDetailed() {
        setTimeout(() => {
            let dtOptions: any = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: false,
                //scrollY: 'calc(100vh - 25em)',
                //scrollCollapse: true,
                columnDefs: [
                    { targets: 0, width: '100px' },
                    { targets: [1, 7], visible: !this.ck_excludeInventory }
                ],
                autoWidth: false
            };
            this.tableFoodCost = $('#tableFoodCostDetailed').DataTable(dtOptions);
            if ($('.toolbarButton').length) $('.toolbarButton').remove();

        }, 100);

    }

    initTableFoodCost() {
        setTimeout(() => {
            let dtOptions: any = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: false,
                scrollY: 'calc(100vh - 25em)',
                scrollCollapse: true,
                columnDefs: [
                    { targets: 0, width: '100px' },
                    { targets: [10, 12, 14, 15], width: '50px' },
                    { targets: [11, 13], width: '70px' }
                ],
                autoWidth: false
            };
            this.tableFoodCost = $('#tableFoodCost').DataTable(dtOptions);
            if ($('.toolbarButton').length) $('.toolbarButton').remove();
            //this.tableFoodCost.columns.adjust().draw();

            $('#tableFoodCost td.hasInput').off();
            $('#tableFoodCost td.hasInput').on({
                click: (ev: any) => {
                    const element = $(ev.target);
                    this.triggerClickToShowInput(element);
                },
                focusout: (ev: any) => {
                    const element = $(ev.target);
                    const parentRow = element.closest('tr');
                    $(parentRow).removeClass('focusedRow');
                }
            });

            $('#tableFoodCost td.hasInput input').off();
            $('#tableFoodCost td.hasInput input').on({
                focusout: () => {
                    this.mappaInput = [];
                    this.ref.detectChanges();
                }
            });
        }, 100);

    }

    getBodyRowsCount() {
        let counter: number = 0;
        const k = this.currentView == 'standard' ? 5 : 7;
        return Array(this.foodCostData.Months.length * k).fill(1);
    }

    getRowHeader(row: number): string {
        const k = this.currentView == 'standard' ? 5 : 7;
        const indiceMese = Math.floor(row / k);
        const moduloRiga = row % k;
        switch (moduloRiga) {
            case 0: // Month
                const dateString = this.foodCostData.Months[indiceMese].Name;
                const date = new Date(dateString);
                return date.toLocaleString(this.translationService.getSelectedLanguage(), { month: 'long', year: 'numeric' });
            case 1: // Headers (Detailed)
                return this.currentView == 'standard' ? this.getStandardHeaderByColumnIndex(0) : this.getDetailedHeaderByColumnIndex(0);
            case 2: // F&B (Standard) - Total (Detailed)
                return this.currentView == 'standard' ? this.translate.instant('ANALISI_FOODCOST.ROW_HEADER.FB') : this.translate.instant('ANALISI_FOODCOST.ROW_HEADER.TOTAL');
            case 3: // FOOD (Standard) - F&B (Detailed)
                return this.currentView == 'standard' ? this.translate.instant('ANALISI_FOODCOST.ROW_HEADER.FOOD') : this.translate.instant('ANALISI_FOODCOST.ROW_HEADER.FB');
            case 4: // BEVERAGE (Standard) - FOOD (Deatiled)
                return this.currentView == 'standard' ? this.translate.instant('ANALISI_FOODCOST.ROW_HEADER.BEVERAGE') : this.translate.instant('ANALISI_FOODCOST.ROW_HEADER.FOOD');
            case 5: // BEVERAGE
                return this.translate.instant('ANALISI_FOODCOST.ROW_HEADER.BEVERAGE');
            case 6: // OTHER
                return this.translate.instant('ANALISI_FOODCOST.ROW_HEADER.OTHER');
            default:
                return '';
        }
    }

    getCurrentMonth(row: number): number {
        const k = this.currentView == 'standard' ? 5 : 7;
        return Math.floor(row / k);
    }

    getInitial(type: string, element: string, exportXLSX: boolean = false) {
        let tot: number = 0;
        let initialMonth = '2100-01';
        this.foodCostData.Months.forEach((month: any) => {
            if (month.Name <= initialMonth) {
                initialMonth = month.Name;
                tot += month[type][element];
            }
        });
        return exportXLSX ? this.utility.formatNumberExcel(tot) : this.utility.formatNumber(tot, this.locale);
    }

    getFinal(type: string, element: string, exportXLSX: boolean = false) {
        let tot: number = 0;
        let finalMonth = '2000-01';
        this.foodCostData.Months.forEach((month: any) => {
            if (month.Name >= finalMonth) {
                finalMonth = month.Name;
                tot = month[type][element];
            }

        });
        return exportXLSX ? this.utility.formatNumberExcel(tot) : this.utility.formatNumber(tot, this.locale);
    }

    getTotSum(type: string, element: string, exportXLSX: boolean = false) {
        let tot: number = 0;
        this.foodCostData.Months.forEach((month: any) => {
            tot += month[type][element];
        });
        return exportXLSX ? this.utility.formatNumberExcel(tot) : this.utility.formatNumber(tot, this.locale);
    }

    getSum(row: number, element: string, monthElements: string = '', exportXLSX: boolean = false, columnIndex: number) {
        if (this.currentView != 'standard' && element == 'UsedGoodsValue') {
            element = 'UsedGoodsValue2';
        }
        const k = this.currentView == 'standard' ? 5 : 7;
        const indiceMese = Math.floor(row / k);
        const moduloRiga = row % k;
        const currentMonth = this.foodCostData.Months[indiceMese];
        let ret = '';
        switch (moduloRiga) {
            case 1: // Headers (Detailed)
                ret = this.currentView == 'standard' ? this.getStandardHeaderByColumnIndex(columnIndex) : this.getDetailedHeaderByColumnIndex(columnIndex);
                break;
            case 2: // F&B (Standard) - Total (Detailed)
                ret = this.currentView == 'standard' ? (exportXLSX ? this.utility.formatNumberExcel(currentMonth.FoodBevarage[element]) : this.utility.formatNumber(currentMonth.FoodBevarage[element], this.locale)) : (exportXLSX ? this.utility.formatNumberExcel(currentMonth.All[element]) : this.utility.formatNumber(currentMonth.All[element], this.locale));
                break;
            case 3: // FOOD (Standard) - F&B (Detailed)
                ret = this.currentView == 'standard' ? (exportXLSX ? this.utility.formatNumberExcel(currentMonth.Food[element]) : this.utility.formatNumber(currentMonth.Food[element], this.locale)) : (exportXLSX ? this.utility.formatNumberExcel(currentMonth.FoodBevarage[element]) : this.utility.formatNumber(currentMonth.FoodBevarage[element], this.locale));
                break;
            case 4: // BEVERAGE (Standard) - FOOD (Deatiled)
                ret = this.currentView == 'standard' ? (exportXLSX ? this.utility.formatNumberExcel(currentMonth.Beverage[element]) : this.utility.formatNumber(currentMonth.Beverage[element], this.locale)) : (exportXLSX ? this.utility.formatNumberExcel(currentMonth.Food[element]) : this.utility.formatNumber(currentMonth.Food[element], this.locale));
                break;
            case 5: // BEVERAGE (Deatiled)
                ret = exportXLSX ? this.utility.formatNumberExcel(currentMonth.Beverage[element]) : this.utility.formatNumber(currentMonth.Beverage[element], this.locale);
                break;
            case 6: // OTHER (Deatiled)
                ret = exportXLSX ? this.utility.formatNumberExcel(currentMonth.Other[element]) : this.utility.formatNumber(currentMonth.Other[element], this.locale);
                break;
            case 0: // Mese
                const type: string = this.ck_lordo ? 'Gross' : 'Net';
                ret = (monthElements.length > 0) ? (exportXLSX ? this.utility.formatNumberExcel(currentMonth[monthElements][type]) : this.utility.formatNumber(currentMonth[monthElements][type], this.locale)) : '';
                break;
        }
        return ret;
    }

    openDetail(row: number) {
        const indiceMese = Math.floor(row / 7);
        const moduloRiga = row % 7;
        let data: any = undefined;
        let type: string = '';

        if (moduloRiga == 4) {
            data = this.foodCostData.Months[indiceMese].Food;
            type = 'Food';
        } else if (moduloRiga == 5) {
            data = this.foodCostData.Months[indiceMese].Beverage;
            type = 'Beverage';
        } else if (moduloRiga == 6) {
            data = this.foodCostData.Months[indiceMese].Other;
            type = 'Other';
        }

        if (data) {
            this.dialog
                .open(FoodCostDetailComponent, {
                    data: {
                        detail: data,
                        monthData: this.foodCostData.Months[indiceMese],
                        type: type,
                        locale: this.locale,
                        currentFilterCfg: this.currentFilterCfg
                    },
                    width: '100%',
                    height: '100%'
                });
        }
    }

    parseFloat(number: any) {
        return parseFloat(number);
    }

    getTotAvg(type: string, element: string, exportXLSX: boolean = false) {
        let tot: number = 0;
        let rows: number = 1;
        this.foodCostData.Months.forEach((month: any) => {
            tot += month[type][element];
            rows++;
        });
        rows--;

        return exportXLSX ? this.utility.formatNumberExcel(tot / rows * 100) : this.utility.formatNumber(tot / rows * 100, this.locale);
    }

    getOtherRevenue(row: number, exportXLSX: boolean = false) {
        const k = this.currentView == 'standard' ? 5 : 7;
        const indiceMese = Math.floor(row / k);
        const moduloRiga = row % k;
        const currentMonth = this.foodCostData.Months[indiceMese];
        const typeRevenue = this.ck_lordo ? 'Gross' : 'Net';
        switch (moduloRiga) {
            case 0: // Mese
                return exportXLSX ? this.utility.formatNumberExcel(currentMonth.OtherRevenue[typeRevenue]) : this.utility.formatNumber(currentMonth.OtherRevenue[typeRevenue], this.locale);
            default:
                return '';
        }
    }

    getAvg(row: number, element: string, monthElements: string = '', exportXLSX: boolean = false, columnIndex: number = 0) {
        const k = this.currentView == 'standard' ? 5 : 7;
        const indiceMese = Math.floor(row / k);
        const moduloRiga = row % k;
        const currentMonth = this.foodCostData.Months[indiceMese];
        switch (moduloRiga) {
            case 1: // Headers
                return this.currentView == 'standard' ? this.getStandardHeaderByColumnIndex(columnIndex) : this.getDetailedHeaderByColumnIndex(columnIndex);
            case 2: // F&B (Standard) - Total (Detailed)
                return this.currentView == 'standard' ? (exportXLSX ? this.utility.formatNumberExcel(currentMonth.FoodBevarage[element] * 100) : this.utility.formatNumber(currentMonth.FoodBevarage[element] * 100, this.locale)) : (exportXLSX ? this.utility.formatNumberExcel(currentMonth.All[element] * 100) : this.utility.formatNumber(currentMonth.All[element] * 100, this.locale));
            case 3: // FOOD (Standard) - F&B (Detailed)
                return this.currentView == 'standard' ? (exportXLSX ? this.utility.formatNumberExcel(currentMonth.Food[element] * 100) : this.utility.formatNumber(currentMonth.Food[element] * 100, this.locale)) : (exportXLSX ? this.utility.formatNumberExcel(currentMonth.FoodBevarage[element] * 100) : this.utility.formatNumber(currentMonth.FoodBevarage[element] * 100, this.locale));
            case 4: // BEVERAGE (Standard) - FOOD (Deatiled)
                return this.currentView == 'standard' ? (exportXLSX ? this.utility.formatNumberExcel(currentMonth.Beverage[element] * 100) : this.utility.formatNumber(currentMonth.Beverage[element] * 100, this.locale)) : (exportXLSX ? this.utility.formatNumberExcel(currentMonth.Food[element] * 100) : this.utility.formatNumber(currentMonth.Food[element] * 100, this.locale));
            case 5: // BEVERAGE (Deatiled)
                return exportXLSX ? this.utility.formatNumberExcel(currentMonth.Beverage[element] * 100) : this.utility.formatNumber(currentMonth.Beverage[element] * 100, this.locale);
            case 6: // OTHER
                return exportXLSX ? this.utility.formatNumberExcel(currentMonth.Other[element] * 100) : this.utility.formatNumber(currentMonth.Other[element] * 100, this.locale);
        }
    }

    /******************************************************************************
      ______                       _    __   ___       _______   __
     |  ____|                     | |   \ \ / / |     / ____\ \ / /
     | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
     |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
     | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
     |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
                 | |
                 |_|
    ******************************************************************************/

    exportAsXLSX() {

        this.loading$.next(true);
        let xlsx: any[] = [];
        let rowsStyles: any = [];
        let index: number = 0;

        rowsStyles[1] = {
            fgColor: { rgb: 'ffdd45' },
            bold: true,
            alignment: { wrapText: true }
        };

        // Aggiunti i supertotali iniziali
        let totali: any;
        const typeRevenue = this.ck_lordo ? 'Gross' : 'Net';

        if (this.currentView == 'standard' && this.foodCostData.Months.length > 1) {
            totali = {
                [``]: '',
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')}`]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.TOTAL_REVENUE'),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')}`]: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.TotaleRevenue[typeRevenue], this.locale) : 0),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')}`]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_REVENUE'),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')}`]: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.FoodRevenue[typeRevenue], this.locale) : 0),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')}`]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.BEVERAGE_REVENUE'),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.SELLING_FOODCOST')}`]: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.BeverageRevenue[typeRevenue], this.locale) : 0),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_FOODCOST')}`]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.OTHER_REVENUE'),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.DIFFERENCE2')}`]: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.OtherRevenue[typeRevenue], this.locale) : 0),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')}`]: '',
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE_PERC')}.`]: '',
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')}`]: '',
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS_PERC')}`]: '',
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')}`]: '',
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS_PERC')} `]: '',
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.ACTUAL_FOODCOST')}`]: ''
            };
            xlsx.push(totali);
        } else if (this.currentView == 'detailed' && this.foodCostData.Months.length > 1){
            totali = {
                [``]: '',
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')}`]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.TOTAL_REVENUE'),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')}`]: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.TotaleRevenue[typeRevenue], this.locale) : 0),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')}`]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_REVENUE'),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')}`]: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.FoodRevenue[typeRevenue], this.locale) : 0),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')}`]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.BEVERAGE_REVENUE'),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')}`]: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.BeverageRevenue[typeRevenue], this.locale) : 0),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')}`]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.OTHER_REVENUE'),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')}`]: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.OtherRevenue[typeRevenue], this.locale) : 0),
                [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOODCOST_PERC')}`]: ''
            };
            xlsx.push(totali);
        }

        

        if (this.foodCostData.Months.length > 1) {
            // aggiungo intestazione
            let totali: any;
            if (this.currentView !== 'standard') {
                totali = {
                    [``]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FB_TOTALS'),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')}`]: this.getInitial('All', 'InitialInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')}`]: this.getTotSum('All', 'Purchases', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')}`]: this.getTotSum('All', 'Waste', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')}`]: this.getTotSum('All', 'Transfer', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')}`]: this.getTotStaffMealsSum('All', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')}`]: this.getTotOfferedMealsSum('All', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')}`]: this.getFinal('All', 'FinalInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')}`]: this.getTotSum('All', 'UsedGoodsValue', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOODCOST_PERC')}`]: this.getTotAvg('All', 'ActualFoodCost', true)
                };
                xlsx.push(totali);
            }
            if (this.currentView == 'standard') { // F&B
                totali = {
                    [``]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FB_TOTALS'),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')}`]: this.getInitial('FoodBevarage', 'InitialInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')}`]: this.getTotSum('FoodBevarage', 'Purchases', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')}`]: this.getTotSum('FoodBevarage', 'Transfer', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')}`]: this.getFinal('FoodBevarage', 'FinalInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')}`]: this.getTotSum('FoodBevarage', 'UsedGoodsValue', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.SELLING_FOODCOST')}`]: this.getTotAvg('FoodBevarage', 'SellingFoodCost', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_FOODCOST')}`]: this.getTotAvg('FoodBevarage', 'FinalFoodCost', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.DIFFERENCE2')}`]: this.getTotAvg('FoodBevarage', 'Difference2', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')}`]: this.getTotSum('FoodBevarage', 'Waste', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE_PERC')}.`]: this.getTotAvg('FoodBevarage', 'WastePerc', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')}`]: this.getTotStaffMealsSum('FoodBevarage', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS_PERC')}`]: this.getTotStaffMealsPerc('FoodBevarage', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')}`]: this.getTotOfferedMealsSum('FoodBevarage', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS_PERC')} `]: this.getTotOfferedMealsPerc('FoodBevarage', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.ACTUAL_FOODCOST')}`]: this.getTotAvg('FoodBevarage', 'ActualFoodCost', true)
                };
            } else {
                totali = {
                    [``]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FB_TOTALS'),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')}`]: this.getInitial('FoodBevarage', 'InitialInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')}`]: this.getTotSum('FoodBevarage', 'Purchases', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')}`]: this.getTotSum('FoodBevarage', 'Waste', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')}`]: this.getTotSum('FoodBevarage', 'Transfer', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')}`]: this.getTotStaffMealsSum('FoodBevarage', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')}`]: this.getTotOfferedMealsSum('FoodBevarage', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')}`]: this.getFinal('FoodBevarage', 'FinalInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')}`]: this.getTotSum('FoodBevarage', 'UsedGoodsValue', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOODCOST_PERC')}`]: this.getTotAvg('FoodBevarage', 'ActualFoodCost', true)
                };
            }
            xlsx.push(totali);
            // aggiungo intestazione
            if (this.currentView == 'standard') { // Food
                totali = {
                    [``]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_TOTALS'),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')}`]: this.getInitial('Food', 'InitialInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')}`]: this.getTotSum('Food', 'Purchases', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')}`]: this.getTotSum('Food', 'Transfer', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')}`]: this.getFinal('Food', 'FinalInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')}`]: this.getTotSum('Food', 'UsedGoodsValue', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.SELLING_FOODCOST')}`]: this.getTotAvg('Food', 'SellingFoodCost', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_FOODCOST')}`]: this.getTotAvg('Food', 'FinalFoodCost', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.DIFFERENCE2')}`]: this.getTotAvg('Food', 'Difference2', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')}`]: this.getTotSum('Food', 'Waste', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE_PERC')}.`]: this.getTotAvg('Food', 'WastePerc', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')}`]: this.getTotStaffMealsSum('Food', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS_PERC')}`]: this.getTotStaffMealsPerc('Food', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')}`]: this.getTotOfferedMealsSum('Food', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS_PERC')} `]: this.getTotOfferedMealsPerc('Food', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.ACTUAL_FOODCOST')}`]: this.getTotAvg('Food', 'ActualFoodCost', true)
                };
            } else {
                totali = {
                    [``]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_TOTALS'),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')}`]: this.getInitial('Food', 'InitialInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')}`]: this.getTotSum('Food', 'Purchases', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')}`]: this.getTotSum('Food', 'Waste', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')}`]: this.getTotSum('Food', 'Transfer', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')}`]: this.getTotStaffMealsSum('Food', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')}`]: this.getTotOfferedMealsSum('Food', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')}`]: this.getFinal('Food', 'FinalInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')}`]: this.getTotSum('Food', 'UsedGoodsValue', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOODCOST_PERC')}`]: this.getTotAvg('Food', 'ActualFoodCost', true)
                };
            }

            xlsx.push(totali);
            // aggiungo intestazione
            if (this.currentView == 'standard') { // Beverage
                totali = {
                    [``]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.BEVERAGE_TOTALS'),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')}`]: this.getInitial('Beverage', 'InitialInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')}`]: this.getTotSum('Beverage', 'Purchases', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')}`]: this.getTotSum('Beverage', 'Transfer', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')}`]: this.getFinal('Beverage', 'FinalInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')}`]: this.getTotSum('Beverage', 'UsedGoodsValue', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.SELLING_FOODCOST')}`]: this.getTotAvg('Beverage', 'SellingFoodCost', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_FOODCOST')}`]: this.getTotAvg('Beverage', 'FinalFoodCost', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.DIFFERENCE2')}`]: this.getTotAvg('Beverage', 'Difference2', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')}`]: this.getTotSum('Beverage', 'Waste', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE_PERC')}.`]: this.getTotAvg('Beverage', 'WastePerc', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')}`]: this.getTotStaffMealsSum('Beverage', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS_PERC')}`]: this.getTotStaffMealsPerc('Beverage', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')}`]: this.getTotOfferedMealsSum('Beverage', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS_PERC')} `]: this.getTotOfferedMealsPerc('Beverage', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.ACTUAL_FOODCOST')}`]: this.getTotAvg('Beverage', 'ActualFoodCost', true)
                };
            } else {
                totali = {
                    [``]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.BEVERAGE_TOTALS'),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')}`]: this.getInitial('Beverage', 'InitialInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')}`]: this.getTotSum('Beverage', 'Purchases', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')}`]: this.getTotSum('Beverage', 'Waste', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')}`]: this.getTotSum('Beverage', 'Transfer', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')}`]: this.getTotStaffMealsSum('Beverage', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')}`]: this.getTotOfferedMealsSum('Beverage', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')}`]: this.getFinal('Beverage', 'FinalInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')}`]: this.getTotSum('Beverage', 'UsedGoodsValue', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOODCOST_PERC')}`]: this.getTotAvg('Beverage', 'ActualFoodCost', true)
                };
            }
            xlsx.push(totali);

            // aggiungo intestazione
            if (this.currentView == 'detailed') {
                totali = {
                    [``]: this.translate.instant('ANALISI_FOODCOST.COLUMNS.OTHER_TOTALS'),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')}`]: this.getInitial('Other', 'InitialInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')}`]: this.getTotSum('Other', 'Purchases', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')}`]: this.getTotSum('Other', 'Waste', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')}`]: this.getTotSum('Other', 'Transfer', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')}`]: this.getTotStaffMealsSum('Other', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')}`]: this.getTotOfferedMealsSum('Other', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')}`]: this.getFinal('Other', 'FinalInventory', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')}`]: this.getTotSum('Other', 'UsedGoodsValue', true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOODCOST_PERC')}`]: this.getTotAvg('Other', 'ActualFoodCost', true)
                };
                xlsx.push(totali);
            }
        }


        let merges: any[] = [];
        let i: number = 0;
        let sortedList = this.utility.sortList(this.getBodyRowsCount(), 'tableFoodCost');
        const k = this.currentView == 'standard' ? 5 : 7;
        sortedList.forEach(() => {
            let indexStyle = this.foodCostData.Months.length > 1 ? i + k : i;
            if (i % k === 0) {                
                //indexStyle++; // per via della prima riga dei totali sotto all'header.
                rowsStyles[indexStyle++] = {
                    fgColor: { rgb: 'f5c195' },
                    bold: true,
                    alignment: { wrapText: true }
                };
                if (this.foodCostData.Months.length == 1) {
                    indexStyle++;
                }
                rowsStyles[indexStyle++] = {
                    fgColor: { rgb: 'ededed' },
                    bold: true,
                    alignment: { wrapText: true }
                };
            }

            if (this.currentView == 'standard') {
                xlsx.push({
                    [``]: this.getRowHeader(i),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')}`]: (i % 5 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.TOTAL_REVENUE') : this.getSum(i, 'InitialInventory', 'TotaleRevenue', true, 1),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')}`]: (i % 5 === 0) ? this.getSum(i, 'Purchases', 'TotaleRevenue', true, 2) : this.getSum(i, 'Purchases', '', true, 2),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')}`]: (i % 5 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_REVENUE') : this.getSum(i, 'Transfer', '', true, 3),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')}`]: this.getSum(i, 'FinalInventory', 'FoodRevenue', true, 4),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')}`]: (i % 5 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.BEVERAGE_REVENUE') : this.getSum(i, 'UsedGoodsValue', 'BeverageRevenue', true, 5),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.SELLING_FOODCOST')}`]: (i % 5 === 0) ? this.getSum(i, '', 'BeverageRevenue', true, 6) : this.getAvg(i, 'SellingFoodCost', '', true, 6),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_FOODCOST')}`]: (i % 5 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.OTHER_REVENUE') : this.getAvg(i, 'FinalFoodCost', '', true, 7),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.DIFFERENCE2')}`]: (i % 5 === 0) ? this.getSum(i, '', 'OtherRevenue', true, 8) : this.getAvg(i, 'Difference2', '', true, 8),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')}`]: this.getSum(i, 'Waste', '', true, 9),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE_PERC')}.`]: this.getAvg(i, 'WastePerc', '', true, 10),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')}`]: (i % 5) == 1 ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS') : ((i % 5) == 2 ? this.getStaffMealsSum(i, true) : ((i % 5) == 3 ? this.utility.formatNumberExcel(Number(this.foodCostData.Months[this.getCurrentMonth(i)].Food.StaffMeals.toString().replace(',', '.'))) : ((i % 5) == 4 ? this.utility.formatNumberExcel(Number(this.foodCostData.Months[this.getCurrentMonth(i)].Beverage.StaffMeals.toString().replace(',', '.'))): 0))),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS_PERC')}`]: this.getStaffMealsPerc(i, true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')}`]: (i % 5) == 1 ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS') :((i % 5) == 2 ? this.getOfferedMealsSum(i, true) : ((i % 5) == 3 ? this.utility.formatNumberExcel(Number(this.foodCostData.Months[this.getCurrentMonth(i)].Food.OfferedMeals.toString().replace(',', '.'))) : ((i % 5) == 4 ? this.utility.formatNumberExcel(Number(this.foodCostData.Months[this.getCurrentMonth(i)].Beverage.OfferedMeals.toString().replace(',', '.'))) : 0))),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS_PERC')} `]: this.getOfferedMealsPerc(i, true),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.ACTUAL_FOODCOST')}`]: this.getAvg(i, 'ActualFoodCost', '', true, 15)
                });
            } else {
                xlsx.push({
                    [``]: this.getRowHeader(i),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')}`]: (i % 7 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.TOTAL_REVENUE') : this.getSum(i, 'InitialInventory', '', true, 1),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')}`]: this.getSum(i, 'Purchases', 'TotaleRevenue', true, 2),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')}`]: (i % 7 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_REVENUE') : this.getSum(i, 'Waste', '', true, 3),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')}`]: this.getSum(i, 'Transfer', 'FoodRevenue', true, 4),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')}`]: (i % 7 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.BEVERAGE_REVENUE') : this.getSum(i, 'StaffMeals', '', true, 5),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')}`]: this.getSum(i, 'OfferedMeals', 'BeverageRevenue', true, 6),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')}`]: (i % 7 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.OTHER_REVENUE') : this.getSum(i, 'FinalInventory', '', true, 7),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')}`]: this.getSum(i, 'UsedGoodsValue', 'OtherRevenue', true, 8),
                    [`${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOODCOST_PERC')}`]: this.getAvg(i, 'ActualFoodCost', '', true, 9)
                });
            }
            i++;

        });

        let columnsInfo: any = [];

        if (this.currentView == 'standard') {
            columnsInfo = [
                { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } }
            ];
        } else {
            columnsInfo = [
                { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
                { enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } }
            ];
        }

        this.loading$.next(false);

        this.excelService.exportAsExcelFile(xlsx, 'FC', merges, columnsInfo, 1, rowsStyles, false);
    }

    exportAsPDF() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('EXPORT_PDF.TITLE_FOODCOST'),
                    period: this.filtriService.getCurrentPeriod()
                }
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                }
                result['language'] = this.translationService.getSelectedLanguage();

                if (this.currentView == 'standard') {
                    this.pdfService.makePdf(result, this.getPDFTableBody(), ['*', '*', '*', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'])
                } else {
                    this.pdfService.makePdf(result, this.getPDFTableBodyDetailed(), ['*', '*', '*', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'])
                }


            };
        });

    }

    getPDFTableBody() {
        let body: any = [];

        const typeRevenue = this.ck_lordo ? 'Gross' : 'Net';

        // aggiungo intestazione
        body.push([
            { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.SELLING_FOODCOST')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_FOODCOST')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.DIFFERENCE2')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE_PERC')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS_PERC')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS_PERC')} `, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.ACTUAL_FOODCOST')}`, style: 'tableHeaderStyle' }
        ]);
        // totali Header
        body.push([
            { alignment: 'left', fillColor: '#ffdd45', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.TOTAL_REVENUE'), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.TotaleRevenue[typeRevenue]) : 0), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_REVENUE'), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.FoodRevenue[typeRevenue]) : 0), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_REVENUE'), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.BeverageRevenue[typeRevenue]) : 0), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_REVENUE'), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.OtherRevenue[typeRevenue]) : 0), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' }
        ]);

        if (this.foodCostData.Months.length > 1) {
            // totali Values
            body.push([
                { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FB_TOTALS'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getInitial('FoodBevarage', 'InitialInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('FoodBevarage', 'Purchases'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('FoodBevarage', 'Transfer'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getFinal('FoodBevarage', 'FinalInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('FoodBevarage', 'UsedGoodsValue'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotAvg('FoodBevarage', 'SellingFoodCost'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotAvg('FoodBevarage', 'FinalFoodCost'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotAvg('FoodBevarage', 'Difference2'), style: this.parseFloat(this.getTotAvg('FoodBevarage', 'Difference2')) > 0 ? 'tableBodyStyleRed' : (this.parseFloat(this.getTotAvg('FoodBevarage', 'Difference2')) > 0 ? 'tableBodyStyleGreen' : 'tableBodyStyle') },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('FoodBevarage', 'Waste'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotAvg('FoodBevarage', 'WastePerc'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotStaffMealsSum('FoodBevarage'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotStaffMealsPerc('FoodBevarage'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotOfferedMealsSum('FoodBevarage'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotOfferedMealsPerc('FoodBevarage'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotActualFoodCost('FoodBevarage'), style: 'tableBodyStyle' }
            ]);
            // totali Values
            body.push([
                { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_TOTALS'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getInitial('Food', 'InitialInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Food', 'Purchases'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Food', 'Transfer'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getFinal('Food', 'FinalInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Food', 'UsedGoodsValue'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotAvg('Food', 'SellingFoodCost'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotAvg('Food', 'FinalFoodCost'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotAvg('Food', 'Difference2'), style: this.parseFloat(this.getTotAvg('Food', 'Difference2')) > 0 ? 'tableBodyStyleRed' : (this.parseFloat(this.getTotAvg('Food', 'Difference2')) < 0 ? 'tableBodyStyleGreen' : 'tableBodyStyle') },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Food', 'Waste'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotAvg('Food', 'WastePerc'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotStaffMealsSum('Food'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotStaffMealsPerc('Food'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotOfferedMealsSum('Food'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotOfferedMealsPerc('Food'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotActualFoodCost('Food'), style: 'tableBodyStyle' }
            ]);
            // totali Values
            body.push([
                { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.BEVERAGE_TOTALS'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getInitial('Beverage', 'InitialInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Beverage', 'Purchases'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Beverage', 'Transfer'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getFinal('Beverage', 'FinalInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Beverage', 'UsedGoodsValue'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotAvg('Beverage', 'SellingFoodCost'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotAvg('Beverage', 'FinalFoodCost'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotAvg('Beverage', 'Difference2'), style: this.parseFloat(this.getTotAvg('Beverage', 'Difference2')) > 0 ? 'tableBodyStyleRed' : (this.parseFloat(this.getTotAvg('Beverage', 'Difference2')) < 0 ? 'tableBodyStyleGreen' : 'tableBodyStyle') },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Beverage', 'Waste'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotAvg('Beverage', 'WastePerc'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotStaffMealsSum('Beverage'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotStaffMealsPerc('Beverage'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotOfferedMealsSum('Beverage'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotOfferedMealsPerc('Beverage'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotActualFoodCost('Beverage'), style: 'tableBodyStyle' }
            ]);
        }

        let i: number = 0;
        let sortedList = this.utility.sortList(this.getBodyRowsCount(), 'tableFoodCost');
        sortedList.forEach(() => {
            //this.getBodyRowsCount().forEach(() => {
            body.push([
                { alignment: 'left', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getRowHeader(i), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: (i % 5 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.TOTAL_REVENUE') + '\n' + this.getSum(i, 'InitialInventory', 'TotaleRevenue', false, 1) : this.getSum(i, 'InitialInventory', 'TotaleRevenue', false, 1), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: (i % 5 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_REVENUE') + '\n' + this.getSum(i, 'Purchases', 'FoodRevenue', false, 2) : this.getSum(i, 'Purchases', 'FoodRevenue', false, 2), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: (i % 5 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.BEVERAGE_REVENUE') + '\n' + this.getSum(i, 'Transfer', 'BeverageRevenue', false, 3) : this.getSum(i, 'Transfer', 'BeverageRevenue', false, 3), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getSum(i, 'FinalInventory', 'BeverageRevenue', false, 4), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getSum(i, 'UsedGoodsValue', '', false, 5), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getAvg(i, 'SellingFoodCost', '', false, 6), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getAvg(i, 'FinalFoodCost', '', false, 7), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getAvg(i, 'Difference2', '', false, 8), style: (i % 5 === 0) ? (this.parseFloat(this.getAvg(i, 'Difference2', '', false, 8)) > 0 ? 'tableBodyStyleRed' : (this.parseFloat(this.getAvg(i, 'Difference2', '', false, 8)) > 0 ? 'tableBodyStyleGreen' : 'tableBodyStyle')) : this.parseFloat(this.getAvg(i, 'Difference2', '', false, 8)) > 0 ? 'tableBodyStyleRed' : (this.parseFloat(this.getAvg(i, 'Difference2', '', false, 8)) < 0 ? 'tableBodyStyleGreen' : 'tableBodyStyle') },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getSum(i, 'Waste', '', false, 0), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getAvg(i, 'WastePerc', '', false, 10), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: (i % 5) == 1 ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS') : ((i % 5) == 2 ? this.getStaffMealsSum(i) : ((i % 5) == 3 ? this.foodCostData.Months[this.getCurrentMonth(i)].Food.StaffMeals : ((i % 5) == 4 ? this.foodCostData.Months[this.getCurrentMonth(i)].Beverage.StaffMeals : 0))), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getStaffMealsPerc(i), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: (i % 5) == 1 ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS') : ((i % 5) == 2 ? this.getOfferedMealsSum(i) : ((i % 5) == 3 ? this.foodCostData.Months[this.getCurrentMonth(i)].Food.OfferedMeals : ((i % 5) == 4 ? this.foodCostData.Months[this.getCurrentMonth(i)].Beverage.OfferedMeals : 0))), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getOfferedMealsPerc(i), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 5 === 0) ? '#f5c195' : ((i % 5 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getActualFoodCost(i), style: 'tableBodyStyle' }
            ]);
            i++;
        });

        return body;
    }

    getPDFTableBodyDetailed() {
        let body: any = [];

        // aggiungo intestazione
        body.push([
            { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.INITIAL_INVENTORY')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.PURCHASES')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.WASTE')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.TRANSFERT')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.STAFF_MEALS')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.OFFERED_MEALS')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FINAL_INVENTORY')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.USED_GOODS_VALUE')}`, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOODCOST_PERC')}`, style: 'tableHeaderStyle' }
        ]);

        const type = this.ck_lordo ? 'Gross' : 'Net';

        // totali Header
        body.push([
            { alignment: 'left', fillColor: '#ffdd45', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.TOTAL_REVENUE'), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.TotaleRevenue[type]) : 0), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_REVENUE'), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.FoodRevenue[type]) : 0), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_REVENUE'), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.BeverageRevenue[type]) : 0), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_REVENUE'), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: (this.foodCostData && this.foodCostData.Total ? this.utility.formatNumberExcel(this.foodCostData.Total.OtherRevenue[type]) : 0), style: 'tableHeaderStyle' },
            { alignment: 'right', fillColor: '#ffdd45', border: [true, true, true, true], text: ``, style: 'tableHeaderStyle' }
        ]);

        if (this.foodCostData.Months.length > 1) {
            // totali Values
            body.push([
                { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.TOTAL'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getInitial('All', 'InitialInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('All', 'Purchases'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('All', 'Waste'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('All', 'Transfer'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotStaffMealsSum('All'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotOfferedMealsSum('All'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getFinal('All', 'FinalInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('All', 'UsedGoodsValue'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotFoodCostPerc('All'), style: 'tableBodyStyle' }
            ]);
            // totali Values
            body.push([
                { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FB_TOTALS'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getInitial('FoodBevarage', 'InitialInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('FoodBevarage', 'Purchases'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('FoodBevarage', 'Waste'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('FoodBevarage', 'Transfer'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotStaffMealsSum('FoodBevarage'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotOfferedMealsSum('FoodBevarage'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getFinal('FoodBevarage', 'FinalInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('FoodBevarage', 'UsedGoodsValue'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotFoodCostPerc('FoodBevarage'), style: 'tableBodyStyle' }
            ]);
            // totali Values
            body.push([
                { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.FB_TOTALS'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getInitial('Food', 'InitialInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Food', 'Purchases'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Food', 'Waste'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Food', 'Transfer'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotStaffMealsSum('Food'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotOfferedMealsSum('Food'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getFinal('Food', 'FinalInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Food', 'UsedGoodsValue'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotFoodCostPerc('Food'), style: 'tableBodyStyle' }
            ]);
            // totali Values
            body.push([
                { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.BEVERAGE_TOTALS'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getInitial('Beverage', 'InitialInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Beverage', 'Purchases'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Beverage', 'Waste'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Beverage', 'Transfer'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotStaffMealsSum('Beverage'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotOfferedMealsSum('Beverage'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getFinal('Beverage', 'FinalInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Beverage', 'UsedGoodsValue'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotFoodCostPerc('Beverage'), style: 'tableBodyStyle' }
            ]);

            // totali Values
            body.push([
                { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: this.translate.instant('ANALISI_FOODCOST.COLUMNS.OTHER_TOTALS'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getInitial('Other', 'InitialInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Other', 'Purchases'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Other', 'Waste'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Other', 'Transfer'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotStaffMealsSum('Other'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotOfferedMealsSum('Other'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getFinal('Other', 'FinalInventory'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotSum('Other', 'UsedGoodsValue'), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.getTotFoodCostPerc('Other'), style: 'tableBodyStyle' }
            ]);
        }

        let i: number = 0;
        let sortedList = this.utility.sortList(this.getBodyRowsCount(), 'tableFoodCost');
        sortedList.forEach(() => {
            body.push([
                { alignment: 'left', fillColor: (i % 7 === 0) ? '#f5c195' : ((i % 7 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getRowHeader(i), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 7 === 0) ? '#f5c195' : ((i % 7 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: (i % 7 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.TOTAL_REVENUE') : this.getSum(i, 'InitialInventory', '', false, 1), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 7 === 0) ? '#f5c195' : ((i % 7 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getSum(i, 'Purchases', 'TotaleRevenue', false, 2), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 7 === 0) ? '#f5c195' : ((i % 7 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: (i % 7 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.FOOD_REVENUE') : this.getSum(i, 'Waste', '', false, 3), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 7 === 0) ? '#f5c195' : ((i % 7 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getSum(i, 'Transfer', 'FoodRevenue', false, 4), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 7 === 0) ? '#f5c195' : ((i % 7 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: (i % 7 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.BEVERAGE_REVENUE') : this.getSum(i, 'StaffMeals', '', false, 5), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 7 === 0) ? '#f5c195' : ((i % 7 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getSum(i, 'OfferedMeals', 'BeverageRevenue', false, 6), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 7 === 0) ? '#f5c195' : ((i % 7 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: (i % 7 === 0) ? this.translate.instant('ANALISI_FOODCOST.COLUMNS.OTHER_REVENUE') : this.getSum(i, 'FinalInventory', '', false, 7), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 7 === 0) ? '#f5c195' : ((i % 7 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getSum(i, 'UsedGoodsValue', 'OtherRevenue', false, 8), style: 'tableBodyStyle' },
                { alignment: 'right', fillColor: (i % 7 === 0) ? '#f5c195' : ((i % 7 === 1) ? '#eeeeee' : ''), border: [true, true, true, true], text: this.getFoodCostPerc(i), style: 'tableBodyStyle' }
            ]);
            i++;
        });

        return body;
    }

}
