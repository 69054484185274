import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { catchError, map, take, debounceTime, distinctUntilChanged, tap, mergeMap } from 'rxjs/operators';

import { Router, ActivatedRoute } from '@angular/router';

// Services
import { TranslateService } from '@ngx-translate/core';
import { VenditeManualiService } from '@app/core/services/vendite-manuali.service';
import { FiltriService } from '@app/core/services/filtri.service';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { ScreenSizeService } from '@app/core/services/screen-size.service';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

// Component
import { FiltroPopupComponent } from './filtro-popup/filtro-popup.component';

// Material
import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';
import * as util from '@app/core/services/utilityfunctions';
import { clear } from 'console';

@Component({
  selector: 'kt-vendite-manuali',
  templateUrl: './vendite-manuali.component.html',
  styleUrls: ['./vendite-manuali.component.scss']
})
export class VenditeManualiComponent implements OnInit {

  switchGroupSubcription!: Subscription;
  filtriSubscription!: Subscription;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // inputChanged: Subject<any> = new Subject<any>();

  currentFilterCfg: any;

  completedRows: boolean = false;
  hoverglassActive: boolean = false;
  table: any;
  list: any;
  listaFiltered: any = [];

  fromMenu: boolean = false;
  productNameFilter!: string;
  utility: any;

  isMobile: boolean = false;

  locale!: string;

  isVenditeManuali: boolean = false;
  isRettificheDiVendita: boolean = false;
  isProdottiFiniti: boolean = false;
  isInventario: boolean = false;

  constructor(
    private translate: TranslateService,
    private ref: ChangeDetectorRef,
    private filtriService: FiltriService,
    private switchGroupService: SwitchGroupService,
    private venditeManualiService: VenditeManualiService,
    private screenSizeService: ScreenSizeService,
    public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
    private translationService: TranslationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.utility = util;
    this.translationService.performSwitchLanguage.subscribe((lang) => {
      this.locale = lang;
    });
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 1023;
    });

    // this.activatedRoute.queryParams.subscribe((params: any) => {
    //   if ($.fn.dataTable.isDataTable('#table')) {
    //     $('#table').DataTable().clear().destroy();
    //   }

    //   this.currentFilterCfg = undefined;
    //   this.clearFilters(false);

    //   console.log('params', params);
    //   this.isRettificheDiVendita = params && params.type == 'rettifiche-vendita';
    //   this.isProdottiFiniti = params && params.type == 'prodotti-finiti';
    //   this.isInventario = params && params.type == 'inventario';
    //   this.isVenditeManuali = !this.isRettificheDiVendita && !this.isProdottiFiniti;
    //   // this.get();
    //   this.openPopup();
    // });

    this.currentFilterCfg = undefined;
    this.clearFilters(false);

    this.isRettificheDiVendita = router.url == '/rettifiche-vendita';
    this.isProdottiFiniti = router.url == '/prodotti-finiti';
    this.isInventario = router.url == '/inventario';
    this.isVenditeManuali = !this.isRettificheDiVendita && !this.isProdottiFiniti;
    this.openPopup();

  }

  getTitle() {
    if (this.isRettificheDiVendita) {
      return 'RETTIFICHE_VENDITA.Title';
    } else if (this.isProdottiFiniti) {
      return 'PRODOTTI_FINITI.Title';
    } else if (this.isInventario) {
      return 'MENU.VENDITE_MANUALI_INVENTARIO';
    }
    else {
      return 'VENDITE_MANUALI.Title';
    }
  }

  ngOnDestroy() {
    this.filtriSubscription.unsubscribe();
    this.switchGroupSubcription.unsubscribe();


    // cancello il filtro
    this.filtriService.filterConfig = {};
  }

  ngOnInit() {
    document.body.classList.add('kt-aside--minimize');

    this.filtriSubscription = this.filtriService.performFilter$.subscribe(
      (filterConfig: any) => {
        // console.log('filterConfig', filterConfig, filterConfig.constructor);
        if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) return;
        if (filterConfig.constructor !== Object) return;

        this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));
        console.log(this.currentFilterCfg);
        this.get();
      }
    );

    this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
      (event: any) => {
        if (event.change) {
          this.clearFilters(false);
        }
      }
    );

    // this.inputChanged
    //   .pipe(
    //     debounceTime(300),
    //     tap(() => this.onFilterChange(this.productNameFilter, 'ProductName'))
    //   )
    //   .subscribe();
  }

  getTooltip(type: string) {
    if (type === 'openPopup') return this.isProdottiFiniti ? this.translate.instant('PRODOTTI_FINITI.New') : (this.isRettificheDiVendita ? this.translate.instant('RETTIFICHE_VENDITA.New') : this.translate.instant('VENDITE_MANUALI.New'));
    if (type === 'clearFilters') return this.isProdottiFiniti ? this.translate.instant('PRODOTTI_FINITI.CleanFilter') : (this.isRettificheDiVendita ? this.translate.instant('RETTIFICHE_VENDITA.CleanFilter') : this.translate.instant('VENDITE_MANUALI.CleanFilter'));
    if (type === 'save') return this.isProdottiFiniti ? this.translate.instant('PRODOTTI_FINITI.Save') : (this.isRettificheDiVendita ? this.translate.instant('RETTIFICHE_VENDITA.Save') : this.translate.instant('VENDITE_MANUALI.Save'));
  }

  onInputChange($event: any) {
    if ($event.keyCode === 13) {
      // this.inputChanged.next();

      // Triggero la ricerca della datatable
      this.table.search(this.productNameFilter).draw();
    }
  }

  get() {

    if (!this.currentFilterCfg) return;

    this.loading$.next(true);
    this.list = undefined;

    /*
          CategoryIds:[]  //=> LISTA DI INTERI
          Company:string
          ProductName:string
          ProductCode:string
          IsHalfWorked:boolean
    */


    if (this.isProdottiFiniti) {
      this.currentFilterCfg['IsHalfWorked'] = true;
    }

    this.venditeManualiService.get(this.currentFilterCfg).toPromise()
      .then(
        (results: any) => {
          console.log('get', results);
          this.list = results;
          this.listaFiltered = JSON.parse(JSON.stringify(results));
          this.initDataTable();
          this.loading$.next(false);
        }
      )
      .catch(error => {
        console.log('get ERROR=>', error, this.currentFilterCfg);
        this.loading$.next(false);
      });
  }

  clearFilters(actionFilter: boolean = true) {
    this.productNameFilter = '';
    this.fromMenu = false;
    this.filtriService.clearFilters$.next(actionFilter);
    $('#table').DataTable().clear().destroy();
    this.list = undefined;
    this.listaFiltered = undefined;
  }

  IsMenuChange($event: any) {
    this.onFilterChange($event.checked, 'IsMenu');
  }

  onFilterChange(event: any, type: string) {
    console.log('onFilterChange', type, event, this.currentFilterCfg);
    if (!this.currentFilterCfg) {
      this.currentFilterCfg = {};
    }
    this.currentFilterCfg[type] = event;
    this.filtriService.performFilter$.next(this.currentFilterCfg);
  }

  initDataTable() {
    if ($.fn.dataTable.isDataTable('#table')) {
      $('#table').DataTable().destroy();
    }
    this.hoverglassActive = true;
    setTimeout(() => {
      const dtOptions = {
        initComplete: () => {
          console.log('hoverglassActive');
          this.hoverglassActive = false;
          this.ref.detectChanges();
        },
        dom: 'lrt',
        destroy: true,
        language: {
          emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
          zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
        },
        paging: false,
        searching: true,
        ordering: true,
        //orderCellsTop: true,
        scrollY: 'calc(100vh - 400px)',
        scrollCollapse: true,
        scrollX: true,
        fixedColumns: {
          leftColumns: 3
        },
        columnDefs: [
          { targets: [0], width: '80px' },
          { targets: [1], width: '200px' },
          { targets: [3, 4, 5, 6], width: '80px' },
        ]
      };

      this.table = $('#table').DataTable(dtOptions);

      this.hoverglassActive = false;

      //this.refreshColumnsVisibility();
      //this.performGroupColumns(false);

      //this.table.columns.adjust().draw();
      // this.ref.detectChanges();
    }, 100);

  }

  currentFilter: any;

  openPopup() {


    const dialogRef = this.dialog.open(FiltroPopupComponent, {
      width: this.isMobile ? '400px' : '600px',
    });

    dialogRef.componentInstance.isRettifica = this.isRettificheDiVendita;
    dialogRef.componentInstance.isProdottiFiniti = this.isProdottiFiniti;

    dialogRef.afterClosed().toPromise()
      .then((res: any) => {
        if (res && res.success && res.body) {
          console.log(res.body);
          if (!this.currentFilterCfg) {
            this.currentFilterCfg = {};
          }

          this.currentFilter = res.body;

          if (res.body.CostCenter) this.currentFilterCfg['CostCenter'] = res.body.CostCenter.Id;
          if (res.body.CostCenterDest) this.currentFilterCfg['CostCenterDest'] = res.body.CostCenterDest.Id;
          if (res.body.CategoryIds) this.currentFilterCfg['CategoryIds'] = res.body.CategoryIds;
          if (res.body.Company) this.currentFilterCfg['Company'] = res.body.Company.Id;
          if (res.body.CompanyDst) this.currentFilterCfg['CompanyDst'] = res.body.CompanyDst.Id;

          if (this.isRettificheDiVendita && res.body.SelectedWasteType) {
            this.currentFilterCfg['SelectedWasteType'] = res.body.SelectedWasteType;
          }
          else {
            this.currentFilterCfg['SelectedWasteType'] = -1;
          }

          if (res.body.ProductName) {
            this.productNameFilter = res.body.ProductName
            this.currentFilterCfg['ProductName'] = res.body.ProductName
          };

          this.get();
        }
      });
  }

  enableEdit(item: any, entity: string) {
    item['isEditing' + entity] = true;
    setTimeout(() => {
      $('#input_' + item.Id).focus();
      $('#input_' + item.Id).select()
    }, 250);
  }

  manageKeyDown(event: any, row: number) {
    const keyCode = event.keyCode;
    if (keyCode === 9) { // TAB
      event.preventDefault();
      event.stopPropagation();

      const element = $(event.target).closest('tr').next('tr').find('.hasInput');
      this.triggerClickToShowInput(element);
    }
  }

  triggerClickToShowInput(el: any) {
    const id = $(el).data('element');

    let item = this.listaFiltered.find((item: any) => item.Id === id);
    item.isEditing = true;

    setTimeout(() => {
      $(el).find('input').select();
      $(el).find('input').focus();
    }, 100);
  }

  almoustOneCompiled() {
    let item = this.listaFiltered.find((item: any) => item.Quantity > 0);
    return item && item.length > 0;
  }

  save() {
    const _title: string = this.isProdottiFiniti ? this.translate.instant('PRODOTTI_FINITI.SAVE_DIALOG.TITLE') : (this.isRettificheDiVendita ? this.translate.instant('RETTIFICHE_VENDITA.SAVE_DIALOG.TITLE') : this.translate.instant('VENDITE_MANUALI.SAVE_DIALOG.TITLE'));
    const _description: string = this.isProdottiFiniti ? this.translate.instant('PRODOTTI_FINITI.SAVE_DIALOG.DESC') : (this.isRettificheDiVendita ? this.translate.instant('RETTIFICHE_VENDITA.SAVE_DIALOG.DESC') : this.translate.instant('VENDITE_MANUALI.SAVE_DIALOG.DESC'));;
    const _waitDesciption: string = '';
    const _yesButton = this.isProdottiFiniti ? this.translate.instant('PRODOTTI_FINITI.SAVE_DIALOG.BUTTON') : (this.isRettificheDiVendita ? this.translate.instant('RETTIFICHE_VENDITA.SAVE_DIALOG.BUTTON') : this.translate.instant('VENDITE_MANUALI.SAVE_DIALOG.BUTTON'));;
    const _noButton = this.translate.instant('COMMONS.CANCEL');

    const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
    dialogRef.afterClosed().subscribe((expand: any) => {
      if (expand) {

        this.loading$.next(true);

        let body: any = [];
        if (!this.currentFilter.Company) return;

        this.listaFiltered.forEach((item: any) => {
          if (item.Quantity && this.utility.parseNumber(item.Quantity, this.locale, 2) > 0) {
            let b = {
              Date: this.currentFilter.Date ? this.currentFilter.Date : moment().format('YYYY-MM-DD'),
              Company: this.currentFilter.Company.Name.trim(),

              IsMenu: false,
              CostCenterId: this.currentFilter.CostCenter.Id,
              CostCenterName: this.currentFilter.CostCenter.Name.trim(),
              ProductId: item.Id,
              Quantity: this.utility.parseNumber(item.Quantity, this.locale, 2),
              Price: this.utility.parseNumber(item.Price, this.locale, 2),
              FoodCost: this.utility.parseNumber(item.FoodCost, this.locale, 2),
              AdjustmentType: 'Unknown',
            };
            if (this.currentFilter.CompanyDst) {
              b['CompanyDst'] = this.currentFilter.CompanyDst.Name.trim();
            }
            if (this.isRettificheDiVendita) {
              //b.AdjustmentType = 'Generic';
              b.AdjustmentType = this.currentFilter.SelectedWasteType;
            } else if (this.isProdottiFiniti) {
              b.AdjustmentType = 'SemiFinished';
            } else if (this.isInventario) {
              b.AdjustmentType = 'Inventory';
            }

            body.push(b);
          }
        });

        if (body.length > 0) {
          this.venditeManualiService.save(body).subscribe(
            (results: any) => {
              console.log('save', results, body);

              let message = this.isProdottiFiniti ? this.translate.instant('PRODOTTI_FINITI.SAVE_DIALOG.SUCCESS') : (this.isRettificheDiVendita ? this.translate.instant('RETTIFICHE_VENDITA.SAVE_DIALOG.SUCCESS') : this.translate.instant('VENDITE_MANUALI.SAVE_DIALOG.SUCCESS'));;
              this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
              this.clearFilters();
              this.loading$.next(false);
            }, (error: any) => {
              console.log('save ERROR=>', error, body);
              this.loading$.next(false);
            }
          );
        } else {
          this.loading$.next(false);
        }

      }
    });


  }

  showCompletedRow($event: any) {

    console.log('showCompletedRow', $event);

    if ($event.checked) {
      this.listaFiltered = this.listaFiltered.filter((item: any) => item.Quantity > 0);
      this.initDataTable();
    } else {
      this.listaFiltered = JSON.parse(JSON.stringify(this.list));
      this.initDataTable();
    }

  }

  updateList(item: any) {
    const index = this.list.findIndex((el: any) => el.Id === item.Id);
    this.list[index].Quantity = item.Quantity;
  }

  getImage() {
    return this.isProdottiFiniti ? 'ProdottiFiniti' : (this.isRettificheDiVendita ? 'RettificheDiVendita' : 'VenditeManuali');
  }

}
