import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';

// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { GestioneRicetteService } from '@app/core/services/gestione-ricette.service';
import { EditMerciComponent } from '@app/views/pages/gestione-dati/gestione-merci/edit-merci/edit-merci.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, debounceTime, of, startWith, switchMap } from 'rxjs';

declare var $: any;

@Component({
    selector: 'kt-cerca-merce',
    templateUrl: './cerca-merce.component.html',
    styleUrls: ['./cerca-merce.component.scss', '../../common-styles.scss']
})
export class CercaMerceComponent implements OnInit {

    //goods: any;
    goods: Observable<{Name: string}[]> = of([]);
    goodsSource: any;
    good: any;
    onlySearch: boolean = false;
    supplierId!: string;
    goodsFormControl = new FormControl();

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private gestioneMerciService: GestioneMerciService,
        private gestioneRicetteService: GestioneRicetteService,
        public dialog: MatDialog,
        private translate: TranslateService
    ) { }

    ngOnInit() {
        this.goodsSource = this.data.goods;
        this.supplierId = this.data.supplierId;
        this.good = {
            Id: this.data.goodId
        };
        this.onlySearch = this.data.onlySearch;
    }

    ngAfterViewInit() {
        this.goods = this.goodsFormControl.valueChanges
            .pipe(
                startWith(''),
                debounceTime(500),
                switchMap(value => this._filter(value))
            );
    }

    displayFn(product: any): string {
        if (!product) return '';
        return product.Name;
    }

    getTitle() {
        if(this.data.title) {
            return this.translate.instant('MAGAZZINO.ADD_NEW_PRODUCT');
        } else {
            return this.translate.instant('GESTIONE_RICETTE.EDIT.PARAMETERS.CERCA_MERCE');
        }
    }

    private _filter(value: any) {
        let filterValue = '';
        if (value.constructor === String) {
            filterValue = value.toLowerCase();
        } else if (value.constructor === Object) {
            filterValue = value.Name ? value.Name.toLowerCase() : '';
        }
        if (this.goodsSource) {
            return of(this.goodsSource.filter((item: any) => {
                return item.Name.toLowerCase().includes(filterValue);
            }));
        } else {
            return this.gestioneMerciService.getGoods({
                OnlyVisibleForCompany: true,
                Companies: [this.data.company],
                EnableState: 'yes',
                FBType: 6,
                Name: filterValue
            });
        }
    }

    forceSelectAll(event: any) {
        $(event.target).select();
    }

    selectedGood!: string;
    selectNewGood(event: any) {
        let item = event?.option?.value;
        this.selectedGood = item ? item.Id + '|' + item.Code : '';
        this.good = item;
    }

    onSubmit() {
        if (this.onlySearch) {
            this.dialogRef.close(this.selectedGood);
        } else {
            //this.gestioneMerciService.getSingleGoods(this.good.Id, this.supplierId).subscribe(
            //    (result: any) => {
                    const dialogRefEditMerci = this.dialog.open(EditMerciComponent, {
                        data: {
                            merce: this.good
                        },
                        width: '100%',
                        height: '100%'
                    });

                    dialogRefEditMerci.afterClosed().subscribe((res: any) => {
                        if (res) {
                            this.dialogRef.close(res);
                        } else {
                            this.dialogRef.close(false);
                        }
                    });

            //    }
            //);
        }
    }

}
