import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ConfigService } from '@app/core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { GestioneRicetteService } from '@app/core/services/gestione-ricette.service';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { SwitchGroupService } from '@app/core/_base/layout';
@Component({
    selector: 'kt-endpoint-settings',
    templateUrl: './endpoint-settings-component.html',
    styleUrls: ['./endpoint-settings-component.scss']
})
export class EndpointSettingsComponent implements OnInit {

    config = {
        OrderPrintType: 0,
        OrderSupplierType: 0,
        WasteMode: 1,
        ClosingTime: '03:00',
        UseSameCodeForAllCompanies: false,
        UseStoreAliasInDashboard: false,
        ImportCostFromAccess: true,
        ImportCostFromInvoices: false,
        ImportAutoGoods: false,
        DistributeCodeAndToken: false,
        DoNotImpotInvoicePurchase: false,
        DoNotImpotInvoiceCE: false,
        AutoCreatePurchaseOnDDT: false,
        AutoCreateCEOnPurchase: false,
        AutoCreateDDTOnPurchase: false,
        AutoUpdateGoodsPrices: false,
        SetOrderAsUsed: false,
        ShowWasteInOrder: false,
        ZucchettiHost: "",
        ZucchettiDatabase: "",
        ZucchettiDatabaseUser: "",
        ZucchettiDatabasePassword: "",
        ImportZSelling: true,
        ImportAutoCreateProduct: true,
        ImportCreateTransfert: false,
        ImportSendMail: false,
        ImportUseInnerCode: false,
        ImportUseTotalMenuPrice: false,
        ImportEdiInOrder: false,
        NotTraining: false,
        VersionToCenters: 0,
        DefaultPLStructId: undefined,
        ProductIdForCovers: undefined,
        ImportCovers: false,
        ProductIdForReceipt: undefined,
        ImportReceipt: false,
    };
    tryedConnection: boolean = false;
    connectionSuccess: boolean = false;
    tryingConnection: boolean = false;
    products: any;
    plList: any = [];

    hidePwd = true;
    constructor(private srv: ConfigService,
        private translate: TranslateService,
        private translateSrv: TranslationService,
        private ref: ChangeDetectorRef,
        private gestioneRicetteService: GestioneRicetteService,
        private anagraficheService: AnagraficheService,
        private layoutUtilsService: LayoutUtilsService,
        private switchGroupService: SwitchGroupService
    ) { }

    async ngOnInit() {
        const plStructure = await this.anagraficheService.getPLStructure().toPromise();
        this.getFlatList('', plStructure);
        this.loadConfigurations();
    }

    checkIntegration(integration) {
        const currentGroup: any = this.switchGroupService.getCurrentGroup();
        return currentGroup.integrations.findIndex(i => i.Integration === integration && i.IsAvailable) > -1;
    }

    getFlatList(parentName, children) {
        children.forEach((element: any) => {
            element.Name = parentName + '/' + element.Name;
            if (element.Children.length > 0) {
                this.getFlatList(element.Name, element.Children);
            }
            if (element.IsLeaf) {
                this.plList.push(element);
            }

        });
    }

    async loadConfigurations() {

        const rcps = await this.gestioneRicetteService.getRecipeList({
            EnableState: 2,
            VisibleState: 1
        }, {
            page: 1,
            pageSize: 1000000,
            sort: 'Product',
            sortMode: 'Asc',
            TotalRows: 0
        }).toPromise();

        this.products = rcps.Rows;


        this.srv.getConfigurations().subscribe(
            r => {
                this.config.OrderPrintType = r.OrderPrintType;
                this.config.OrderSupplierType = r.OrderSupplierType;
                this.config.ClosingTime = r.ClosingTime || this.config.ClosingTime;
                this.config.WasteMode = r.WasteMode;
                this.config.UseSameCodeForAllCompanies = r.UseSameCodeForAllCompanies;
                this.config.UseStoreAliasInDashboard = r.UseStoreAliasInDashboard;
                this.config.ShowWasteInOrder = r.ShowWasteInOrder;

                this.config.ImportCostFromAccess = r.ImportCostFromAccess;
                this.config.ImportCostFromInvoices = r.ImportCostFromInvoices;
                this.config.ImportAutoGoods = r.ImportAutoGoods;
                this.config.DistributeCodeAndToken = r.DistributeCodeAndToken;
                this.config.DoNotImpotInvoiceCE = r.DoNotImpotInvoiceCE;
                this.config.DoNotImpotInvoicePurchase = r.DoNotImpotInvoicePurchase;

                this.config.AutoCreatePurchaseOnDDT = r.AutoCreatePurchaseOnDDT;
                this.config.AutoCreateCEOnPurchase = r.AutoCreateCEOnPurchase;
                this.config.AutoCreateDDTOnPurchase = r.AutoCreateDDTOnPurchase;
                this.config.AutoUpdateGoodsPrices = r.AutoUpdateGoodsPrices;
                this.config.SetOrderAsUsed = r.SetOrderAsUsed;


                this.config.ZucchettiHost = r.ZucchettiHost;
                this.config.ZucchettiDatabase = r.ZucchettiDatabase;
                this.config.ZucchettiDatabaseUser = r.ZucchettiDatabaseUser;
                this.config.ZucchettiDatabasePassword = r.ZucchettiDatabasePassword;

                this.config.ImportZSelling = r.ImportZSelling;
                this.config.ImportAutoCreateProduct = r.ImportAutoCreateProduct;


                this.config.ImportCreateTransfert = r.ImportCreateTransfert;
                this.config.ImportSendMail = r.ImportSendMail;
                this.config.ImportUseInnerCode = r.ImportUseInnerCode;
                this.config.ImportUseTotalMenuPrice = r.ImportUseTotalMenuPrice;
                this.config.ImportEdiInOrder = r.ImportEdiInOrder;
                this.config.NotTraining = r.NotTraining;

                this.config.VersionToCenters = r.VersionToCenters;
                this.config.DefaultPLStructId = r.DefaultPLStructId;

                this.config.ProductIdForReceipt = r.ProductIdForReceipt;
                this.config.ProductIdForCovers = r.ProductIdForCovers;
                this.config.ImportReceipt = r.ImportReceipt;
                this.config.ImportCovers = r.ImportCovers;

                this.ref.detectChanges();
            }
        );
    }

    testConnection() {
        this.tryedConnection = false;
        this.tryingConnection = true;
        this.srv.testConnection(this.config.ZucchettiHost,
            this.config.ZucchettiDatabase,
            this.config.ZucchettiDatabaseUser,
            this.config.ZucchettiDatabasePassword
        ).toPromise().then(
            r => {

                this.connectionSuccess = true;

            }).catch(
                fail => {

                    this.connectionSuccess = false;
                }).finally(
                    () => {
                        this.tryedConnection = true;
                        this.tryingConnection = false;
                        this.ref.detectChanges()

                    }
                )
    }

    onChange(sender, e) {
        switch (sender) {
            case 'ImportCostFromAccess':
                this.config.ImportCostFromInvoices = !e;
                break;
            case 'ImportCostFromInvoices':
                this.config.ImportCostFromAccess = !e;
                break;
        }
    }

    save() {
        this.srv.postConfigurations(this.config).subscribe(
            r => {

                let message = this.translate.instant('GLOBAL_CONFIG.SaveMsg');
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

            }
        );
    }

}
