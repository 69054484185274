import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, timer, Observable, Subject, Subscription, fromEvent, lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '@app/core/services/excel.service';
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { ManutenzioneService } from '@app/views/pages/manutenzione/manutenzione.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { MbSelect2Component } from '@app/mb-components/mb-select2/mb-select2.component';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import { GestioneRicetteService } from '@app/core/services/gestione-ricette.service';
import * as _ from 'lodash';
import * as util from '@app/core/services/utilityfunctions';
import { SwitchGroupService } from '@app/core/_base/layout';

@Component({
    // tslint:disable-next-line: indent
    selector: 'kt-tasks',
    // tslint:disable-next-line: indent
    templateUrl: './tasks.component.html',
    styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit, OnDestroy {
    dateStart: any;
    dateEnd: any;

    importProgress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    // tslint:disable-next-line: indent

    // tslint:disable-next-line: indent
    select2AllAziende: boolean = false;
    utility: any;
    companies = [];
    products = [];

    currentView: string = 'RicalcolaConsumi';

    constructor(
        public staticCollections: StaticCollectionsService,
        private manutenzioneService: ManutenzioneService,
        private translate: TranslateService,
        private excelService: ExcelService,
        private ref: ChangeDetectorRef,
        private layoutUtilsService: LayoutUtilsService,
        private gestioneRicetteService: GestioneRicetteService,
        public roleService: RoleService,
        private cdr: ChangeDetectorRef,
        private switchGroupService: SwitchGroupService
    ) {
        this.utility = util;
    }

    ngOnInit(): void {
        this.staticCollections.fillStaticCollection(true);
    }

    checkIntegration(integration) {
        const currentGroup: any = this.switchGroupService.getCurrentGroup();
        return currentGroup.integrations.findIndex(i => i.Integration === integration && i.IsAvailable) > -1;
    }

    getCompanies() {
        if (this.companies && this.companies.length > 0) {
            let list: any = [];
            this.companies.forEach((element: any) => {
                list.push(element.Name);
            });
            return list;
        }
        else return null;
    }

    recalculateFC() {
        const _title: string = this.translate.instant('GESTIONE_RICETTE.CALCFC_DIALOG.TITLE');
        const _description: string = this.translate.instant('GESTIONE_RICETTE.CALCFC_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('GESTIONE_RICETTE.CALCFC_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            if (confirm) {
                this.gestioneRicetteService.recalculateFC().subscribe(
                    (result: any) => {


                        let message = this.translate.instant('GESTIONE_RICETTE.CALCFC_DIALOG.SUCCESS');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');


                    },
                    (err: any) => {
                        let message = this.translate.instant('GESTIONE_RICETTE.CALCFC_DIALOG.ERROR');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                    }
                )
            }
        });
    }

    assignFC() {
        const title: string = this.translate.instant('MANUTENZIONE.TASKS.AssignFC_MSG.TITLE');
        const description: string = this.translate.instant('MANUTENZIONE.TASKS.AssignFC_MSG.DESCRIPTION');
        const waitDesciption: string = this.translate.instant('MANUTENZIONE.TASKS.AssignFC_MSG.WAIT_DESCRIPTION');
        const yes: string = this.translate.instant('COMMONS.OK');
        const cancel: string = this.translate.instant('COMMONS.CANCEL');
        const deleteMessage = this.translate.instant('MANUTENZIONE.TASKS.AssignFC_MSG.MESSAGE');

        const dialogRef = this.layoutUtilsService.simpleElement(title, description, waitDesciption, yes, cancel);
        dialogRef.afterClosed().subscribe((res: any) => {
            if (!res) {
                return;
            }
            this.loading$.next(true);
            var cms = this.getCompanies();
            var obj = this.manutenzioneService.assignFCn(this.dateStart, this.dateEnd, cms, this.products)

            obj.subscribe(
                (result: any) => {
                    this.loading$.next(false);
                    this.layoutUtilsService.showActionNotification(deleteMessage, MessageType.Update);
                },
                (error) => {
                    this.loading$.next(false);
                    this.layoutUtilsService.showActionNotification("", MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');

                });
        });
    }

    compareTCPOS() {
        this.loading$.next(true);
        // var cms=this.getCompanies();
        var obj = this.manutenzioneService.compareTCPOS(this.dateStart, this.dateEnd, this.companies);

        obj.subscribe(
            data => {
                this.loading$.next(false);
                this.downloadFile(data);

            },
            (err: any) => {
                this.loading$.next(false);
                this.layoutUtilsService.showActionNotification("", MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
            }
        );

    }

    downloadFile(data) {
        //const blob = new Blob(data.body, { type: 'application/octet-stream' });
        //window.open(url);

        var link = document.createElement('a');
        const url = window.URL.createObjectURL(data.body);
        link.href = url;

        link.download = "Results.xlsx";
        link.click();

    }

    // tslint:disable-next-line: indent
    calcConsumption() {

        const title: string = this.translate.instant('MANUTENZIONE.TASKS.RicalcolaConsumi_MSG.TITLE');
        const description: string = this.translate.instant('MANUTENZIONE.TASKS.RicalcolaConsumi_MSG.DESCRIPTION');
        const waitDesciption: string = this.translate.instant('MANUTENZIONE.TASKS.RicalcolaConsumi_MSG.WAIT_DESCRIPTION');
        const yes: string = this.translate.instant('COMMONS.OK');
        const cancel: string = this.translate.instant('COMMONS.CANCEL');
        const deleteMessage = this.translate.instant('MANUTENZIONE.TASKS.RicalcolaConsumi_MSG.MESSAGE');
        const errorMessage = this.translate.instant('MANUTENZIONE.TASKS.RicalcolaConsumi_MSG.ERR_MESSAGE');

        const dialogRef = this.layoutUtilsService.simpleElement(title, description, waitDesciption, yes, cancel);
        dialogRef.afterClosed().subscribe((res: any) => {
            if (!res) {
                return;
            }
            this.loading$.next(true);
            var cms = this.getCompanies();
            var obj = this.manutenzioneService.callConsumption(this.dateStart, this.dateEnd, cms, this.products)

            obj.subscribe(
                (result: any) => {
                    this.loading$.next(false);
                    this.layoutUtilsService.showActionNotification(deleteMessage, MessageType.Update);

                    this.startMonitoringStatus('RecalculateAllConsumptions');
                },
                (error) => {
                    this.loading$.next(false);
                    this.layoutUtilsService.showActionNotification(errorMessage, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');

                });
        });

    }

    recalculating: boolean = false;
    recalculatingPercentage: number = 0;

    private monitoringInterval: any;
    startMonitoringStatus(type: string): void {
        this.recalculating = true;
        this.monitoringInterval = setInterval(() => {
            lastValueFrom(this.manutenzioneService.getImportStatus(type))
                .then((result: any) => {
                    this.recalculatingPercentage = result.Progress;
                    this.cdr.detectChanges();
                    if (result.Progress === 100) {
                        this.stopMonitoringStatus();
                    }
                })
                .catch((error) => {
                    console.error('Error fetching status:', error);
                    this.stopMonitoringStatus(); // Optionally stop monitoring on error
                });
        }, 100);
    }

    stopMonitoringStatus(): void {
        if (this.monitoringInterval) {
            clearInterval(this.monitoringInterval);
            this.monitoringInterval = null;
        }
        this.recalculating = false;
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.stopMonitoringStatus();
    }

    deleteConsumption() {
        alert("TODO");
    }

    calcStock() {
        const title: string = this.translate.instant('MANUTENZIONE.TASKS.RicalcolaGiacenze_MSG.TITLE');
        const description: string = this.translate.instant('MANUTENZIONE.TASKS.RicalcolaGiacenze_MSG.DESCRIPTION');
        const waitDesciption: string = this.translate.instant('MANUTENZIONE.TASKS.RicalcolaGiacenze_MSG.WAIT_DESCRIPTION');
        const yes: string = this.translate.instant('COMMONS.OK');
        const cancel: string = this.translate.instant('COMMONS.CANCEL');
        const deleteMessage = this.translate.instant('MANUTENZIONE.TASKS.RicalcolaGiacenze_MSG.MESSAGE');

        const dialogRef = this.layoutUtilsService.simpleElement(title, description, waitDesciption, yes, cancel);
        dialogRef.afterClosed().subscribe((res: any) => {
            if (!res) {
                return;
            }
            this.loading$.next(true);
            var cms = this.getCompanies();
            var obj = this.manutenzioneService.callStock(this.dateStart, this.dateEnd, cms)

            obj.subscribe(
                (result: any) => {
                    this.loading$.next(false);
                    this.layoutUtilsService.showActionNotification(deleteMessage, MessageType.Update);
                    this.startMonitoringStatus('RecalculateStock');
                },
                (error) => {
                    this.loading$.next(false);
                    this.layoutUtilsService.showActionNotification("", MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                });
        });


    }

    deleteStock() {
        alert("TODO");
    }

    deleteInventory() {
        alert("TODO");
    }

    deleteSellings() {
        alert("TODO");
    }

    checkDisabled() {
        let disabled = false;

        switch (this.currentView) {
            case 'Compare_TCPOS':
            case 'AssignFC':
            case 'RicalcolaConsumi':
                disabled = !this.dateStart || !this.dateEnd || !this.companies
                break;
        }

        return disabled;
    }

    performAction() {

        switch (this.currentView) {
            case 'RicalcolaConsumi':
                this.calcConsumption();
                break;
            case 'RicalcolaGiacenze':
                this.calcStock();
                break;
            case 'REC_FC':
                this.recalculateFC();
                break;
            case 'Compare_TCPOS':
                this.compareTCPOS();
                break;
            case 'AssignFC':
                this.assignFC();
                break;
        }

    }

    clearAction() {
        switch (this.currentView) {
            case 'RicalcolaConsumi':
                this.unlockImporting('RecalculateAllConsumptions');
                break;
            case 'RicalcolaGiacenze':
                this.unlockImporting('RecalculateStock');
                break;
            case 'REC_FC':
                break;
            case 'Compare_TCPOS':
                break;
            case 'AssignFC':
                break;
        }
    }

    unlockImporting(type: string) {

        const title: string = this.translate.instant('MANUTENZIONE.TASKS.StopRicalcola_MSG.TITLE');
        const description: string = this.translate.instant('MANUTENZIONE.TASKS.StopRicalcola_MSG.DESCRIPTION');
        const waitDesciption: string = this.translate.instant('MANUTENZIONE.TASKS.StopRicalcola_MSG.WAIT_DESCRIPTION');
        const yes: string = this.translate.instant('COMMONS.OK');
        const cancel: string = this.translate.instant('COMMONS.CANCEL');
        const deleteMessage = this.translate.instant('MANUTENZIONE.TASKS.StopRicalcola_MSG.MESSAGE');
        const errorMessage = this.translate.instant('MANUTENZIONE.TASKS.StopRicalcola_MSG.ERR_MESSAGE');

        const dialogRef = this.layoutUtilsService.simpleElement(title, description, waitDesciption, yes, cancel);
        dialogRef.afterClosed().subscribe((res: any) => {
            if (!res) {
                return;
            }
            this.loading$.next(true);
            lastValueFrom(this.manutenzioneService.unlockImporting(type))
                .then((result: any) => {
                    this.layoutUtilsService.showActionNotification(deleteMessage, MessageType.Update);                    
                })
                .catch((error) => {
                    this.layoutUtilsService.showActionNotification(errorMessage, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                })
                .finally(() => {
                    this.loading$.next(false);
                    this.stopMonitoringStatus();
                });


        });

    }

}
