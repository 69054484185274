// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login } from '../../../../core/auth';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { environment } from '@env/environment';

/**
 * ! Just example => Should be removed in development
 */



@Component({
    selector: 'kt-login',
    templateUrl: './login.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
    // Public params
    loginForm!: FormGroup;
    loading = false;
    isLoggedIn$!: Observable<boolean>;
    errors: any = [];
    hide = true;

    private unsubscribe: Subject<any>;

    private returnUrl: any;

    // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    /**
     * Component constructor
     *
     * @param router: Router
     * @param auth: AuthService
     * @param authNoticeService: AuthNoticeService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     * @param fb: FormBuilder
     * @param cdr
     * @param route
     */
    constructor(
        private router: Router,
        private auth: AuthService,
        private authNoticeService: AuthNoticeService,
        private translate: TranslateService,
        private store: Store<AppState>,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private switchGroup: SwitchGroupService
    ) {
        this.unsubscribe = new Subject();
    }

    /**
     * @ Lifecycle sequence(s: any) => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        this.initLoginForm();

        // redirect back to the returnUrl before login
        this.route.queryParams.subscribe((params: any) => {
            this.returnUrl = params['returnUrl'] || '/';
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.authNoticeService.setNotice('');
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
        this.loading = false;
    }

    isMac() {
        return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    }

    /**
     * Form initalization
     * Default params, validators
     */
    initLoginForm() {
        // demo message to show
        // if (!this.authNoticeService.onNoticeChanged$.getValue()) {
        // 	const initialNotice = `Use account
        // 	<strong>${DEMO_PARAMS.EMAIL}</strong> and password
        // 	<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
        // 	//this.authNoticeService.setNotice(initialNotice, 'info');
        // }

        const email = environment.production ? '' : 'admin@fbmanager.com';
        const password = environment.production ? '' : '@AdRstSe1964!';

        this.loginForm = this.fb.group({
            email: [email, Validators.compose([
                Validators.required,
                Validators.email,
                Validators.minLength(3),
                Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
            ])
            ],
            password: [password, Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ])
            ]
        });
    }

    /**
     * Form Submit
     */
    submit() {
        const controls = this.loginForm.controls;
        /** check form */
        if (this.loginForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return;
        }

        this.loading = true;

        const authData = {
            email: controls['email'].value,
            password: controls['password'].value
        };
        this.auth
            .login(authData.email, authData.password)
            .pipe(
                tap(user => {
                    console.log('user', user);
                    if (user) {
                        if (user.Logins.length > 0 && user.Logins.filter((x: any) => x.access_token).length > 0) {
                            user.Logins = user.Logins.filter((x: any) => x.access_token);

                            if (environment.UseTestEndpoint) {
                                //USA SOLO DATABASE DI TEST 
                                user.Logins = user.Logins.filter((x: any) => x.endpointURL === environment.TestEndpoint)
                            }
                            else {
                                if (!user.Logins[0].Roles.includes('SuperAdmin')) {
                                    if (!(user.Logins.length === 1 && user.Logins[0].endpointURL !== environment.TestEndpoint)) {
                                        //NON VEDE DATABASE DI TEST
                                        user.Logins = user.Logins.filter((x: any) => x.endpointURL !== environment.TestEndpoint)
                                    }
                                }
                            }
                            localStorage.setItem('currentUser', JSON.stringify(user));

                            if (user.Logins.length > 0) {
                                this.switchGroup.setFirstAvailableGroup();
                            } else {
                                this.switchGroup.setCurrentGroup(0);
                            }


                            this.store.dispatch(new Login({ authToken: user.email }));

                            // if (user.Logins[0] && user.Logins[0].Roles.includes('User')) {
                            // 	this.router.navigateByUrl('/magazzino/ordini'); // Main page
                            // } else {
                            // 	this.router.navigateByUrl(this.returnUrl); // Main page
                            // }

                            this.router.navigateByUrl(this.returnUrl); // Main page

                        } else {
                            this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
                        }
                    } else {
                        this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
                    }
                }),
                takeUntil(this.unsubscribe),
                finalize(() => {
                    this.loading = false;
                    this.cdr.markForCheck();
                })
            )
            .subscribe(null, (error: any) => {
                // console.log(error);
                this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
            });
    }

    /**
     * Checking control validation
     *
     * @param controlName: string => Equals to formControlName
     * @param validationType: string => Equals to valitors name
     */
    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.loginForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }
}
