// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
    ContentAnimateDirective,
    FirstLetterPipe,
    GetObjectPipe,
    HeaderDirective,
    JoinPipe,
    MenuDirective,
    OffcanvasDirective,
    SafePipe,
    ScrollTopDirective,
    SparklineChartDirective,
    StickyDirective,
    TabClickEventDirective,
    TimeElapsedPipe,
    ToggleDirective
} from './_base/layout';

import { DashboardService } from '@app/core/services/dashboard.service';
//import { FiltriService } from '@app/core/services/filtri.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { AziendeService } from '@app/core/services/aziende.service';
import { CategorieProdottiService } from '@app/core/services/categorie-prodotti.service';
import { ProdottiService } from '@app/core/services/prodotti.service';
import { CostCenterService } from '@app/core/services/cost-center.service';
import { AnalisiVenditeService } from '@app/core/services/analisi-vendite.service';
import { AnalisiFoodCostService } from '@app/core/services/analisi-foodcost.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { GestioneRicetteService } from '@app/core/services/gestione-ricette.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { EndpointsService } from '@app/core/services/endpoints.service';
import { LocalizedDateTimePipe } from './pipes/localized-date-time.pipe';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { OnlyIntegerDirective } from './directives/only-integer.directive';
import { FormatCurrencyDirective } from './directives/format-currency.directive';
import { DndDirective } from './directives/dnd.directive';
import { BulkActionsService } from '@app/core/services/bulk-actions.service';
import { FormatNumberDecimalDirective } from './directives/format-number-decimal.directive';
import { SelectOnClickDirective } from './directives/select-on-click.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [
        // directives
        ScrollTopDirective,
        HeaderDirective,
        OffcanvasDirective,
        ToggleDirective,
        MenuDirective,
        TabClickEventDirective,
        SparklineChartDirective,
        ContentAnimateDirective,
        StickyDirective,
        // pipes
        TimeElapsedPipe,
        JoinPipe,
        GetObjectPipe,
        SafePipe,
        FirstLetterPipe,
        LocalizedDateTimePipe,
        OnlyNumberDirective,
        OnlyIntegerDirective,
        FormatCurrencyDirective,
        DndDirective,
        FormatNumberDecimalDirective,
        SelectOnClickDirective
    ],
    exports: [
        // directives
        ScrollTopDirective,
        HeaderDirective,
        OffcanvasDirective,
        ToggleDirective,
        MenuDirective,
        TabClickEventDirective,
        SparklineChartDirective,
        ContentAnimateDirective,
        StickyDirective,
        // pipes
        TimeElapsedPipe,
        JoinPipe,
        GetObjectPipe,
        SafePipe,
        FirstLetterPipe,
        LocalizedDateTimePipe,
        OnlyNumberDirective,
        OnlyIntegerDirective,
        FormatCurrencyDirective,
        DndDirective,
        FormatNumberDecimalDirective,
        SelectOnClickDirective
    ],
    providers: [
        DashboardService,
        //FiltriService,
        StaticCollectionsService,
        AziendeService,
        CategorieProdottiService,
        ProdottiService,
        CostCenterService,
        AnalisiVenditeService,
        AnalisiFoodCostService,
        AnagraficheService,
        ExcelService,
        PDFService,
        EndpointsService,
        MagazzinoService,
        GestioneRicetteService,
        GestioneMerciService,
        BulkActionsService
    ]
})
export class CoreModule {
}
