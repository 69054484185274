import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { LayoutConfigService, SwitchGroupService } from '@app/core/_base/layout';
import * as util from '@app/core/services/utilityfunctions';
import { ColumnsSelectionPopupComponent } from '@app/views/partials/content/crud/columns-selection-popup/columns-selection-popup.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { PDFService } from '@app/core/services/pdf.service';
import { AnalisiConsumiService } from '@app/views/pages/analisi/analisi-consumi/analisi-consumi.service';
import { PopupDettaglioComponent } from '../consumi/popup-dettaglio/popup-dettaglio.component';
import { Router } from '@angular/router';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { EditMerciComponent } from '@app/views/pages/gestione-dati/gestione-merci/edit-merci/edit-merci.component';

declare var $: any;

@Component({
    selector: 'kt-consumi-acquisti',
    templateUrl: './consumi-acquisti.component.html',
    styleUrls: ['./consumi-acquisti.component.scss']
})
export class ConsumiAcquistiComponent implements OnInit {
    currentFilterCfg: any;
    @Input() bestItems!: number;
    @Input() showBase: boolean = false;
    @Output() onShowHoverglass = new EventEmitter();
    @Output() onLoading = new EventEmitter();

    initComplete: boolean = false;
    PDFPreferences: any;

    utility: any;
    locale!: string;
    table: any;
    columnsList: any;

    pagination: any = {
        TotalRows: 0,
        pageSize: 10,
        pageIndex: 0
    }

    constructor(
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private dialog: MatDialog,
        private pdfService: PDFService,
        private consumiSrv: AnalisiConsumiService,
        private router: Router,
        private gestioneMerciService: GestioneMerciService,
        private switchGroupService: SwitchGroupService
    ) {
        this.utility = util;
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });

        this.columnsList = [
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_ACQUISTI.COLUMNS.CODICE'), enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_ACQUISTI.COLUMNS.PRODOTTO'), enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_ACQUISTI.COLUMNS.CONSUMI_DA_RICETTE'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_ACQUISTI.COLUMNS.UNITA'), enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_ACQUISTI.COLUMNS.INVENTARIO_INIZIALE'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_ACQUISTI.COLUMNS.DATA_INV_INIZIALE'), enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_ACQUISTI.COLUMNS.ACQUISTI_EDI'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_ACQUISTI.COLUMNS.INVENTARIO_FINALE'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_ACQUISTI.COLUMNS.DATA_INV_FINALE'), enabled: true, style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_ACQUISTI.COLUMNS.CFT'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_ACQUISTI.COLUMNS.DRQ'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true },
            { label: this.translate.instant('ANALISI_CONSUMI.CONSUMI_ACQUISTI.COLUMNS.DRP'), format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 }, enabled: true },
        ];
    }

    ngOnInit() { }

    initDataTable() {
        this.onShowHoverglass.emit(true);
        if ($.fn.dataTable.isDataTable('#table')) {
            $('#table').DataTable().destroy();
        }
        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();
            let dtOptions: any = {
                initComplete: () => {
                    this.onShowHoverglass.emit(false);
                    this.initComplete = true;
                    this.ref.detectChanges();
                },
                processing: true,
                serverSide: true,
                ajax: {
                    url: currentLogin.endpointURL + "/api/Consumption/PurchasingVariance",
                    type: "POST",
                    data: (d: any) => {
                        d.filters = this.currentFilterCfg;
                        d.filters['OnlyEDIPurchase'] = true;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        this.pagination.TotalRows = response.recordsFiltered;
                        this.ref.detectChanges();
                        return response.data;
                    }
                },
                destroy: true,
                dom: 'rt<"paginator">',
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: true,
                searching: true,
                ordering: true,
                orderCellsTop: true,
                scrollY: 'calc(100vh - 380px)',
                scrollCollapse: true,
                columnDefs: [
                    {
                        targets: 0, data: 'Code', name: 'Code', render: (data, type, p, meta) => {
                            return `<div class="overflow-ellipses exportText" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }, width: '50px', visible: this.columnsList[0].enabled, className: 'max-width-100'
                    },
                    {
                        targets: 1, data: 'Goods', name: 'Goods', render: (data, type, p, meta) => {
                            return `<div class="display-flex-left"><a href="javascript:;" data-id="${data.Id}" name="openMerce"><i class="fas fa-carrot"></i></a>&nbsp;
                            <div class="overflow-ellipses"><a href="javascript:;" name="openPopup" class="exportText" data-toggle="tooltip" title="${p.Goods?.Name}">${p.Goods?.Name}</a></div></div>`;
                        }, className: 'linktoAction max-width-250', visible: this.columnsList[1].enabled
                    },
                    {
                        targets: 2, data: 'BaseConsumption', name: 'BaseConsumption', render: (data, type, p, meta) => {
                            return `${this.utility.formatNumber(this.whatColumn(p, 'Consumption'), this.locale, 2, true)}`;
                        }, width: '80px', className: 'linktoAction text-right numeric', orderable: true, visible: this.columnsList[2].enabled
                    },
                    {
                        targets: 3, data: 'Unit', name: 'Unit', render: (data, type, p, meta) => {
                            return this.whatColumn(p, 'Unit');
                        }, width: '80px', className: 'linktoAction', orderable: true, visible: this.columnsList[3].enabled
                    },
                    {
                        targets: 4, data: 'PrevInventory.Quantity', name: 'PrevInventory.Quantity', render: (data, type, p, meta) => {
                            return `<span class='exportText'>${this.utility.formatNumber(this.whatColumn(p, 'PrevInventory.Quantity'), this.locale, 2, true)}</span>&nbsp;<a href="javascript:;" name="goToInventario"><i class="fas fa-share-square"></i></a>`;
                        }, width: '80px', className: 'linktoAction numeric text-right', orderable: true, visible: this.columnsList[4].enabled
                    },
                    {
                        targets: 5, data: 'PrevInventory.Date', name: 'PrevInventory.Date', render: (data, type, p, meta) => {
                            return this.utility.getLocaleData(p.PrevInventory.Date, this.locale, 2, true);
                        }, width: '80px', className: 'linktoAction', orderable: true, visible: this.columnsList[5].enabled
                    },
                    {
                        targets: 6, data: 'Purchase', name: 'Purchase', render: (data, type, p, meta) => {
                            const format = this.utility.formatNumber(this.whatColumn(p, 'Purchase'), this.locale, 2, true);
                            return `<span class="exportText">${format}</span>&nbsp;<a href="javascript:;" name="goToAcquisti"><i class="fas fa-share-square"></i></a>`;
                        }, width: '80px', className: 'linktoAction numeric text-right', orderable: true, visible: this.columnsList[6].enabled
                    },
                    {
                        targets: 7, data: 'Inventory.Quantity', name: 'Inventory.Quantity', render: (data, type, p, meta) => {
                            return `<span class='exportText'>${this.utility.formatNumber(this.whatColumn(p, 'Inventory.Quantity'), this.locale, 2, true)}</span>&nbsp;<a href="javascript:;" name="goToInventario"><i class="fas fa-share-square"></i></a>`;
                        }, width: '80px', className: 'linktoAction numeric text-right', orderable: true, visible: this.columnsList[7].enabled
                    },
                    {
                        targets: 8, data: 'Inventory.Date', name: 'Inventory.Date', render: (data, type, p, meta) => {
                            return this.utility.getLocaleData(p.Inventory.Date, this.locale, 2, true);
                        }, width: '80px', className: 'linktoAction', orderable: true, visible: this.columnsList[8].enabled
                    },
                    {
                        targets: 9, data: 'TheoreticalNeeds', name: 'TheoreticalNeeds', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(this.whatColumn(p, 'TheoreticalNeeds'), this.locale, 2, true);
                        }, width: '80px', className: 'linktoAction text-right numeric', orderable: true, visible: this.columnsList[9].enabled
                    },
                    {
                        targets: 10, data: 'PurchaseDifference', name: 'PurchaseDifference', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(this.whatColumn(p, 'PurchaseDifference'), this.locale, 2, true);
                        }, width: '80px', className: 'linktoAction text-right numeric', orderable: true, visible: this.columnsList[10].enabled
                    },
                    {
                        targets: 11, data: 'PurchaseDifferencePercentage', name: 'PurchaseDifferencePercentage', render: (data, type, p, meta) => {
                            return this.utility.formatNumber(this.whatColumn(p, 'PurchaseDifferencePercentage'), this.locale, 2, true);
                        }, width: '80px', className: 'linktoAction text-right numeric', orderable: true, visible: this.columnsList[11].enabled
                    }
                ],
                aaSorting: [],
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        //text: '<i class="fa fa-file-pdf text-danger"></i>',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.excludeFromExport)',
                            //format: {
                            //    body: function (data, row, column, node) {
                            //        if (column == 4) {
                            //            data = data.replace(/<i class="fas fa-coins" style="padding-left:10px;" title="Credit"><\/i>/g, '');
                            //        }
                            //        if (column === 7) {
                            //            data = data.replace(/<br>/g, '\n');
                            //        }

                            //        if (data.indexOf('exportableTooltip') >= 0) {
                            //            var parser = new DOMParser();
                            //            var html = parser.parseFromString(data, 'text/html');
                            //            let html$ = $(html);
                            //            data = html$.find(`.exportableTooltip`).attr("title");
                            //        }

                            //        return data;
                            //    }
                            //}
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            const columns = doc.content[1].table.body.length > 0 ? doc.content[1].table.body[0].length : 0;

                            const arrayCols = Array(columns).fill('auto');
                            arrayCols[2] = '*'; // La colonan prodotto la metto espandibile al massimo

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, arrayCols, '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
                            ////doc.defaultStyle = docDefinition.defaultStyle;
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;
                            doc.styles = docDefinition.styles;

                            // Imposto gli allineamenti
                            var rowCount = doc.content[1].table.body.length;
                            for (let i = 0; i < rowCount; i++) {
                                doc.content[1].table.body[i].forEach((col: any, index: number) => {
                                        const column = this.table.column(index);
                                        if (column.header().className.includes('text-right')) {
                                            doc.content[1].table.body[i][index].alignment = 'right';
                                        }
                                });
                            }
                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.pdfHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        //autoFilter: true,
                        //sheetName: 'Exported data',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.excludeFromExport)',
                            format: {
                                body: (data, row, column, node) => {

                                    if (data.indexOf('exportText') >= 0) {
                                        var parser = new DOMParser();
                                        var htmlDoc = parser.parseFromString(data, 'text/html');
                                        data = $(htmlDoc).find('.exportText').text();
                                    }

                                    if (node.className.includes('numeric')) {
                                        return this.utility.parseNumber(data, this.locale);
                                    } else {
                                        return '\0' + data
                                    }
                                }
                            },
                            orthogonal: 'export'
                        },
                        customize: async (xlsx: any) => {
                            let sheet = xlsx.xl.worksheets['sheet1.xml'];

                            const visibleColumns = this.table.columns().visible().toArray();

                            const numToAlpha = (num: number) => {
                                var alpha = '';
                                for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
                                    alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
                                }
                                return alpha;
                            }

                            visibleColumns.forEach((visible: boolean, index: number) => {
                                if (visible) {
                                    const column = this.table.column(index);
                                    if (column.header().className.includes('numeric')) {
                                        const alpha = numToAlpha(index);
                                        $(`row c[r^="${alpha}"]`, sheet).attr('s', '64');
                                    }
                                }
                            });

                        },

                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.excelHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    }
                ]
            };

            this.table = $('#table').DataTable(dtOptions);

            $('tbody').off('click');

            $('tbody').on('click', '.linktoAction', async (event: any) => {
                const tr = $(event.target).closest('tr');
                const a = $(event.target).closest('a');
                const data = this.table.row(tr.index()).data();

                switch (a.attr('name')) {
                    case 'openMerce':
                        this.openMerce(data);
                        break;
                    case 'openPopup':
                        this.openPopup(data);
                        break;
                    case 'goToInventario':
                        this.goToInventario(data);
                        break;
                    case 'goToAcquisti':
                        this.goToAcquisti(data);
                        break;
                    case 'goToSprechi':
                        this.goToSprechi(data);
                        break;

                }

            });
        }, 100);
    }

    changePagination(event: any) {
        if (this.pagination.pageSize != event.pageSize) {
            this.pagination.pageSize = event.pageSize;
            this.pagination.pageIndex = 0;
            this.table.page.len(this.pagination.pageSize).draw();
            this.table.page(0).draw('page');
        } else if (this.pagination.pageIndex != event.pageIndex) {
            this.pagination.pageIndex = event.pageIndex;
            this.table.page(this.pagination.pageIndex).draw('page');
        }
    }

    whatColumn(item: any, column: string) {
        if (column == 'Consumption') {
            return this.showBase ? item['BaseConsumption'] : item['WarehouseConsumption'];
        }
        if (column == 'Unit') {
            return this.showBase ? item['Units']['BaseUnit'] : item['Units']['WarehouseUnit'];
        }
        if (column == 'Adjustments') {
            return this.showBase ? item['AdjustmentsBase'] : item['Adjustments'];
        }
        if (column == 'Available') {
            return this.showBase ? item['AvailableBase'] : item['Available'];
        }
        if (column == 'Final') {
            return this.showBase ? item['FinalBase'] : item['Final'];
        }
        if (column == 'Inventory.Quantity') {
            return this.showBase ? item['Inventory']['QuantityBase'] : item['Inventory']['Quantity'];
        }
        if (column == 'PrevInventory.Quantity') {
            return this.showBase ? item['PrevInventory']['QuantityBase'] : item['PrevInventory']['Quantity'];
        }
        if (column == 'Price') {
            return this.showBase ? item['PriceBase'] : item['Price'];
        }
        if (column == 'Purchase') {
            return this.showBase ? item['PurchaseBase'] : item['Purchase'];
        }
        if (column == 'TheoreticalNeeds') {
            return this.showBase ? item['TheoreticalNeedsBase'] : item['TheoreticalNeeds'];
        }
        if (column == 'PurchaseDifference') {
            return this.showBase ? item['PurchaseDifferenceBase'] : item['PurchaseDifference'];
        }
        if (column == 'PurchaseDifferencePercentage') {
            return this.showBase ? item['PurchaseDifferencePercentageBase'] : item['PurchaseDifferencePercentage'];
        }
    }

    refreshColumnsVisibility() {
        for (let index = 0; index < this.columnsList.length; index++) {
            var column = this.table.column(index);
            if (column) column.visible(this.columnsList[index].enabled);
        }
        this.table.columns.adjust();
    }

    openColumnsDialog() {

        const columnsList = this.columnsList.filter((c: any) => {
            if (!this.showBase) {
                return !c.baseUnitCol || c.defaultUnitCol;
            } else {
                return c.baseUnitCol || !c.defaultUnitCol;
            }
        })

        const dialogRef = this.dialog.open(ColumnsSelectionPopupComponent, {
            data: { columnsList: columnsList },
            width: '300px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            if (res.success && res.columnsList) {
                //this.columnsList = res.columnsList;

                res.columnsList.forEach((c: any) => {
                    const index = this.columnsList.findIndex((i: any) => {
                        if (!this.showBase) {
                            return (!i.baseUnitCol || i.defaultUnitCol) && i.label == c.label;
                        } else {
                            return (i.baseUnitCol || !i.defaultUnitCol) && i.label == c.label;
                        }
                    });
                    this.columnsList[index] = c;
                });

                this.refreshColumnsVisibility();
            }
        });
    }

    goToAcquisti(consumption: any) {

        let filters = this.currentFilterCfg;
        filters['ProductOrCode'] = consumption.Code;
        delete filters['ValueZero'];

        this.router.navigate([`/magazzino/acquisti`], {
            queryParams: {
                filters: JSON.stringify(filters)
            }
        });
    }

    goToInventario(consumption: any) {

        let filters = this.currentFilterCfg;
        filters['ProductOrCode'] = consumption.Code;
        delete filters['ValueZero'];

        this.router.navigate([`/magazzino/inventari`], {
            queryParams: {
                filters: JSON.stringify(filters)
            }
        });
    }

    goToSprechi(consumption: any) {

        let filters = this.currentFilterCfg;
        filters['ProductOrCode'] = consumption.Code;
        delete filters['ValueZero'];

        this.router.navigate([`/magazzino/sprechi`], {
            queryParams: {
                filters: JSON.stringify(filters)
            }
        });
    }

    async openMerce(item: any) {
        const merce = await this.gestioneMerciService.getSingleGoods(item.Goods.Id).toPromise();

        this.dialog
            .open(EditMerciComponent, {
                data: {
                    merce: merce,
                    readOnly: true
                },
                width: '100%',
                height: '100%'
            });
    }

    /******************************************************************************
      ______                       _    __   ___       _______   __
     |  ____|                     | |   \ \ / / |     / ____\ \ / /
     | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
     |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
     | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
     |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
           | |
           |_|
    ******************************************************************************/

    exportAsXLSX() {
        this.table.button(1).trigger();
    }

    /******************************************************************************
        ______                       _     _____  _____  ______
       |  ____|                     | |   |  __ \|  __ \|  ____|
       | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
       |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
       | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
       |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
                   | |
                   |_|
      ******************************************************************************/

    exportAsPDF() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.translate.instant('ANALISI_CONSUMI.TITLE') }),
                    //period: ''
                }
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                }
                result['language'] = this.translationService.getSelectedLanguage();
                result['table'].headerRows = 1;

                this.PDFPreferences = result;
                this.table.button(0).trigger();
            };
        });

    }

    openPopup(item: any) {
        let details = item.details;
        if (!details) {
            this.onLoading.emit(true);
            this.consumiSrv.getConsumptionsDetails(item.Id, this.currentFilterCfg).subscribe(
                (items: any) => {
                    item.details = { Goods: item, Details: items };
                    this.onLoading.emit(false);
                    const dialogRef = this.dialog.open(PopupDettaglioComponent, {
                        data: item.details,
                        width: '1024px',
                        //height: '600px'
                    });
                }
            );
        }
        else {
            const dialogRef = this.dialog.open(PopupDettaglioComponent, {
                data: details,
                width: '1024px',
                //height: '600px'
            });
            dialogRef.afterClosed().subscribe((res: any) => {

                // Agggiugere codice di ritorno

            });
        }


    }

}
