// Core
import { Component, OnInit, OnDestroy, ChangeDetectorRef, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';

// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//RxJs
import { Observable, Subscription, BehaviorSubject, Subject, combineLatest, lastValueFrom } from 'rxjs';
import { tap, map, startWith, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

// Services
import { GestioneRicetteService } from '@app/core/services/gestione-ricette.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { CategorieProdottiService } from '@app/core/services/categorie-prodotti.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';

// Dialogs
import { InsertNotesComponent } from './insert-notes/insert-notes.component';
import { ForzaDateInizioComponent } from './forza-date-inizio/forza-date-inizio.component';
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { EditMerciComponent } from '../../gestione-merci/edit-merci/edit-merci.component';
import { CostCenterService } from '@app/core/services/cost-center.service';

import { ImportIngredientsComponent } from './import-ingredients/import-ingredients.component';
import { CategorieProdottiEditComponent } from '@app/views/pages/anagrafiche/categorie-prodotti/categorie-prodotti-edit/categorie-prodotti-edit.component';
import { RepartiEditComponent } from '@app/views/pages/anagrafiche/reparti/reparti-edit/reparti-edit.component';
import { ConfigService } from '@app/core/services/config.service';
import { FiltriService } from '@app/core/services/filtri.service';

// Utilities
import * as util from '@app/core/services/utilityfunctions';
import * as _ from 'lodash';
import moment from 'moment';
declare var $: any;

@Component({
    selector: 'kt-edit-ricetta',
    templateUrl: './edit-ricetta.component.html',
    styleUrls: ['./edit-ricetta.component.scss']
})
export class EditRicettaComponent implements OnInit, OnDestroy {
    isMobile: boolean = false;
    readOnly: boolean = false;
    allList: boolean = false;
    hoverglassActive: boolean = true;
    elem: any;
    isFullScreen: boolean = false;

    staticCollectionsSubcription!: Subscription;
    ricetta: any;
    editMode: boolean = false;
    utility: any;
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    ready$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    inputChanged: Subject<any> = new Subject<any>();
    inputCalcoloChanged: Subject<any> = new Subject<any>();

    step: number = 0;
    radioPerc: string = '1';
    locale!: string;
    mappaInput: any;
    mappaInputUnit: any;
    myControl = new FormControl();
    semilavoratoCollegato = new FormControl();
    semilavorato = '';
    goodsListFinished: any;
    optScarto = 1;
    showWeightButton = false;

    byCompanyIngredients: any = {};

    totRicetta: number = 0;
    byCompanyTotal: any = {}
    currentCategory: any;

    hasSubstituteIngredients: boolean = false;

    fcNettAvg: number = 0;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private layoutUtilsService: LayoutUtilsService,
        private gestioneRicetteService: GestioneRicetteService,
        private gestioneMerciService: GestioneMerciService,
        private translate: TranslateService,
        private translationService: TranslationService,
        private categorieProdottiService: CategorieProdottiService,
        private anagraficheService: AnagraficheService,
        public roleService: RoleService,
        public dialog: MatDialog,
        private ref: ChangeDetectorRef,
        private _adapter: DateAdapter<any>,
        @Inject(DOCUMENT) private document: any,
        private excelService: ExcelService,
        private pdfService: PDFService,
        private staticCollectionsService: StaticCollectionsService,
        private costCenterService: CostCenterService,
        private confSrv: ConfigService,
        private filtriService: FiltriService
    ) {

        this.mappaInput = {};
        this.mappaInputUnit = {};
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
        this.utility = util;
    }

    getRicettaId() {
        return this.ricetta && this.ricetta.Id ? this.ricetta.Id.toString().replace(/ /g, '') : "";
    }

    getRicettaCode() {
        //const code = this.ricetta && this.ricetta.Code ? this.ricetta.Code.toString().replace(/ /g, '').replace('(', '').replace(')', '') : "";
        //return code.trim();
        return "";
    }

    originalIngredients: any;

    private categorieProdottiObserv$!: Observable<any[]>;
    private unitObserv$!: Observable<any[]>;
    private repartiMagazzinoObserv$!: Observable<any[]>;
    private costCentersObserv$

    categorieProdotti$: any;
    unit$: any;
    repartiMagazzino$: any;
    centriCosto$: any
    defaultReparto: any;
    initYield;
    initialObject!: string;

    stopEvent(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    /**
     * INIZIALIZZAZIONE
     */
    async ngOnInit() {
        this.loading$.next(true);

        document.body.addEventListener('dragover', this.stopEvent, false);
        document.body.addEventListener('drop', this.stopEvent, false);
        $('body').on('keyup', (e: any) => {
            if (e.code == 'F2') {
                e.preventDefault();
                e.stopPropagation();
                console.log('F10Management => save()');
                this.save();
            }
        });

        try {
            const r: any = await this.confSrv.getConfigurations().toPromise().then();
            this.optScarto = r.WasteMode;
        } catch (error) {
            console.log(error);
        }

        this.categorieProdottiObserv$ = this.categorieProdottiService.getCategorieProdotti();
        this.unitObserv$ = this.anagraficheService.getUnitList();
        this.repartiMagazzinoObserv$ = this.anagraficheService.getEntity('Department'); // Reparto
        this.costCentersObserv$ = this.costCenterService.getCostCenters();

        const pair: any = await combineLatest(this.categorieProdottiObserv$, this.unitObserv$, this.repartiMagazzinoObserv$, this.costCentersObserv$,
            (categorieProdottiObserv, unitObserv, repartiMagazzinoObserv, costCentersObserv) => ({
                categorieProdottiObserv, unitObserv, repartiMagazzinoObserv, costCentersObserv
            }))
            .toPromise();

        this.categorieProdotti$ = pair.categorieProdottiObserv;
        this.unit$ = pair.unitObserv;
        this.repartiMagazzino$ = pair.repartiMagazzinoObserv;
        console.log(this.repartiMagazzino$);
        this.centriCosto$ = pair.costCentersObserv;
        this.defaultReparto = this.repartiMagazzino$.find((x: any) => x.Name === '---');
        this.chkScreenMode();
        //this.elem = document.getElementById('accordion');
        this.elem = document.documentElement;

        // this.openFullscreen();

        this._adapter.setLocale('it');//this._adapter.setLocale(this.translationService.getSelectedLanguage());

        this.ricetta = this.data?.ricetta;

        if (this.ricetta && !this.ricetta.Department) {
            this.ricetta.Department = {}
        }

        this.readOnly = this.data?.readonly;

        // Se la ricetta non ha ingredienti sostituiti , 
        // imposto tutte le date degli ingredienti al 01/01/2000
        // => http://mantis.fbmanager.com/view.php?id=963
        if (this.ricetta && this.ricetta.Ingredients && this.ricetta.Ingredients.length > 0) {
            this.hasSubstituteIngredients = this.ricetta.Ingredients.find((i: any) => i.SubstituteId > 0);
            if (!this.hasSubstituteIngredients) {
                this.ricetta.Ingredients.forEach((i: any) => {
                    i.FromDate = moment('2000/01/01').toISOString();
                })
            }
        }


        // recupero subito i valori dell'incidenza associati alla categoria
        if (this.ricetta && this.ricetta.Category && this.ricetta.Category.Id) {
            this.currentCategory = this.categorieProdotti$.find((c: any) => c.Id == this.ricetta.Category.Id)
        } else {
            this.currentCategory = {
                HiFactor: 3,
                MediumFactor: 2,
                LowFactor: 1
            }
        }

        setTimeout(() => {
            $('#ricettaCode').focus();
            $('#ricettaCode').select();
            this.initSelect2();
        }, 1000);

        // Edit
        if (this.ricetta) {
            if (!this.ricetta.Department) {
                this.ricetta.Department = { Id: this.defaultReparto ? this.defaultReparto.Id : 0 };
            }

            if (!this.ricetta.Procedures || this.ricetta.Procedures.length === 0) {
                this.ricetta.Procedures = [{ Message: '', Image: '' }]
            }

            const factor = this.utility.parseNumber(this.ricetta.Factor, this.locale);

            this.ricetta.Factor = this.utility.formatNumber(factor, this.locale);

            this.ricetta.Prices.forEach((element: any) => {
                element['PVImposto'] = util.formatNumber(element['Price'], this.locale, 2, true);;
            });

            this.initYield = this.ricetta.Yield;
            this.ricetta.Yield = this.ricetta ? this.utility.formatNumber(this.ricetta.Yield, this.locale) : 0;

            //Rimpiazzo lo scarto in base all'opzione scelta
            await Promise.all(this.ricetta.Ingredients.map(async (element: any) => {
                element.UniqueId = element.Type + "|" + element.Id + "|" + element.Position;
                //Dal DB lo scarto arriva sempre Netto
                element.wasteView = this.GetScarto(element.Waste, this.optScarto);
                return this.calcoloSingoloIngrediente(element, undefined, undefined);
            }));
            if (this.ricetta.HalfWorked) {
                // Siccome il formato del DisplayFn è diverso
                this.semilavoratoCollegato.setValue({
                    Id: this.ricetta.HalfWorked.Id,
                    Goods: {
                        Name: this.ricetta.HalfWorked.Name,
                        AlternateName: this.ricetta.HalfWorked.Name
                    }
                })
            }

            this.originalIngredients = JSON.parse(JSON.stringify(this.ricetta.Ingredients));
            this.addNewEmptyIngredient();

        } else {
            // NUOVA RICETTA
            // CARICO LE AZIENDE
            this.anagraficheService.getCompanies().subscribe(
                async arg => {
                    let companies: any[] = arg;
                    let code = await this.gestioneRicetteService.getNextRecipeCode().toPromise();
                    localStorage.removeItem('new');
                    this.ricetta = {
                        Code: code,
                        totRicetta: 0,
                        Factor: this.utility.formatNumber(3.1, this.locale),
                        Incidence: 2,
                        Yield: 1,
                        AddedCost: 0,
                        Category: { Id: 0 },
                        Vat: 10,
                        Unit: { Id: this.unit$[0].Id },
                        Type: 1,
                        Department: { Id: this.defaultReparto ? this.defaultReparto.Id : 0 },
                        Ingredients: [],
                        Prices: [],
                        Procedures: [{ Message: '', Image: '' }]
                    };
                    if (this.categorieProdotti$ && this.categorieProdotti$[0]) {
                        this.ricetta.Category = { Id: this.categorieProdotti$[0].Id };
                    }
                    companies.forEach(
                        (x: any) => {
                            this.ricetta.Prices.push({
                                Company: x.Name,
                                FoodCost: 0,
                                ImportCode: 0,
                                IsDisabled: false,
                                Price: 0
                            });
                        }
                    );

                    this.addNewEmptyIngredient();
                    this.initYield = this.ricetta.Yield;
                });
        }

        this.initParameters();
        this.initDataTable();

        this.initialObject = JSON.stringify(this.ricetta);  /// <<<< OGGETTO INIZIALE SALVATO PER CONFRONTO

        this.inputChanged
            .pipe(
                debounceTime(2000),
                distinctUntilChanged()
            )
            .subscribe(model => {
                if (!model) return;
                this.askAddEditIngredient(model);
            });

        this.inputCalcoloChanged
            .pipe(
                debounceTime(1000),
                //distinctUntilChanged()
            )
            .subscribe(() => {
                this.updateTabellaCalcolo();
            });


        if (!$('body').hasClass('kt-aside--minimize')) {
            document.body.classList.add('kt-aside--minimize');
        }


        this.loading$.next(false);
    }
    /**  FINE  ngOnInit()*/

    /*************************************************************************************************/


    alreadyInited: boolean = false;
    prodottiFiltrati!: Observable<any[]>;
    semilavoratiFiltrati!: Observable<any[]>;
    /***************************************************************************************************** */
    ngAfterViewInit() {
        this.semilavoratiFiltrati = this.semilavoratoCollegato.valueChanges
            .pipe(
                //startWith(''),
                debounceTime(500),
                switchMap(value => this._filter(value))
            );
    }
    /***************************************************************************************************** */
    table: any;
    tableCalcolo: any;
    async initDataTable() {

        this.hoverglassActive = true;
        setTimeout(async () => {
            let dtOptions: any = {
                initComplete: (settings) => {
                    this.hoverglassActive = false;
                    this.ref.detectChanges();

                    var api = new (<any>$.fn).dataTable.Api(settings);
                    api.scroller.toPosition(1000);
                },
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                scrollY: 'calc(100vh - 200px)',
                scrollCollapse: true,
                responsive: false,
                columnDefs: [
                    { targets: '_all', orderable: false },
                    { targets: [0], visible: false, data: 'position' },
                    { targets: [1], width: '150px' },
                    { targets: [3], width: '60px' },  // Price
                    { targets: [4, 6], width: '40px' },  // Unit
                    { targets: [5, 8], width: '80px' },  // Quantity, Gross Qty
                    { targets: [7], width: '60px' },  // Waste
                    { targets: [9], width: '60px', className: 'text-right' },  // Total
                    { targets: [10, 11], width: '90px' }, // Date From, Date To
                    { targets: [12], width: '50px' }  // Actions
                ],
                rowReorder: {
                    dataSrc: 'position'
                },
                aaSorting: [[0, 'asc']]
            };
            const ricettaCode = "";//this.getRicettaCode().replaceAll("'", "");
            this.table = $(`#tableIngredienti_${ricettaCode}`).DataTable(dtOptions);

            //this.hoverglassActive = false;

            await this.refreshCalcoliIngredienti(undefined);
            await this.updateTotRicetta();

            // Per fcNettAvg mi serve this.byCompanyTotal (updateTotRicetta)
            // Per this.byCompanyTotal mi serve this.byCompanyIngredients (calcoloSingoloIngrediente)
            // calcoloSingoloIngrediente viene richiamato da refreshCalcoliIngredienti
            // e poi da updateTotRicetta 

            this.updateTabellaCalcolo();

            this.table.on('row-reorder', (e, diff, edit) => {

                var result = 'Reorder started on row: ' + edit.triggerRow.data()[1] + '<br>';

                for (var i = 0, ien = diff.length; i < ien; i++) {
                    const from = diff[i].oldData;
                    const to = diff[i].newData;
                    let index = this.ricetta.Ingredients.findIndex((i: any) => i.Position == from);
                    if (index >= 0) this.ricetta.Ingredients[index].Position = to;
                }

            });

            let dtOptionsCalcolo: any = {
                initComplete: () => {
                    this.hoverglassActive = false;
                    this.ref.detectChanges();

                },
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                scrollY: 'calc(100vh - 250px)',
                scrollCollapse: true,
                ordering: false,
                aaSorting: [[1, 'asc']],
                columnDefs: [
                    { targets: '_all', orderable: false },
                    { targets: [0], width: '50px' },
                    { targets: [2, 3, 4, 5, 6, 7, 8, 9, 10], width: '100px' },
                ],
            };
            this.tableCalcolo = $('#tableCalcolo').DataTable(dtOptionsCalcolo);
            this.tableCalcolo.columns.adjust().draw();
            this.updateTabellaCalcolo();

        }, 500);

    }
    /***************************************************************************************************** */
    private _filter(value: any) {
        let filterValue = '';
        if (value.constructor === String) {
            filterValue = value.toLowerCase();
        } else if (value.constructor === Object) {
            filterValue = value.Name ? value.Name.toLowerCase() : '';
        }
        return this.gestioneRicetteService.getAvailableIngredients({
            Name: filterValue
        });
    }
    /***************************************************************************************************** */
    ngOnDestroy() {
        if (this.staticCollectionsSubcription) this.staticCollectionsSubcription.unsubscribe();
        document.body.removeEventListener('dragover', this.stopEvent, false);
        document.body.removeEventListener('drop', this.stopEvent, false);
        $('body').off('keyup');
    }
    /***************************************************************************************************** */
    getTitle(): string {
        if (this.ricetta && this.ricetta.Name) {
            return `${this.ricetta.Name}`;
        }
        return this.translate.instant('GESTIONE_RICETTE.EDIT.NEW_RECIPE');
    }

    onChangeCategory(event: any) {
        console.log(event);
    }

    /***************************************************************************************************** */
    exit() {

        if (JSON.stringify(this.ricetta) == this.initialObject || !this.roleService.isCompanyAdminOrMore()) {
            this.goBack(null);
        } else {
            const _title: string = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_SOMETHING_CHANGED.TITLE');
            const _description: string = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_SOMETHING_CHANGED.DESC');
            const _waitDesciption: string = '';
            const _yesButton = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_SOMETHING_CHANGED.SAVE');
            const _yes2Button = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_SOMETHING_CHANGED.EXIT');
            const _noButton = this.translate.instant('COMMONS.CANCEL');
            const success = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_SOMETHING_CHANGED.SUCCESS');

            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton, _yes2Button);
            dialogRef.afterClosed().subscribe((result: any) => {
                if (result === 2) {
                    this.goBack(null);
                } else if (result) {
                    this.save();
                } else {
                    //NOP
                }
            });

        }

    }
    /***************************************************************************************************** */
    /**
     * Ritorna alla liste ricette chiudendo il popup
     */
    goBack(returnValue) {


        if (this.isFullScreen) this.closeFullscreen();
        /*
        this.router.navigate([`/gestione-ricette`], {
          queryParams: {}
        });
        */

        this.dialogRef.close(returnValue);
    }
    /***************************************************************************************************** */
    /**
     * Aggiorna i valori della tabella dei Prezzi per azienda
     */
    updateTabellaCalcolo() {
        if (!this.ricetta) return;
        let addedCost = this.ricetta.AddedCost ? util.parseNumber(this.ricetta.AddedCost, this.locale) : 0;
        let vat = this.ricetta.Vat ? util.parseNumber(this.ricetta.Vat, this.locale) : 0;
        let factor = this.ricetta.Factor ? util.parseNumber(this.ricetta.Factor, this.locale) : 0;

        if (!this.ricetta || !this.ricetta.Prices) return;
        this.fcNettAvg = 0;
        this.ricetta.Prices.forEach((element: any) => {
            let tot = this.byCompanyTotal[element.Company.toUpperCase()] / util.parseNumber(this.ricetta.Yield, this.locale) || 0;
            let pvImposto = element['PVImposto'] ? util.parseNumber(element['PVImposto'], this.locale) : 0;
            element['FoodCost'] = tot;
            element['FoodCostLordo'] = tot * (1 + addedCost / 100);
            let pv = element['FoodCostLordo'] * factor;
            element['PvTeorico'] = pv * (1 + vat / 100);
            element['MdcTeorico'] = pv - element['FoodCostLordo'];
            element['FcTeorico'] = pv > 0 ? element['FoodCostLordo'] * 100 / pv : 0;

            element['MdcImposto'] = pvImposto * (100 / (100 + vat)) - element['FoodCostLordo']
            if ((pvImposto - (pvImposto * vat / (100 + vat))) == 0) {
                element['FCImposto'] = 0;
            }
            else {
                element['FCImposto'] = element['FoodCostLordo'] / (pvImposto - (pvImposto * vat / (100 + vat))) * 100
            }

            element['FattoreImposto'] = element['FoodCostLordo'] > 0 ? pvImposto * (100 / (100 + vat)) / element['FoodCostLordo'] : 0;

            this.fcNettAvg += element.FoodCost;
        });
        this.fcNettAvg = (this.ricetta.Prices.length > 0) ? this.fcNettAvg / this.ricetta.Prices.length : 0;

        this.ref.detectChanges();
    }
    /***************************************************************************************************** */
    /**
     * Ritorna lo scarto in base al fatto che sia sul netto, sul lordo o lordo/netto
     * @param Netto 
     * @param scarto 
     * @param optScarto 
     */
    GetScarto(scartoNetto: number, optScarto = 0) {
        let x = 0;
        let sn = scartoNetto / 100;
        switch (optScarto) {
            case 0:
                //NETTO
                x = sn;
                break;
            case 1:
                //LORDO
                x = sn / (1 + sn);
                break;
            case 2:
                //L/N
                x = 1 + sn;
                break;
        }

        let sc = Math.round(x * 100 * 1000) / 1000;

        return util.formatNumber(sc, this.locale, 2, true);

    }
    /***************************************************************************************************** */
    /**
     * Ritorna lo scarto netto da salvare nel DB indipendentemente dal formato di visualizzazione
     * @param Netto 
     * @param scarto 
     * @param optScarto 
     */
    GetDBScarto(scarto: number, optScarto = 0) {
        let lordo = 0;
        let x = 0;
        let s = scarto / 100;
        switch (optScarto) {
            case 0:
                //arriva già Netto
                x = s;
                break;
            case 1:
                //arriva Lordo
                x = s / (1 - s);
                break;
            case 2:
                //arriva L/N
                x = s - 1;
                break;
        }
        return Math.round(x * 100 * 100) / 100;
    }

    /***************************************************************************************************** */
    async calcoloSingoloIngrediente(ingredient: any, changedColumn: any = 'Quantity', columnToModify) {

        let wasteView = ingredient.wasteView ? util.parseNumber(ingredient.wasteView, this.locale) : 0;
        let qtaLorda = ingredient.QtaLorda ? util.parseNumber(ingredient.QtaLorda, this.locale) : 0;
        let qty = ingredient.Quantity ? util.parseNumber(ingredient.Quantity, this.locale) : 0;

        if (columnToModify === "Waste") {
            switch (this.optScarto) {
                case 0:
                    wasteView = (qtaLorda / qty - 1) * 100;
                    break;
                case 1:
                    wasteView = (qty / qtaLorda + 1) * 100;
                    break;
                case 2:
                    wasteView = qtaLorda * 100 / qty;
                    break;
            }

        }
        else {
            // Se Waste o Quantity
            switch (this.optScarto) {
                case 0:
                    {

                        switch (changedColumn) {
                            case "QtaLorda":

                                qty = qtaLorda / (1 + wasteView / 100);
                                break;
                            case "Quantity":

                                qtaLorda = qty * (1 + wasteView / 100);
                                break;
                            case "wasteView":

                                qtaLorda = qty * (1 + wasteView / 100);
                                break;

                        }
                    }
                    break;
                case 1:
                    {
                        switch (changedColumn) {
                            case "QtaLorda":

                                qty = qtaLorda * (1 - wasteView / 100);
                                break;
                            case "Quantity":

                                qtaLorda = qty / (1 - wasteView / 100);
                                break;
                            case "wasteView":


                                qtaLorda = qty / (1 - wasteView / 100);
                                break;

                        }
                    }
                    break;
                case 2:
                    {
                        //x= lordo * 100 / Netto;
                        switch (changedColumn) {
                            case "QtaLorda":

                                qty = qtaLorda * 100 / wasteView;
                                break;
                            case "Quantity":

                                qtaLorda = qty * wasteView / 100;
                                break;
                            case "wasteView":

                                qtaLorda = qty * wasteView / 100;
                                break;

                        }
                    }
                    break;
            }
        }

        ingredient.Waste = util.formatNumber(wasteView, this.locale, 2, true);
        ingredient.Quantity = util.formatNumber(qty, this.locale, 2, true);
        ingredient.QtaLorda = util.formatNumber(qtaLorda, this.locale, 2, true);

        const ingredientInfo: any = await this.getTotaleIngrediente(ingredient);

        ingredient.Totale = util.formatNumber(ingredientInfo.total, this.locale, 3, true);

        this.byCompanyIngredients[ingredient.Id + '|' + ingredient.Position] = { ingredient: ingredient, info: ingredientInfo };

        this.ref.detectChanges();
    }
    /***************************************************************************************************** */
    async refreshCalcoliIngredienti(columnToModify: any = undefined) {
        if (this.ricetta && this.ricetta.Ingredients && this.ricetta.Ingredients.length > 0) {
            await Promise.all(this.ricetta.Ingredients.map(ingredient => 
                this.calcoloSingoloIngrediente(ingredient, undefined, columnToModify)
            ));
            this.ref.detectChanges();
        }
    }
    /***************************************************************************************************** */
    async updateTotRicetta() {
        return new Promise(async (resolve) => {

            if (!this.ricetta) {
                resolve(false);
            } else {
                this.totRicetta = 0;
                let visibili = this.ricetta.Ingredients.filter((ingredient: any) => ingredient.SubstituteId === 0);
                this.byCompanyTotal = {};

                for (let index = 0; index < visibili.length; index++) {
                    const element = visibili[index];
                    let ingInfo = this.byCompanyIngredients[element.Id + '|' + element.Position];
                    if (ingInfo) {

                        if (ingInfo.ingredient.Type !== 'Goods') {
                            //Caso Sottoricetta non ci sono i costi per azienda
                            this.ricetta.Prices.forEach((x: any) => {
                                let cmp = x.Company.toUpperCase();
                                if (!this.byCompanyTotal[cmp]) {
                                    this.byCompanyTotal[cmp] = 0;
                                }
                            });

                            for (let cmp in this.byCompanyTotal) {
                                this.byCompanyTotal[cmp] += ingInfo.info.total;
                            }
                        } else {
                            for (let cmp in ingInfo.info.byCompany) {
                                if (!this.byCompanyTotal[cmp]) {
                                    this.byCompanyTotal[cmp] = 0;
                                }
                                this.byCompanyTotal[cmp] += ingInfo.info.byCompany[cmp].Total;
                            }
                        }
                    }

                    const result: any = await this.getTotaleIngrediente(element);
                    element.Totale = util.formatNumber(result.total, this.locale, 3, true);

                    this.byCompanyIngredients[element.Id + '|' + element.Position] = { ingredient: element, info: result };
                    this.totRicetta += result.total;
                };
                this.ref.detectChanges();
                resolve(true);
            }

        });
    }
    /***************************************************************************************************** */
    async getTotaleIngrediente(ingredient: any) {
        return new Promise(async (resolve) => {

            //let foundGoods: any;
            //if (ingredient.Goods && ingredient.Goods.Id) {
            //    foundGoods = ingredient;
            //    //if (ingredient.Type === 'Goods') {
            //    //    foundGoods = ingredient;
            //    //} else {
            //    //    foundGoods = await lastValueFrom(this.gestioneRicetteService.getSingleRecipe(ingredient.Goods.Id));
            //    //}
            //}

            let wasteView = ingredient.wasteView ? util.parseNumber(ingredient.wasteView, this.locale) : 0;
            let qtaLorda = ingredient.QtaLorda ? util.parseNumber(ingredient.QtaLorda, this.locale) : 0;
            let qty = ingredient.Quantity ? util.parseNumber(ingredient.Quantity, this.locale) : 0;
            let price = ingredient.Goods.Price ? util.parseNumber(ingredient.Goods.Price, this.locale) : 0;
            let byCompany: any = {};

            if(ingredient.Id == 13432) {
                ingredient
            }
            
            let unit: any = undefined;
            if (ingredient.Unit && ingredient.Unit.Id) {
                unit = this.unit$.find((unit: any) => unit.Id.toString() === ingredient.Unit.Id.toString());
            }
            let coeffUnitaMisura = unit && unit.A ? unit.A : 1;
            if (ingredient && ingredient.Prices) {
                coeffUnitaMisura = ingredient && ingredient.Goods.A ? coeffUnitaMisura / ingredient.Goods.A : coeffUnitaMisura;
                ingredient.Prices.forEach(p => {
                    if (p.Store) {
                        byCompany[p.Store.toUpperCase()] = {
                            Price: p.Price,
                            Total: qtaLorda * p.Price * coeffUnitaMisura
                        };
                    }
                });                
            }
            resolve({
                wasteView: wasteView,
                qtaLorda: qtaLorda,
                qty: qty,
                price: price,
                unit: unit,
                coeffUnitaMisura: coeffUnitaMisura,
                total: qtaLorda * price * coeffUnitaMisura,
                byCompany: byCompany
            });
        });
    }
    /***************************************************************************************************** */
    focusInput(event: any) {
        $(event.target).focus();
        $(event.target).select();
    }
    /***************************************************************************************************** */
    async showAutocomplete(ingredient: any, $event: any) {
        if (this.mappaInput[ingredient.Id]) return;

        const index = $($event.target).closest('tr').index();

        ingredient['Index'] = index;

        this.mappaInput = {};
        this.mappaInput[ingredient.Index] = true;

        this.prodottiFiltrati = this.myControl.valueChanges
            .pipe(
                startWith(''),
                debounceTime(500),
                switchMap(value => this._filter(value))
            );

        const value = (!ingredient || !ingredient.Id) ? null : await lastValueFrom(this.gestioneMerciService.getSingleGoods(ingredient.Id));

        if (ingredient && Number(ingredient.Id) > 0) {
            this.myControl.setValue(value);
        } else {
            this.myControl.setValue('');
        }

        setTimeout(() => {
            $('#matAutoCompleteInput').focus();
        }, 100);
    }
    /***************************************************************************************************** */
    getSupplier(item) {
        let s = "";
        if (item.Suppliers && item.Suppliers.length > 0) {
            s = "[";
            item.Suppliers.forEach((element: any) => {
                s += element.Name + ", ";
            });
            s = s.substring(0, s.length - 2);
            s += "]";
        }

        return s;
    }
    /***************************************************************************************************** */
    unitaMisuraLst: any;
    showSelectUnit(item: any, $event: any) {

        const index = $($event.target).closest('tr').index();
        item['Index'] = index;

        this.mappaInputUnit = {};
        this.mappaInputUnit[item.Index] = true;

        if (item.Unit) {
            this.unitaMisuraLst = this.unit$.filter((u: any) => u.MainUnitId === item.Unit.BaseId);
        } else {
            this.unitaMisuraLst = this.unit$;
        }

        if (this.unitaMisuraLst.length > 1) {
            setTimeout(() => {

                $('#selectUnitBase').select();
                $('#selectUnitBase').focus();
            });
        } else {
            this.mappaInputUnit = {};
        }

    }
    /***************************************************************************************************** */
    async selectNewUnit(event: any, item: any, row: number) {
        // Cerco l'indice nell'array ingredients
        // const index = this.ricetta.Ingredients.findIndex((obj => obj.Id == item.Id));
        const index = row;
        const unit = this.unit$.find((unit: any) => unit.Id.toString() === event.target.value.toString());
        const baseUnit = this.unit$.find((u: any) => u.Id === unit.MainUnitId);

        const coeff = item.Unit.A > unit.A ? baseUnit.A / unit.A : item.Unit.A / baseUnit.A;
        let qty = this.utility.parseNumber(this.ricetta.Ingredients[index].Quantity, this.locale) * coeff;
        this.ricetta.Ingredients[index].Quantity = this.utility.formatNumber(qty, this.locale);

        // Se c'è lo scarto
        let waste = this.utility.parseNumber(this.ricetta.Ingredients[index].Waste, this.locale);
        if (waste > 0) {
            waste = this.GetScarto(waste, this.optScarto);
            switch (this.optScarto) {
                case 0:
                    {
                        let qtaLorda = qty * (1 + waste / 100);
                        this.ricetta.Ingredients[index].QtaLorda = this.utility.formatNumber(qtaLorda, this.locale);
                    }
                    break;
                case 1:
                    {
                        let qtaLorda = qty / (1 - waste / 100);
                        this.ricetta.Ingredients[index].QtaLorda = this.utility.formatNumber(qtaLorda, this.locale);
                    }
                    break;
                case 2:
                    {
                        let qtaLorda = qty * waste / 100;
                        this.ricetta.Ingredients[index].QtaLorda = this.utility.formatNumber(qtaLorda, this.locale);
                    }
                    break;
            }

        } else {
            this.ricetta.Ingredients[index].QtaLorda = this.utility.formatNumber(qty, this.locale);
        }

        this.ricetta.Ingredients[index].Unit = {
            A: unit.A,
            B: unit.B,
            Id: unit.Id,
            Name: unit.Name,
            BaseId: baseUnit.Id,
            Yield: unit.Yield,
            BaseUnitName: baseUnit.Name
        };
        this.mappaInputUnit = {};

        await this.calcoloSingoloIngrediente(this.ricetta.Ingredients[index], undefined, undefined);
        await this.updateTotRicetta();
        this.updateTabellaCalcolo();
    }
    /***************************************************************************************************** */
    /**
     * Selezione nuovo ingrediente 
     * @param event 
     * @param ingredient 
     */
    selectNewIngredient(event: any, ingredient: any) {
        const row = $('#select2_productName').closest('tr').index() + 1;

        let newIngredient = event.option.value;

        const index = this.ricetta.Ingredients.findIndex((obj => obj.UniqueId == ingredient.UniqueId));

        //Se sto modificando ingrediente/sottoricetta Aggiungo questo elemento alla lista, ma non verrà visualizzato.
        if (ingredient.Id && !ingredient.IsAddedd) {

            const _title: string = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_ADD_UPDATE_INGREDIENT.TITLE');
            const _description: string = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_ADD_UPDATE_INGREDIENT.DESC');
            const _waitDesciption: string = '';
            const _yesButton = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_ADD_UPDATE_INGREDIENT.UPDATE');
            const _yes2Button = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_ADD_UPDATE_INGREDIENT.ADD');
            const _noButton = this.translate.instant('COMMONS.CANCEL');
            const success = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_ADD_UPDATE_INGREDIENT.SUCCESS');

            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton, _yes2Button);
            dialogRef.afterClosed().subscribe((result: any) => {
                if (result === 2) { // Aggiungi nuovo
                    this.performSubtituiteIngredient(ingredient, newIngredient);
                } else if (result) { // Update
                    this.ricetta.Ingredients[index].Type = newIngredient.Type;
                    this.ricetta.Ingredients[index].Goods = { Id: newIngredient.Id };
                    this.ricetta.Ingredients[index].Goods.Name = newIngredient.Goods.Name;
                    this.ricetta.Ingredients[index].Goods.AlternateName = newIngredient.Goods.AlternateName;
                    this.ricetta.Ingredients[index].Goods.Price = newIngredient.Goods.Price;
                    this.ricetta.Ingredients[index].Goods.Code = newIngredient.Goods.Code;
                    this.ricetta.Ingredients[index].Goods.UnitName = newIngredient.Unit.Name;
                    this.ricetta.Ingredients[index].Complement = '';
                    this.ricetta.Ingredients[index].Unit = this.getBaseUnit(newIngredient);
                    this.ricetta.Ingredients[index].UniqueId = newIngredient.Type + "|" + newIngredient.Id + "|" + ingredient.Position;
                    this.ricetta.Ingredients[index].Prices = newIngredient.Prices || [];
                }
            });


        } else {

            let w = this.GetScarto(newIngredient.Goods.Waste, this.optScarto);
            // Sostituisco l'attuale ingrediente con quello nuovo
            this.ricetta.Ingredients[index].IsAddedd = true;
            this.ricetta.Ingredients[index].FromDate = !this.hasSubstituteIngredients ? moment('2000/01/01').toDate() : new Date();
            this.ricetta.Ingredients[index].Id = newIngredient.Id;//0; => http://mantis.fbmanager.com/view.php?id=891
            this.ricetta.Ingredients[index].QtaLorda = '0,0';
            this.ricetta.Ingredients[index].Quantity = '0';
            this.ricetta.Ingredients[index].Totale = '0,0';
            this.ricetta.Ingredients[index].Waste = this.utility.formatNumber(w, this.locale);
            this.ricetta.Ingredients[index].wasteView = this.utility.formatNumber(w, this.locale);
            this.ricetta.Ingredients[index].SubstituteId = 0;
            this.ricetta.Ingredients[index].Complement = '';
            this.ricetta.Ingredients[index].ToDate = new Date(2199, 11, 31);
            this.ricetta.Ingredients[index].Type = newIngredient.Type;
            this.ricetta.Ingredients[index].Goods = { Id: newIngredient.Id };
            this.ricetta.Ingredients[index].Goods.Name = newIngredient.Goods.Name.trim();
            this.ricetta.Ingredients[index].Goods.AlternateName = newIngredient.Goods.AlternateName.trim();
            this.ricetta.Ingredients[index].Goods.UnitName = newIngredient.Unit.Name;
            this.ricetta.Ingredients[index].Goods.Price = newIngredient.Goods.Price;
            this.ricetta.Ingredients[index].Goods.Code = newIngredient.Goods.Code;
            this.ricetta.Ingredients[index].Unit = this.getBaseUnit(newIngredient);
            this.ricetta.Ingredients[index].UniqueId = newIngredient.Type + "|" + newIngredient.Id + "|" + ingredient.Position;
            this.ricetta.Ingredients[index].Prices = newIngredient.Prices || [];

            // Se ho utilizzato l'ultima riga vuota allora ne aggiungo un altra.      
            this.addNewEmptyIngredient();

            this.mappaInput = {};
            // this.hoverglassActive = true;

            $(`#tableIngredienti_${this.getRicettaCode()}`).DataTable().destroy();
            // setTimeout(() => {
            this.initDataTable();
            setTimeout(() => {
                $(`#tableIngredienti_${this.getRicettaCode()} tr:nth-child(${row}) .qty_input`).select();
                var $scrollBody = $(this.table.table().node()).parent();
                $scrollBody.scrollTop($scrollBody.get(0).scrollHeight);
            }, 1000);
            // }, 1000);
        }

    }
    /***************************************************************************************************** */
    /**
     * Gets base unit according to selected unit
     * @param newIngredient 
     */
    getBaseUnit(newIngredient: any): any {
        const unit = this.unit$.find((unit: any) => unit.Id === newIngredient.Unit.Id);
        const baseUnit = this.unit$.find((u: any) => u.Id === unit.MainUnitId)
        let u: any = unit;
        if (baseUnit && baseUnit.Name.toLowerCase() === 'kg' && unit && unit.Name.toLowerCase() !== 'g') {
            u = this.unit$.find((u: any) => u.Name.toLowerCase() === 'g');
        } else if (baseUnit && baseUnit.Name.toLowerCase() === 'lt' && unit && unit.Name.toLowerCase() !== 'ml') {
            u = this.unit$.find((u: any) => u.Name.toLowerCase() === 'ml');
        } else {
            u = unit;
        }

        return {
            Id: u.Id,
            Name: u.Name,
            A: u.A,
            B: u.B,
            Yield: u.Yield,
            BaseId: u.MainUnitId,
            BaseUnitName: u.Name
        };

    }
    /***************************************************************************************************** */
    performSubtituiteIngredient(oldIngredient: any, newIngredientInfo: any) {

        let addNew = true;
        this.ricetta.Ingredients = this.ricetta.Ingredients.filter(obj => obj.Goods.Id);

        let newIngredient: any = undefined;
        if (newIngredientInfo.Goods) {
            // newIngredient è quello con il valore nuovo già presente tra gli ingredienti
            newIngredient = newIngredientInfo;
            addNew = false;

            oldIngredient.ToDate = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
            oldIngredient.SubstituteId = newIngredient.Id;
            oldIngredient.Position = this.ricetta.Ingredients.length;
            oldIngredient.UniqueId = oldIngredient.Type + "|" + oldIngredient.Id + "|" + oldIngredient.Position;

            newIngredient.FromDate = moment(new Date()).format('YYYY-MM-DD');
            newIngredient.ToDate = moment(new Date(2199, 11, 31)).format('YYYY-MM-DD');
        }
        else {
            //è quello con la nuova merce, non è presente
            addNew = true;
            newIngredient = {
                IsAddedd: true,
                FromDate: moment(new Date()).format('YYYY-MM-DD'),
                Id: newIngredientInfo.Id,
                QtaLorda: oldIngredient.QtaLorda,
                Quantity: oldIngredient.Quantity,
                Totale: oldIngredient.Totale,
                Waste: oldIngredient.Waste,
                SubstituteId: 0,
                Complement: oldIngredient.Complement,
                ToDate: moment(new Date(2199, 11, 31)).format('YYYY-MM-DD'),
                Type: newIngredientInfo.Type,
                Goods: {
                    Id: newIngredientInfo.Id,
                    Name: newIngredientInfo.Goods.Name,
                    UnitName: newIngredientInfo.Unit.Name,
                    Price: newIngredientInfo.Goods.Price,
                    Code: newIngredientInfo.Goods.Code,
                },
                Position: oldIngredient.Position,
                Unit: this.getBaseUnit(newIngredientInfo),
                UniqueId: newIngredientInfo.Type + "|" + newIngredientInfo.Id + "|" + oldIngredient.Position
            }

            oldIngredient.ToDate = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
            oldIngredient.SubstituteId = newIngredient.Id;
            oldIngredient.Position = this.ricetta.Ingredients.length;
            oldIngredient.UniqueId = oldIngredient.Type + "|" + oldIngredient.Id + "|" + oldIngredient.Position;
        }

        if (addNew) {
            this.ricetta.Ingredients.push(newIngredient);
        }
        else {
            this.ricetta.Ingredients.push(oldIngredient);
        }

        this.addNewEmptyIngredient();
        $(`#tableIngredienti_${this.getRicettaCode()}`).DataTable().destroy();
        setTimeout(() => {
            this.initDataTable();
        }, 1000);

        this.ref.detectChanges();
    }
    /***************************************************************************************************** */
    addNewEmptyIngredient() {

        // Se per caso ho già un ultima riga vuota non la metto
        if (this.ricetta && this.ricetta.Ingredients && this.ricetta.Ingredients.length > 0 &&
            this.ricetta.Ingredients[this.ricetta.Ingredients.length - 1] &&
            this.ricetta.Ingredients[this.ricetta.Ingredients.length - 1].Goods &&
            this.ricetta.Ingredients[this.ricetta.Ingredients.length - 1].Goods.Name &&
            this.ricetta.Ingredients[this.ricetta.Ingredients.length - 1].Goods.Name.length === 0) return;

        let maxPosition = 0;
        this.ricetta.Ingredients.forEach((element: any) => {
            maxPosition = element.Position > maxPosition ? element.Position : maxPosition;
        });

        this.ricetta.Ingredients.push({
            Position: maxPosition + 1,
            Type: 'Goods',
            Goods: {
                Name: ''
            },
            Unit: {},
            Quantity: util.formatNumber(0, this.locale, 2),
            QtaLorda: util.formatNumber(0, this.locale, 2),
            Totale: util.formatNumber(0, this.locale, 3),
            Waste: util.formatNumber(0, this.locale, 2),
            wasteView: 0,
            Complement: ''
        });
    }
    /***************************************************************************************************** */
    dateChange(event: any) {
        //console.log('dateChange', event);
    }
    /***************************************************************************************************** */
    displayFn(product: any): string {
        if (!product) return '';
        // Prima era Name, poi cambiato per http://mantis.fbmanager.com/view.php?id=837
        return product.Goods ? (product.Type !== 'Goods' ? product.Goods.Name : product.Goods.AlternateName) : '';
    }
    /***************************************************************************************************** */
    blurInput(event: any, item: any) {
        setTimeout(() => {
            //this.mappaInput = {};
            //this.mappaInputUnit = {};
            //this.ref.detectChanges();
        }, 100);
    }
    /***************************************************************************************************** */
    forceSelectAll(event: any) {
        $(event.target).select();
    }
    /***************************************************************************************************** */
    categoria: any;
    recipeType: any;
    reparto: any;
    unit: any;
    initSelect2() {

        (<any>$(`#recipe_${this.getRicettaId()} .select2Categories`)).select2({
            placeholder: this.translate.instant('FILTRI.CATEGORIES_PLACEHOLDER'),
            allowClear: false,
            closeOnSelect: true,
            minimumResultsForSearch: -1,
            width: '100%',
            containerCssClass: 'select2Single',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });
        $(`#recipe_${this.getRicettaId()} .select2Categories`).on('select2:select', (e: any) => {

            this.ricetta.Category = {
                Id: e.params.data.id,
                Name: e.params.data.text
            };

            this.onChangeCategory(this.categorieProdotti$.find((c: any) => c.Id == e.params.data.id));

        });
        $(`#recipe_${this.getRicettaId()} .select2Categories`).on('select2:clear', (e: any) => {
            this.categoria = undefined;
        });

        // $(`#recipe_ .select2RecipeType_ERC`)
        (<any>$(`#recipe_${this.getRicettaId()} .select2RecipeType_ERC`)).select2({
            placeholder: this.translate.instant('FILTRI.RECIPE_TYPE'),
            allowClear: false,
            closeOnSelect: true,
            minimumResultsForSearch: -1,
            width: '100%',
            containerCssClass: 'select2Single',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });
        $(`#recipe_${this.getRicettaId()} .select2RecipeType_ERC`).on('select2:select', (e: any) => {
            this.ricetta.Type = e.params.data.id;
        });
        $(`#recipe_${this.getRicettaId()} .select2RecipeType_ERC`).on('select2:clear', (e: any) => {
            this.ricetta.Typ = undefined;
        });

        (<any>$(`#recipe_${this.getRicettaId()} .select2Department`)).select2({
            placeholder: this.translate.instant('FILTRI.REPARTO'),
            allowClear: false,
            closeOnSelect: true,
            minimumResultsForSearch: -1,
            width: '100%',
            containerCssClass: 'select2Single'
        });
        $(`#recipe_${this.getRicettaId()} .select2Department`).on('select2:select', (e: any) => {
            this.ricetta.Department.Id = e.params.data.id
        });
        $(`#recipe_${this.getRicettaId()} .select2Department`).on('select2:clear', (e: any) => {
            this.ricetta.Department.Id = undefined;
        });

        (<any>$(`#recipe_${this.getRicettaId()} .select2Unit`)).select2({
            placeholder: this.translate.instant('MENU.UNIT'),
            allowClear: false,
            closeOnSelect: true,
            minimumResultsForSearch: -1,
            width: '100%',
            containerCssClass: 'select2Single',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });
        $(`#recipe_${this.getRicettaId()} .select2Unit`).on('select2:select', (e: any) => {
            this.ricetta.Unit.Id = e.params.data.id;
        });
        $(`#recipe_${this.getRicettaId()} .select2Unit`).on('select2:clear', (e: any) => {
            this.ricetta.Unit.Id = undefined;
        });


        $(`#recipe_${this.getRicettaId()} .select2Categories`).on('select2:opening', (e: any) => {
            $(`#recipe_${this.getRicettaId()} .select2Categories + .select2 .select2-selection`).css('background-color', 'rgba(255, 221, 69, 0.3)');

            $(`#recipe_${this.getRicettaId()} .select2RecipeType_ERC + .select2 .select2-selection`).css('background-color', 'white');
            $(`#recipe_${this.getRicettaId()} .select2Department + .select2 .select2-selection`).css('background-color', 'white');
            $(`#recipe_${this.getRicettaId()} .select2Unit + .select2 .select2-selection`).css('background-color', 'white');
        });

        $(`#recipe_${this.getRicettaId()} .select2RecipeType_ERC`).on('select2:opening', (e: any) => {
            $(`#recipe_${this.getRicettaId()} .select2RecipeType_ERC + .select2 .select2-selection`).css('background-color', 'rgba(255, 221, 69, 0.3)');

            $(`#recipe_${this.getRicettaId()} .select2Categories + .select2 .select2-selection`).css('background-color', 'white');
            $(`#recipe_${this.getRicettaId()} .select2Department + .select2 .select2-selection`).css('background-color', 'white');
            $(`#recipe_${this.getRicettaId()} .select2Unit + .select2 .select2-selection`).css('background-color', 'white');
        });

        $(`#recipe_${this.getRicettaId()} .select2Department`).on('select2:opening', (e: any) => {
            $(`#recipe_${this.getRicettaId()} .select2Department + .select2 .select2-selection`).css('background-color', 'rgba(255, 221, 69, 0.3)');

            $(`#recipe_${this.getRicettaId()} .select2RecipeType_ERC + .select2 .select2-selection`).css('background-color', 'white');
            $(`#recipe_${this.getRicettaId()} .select2Categories + .select2 .select2-selection`).css('background-color', 'white');
            $(`#recipe_${this.getRicettaId()} .select2Unit + .select2 .select2-selection`).css('background-color', 'white');
        });

        $(`#recipe_${this.getRicettaId()} .select2Unit`).on('select2:opening', (e: any) => {
            $(`#recipe_${this.getRicettaId()} .select2Unit + .select2 .select2-selection`).css('background-color', 'rgba(255, 221, 69, 0.3)');

            $(`#recipe_${this.getRicettaId()} .select2RecipeType_ERC + .select2 .select2-selection`).css('background-color', 'white');
            $(`#recipe_${this.getRicettaId()} .select2Department + .select2 .select2-selection`).css('background-color', 'white');
            $(`#recipe_${this.getRicettaId()} .select2Categories + .select2 .select2-selection`).css('background-color', 'white');
        });

        $('.form-control').on('focus', () => {
            $(`#recipe_${this.getRicettaId()} .select2Unit + .select2 .select2-selection`).css('background-color', 'white');
            $(`#recipe_${this.getRicettaId()} .select2RecipeType_ERC + .select2 .select2-selection`).css('background-color', 'white');
            $(`#recipe_${this.getRicettaId()} .select2Department + .select2 .select2-selection`).css('background-color', 'white');
            $(`#recipe_${this.getRicettaId()} .select2Categories + .select2 .select2-selection`).css('background-color', 'white');
        })

    }
    /***************************************************************************************************** */
    initParameters() {
        setTimeout(() => {

            //   if (this.ricetta.Category) $(`#recipe_${this.getRicettaId()} .select2Categories`).val(this.ricetta.Category.Id).trigger('change');
            if (this.ricetta && this.ricetta.Type) {
                $(`#recipe_${this.getRicettaId()} .select2RecipeType_ERC`).val(this.ricetta.Type).trigger('change');
            } else {
                $(`#recipe_${this.getRicettaId()} .select2RecipeType_ERC`).val(3).trigger('change');
            }
            if (this.ricetta && this.ricetta.Department) $(`#recipe_${this.getRicettaId()} .select2Department`).val(this.ricetta.Department.Id).trigger('change');
            if (this.ricetta && this.ricetta.Unit) $(`#recipe_${this.getRicettaId()} .select2Unit`).val(this.ricetta.Unit.Id).trigger('change');

        }, 100);

    }
    /***************************************************************************************************** */
    selectAll(event: any) {
        this.ricetta.Ingredients.forEach((item: any) => item['selected'] = event.checked);
    }
    /***************************************************************************************************** */
    selectItem(event: any, item: any) {
        item['selected'] = event.checked;
    }
    /***************************************************************************************************** */
    changeVersionUsed(event: any, item: any) {
        item['IsCompanyVersion'] = event.checked;

    }
    /***************************************************************************************************** */
    selectAllCalcolo(event: any) {
        this.ricetta.Prices.forEach((item: any) => item['selected'] = event.checked);
    }
    /***************************************************************************************************** */
    openPopupComplement(ingredient: any) {

        if (!this.roleService.isCompanyAdminOrMore()) return;

        const dialogRef = this.dialog.open(InsertNotesComponent, {
            data: {
                note: ingredient.Complement,
                title: this.translate.instant('GESTIONE_RICETTE.EDIT.COMPLEMENTS_POPUP.TITLE'),
                subtitle: this.translate.instant('GESTIONE_RICETTE.EDIT.COMPLEMENTS_POPUP.SUBTITLE')
            },
            width: '600px',
            panelClass: 'InsertNotesComponent'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            //console.log('InsertNotesComponent', res);
            if (_.isEmpty(res) || !res) {
                return;
            }
            if (res.success) {
                ingredient.Complement = res.Note;
                this.ref.detectChanges();
                //this.table.columns.adjust().draw();
            }

        });
    }
    /***************************************************************************************************** */
    onInputChange(event: any, item: any, type: string) {
        if (event.keyCode !== 13) {
            item.invalidQuantity = false;
            this.inputChanged.next({
                item: item,
                type: type,
                event: event
            });
        }
    }
    /***************************************************************************************************** */
    onInputCalcoloChange(event: any, row: any) {
        if (event.keyCode !== 13) {
            if (this.allList && row) {
                this.ricetta.Prices.forEach((e: any) => {
                    e.PVImposto = row.PVImposto;
                });
            }
            this.inputCalcoloChanged.next(true);
        }
    }
    /***************************************************************************************************** */
    manageKeyDownPrice(event: any) {
        const keyCode = event.keyCode;
        if (keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
    /***************************************************************************************************** */
    manageKeyDownQty(event: any, origin: any) {
        const keyCode = event.keyCode;
        if (keyCode === 9) {
            event.preventDefault();
            event.stopPropagation();
            //console.log('manageKeyDownQty', event);
            const nextRow = $(event.target).closest('tr').index() + 2; // +1 per la riga successiva e +1 perchè nth-child parte da 1 e non da 0
            let nextEventRow = {
                target: $(`#tableIngredienti_${this.getRicettaCode()} tbody tr:nth-child(${nextRow})`).get(0)
            }
            //console.log($(event.target).closest('tr').index(), nextRow, nextEventRow);
            this.showAutocomplete(this.ricetta.Ingredients[this.ricetta.Ingredients.length - 1], nextEventRow);

            $(`#tableIngredienti_${this.getRicettaCode()} tr:last-child() #select2_productName input`).focus();
        }
    }
    /***************************************************************************************************** */
    @HostListener('document:fullscreenchange', ['$event'])
    @HostListener('document:webkitfullscreenchange', ['$event'])
    @HostListener('document:mozfullscreenchange', ['$event'])
    @HostListener('document:MSFullscreenChange', ['$event'])
    fullscreenmodes(event: any) {
        //console.log('fullscreenmodes', event);
        this.chkScreenMode();
    }
    /***************************************************************************************************** */
    chkScreenMode() {
        if (document.fullscreenElement) {
            //fullscreen
            this.isFullScreen = true;
        } else {
            //not in full screen
            $('kt-header').show();
            $('kt-aside-left').show();
            $('#kt_wrapper').css('padding-left', this.savedPaddingLeft);
            $('#kt_wrapper').css('padding-top', this.savedPaddingTop);
            this.isFullScreen = false;
        }
    }
    /***************************************************************************************************** */
    async askAddEditIngredient(model: any) {
        if (model.item.Id && !model.item.IsAddedd) {
            const _title: string = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_ADD_UPDATE_INGREDIENT.TITLE');
            const _description: string = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_ADD_UPDATE_INGREDIENT.DESC');
            const _waitDesciption: string = '';
            const _yesButton = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_ADD_UPDATE_INGREDIENT.UPDATE');
            const _yes2Button = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_ADD_UPDATE_INGREDIENT.ADD');
            const _noButton = this.translate.instant('COMMONS.CANCEL');
            const success = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_ADD_UPDATE_INGREDIENT.SUCCESS');

            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton, _yes2Button);

            dialogRef.afterClosed().subscribe(async (result: any) => {
                if (result === 2) { // Aggiungi nuovo

                    let originalIngredient = this.originalIngredients.find((i: any) => i.UniqueId == model.item.UniqueId);
                    let newIngredient = this.ricetta.Ingredients.find((i: any) => i.UniqueId == model.item.UniqueId);
                    this.performSubtituiteIngredient(originalIngredient, newIngredient);


                    //this.layoutUtilsService.showActionNotification(success, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                } else if (result) { // Update
                    await this.calcoloSingoloIngrediente(model.item, model.type, undefined);
                    await this.updateTotRicetta();
                    this.updateTabellaCalcolo()
                }
            });
        }
        else {
            await this.calcoloSingoloIngrediente(model.item, model.type, undefined);
            await this.updateTotRicetta();
            this.updateTabellaCalcolo()
        }
    }

    openImportIngredients() {

        this.gestioneRicetteService.getRecipeList({
            EnableState: 2,
            VisibleState: 1
        }, {
            page: 1,
            pageSize: 1000000,
            sort: 'Product',
            sortMode: 'Asc',
            TotalRows: 0
        }).toPromise().then(

            (recipes: any) => {

                const dialogRef = this.dialog.open(ImportIngredientsComponent, {
                    data: {
                        recipes: recipes.Subrecipes.concat(recipes.Rows)
                    },
                    width: '800px'
                });

                dialogRef.afterClosed().subscribe((res: any) => {
                    if (res && res.success && res.recipe) {
                        this.gestioneRicetteService.getSingleRecipe(res.recipe.Id).toPromise()
                            .then((recipe: any) => {
                                console.log(recipe);

                                this.hoverglassActive = true;
                                this.ricetta.Ingredients = recipe.Ingredients.filter((ing: any) => !ing.SubstituteId);
                                this.ricetta.FlatIngredients = recipe.FlatIngredients.filter((ing: any) => !ing.SubstituteId);
                                this.ricetta.Procedures = recipe.Procedures;

                                //Rimpiazzo lo scarto in base all'opzione scelta
                                this.ricetta.Ingredients.forEach((element: any) => {
                                    element.UniqueId = element.Type + "|" + element.Id + "|" + element.Position;
                                    //Dal DB lo scarto arriva sempre Netto
                                    element.wasteView = this.GetScarto(element.Waste, this.optScarto);

                                });

                                $(`#tableIngredienti_${this.getRicettaCode()}`).DataTable().destroy();
                                setTimeout(() => {
                                    this.initDataTable();
                                }, 500);
                            })
                    }
                });

            }

        )


    }

    /***************************************************************************************************** */
    savedPaddingLeft: any;
    savedPaddingTop: any;
    openFullscreen() {
        $('kt-header').hide();
        $('kt-aside-left').hide();
        this.savedPaddingLeft = $('#kt_wrapper').css('padding-left');
        $('#kt_wrapper').css('padding-left', '0px');
        this.savedPaddingTop = $('#kt_wrapper').css('padding-top');
        $('#kt_wrapper').css('padding-top', '0px');

        //console.log('openFullscreen');
        if (this.elem.requestFullscreen) {
            this.elem.requestFullscreen();
        } else if (this.elem.mozRequestFullScreen) {
            /* Firefox */
            this.elem.mozRequestFullScreen();
        } else if (this.elem.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            this.elem.webkitRequestFullscreen();
        } else if (this.elem.msRequestFullscreen) {
            /* IE/Edge */
            this.elem.msRequestFullscreen();
        }
    }
    /***************************************************************************************************** */
    closeFullscreen() {
        $('kt-header').show();
        $('kt-aside-left').show();
        $('#kt_wrapper').css('padding-left', this.savedPaddingLeft);
        $('#kt_wrapper').css('padding-top', this.savedPaddingTop);

        if (this.document.exitFullscreen) {
            this.document.exitFullscreen();
        } else if (this.document.mozCancelFullScreen) {
            /* Firefox */
            this.document.mozCancelFullScreen();
        } else if (this.document.webkitExitFullscreen) {
            /* Chrome, Safari and Opera */
            this.document.webkitExitFullscreen();
        } else if (this.document.msExitFullscreen) {
            /* IE/Edge */
            this.document.msExitFullscreen();
        }
    }
    /***************************************************************************************************** */
    async creaMerce() {

        const unit = this.staticCollectionsService.unit$.find((unit: any) => unit.Name === 'kg');
        const newCode = await this.gestioneMerciService.getFirstAvailableGoodCode().toPromise();
        let newItem: any = {
            AlternateName: '',
            AvgPrice: '',
            BaseUnitId: unit.MainUnitId,
            A: unit.A,
            B: unit.B,
            Category: '',
            CategoryId: '',
            Code: newCode,
            ExcludedByConsumption: false,
            Group: '',
            GroupId: '',
            InOut: 0,
            IsDisabled: false,
            IsFinished: false,
            IsFresh: false,
            IsHidden: false,
            LockPriceChange: false,
            Name: '',
            Note: '',
            OtherUnit: "",
            OtherUnitId: unit.Id,
            OtherUnitRatio: 1,
            Position: '',
            Price: '',
            PriceMode: "MasterPrice",
            Prices: [],
            ProductionRatio: 1,
            ProductionUnit: "Kg",
            ProductionUnitId: unit.Id,
            SecondaryUnit: "",
            SecondaryUnitId: unit.Id,
            SecondaryUnitRatio: 1,
            Suppliers: [],
            Unit: 'Kg',
            UnitId: unit.Id,
            VAT: 10,
            WastPercentage: 0
        };

        const orderUnit = this.staticCollectionsService.orderUnit$[0];

        this.staticCollectionsService.aziende$.forEach((company: any) => {
            newItem.Prices.push({
                Alias: '',
                Azienda: company.Name,
                IsMaster: false,
                IsVisible: true,
                OtherUnit: '',
                OtherUnitId: orderUnit.Id,
                OtherUnitRatio: 1,
                Price: '',
                PriceUM: '',
                PriceUO: '',
                SecondaryUnit: '',
                SecondaryUnitId: orderUnit.Id,
                SecondaryUnitRatio: 1,
                SupplierId: ''
            });
        });

        const dialogRef = this.dialog.open(EditMerciComponent, {
            data: {
                merce: newItem
            },
            width: '100%',
            height: '100%'
        });
    }
    /***************************************************************************************************** */
    yieldChanged() {
        this.showWeightButton = true;
        //Modifica tutti i FoodCost della ricetta per tutte le aziende
        this.updateTabellaCalcolo();
    }
    /***************************************************************************************************** */
    forzaDateInizio() {

        const subs = this.ricetta.Ingredients.filter((i: any) => i.SubstituteId > 0);
        if (subs && subs.length > 0) {

            const _title: string = this.translate.instant('GESTIONE_RICETTE.EDIT.FORZA_DATE_INIZIO.TITLE');
            const _description: string = this.translate.instant('GESTIONE_RICETTE.EDIT.FORZA_DATE_INIZIO.DESC_ERR');
            const _waitDesciption: string = '';
            const _yesButton = this.translate.instant('GESTIONE_RICETTE.EDIT.FORZA_DATE_INIZIO.OK_ERR');

            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton);

        } else {

            const dialogRef = this.dialog.open(ForzaDateInizioComponent, {
                data: {
                    title: this.translate.instant('GESTIONE_RICETTE.EDIT.FORZA_DATE_INIZIO.TITLE'),
                    subtitle: this.translate.instant('GESTIONE_RICETTE.EDIT.FORZA_DATE_INIZIO.DESC'),
                    forceBtn: this.translate.instant('GESTIONE_RICETTE.EDIT.FORZA_DATE_INIZIO.OK')
                },
                width: '600px',
                panelClass: 'ForzaDateInizioComponent'
            });
            dialogRef.afterClosed().subscribe((res: any) => {
                if (_.isEmpty(res) || !res) {
                    return;
                }
                if (res.success) {
                    this.ricetta.Ingredients.forEach((element: any) => {
                        element.FromDate = res.date;
                    });
                    this.ref.detectChanges();
                }

            });

        }

    }
    /***************************************************************************************************** */
    uploadImage(event) {

        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let file: File = fileList[0];
            let formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            this.gestioneRicetteService.uploadPhoto(formData).subscribe(
                (result) => {
                    if (!this.ricetta.Procedures || this.ricetta.Procedures.length === 0) {
                        this.ricetta.Procedures.push({ Message: '', Image: '' });
                    }
                    this.ricetta.Procedures[0].Image = result;
                }
            );
        }

        //INVOCARE api/Recipe/Photo
        //ritorna il nome del file da mattere in 
        //this.ricetta.Procedures[0].Image = img


    }
    /***************************************************************************************************** */
    getRecipeImage() {
        if (this.ricetta.Procedures && this.ricetta.Procedures.length > 0) {
            let img = this.ricetta.Procedures[0].Image;
            if (img) {
                return this.gestioneRicetteService.getRecipeImagePath() + '/' + img;
                //return  img;
            }
            else {
                return null;
            }

        }
        return null;
    }

    /***************************************************************************************************** */
    stampa(mode) {
        let lang = this.translationService.getSelectedLanguage();
        this.gestioneRicetteService.getRecipePDF(this.ricetta.Id, mode, lang).subscribe(
            (result: any) => {
                window.open(`${result.endpointURL}${result.link}`)
            }
        );


    }
    /***************************************************************************************************** */
    cancellaSostituti() {
        const _title: string = this.translate.instant('GESTIONE_RICETTE.EDIT.CANCELLA_SOSTITUTI.TITLE');
        const _description: string = this.translate.instant('GESTIONE_RICETTE.EDIT.CANCELLA_SOSTITUTI.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('GESTIONE_RICETTE.EDIT.CANCELLA_SOSTITUTI.OK');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                this.ricetta.Ingredients = this.ricetta.Ingredients.filter((i: any) => !i.SubstituteId);
                const message = this.translate.instant('GESTIONE_RICETTE.EDIT.CANCELLA_SOSTITUTI.SUCCESS');
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

            }
        });
    }
    /***************************************************************************************************** */
    async calcolaValoriDaResa() {
        const y = this.ricetta.Yield ? util.parseNumber(this.ricetta.Yield, this.locale) : 0;
        let ratio = y / this.initYield;
        if (this.ricetta.Ingredients && this.ricetta.Ingredients.length) {
            await Promise.all(this.ricetta.Ingredients.map(async (e: any) => {
                let q = e.Quantity ? util.parseNumber(e.Quantity, this.locale) : 0;
                e.Quantity = q * ratio;
                return this.calcoloSingoloIngrediente(e, 'Quantity', undefined);
            }));
        }
        this.showWeightButton = false;
        this.initYield = y;
    }
    /***************************************************************************************************** */
    calcoloResa() {
        let sum = 0;
        this.ricetta.Ingredients.forEach((ing: any) => {
            if (ing.SubstituteId > 0) {
                ;
            } else {
                let q = util.parseNumber(ing.Quantity, this.locale);
                if (q > 0) {
                    let yy = ing.Unit.Yield;
                    let uom = ing.Unit.Name.toLowerCase();
                    if (!['kg', 'g', 'l', 'ml', 'lt', 'gr'].includes(uom)) {
                        //Tutte le unità non trasformabili consiterate 1 grammo
                        yy = 0.001;
                    }
                    if (q > 0 && ing.Unit.Yield) sum = sum + q * yy;
                }

            }

        });
        this.ricetta.Yield = sum;
        this.initYield = sum;
        var u = this.unit$.find((x: any) => x.Name.toLowerCase() === 'kg');
        if (u) {
            this.ricetta.Unit.Id = u.Id;
            $(`#recipe_${this.getRicettaId()} .select2Unit`).val(this.ricetta.Unit.Id).trigger('change');
        }

        this.ricetta.Yield = util.formatNumber(this.ricetta.Yield, this.locale);
    }

    //ricalcolo() { }
    /***************************************************************************************************** */
    invalidName: boolean = false;
    invalidYield: boolean = false;
    checkSave() {
        let ret: boolean = true;

        if (!this.ricetta.Name) {
            ret = false;
            this.invalidName = true;
        } else {
            this.invalidName = false;
        }

        if (!this.ricetta.Yield || Number(this.ricetta.Yield) === 0) {
            ret = false;
            this.invalidYield = true;
        } else {
            this.invalidYield = false;
        }

        this.ricetta.Ingredients.forEach((ingredient: any) => {
            if (!ingredient.Quantity || Number(ingredient.Quantity) === 0) {
                ret = false;
                ingredient.invalidQuantity = true;
            } else {
                ingredient.invalidQuantity = false;
            }
        });

        return ret;
    }
    /***************************************************************************************************** */
    fixNumericFields(ricetta) {

        ricetta.AddedCost = ricetta.AddedCost ? util.parseNumber(ricetta.AddedCost, this.locale) : 0;
        ricetta.Factor = ricetta.Factor ? util.parseNumber(ricetta.Factor, this.locale) : 0;
        ricetta.Vat = ricetta.Vat ? util.parseNumber(ricetta.Vat, this.locale) : 0;
        ricetta.Yield = ricetta.Yield ? util.parseNumber(ricetta.Yield, this.locale) : 0;

        ricetta.Ingredients.forEach(e => {
            e.Quantity = e.Quantity ? util.parseNumber(e.Quantity, this.locale) : 0;
            e.Waste = e.Waste ? util.parseNumber(e.Waste, this.locale) : 0;
        });

        if (!ricetta.Prices) {
            ricetta.Prices = [];
        }
        ricetta.Prices.forEach(e => {
            e.Price = e.PVImposto ? util.parseNumber(e.PVImposto, this.locale) : 0;
        });
    }
    /***************************************************************************************************** */
    parseFileds(ricetta: any) {

        ricetta.AddedCost = util.formatNumber(ricetta.AddedCost, this.locale, 2, true);
        ricetta.Factor = util.formatNumber(ricetta.Factor, this.locale, 2, true);
        ricetta.Vat = util.formatNumber(ricetta.Vat, this.locale);
        ricetta.Yield = util.formatNumber(ricetta.Yield, this.locale);

        ricetta.Ingredients.forEach(e => {
            e.Quantity = util.formatNumber(e.Quantity, this.locale);
            e.Waste = util.formatNumber(e.Waste, this.locale);
        });

        if (!ricetta.Prices) {
            ricetta.Prices = [];
        }
        ricetta.Prices.forEach(e => {
            e.Price = util.formatNumber(e.PVImposto, this.locale);
            e.PVImposto = util.formatNumber(e.PVImposto, this.locale);
        });
    }

    /***************************************************************************************************** */
    /**
     * SAVE
     */
    save() {

        if (!this.checkSave()) {
            const message = this.translate.instant('GESTIONE_RICETTE.SAVE_DIALOG.MISSING_FIELDS');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-error');
        } else {

            if (JSON.stringify(this.ricetta) == this.initialObject) {
                this.goBack(null);
            }
            this.ricetta.Ingredients.forEach((element: any) => {
                let wasteView = element.wasteView ? util.parseNumber(element.wasteView, this.locale) : 0;
                element.Waste = this.GetDBScarto(wasteView, this.optScarto);
            });
            if (this.ricetta.Id) {
                //UPDATE
                const _title: string = this.translate.instant('GESTIONE_RICETTE.UPDATE_DIALOG.TITLE');
                const _description: string = this.translate.instant('GESTIONE_RICETTE.UPDATE_DIALOG.DESC');
                const _waitDesciption: string = '';
                const _yesButton = this.translate.instant('GESTIONE_RICETTE.UPDATE_DIALOG.BUTTON');
                const _noButton = this.translate.instant('COMMONS.CANCEL');

                const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
                dialogRef.afterClosed().subscribe((expand: any) => {
                    if (expand) {
                        this.loading$.next(true);
                        this.fixNumericFields(this.ricetta)

                        this.gestioneRicetteService.updateRecipe(this.ricetta).subscribe(
                            (result: any) => {
                                this.loading$.next(false);

                                let message: string = '';
                                if (result.SavedRecipeId) {
                                    message = this.translate.instant('GESTIONE_RICETTE.UPDATE_DIALOG.SUCCESS');
                                    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                                    this.goBack(this.ricetta);

                                    this.initialObject = JSON.stringify(this.ricetta);
                                } else {
                                    message = this.translate.instant('GESTIONE_RICETTE.UPDATE_DIALOG.ERROR');
                                    this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                                }
                                this.parseFileds(this.ricetta);
                                //this.goBack(null);
                            }, (error: any) => {
                                this.loading$.next(false);
                                this.parseFileds(this.ricetta);
                            }
                        );
                    }
                });
            }
            else {
                //SAVE
                const _title: string = this.translate.instant('GESTIONE_RICETTE.SAVE_DIALOG.TITLE');
                const _description: string = this.translate.instant('GESTIONE_RICETTE.SAVE_DIALOG.DESC');
                const _waitDesciption: string = '';
                const _yesButton = this.translate.instant('GESTIONE_RICETTE.SAVE_DIALOG.BUTTON');
                const _noButton = this.translate.instant('COMMONS.CANCEL');

                const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
                dialogRef.afterClosed().subscribe((expand: any) => {
                    if (expand) {
                        this.loading$.next(true);
                        this.fixNumericFields(this.ricetta)

                        this.gestioneRicetteService.saveRecipe(this.ricetta).subscribe(
                            (result: any) => {
                                this.loading$.next(false);

                                let message: string = '';
                                if (result.SavedRecipeId) {
                                    message = this.translate.instant('GESTIONE_RICETTE.SAVE_DIALOG.SUCCESS');
                                    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                                    this.initialObject = JSON.stringify(this.ricetta);
                                    this.goBack(this.ricetta);
                                } else {
                                    message = this.translate.instant('GESTIONE_RICETTE.SAVE_DIALOG.ERROR');
                                    this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                                }
                                this.parseFileds(this.ricetta);
                                // this.goBack(null);
                            }, (error: any) => {
                                this.loading$.next(false);
                                this.parseFileds(this.ricetta);
                            }
                        );
                    }
                });
            }



        }

    }

    /***************************************************************************************************** */

    getSemilavoratoName() {
        return this.ricetta.HalfWorked ? this.ricetta.HalfWorked.Name : null;

    }

    selectSemilavorato(event: any) {
        if (!this.ricetta.HalfWorked) {
            this.ricetta.HalfWorked = {};
        }
        let item = event.option.value;
        this.ricetta.HalfWorked.Id = item.Id;
        this.ricetta.HalfWorked.Name = item.Name;
    }

    cleanSemiLavorato() {
        this.ricetta.HalfWorked = null;
        this.semilavoratoCollegato.setValue(undefined);
    }
    /***************************************************************************************************** */
    cleanItem(item) {
        const _title: string = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_DELETE_INGREDIENT.TITLE');
        const _description: string = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_DELETE_INGREDIENT.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_DELETE_INGREDIENT.UPDATE');
        const _yes2Button = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_DELETE_INGREDIENT.ADD');
        const _noButton = this.translate.instant('COMMONS.CANCEL');
        const success = this.translate.instant('GESTIONE_RICETTE.EDIT.ASK_DELETE_INGREDIENT.SUCCESS');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton, _yes2Button);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result === 2) { // Elimina e mantieni

                item.SubstituteId = 99999;
                item.ToDate = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');

            } else if (result) { // Elimina del tutto
                this.ricetta.Ingredients = this.ricetta.Ingredients.filter((x: any) => x.UniqueId != item.UniqueId);


            }

            $(`#tableIngredienti_${this.getRicettaCode()}`).DataTable().destroy();
            setTimeout(() => {
                this.initDataTable();

            }, 1000);


        });


    }
    /***************************************************************************************************** */

    openMerce(ingredient: any, row: number) {
        if (ingredient.Type === 'Goods') {
            this.gestioneMerciService.getSingleGoods(ingredient.Id).subscribe(
                (result: any) => {
                    const dialogRef = this.dialog.open(EditMerciComponent, {
                        data: {
                            merce: result
                        },
                        width: '100%',
                        height: '100%'
                    });

                    dialogRef.afterClosed().subscribe(async (res: any) => {
                        if (_.isEmpty(res) || !res) {
                            return;
                        }

                        ingredient.Goods.AlternateName = res.AlternateName;
                        ingredient.Goods.Name = res.Name;
                        ingredient.Goods.Price = res.Price;
                        ingredient.Goods.UnitName = res.Unit.Name;
                        ingredient.Goods.Code = res.Code;
                        ingredient.Goods.Id = res.Id;


                        await this.calcoloSingoloIngrediente(ingredient, undefined, undefined)

                        this.table.row(row).invalidate().draw();
                        this.ref.detectChanges();
                    });
                }
            );
        } else if (ingredient.Type === 'Recipe') {
            this.gestioneRicetteService.getSingleRecipe(ingredient.Id).subscribe(
                (result: any) => {
                    const dialogRef = this.dialog.open(EditRicettaComponent, {
                        data: {
                            ricetta: result
                        },
                        width: '100%',
                        height: '100%'
                    });

                    dialogRef.afterClosed().subscribe(async (res: any) => {
                        if (_.isEmpty(res) || !res) {
                            return;
                        }

                        ingredient.Goods.Name = res.Name;
                        ingredient.Goods.Price = res.Price;
                        ingredient.Goods.UnitName = res.Unit.Name;
                        ingredient.Goods.Code = res.Code;

                        await this.calcoloSingoloIngrediente(ingredient, undefined, undefined)

                        this.table.row(row).invalidate().draw();
                        this.ref.detectChanges();
                    });
                }
            );

        }
    }

    newCategory() {
        const item = {
            Id: ''
        };
        let saveMessageTranslateParam = 'ANAGRAFICHE.CATEGORIE_MERCI.ACTION_EDIT.ADD_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = MessageType.Create;
        const dialogRef = this.dialog.open(CategorieProdottiEditComponent, { data: { item }, width: '800px' });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
            this.categorieProdottiService.getCategorieProdotti().toPromise().then((categorie: any) => {
                this.categorieProdotti$ = categorie;
                this.ricetta.Category.Id = res.item.Id;

                this.onChangeCategory(this.categorieProdotti$.find((c: any) => c.Id == res.item.Id));
            });

        });
    }

    newDepartment() {
        const item = {
            Id: ''
        };
        let saveMessageTranslateParam = 'ANAGRAFICHE.CATEGORIE_MERCI.ACTION_EDIT.ADD_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = MessageType.Create;
        const dialogRef = this.dialog.open(RepartiEditComponent, { data: { item }, width: '800px' });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);

            this.anagraficheService.getEntity('Department').toPromise().then((reparti: any) => {
                this.repartiMagazzinoObserv$ = reparti;
                this.ricetta.Department.Id = res.item.Id;
            });

        });
    }

    /***************************************************************************************************** */
    ChangeIncidence() {
        // switch (this.ricetta.Incidence) {
        //   case "1":
        //     this.ricetta.Factor = 2.5;
        //     break;
        //   case "2":
        //     this.ricetta.Factor = 3.1;
        //     break;
        //   case "3":
        //     this.ricetta.Factor = 3.8;
        //     break;
        // }
        this.ricetta.Factor = this.ricetta.Incidence;
        this.updateTabellaCalcolo();
    }


    /******************************************************************************
      ______                       _    __   ___       _______   __
     |  ____|                     | |   \ \ / / |     / ____\ \ / /
     | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
     |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
     | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
     |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
         | |
         |_|
    ******************************************************************************/
    exportAsXLSX() {
        this.loading$.next(true);
        let xlsx: any[] = [];
        let merges: any[] = [];
        let i: number = 0;
        let sortedList = this.utility.sortList(this.ricetta.Prices, 'tableCalcolo');
        let rowsStyles: any = [];
        sortedList.forEach((item: any) => {

            if (i % 2 === 0) {
                rowsStyles[i] = {
                    fgColor: { rgb: 'e6edfb' }
                };
            }

            let columns: any = {};

            columns[`${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.AZIENDA')}`] = item.Company ? item.Company : '';
            columns[`${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.FC_NETTO')}`] = this.totRicetta ? this.utility.formatNumberExcel(this.totRicetta, 2) : '';
            columns[`${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.FC_LORDO')}`] = item.FoodCostLordo ? this.utility.formatNumberExcel(item.FoodCostLordo, 2) : '';
            columns[`${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.PV_TEORICO')}`] = item.PvTeorico ? this.utility.formatNumberExcel(item.PvTeorico, 2) : '';
            columns[`${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.MDC_TEORICO')}`] = item.MdcTeorico ? this.utility.formatNumberExcel(item.MdcTeorico, 2) : '';
            columns[`${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.FC_TEORICO')}`] = item.FcTeorico ? this.utility.formatNumberExcel(item.FcTeorico, 2) : '';
            columns[`${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.PV_IMPOSTO')}`] = item.PVImposto ? this.utility.formatNumberExcel(this.utility.parseNumber(item.PVImposto, this.locale), 2) : '';
            columns[`${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.MDC_IMPOSTO')}`] = item.MdcImposto ? this.utility.formatNumberExcel(item.MdcImposto, 2) : '';
            columns[`${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.FC_IMPOSTO')}`] = item.FCImposto ? this.utility.formatNumberExcel(item.FCImposto, 2) : '';
            columns[`${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.FATTORE_IMPOSTO')}`] = item.FattoreImposto ? this.utility.formatNumberExcel(item.FattoreImposto, 2) : '';

            xlsx.push(columns);
            i++;
        });

        this.loading$.next(false);
        this.excelService.exportAsExcelFile(xlsx, 'editrecipe', merges, [], 1, rowsStyles, true);
    }


    /******************************************************************************
        ______                       _     _____  _____  ______
       |  ____|                     | |   |  __ \|  __ \|  ____|
       | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
       |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
       | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
       |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
             | |
             |_|
    ******************************************************************************/
    exportAsPDF() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.getTitle() }),
                    //period: ''
                }
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                }
                result['language'] = this.translationService.getSelectedLanguage();
                result['table'].headerRows = 1;
                this.pdfService.makePdf(result, this.getPDFTableBody(), ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']); //Array(10).fill('auto')
            };
        });

    }

    getPDFTableBody() {
        let body: any = [];
        let tmpRow: any = [];

        // aggiungo intestazione
        // totali Header
        tmpRow = [
            { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.AZIENDA')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.FC_NETTO')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.FC_LORDO')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.PV_TEORICO')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.MDC_TEORICO')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.FC_TEORICO')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.PV_IMPOSTO')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.MDC_IMPOSTO')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.FC_IMPOSTO')}`, style: 'tableHeaderStyle' },
            { visible: true, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('GESTIONE_RICETTE.EDIT.ACCORD_CALCOLO.COLUMNS.FATTORE_IMPOSTO')}`, style: 'tableHeaderStyle' },
        ];
        body.push(tmpRow.filter((item: any) => item.visible));

        let i: number = 0;
        let sortedList = this.utility.sortList(this.ricetta.Prices, 'table');
        sortedList.forEach((item: any) => {
            tmpRow = [
                { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Company ? item.Company : ' ', style: 'tableBodyStyle' },
                { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: this.totRicetta ? this.utility.formatNumber(this.totRicetta) : ' ', style: 'tableBodyStyle' },
                { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.FoodCostLordo ? this.utility.formatNumber(item.FoodCostLordo) : ' ', style: 'tableBodyStyle' },
                { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.PvTeorico ? this.utility.formatNumber(item.PvTeorico) : ' ', style: 'tableBodyStyle' },
                { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.MdcTeorico ? this.utility.formatNumber(item.MdcTeorico) : ' ', style: 'tableBodyStyle' },
                { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.FcTeorico ? this.utility.formatNumber(item.FcTeorico) : ' ', style: 'tableBodyStyle' },
                { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.PVImposto ? this.utility.formatNumber(this.utility.parseNumber(item.PVImposto, this.locale)) : ' ', style: 'tableBodyStyle' },
                { visible: true, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.MdcImposto ? this.utility.formatNumber(item.MdcImposto) : ' ', style: 'tableBodyStyle' },
                { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.FCImposto ? this.utility.formatNumber(item.FCImposto) : ' ', style: 'tableBodyStyle' },
                { visible: true, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.FattoreImposto ? this.utility.formatNumber(item.FattoreImposto) : ' ', style: 'tableBodyStyle' },
            ];
            body.push(tmpRow.filter((item: any) => item.visible));
            i++;
        });

        return body;
    }

}
