import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { RoleService } from '@app/core/_base/layout/services/role.service';

@Component({
    selector: 'kt-blocco-inventari',
    templateUrl: './blocco-inventari.component.html',
    styleUrls: ['./blocco-inventari.component.scss']
})
export class BloccoInventariComponent implements OnInit {

    viewLoading: boolean = false;
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    inventoryDates: any;
    selectedDate: any;
    prevSelectedDate: any;
    originalMap = {};
    table: any;

    constructor(
        public roleService: RoleService,
        private srv: MagazzinoService,
        private translate: TranslateService,
        private layoutUtilsService: LayoutUtilsService) { }

    ngOnInit() {

        this.srv.getInvetoryDates().toPromise().then((r: any) => {
            if (r && r.length > 0) {
                this.inventoryDates = r;
                this.selectedDate = r[0];

                r.forEach((x: any) => {
                    this.originalMap[x.Date] = JSON.stringify(x.InventoryLocks);
                });
            }

            setTimeout(() => {
                this.table = $('#tableBloccoInventari').DataTable({
                    scrollY: '300px',
                    scrollCollapse: true,
                    paging: false,
                    language: {
                        search: this.translate.instant('MAGAZZINO.INVENTARI.BLOCCO_INVENTARI.SEARCH')
                    }
                });
            }, 100);

        });
    }

    onSubmit() {
        let savingObj: any = [];
        this.inventoryDates.forEach((x: any) => {
            if (this.originalMap[x.Date] !== JSON.stringify(x.InventoryLocks)) {
                //Is Modified
                savingObj.push(x);
            }

        });
        this.srv.lockInventory(savingObj).toPromise().then((r: any) => {
            this.prevSelectedDate = this.selectedDate;
            let message = this.translate.instant('MAGAZZINO.MODIFICHE.MODIFICA_OK');
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
            if (r && r.length > 0) {
                this.inventoryDates = r;
                this.selectedDate = r.find((x: any) => x.Date === this.selectedDate.Date);

                r.forEach((x: any) => {
                    this.originalMap[x.Date] = JSON.stringify(x.InventoryLocks);
                });
            }

        });
    }
}
