declare var $: any;


export function parseNumber(n: any, locale: string, decimal: any = 2) {
    if (!n) return 0;

    if (typeof n === 'string' || n instanceof String) {
        const ds: any = getDecimalSeparator(locale);
        let ts = (ds === '.') ? ',' : '.';
        const formatted: number = Number(n.replace(ts, '').replace(ds, '.'));
        return decimal ? parseFloat(formatted.toFixed(decimal)) : formatted;
    }
    else {
        return decimal ? parseFloat(n.toFixed(decimal)) : Number(n);
    }
}

export function getDecimalSeparator(locale) {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(locale)?.
        formatToParts(numberWithDecimalSeparator)?.
        find(part => part.type === 'decimal')?.
        value;
}

import * as numeral from 'numeral';

export function kFormatter(num: any, locale: string, decimal: boolean = true) {
    const number = numeral(num);
    const currencyCode = locale == 'en' ? ' £' : ' €';
    if (decimal) {
        return number.format('0.0a') + ' ' + currencyCode;
    } else {
        return number.format('0a') + ' ' + currencyCode;
    }

}

export function getSeparator(locale, separatorType = 'decimal') {
    const numberWithGroupAndDecimalSeparator = 1000.1;
    return Intl.NumberFormat(locale)?.
        formatToParts(numberWithGroupAndDecimalSeparator)?.
        find(part => part.type === separatorType)?.
        value;
}

export function formatNumber(x: any, locale: string, decimal: number = 2, showZero: boolean = true, currency: boolean = false) {
    // Converti in numero se è una stringa
    if (typeof x === 'string') {
        x = parseNumber(x, locale);
    }

    if (isNaN(x) || x === undefined || x === null) {
        x = 0;
    }

    if (!showZero && x == 0) x = 0;

    // Arrotonda con toFixed e converte di nuovo in numero
    x = parseFloat(x.toFixed(decimal));

    let formatted = '';

    if (currency) {
        formatted = x.toLocaleString(locale, {
            style: 'currency',
            currency: locale == 'en' ? 'GBP' : 'EUR',
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal
        });
    } else {
        formatted = x.toLocaleString(locale, {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal
        });
    }
    
    return formatted;
}


//export function formatNumber(x: any, locale: string, decimal: number = 2, showZero: boolean = true, currency: boolean = false) {
//    if (String(x).includes('NaN')) x = 0;
//    if (x === undefined || x === null) x = 0;
//    if (!showZero && x == 0) x = 0;

//    if (typeof x === 'string') {
//        x = parseNumber(x, locale);
//    }

//    x = parseFloat(x.toFixed(decimal));

//    //x = x.toString().replace(',', '.');

//    // Truncate to the desired number of decimal places
//    x = Math.floor(x * Math.pow(10, decimal)) / Math.pow(10, decimal);

//    if (currency) {
//        return Number(x).toLocaleString(locale, {
//            style: 'currency',
//            currency: locale == 'en' ? 'GBP' : 'EUR',
//            minimumFractionDigits: decimal,
//            maximumFractionDigits: decimal
//        });

//    } else {

//        return Number(x).toLocaleString(locale, {
//            minimumFractionDigits: decimal,
//            maximumFractionDigits: decimal
//        });

//    }
//}

export function formatNumberExcel(x: number, decimal: number = 2): number {
    if (!x || String(x).includes('NaN')) { return 0.00 };
    const res = Math.round((x + Number.EPSILON) * 100) / 100;
    return res ? res : 0.00;
}

export function getLocaleData(date: string, locale: string) {
    const event = new Date(date);
    if (event.getFullYear() > 1900) {
        const options = {};
        return event.toLocaleDateString(locale, options);
    }
    else {
        return '--/--/----';
    }

}

export function select2All(ev: any, idElement: string, returnType: string = '') {
    if (ev.checked) {
        (<any>$(`#${idElement}`)).select2('destroy').find('option').prop('selected', 'selected').end().select2();
    } else {
        (<any>$(`#${idElement}`)).select2('destroy').find('option').prop('selected', false).end().select2();
    }
    if (!returnType) {
        return $(`#${idElement}`).val();
    } else {
        let ret: any = [];
        (<any>$(`#${idElement}`)).select2('data').forEach((element: any) => {
            ret.push({
                Id: element.id,
                Name: element.text
            })
        });
        return ret;
    }
}

export function sortList(list: any, table: any, allData: any) {
    let currentOrderId: any = [];
    $(`#${table} tbody tr`).each((index: number, tr: any) => {
        let itemId = $(tr).data('itemid');
        let find = list.find((item: any) => item && itemId && item.Id.toString() === itemId.toString());
        if (find) currentOrderId.push(find);
    })
    if (currentOrderId.length === 0) {
        console.log('NON E\' STATO POSSIBILE ORDINARE, MANCA item.Id');
        return list;
    }
    return currentOrderId;
}

export function insertAndShift(arr, from, to) {
    let cutOut = arr.splice(from, 1)[0]; // cut the element at index 'from'
    arr.splice(to, 0, cutOut);            // insert it at index 'to'
    return arr;
}

/********************************************* */

export function generateRaimbow(index: number) {

    const raimbow: string[] = [
        '#A6CFE3',
        '#2077B4',
        '#B1DF89',
        '#31A02C',
        '#FB9A98',
        '#E31B1B',
        '#FDBE6E',
        '#FF7F00',
        '#CAB2D5',
        '#6A3D9A',
        '#FEFF99',
        '#B25A28',
        '#34BFA3',
        '#36A3F7',
        '#FFB822'
    ];

    return index > raimbow.length ? raimbow[raimbow.length - 1] : raimbow[index];
}

export function capitalize(word: string) {
    return word[0].toUpperCase() + word.substring(1);
}