import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { FiltriService } from '@app/core/services/filtri.service';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { TranslateService } from '@ngx-translate/core';
// Layout
import { LayoutConfigService } from '@app/core/_base/layout';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

// Material
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { RepartiEditComponent } from '@app/views/pages/anagrafiche/reparti/reparti-edit/reparti-edit.component';

import * as _ from 'lodash';

import { RoleService } from '@app/core/_base/layout/services/role.service';

import * as util from '@app/core/services/utilityfunctions';

@Component({
    selector: 'kt-reparti',
    templateUrl: './reparti.component.html',
    styleUrls: ['./reparti.component.scss', '../common-styles.scss']
})
export class RepartiComponent implements OnInit, OnDestroy {

    lista: any;
    listaSubscribtions!: Subscription;
    switchGroupSubcription!: Subscription;

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    dataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    table: any;
    currentFilterCfg: any;

    utility: any;
    constructor(
        private dialog: MatDialog,
        private filtriService: FiltriService,
        private anagraficheService: AnagraficheService,
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private layoutConfigService: LayoutConfigService,
        private excelService: ExcelService,
        private pdfService: PDFService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private switchGroupService: SwitchGroupService,
        public roleService: RoleService
    ) {
        this.utility = util;
    }

    ngOnInit() {
        document.body.classList.add('kt-aside--minimize');
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.refreshTable();
                }
            }
        );
        this.refreshTable();
    }

    ngOnDestroy() {
        this.switchGroupSubcription.unsubscribe();
        if (this.listaSubscribtions) this.listaSubscribtions.unsubscribe();
    }

    getTitle() {
        return this.translate.instant('ANAGRAFICHE.REPARTI.TITLE');
    }

    refreshTable() {
        if (this.listaSubscribtions) this.listaSubscribtions.unsubscribe();
        this.loading$.next(true);
        this.filtriService.readOnly$.next(true);
        if ($.fn.dataTable.isDataTable('#table')) {
            $('#table').DataTable().destroy();
        }
        this.listaSubscribtions = this.anagraficheService.getEntity('Department')
            .subscribe((ret: any) => {
                console.log('lista', ret);
                this.lista = ret;
                this.loading$.next(false);
                this.filtriService.readOnly$.next(false);
                this.initTable();
            });
    }

    initTable() {
        setTimeout(() => {
            let dtOptions: any = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: false,
                scrollY: 'calc(100vh - 300px)',
                scrollCollapse: true,
                columnDefs: [
                    { targets: [0], width: '100px' }
                ]
            };
            this.table = $('#table').DataTable(dtOptions);
            this.table.columns.adjust().draw();
        }, 100);
    }

    /** ACTIONS */
    deleteEntityItem(_item: any) {
        const _title: string = this.translate.instant('ANAGRAFICHE.REPARTI.ACTION_DELETE.TITLE');
        const _description: string = this.translate.instant('ANAGRAFICHE.REPARTI.ACTION_DELETE.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('ANAGRAFICHE.REPARTI.ACTION_DELETE.WAIT_DESCRIPTION');
        const _deleteMessage = this.translate.instant('ANAGRAFICHE.REPARTI.ACTION_DELETE.MESSAGE');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe((res: any) => {
            if (!res) {
                return;
            }

            this.anagraficheService.deleteEntity('Department', _item).subscribe(
                (ret: any) => {
                    this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
                    this.refreshTable();
                }, (error: any) => {
                    console.log(error);
                }
            )
        });
    }

    addEntityItem() {
        const newEntityItem = {
            Id: ''
        };
        this.editEntityItem(newEntityItem);
    }

    editEntityItem(item: any) {
        let saveMessageTranslateParam = 'ANAGRAFICHE.REPARTI.ACTION_EDIT.';
        saveMessageTranslateParam += item.Id > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = item.Id.length > 0 ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(RepartiEditComponent, { data: { item }, width: '800px' });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
            this.refreshTable();
        });
    }

    /******************************************************************************
      ______                       _    __   ___       _______   __
     |  ____|                     | |   \ \ / / |     / ____\ \ / /
     | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
     |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
     | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
     |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
           | |
           |_|
    ******************************************************************************/

    exportAsXLSX() {

        this.loading$.next(true);
        let xlsx: any[] = [];
        let merges: any[] = [];
        let i: number = 0;
        let sortedList = this.utility.sortList(this.lista, 'table');
        sortedList.forEach((item: any) => {
            //this.lista.forEach((item: any) => {
            xlsx.push({
                [`${this.translate.instant('ANAGRAFICHE.REPARTI.COLUMNS.CODE')}`]: item.Code ? item.Code.toUpperCase() : '',
                [`${this.translate.instant('ANAGRAFICHE.REPARTI.COLUMNS.NAME')}`]: item.Name ? item.Name.toUpperCase() : ''
            });
            i++;
        });
        this.loading$.next(false);
        const columnsInfo = [
            { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } }
        ];
        this.excelService.exportAsExcelFile(xlsx, 'departments', merges, columnsInfo, 1, [], true);
    }

    /******************************************************************************
      ______                       _     _____  _____  ______
     |  ____|                     | |   |  __ \|  __ \|  ____|
     | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
     |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
     | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
     |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
                 | |
                 |_|
    ******************************************************************************/

    exportAsPDF() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.getTitle() }),
                    //period: ''
                }
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                }
                result['language'] = this.translationService.getSelectedLanguage();
                result['table'].headerRows = 1;
                this.pdfService.makePdf(result, this.getPDFTableBody(), ['auto', '*'])
            };
        });

    }

    getPDFTableBody() {
        let body: any = [];

        // aggiungo intestazione
        // totali Header
        body.push([
            { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANAGRAFICHE.REPARTI.COLUMNS.CODE')}`, style: 'tableHeaderStyle' },
            { alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('ANAGRAFICHE.REPARTI.COLUMNS.NAME')}`, style: 'tableHeaderStyle' },
        ]);

        let i: number = 0;
        let sortedList = this.utility.sortList(this.lista, 'table');
        sortedList.forEach((item: any) => {
            //this.lista.forEach((item: any) => {
            body.push([
                { alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Code ? item.Code.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Name ? item.Name.toUpperCase() : ' ', style: 'tableBodyStyle' },
            ]);
            i++;
        });

        return body;
    }

}
