import { Component, OnInit } from '@angular/core';
import { NavigationService } from '@app/core/services/navigation.service';

@Component({
    selector: 'kt-shortcut-date-filter',
    templateUrl: './shortcut-date-filter.component.html',
    styleUrls: ['./shortcut-date-filter.component.scss']
})
export class ShortcutDateFilterComponent implements OnInit {

    constructor(
        public navigationService: NavigationService
    ) { }

    ngOnInit(): void {
    }

    backGroundStyle() {
        return {}
    }

}
