import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

import * as utils from '@app/core/services/utilityfunctions';
import { FornitoriEditComponent } from '@app/views/pages/anagrafiche/fornitori/fornitori-edit/fornitori-edit.component';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ScadenziarioService } from '../scadenziario.service';
import { lastValueFrom } from 'rxjs';
import moment from 'moment';

@Component({
    selector: 'kt-scadenziario-edit',
    templateUrl: './scadenziario-edit.component.html',
    styleUrls: ['./scadenziario-edit.component.scss']
})
export class ScadenziarioEditComponent implements OnInit {

    // Selezione tipo utente
    accountTypeDlg: number = 1;
    accountList: any;
    selectedAccount: any;

    scadenza: any;
    period: boolean = false;
    frequency: number = 0;
    banksList: any;
    bankOperationsList: any;
    paymentModalityList: any;
    paymentModality: any;
    bankOperation: any;
    bank: any;
    amount: number = 0;
    selectedPeriod: any;
    utility: any;
    dates: any;
    description!: string;
    company!: string;
    locale!: string;
    isEdit: boolean = false;

    @Output() onLoading = new EventEmitter<boolean>(false);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public staticCollectionsService: StaticCollectionsService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private layoutUtilsService: LayoutUtilsService,
        private ref: ChangeDetectorRef,
        private scadenziarioService: ScadenziarioService,
        public dialogRef: MatDialogRef<any>
    ) {
        this.utility = utils;
    }

    async ngOnInit() {
        this.banksList = this.data.banksList;
        this.bankOperationsList = this.data.bankOperationsList;
        this.paymentModalityList = this.data.paymentModalityList;
        this.scadenza = this.data.scadenza;
        this.locale = this.data.locale;

        console.log('scadenza', this.scadenza);

        this.isEdit = this.scadenza && this.scadenza.Id ? true : false;

        if (this.isEdit) {

            this.accountTypeDlg = this.scadenza.Type;
            switch (this.accountTypeDlg) {
                case 1:
                    this.accountList = this.staticCollectionsService.fornitori$;
                    break;
                case 2:
                    this.accountList = this.staticCollectionsService.clientiTutti$;
                    break;
                case 4:
                    this.accountList = this.staticCollectionsService.dipendentiTutti$;
                    break;
            }

            this.period = false;
            this.frequency = 0;
            this.amount = this.scadenza.Credit > 0 ? this.scadenza.Credit : this.scadenza.Debit;
            this.description = this.scadenza.Description;

            this.amount = this.utility.formatNumber(this.amount, this.locale);

            this.dates = [{
                date: this.scadenza.Date,
                perc: 0,
                amount: this.scadenza.Credit > 0 ? this.scadenza.Credit : this.scadenza.Debit,
                Id: this.scadenza.Id
            }];

            this.selectedPeriod = {
                startDate: moment(this.scadenza.Date),
                endDate: moment(this.scadenza.Date)
            };

            // Inizializzo i model delle select
            setTimeout(() => {
                this.bank = this.scadenza.BankId;
                this.bankOperation = this.scadenza.OperationId;
                this.selectedAccount = this.scadenza.SupplierId;
                this.company = this.scadenza.Store;
                this.paymentModality = this.scadenza.PaymentModality;
            }, 100);
        } else {
            this.accountList = this.staticCollectionsService.fornitori$;
        }
    }

    changeAccountType(eventy: any) {
        this.selectedAccount = undefined;
        this.accountList = undefined;
        setTimeout(() => {
            switch (eventy) {
                case 1:
                    this.accountList = this.staticCollectionsService.fornitori$;
                    break;
                case 2:
                    this.accountList = this.staticCollectionsService.clientiTutti$;
                    break;
                case 4:
                    this.accountList = this.staticCollectionsService.dipendentiTutti$;
                    break;
            }
        }, 100);
    }

    getPlaceholder() {
        let placeholder = "";
        switch (this.accountTypeDlg) {
            case 1:
                placeholder = this.translate.instant('FILTRI.FORNITORE_PLACEHOLDER');
                break;
            case 2:
                placeholder = this.translate.instant('FILTRI.CLIENTE_PLACEHOLDER');
                break;
            case 4:
                placeholder = this.translate.instant('FILTRI.DIPENDENTE_PLACEHOLDER');
                break;
        }

        return placeholder;
    }

    addNewAccount() {
        const item = {
            Id: '',
            SubjectType: this.accountTypeDlg
        };
        let saveMessageTranslateParam = 'ANAGRAFICHE.FORNITORI.ACTION_EDIT.ADD_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = MessageType.Create;
        const dialogRef = this.dialog.open(FornitoriEditComponent, { data: { item }, width: '800px' });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            //this.ref.detectChanges();
            this.staticCollectionsService.fornitori$ = undefined;
            setTimeout(() => {
                this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
                this.staticCollectionsService.fillStaticCollection(true).then(() => {
                    console.log(this.staticCollectionsService.fornitori$);
                    this.selectedAccount = res.item.toString();
                    this.ref.detectChanges();
                });
            }, 1000);
        });
    }

    checkDisabled() {
        return !this.bank || !this.bankOperation || !this.amount || !this.selectedPeriod || !this.selectedAccount || !this.company;
    }

    getAccountSettings(accountId: string) {
        if (accountId) {
            const account = this.accountList.find((account: any) => account.Id == accountId);
            if (account.EnableScadenziario) {
                this.bank = account.BankId;
                this.bankOperation = account.OperationId;
                this.period = account.PaymentType == 0 ? true : false;
                this.paymentModality = account.PaymentModalityId;

                if (!this.period && this.paymentModality) {
                    this.setDateByModality();
                }
            }
        }
    }

    setDateByModality() {
        if (this.paymentModality) {
            const modality = this.paymentModalityList.find((modality: any) => modality.Id == this.paymentModality);
            /*
                modality format example:
                {
                    Delay: 30,
                    EndMonth: true,
                    Fixed: false,
                    FixedDate: false,
                    FixedDay: false,
                    Id: 'e08a7ae7-8365-4f9d-a8b2-5a865a4ccbfa',
                    Name: '30 gg fm'
                }
            */
            if (modality && !modality.Fixed && !modality.FixedDate && !modality.FixedDay) {
                const delay = modality.Delay;
                const endDate = moment().add(delay, 'days');
                if(modality.EndMonth) {
                    endDate.endOf('month');
                }
                this.selectedPeriod = {
                    startDate: endDate
                };

                this.performDistribution();
            }
 
        }
    }

    getTitle() {
        let title = 'Nuovo Scadenziario';

        return this.scadenza.Id ? this.translate.instant('SCADENZIARIO.EDIT_SCADENZIARIO.EDIT') : this.translate.instant('SCADENZIARIO.EDIT_SCADENZIARIO.ADD_NEW');
    }

    performDistribution() {

        if (!this.selectedPeriod) return;

        if (this.period) {
            this.dates = [];

            const startDate = this.selectedPeriod.startDate;
            const endDate = this.selectedPeriod.endDate;

            const calcDistribution = (startDate: moment.Moment, endDate: moment.Moment) => {
                const days: any = [];
                let currentDate;
                switch (this.frequency) {
                    case 0:
                        currentDate = startDate.clone().startOf('month');
                        break;
                    case 1:
                        currentDate = startDate.clone().date(15);
                        break;
                    case 2:
                        currentDate = startDate.clone().endOf('month');
                        break;
                }

                while (currentDate.isSameOrBefore(endDate)) {
                    if (currentDate.isSameOrAfter(startDate)) {
                        days.push({
                            date: currentDate.clone().format('YYYY-MM-DD'),
                            perc: 0,
                            amount: 0
                        });
                    };
                    currentDate.add(1, 'month');
                }

                return days;
            };

            this.dates = calcDistribution(startDate, endDate);

        } else if (!this.period) {
            this.dates = [];
            const startDate = this.selectedPeriod.startDate;
            this.dates = [{
                date: startDate.clone().format('YYYY-MM-DD'),
                perc: 0,
                amount: this.utility.parseNumber(this.amount, this.locale)
            }];
        }

        this.dates.forEach((date: any) => {
            date.perc = 100 / this.dates.length;
            date.amount = this.utility.parseNumber(this.amount, this.locale) * date.perc / 100;
        });
    }

    selectAllInput(event: any) {
        $(event.target).select();
    }

    selectedCustomDatesScadenziario(event: any) {
        this.selectedPeriod = event;
        this.performDistribution();
    }


    initNewPayload() {
        return {
            "SupplierId": this.selectedAccount,
            "Description": this.description ? this.description : '',
            "Note": "",
            "BankId": this.bank,
            "Store": this.company,
            "OperationId": this.bankOperation,
        };
    }

    onSubmit() {

        let rows: any = [];
        if (this.dates && this.dates.length > 0) {

            this.dates.forEach((date: any) => {
                let payload = this.initNewPayload();

                payload['Date'] = date.date;

                let model = 'Credit';
                if(this.isEdit) {
                    model = this.scadenza.Credit > 0 ? 'Credit' : 'Debit';
                } else {
                    model = this.accountTypeDlg == 2 ? 'Credit' : 'Debit';
                }

                payload[model] = date.amount;

                if (this.scadenza && this.scadenza.Id) {
                    payload['Id'] = this.scadenza.Id;
                } else {
                    payload['IsPayed'] = false;
                }

                rows.push(payload);
            });
        }

        this.onLoading.emit(true);
        if (this.scadenza && this.scadenza.Id) {
            lastValueFrom(
                this.scadenziarioService.updateSchedule(rows[0]))
                .then((res: any) => {
                    console.log(res);
                    this.dialogRef.close({ success: true });
                })
                .catch((err: any) => {
                    this.dialogRef.close(undefined);
                })
                .finally(() => {
                    this.onLoading.emit(true);
                });
        } else {
            lastValueFrom(
                this.scadenziarioService.createSchedule(rows))
                .then((res: any) => {
                    console.log(res);
                    this.dialogRef.close({ success: true });
                })
                .catch((err: any) => {
                    this.dialogRef.close(undefined);
                })
                .finally(() => {
                    this.onLoading.emit(true);
                });
        }
    }

}
