import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[SelectOnClick]'
})
export class SelectOnClickDirective {

    constructor(private el: ElementRef<HTMLInputElement>) { }

    @HostListener('click', ['$event.target'])
    onClick(target: HTMLInputElement): void {
      this.selectText(target);
    }
  
    private selectText(inputElement: HTMLInputElement): void {
      inputElement.select();
    }

}
