import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { API } from '@env/environment';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { Observable, of, BehaviorSubject, combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MagazzinoService {

  private _currentConfig: any;
  summaryUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectorChanged$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  setModel$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private http: HttpClient,
    private switchGroupService: SwitchGroupService
  ) { }

  makeOrderVisible(item) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    //console.log('getWarehouse', entity, filter);

    const url = `/api/Orders/MakeVisible?id=${item.Id}`;
    return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  getWarehouse(entity: string, filter: any, pagination: any = undefined): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = pagination ? `/api/Warehouse/${entity}?page=${pagination.page}&pagesize=${pagination.pageSize}&sort=${pagination.sort}&sortMode=${pagination.sortMode}` : `/api/Warehouse/${entity}`;
    return this.http.post<any>(currentLogin.endpointURL + url, filter, { headers: httpHeaders });
  }

  getWarehouseMainGraph(entity: string, filter: any, lang: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Warehouse/Dashboard/?table=${entity}&lang=${lang}`;
    return this.http.post<any>(currentLogin.endpointURL + url, filter, { headers: httpHeaders });
  }

  getWarehouseTopItems(entity: string, filter: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Warehouse/TopItems/?table=${entity}`;
    return this.http.post<any>(currentLogin.endpointURL + url, filter, { headers: httpHeaders });
  }

  getWarehousePricesGraph(filter: any, ProductId: string = "0"): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Warehouse/PricesGraph`;
    return this.http.post<any>(currentLogin.endpointURL + url, {
      filter,
      ProductId: ProductId
    }, { headers: httpHeaders });
  }

  getOrders(company: string = ''): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    let url = `/api/Warehouse/Orders`;
    url = company ? `${url}?company=${company}` : url;
    return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  getSingleOrder(order: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Warehouse/SingleOrder?listId=${order.ListId}&supplierId=${order.SupplierId}`;
    return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  getSuppliersByCompany(company) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Warehouse/SuppliersByCompany?company=${company}`;
    return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  populate(body: any, collapse: boolean = true, bestPrice: boolean = false): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Warehouse/Goods?collapse=${collapse}&bestPrice=${bestPrice}`;
    return this.http.post<any>(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }

  setCurrentConfig(config: any) {
    this._currentConfig = config;
  }

  getCurrentConfig() {
    return this._currentConfig;
  }

  save(entity: string, body) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Warehouse/Save?table=${entity}`;
    return this.http.post<any>(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }

  lockInventory(data) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Warehouse/lockInventory`;
    return this.http.post<any>(currentLogin.endpointURL + url, data, { headers: httpHeaders });
  }

  getInvetoryDates() {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });
    let url = `/api/Warehouse/InvetoryDates`;
    return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  getExistingInvetory(item) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });
    let url = `/api/Warehouse/NewInvetory`;

    var body = {
      Date: item.Date,
      Company: item.Company,
      DepartmentId: item.DepartmentId,
      CostCenterId: item.CostCenterId,
    };

    return this.http.post<any>(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }

  itemsForUpdate(itemId: string, entity: string, supplierId: string = ''): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });
    let url = `/api/Warehouse/ItemsForUpdate?id=${itemId}&table=${entity}`;
    if (supplierId) {
      url = `/api/Warehouse/ItemsForUpdate?id=${itemId}&supplierId=${supplierId}&table=${entity}`;
    }
    if (entity === 'Inventory') {
      url += '&byInventoryDate=true';
    }

    return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  update(entity: string, body) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Warehouse/Update?table=${entity}`;
    return this.http.put<any>(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }

  delete(entity: string, body) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    /// Array id da cancellare

    const url = `/api/Warehouse/Delete?table=${entity}`;
    return this.http.put<any>(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }

  deleteMultiPurchase(itemId: string) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Warehouse/DeletePurchaseDocument?id=${itemId}`;
    return this.http.delete<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  deleteMultiOrders(itemId: string) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Warehouse/DeleteOrderDocument?id=${itemId}`;
    return this.http.delete<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  deleteMultiInventory(itemId: string) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Warehouse/DeleteFullInventory?id=${itemId}`;
    return this.http.delete<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  getOrderPDF(order: any, lang: string): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Orders/PDF?listId=${order.Id}&supplierId=${order.Supplier ? order.Supplier.Id : 0}&lang=${lang}`;
    return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders })
      .pipe(
        map((result: any) => {
          result.endpointURL = currentLogin.endpointURL;
          return result;
        })
      );
  }

  getPrintDDT(order: any, lang: string): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Orders/PrintDDT?listId=${order.Id}&supplierId=${order.Supplier.Id}&evaso=${order.IsEDI}&lang=${lang}`;
    return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders })
      .pipe(
        map((result: any) => {
          result.endpointURL = currentLogin.endpointURL;
          return result;
        })
      );
  }

  getOrderMailInfo(order: any, lang: string): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Orders/MailInfo?listId=${order.Id}&supplierId=${order.Supplier.Id}&lang=${lang}`;
    return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  sendOrderMail(body: any, lang: string) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Orders/SendMail?&lang=${lang}`;
    return this.http.post<any>(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }

  sendDDT(body: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Orders/DispatchOrder?listId=${body.ListId}&supplierId=${body.SupplierId}&requireDDT=false&autoSend=${body.autoSend}&sameDate=${body.sameDate}`;
    return this.http.post<any>(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }

  getInventoryPDF(order, company, language, categoryIds: any = null) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Inventory/PDFSheet?order=${order}&company=${company}&lang=${language}`;


    return this.http.post<any>(currentLogin.endpointURL + url, {
      CategoryIds: categoryIds
    }, { headers: httpHeaders })
      .pipe(
        map((result: any) => {
          result.endpointURL = currentLogin.endpointURL;
          return result;
        })
      );
  }

  getInventoryLayout(order, company, language, categoryIds: any = null) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/Inventory/XLSData?order=${order}&company=${company}&lang=${language}`;

    return this.http.post<any>(currentLogin.endpointURL + url, {
      CategoryIds: categoryIds
    }, { headers: httpHeaders })
      .pipe(
        map((result: any) => {
          result.endpointURL = currentLogin.endpointURL;
          return result;
        })
      );
  }

  importAcquisti(file): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Action': 'upload',
      'Authorization': 'Bearer ' + currentLogin.access_token

    });
    const url = `/api/Fe/UploadFe`;
    return this.http.post(currentLogin.endpointURL + url, file, { headers: httpHeaders });
  }

  getAcquistiImportati(other: boolean): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Action': 'upload',
      'Authorization': 'Bearer ' + currentLogin.access_token

    });
    let url = `/api/Fe/FeList`;
    if (other) {
      url = `/api/Fe/FeList?profitAndLossOnly=true`;
    }
    return this.http.get(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  moveAcquistiImportati(fileName: string, store: string): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Action': 'upload',
      'Authorization': 'Bearer ' + currentLogin.access_token

    });
    const url = `/api/Fe/Move`;
    return this.http.put(currentLogin.endpointURL + url, {
      "FileName": fileName,
      "Store": store
    }, { headers: httpHeaders });
  }

  bulkMoveAcquistiImportati(fileNames: string[], store: string): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Action': 'upload',
      'Authorization': 'Bearer ' + currentLogin.access_token

    });
    const url = `/api/Fe/Move`;
    return this.http.put(currentLogin.endpointURL + url, {
      "fileNames": fileNames,
      "Store": store
    }, { headers: httpHeaders });
  }

  checkFilename(fileName: string) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Action': 'upload',
      'Authorization': 'Bearer ' + currentLogin.access_token

    });
    const url = `/api/Fe/Check?fileName=${fileName}`;
    return this.http.get(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  checkAll(company: string) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Action': 'upload',
      'Authorization': 'Bearer ' + currentLogin.access_token

    });
    const url = `/api/Fe/CheckAll`;
    return this.http.post(currentLogin.endpointURL + url, {
      Store: company
    }, { headers: httpHeaders });
  }

  getXml(fileName: string, model: number = 2) {
    const currentLogin = this.switchGroupService.getCurrentGroup();
    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'text/html',
        'Authorization': 'Bearer ' + currentLogin.access_token,
        'Accept': 'text/html, application/xhtml+xml, */*',
      }),
      responseType: 'text'
    };
    return this.http.get<any>(currentLogin.endpointURL + `/api/Fe/Xml?fileName=${fileName}&model=${model}`, httpOptions);
  }

  getRawXml(fileName: string, model: number = 2) {
    const currentLogin = this.switchGroupService.getCurrentGroup();
    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'text/html',
        'Authorization': 'Bearer ' + currentLogin.access_token,
        'Accept': 'text/html, application/xhtml+xml, */*',
      }),
      responseType: 'text'
    };
    return this.http.get<any>(currentLogin.endpointURL + `/api/Fe/RawXML?fileName=${fileName}&model=${model}`, httpOptions);
  }

  updateInvoice(body: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Action': 'upload',
      'Authorization': 'Bearer ' + currentLogin.access_token

    });
    const url = `/api/Fe/Fix`;
    return this.http.post(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }

  updateInvoiceMultiple(body: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Action': 'upload',
      'Authorization': 'Bearer ' + currentLogin.access_token

    });
    const url = `/api/Fe/FixMultiple`;
    return this.http.post(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }

  restoreInvoiceRow(body: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Action': 'upload',
      'Authorization': 'Bearer ' + currentLogin.access_token

    });
    const url = `/api/Fe/Restore`;
    return this.http.post(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }

  restoreInvoiceRowMultiple(body: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Action': 'upload',
      'Authorization': 'Bearer ' + currentLogin.access_token

    });
    const url = `/api/Fe/RestoreMultiple`;
    return this.http.post(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }

  importInvoices(invoice: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Action': 'upload',
      'Authorization': 'Bearer ' + currentLogin.access_token

    });
    const url = `/api/Fe/ImportFe?fileName=${invoice.FileName}`;

    let payload: any = [];

    if (invoice.Check && invoice.Check.PlItems && invoice.Check.PlItems.length > 0) {
      invoice.Check.PlItems.forEach((item: any) => {
        payload.push({
          Id: item.Id,
          Percentage: item.Percentage
        })
      });
    }

    return this.http.post(currentLogin.endpointURL + url, payload, { headers: httpHeaders });
  }

  // DDT
  addDDT(body: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });
    const url = `/api/DDT`;
    return this.http.post(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }

  updateDDT(body: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });
    const url = `/api/DDT?id=${body.Id}`;
    return this.http.put(currentLogin.endpointURL + url, body, { headers: httpHeaders });
  }

  deleteDDT(id: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });
    const url = `/api/DDT?id=${id}`;
    return this.http.delete(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  getDDTs(supplierId: string) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/DDT/Available?SupplierId=${supplierId}`;

    return this.http.get<any>(currentLogin.endpointURL + url, { headers: httpHeaders });
  }

  linkDDTs(invoiceId: string, DDTIds: any) {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/DDT/LinkDDT`;

    return this.http.put<any>(currentLogin.endpointURL + url, {
      InvoiceId: invoiceId,
      DDTIds: DDTIds
    }, { headers: httpHeaders });
  }

  forceCheckDDT(invoiceId: string, force: boolean = false, Note: string = '') {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    const url = `/api/DDT/ForceCheckDDT`;

    return this.http.put<any>(currentLogin.endpointURL + url, {
      InvoiceId: invoiceId,
      Checked: force,
      Note: Note
    }, { headers: httpHeaders });
  }

}
