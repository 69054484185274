import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as utils from '@app/core/services/utilityfunctions';

@Component({
    selector: 'mb-select2',
    templateUrl: './mb-select2.component.html',
    styleUrls: ['./mb-select2.component.scss']
})
export class MbSelect2Component implements OnInit {

    @Input() id: any;
    @Input() list: any;
    @Input() valueField!: string;
    @Input() descriptionField!: string;
    @Input() additionalValueField!: string;
    @Input() otherFields!: string;
    @Input() otherFieldsIsDate: boolean = false;
    @Input() locale: string = 'it';

    @Input() prependBrackets: boolean = false;
    @Input() appendBrackets: boolean = false;

    @Input() disabled: boolean = false;

    @Input() placeholder: string = '';
    @Input() multiple: boolean = false;
    @Input() filterLayout: boolean = false; // Modalità vista filtro con label + checbox select all
    @Input() filterLayoutLabel: string = '';
    @Input() filterLayoutLabelSelectAll: string = '';
    @Input() modelTypeObject: boolean = false;
    @Input() modelTypeObjectFull: boolean = false; // Serve per recueprare tutto l'oggetto e non solo il valore
    @Input() tooltip!: string;

    @Output() onSelect = new EventEmitter<any>();
    @Output() onUnselect = new EventEmitter<void>();
    @Output() onClear = new EventEmitter<void>();

    // NgModel
    @Input() model: any;
    @Output() modelChange = new EventEmitter<any>();

    constructor(
        private ref: ChangeDetectorRef,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.model = [];
    }

    ngAfterViewInit() {
        this.initSelect2();
        setTimeout(() => {
            $(`#${this.id}`).val('').trigger('change');
        }, 100);
    }

    ngOnChanges(change: SimpleChanges) {

        if (this.id == 'select2Fornitori_io') {
            change
        }

        if (change['model'] && (change['model'].currentValue !== change['model'].previousValue || change['model'].currentValue === '' || change['model'].currentValue === undefined || (change['model'].currentValue.length == 0 && this.multiple))) {
            // è come un inizializzazione, quando lo metto a undefined lancia il trigger
            let model;
            if (this.modelTypeObject && this.multiple) {
                model = change['model'] && change['model'].currentValue ? change['model'].currentValue.map((i: any) => i[this.valueField]) : undefined;
            } else if (this.modelTypeObject) {
                model = change['model'] && change['model'].currentValue ? change['model'].currentValue[this.valueField] : undefined;
            } else {
                model = change['model'].currentValue;
            }
            setTimeout(() => {
                $(`#${this.id}`).val(model).trigger('change');
            }, 100);
        }

        // Quando cambio la lista di riferimento, azzero il model
        if (change['list'] && change['list'].currentValue !== change['list'].previousValue) {
            $(`#${this.id}`).val('').trigger('change');
        }
    }

    initSelect2() {
        if ((<any>$(`#${this.id}`)).data('select2')) {
            (<any>$(`#${this.id}`)).select2('destroy');
        }

        const matchCustom = (params, data) => {
            // If there are no search terms, return all of the data
            if ($.trim(params.term) === '') {
                return data;
            }

            // Do not display the item if there is no 'text' property
            if (typeof data.text === 'undefined') {
                return null;
            }

            // `params.term` should be the term that is used for searching
            // `data.text` is the text that is displayed for the data object
            if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1 || data.additionalId.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
                var modifiedData = $.extend({}, data, true);
                //modifiedData.text += ' (matched)';

                // You can return modified objects from here
                // This includes matching the `children` how you want in nested data sets
                return modifiedData;
            }

            // Return `null` if the term should not be displayed
            return null;
        }

        let options: any = {
            placeholder: this.placeholder,
            allowClear: true,
            closeOnSelect: !this.multiple,
            multiple: this.multiple,
            width: '100%',
            data: this.list ? this.list.map((item: any) => {
                return {
                    id: item[this.valueField],
                    text: item[this.descriptionField],
                    additionalId: this.additionalValueField ? item[this.additionalValueField] : ''
                }
            }) : [],
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        };

        if (this.additionalValueField) {
            options['matcher'] = matchCustom
        }

        this.model = this.multiple ? [] : undefined;

        if (this.multiple) {
            var Utils = (<any>$).fn.select2.amd.require('select2/utils');
            var Dropdown = (<any>$).fn.select2.amd.require('select2/dropdown');
            var DropdownSearch = (<any>$).fn.select2.amd.require('select2/dropdown/search');
            var CloseOnSelect = (<any>$).fn.select2.amd.require('select2/dropdown/closeOnSelect');
            var AttachBody = (<any>$).fn.select2.amd.require('select2/dropdown/attachBody');

            var dropdownAdapter = Utils.Decorate(Utils.Decorate(Utils.Decorate(Dropdown, DropdownSearch), CloseOnSelect), AttachBody);
            options.dropdownAdapter = dropdownAdapter;

            (<any>$(`#${this.id}`)).attr('multiple', 'multiple');
        }

        (<any>$(`#${this.id}`)).select2(options).on('select2:opening select2:closing', (event) => {
            if (this.multiple) {
                //Disable original search (https://select2.org/searching#multi-select)
                var searchfield = $(event.target).parent().find('.select2-search__field');
                searchfield.prop('disabled', true);
            }
            setTimeout(() => {
                (<any>$(`input.select2-search__field`)).focus();
                (<any>$(`input.select2-search__field`)).select();
            }, 100);
        });

        (<any>$(`#${this.id}`)).on('select2:select', (e: any) => {
            const value = e.params.data.id;
            const exists = this.list.find((f: any) => f[this.valueField] == value);
            const description = exists ? exists[this.descriptionField] : '';

            let model;

            if (this.modelTypeObject && !this.modelTypeObjectFull) {
                model = {};
                model[this.valueField] = value;
                model[this.descriptionField] = description;

                if (this.otherFields && this.otherFields.length > 0) {
                    const otherFields = this.otherFields.split(',');
                    otherFields.forEach((field: string) => {
                        const fieldValue = this.list.find((f: any) => f[this.valueField] == value)[field];
                        model[field] = fieldValue;
                    });
                }
            } else if (this.modelTypeObject && this.modelTypeObjectFull) {

                model = exists;

            } else {
                model = value;
            }

            if (this.multiple) {
                if(!this.model) this.model = [];
                this.model.push(model);
            } else {
                this.model = model;
            }

            this.onSelect.emit(this.model);
            this.modelChange.emit(this.model);
        });

        // Non so per quale motivo, ma quando chiudo il dropdown la textarea per la ricerca rimane disabilitata, 
        // allora intercetto l'evento close e la abilito manualmente
        // https://mantis.fbmanager.com/view.php?id=1515
        (<any>$(`#${this.id}`)).on('select2:close', (e: any) => {
            $(e.target).closest('mb-select2').find('textarea').prop('disabled', false);
        });

        (<any>$(`#${this.id}`)).on('select2:clear', (e: any) => {

            if (this.multiple) {
                this.model = [];
            } else {
                this.model = undefined;
            }
            this.modelChange.emit(this.model);
            this.onClear.emit();
        });
        (<any>$(`#${this.id}`)).on('select2:unselect', (e: any) => {
            if (this.multiple) {
                this.model = this.model.filter((i: any) => {

                    if (this.modelTypeObject) {
                        return i[this.valueField] != e.params.data.id
                    } else {
                        return i != e.params.data.id
                    }

                });
            } else {
                this.model = undefined;
            }
            this.modelChange.emit(this.model);
            this.onUnselect.emit(e.params.data.id);
        });

    }

    trackByIndex(index: any, item: any) {
        return index;
    }

    public setValue(value: any) {
        (<any>$(`#${this.id}`)).val(value).trigger('change');
        this.ref.detectChanges();
    }

    public checkOtherFields(item: any) {
        const otherFields = this.otherFields.split(',');
        let result = '';
        otherFields.forEach((field: string) => {
            const fieldValue = item[field];
            if (result.length > 0) result += ', ';
            result += this.otherFieldsIsDate ? utils.getLocaleData(fieldValue, this.locale) : fieldValue;
        });
        return result
    }

    select2All(ev: any) {
        if (ev.checked) {
            (<any>$(`#${this.id}`)).select2('destroy').find('option').prop('selected', 'selected').end().select2();
        } else {
            (<any>$(`#${this.id}`)).select2('destroy').find('option').prop('selected', false).end().select2();
        }

        if (this.modelTypeObject) {
            let ret: any = [];
            (<any>$(`#${this.id}`)).select2('data').forEach((element: any) => {
                ret.push({
                    Id: element.id,
                    Name: element.text
                })
            });
            this.model = ret;
        } else {
            this.model = $(`#${this.id}`).val();
        }

        this.modelChange.emit(this.model);
    }

}
