// Angular
import { Component, OnInit, AbstractType } from '@angular/core';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
// Translate Module
import { AziendeService } from '@app/core/services/aziende.service';

@Component({
  selector: 'kt-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {

  logoBase64: any;
  logo: any;
  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   */
  constructor(
    private switchGroupService: SwitchGroupService,
    public aziendeService: AziendeService
  ) {
  }

  /**
   * @ Lifecycle sequence(s: any) => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    const currentLogin = this.switchGroupService.getCurrentGroup();
    // console.log('currentLogin', currentLogin);
    this.aziendeService.getLogo(currentLogin.endpointURL, currentLogin.access_token).subscribe(
      logo => {
        this.logoBase64 = logo;
      }
    );
  }

  existsLogo() {
    const urlChecker = new XMLHttpRequest();
    const currentLogin = this.switchGroupService.getCurrentGroup();
    const url = currentLogin.endpointURL + '/in/logos/logo.png';
    urlChecker.open('get', url, true);

    //check each time the ready state changes
    //to see if the object is ready
    urlChecker.onreadystatechange = () => {

      if (urlChecker.readyState === 4) {

        //check to see whether request for the file failed or succeeded
        if ((urlChecker.status === 200) || (urlChecker.status === 0)) {
          this.logo = url;
        }
        else {
          return false;
        }
      }

      return;

    }
    urlChecker.send(null);

  }

  getLogo() {

    // if(!this.logoBase64) {
    // 	const currentLogin = this.switchGroupService.getCurrentGroup();
    // 	return  currentLogin.endpointURL + '/in/logos/logo.png';
    // } else {
    // 	return this.logoBase64;
    // }

    return this.logoBase64;
  }
}
