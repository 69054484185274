// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, catchError, filter, take, switchMap, finalize } from 'rxjs/operators';
//import { debug } from 'util';
import { Router } from '@angular/router';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

	isRefreshingToken: boolean = false;
	tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

	constructor(
		private router: Router
	) { }

	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// tslint:disable-next-line:no-debugger
		// modify request
		// request = request.clone({
		// 	setHeaders: {
		// 		Authorization: `Bearer ${localStorage.getItem('accessToken')}`
		// 	}
		// });
		return next.handle(request).pipe(
			tap(
				event => {
					if (event instanceof HttpResponse) {
					}
				},
				error => {
					if (error.status == 401) {
						const performRefreshToken = request.url.endsWith('/api/Dashboard');
						if (performRefreshToken) {
							return this.handle401Error(request, next);
						} else {
							//return Observable.throw(JSON.stringify(error));
						}
					} else {
						//return Observable.throw(JSON.stringify(error));
					}

                    return;

				}
			)
		);
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshingToken) {
			this.isRefreshingToken = true;

			// Reset here so that the following requests wait until the token
			// comes back from the refreshToken call.
			this.tokenSubject.next('');

            return 

		} else {
			this.isRefreshingToken = false;
			return this.tokenSubject
				.pipe(filter(token => token != null),
					take(1),
					switchMap(token => {
						return next.handle(request);
					}));
		}
	}
}
