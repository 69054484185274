import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as util from '@app/core/services/utilityfunctions';
import { locale } from 'moment';

@Component({
    selector: 'kt-discounts-edit-dialog',
    templateUrl: './discounts-edit-dialog.component.html',
    styleUrls: ['./discounts-edit-dialog.component.scss']
})
export class DiscountsEditDialogComponent implements OnInit {

    viewLoading: boolean = false;
    utility: any;
    item: any;

    constructor(
        public dialogRef: MatDialogRef<DiscountsEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
        this.utility = util;
    }

    ngAfterViewInit() {
        this.item = this.data.item;
        this.item.S1 = util.formatNumber(this.data.item.S1, this.data.locale);
        this.item.S2 = util.formatNumber(this.data.item.S2, this.data.locale);
        this.item.S3 = util.formatNumber(this.data.item.S3, this.data.locale);
        this.item.S4 = util.formatNumber(this.data.item.S4, this.data.locale);
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }


    calcDiscount() {
        let scontoParziale = 0;
        if (this.item.S1 && util.parseNumber(this.item.S1, this.data.locale) > 0) {
            scontoParziale = 1 - util.parseNumber(this.item.S1, this.data.locale) / 100;
            if (util.parseNumber(this.item.S2, this.data.locale) > 0) {
                scontoParziale *= (1 - util.parseNumber(this.item.S2, this.data.locale) / 100);
                if (util.parseNumber(this.item.S3, this.data.locale) > 0) {
                    scontoParziale *= (1 - util.parseNumber(this.item.S3, this.data.locale) / 100);
                    if (util.parseNumber(this.item.S4, this.data.locale) > 0) {
                        scontoParziale *= (1 - util.parseNumber(this.item.S4, this.data.locale) / 100);
                    }
                }
            }
        }

        return (scontoParziale > 0) ? 1 - scontoParziale : 0;
    }

    onYesClick(): void {
        this.viewLoading = true;
        const payload = {
            S1: util.parseNumber(this.item.S1, this.data.locale),
            S2: util.parseNumber(this.item.S2, this.data.locale),
            S3: util.parseNumber(this.item.S3, this.data.locale),
            S4: util.parseNumber(this.item.S4, this.data.locale),
            Discount: this.calcDiscount(),
        }
        this.dialogRef.close(payload);
    }

}
