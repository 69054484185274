import { Component, OnInit, Inject } from '@angular/core';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import * as util from '@app/core/services/utilityfunctions';
import { TranslationService } from '@app/core/_base/layout';
import { timeStamp } from 'console';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'kt-companies-distribution',
    templateUrl: './companies-distribution.component.html',
    styleUrls: ['./companies-distribution.component.scss']
})
export class CompaniesDistributionComponent implements OnInit {

    companies: any;
    utility: any;
    locale!: string;
    totDistribution!: number;
    mode: string = 'perc';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        public staticCollectionsService: StaticCollectionsService,
        private translationService: TranslationService,
    ) {
        this.utility = util;

        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
    }

    ngOnInit() {
        this.companies = JSON.parse(JSON.stringify(this.staticCollectionsService.aziende$));

        const index = this.companies.findIndex((company: any) => company.Name == this.data.Store);
        if (index >= 0) {
            this.companies[index].Percentage = 100;
            this.updateDistribution();
        }
    }

    calcAbsolute(item: any) {
        if (!item.Percentage) return '';
        const value = this.data.TotalDdt * this.utility.parseNumber(item.Percentage, this.locale) / 100;
        //return this.utility.formatNumber(value, this.locale, 2, true, true);
        return value;
    }

    calcPercentage(item: any) {
        if (!item.Absolute) return '';
        const value = this.utility.parseNumber(item.Absolute, this.locale) / this.data.TotalDdt * 100;
        //return this.utility.formatNumber(value, this.locale, 2, true, true);
        return value;
    }

    changeMode(event: any) {
        this.totDistribution = 0;
        this.companies.forEach((item: any) => {
            item.Absolute = item.Percentage = null;
        });
    }

    updateDistribution() {
        this.totDistribution = 0;
        this.companies.forEach((item: any) => {
            if (this.mode == 'perc') {
                item.Absolute = this.calcAbsolute(item);
            } else {
                item.Percentage = this.calcPercentage(item);
            }
            if (item.Percentage && this.utility.parseNumber(item.Percentage, this.locale) > 0) {
                this.totDistribution += this.utility.parseNumber(item.Percentage, this.locale);
            }
        });
    }

    totDistributionOk() {
        return Math.abs(100 - this.totDistribution) < 1;
    }
}

