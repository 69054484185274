import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import {
	MAT_MOMENT_DATE_FORMATS,
	MomentDateAdapter,
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, fromEvent, lastValueFrom } from 'rxjs';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { MbSelect2Component } from '@app/mb-components/mb-select2/mb-select2.component';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';

@Component({
	selector: 'kt-filtro-popup',
	templateUrl: './filtro-popup.component.html',
	styleUrls: ['./filtro-popup.component.scss'],
	providers: [
		{ provide: MAT_DIALOG_DATA, useValue: {} },
		{ provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
	]
})
export class FiltroPopupComponent implements OnInit {

	loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private select2CostCenter!: MbSelect2Component;
	@ViewChild('select2CostCenter', { static: false }) set _select2CostCenter(content: MbSelect2Component) {
		if (content) { // initially setter gets called with undefined
			this.select2CostCenter = content;
		}
	}

	private select2CostCenterDestination!: MbSelect2Component;
	@ViewChild('select2CostCenterDestination', { static: false }) set _select2CostCenterDestination(content: MbSelect2Component) {
		if (content) { // initially setter gets called with undefined
			this.select2CostCenterDestination = content;
		}
	}

	private select2Aziende!: MbSelect2Component;
	@ViewChild('select2Aziende', { static: false }) set _select2Aziende(content: MbSelect2Component) {
		if (content) { // initially setter gets called with undefined
			this.select2Aziende = content;
		}
	}

	private select2AziendeDst!: MbSelect2Component;
	@ViewChild('select2AziendeDst', { static: false }) set _select2AziendeDst(content: MbSelect2Component) {
		if (content) { // initially setter gets called with undefined
			this.select2AziendeDst = content;
		}
	}

	dateControl: any;
	timeControl: string = '00:00';
	company: any;
	companyDst: any;
	costCenter: any;
	costCenterDest: any;
	categories: any = [];
	categoriesLabels: any = [];
	productName!: string;

	viewLoading: boolean = false;
	hasFormErrors: boolean = false;
	errorMessage!: string;

	staticCollectionReady: boolean = false;
	// staticCollectionsSubcription!: Subscription;
	isRettifica: boolean = false;
	isProdottiFiniti: boolean = false;
	selectedWasteType: any = 0;

    wasteTypes: any;

	constructor(
		public dialogRef: MatDialogRef<any>,
		public staticCollectionsService: StaticCollectionsService,
		private _adapter: DateAdapter<any>,
		private anagraficheService: AnagraficheService
	) {}

	async ngOnInit() {
		this._adapter.setLocale('it');//this._adapter.setLocale(this.translationService.getSelectedLanguage());		

        if(this.isRettifica) {
            const wasteTypes = await lastValueFrom(this.anagraficheService.getEntity('Wastetypes'));
            if(wasteTypes) {
                this.wasteTypes = wasteTypes.filter((wt: any) => wt.IsSelling);
            }
        }

		await this.staticCollectionsService.fillStaticCollection(false);
		this.staticCollectionReady = true;

		this.timeControl = moment().format('HH:mm');
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.initFiltri();	
		}, 1500);
	}

	selectCompany(event: any) {
		this.company = {
			Name: event,
			Id: event
		}
	}

	selectCompanyDest(event: any) {
		this.companyDst = {
			Name: event,
			Id: event
		};
	}

	initFiltri() {

			// this.select2Categorie.setValue(undefined);
			this.categories = [];
			this.select2Aziende.setValue(undefined);

			this.costCenter = this.staticCollectionsService.costCenters$.find((cc: any) => cc.Name === '---');
			if (this.costCenter) {
				this.select2CostCenter.setValue(this.costCenter.Id);
			} else {
				this.select2CostCenter.setValue(undefined);
			}			
			if ( this.isProdottiFiniti ) {
				this.select2AziendeDst.setValue(undefined);
				this.costCenterDest = this.staticCollectionsService.costCenters$.find((cc: any) => cc.Name === '---');
				if (this.costCenterDest) {
					this.select2CostCenterDestination.setValue(this.costCenterDest.Id);
				} else {
					this.select2CostCenterDestination.setValue(undefined);
				}
			}
	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}

	onSubmit() {

		const dateTime = moment(this.dateControl).format('YYYY-MM-DD') + 'T' + this.timeControl;

		console.log('dateTime', dateTime);

		let categoriesLabels: any = [];
		this.categories.forEach(event => {
			const cat = this.staticCollectionsService.categorieProdotti$.find((c: any) => c.Id == event);
			if (cat) {
				categoriesLabels.push(cat.Name);
			}
		});

		this.dialogRef.close({
			success: true,
			body: {
				Date: dateTime,
				Company: this.company,
				CompanyDst: this.companyDst,
				CostCenter: this.costCenter, // vecchio
				CostCenterName: this.costCenter.Id, // nuovo
				CostCenterId: this.costCenter.Name, // nuovo
				CostCenterDest: this.costCenterDest ? this.costCenterDest : undefined, // vecchio
				CostCenterDestName: this.costCenterDest ? this.costCenterDest.Id : undefined, // nuovo
				CostCenterDestId: this.costCenterDest ? this.costCenterDest.Name : undefined, // nuovo
				CategoryIds: this.categories,
				CategoryNames: categoriesLabels.toString(),
				ProductName: this.productName,
				SelectedWasteType: !this.isProdottiFiniti ? this.selectedWasteType : 4
			},
		});
	}

	manageKeypress(event: any) {
		if (event.keyCode === 13) this.onSubmit();
	}

	selectCostCenter(event: any) {
		this.costCenter = this.staticCollectionsService.costCenters$.find(cc => cc.Id == event);
	}

	selectCostCenterDest(event: any) {
		this.costCenterDest = this.staticCollectionsService.costCenters$.find(cc => cc.Id == event);
	}

}
