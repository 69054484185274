import { Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

// RXJS
import { BehaviorSubject, fromEvent, lastValueFrom, Subscription } from 'rxjs';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Services
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { FiltriService } from '@app/core/services/filtri.service';
import { GestioneRicetteService } from '@app/core/services/gestione-ricette.service';
import { ConfigService } from '@app/core/services/config.service';

// Pagination
import { PageEvent } from '@angular/material/paginator';

// Utilities
import * as util from '@app/core/services/utilityfunctions';
import * as _ from 'lodash';
import { RoleService, SwitchGroupService } from '@app/core/_base/layout';

import { EditRicettaComponent } from '../edit-ricetta/edit-ricetta.component';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { InsertNotesComponent } from '../edit-ricetta/insert-notes/insert-notes.component';
import { Console } from 'console';


@Component({
    selector: 'kt-manutenzione-ricette',
    templateUrl: './manutenzione-ricette.component.html',
    styleUrls: ['./manutenzione-ricette.component.scss']
})
export class ManutenzioneRicetteComponent implements OnInit {

    locale!: string;
    pagination: any;
    expandAll: boolean = false;
    utility: any;
    filtriServiceSubscription!: Subscription;
    currentFilterCfg: any;
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    optScarto: any;
    goodsOrSubrecipeNamesList: any;
    ProductOrCode: any;
    CategoryIds: any;
    recipeType: string = '';

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private gestioneRicetteService: GestioneRicetteService,
        private translate: TranslateService,
        public staticCollectionsService: StaticCollectionsService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private switchGroupService: SwitchGroupService,
        private ref: ChangeDetectorRef,
        private dialog: MatDialog,
        private confSrv: ConfigService,
        private gestioneMerciService: GestioneMerciService,
        private roleService: RoleService
    ) {
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
        this.utility = util;

        this.initFilter();
    }

    ngOnDestroy() {
    }

    initFilter() {
        this.currentFilterCfg = {
            EnableState: 2,
            FBType: 6,
            ShowDisabled: false,
            ShowInvisible: false,
            VisibleState: 1
        };
    }

    async ngOnInit() {
        this.pagination = {
            pageSize: 10,
            TotalRows: 0,
            pageIndex: 0
        };

        const r: any = await this.confSrv.getConfigurations().toPromise().then();
        this.optScarto = r.WasteMode;

        this.gestioneRicetteService.goodsOrSubrecipeNamesList = undefined;
        if (this.gestioneRicetteService.goodsOrSubrecipeNamesList) {
            this.goodsOrSubrecipeNamesList = this.gestioneRicetteService.goodsOrSubrecipeNamesList;
        } else {
            const filter: any = {
                HideExcluded: false,
                HideInvisible: false,
                FBType: 6,
                OnlyForRecipe: true
            };
            this.goodsOrSubrecipeNamesList = await lastValueFrom(this.gestioneMerciService.getGoods(filter));

            const recipes: any = await this.gestioneRicetteService.getRecipeList({
                EnableState: 3,
                VisibleState: 1
            }, {
                page: 1,
                pageSize: 10,
                sort: 'ChangeDate',
                sortMode: 'Desc',
                TotalRows: 0
            }).toPromise();
            const validSubRecipes: any = recipes && recipes.Subrecipes ? recipes.Subrecipes.filter((x: any) => !x.IsDisabled) : [];
            this.goodsOrSubrecipeNamesList = this.goodsOrSubrecipeNamesList.concat(validSubRecipes);
        }

        this.initTable();
    }

    selectCategories(event: any) {
        this.filter(event, 'CategoryIds');
    }

    changeFBType(event: any) {
        this.filter(event, 'Type')
    }

    clearFilters() {
        this.CategoryIds = null;
        this.ProductOrCode = null;
        this.recipeType = '';
        this.initFilter();
        this.table.draw();
    }

    filter(event: any, type: string) {
        if (!this.currentFilterCfg) {
            this.initFilter();
        }
        this.currentFilterCfg[type] = event;
        this.table.draw();
    }

    manageProductOrCodeKeyDown(event: KeyboardEvent) {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            this.filter(this.ProductOrCode, 'ProductOrCode');
        }
    }

    initTable() {

        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();

            let dtOptions: any = {
                destroy: true,
                paging: true,
                searching: true,
                dom: 'rt<"paginator">',
                ordering: true,
                scrollY: 'calc(100vh - 350px)',
                scrollX: false,
                scrollCollapse: true,
                autoWidth: true,
                order: [[11, 'desc']],
                //aaSorting: [[11, 'desc']],
                processing: true,
                serverSide: true,
                ajax: {
                    url: currentLogin.endpointURL + "/api/Recipe/DataList",
                    type: "POST",
                    data: (d: any) => {
                        d.filters = this.currentFilterCfg;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        this.pagination.TotalRows = response.recordsFiltered;
                        this.ref.detectChanges();
                        return response.data;
                    }
                },
                columnDefs: [
                    {
                        targets: [0], width: '20px', className: 'parent-row', render: (data) => {
                            return `<i class="fas expander pointer "></i>`;
                        }, orderable: false
                    },
                    {
                        targets: [1], width: '80px', data: 'Code', name: 'Code', render: (data) => {
                            return `<div class="overflow-ellipses" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }
                    },
                    {
                        targets: [2], width: '150px', data: 'Category', name: 'Category', render: (data) => {
                            return `<div class="overflow-ellipses colCategory" data-toggle="tooltip" title="${data?.Name}">${data?.Name}</div>`;
                        }
                    },
                    {
                        targets: [3], data: 'Name', name: 'Name', render: (data) => {
                            const name = data.length > 50 ? data.substr(0, 50) + '...' : data;

                            return `<div class="overflow-ellipses"><a href="javascript:void(0);" data-toggle="tooltip" title="${data}" 
                                                   class="editItem">                                                    
                                                    ${name}
                                                </a></div>`;
                        }
                    },
                    {   // Codice
                        targets: [4], width: '100px', data: 'Name', name: 'Name', render: (data) => {
                            return ''
                        }
                    },
                    {   // Sotto ricetta
                        targets: [5], width: '50px', data: 'Name', name: 'Name', render: (data) => {
                            return ''
                        }
                    },
                    {   // Quantità Netta
                        targets: [6], width: '50px', data: 'Name', name: 'Name', render: (data) => {
                            return ''
                        }
                    },
                    {   // Unità usata nella ricetta
                        targets: [7], width: '30px', data: 'Name', name: 'Name', render: (data) => {
                            return ''
                        }
                    },
                    {   // Quantità Lorda
                        targets: [8], width: '50px', data: 'Name', name: 'Name', render: (data) => {
                            return ''
                        }
                    },
                    {   // Scarti %
                        targets: [9], width: '50px', data: 'Name', name: 'Name', render: (data) => {
                            return ''
                        }
                    },
                    {   // Nota
                        targets: [10], width: '30px', data: 'Name', name: 'Name', render: (data) => {
                            return ''
                        }, className: 'text-center'
                    },
                    {
                        targets: [11], width: '30px', orderable: false, className: 'text-center', render: (data) => {
                            return `<div class="display-flex-center flex-align-center">
                                    <i class="fas fa-pencil-alt text-primary pointer editItem"></i>
                                    <div class="h_spacer"></div>
                                    <i class="fas fa-save text-primary pointer saveItem"></i>
                                </div>
                            `;
                        }
                    },
                    { // usata solo per l'ordinamento iniziale, per averlo come nella pagina ricette
                        targets: [12], visible: false, data: 'ChangeDate', name: 'ChangeDate'
                    },
                ],
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                createdRow: function (row, data, dataIndex) {
                    $(row).attr('id', data.Id);
                }
            };

            this.table = ($('#tableManutenzioneRicette') as any).DataTable(dtOptions);
            this.table.on('page.dt', function () {
                $('.dataTables_scrollBody').animate({
                    scrollTop: 0
                }, 'slow');
            });
            //this.table.columns.adjust().draw();

            setTimeout(() => {

                $('#tableManutenzioneRicette tbody').off('click');

                $('#tableManutenzioneRicette tbody').on('click', 'i.saveItem', (event: any) => {
                    const tr = $(event.target).closest('tr');
                    const data: any = this.table.row(tr).data();
                    if (data) {
                        this.saveItem(data.Id);
                    }
                });

                $('#tableManutenzioneRicette tbody').on('click', '.editItem', (event: any) => {
                    const tr = $(event.target).closest('tr');
                    const data: any = this.table.row(tr).data();
                    if (data) {
                        this.editItem(data);
                    }
                });

                $('#tableManutenzioneRicette tbody').on('click', 'td.parent-row', (event: any) => {
                    const tr = $(event.target).closest('tr');
                    const row = this.table.row(tr);

                    if (row.child.isShown()) {
                        // This row is already open - close it
                        row.child.hide();
                        tr.removeClass('shown');
                    } else {
                        // Open this row

                        const ricetta = row.data();
                        this.gestioneRicetteService.getSingleRecipe(ricetta.Id).toPromise()
                            .then(singleRecipe => {
                                row.child(this.format(singleRecipe)).show();
                                $(tr).addClass('shown');
                            });

                    }
                });

                $('#tableManutenzioneRicette tbody').on('click', '.inputNumberController', (event: any) => {
                    $(event.target).focus();
                    $(event.target).select();
                });

                $('#tableManutenzioneRicette tbody').on('change', '.ingredientSubrecipeCol', (event: any) => {
                    this.syncRowData(event);
                });

                $('#tableManutenzioneRicette tbody').off('blur');
                $('#tableManutenzioneRicette tbody').on('blur', '.inputNumberController', (event: any) => {
                    // Tengo solo i numeri
                    //const code = event.target.value.replace(/[^\d.-]/g, '');
                    const decSeparator = this.utility.getSeparator(this.locale);
                    if (decSeparator == ",") {
                        // In ogni caso converto la virgola in punto per avere una stringa da formattare
                        event.target.value = event.target.value.indexOf(',') >= 0 && event.target.value.indexOf('.') ? event.target.value.replace('.', '').replace(',', '.') : (
                            event.target.value.indexOf(',') >= 0 ? event.target.value.replace(',', '.') : event.target.value
                        );
                    } else {
                        event.target.value = event.target.value.replace(',', '');
                    }
                    event.target.value = this.utility.formatNumber(parseFloat(event.target.value), this.locale);

                    this.syncRowData(event);
                });
            }, 1000);

            this.ref.detectChanges();
        }, 100);

    }

    syncRowData(event: any) {
        const tr: any = $(event.target).closest('tr');
        const td: any = $(event.target).closest('td');
        const idIngrediente = tr.data('ingredientid');
        let parentRicetta = JSON.parse(atob(tr.data('json')));

        const ingredientIndex = parentRicetta.Ingredients.findIndex((i: any) => i.Id == idIngrediente);

        parentRicetta.Ingredients[ingredientIndex].Goods.Name = tr.find('.ingredientNameCol input').val();
        //parentRicetta.Ingredients[ingredientIndex].Type = tr.find('.ingredientSubrecipeCol select').val() == 'true' ? 'Subrecipe' : 'Goods';
        parentRicetta.Ingredients[ingredientIndex].Quantity = tr.find('.ingredientQtaNettaCol input').val();
        parentRicetta.Ingredients[ingredientIndex].QtaLorda = tr.find('.ingredientQtaLordaCol input').val();
        parentRicetta.Ingredients[ingredientIndex].wasteView = tr.find('.ingredientWasteCol input').val();
        parentRicetta.Ingredients[ingredientIndex].Complement = tr.find('.ingredientNotaCol input').val();

        const type = td.hasClass('ingredientWasteCol') ? 'wasteView' : (
            td.hasClass('ingredientQtaLordaCol') ? 'QtaLorda' : 'Quantity'
        );

        parentRicetta.Ingredients[ingredientIndex] = this.calcoloSingoloIngrediente(parentRicetta.Ingredients[ingredientIndex], type, undefined);
        tr.find('.ingredientQtaNettaCol input').val(parentRicetta.Ingredients[ingredientIndex].Quantity);
        tr.find('.ingredientQtaLordaCol input').val(parentRicetta.Ingredients[ingredientIndex].QtaLorda);
        tr.find('.ingredientWasteCol input').val(parentRicetta.Ingredients[ingredientIndex].wasteView);
    }

    getTotaleIngrediente(ingredient: any) {
        let wasteView = ingredient.wasteView ? util.parseNumber(ingredient.wasteView, this.locale) : 0;
        let qtaLorda = ingredient.QtaLorda ? util.parseNumber(ingredient.QtaLorda, this.locale) : 0;
        let qty = ingredient.Quantity ? util.parseNumber(ingredient.Quantity, this.locale) : 0;
        let price = ingredient.Goods.Price ? util.parseNumber(ingredient.Goods.Price, this.locale) : 0;

        let foundGoods: any;
        if (ingredient.Goods && ingredient.Goods.Id) {
            if (ingredient.Type === 'Goods') {
                foundGoods = this.goodsOrSubrecipeNamesList.find((x: any) => x.Brands && x.Id == ingredient.Goods.Id);
            } else {
                foundGoods = this.goodsOrSubrecipeNamesList.find((x: any) => !x.Brands && x.Id == ingredient.Goods.Id);
            }
        }

        let byCompany: any = {};

        let unit: any = undefined;
        if (ingredient.Unit && ingredient.Unit.Id) {
            unit = this.staticCollectionsService.unit$.find((unit: any) => unit.Id.toString() === ingredient.Unit.Id.toString());
        }
        const coeffUnitaMisura = unit && unit.A ? unit.A : 1;
        if (foundGoods && foundGoods.Prices) {
            foundGoods.Prices.forEach(p => {
                byCompany[p.Azienda.toUpperCase()] = {
                    Price: p.Price,
                    Total: qtaLorda * p.Price * coeffUnitaMisura
                };
            });
        }
        return {
            wasteView: wasteView,
            qtaLorda: qtaLorda,
            qty: qty,
            price: price,
            unit: unit,
            coeffUnitaMisura: coeffUnitaMisura,
            total: qtaLorda * price * coeffUnitaMisura,
            byCompany: byCompany
        };
    }

    updateTotRicetta(ricetta: any, byCompanyIngredients: any) {
        let visibili = ricetta.Ingredients.filter((ingredient: any) => ingredient.SubstituteId === 0);
        let byCompanyTotal = {};
        visibili.forEach((element: any) => {
            let ingInfo = byCompanyIngredients[element.Id + '|' + element.Position];
            if (ingInfo) {

                if (ingInfo.ingredient.Type !== 'Goods') {
                    //Caso Sottoricetta non ci sono i costi per azienda
                    ricetta.Prices.forEach((x: any) => {
                        let cmp = x.Company.toUpperCase();
                        if (!byCompanyTotal[cmp]) {
                            byCompanyTotal[cmp] = 0;
                        }
                    });

                    for (let cmp in byCompanyTotal) {
                        byCompanyTotal[cmp] += ingInfo.info.total;
                    }
                } else {
                    for (let cmp in ingInfo.info.byCompany) {
                        if (!byCompanyTotal[cmp]) {
                            byCompanyTotal[cmp] = 0;
                        }
                        byCompanyTotal[cmp] += ingInfo.info.byCompany[cmp].Total;
                    }
                }
            }
        });
        return byCompanyTotal;
    }

    /**
    * Aggiorna i valori della tabella dei Prezzi per azienda
    */
    updateTabellaCalcolo(ricetta: any, byCompanyTotal: any) {

        let addedCost = ricetta.AddedCost ? util.parseNumber(ricetta.AddedCost, this.locale) : 0;
        let vat = ricetta.Vat ? util.parseNumber(ricetta.Vat, this.locale) : 0;
        let factor = ricetta.Factor ? util.parseNumber(ricetta.Factor, this.locale) : 0;

        if (!ricetta || !ricetta.Prices) return;

        ricetta.Prices.forEach((element: any) => {
            let tot = byCompanyTotal[element.Company.toUpperCase()] / util.parseNumber(ricetta.Yield, this.locale);
            let pvImposto = element['PVImposto'] ? util.parseNumber(element['PVImposto'], this.locale) : 0;
            element['FoodCost'] = tot;
            element['FoodCostLordo'] = tot * (1 + addedCost / 100);
            let pv = element['FoodCostLordo'] * factor;
            element['PvTeorico'] = pv * (1 + vat / 100);
            element['MdcTeorico'] = pv - element['FoodCostLordo'];
            element['FcTeorico'] = element['FoodCostLordo'] * 100 / pv;

            element['MdcImposto'] = pvImposto * (100 / (100 + vat)) - element['FoodCostLordo']
            if ((pvImposto - (pvImposto * vat / (100 + vat))) == 0) {
                element['FCImposto'] = 0;
            }
            else {
                element['FCImposto'] = element['FoodCostLordo'] / (pvImposto - (pvImposto * vat / (100 + vat))) * 100
            }

            element['FattoreImposto'] = pvImposto * (100 / (100 + vat)) / element['FoodCostLordo'];

        });
        return ricetta;
    }

    calcoloSingoloIngrediente(ingredient: any, changedColumn: any = 'Quantity', columnToModify: any = undefined) {

        let ingredientInfo = this.getTotaleIngrediente(ingredient);

        let wasteView = ingredientInfo.wasteView;
        let qtaLorda = ingredientInfo.qtaLorda;
        let qty = ingredientInfo.qty;

        if (columnToModify === "Waste") {
            switch (this.optScarto) {
                case 0:
                    wasteView = (qtaLorda / qty - 1) * 100;
                    break;
                case 1:
                    wasteView = (qty / qtaLorda + 1) * 100;
                    break;
                case 2:
                    wasteView = qtaLorda * 100 / qty;
                    break;
            }

        } else {
            // Se Waste o Quantity
            switch (this.optScarto) {
                case 0:
                    {

                        switch (changedColumn) {
                            case "QtaLorda":

                                qty = qtaLorda / (1 + wasteView / 100);
                                break;
                            case "Quantity":

                                qtaLorda = qty * (1 + wasteView / 100);
                                break;
                            case "wasteView":

                                qtaLorda = qty * (1 + wasteView / 100);
                                break;

                        }
                    }
                    break;
                case 1:
                    {
                        switch (changedColumn) {
                            case "QtaLorda":
                                qty = qtaLorda * (1 - wasteView / 100);
                                break;
                            case "Quantity":
                                qtaLorda = qty / (1 - wasteView / 100);
                                break;
                            case "wasteView":
                                qtaLorda = qty / (1 - wasteView / 100);
                                break;

                        }
                    }
                    break;
                case 2:
                    {
                        //x= lordo * 100 / Netto;
                        switch (changedColumn) {
                            case "QtaLorda":
                                qty = qtaLorda * 100 / wasteView;
                                break;
                            case "Quantity":
                                qtaLorda = qty * wasteView / 100;
                                break;
                            case "wasteView":
                                qtaLorda = qty * wasteView / 100;
                                break;
                        }
                    }
                    break;
            }
        }

        ingredient.wasteView = util.formatNumber(wasteView, this.locale, 2, true);
        ingredient.Quantity = util.formatNumber(qty, this.locale, 2, true);
        ingredient.QtaLorda = util.formatNumber(qtaLorda, this.locale, 2, true);

        return ingredient
    }

    /***************************************************************************************************** */
    /**
     * Ritorna lo scarto in base al fatto che sia sul netto, sul lordo o lordo/netto
     * @param Netto 
     * @param scarto 
     * @param optScarto 
     */
    GetScarto(scartoNetto: number, optScarto = 0) {
        let x = 0;
        let sn = scartoNetto / 100;
        switch (optScarto) {
            case 0:
                //NETTO
                x = sn;
                break;
            case 1:
                //LORDO
                x = sn / (1 + sn);
                break;
            case 2:
                //L/N
                x = 1 + sn;
                break;
        }

        let sc = Math.round(x * 100 * 1000) / 1000;

        return util.formatNumber(sc, this.locale, 2, true);

    }

    format(ricetta: any) {
        let trs = '';
        ricetta.Ingredients.forEach(ingredient => {
            if (ingredient.SubstituteId && ingredient.SubstituteId > 0) return;
            ingredient.wasteView = this.GetScarto(ingredient.Waste, this.optScarto);
            ingredient = this.calcoloSingoloIngrediente(ingredient);
            const isRecipe = ingredient.Type.toLowerCase() == 'subrecipe' || ingredient.Type.toLowerCase() == 'recipe';
            const typeLabel = isRecipe ? this.translate.instant('COMMONS.YES') : this.translate.instant('COMMONS.NO');
            const typeClass = isRecipe ? 'text-blue' : '';
            const complementClass = ingredient.Complement && ingredient.Complement.trim().length > 0 ? 'active' : '';

            trs += '<tr class="childRow" data-json="' + btoa(JSON.stringify(ricetta)) + '" data-ingredientid="' + ingredient.Id + '" data-ricettaid="' + ricetta.Id + '">' +
                '<td class=""></td>' +
                '<td class=""></td>' +
                '<td class=""></td>' +
                '<td class="ingredientNameCol hasInput text-right">' +
                '<input type="text" class="form-control text-right inputController ' + typeClass + '" data-toggle="tooltip" value="' + ingredient.Goods.Name + ' (' + ingredient.Goods.UnitName + ')">' +
                '</td>' +
                '<td class="ingredientCodeCol">' + ingredient.Goods.Code + '</td>' +
                '<td class="ingredientSubrecipeCol">' +
                typeLabel +
                '</td>' +
                '<td class="ingredientQtaNettaCol hasInput">' +
                '<input type="text" class="form-control text-right inputNumberController" value="' + this.utility.formatNumber(ingredient.Quantity, this.locale) + '">' +
                '</td>' +

                '<td class="ingredientUnitCol">' + ingredient.Unit?.Name + '</td>' +

                '<td class="ingredientQtaLordaCol hasInput">' +
                '<input type="text" class="form-control text-right inputNumberController" value="' + this.utility.formatNumber(ingredient.QtaLorda, this.locale) + '">' +
                '</td>' +
                '<td class="ingredientWasteCol hasInput">' +
                '<input type="text" class="form-control text-right inputNumberController" value="' + this.utility.formatNumber(ingredient.Waste, this.locale) + '">' +
                '</td>' +
                '<td class="ingredientNotaCol text-center">' +
                '<a href="javascript:;" data-toggle="tooltip" title="' + (ingredient.Complement && ingredient.Complement.trim().length > 0 ? ingredient.Complement : '') + '" class="openPopupComplement">' +
                '<i class="fa fa-sticky-note pointer ' + complementClass + '"></i>' +
                '</a>' +
                '</td>' +
                '<td></td>' +
                '</tr>'
                ;
        });

        trs = trs.replace(/\n/g, '');

        // Trick per poter aggiungere dinamicamente il tooltip agli input
        setTimeout(() => {
            $('.inputController').off('mouseover');
            $('.inputController').on('mouseover', (event, index) => {
                const value = $(event.target).val()?.toString() || '';
                $(event.target).attr('title', value);
            });

            $('.openPopupComplement').off('click');
            $('.openPopupComplement').on('click', (event, index) => {
                const tr: any = $(event.target).closest('tr');
                const idIngrediente = tr.data('ingredientid');
                let parentRicetta = JSON.parse(atob(tr.data('json')));
                const ingredientIndex = parentRicetta.Ingredients.findIndex((i: any) => i.Id == idIngrediente);

                if (parentRicetta.Ingredients[ingredientIndex]) {
                    this.openPopupComplement(event, parentRicetta.Ingredients[ingredientIndex]);
                }
            });
        }, 1000);

        return $(trs);
    }

    table: any;

    changePagination(event: PageEvent) {
        this.expandAll = false;
        if (this.pagination.pageIndex != event.pageIndex) {
            this.pagination.pageIndex = event.pageIndex;
            this.table.page(this.pagination.pageIndex).draw('page');
        } else if (this.pagination.pageSize != event.pageSize) {
            this.pagination.pageSize = event.pageSize;
            this.table.page.len(this.pagination.pageSize).draw();
        }
    }

    openPopupComplement(event: any, ingredient: any) {

        if (!this.roleService.isCompanyAdminOrMore()) return;

        const dialogRef = this.dialog.open(InsertNotesComponent, {
            data: {
                note: ingredient.Complement,
                title: this.translate.instant('GESTIONE_RICETTE.EDIT.COMPLEMENTS_POPUP.TITLE'),
                subtitle: this.translate.instant('GESTIONE_RICETTE.EDIT.COMPLEMENTS_POPUP.SUBTITLE')
            },
            width: '600px',
            panelClass: 'InsertNotesComponent'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            if (res.success) {
                if (res.Note && res.Note.trim().length > 0) {
                    $(event.target).closest('a').attr('title', res.Note);
                    if (!$(event.target).hasClass('active')) {
                        $(event.target).addClass('active');
                    }
                }
            }

        });
    }

    saveItem(ricettaId: string) {
        const trs = $(`tr[data-ricettaid=${ricettaId}]`);
        let ricetta = JSON.parse(atob($(trs[0]).data('json')));
        let byCompanyIngredients: any = {};
        trs.each((index, tr) => {
            const idIngrediente = $(tr).data('ingredientid');
            const ingredientIndex = ricetta.Ingredients.findIndex((i: any) => i.Id == idIngrediente);
            ricetta.Ingredients[ingredientIndex].Goods.Name = $(tr).find('.ingredientNameCol input').val();
            //ricetta.Ingredients[ingredientIndex].Type = $(tr).find('.ingredientSubrecipeCol select').val() == 'true' ? 'Subrecipe' : 'Goods';
            ricetta.Ingredients[ingredientIndex].Quantity = $(tr).find('.ingredientQtaNettaCol input').val();
            ricetta.Ingredients[ingredientIndex].QtaLorda = $(tr).find('.ingredientQtaLordaCol input').val();
            ricetta.Ingredients[ingredientIndex].wasteView = $(tr).find('.ingredientWasteCol input').val();
            ricetta.Ingredients[ingredientIndex].Complement = $(tr).find('.ingredientNotaCol a').attr('title');

            let ingredientInfo = this.getTotaleIngrediente(ricetta.Ingredients[ingredientIndex]);
            ricetta.Ingredients[ingredientIndex].Totale = util.formatNumber(ingredientInfo.total, this.locale, 3, true);
            byCompanyIngredients[ricetta.Ingredients[ingredientIndex].Id + '|' + ricetta.Ingredients[ingredientIndex].Position] = { ingredient: ricetta.Ingredients[ingredientIndex], info: ingredientInfo };
        });

        let byCompanyTotal = this.updateTotRicetta(ricetta, byCompanyIngredients);
        ricetta = this.updateTabellaCalcolo(ricetta, byCompanyTotal);

        ricetta.Ingredients.forEach((element: any) => {
            let wasteView = element.wasteView ? util.parseNumber(element.wasteView, this.locale) : 0;
            element.Waste = this.GetDBScarto(wasteView, this.optScarto);
        });

        //UPDATE
        const _title: string = this.translate.instant('GESTIONE_RICETTE.UPDATE_DIALOG.TITLE');
        const _description: string = this.translate.instant('GESTIONE_RICETTE.UPDATE_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('GESTIONE_RICETTE.UPDATE_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((expand: any) => {
            if (expand) {
                this.loading$.next(true);
                ricetta = this.fixNumericFields(ricetta)
                this.gestioneRicetteService.updateRecipe(ricetta).subscribe(
                    (result: any) => {
                        this.loading$.next(false);

                        let message: string = '';
                        if (result.SavedRecipeId) {
                            message = this.translate.instant('GESTIONE_RICETTE.UPDATE_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                        } else {
                            message = this.translate.instant('GESTIONE_RICETTE.UPDATE_DIALOG.ERROR');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                        }
                        //this.goBack(null);
                    }, (error: any) => {
                        this.loading$.next(false);
                    }
                );
            }
        });

    }

    fixNumericFields(ricetta) {

        ricetta.Factor = ricetta.Factor ? util.parseNumber(ricetta.Factor, this.locale) : 0;
        ricetta.Vat = ricetta.Vat ? util.parseNumber(ricetta.Vat, this.locale) : 0;
        ricetta.Yield = ricetta.Yield ? util.parseNumber(ricetta.Yield, this.locale) : 0;

        ricetta.Ingredients.forEach(e => {
            e.Quantity = e.Quantity ? util.parseNumber(e.Quantity, this.locale) : 0;
            e.Waste = e.Waste ? util.parseNumber(e.Waste, this.locale) : 0;
        });

        if (!ricetta.Prices) {
            ricetta.Prices = [];
        }
        ricetta.Prices.forEach(e => {
            e.Price = e.PVImposto ? util.parseNumber(e.PVImposto, this.locale) : 0;
        });

        return ricetta
    }

    /***************************************************************************************************** */
    /**
     * Ritorna lo scarto netto da salvare nel DB indipendentemente dal formato di visualizzazione
     * @param Netto 
     * @param scarto 
     * @param optScarto 
     */
    GetDBScarto(scarto: number, optScarto = 0) {
        let lordo = 0;
        let x = 0;
        let s = scarto / 100;
        switch (optScarto) {
            case 0:
                //arriva già Netto
                x = s;
                break;
            case 1:
                //arriva Lordo
                x = s / (1 - s);
                break;
            case 2:
                //arriva L/N
                x = s - 1;
                break;
        }
        return Math.round(x * 100 * 100) / 100;
    }

    editItem(ricetta: any) {
        this.gestioneRicetteService.getSingleRecipe(ricetta.Id).toPromise().then(
            (result: any) => {
                const dialogRef = this.dialog.open(EditRicettaComponent, {
                    data: {
                        ricetta: result
                    },
                    width: '100%',
                    height: '100%'
                });
            });
    }

    expandAllRows() {
        this.expandAll = !this.expandAll;
        const trList = $('#tableManutenzioneRicette tbody tr');
        trList.each((index: number, tr: any) => {
            const row = this.table.row(tr);
            if (!this.expandAll) {
                // This row is already open - close it
                row.child.hide();
                $(tr).removeClass('shown');
            } else {
                // Open this row   
                const ricetta = row.data();
                if (ricetta) {
                    this.gestioneRicetteService.getSingleRecipe(ricetta.Id).toPromise()
                        .then(singleRecipe => {
                            row.child(this.format(singleRecipe)).show();
                            $(tr).addClass('shown');
                        });
                }
            }
        });


    }

}
