import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { stat } from 'fs';
import moment from 'moment';

@Component({
    selector: 'kt-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

    @Input() DateSelectionType: string = '';
    @Input() dayWeek: any;
    @Input() aziende: any;
    @Input() categorie: any;
    @Input() prodotti: any;
    @Input() costCenters: any;
    @Input() fbType: any;
    @Input() bestItems!: number;
    @Input() period: string = '';
    @Input() dateStart: any;
    @Input() dateList: any;
    @Input() dateEnd: any;
    @Input() gruppi: any;
    @Input() reparti: any;
    @Input() enableState: string = '';
    @Input() visibleState: string = '';
    @Input() recipeType: string = '';
    @Input() goodsOrSubrecipeName: string = '';
    @Input() fornitori: any;
    @Input() gruppiMerci: any;
    @Input() timeInterval: any;
    @Input() periodTranslate: string = '';

    constructor(
        private translate: TranslateService,
        private translationService: TranslationService,
    ) { }

    ngOnInit() { }

    removeTime(date: string) {
        const dateInfo = date.split(',');
        return dateInfo[0];
    }

    getDateList() {
        let dateList = '';
        if (this.dateList) {
            this.dateList.forEach((date: any) => {
                dateList += date + ', ';
            });
            dateList = dateList.substring(0, dateList.length - 2);
        }
        return dateList;
    }

    getPeriodDetails() {
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        const locale = 'it';

        //PREVIOUS_YEAR: string = this.translate.instant('FILTRI.PERIOD_TYPE.PREVIOUS_YEAR');
        //CURRENT_YEAR: string = this.translate.instant('FILTRI.PERIOD_TYPE.CURRENT_YEAR');
        switch (this.period.toLowerCase()) {
            case 'onlyyesterday':
                return this.translate.instant('FILTRI.PERIOD_TYPE.ONLYYESTERDAY');
                break;
            case 'yesterday':
                return this.translate.instant('FILTRI.PERIOD_TYPE.YESTERDAY');
            case 'ytd':
                const ytd = new Date();
                ytd.setDate(today.getDate() - 366);
                return this.removeTime(ytd.toLocaleString(locale));
            case 'mtd':
                const mtd = new Date();
                mtd.setDate(today.getDate() - 30);
                return this.removeTime(mtd.toLocaleString(locale));
            case 'year':
                const yStart = new Date(today.getFullYear(), 0, 1);
                const yEnd = new Date();
                yEnd.setDate(today.getDate() - 1);
                return `${this.removeTime(yStart.toLocaleString(locale))} - ${this.removeTime(yEnd.toLocaleString(locale))}`
            case 'month':
                const mStart = new Date(today.getFullYear(), today.getMonth(), 1);
                const mEnd = new Date();
                mEnd.setDate(today.getDate() - 1);
                return `${this.removeTime(mStart.toLocaleString(locale))} - ${this.removeTime(mEnd.toLocaleString(locale))}`
            case 'week':
                const first = today.getDate() - today.getDay() + 1;
                const wStart = new Date(new Date().setDate(first));
                const wEnd = new Date();
                wEnd.setDate(new Date().getDate() - 1);
                return `${this.removeTime(wStart.toLocaleString(locale))} - ${this.removeTime(wEnd.toLocaleString(locale))}`
            case 'day':
                return `${this.removeTime(today.toLocaleString(locale))}`
            case 'start_schedule':
                const endDate = new Date(today.getFullYear(), today.getMonth() + 6, today.getDate());
                return this.translate.instant('FILTRI.PERIOD_TYPE.START_SCHEDULE') + ' - ' + this.removeTime(endDate.toLocaleString(locale));
            case 'previousyear':
                return this.translate.instant('FILTRI.PERIOD_TYPE.PREVIOUS_YEAR');
            case 'currentyear':
                return this.translate.instant('FILTRI.PERIOD_TYPE.CURRENT_YEAR');
        }

        return '';
    }

    showTextPeriod() {
        let show = true;
        switch (this.period.toLowerCase()) {
            case 'day':
            case 'onlyyesterday':
                show = false;
                break;
            case 'ytd':
            case 'yesterday':
            case 'mtd':
            case 'year':
            case 'month':
            case 'week':
            case 'previousyear':
            case 'currentyear':
                show = true;
                break;
        }

        return show;
    }

    nextDay() {
        switch (this.period.toLowerCase()) {
            case 'day':
                this.period = moment().format('YYYY-MM-DD');
                break;
            case 'onlyyesterday':
                this.period = moment().subtract(1, 'day').format('YYYY-MM-DD');
                break;
            case 'ytd':
            case 'yesterday':
            case 'mtd':
            case 'year':
            case 'month':
            case 'week':

                break;
            default:

                break;
        }
    }

    getTooltips(list: any[]) {
        let ret: string = '';
        list.forEach((item: any) => {
            if (item) {
                if (ret && ret.length > 0) ret += ', ';
                ret += item.Name ? item.Name : item;
            };
        });
        return ret;
    }

    getRecipeTypeStr(recipeType: string) {
        return this.translate.instant(`GESTIONE_RICETTE.RECIPE_TYPE.${recipeType.toUpperCase()}`);
    }

    getThreeState(state: any) {
        const threeStateIndex = ["NONE", "YES", "NO", "ALL"];
        return this.translate.instant(`FILTRI.THREE_STATE_TYPE.${threeStateIndex[state].toUpperCase()}`);
    }

}
