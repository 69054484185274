import { Component, OnInit, Inject } from '@angular/core';

// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as util from '@app/core/services/utilityfunctions';

@Component({
    selector: 'kt-select-variation-dialog',
    templateUrl: './select-variation-dialog.component.html',
    styleUrls: ['./select-variation-dialog.component.scss']
})
export class SelectVariationDialogComponent implements OnInit {

    locale: string = 'it';
    utility: any = util;
    viewLoading = false;
    constructor(
        public dialogRef: MatDialogRef<any>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {
        console.log(this.data);
        this.locale = this.data.locale;
    }

    getUnitName(unitId: string) {
        const unit = this.data.unitOrd.find((unit: any) => unit.Id == unitId)
        return unit ? unit.Name : '';
    }

    selectVariation(variation: any) {
        this.dialogRef.close({
            success: true,
            variation: variation
        });
    }

}
