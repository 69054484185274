import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslationService } from '@app/core/_base/layout/services/translation.service';

import * as moment from 'moment';

@Component({
  selector: 'kt-months-selector',
  templateUrl: './months-selector.component.html',
  styleUrls: ['./months-selector.component.scss']
})
export class MonthsSelectorComponent implements OnInit {

  months: number[];
  rangeStart: any;
  rangeEnd: any;
  dateStart: any;
  dateEnd: any;

  @Input() isDialog: boolean = true;
  @Output() OnSelectRange = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<any>,
    private translationService: TranslationService
  ) { 
    let counter = 1;
    this.months =  Array(12).fill(1).map(
			(x, i) => counter++
    );
  }

  years: number[] = [];
  selectedYear!:number;
  ngOnInit() {
    const today = new Date();
    this.selectedYear = today.getFullYear();
    for (let index = this.selectedYear-5; index < this.selectedYear+5; index++) {
      this.years.push(index);
    }
  }

  getMonthName(month: number) {
    const today = new Date();
    const date = new Date(today.getFullYear(), month-1, 1);
    return date.toLocaleString(this.translationService.getSelectedLanguage(), { month: 'short' });
  }

  over(month: number) {
    if (this.rangeStart && !this.rangeEnd) {
      for (let index = this.rangeStart; index <= month; index++) {
        if (!$(`#month_${index}`).hasClass('active')) {
          $(`#month_${index}`).addClass('active');
        }
      }
      // Pulisco quelli oltre
      for (let index = month+1; index <= month + (12-month); index++) {
          $(`#month_${index}`).removeClass('active');
      }
    }
  }

  selectMonth(month: number) {
    if (!this.rangeStart) {
      this.rangeStart = month;
      this.rangeEnd = undefined;
      if (!$(`#month_${month}`).hasClass('active')) {
        $(`#month_${month}`).addClass('active');
        console.log
      }

      const today = new Date();
      this.dateStart = moment(new Date(this.selectedYear, month-1, 1));

    } else if (this.rangeStart && !this.rangeEnd) {
      this.rangeEnd = month;
      for (let index = this.rangeStart; index <= this.rangeEnd; index++) {
        if (!$(`#month_${index}`).hasClass('active')) {
          $(`#month_${index}`).addClass('active');
        }
      }

      this.dateEnd = moment(this.dateStart).add(this.rangeEnd-this.rangeStart, 'M').endOf('month');

      this.OnSelectRange.emit({
        dateStart: this.dateStart, 
        dateEnd: this.dateEnd
      });

    } else {
      $(`.monthBtn`).removeClass('active');
      this.rangeStart = undefined;
      this.rangeEnd = undefined;
      this.dateStart = undefined;
      this.dateEnd = undefined;

      this.OnSelectRange.emit(undefined);
    }
  }

  ok() {
    this.dialogRef.close({
      dateStart: this.dateStart,
      dateEnd: this.dateEnd
    });
  }

  changeYear() {
    $(`.monthBtn`).removeClass('active');
    this.rangeStart = undefined;
    this.rangeEnd = undefined;
    this.dateStart = undefined;
    this.dateEnd = undefined;
  }

}
