import { Component, OnInit, Inject } from '@angular/core';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-set-category-dlg',
  templateUrl: './set-category-dlg.component.html',
  styleUrls: ['./set-category-dlg.component.scss']
})
export class SetCategoryDlgComponent implements OnInit {

  categoryId!: string;
  categories: any;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.categories = this.data.categorieProdotti;
  }

  ngAfterViewInit() {
    (<any>$('#select2CategoryBulk')).select2({
      containerCssClass: 'select2Single',
      language: {
          errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
          inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
          inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
          loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
          maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
          noResults: () => this.translate.instant('SELECT2.noResults'),
          searching: () => this.translate.instant('SELECT2.searching')
      }
    });
    $('#select2CategoryBulk').on('select2:select', (e: any) => {
      this.categoryId = e.params.data.id;
    });
    setTimeout(() => {
      $('#select2CategoryBulk').val('').trigger('change');
    }, 100);
  }

  onSubmit() {
    this.dialogRef.close(this.categoryId);
	}

}
