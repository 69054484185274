import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API } from '@env/environment';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { MatDialog } from '@angular/material/dialog';
import { ShowXmlInvoiceDialogComponent } from '@app/views/partials/content/crud/show-xml-invoice-dialog/show-xml-invoice-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class GestioneMerciService {

    constructor(
        private http: HttpClient,
        private switchGroupService: SwitchGroupService,
        private dialog: MatDialog,
        private domSanitizer: DomSanitizer
    ) { }

    getGoodsCategories(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + '/api/Filter/GoodsCategories', { headers: httpHeaders });
    }

    getGoodsGroups(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + '/api/Filter/GoodsGroups', { headers: httpHeaders });
    }

    getSuppliers(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + '/api/Filter/Suppliers', { headers: httpHeaders });
    }

    applyWaste(merceId, wastPercentage) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.post<any>(currentLogin.endpointURL + '/api/Recipe/ApplyWaste', { GoodsId: merceId, Waste: wastPercentage }, { headers: httpHeaders });
    }

    getProducts(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + '/api/Filter/Products', { headers: httpHeaders });
    }

    getImportStatus(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Action': 'upload',
            'Authorization': 'Bearer ' + currentLogin.access_token

        });
        const url = `/api/Import/LogStatus?type=goods`;
        return this.http.get(currentLogin.endpointURL + url, { headers: httpHeaders })
            .pipe(
                map((result: any) => {

                    return result;
                })
            );
    }

    mergeGoods(body: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Action': 'upload',
            'Authorization': 'Bearer ' + currentLogin.access_token

        });
        const url = `/api/Entity/mergeGoods`;
        return this.http.post(currentLogin.endpointURL + url, body, { headers: httpHeaders })
            .pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    unlock(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Action': 'upload',
            'Authorization': 'Bearer ' + currentLogin.access_token

        });
        const url = `/api/Entity/UnlockImporting?type=goods`;
        return this.http.get(currentLogin.endpointURL + url, { headers: httpHeaders })
            .pipe(
                map((result: any) => {

                    return result;
                })
            );
    }

    uploadMerci(file): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Action': 'upload',
            'Authorization': 'Bearer ' + currentLogin.access_token

        });
        const url = `/api/Fe/UploadXml`;
        return this.http.post(currentLogin.endpointURL + url, file, { headers: httpHeaders })
            .pipe(
                map((result: any) => {

                    return result;
                })
            );
    }

    uploadFile(file): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Action': 'upload',
            'Authorization': 'Bearer ' + currentLogin.access_token

        });
        const url = `/api/Import/UploadGoods`;
        return this.http.post(currentLogin.endpointURL + url, file, { headers: httpHeaders })
            .pipe(
                map((result: any) => {

                    return result;
                })
            );
    }

    dowloadFile(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Action': 'upload',
            'Authorization': 'Bearer ' + currentLogin.access_token

        });
        const url = `/api/Import/DownloadGoods`;
        return this.http.get(currentLogin.endpointURL + url, { headers: httpHeaders })
            .pipe(
                map((result: any) => {

                    return result;
                })
            );
    }

    async isSupplierUsed(goodsId, supplierId, variationCode = undefined) {

        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        let url = currentLogin.endpointURL + `/api/Supplier/IsSupplierUsed?goodsId=${goodsId}&supplierId=${supplierId}&code=`;
        if (variationCode) {
            url += `${variationCode}`;
        }
        const observable: Observable<boolean> = this.http.get<any>(url, { headers: httpHeaders });
        const v = await observable.toPromise();
        return v;
    }

    getGoods(goodsFilter: any, collapse: boolean = true, bestPrice: boolean = false, internal: boolean = false): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.post<any>(currentLogin.endpointURL + `/api/Entity/Goods?collapse=${collapse}&bestPrice=${bestPrice}&isInternalOrder=${internal}`, goodsFilter, { headers: httpHeaders });
    }

    getSingleGoods(goodId: string, AddSupplierId: string = ''): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const addSupplierParam = AddSupplierId ? `&AddSupplierId=${AddSupplierId}` : '';

        return this.http.get<any>(currentLogin.endpointURL + '/api/Entity/SingleGoods?id=' + goodId + addSupplierParam, { headers: httpHeaders });
    }

    getGoodsPaged(goodsFilter: any, pagination: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });
        const url = `/api/Entity/GoodsPage?page=${pagination.page}&pagesize=${pagination.pageSize}&sort=${pagination.sort}&sortMode=${pagination.sortMode}`;
        return this.http.post<any>(currentLogin.endpointURL + url, goodsFilter, { headers: httpHeaders });
    }

    saveGoods(good: any) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.post<any>(currentLogin.endpointURL + '/api/Entity/SaveGoods', good, { headers: httpHeaders });
    }

    updateGoods(good: any, linkedId: string = '') {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.put<any>(currentLogin.endpointURL + '/api/Entity/UpdateGoods', {
            good: good,
            linkedId: linkedId
        }, { headers: httpHeaders });
    }

    deleteGoods(goodId: string) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.delete<any>(currentLogin.endpointURL + '/api/Entity/DeleteGoods?id=' + goodId, { headers: httpHeaders });
    }

    getGoodsTable(goodsFilter: any, pagination: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const url = `/api/Entity/GoodsTable?page=${pagination.page}&pagesize=${pagination.pageSize}&sort=${pagination.sort}&sortMode=${pagination.sortMode}`;

        return this.http.post<any>(currentLogin.endpointURL + url, goodsFilter, { headers: httpHeaders });
    }

    /**
     * Id: <ID DELLA MERCE>,
     * Company: <NOME AZIENDA>,
     * IsVisible: true/false
     * @param good
     */
    setGoodsVisibility(good: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.put<any>(currentLogin.endpointURL + '/api/Entity/GoodsVisibility', good, { headers: httpHeaders });
    }

    setAllGoodsVisibility(goods: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.put<any>(currentLogin.endpointURL + '/api/Entity/AllGoodsVisibility', goods, { headers: httpHeaders });
    }

    getFirstAvailableGoodCode() {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });
        return this.http.get<any>(currentLogin.endpointURL + '/api/Entity/NextGoodsCode', { headers: httpHeaders });
    }

    getHashByName(name: string) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });
        return this.http.get<any>(currentLogin.endpointURL + '/api/Entity/HashCode?name=' + name, { headers: httpHeaders });
    }

    checkLevenshteinDistance(goodId: any, searchText: string = '', supplierId: string = '') {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        let name = '';
        if (searchText) {
            name = '&name=' + searchText;
        }

        let supplier = '';
        if (supplierId) {
            supplier = '&supplierId=' + supplierId;
        }

        return this.http.get<any>(currentLogin.endpointURL + `/api/Entity/SimilarGoods?goodsId=${goodId}${name}${supplier}`, { headers: httpHeaders });
    }

    getXml(fileName: string) {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const httpOptions: any = {
            headers: new HttpHeaders({
                'Content-Type': 'text/html',
                'Authorization': 'Bearer ' + currentLogin.access_token,
                'Accept': 'text/html, application/xhtml+xml, */*',
            }),
            responseType: 'text'
        };
        return this.http.get<any>(currentLogin.endpointURL + `/api/Fe/Xml?fileName=${fileName}`, httpOptions);
    }

    getXmlByProduct(GoodsId: string, SupplierId: string, GoodsCode: string) {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const httpOptions: any = {
            headers: new HttpHeaders({
                'Content-Type': 'text/html',
                'Authorization': 'Bearer ' + currentLogin.access_token,
                'Accept': 'text/html, application/xhtml+xml, */*',
            }),
            responseType: 'text'
        };
        return this.http.get<any>(currentLogin.endpointURL + `/api/Fe/InvoiceByProduct?GoodsId=${GoodsId}&SupplierId=${SupplierId}&GoodsCode=${GoodsCode}`, httpOptions);
    }

    popupXml: any;

    async performHiglight(html: string, GoodName: string, GoodCode: string, options: any = undefined, invoiceNumber: string = '') {

        const width = options && options.width ? options.width : 830;
        const height = options && options.height ? options.height : 285;
        const top = options && options.top ? options.top : 0;
        const left = options && options.left ? options.left : (screen.width / 2) - 415;


        return new Promise<any>((resolve, reject) => {

            if (!html) {
                reject({
                    error_code: 'GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.NO_INVOICE_PRESENTS'
                });
            } else {

                var parser = new DOMParser();
                var htmlDoc = parser.parseFromString(html, 'text/html');

                let html$ = $(htmlDoc);

                // cerco il codice
                // se il codice non esiste ricerco per corrispondenza esatta del nome
                // se non esiste corrispondenza col nome lascio perdere

                const $table = html$.find('#fattura-elettronica table.tbFoglio:contains(\'Cod. articolo\')').closest('table');
                let $tr = GoodCode ? $table.find(`td:contains(\'${GoodCode}\')`).closest('tr') : null;
                if ($tr && $tr.length == 0 && GoodName) {
                    $tr = $table.find(`td:contains(\'${GoodName}\')`).closest('tr')
                }

                if ($tr && $tr.length > 0) {
                    $tr.css('background-color', 'yellow');
                    $tr.attr('id', GoodCode);
                }

                this.dialog.open(ShowXmlInvoiceDialogComponent, {
                    data: {
                        html: this.domSanitizer.bypassSecurityTrustHtml(new XMLSerializer().serializeToString(html$[0])),
                        code: GoodCode,
                        top: top + 'px',
                        left: left + 'px',
                        invoiceNumber: invoiceNumber
                    },
                    width: width + 50 + 'px',
                    height: height + 'px',
                    hasBackdrop: false,
                    panelClass: 'matDialogNoPadding'
                });

                resolve(true);

            }

        });

    }

    openXmlHighlightedByProduct(GoodName: string, GoodId: string, SupplierId: string, GoodCode: string, options: any = undefined) {
        return new Promise<any>((resolve, reject) => {
            this.getXmlByProduct(GoodId, SupplierId, GoodCode).toPromise()
                .then((html: any) => {
                    return this.performHiglight(html, GoodName, GoodCode, options, GoodName);
                })
                .catch((err: any) => {
                    reject({
                        error_code: 'GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.NO_INVOICE_PRESENTS'
                    });
                });
        });
    }

    openXmlHighlightedByFileName(FileName: string, GoodName: string, GoodCode: string, options: any = undefined) {
        return new Promise<any>((resolve, reject) => {
            lastValueFrom(this.getXml(FileName))
                .then((html: any) => {
                    return this.performHiglight(html, GoodName, GoodCode, options, FileName);
                })
                .catch((err: any) => {
                    reject({
                        error_code: 'GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.NO_INVOICE_PRESENTS'
                    });
                });
        });
    }

    createGoodsFromInvoices(invoicesList: any, categoryId: string = '', presets: any = []) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.post<any>(currentLogin.endpointURL + '/api/Fe/CreateGoods', {
            CategoryId: categoryId,
            FileNames: invoicesList,
            Presets: presets
        }, { headers: httpHeaders });
    }

    checkStatus() {
        const currentLogin = this.switchGroupService.getCurrentGroup();
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + `/api/Check/Status`, { headers: httpHeaders });
    }

    reenableVariation(goodId: any, supplierId: string) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(currentLogin.endpointURL + `/api/Entity/EnableGoodsVariation?enable=true&goodsId=${goodId}&supplierId=${supplierId}`, { headers: httpHeaders });
    }

}
