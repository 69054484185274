import { Component, OnInit, ChangeDetectorRef, Inject, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

import { BehaviorSubject, lastValueFrom } from 'rxjs';

import { CdkDragDrop, CdkDragEnter, CdkDragExit, CdkDragStart, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Utilities
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import * as util from '@app/core/services/utilityfunctions';

// Components
import { EditMerciComponent } from '@app/views/pages/gestione-dati/gestione-merci/edit-merci/edit-merci.component';
import { FornitoriEditComponent } from '@app/views/pages/anagrafiche/fornitori/fornitori-edit/fornitori-edit.component';
import { LayoutUtilsService, MessageType } from '@app/core/_base/crud';
import { MergeGoodsComponent } from '@app/views/pages/gestione-dati/gestione-merci/merge-goods/merge-goods.component';
import { Preset, PresetGoodsDialogComponent } from '../preset-goods-dialog/preset-goods-dialog.component';
import { CategorieMerciEditComponent } from '@app/views/pages/anagrafiche/categorie-merci/categorie-merci-edit/categorie-merci-edit.component';
import { MapStructureComponent } from '../map-structure/map-structure.component';
import { MonthsSelectorComponent } from '../../widgets/filtri/months-selector/months-selector.component';
import { GestioneFattureService } from '@app/views/pages/gestione-dati/gestione-fatture/gestione-fatture.service';
import { SetSupplierDialogComponent } from '../set-supplier-dialog/set-supplier-dialog.component';
import { BulkEditNewMerciComponent } from '../bulk-edit-new-merci/bulk-edit-new-merci.component';
import { BulkActionsService } from '@app/core/services/bulk-actions.service';

declare var $: any;

@Component({
    selector: 'kt-imported-purchases',
    templateUrl: './imported-purchases.component.html',
    styleUrls: ['./imported-purchases.component.scss']
})
export class ImportedPurchasesComponent implements OnInit {

    todoList!: CdkDropList;
    @ViewChild('searchInput', { static: false }) set _todoList(content: CdkDropList) {
        if (content) { // initially setter gets called with undefined
            this.todoList = content;
        }
    }
    doneList!: CdkDropList;
    @ViewChild('searchInput', { static: false }) set _doneList(content: CdkDropList) {
        if (content) { // initially setter gets called with undefined
            this.doneList = content;
        }
    }

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    companiesList: any;
    daSmistare: any;
    utility: any;
    locale!: string;
    currentIndex: any;
    showHiddenInvoices: boolean = false;
    showExcludedInvoices: boolean = false;
    showMissingSuppliers: boolean = false;
    showImportable: boolean = false;
    showNotImportable: boolean = false;
    invoiceType: string = 'Purchase';

    filter_itm_BySupplier: string = '';
    filter_itm_ByVAT: string = '';

    showBulkMove: boolean = false;
    showSelectedXml: any = [];
    showOnlySelected: boolean = false;
    companyTargetToMove: any = undefined;

    selectAll: boolean = false;

    filterByDate: any;

    enableBulkActions: boolean = false;
    currentSelectedInvoice: any;
    bulkSelect: string = '';

    selectAllSupplier: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        private magazzinoService: MagazzinoService,
        private ref: ChangeDetectorRef,
        public domSanitizer: DomSanitizer,
        private dialog: MatDialog,
        private gestioneMerciService: GestioneMerciService,
        public staticCollectionsService: StaticCollectionsService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private gestioneFattureService: GestioneFattureService,
        private bulkActionsService: BulkActionsService
    ) {
        this.utility = util;
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });

        this.showSelectedXml = [];
    }


    async ngOnInit() {
        // await this.staticCollectionsService.fillStaticCollection(false);
        this.refreshList();

        setTimeout(() => {
            $('#bulkSelectHeader').select2({
                templateResult: (option: any) => {
                    if (!option.id) {
                        return option.text;
                    }

                    const $option = $(
                        `<span><i class="img-flag ${option.element.dataset.icon} text-warning" /></i>&nbsp;${option.text}</span>`
                    );
                    return $option;
                },
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });


            $('#bulkSelectHeader').on('select2:select', (e: any) => {
                const value = e.params.data.id;
                this.bulkSelectionHeader(value);
                $('#bulkSelectHeader').val('').trigger('change');
            });

        }, 1000);
    }

    updateHeight() {
        setTimeout(() => {
            const elements: any = [
                20, // Padding-top kt-portlet__body    
                $('#leftPane .legendAccordion').height(), // Altezza accordion legenda
                $('#leftPane .accordion-dnd-container').height(), // Altezza accordion punti vendita
                $('#leftPane .dnd-container:nth-child(2) #filterRow').height(), // Input Filtro + checkbox
                5, //v_spacer_half
                36, // filter_itm_ByVAT
                $('#leftPane .dnd-container:nth-child(2) #moveToCompanyRow').height(), // Input Filtro + checkbox
                20, // Padding-bottom kt-portlet__body
                90 // margini vari 
            ]

            let hDisp: any = $('.kt-form.kt-form--main').height();

            elements.forEach((el: any) => {
                hDisp -= el || 0;
            })
                ;
            $('#daSmistareList').css('height', `${hDisp}px`)
        }, 250);
    }

    refreshList(currentCompany: any = null) {

        this.loading$.next(true);
        lastValueFrom(this.magazzinoService.getAcquistiImportati(this.invoiceType === 'Other'))
            .then((list: any) => {
                console.log('getAcquistiImportati', list);
                this.daSmistare = list.find((i: any) => i.Store === null);

                if (this.daSmistare && this.daSmistare.Documents) {
                    this.daSmistare.Documents.forEach((s: any) => {
                        const index = this.staticCollectionsService.fornitoriTutti$.findIndex((f: any) => f.Id == s.Check.SupplierId);
                        s.supplierExists = index >= 0;
                    })
                }

                this.companiesList = list.filter((i: any) => i.Store !== null);

                this.companiesList.forEach((company: any) => {
                    company = company.Documents.sort((a: any, b: any) => {
                        return a.OriginName.localeCompare(b.OriginName);
                    });
                });

                // Imposto l'altezza delle due liste di sinistra
                this.updateHeight();

                if (currentCompany) {
                    this.currentCompany = this.companiesList.find((c: any) => c.Store == currentCompany.Store);
                    this.currentCompany.isActive = true;

                    this.currentCompany.Documents.forEach((doc: any) => {
                        doc.importable = this.getIsImportable(doc.Check);
                        if (doc.Check) {
                            if (doc.Check.ErrorType === 'SupplierNotFound') {
                                doc.SupplierNotFound = true;
                            } else if (doc.Check.ErrorType == 'WarningSupplierName') {
                                doc.WarningSupplierName = true;
                            }
                            else if (doc.Check.ErrorType == 'SupplierNotMappedToProfitAndLoss') {
                                doc.SupplierNotMappedToProfitAndLoss = true;

                            }
                        }
                    });
                    this.currentCompany.DocumentsOriginal = JSON.parse(JSON.stringify(this.currentCompany.Documents));

                    // Se ci sono dei filtri attivi li ripristino;                    
                    this.filterInvoices();
                    // https://mantis.fbmanager.com/view.php?id=1474
                    this.onExpandPanel(this.currentCompany.DocumentsFiltered[this.currentIndex], this.currentIndex)

                } else if (this.companiesList && this.companiesList.length >= 0) {
                    this.setCurrentCompany(this.companiesList[0]);
                }
            })
            .catch((err: any) => {
                console.log(err);
            })
            .finally(() => {
                this.loading$.next(false)
            });
    }

    getTitle() {
        return 'title';
    }

    currentInvoice: any;
    drag(event: CdkDragStart) {
        try {
            this.currentInvoice = event.source.element.nativeElement.getAttribute('data-filename');
        } catch (error) {
            this.currentInvoice = undefined;
        }
    }

    dropInvoicesList(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.currentCompany.DocumentsFiltered, event.previousIndex, event.currentIndex);
    }

    checkFilter_itm_BySupplier(item: any) {
        try {
            const supplierFilter = item.OriginName.toLowerCase().includes(this.filter_itm_BySupplier.toLowerCase()) ||
                item.DestinationAddress.toLowerCase().includes(this.filter_itm_BySupplier.toLowerCase()) ||
                item.OriginCode.toLowerCase().includes(this.filter_itm_BySupplier.toLowerCase());
            const vatFilter = this.filter_itm_ByVAT ? item.DestCode.toLowerCase().includes(this.filter_itm_ByVAT.toLowerCase()) : true;

            return supplierFilter && vatFilter
        } catch (error) {
            return true;
        }
    }

    currentCompanyOver: any;
    mouseover(company: any) {
        this.currentCompanyOver = company;
        company.isOver = true;
    }

    ondragover(item: any) {
        item
    }

    mouseleave(company: any) {
        company.isOver = false;
        this.currentCompanyOver = undefined;
    }

    drop(event: CdkDragDrop<string[]>) {
        if (!this.currentInvoice || !this.currentCompanyOver || !this.currentCompanyOver.Store) return;

        this.loading$.next(true);
        this.magazzinoService.moveAcquistiImportati(this.currentInvoice.trim(), this.currentCompanyOver.Store.trim()).toPromise()
            .then((res: any) => {
                if (this.currentCompany) {
                    this.refreshList(this.companiesList.find((c: any) => c.Store == this.currentCompany.Store));
                } else {
                    this.refreshList();
                }
            })
            .catch((err: any) => {
                console.log(err);
                this.loading$.next(false);
            });
    }

    unlinkInvoice(event: any, fileName: string) {
        event.stopPropagation();
        this.loading$.next(true);
        this.magazzinoService.moveAcquistiImportati(fileName, '').toPromise()
            .then((res: any) => {
                console.log(res);
                this.refreshList(this.currentCompany);
            })
            .catch((err: any) => {
                console.log(err);
                this.loading$.next(false);
            });
    }

    currentScrollIndex: number = 0;
    maxItemsPerPage: number = 20;
    onScroll() {
        this.currentScrollIndex++;
        const from = this.maxItemsPerPage * this.currentScrollIndex;
        const to = this.maxItemsPerPage * this.currentScrollIndex + this.maxItemsPerPage;
        this.currentCompany.DocumentsFiltered = this.currentCompany.DocumentsFiltered.concat(this.currentCompany.Documents.slice(from, to));

        this.ref.detectChanges();
    }

    currentCompany: any;
    async setCurrentCompany(company: any, clearIndex: boolean = true) {

        this.clearFilters(false);

        this.selectAllSupplier = false;

        if (clearIndex) {
            this.currentIndex = undefined;
            // Inizializzo anche tutte le checkbox della lista da smistare
            if (this.daSmistare && this.daSmistare.Documents && this.daSmistare.Documents.length > 0) {
                this.daSmistare.Documents.forEach((i: any) => i.Checked = false);
            }
        }

        // Resetto higlight
        this.companiesList.forEach((c: any) => c.isActive = c.isOver = false);

        company.isActive = true;

        this.currentCompany = company;

        company.Documents.forEach((doc: any) => {
            doc.importable = this.getIsImportable(doc.Check);
            if (doc.Check) {
                if (doc.Check.ErrorType === 'SupplierNotFound') {
                    doc.SupplierNotFound = true;
                } else if (doc.Check.ErrorType == 'WarningSupplierName') {
                    doc.WarningSupplierName = true;
                }
                else if (doc.Check.ErrorType == 'SupplierNotMappedToProfitAndLoss') {
                    doc.SupplierNotMappedToProfitAndLoss = true;

                }
            }
        });

        this.currentScrollIndex = 0;
        // mi salvo gli originali
        this.currentCompany.DocumentsOriginal = JSON.parse(JSON.stringify(company.Documents));
        this.currentCompany.DocumentsFiltered = company.Documents.slice(this.currentScrollIndex, this.maxItemsPerPage);

        if (this.currentIndex >= 0) {

            while (this.currentIndex >= this.currentCompany.DocumentsFiltered.length && this.currentCompany.DocumentsFiltered.length > 0) {
                this.currentScrollIndex++;
                const from = this.maxItemsPerPage * this.currentScrollIndex;
                const to = this.maxItemsPerPage * this.currentScrollIndex + this.maxItemsPerPage;
                this.currentCompany.DocumentsFiltered = this.currentCompany.DocumentsFiltered.concat(this.currentCompany.Documents.slice(from, to))
            }

            this.onExpandPanel(this.currentCompany.DocumentsFiltered[this.currentIndex], this.currentIndex)
        }
    }

    checkSearch(event: any) {
        if (event.code == 'Enter' || event.code === 'NumpadEnter') {
            setTimeout(() => {
                this.filterInvoices(true);
            }, 100);
        } else if (event.code == 'Backspace' && (!this.filterInvoice || this.filterInvoice.length <= 1)) {

            setTimeout(() => {
                this.filterInvoices(true);
            }, 100);
        }
    }

    filterInvoice!: string;
    supplierdStartsWith: boolean = false;

    selectMonth() {
        const dialogRef = this.dialog.open(MonthsSelectorComponent, { data: {}, width: '980px' });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                this.filterByDate = undefined;
                return;
            }
            this.filterByDate = res;
            this.filterInvoices(true);
        });
    }

    getButtonFilterDate() {
        if (!this.filterByDate) {
            return this.translate.instant('FILTRI.PERFORM_MONTH_RANGE')
        }
        if (this.filterByDate.dateStart.format('MMM') == this.filterByDate.dateEnd.format('MMM')) {
            return this.filterByDate.dateStart.format('YYYY') + ' ' + this.filterByDate.dateStart.format('MMM');
        } else {
            return this.filterByDate.dateStart.format('YYYY') + ' ' + this.filterByDate.dateStart.format('MMM') + '-' + this.filterByDate.dateEnd.format('MMM');
        }
    }

    clearFilters(applyFilter: boolean = true) {
        this.filterByDate = undefined;
        this.supplierdStartsWith = false;
        this.filterInvoice = '';

        if (applyFilter) this.filterInvoices(true);
    }

    setSupplier(event: any, invoice: any) {
        event.preventDefault();
        event.stopPropagation();
        const dialogRef = this.dialog.open(SetSupplierDialogComponent, {
            width: '600px',
            data: {
                suppliers: this.staticCollectionsService.fornitoriTutti$,
                supplierId: [invoice.supplierId]
            }
        }).afterClosed().subscribe(async (result: any) => {
            this.linkSupplier(result.supplierId, invoice)
        });
    }

    someFiltersActive() {
        return this.filterInvoice ||
            this.showNotImportable ||
            this.showImportable ||
            this.showMissingSuppliers ||
            this.showExcludedInvoices ||
            this.showHiddenInvoices ||
            this.filterByDate;
    }

    filterInvoices(clearIndex: boolean = false) {
        this.currentCompany.Documents = this.currentCompany.DocumentsOriginal.filter((doc: any) => {
            let textResult = true;
            let showNotImportable = true;
            let showImportable = true;
            let showMissingSuppliers = true;
            let showExcludedInvoices = true;
            let showHiddenInvoices = true;
            if (this.supplierdStartsWith && this.filterInvoice) {
                const originName = doc.OriginName ? doc.OriginName.toLowerCase().startsWith(this.filterInvoice.toLowerCase()) : undefined;
                textResult = originName;
            } else if (this.filterInvoice) {
                const filename = doc.FileName ? doc.FileName.toLowerCase().indexOf(this.filterInvoice.toLowerCase()) >= 0 : undefined;
                const originName = doc.OriginName ? doc.OriginName.toLowerCase().indexOf(this.filterInvoice.toLowerCase()) >= 0 : undefined;
                const rows = doc.Check ? doc.Check.Rows.find((r: any) => r.Description.toLowerCase().indexOf(this.filterInvoice.toLowerCase()) >= 0) : undefined;
                textResult = filename || originName || rows;
            }
            if (this.showNotImportable) {
                showNotImportable = !doc.importable;
            }
            if (this.showImportable) {
                showImportable = doc.importable && !doc.Check.IsToSplit;
            }
            if (this.showMissingSuppliers) {
                showMissingSuppliers = doc.Check && doc.Check.ErrorType == 'SupplierNotFound';
            }
            if (this.showExcludedInvoices) {
                showExcludedInvoices = doc.Check && doc.Check.IsDisabled;
            }
            if (this.showHiddenInvoices) {
                showHiddenInvoices = doc.IsHidden;
            } else {
                showHiddenInvoices = !doc.IsHidden;
            }

            let dateResult = true;
            if (this.filterByDate) {
                this.filterByDate
                dateResult = moment(doc.Date).isSameOrAfter(this.filterByDate.dateStart) &&
                    moment(doc.Date).isSameOrBefore(this.filterByDate.dateEnd);
            }

            return textResult && dateResult && showNotImportable && showImportable &&
                showMissingSuppliers && showExcludedInvoices && showHiddenInvoices;

        });

        if (clearIndex) {
            this.currentIndex = undefined;
        }

        this.currentCompany.DocumentsFiltered = [];
        this.currentScrollIndex = -1;
        this.onScroll();
    }

    performShowExcludedInvoices() {
        // Se passo a mostra a nascondi fatture escluse, 
        // elimino le eventuali selezioni fatte
        if (this.showExcludedInvoices) {
            this.performDeSelectAllSupplier();
        }
        this.showExcludedInvoices = !this.showExcludedInvoices;
        this.filterInvoices(true);
    }

    performShowNotImportable() {
        this.showNotImportable = !this.showNotImportable;
        this.filterInvoices(true);
    }

    performShowImportable() {
        this.showImportable = !this.showImportable;
        this.filterInvoices(true);
    }

    performShowMissingSupplier() {
        this.showMissingSuppliers = !this.showMissingSuppliers;
        this.filterInvoices(true);
    }

    performShowAllInvoice() {
        this.showHiddenInvoices = !this.showHiddenInvoices;
        this.filterInvoices(true);
        //this.currentCompany.DocumentsFiltered.forEach((i: any) => i.IsHidden = false);
    }

    getDocNumbers(docs: any) {
        var n = docs.length;
        let i = 0;
        docs.forEach((element: any) => {
            if (!element.Check.IsDisabled) i++;
        });
        return i + '/' + n;
    }
    highlightWhole(html: string): string {
        var parser = new DOMParser();
        var htmlDoc = parser.parseFromString(html, 'text/html');
        let html$ = $(htmlDoc);
        html$.find(`#fattura-container`).addClass(`highlightWhole`);
        return new XMLSerializer().serializeToString(html$[0]);
    }

    hideGoods(html: string, good: any): string {
        //var parser = new DOMParser();
        //var htmlDoc = parser.parseFromString(html, 'text/html');
        //let html$ = $(htmlDoc);
        //const find = html$.find(`*:contains('${good.Description}')`).last();
        //$(find).closest('tr').hide();
        //return new XMLSerializer().serializeToString(html$[0]);
        return html;
    }

    highlightGoods(html: string, good: any, supplierId: string, mode: string = 'danger', index: number): string {
        var parser = new DOMParser();
        var htmlDoc = parser.parseFromString(html, 'text/html');

        if (good.ErrorType == 'GoodsNotFound') {
            console.log(good);
        }

        const tooltip = mode == 'warning' ? this.translate.instant(`MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.STATUS.WarningGoodsName`) :
            (mode == 'danger' ? this.translate.instant(`MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.STATUS.GoodsNotFound`) : this.translate.instant(`MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.STATUS.GoodsNotMapped`));

        let html$ = $(htmlDoc);

        const $table = html$.find('#fattura-elettronica table.tbFoglio:contains(\'Cod. articolo\')').closest('table');
        //const filteredRow = $table.find(`tbody tr:nth-child(${index+1})`);
        const filteredRow = $table.find(`tbody tr[rownumber=${good.RowNumber}]`);

        // TODO: Gestire le situazioni con più merci con lo stesso nome, in questo caso la filteredRow avrebbe più elementi
        // bisogna andare a capire in base alla posizione dell'elemento nell'array anche la posizione 
        // nella filteredRow

        if (filteredRow.length > 0) {
            filteredRow.addClass(`highlight ${mode}`);
            filteredRow.addClass('pos-relative');
            filteredRow.find('td:nth-child(2)').addClass('xmltooltip');
            filteredRow.find('td:nth-child(2)').prepend(`<span class="xmltooltiptext">${tooltip}: ${good.MB_Name ? good.MB_Name : good.Description}</span>`);
            filteredRow.attr('data-goodid', good.GoodsId);
            filteredRow.attr('data-supplierid', supplierId);
            //filteredRow.attr('data-rownumber', good.RowNumber);
            filteredRow.attr('data-isexcluded', good.IsExcluded);
            if (good.ReplacedGoods && Object.keys(good.ReplacedGoods).length > 0) {
                filteredRow.attr('data-replacedgoods', JSON.stringify(good.ReplacedGoods));
            }
            if (this.invoiceType == 'Purchase' && good.ErrorType == 'GoodsNotMappedOnPL') {
                filteredRow.attr('data-categorynotmapped', 'true');
                filteredRow.attr('data-categoryid', good.GoodsCategoryId);
            }
        }

        return new XMLSerializer().serializeToString(html$[0]);
    }

    highlightSupplier(html: string, mode: string = 'danger'): string {
        var parser = new DOMParser();
        var htmlDoc = parser.parseFromString(html, 'text/html');
        let html$ = $(htmlDoc);
        let denominazione$ = html$.find(`.tdHead:first-child div.headContent:contains('Denominazione')`);
        // Se non c'è provo a cercare sul campo "Cognome nome"
        if (denominazione$.length == 0) {
            denominazione$ = html$.find(`.tdHead:first-child div.headContent:contains('Cognome nome')`);
        }

        if (denominazione$.length > 0) {
            const selector$ = $(denominazione$.get(0)).find(`span`);
            selector$.addClass(`highlight ${mode}`);
            const tooltip = mode == 'warning' ? this.translate.instant(`MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.STATUS.WarningSupplierName`) :
                (mode == 'dangerMapping' ? this.translate.instant(`MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.STATUS.PLNotMapped`) : this.translate.instant(`MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.STATUS.SupplierNotFound`));

            selector$.closest('div').append(`<span class="xmltooltiptext">${tooltip}</span>`);
            selector$.closest('div').addClass('xmltooltip');
        }
        return new XMLSerializer().serializeToString(html$[0]);
    }

    addActions(html: string, doc: any): string {
        var parser = new DOMParser();
        var htmlDoc = parser.parseFromString(html, 'text/html');
        let html$ = $(htmlDoc);
        const tooltip = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.EXCLUDE_FROM_IMPORT');

        const missingSupplier = doc.Check.ErrorType == 'SupplierNotFound';

        let countActions = 0;

        const $table = $(htmlDoc).find('#fattura-elettronica table tr.highlight').closest('table');
        $table.find('thead tr').append(`<th class="colAction"></th>`);
        $table.find('tbody tr').each((index: any, tr: any) => {
            if ($(tr).hasClass('highlight')) {
                const checked = $(tr).data('isexcluded') && !this.enableBulkActions ? "checked" : ""
                const repGoods = $(tr).data('replacedgoods') ? $(tr).data('replacedgoods') : undefined;
                const checkbox = !missingSupplier ? `&nbsp;<div class="escludiMerce actionItem">
                                    <label for="ck_${index}" class="kt-checkbox" style="display: block;">
                                        <input id="ck_${index}" type="checkbox" ${checked}>
                                        <span></span>                                    
                                    </label>
                                    <span class="xmltooltipAction">${tooltip}</span>
                                 </div>` : '';
                const tooltipEditmerce = $(tr).hasClass('danger') || $(tr).hasClass('dangerMapping') ? this.translate.instant('GESTIONE_MERCI.EDIT.NEW') : this.translate.instant('GESTIONE_MERCI.EDIT.EDIT');
                const iconMerce = $(tr).hasClass('danger') || $(tr).hasClass('dangerMapping') ? `<i class="fas fa-plus-square"></i>` : `<i class="fas fa-pen-square"></i>`;
                const editMerce = !missingSupplier ? `&nbsp;<div class="editMerce actionItem">${iconMerce}<span class="xmltooltipAction">${tooltipEditmerce}</span></div>` : '';
                const tooltipSearch = this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.FIND_LEVENSHTEIN_DISTANCE');
                const findMerce = !missingSupplier && ($(tr).hasClass('danger') || $(tr).hasClass('dangerMapping')) ? `&nbsp;<div class="findMerce actionItem"><i class="fas fa-search"></i><span class="xmltooltipAction">${tooltipSearch}</span></div>` : '';
                const replacedGoods = repGoods ? `&nbsp;<div class="replacedGoods actionItem"><i class="fas fa-random"></i><span class="xmltooltipAction">${repGoods.Code}: ${repGoods.Name}</span></div>` : '';

                const categorynotmapped = $(tr).data('categorynotmapped');
                const tooltipGoodCategory = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.OPEN_GOOD_CATEGORY');
                const openGoodCategory = this.invoiceType === 'Purchase' && categorynotmapped ? `&nbsp;<div class="showGoodCategory actionItem"><i class="fas fa-object-group"></i><span class="xmltooltipAction">${tooltipGoodCategory}</span></div>` : '';

                $(tr).append(`<td class="colAction">
                                <div class="actionContainer">
                                    ${openGoodCategory}                                                                                                            
                                    ${replacedGoods}
                                    ${findMerce}
                                    ${editMerce}
                                    ${checkbox}
                                </div>
                              </td>`);

                let numberActions = 0;
                if (openGoodCategory) numberActions++;
                if (checkbox) numberActions++;
                if (editMerce) numberActions++;
                if (findMerce) numberActions++;
                if (replacedGoods) numberActions++;

                if (numberActions > countActions) {
                    countActions = numberActions;
                }

            } else {
                $(tr).append(`<td class="colAction"></td>`);
            }
        });

        const widthColAction = countActions * 23;

        $table.find('.colAction').css('width', `${widthColAction}px`);

        return new XMLSerializer().serializeToString(html$[0]);
    }

    numberChecked() {
        const numberChecked: any = $('.escludiMerce input[type="checkbox"]:checked');
        return numberChecked.length;
    }

    performEnableMultiSelection(event: any) {
        // Se è attivo passo tutti i checkbox e li disabilito
        if (event.checked) {
            $('.escludiMerce input[type="checkbox"]').prop('checked', false);

            // Se è disattivato passo tutti i checkbox e li abilito
        } else {
            $('tr[data-isexcluded="true"] .escludiMerce input[type="checkbox"]').prop('checked', true);
        }
    }


    bulkSelection(event: any) {
        if (!event.value) return;
        const rows: any = $('.escludiMerce input[type="checkbox"]:checked');

        let payload: any = {
            FileName: this.currentSelectedInvoice.FileName,
            DisableRow: event.value === 'Exclude',
            SupplierId: this.currentSelectedInvoice.Check.SupplierId,
            IsForAllDocument: true,
            GoodsId: 0,
            Items: []
        }

        rows.each((index: number, checkbox: any) => {

            // Se è già escluso e voglio escludere lo salto            
            if ($(checkbox).closest('tr').data('isexcluded') && event.value === 'Exclude') return;

            // Se è già incluso e voglio includere lo salto            
            if (!$(checkbox).closest('tr').data('isexcluded') && event.value === 'Include') return;

            const rownumber = parseInt($(checkbox).closest('tr').attr('rownumber') || '');

            const docRow = this.currentSelectedInvoice.Check.Rows.find((x: any) => x.RowNumber === rownumber)
            if (docRow) {
                payload.Items.push({
                    RowNumber: rownumber,
                    CurrentGoodsCode: docRow.Code
                })
            }

        });

        // Se non ho aggiunto nulla non faccio nulla
        if (payload.Items.length == 0) {
            this.bulkSelect = '';
            return;
        };

        this.loading$.next(true);

        switch (event.value) {
            case 'Exclude': {
                this.magazzinoService.updateInvoiceMultiple(payload).toPromise()
                    .then(() => {
                        const message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INVOICE_EXCLUDED');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Create);
                        this.refreshList(this.currentCompany);
                    })
                    .catch(() => { })
                    .finally(() => this.loading$.next(false))
                break;
            }
            case 'Include': {

                this.magazzinoService.restoreInvoiceRowMultiple(payload).toPromise()
                    .then(() => {
                        const message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INVOICE_INCLUDED');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Create);
                        this.refreshList(this.currentCompany);
                    })
                    .catch(() => { })
                    .finally(() => this.loading$.next(false))

                break;
            }
        }
    }

    currentFilterActive: string = '';
    filter(filterName: string) {

        const doc = this.currentCompany.DocumentsFiltered[this.currentIndex];
        let html: any = doc.originalHtml;

        if (this.currentFilterActive != filterName) {
            this.currentFilterActive = filterName;
            $('.legend-box').css('opacity', '.5');
            $(`.${filterName}`).css('opacity', '1');

        } else {
            this.currentFilterActive = '';
            $('.legend-box').css('opacity', '1');
        }

        this.performFilterRows(html, doc);
    }

    hideInvoice(invoice: any) {
        return (invoice.IsHidden
            || (invoice.Check && invoice.Check.IsDisabled && !this.showExcludedInvoices)
            || (invoice.Check && !invoice.Check.IsDisabled && this.showExcludedInvoices)

            //  || (invoice.Check && invoice.Check.ErrorType !== 'SupplierNotFound' && this.showMissingSuppliers)
            //  || ((!invoice.importable || invoice.Check.IsToSplit) && this.showImportable)|| (invoice.importable && this.showNotImportable)
        );
    }

    massiveInvoiceDeletion() {
        const _title: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_DELETE_INVOICE_DIALOG.TITLE');
        const _description: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_DELETE_INVOICE_DIALOG.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_DELETE_INVOICE_DIALOG.WAIT_DESCRIPTION');
        const _success = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_DELETE_INVOICE_DIALOG.MESSAGE');
        const _yesButton = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_DELETE_INVOICE_DIALOG.YESBUTTON');
        const _noButton = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_DELETE_INVOICE_DIALOG.NOBUTTON');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(async (expand: any) => {
            if (expand) {

                this.loading$.next(true);

                const deletableInvoices = this.currentCompany.DocumentsFiltered.filter((invoice: any) => invoice.selectedFor && !this.hideInvoice(invoice)).map((invoice: any) => invoice.FileName);

                await this.gestioneFattureService.deleteBulkInvoices(deletableInvoices).toPromise();

                this.loading$.next(false);
                this.layoutUtilsService.showActionNotification(_success, MessageType.Create);

                this.selectAllSupplier = false;

                this.refreshList(this.currentCompany);

            }

        });
    }

    async massiveGoodsCreation() {
        const _success = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_GOODS_FROM_INVOICE_DIALOG.MESSAGE');

        const importableInvoices = this.currentCompany.DocumentsFiltered.filter((i: any) => i.selectedFor);

        //const dialogRef = this.dialog.open(PresetGoodsDialogComponent, {
        //    width: '700px',
        //    data: {
        //        showCategorySelector: true,
        //        showManual: true,
        //        importableInvoices: JSON.parse(JSON.stringify(importableInvoices)),
        //        locale: this.locale
        //    }
        //}).afterClosed().subscribe(async (result: any) => {

        let selection: any = [];
        importableInvoices.forEach((invoice: any) => {

            if (invoice.Check && invoice.Check.Rows && invoice.Check.Rows.length > 0) {
                invoice.Check.Rows.forEach((merce: any) => {
                    let merceCloned = JSON.parse(JSON.stringify(merce));
                    if (merceCloned.Quantity > 0 && (merceCloned.ErrorType === 'GoodsNotFound' || merceCloned.ErrorType === 'GoodsNotMappedOnPL')) {
                        const alreadyExists = selection.findIndex((selected: any) => selected.Code == merceCloned.Code && selected.SupplierId == invoice.Check.SupplierId) >= 0;
                        if (!alreadyExists) {
                            merceCloned.Price = util.formatNumber(merceCloned.Price, this.data.locale, 2);
                            merceCloned['SupplierId'] = invoice.Check.SupplierId;
                            merceCloned['FileName'] = invoice.FileName;
                            selection.push(merceCloned);
                        }
                    }
                })
            }

        });

        const dialogRef = this.dialog.open(BulkEditNewMerciComponent, {
            width: '90%',
            //height: '500px',
            data: {
                selection: selection
            }
        }).afterClosed().subscribe(async (result: any) => {
            if ((!result || result !== undefined) && ((result && result.category) || (result && result.success && result.type == 'manual'))) {

                const importableInvoices = this.currentCompany.DocumentsFiltered.filter((i: any) => i.selectedFor).map((invoice: any) => invoice.FileName);
                this.loading$.next(true);

                let presets = (result.success && result.type == 'manual') ? result.selection : undefined;

                await this.gestioneMerciService.createGoodsFromInvoices(importableInvoices, result?.category, presets).toPromise();

                this.loading$.next(false);
                this.layoutUtilsService.showActionNotification(_success, MessageType.Create);
                this.refreshList(this.currentCompany);

            }
        });
    }

    async massiveImports() {
        const _title: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_IMPORT_DIALOG.TITLE');
        const _description: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_IMPORT_DIALOG.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_IMPORT_DIALOG.WAIT_DESCRIPTION');
        const _success = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_IMPORT_DIALOG.MESSAGE');
        const _yesButton = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_IMPORT_DIALOG.YESBUTTON');
        const _noButton = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_IMPORT_DIALOG.NOBUTTON');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(async (expand: any) => {
            if (expand) {
                const importableInvoices = this.currentCompany.DocumentsFiltered.filter((i: any) => i.importable && !i.Check.IsToSplit);
                this.loading$.next(true);
                for (const invoice of importableInvoices) {
                    await this.magazzinoService.importInvoices(invoice).toPromise();
                    this.currentCompany.DocumentsFiltered = this.currentCompany.DocumentsFiltered.filter((i: any) => i.FileName !== invoice.FileName);
                    this.ref.detectChanges();
                }
                this.loading$.next(false);
                this.layoutUtilsService.showActionNotification(_success, MessageType.Create);
                this.refreshList(this.currentCompany);
            }

        });
    }

    async selectAllFiltered() {
        this.daSmistare.Documents.forEach((item: any) => {
            const hidden = ((this.filter_itm_BySupplier.length > 0 || this.filter_itm_ByVAT.length > 0) && !this.checkFilter_itm_BySupplier(item)) ||
                (this.showOnlySelected && !item.Checked);
            if (!hidden) {
                item.Checked = this.selectAll;
                this.checkBulkMove(item);
            }
        });
    }

    async checkBulkMove(doc: any) {
        const almoustOneSelected = this.daSmistare.Documents.filter((i: any) => i.Checked);
        this.showBulkMove = almoustOneSelected && almoustOneSelected.length > 0;

        this.updateHeight();

        // Se ero in visualizzazione di una company, allora inizializzo l'elenco degli xml
        if (this.currentCompany) {
            this.showSelectedXml = [];
        }

        if (doc.Checked) {
            this.currentCompany = undefined;

            this.loading$.next(true);

            const html: any = await this.magazzinoService.getXml(doc.FileName).toPromise();

            let tmp = doc;
            tmp.html = this.domSanitizer.bypassSecurityTrustHtml(html)

            this.showSelectedXml.push(tmp);

            this.loading$.next(false);

        } else {
            this.showSelectedXml = this.showSelectedXml.filter((f: any) => f.FileName !== doc.FileName);
        }

    }

    moveBulkToCompany(company: any) {
        if (!company) return;

        const _title: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_MOVE_DIALOG.TITLE');
        const _description: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_MOVE_DIALOG.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_MOVE_DIALOG.WAIT_DESCRIPTION');
        const _success = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_MOVE_DIALOG.MESSAGE');
        const _yesButton = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_MOVE_DIALOG.YESBUTTON');
        const _noButton = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.BULK_MOVE_DIALOG.NOBUTTON');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(expand => {

            if (expand) {

                const fileNames = this.daSmistare.Documents.filter((i: any) => i.Checked).map((i: any) => i.FileName);

                this.loading$.next(true);
                this.magazzinoService.bulkMoveAcquistiImportati(fileNames, company).toPromise()
                    .then((res: any) => {
                        this.companyTargetToMove = '';
                        if (this.currentCompany) {
                            this.refreshList(this.companiesList.find((c: any) => c.Store == this.currentCompany.Store));
                        } else {
                            this.refreshList();
                        }
                    })
                    .catch((err: any) => {
                        console.log(err);
                        this.loading$.next(false);
                    });
            }

        });

    }

    performFilterRows(html: string, doc: any) {

        this.loading$.next(true);
        this.ref.detectChanges();

        setTimeout(() => {
            if (doc.Check && doc.Check.ErrorType == 'SupplierNotFound') {
                html = this.highlightSupplier(html, 'danger')
            } else if (doc.Check && doc.Check.ErrorType == 'WarningSupplierName') {
                html = this.highlightSupplier(html, 'warning')
            }
            else if (doc.Check && doc.Check.ErrorType == 'SupplierNotMappedToProfitAndLoss') {
                html = this.highlightSupplier(html, 'dangerMapping')
            }

            if (doc.Check && doc.Check.ErrorType == 'AlreadyImported') {
                html = this.highlightWhole(html);
            }

            //if (doc.Check && doc.Check.ErrorType !== 'None') {
            doc.Check.Rows.forEach((row: any, index: number) => {
                if (row.Description == 'G100X8 BURRATA MC') {
                    console.log(row);
                }
                switch (row.ErrorType) {
                    case 'GoodsNotFound':
                        html = (this.currentFilterActive == 'missingGoodBox') || !this.currentFilterActive ? this.highlightGoods(html, row, doc.Check.SupplierId, 'danger', index) : this.hideGoods(html, row)
                        break;
                    case 'GoodsNotMappedOnPL':
                        html = (this.currentFilterActive == 'missingGoodBox') || !this.currentFilterActive ? this.highlightGoods(html, row, doc.Check.SupplierId, 'dangerMapping', index) : this.hideGoods(html, row)
                        break;
                    case 'WarningGoodsName':
                        html = (this.currentFilterActive == 'warningGoodBox') || !this.currentFilterActive ? this.highlightGoods(html, row, doc.Check.SupplierId, 'warning', index) : this.hideGoods(html, row)
                        break;
                    case 'WarningRowExcluded':
                        html = (this.currentFilterActive == 'excludedGoodBox') || !this.currentFilterActive ? this.highlightGoods(html, row, doc.Check.SupplierId, 'excluded', index) : this.hideGoods(html, row)
                        break;
                    case 'WarningGoodsReplaced':
                        html = (this.currentFilterActive == 'replacedGoodBox') || !this.currentFilterActive ? this.highlightGoods(html, row, doc.Check.SupplierId, 'replaced', index) : this.hideGoods(html, row)
                        break;
                    default: ;
                }
            });
            html = this.addActions(html, doc);
            //}

            doc.html = this.domSanitizer.bypassSecurityTrustHtml(html);

            setTimeout(() => {

                // Per i fornitori
                $('.tdHead .highlight').off('click');
                $('.tdHead .highlight').on('click', async (event: any) => {

                    let supplier: any;

                    if ($(event.target).hasClass('warning') || $(event.target).hasClass('dangerMapping')) {

                        const index = this.staticCollectionsService.fornitoriTutti$.findIndex((f: any) => f.Id == doc.Check.SupplierId);
                        if (index < 0) return;

                        supplier = this.staticCollectionsService.fornitoriTutti$[index];

                    } else if ($(event.target).hasClass('danger')) {
                        const tdHead$: any = $(event.target).closest('.tdHead');
                        // const type: string = $(event.target).hasClass('warning') ? 'warning' : 'danger';
                        let denominazione, indirizzo, comune, cap, telefono, piva, email;

                        const denominazione$ = tdHead$.find(`div.headContent.xmltooltip:contains('Denominazione')`).first();
                        if (denominazione$.length > 0) {
                            denominazione = $(denominazione$.get(0)).find(`span:not(.xmltooltiptext)`).text();
                        }

                        if (tdHead$.find(`div.headContent:contains('Indirizzo'):first-child > span`).length > 0) {
                            indirizzo = tdHead$.find(`div.headContent:contains('Indirizzo'):first-child > span`).text();
                        } else if (tdHead$.find(`div.headContent:contains('Indirizzo') > span`)) {
                            indirizzo = tdHead$.find(`div.headContent:contains('Indirizzo') > span`).text();
                        }

                        if (tdHead$.find(`div.headContent:contains('Comune'):first-child > span`).length > 0) {
                            comune = tdHead$.find(`div.headContent:contains('Comune'):first-child > span`).text();
                        } else if (tdHead$.find(`div.headContent:contains('Comune') > span`)) {
                            comune = tdHead$.find(`div.headContent:contains('Comune') > span`).text();
                        }

                        if (tdHead$.find(`div.headContent:contains('Cap'):first-child > span`).length > 0) {
                            cap = tdHead$.find(`div.headContent:contains('Cap'):first-child > span`).text();
                        } else if (tdHead$.find(`div.headContent:contains('Cap') > span`)) {
                            cap = tdHead$.find(`div.headContent:contains('Cap') > span`).text();
                        }

                        if (tdHead$.find(`div.headContent:contains('Telefono'):first-child > span`).length > 0) {
                            telefono = tdHead$.find(`div.headContent:contains('Telefono'):first-child > span`).text();
                        } else if (tdHead$.find(`div.headContent:contains('Telefono') > span`)) {
                            telefono = tdHead$.find(`div.headContent:contains('Telefono') > span`).text();
                        }

                        if (tdHead$.find(`div.headContent:contains('Email'):first-child > span`).length > 0) {
                            email = tdHead$.find(`div.headContent:contains('Email'):first-child > span`).text();
                        } else if (tdHead$.find(`div.headContent:contains('Email') > span`)) {
                            email = tdHead$.find(`div.headContent:contains('Email') > span`).text();
                        }

                        //const telefono$ = tdHead$.find(`div.headContent:contains('Telefono')`);
                        //if (telefono$.length > 0) {
                        //    telefono = $(telefono$.get(0)).find(`span`).text();
                        //}

                        if (tdHead$.find(`div.headContent:contains('Identificativo fiscale ai fini IVA'):first-child > span`).length > 0) {
                            piva = tdHead$.find(`div.headContent:contains('Identificativo fiscale ai fini IVA'):first-child > span`).text();
                        } else if (tdHead$.find(`div.headContent:contains('Identificativo fiscale ai fini IVA') > span`)) {
                            piva = tdHead$.find(`div.headContent:contains('Identificativo fiscale ai fini IVA') > span`).text();
                        }

                        const piva$ = tdHead$.find(`div.headContent:contains('Identificativo fiscale ai fini IVA')`);
                        if (piva$.length > 0) {
                            piva = $(piva$.get(0)).find(`span`).text();
                        }

                        supplier = {
                            CheckPrice: false,
                            Code: piva,
                            Company: null,
                            Conditions: null,
                            EDICode: null,
                            Email: email,
                            IsInternal: false,
                            MinOrder: null,
                            Name: denominazione,
                            Phone: telefono,
                            IsDefault: false,
                            IsDisabled: false,
                            IsEDI: false,
                            Id: doc.SupplierId,
                            SubjectType: 1
                        }
                    }

                    let saveMessageTranslateParam = 'ANAGRAFICHE.FORNITORI.ACTION_EDIT.';
                    saveMessageTranslateParam += supplier.Id > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
                    const _saveMessage = this.translate.instant(saveMessageTranslateParam);
                    const _messageType = supplier.Id > 0 ? MessageType.Update : MessageType.Create;
                    const dialogRef = this.dialog.open(FornitoriEditComponent, { data: { item: supplier }, width: '900px' });
                    dialogRef.afterClosed().subscribe(async (res) => {
                        if (_.isEmpty(res) || !res) {
                            return;
                        }
                        this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
                        await this.staticCollectionsService.refreshFornitori();
                        this.refreshList(this.currentCompany);
                    });

                });

                // Per i checkboxes
                $('.colAction .actionItem').off('click').on('click', async (event: any) => {
                    const actionItem = $(event.target).closest('.actionItem');
                    if (actionItem.hasClass('escludiMerce') && !this.enableBulkActions) {
                        this.excludeSingleRow(event, doc);
                    } else if (actionItem.hasClass('editMerce')) {
                        this.performSelectEditMerce(event, doc);
                    } else if (actionItem.hasClass('findMerce')) {
                        this.searchGoods(event, doc);
                    } else if (actionItem.hasClass('replacedGoods')) {
                        this.restoreGood(event, doc);
                    } else if (actionItem.hasClass('showGoodCategory')) {
                        this.openGoodCategory(event, doc)
                    }
                });

                this.loading$.next(false);

                this.ref.detectChanges();

            }, 1000);

        }, 100);

    }

    async onExpandPanel(doc: any, index: number) {
        this.enableBulkActions = false;
        this.currentSelectedInvoice = undefined;
        if ((doc && doc.html) || !doc) return;
        this.loading$.next(true);
        this.ref.detectChanges();
        this.currentIndex = index;
        let html: any = doc.FileName ? await lastValueFrom(this.magazzinoService.getXml(doc.FileName)) : null;
        doc.originalHtml = html;
        doc.html = html; // inizio a farlo vedere nel frattempo analizzo tutte le righe.
        doc.expanded = true;
        this.currentSelectedInvoice = doc;
        setTimeout(() => {
            this.performFilterRows(html, doc);
        }, 100);
    }

    async downloadInvoice(invoice: any, event: any) {
        event.stopPropagation();
        this.loading$.next(true);
        //this.pdfService.htmlToPdf(invoice.originalHtml, invoice.FileName).then(() => this.loading$.next(false));

        this.magazzinoService.getRawXml(invoice.FileName).toPromise()
            .then((xml: any) => {
                this.loading$.next(false);
                let blob = new Blob([xml], { type: 'text/xml' });
                let url = URL.createObjectURL(blob);
                window.open(url);
                URL.revokeObjectURL(url); //Releases the resources
            });
    }

    async changeInvoiceMode(doc: any, event: any, performFilterRows: boolean = true) {
        event.stopPropagation();
        this.loading$.next(true);
        this.ref.detectChanges();
        if (!doc.mode) {
            doc.mode = 1;
        } else {
            doc.mode = 3 - doc.mode
        }
        let html: any = await this.magazzinoService.getXml(doc.FileName, doc.mode).toPromise();
        doc.html = html;
        this.loading$.next(false);

        if (doc.mode == 2 && performFilterRows) {
            this.performFilterRows(html, doc);
        }
    }

    getCheckedInvoices() {
        return this.daSmistare.Documents.filter((i: any) => i.Checked).length;
    }

    getFilteredInvoices() {
        return this.daSmistare.Documents.filter((i: any) => this.checkFilter_itm_BySupplier(i)).length;
    }

    async performSelectEditMerce(event: any, doc: any) {
        const tr = $(event.target).closest('tr');
        console.log(tr);
        let merce: any = '';

        if (tr.hasClass('warning') || tr.hasClass('replaced')) {
            const goodId = $(tr).data('goodid');
            merce = await this.gestioneMerciService.getSingleGoods(goodId).toPromise();

            const dialogRef = this.dialog.open(EditMerciComponent, {
                data: {
                    merce: merce,
                    html: doc.originalHtml,
                    disablePrices: true
                },
                width: '100%',
                height: '100%'
            });

            dialogRef.afterClosed().subscribe((res: any) => {
                setTimeout(() => {
                    if (res) {
                        let message = merce.Id ? this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.SUCCESS') : this.translate.instant('GESTIONE_MERCI.SAVE_DIALOG.SUCCESS');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

                        this.refreshList(this.currentCompany);
                    }
                }, 100);
            });

        } else {
            //.clone().find('span').remove().end()
            // Serve per rimuovere tutti gli span dentro ai td

            const dialogRef = this.dialog.open(PresetGoodsDialogComponent, {
                width: '700px',
            }).afterClosed().subscribe(async (selection: Preset) => {
                if (selection !== undefined) {

                    const name = tr.find('td:nth-child(2)').clone().find('span').remove().end().text();
                    // Creando nuova merce forzo la creazione di un nuovo codice
                    const existingCode = $(tr).find('td:nth-child(1) .tx-xsmall').clone().find('span').remove().end().text().trim();
                    const prezzoUnitario = $(tr).find('td:nth-child(4)').clone().find('span').remove().end().text().trim();
                    const unita = $(tr).find('td:nth-child(5)').clone().find('span').remove().end().text().trim();
                    const sconto = $(tr).find('td:nth-child(6)').clone().find('span').remove().end().text().trim().replace('%', '').replace('-', '').replace('.', ',');
                    const iva = $(tr).find('td:nth-child(7)').clone().find('span').remove().end().text().trim();

                    merce = await this.newMerce(name, doc.Check.SupplierId, existingCode, prezzoUnitario, iva, unita, sconto, undefined, selection);

                    const dialogRef = this.dialog.open(EditMerciComponent, {
                        data: {
                            merce: merce,
                            html: doc.originalHtml,
                            disablePrices: true
                        },
                        width: '100%',
                        height: '100%'
                    });

                    dialogRef.afterClosed().subscribe((res: any) => {
                        setTimeout(() => {
                            if (res) {
                                let message = merce.Id ? this.translate.instant('GESTIONE_MERCI.UPDATE_DIALOG.SUCCESS') : this.translate.instant('GESTIONE_MERCI.SAVE_DIALOG.SUCCESS');
                                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');

                                this.refreshList(this.currentCompany);
                            }
                        }, 100);
                    });

                }
            });


        }


    }

    async newMerce(name: string, supplierid: string, existingCode: string, prezzoUnitario: string, iva: string, unita: string, sconto: string, search: boolean = false, preset: Preset = Preset.NO_PRESET) {

        let newCode = search && existingCode ? existingCode : await this.gestioneMerciService.getFirstAvailableGoodCode().toPromise();

        let code = existingCode;
        if (!existingCode) {
            code = await this.gestioneMerciService.getHashByName(name).toPromise();
        }

        const category = this.staticCollectionsService.categorieMerci$.find((c: any) => c.IsImport == true);
        const unit = this.staticCollectionsService.unit$.find((unit: any) => unit.Name.toLowerCase() === unita);
        const defGroup = this.staticCollectionsService.gruppiMerci$.find((m: any) => m.Name == '---');

        let newItem: any = {
            AlternateName: name,
            AvgPrice: '',
            BaseUnitId: unit ? unit.MainUnitId : '',
            A: unit ? unit.A : 1,
            B: unit ? unit.B : 1,
            Category: category ? category.Name : '',
            CategoryId: category ? category.Id : '',
            Code: newCode,
            MBCode: newCode,
            ExcludedByConsumption: false,
            Group: '',
            GroupId: defGroup ? defGroup.Id : '',
            InOut: 0,
            IsDisabled: false,
            IsFinished: false,
            IsFresh: false,
            IsHidden: false,
            LockPriceChange: false,
            Name: name,
            Note: '',
            OtherUnit: "",
            OtherUnitId: unit ? unit.Id : -1,
            OtherUnitRatio: 1,
            Position: '',
            Price: this.utility.parseNumber(prezzoUnitario, this.locale),
            PriceMode: "MasterPrice",
            Prices: [],
            ProductionRatio: 1,
            ProductionUnit: unit ? unit.Name : '',
            ProductionUnitId: unit ? unit.Id : '',
            SecondaryUnit: "",
            SecondaryUnitId: unit ? unit.Id : -1,
            SecondaryUnitRatio: 1,
            Suppliers: [],
            Unit: unit ? unit.Name : '',
            UnitId: unit ? unit.Id : '',
            VAT: this.utility.parseNumber(iva, this.locale),
            WastPercentage: 0
        };

        let orderUnit = this.staticCollectionsService.orderUnit$.find((unit: any) => unit.Name.toLowerCase() === unita.toLowerCase());

        this.staticCollectionsService.aziende$.forEach((company: any) => {
            newItem.Prices.push({
                Alias: '',
                Azienda: company.Name,
                IsMaster: false,
                IsVisible: true,
                // OtherUnit: orderUnit ? orderUnit.Name : '',
                // OtherUnitId: orderUnit ? orderUnit.Id : -1,
                OtherUnit: '',
                OtherUnitId: -1,
                OtherUnitRatio: '',
                Price: this.utility.parseNumber(prezzoUnitario, this.locale),
                PriceUM: this.utility.parseNumber(prezzoUnitario, this.locale),
                PriceUO: this.utility.parseNumber(prezzoUnitario, this.locale),
                SecondaryUnit: orderUnit ? orderUnit.Name : '',
                SecondaryUnitId: orderUnit ? orderUnit.Id : -1,
                SecondaryUnitRatio: '',
                SupplierId: supplierid
            });
        });

        if (supplierid) {
            const supplier = this.staticCollectionsService.fornitoriTutti$.find((s: any) => s.Id == supplierid);
            if (supplier) {
                newItem.Suppliers.push({
                    CalculatedPrice: false,
                    Id: supplierid,
                    IsDisabled: supplier.IsDisabled,
                    LockPriceChange: false,
                    Name: supplier.Name,
                    Variations: [{
                        Alias: '',
                        CalculatedPrice: false,
                        Code: code,
                        Disabled: false,
                        LockPriceChange: false,
                        // OtherUnit: orderUnit ? orderUnit.Name : '',
                        // OtherUnitId: orderUnit ? orderUnit.Id : -1,
                        OtherUnit: '',
                        OtherUnitId: -1,
                        OtherUnitRatio: '',
                        Price: this.utility.parseNumber(prezzoUnitario, this.locale),
                        QxC: 0,
                        S1: sconto ? this.utility.parseNumber(sconto, this.locale) : 0,
                        S2: 0,
                        S3: 0,
                        S4: 0,
                        SecondaryUnit: orderUnit ? orderUnit.Name : '',
                        SecondaryUnitId: orderUnit ? orderUnit.Id : -1,
                        SecondaryUnitRatio: '',
                        SupplierId: supplierid
                    }]
                });
            } else {
                alert('Fornitore non trovato => ' + supplierid);
            }

        }

        // Se è attivo un preset lo applico
        if (preset !== Preset.NO_PRESET) {

            switch (+preset) {
                case Preset.GOOD_WEIGHT_KG_1_1: {

                    const unit = this.staticCollectionsService.unit$.find((unit: any) => unit.Name.toLowerCase() === 'kg');
                    newItem.BaseUnitId = unit.MainUnitId;
                    newItem.A = unit.A;
                    newItem.B = unit.B;
                    newItem.OtherUnitId = unit.Id;

                    newItem.ProductionUnit = unit.Name;
                    newItem.ProductionUnitId = unit.Id;
                    newItem.SecondaryUnitId = unit.Id;

                    newItem.Unit = unit.Name;
                    newItem.UnitId = unit.Id;

                    newItem.OtherUnitRatio = 1;
                    newItem.SecondaryUnitRatio = 1;
                    newItem.ProductionRatio = 1;

                    const orderUnit = this.staticCollectionsService.orderUnit$.find((unit: any) => unit.Name.toLowerCase() === 'kg');

                    newItem.Prices.forEach((company: any) => {
                        company.OtherUnitRatio = 1;
                        company.OtherUnit = orderUnit.Name;
                        company.OtherUnitId = orderUnit.Id;

                        company.SecondaryUnitRatio = 1;
                        company.SecondaryUnit = orderUnit.Name;
                        company.SecondaryUnitId = orderUnit.Id;

                    });

                    if (supplierid) {

                        newItem.Suppliers.forEach((supplier: any) => {
                            supplier.Variations.forEach((variation: any) => {
                                variation.OtherUnitRatio = 1;
                                variation.OtherUnit = orderUnit.Name;
                                variation.OtherUnitId = orderUnit.Id;

                                variation.SecondaryUnitRatio = 1;
                                variation.SecondaryUnit = orderUnit.Name;
                                variation.SecondaryUnitId = orderUnit.Id;
                            });
                        });

                    }
                }
                    break;
                case Preset.GOOD_WEIGHT_LT_1_1: {

                    const unit = this.staticCollectionsService.unit$.find((unit: any) => unit.Name.toLowerCase() === 'lt');
                    newItem.BaseUnitId = unit.MainUnitId;
                    newItem.A = unit.A;
                    newItem.B = unit.B;
                    newItem.OtherUnitId = unit.Id;

                    newItem.ProductionUnit = unit.Name;
                    newItem.ProductionUnitId = unit.Id;
                    newItem.SecondaryUnitId = unit.Id;

                    newItem.Unit = unit.Name;
                    newItem.UnitId = unit.Id;

                    newItem.OtherUnitRatio = 1;
                    newItem.SecondaryUnitRatio = 1;
                    newItem.ProductionRatio = 1;

                    const orderUnit = this.staticCollectionsService.orderUnit$.find((unit: any) => unit.Name.toLowerCase() === 'lt');

                    newItem.Prices.forEach((company: any) => {
                        company.OtherUnitRatio = 1;
                        company.OtherUnit = orderUnit.Name;
                        company.OtherUnitId = orderUnit.Id;

                        company.SecondaryUnitRatio = 1;
                        company.SecondaryUnit = orderUnit.Name;
                        company.SecondaryUnitId = orderUnit.Id;

                    });

                    if (supplierid) {

                        newItem.Suppliers.forEach((supplier: any) => {
                            supplier.Variations.forEach((variation: any) => {
                                variation.OtherUnitRatio = 1;
                                variation.OtherUnit = orderUnit.Name;
                                variation.OtherUnitId = orderUnit.Id;

                                variation.SecondaryUnitRatio = 1;
                                variation.SecondaryUnit = orderUnit.Name;
                                variation.SecondaryUnitId = orderUnit.Id;
                            });
                        });

                    }
                }

                    break;
                case Preset.GOOD_BOTTLE_BOT_1_1: {

                    const unit = this.staticCollectionsService.unit$.find((unit: any) => unit.Name.toLowerCase() === 'bot');
                    newItem.BaseUnitId = unit.MainUnitId;
                    newItem.A = unit.A;
                    newItem.B = unit.B;
                    newItem.OtherUnitId = unit.Id;

                    newItem.ProductionUnit = unit.Name;
                    newItem.ProductionUnitId = unit.Id;
                    newItem.SecondaryUnitId = unit.Id;

                    newItem.Unit = unit.Name;
                    newItem.UnitId = unit.Id;

                    newItem.OtherUnitRatio = 1;
                    newItem.SecondaryUnitRatio = 1;
                    newItem.ProductionRatio = 1;

                    const orderUnit = this.staticCollectionsService.orderUnit$.find((unit: any) => unit.Name.toLowerCase() === 'bot');

                    newItem.Prices.forEach((company: any) => {
                        company.OtherUnitRatio = 1;
                        company.OtherUnit = orderUnit.Name;
                        company.OtherUnitId = orderUnit.Id;

                        company.SecondaryUnitRatio = 1;
                        company.SecondaryUnit = orderUnit.Name;
                        company.SecondaryUnitId = orderUnit.Id;

                    });

                    if (supplierid) {

                        newItem.Suppliers.forEach((supplier: any) => {
                            supplier.Variations.forEach((variation: any) => {
                                variation.OtherUnitRatio = 1;
                                variation.OtherUnit = orderUnit.Name;
                                variation.OtherUnitId = orderUnit.Id;

                                variation.SecondaryUnitRatio = 1;
                                variation.SecondaryUnit = orderUnit.Name;
                                variation.SecondaryUnitId = orderUnit.Id;
                            });
                        });

                    }
                }

                    break;
                case Preset.GOOD_BOTTLE_POURING_LT_1_X: {

                    const unit = this.staticCollectionsService.unit$.find((unit: any) => unit.Name.toLowerCase() === 'lt');
                    newItem.BaseUnitId = unit.MainUnitId;
                    newItem.A = unit.A;
                    newItem.B = unit.B;
                    newItem.OtherUnitId = unit.Id;

                    newItem.ProductionUnit = unit.Name;
                    newItem.ProductionUnitId = unit.Id;
                    newItem.SecondaryUnitId = unit.Id;

                    newItem.Unit = unit.Name;
                    newItem.UnitId = unit.Id;

                    newItem.OtherUnitRatio = 1;
                    newItem.SecondaryUnitRatio = 1;
                    newItem.ProductionRatio = 1;

                    const orderUnit = this.staticCollectionsService.orderUnit$.find((unit: any) => unit.Name.toLowerCase() === 'bot');

                    newItem.Prices.forEach((company: any) => {
                        company.OtherUnitRatio = 1;
                        company.OtherUnit = orderUnit.Name;
                        company.OtherUnitId = orderUnit.Id;

                        company.SecondaryUnitRatio = '';
                        company.SecondaryUnit = orderUnit.Name;
                        company.SecondaryUnitId = orderUnit.Id;

                    });

                    if (supplierid) {

                        newItem.Suppliers.forEach((supplier: any) => {
                            supplier.Variations.forEach((variation: any) => {
                                variation.OtherUnitRatio = 1;
                                variation.OtherUnit = orderUnit.Name;
                                variation.OtherUnitId = orderUnit.Id;

                                variation.SecondaryUnitRatio = '';
                                variation.SecondaryUnit = orderUnit.Name;
                                variation.SecondaryUnitId = orderUnit.Id;
                            });
                        });

                    }
                }

                    break;

                case Preset.GOOD_PIECE_KG_1_X: {

                    const unit = this.staticCollectionsService.unit$.find((unit: any) => unit.Name.toLowerCase() === 'kg');
                    newItem.BaseUnitId = unit.MainUnitId;
                    newItem.A = unit.A;
                    newItem.B = unit.B;
                    newItem.OtherUnitId = unit.Id;

                    newItem.ProductionUnit = unit.Name;
                    newItem.ProductionUnitId = unit.Id;
                    newItem.SecondaryUnitId = unit.Id;

                    newItem.Unit = unit.Name;
                    newItem.UnitId = unit.Id;

                    newItem.OtherUnitRatio = 1;
                    newItem.SecondaryUnitRatio = 1;
                    newItem.ProductionRatio = 1;

                    const orderUnit = this.staticCollectionsService.orderUnit$.find((unit: any) => unit.Name.toLowerCase() === 'pz');

                    newItem.Prices.forEach((company: any) => {
                        company.OtherUnitRatio = 1;
                        company.OtherUnit = orderUnit.Name;
                        company.OtherUnitId = orderUnit.Id;

                        company.SecondaryUnitRatio = '';
                        company.SecondaryUnit = orderUnit.Name;
                        company.SecondaryUnitId = orderUnit.Id;

                    });

                    if (supplierid) {

                        newItem.Suppliers.forEach((supplier: any) => {
                            supplier.Variations.forEach((variation: any) => {
                                variation.OtherUnitRatio = 1;
                                variation.OtherUnit = orderUnit.Name;
                                variation.OtherUnitId = orderUnit.Id;

                                variation.SecondaryUnitRatio = '';
                                variation.SecondaryUnit = orderUnit.Name;
                                variation.SecondaryUnitId = orderUnit.Id;
                            });
                        });

                    }
                }

                    break;

                case Preset.GOOD_PACKAGE_PZ_1_X: {

                    const unit = this.staticCollectionsService.unit$.find((unit: any) => unit.Name.toLowerCase() === 'pz');
                    newItem.BaseUnitId = unit.MainUnitId;
                    newItem.A = unit.A;
                    newItem.B = unit.B;
                    newItem.OtherUnitId = unit.Id;

                    newItem.ProductionUnit = unit.Name;
                    newItem.ProductionUnitId = unit.Id;
                    newItem.SecondaryUnitId = unit.Id;

                    newItem.Unit = unit.Name;
                    newItem.UnitId = unit.Id;

                    newItem.OtherUnitRatio = 1;
                    newItem.SecondaryUnitRatio = 1;
                    newItem.ProductionRatio = 1;

                    const orderUnit = this.staticCollectionsService.orderUnit$.find((unit: any) => unit.Name.toLowerCase() === 'ct');

                    newItem.Prices.forEach((company: any) => {
                        company.OtherUnitRatio = 1;
                        company.OtherUnit = orderUnit.Name;
                        company.OtherUnitId = orderUnit.Id;

                        company.SecondaryUnitRatio = '';
                        company.SecondaryUnit = orderUnit.Name;
                        company.SecondaryUnitId = orderUnit.Id;

                    });

                    if (supplierid) {

                        newItem.Suppliers.forEach((supplier: any) => {
                            supplier.Variations.forEach((variation: any) => {
                                variation.OtherUnitRatio = 1;
                                variation.OtherUnit = orderUnit.Name;
                                variation.OtherUnitId = orderUnit.Id;

                                variation.SecondaryUnitRatio = '';
                                variation.SecondaryUnit = orderUnit.Name;
                                variation.SecondaryUnitId = orderUnit.Id;
                            });
                        });

                    }
                }

                    break;

            }

        }

        console.log('newItem', newItem);

        return newItem;
    }

    showBase() {
        this.refreshList(this.currentCompany);
    }

    getIsImportable(chk: any) {
        if (chk.IsDisabled) {
            return false;
        }

        if (this.invoiceType === 'Purchase') {
            if (chk.SkipAllGoodsCheck) {
                if (chk.ErrorType === 'SupplierNotFound') {
                    return false
                } else {
                    return true;
                }
            }
            else {
                if (chk.ErrorType === 'None') {
                    return true;
                } else {
                    var err = chk.Rows.find((row: any) => row.ErrorType === 'GoodsNotFound' || row.ErrorType === 'GoodsNotMappedOnPL');
                    return err == null;

                }


            }

            return true;

        } else {
            if (chk.ErrorType === 'SupplierNotMappedToProfitAndLoss' || chk.ErrorType === 'SupplierNotFound') {
                return false;
            } else {
                return true;
            }
        }

    }

    getStatus(chk: any) {
        //return this.translate.instant(`MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.STATUS.${errorType}`);
        var isImportable = this.getIsImportable(chk);
        if (isImportable) {
            return this.translate.instant(`MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.STATUS.None`);
        }
        else {
            return this.translate.instant(`MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.STATUS.ToCheck`);
        }
    }

    preventClick(event: MouseEvent) {
        event.stopPropagation();
    }

    requireDistribution(event: any, invoice: any) {
        event.stopPropagation();

        if (invoice.NeedDistribution) return;

        setTimeout(() => {
            // const checked = $(event.target).is(':checked');
            //invoice.Check.IsDisabled = checked;
            this.loading$.next(true);

            this.ref.detectChanges();
            let item = {}, message = "";

            invoice.RequireDistribution = !invoice.RequireDistribution;
            if (invoice.RequireDistribution) {
                message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.EXECUTED');
            } else {
                message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.EXECUTED');
            }
            item = {
                FileName: invoice.FileName,
                RequireDistribution: invoice.RequireDistribution
            };
            this.magazzinoService.updateInvoice(item).toPromise()
                .then(() => {

                    this.layoutUtilsService.showActionNotification(message, MessageType.Create);
                    this.loading$.next(false);
                    this.refreshList(this.currentCompany);
                })
                .catch(() => {
                    this.loading$.next(false);
                })
        }, 100);
    }

    excludeInvoice(event: any, invoice: any, mode: any) {

        event.stopPropagation();
        setTimeout(() => {
            // const checked = $(event.target).is(':checked');
            //invoice.Check.IsDisabled = checked;
            this.loading$.next(true);

            this.ref.detectChanges();
            let item = {}, message = "";
            switch (mode) {
                case "Disable":
                    invoice.Check.IsDisabled = !invoice.Check.IsDisabled;
                    if (invoice.Check.IsDisabled) {
                        message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INVOICE_EXCLUDED');
                    } else {
                        message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INVOICE_INCLUDED');
                    }
                    item = {
                        FileName: invoice.FileName,
                        DisableDocument: invoice.Check.IsDisabled
                    };
                    break;
                case "CE":
                    invoice.ExcludedCE = !invoice.ExcludedCE;
                    if (invoice.ExcludedCE) {
                        message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INVOICE_EXCLUDED');
                    } else {
                        message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INVOICE_INCLUDED');
                    }
                    item = {
                        FileName: invoice.FileName,
                        ExcludedCE: invoice.ExcludedCE
                    };
                    break;
                case "Purchase":
                    invoice.ExcludedPurchase = !invoice.ExcludedPurchase;
                    if (invoice.ExcludedPurchase) {
                        message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INVOICE_EXCLUDED');
                    } else {
                        message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INVOICE_INCLUDED');
                    }
                    item = {
                        FileName: invoice.FileName,
                        ExcludePurchase: invoice.ExcludedPurchase
                    };
                    break;
            }
            this.magazzinoService.updateInvoice(item).toPromise()
                .then(() => {
                    //let message = '';
                    //if (invoice.Check.IsDisabled) {
                    //    message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INVOICE_EXCLUDED');
                    //} else {
                    //    message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INVOICE_INCLUDED');
                    //}
                    this.layoutUtilsService.showActionNotification(message, MessageType.Create);
                    this.loading$.next(false);
                    this.refreshList(this.currentCompany);
                })
                .catch(() => {
                    this.loading$.next(false);
                })
        }, 100);
    }

    excluding: boolean = false;
    excludeSingleRow(event: any, doc: any) {

        if (this.excluding) return;
        this.excluding = true;

        const checkBox = $(event.target).closest('td').find('input[type="checkbox"]');

        const checked = checkBox.is(':checked');
        const rownumber = parseInt($(event.target).closest('tr').attr('rownumber') || '');

        let _title: string, _description: string, _waitDesciption: string, _success: string, _yesButton: string, _noButton: string;

        if (!checked) {
            _title = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.EXCLUDE_SINGLE_ROW_DIALOG.TITLE');
            _description = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.EXCLUDE_SINGLE_ROW_DIALOG.DESCRIPTION');
            _waitDesciption = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.EXCLUDE_SINGLE_ROW_DIALOG.WAIT_DESCRIPTION');
            _success = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.EXCLUDE_SINGLE_ROW_DIALOG.MESSAGE');
            _yesButton = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.EXCLUDE_SINGLE_ROW_DIALOG.YESBUTTON');
            _noButton = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.EXCLUDE_SINGLE_ROW_DIALOG.NOBUTTON');
        } else {
            _title = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INCLUDE_SINGLE_ROW_DIALOG.TITLE');
            _description = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INCLUDE_SINGLE_ROW_DIALOG.DESCRIPTION');
            _waitDesciption = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INCLUDE_SINGLE_ROW_DIALOG.WAIT_DESCRIPTION');
            _success = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INCLUDE_SINGLE_ROW_DIALOG.MESSAGE');
            _yesButton = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INCLUDE_SINGLE_ROW_DIALOG.YESBUTTON');
            _noButton = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.INCLUDE_SINGLE_ROW_DIALOG.NOBUTTON');
        }

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(async expand => {

            if (expand === undefined) {
                checkBox.prop('checked', checked);
                this.excluding = false;
                return;
            };

            let payload = {
                FileName: doc.FileName,
                DisableRow: !checked,
                RowNumber: rownumber
            }

            const docRow = doc.Check.Rows.find((x: any) => x.RowNumber === rownumber)
            if (docRow) {
                payload['IsForAllDocument'] = true;
                payload['SupplierId'] = doc.Check.SupplierId;
                payload['CurrentGoodsCode'] = docRow.Code;
                payload['GoodsId'] = docRow.GoodsId;
            }

            this.loading$.next(true);
            event.stopPropagation();

            const api$ = !checked ? this.magazzinoService.updateInvoice(payload) : this.magazzinoService.restoreInvoiceRow(payload);

            api$.toPromise()
                .then(() => {
                    this.layoutUtilsService.showActionNotification(_success, MessageType.Create);
                })
                .finally(() => {
                    this.loading$.next(false);
                    this.excluding = false;
                    this.refreshList(this.currentCompany);
                });
        });


    }

    openGoodCategory(event: any, doc: any) {
        const categoryId = $(event.target).closest('tr').data('categoryid');
        const item = this.staticCollectionsService.categorieMerci$.find((cat: any) => cat.Id == categoryId);
        let saveMessageTranslateParam = 'ANAGRAFICHE.CATEGORIE_MERCI.ACTION_EDIT.UPDATE_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = MessageType.Update;
        const dialogRef = this.dialog.open(CategorieMerciEditComponent, { data: { item }, width: '600px' });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
            this.refreshList(this.currentCompany);
        });
    }

    restoreGood(event: any, doc: any) {

        if (this.excluding) return;
        this.excluding = true;

        const checkBox = $(event.target).closest('td').find('input[type="checkbox"]');

        const checked = checkBox.is(':checked');
        const num: any = $(event.target).closest('tr').attr('rownumber') || '';
        const rownumber = parseInt(num);

        const _title: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.RESTORE_SINGLE_ROW_DIALOG.TITLE');
        const _description: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.RESTORE_SINGLE_ROW_DIALOG.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.RESTORE_SINGLE_ROW_DIALOG.WAIT_DESCRIPTION');
        const _success: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.RESTORE_SINGLE_ROW_DIALOG.MESSAGE');
        const _yesButton: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.RESTORE_SINGLE_ROW_DIALOG.YESBUTTON');
        const _noButton: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.RESTORE_SINGLE_ROW_DIALOG.NOBUTTON');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(async expand => {

            if (expand === undefined) {
                checkBox.prop('checked', checked);
            };

            let payload: any = {
                FileName: doc.FileName,
                DisableRow: !checked,
                RowNumber: rownumber
            }

            const docRow = doc.Check.Rows.find((x: any) => x.RowNumber === rownumber)
            if (docRow) {
                payload['IsForAllDocument'] = true;
                payload['SupplierId'] = doc.Check.SupplierId;
                payload['CurrentGoodsCode'] = docRow.Code;
                payload['GoodsId'] = docRow.GoodsId;
            }

            this.loading$.next(true);
            event.stopPropagation();

            this.magazzinoService.restoreInvoiceRow(payload).toPromise()
                .then(() => {
                    this.layoutUtilsService.showActionNotification(_success, MessageType.Create);
                })
                .finally(() => {
                    this.loading$.next(false);
                    this.excluding = false;
                    this.refreshList(this.currentCompany);
                });
        });
    }

    openSupplier(event: any, supplierId: string) {
        event.stopPropagation();
        const index = this.staticCollectionsService.fornitoriTutti$.findIndex((f: any) => f.Id == supplierId);
        if (index < 0) return;

        let supplier = this.staticCollectionsService.fornitoriTutti$[index];
        supplier.SubjectType = 1;

        let saveMessageTranslateParam = 'ANAGRAFICHE.FORNITORI.ACTION_EDIT.';
        saveMessageTranslateParam += 'UPDATE_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = supplier.Id > 0 ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(FornitoriEditComponent, { data: { item: supplier }, width: '900px' });
        dialogRef.afterClosed().subscribe(async (res) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
            this.staticCollectionsService.fornitoriTutti$[index] = res.item;
            this.refreshList(this.currentCompany);
            await this.staticCollectionsService.refreshFornitori();
        });
    }

    linkSupplier(event: any, invoice: any) {
        const index = this.staticCollectionsService.fornitoriTutti$.findIndex((f: any) => f.Id == event);
        if (index < 0) return;

        let supplier = this.staticCollectionsService.fornitoriTutti$[index];
        supplier.SubjectType = 1;

        let saveMessageTranslateParam = 'ANAGRAFICHE.FORNITORI.ACTION_EDIT.';
        saveMessageTranslateParam += 'UPDATE_MESSAGE';
        const _saveMessage = this.translate.instant(saveMessageTranslateParam);
        const _messageType = supplier.Id > 0 ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(FornitoriEditComponent, { data: { item: supplier }, width: '900px' });
        dialogRef.afterClosed().subscribe(async (res) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
            this.staticCollectionsService.fornitoriTutti$[index] = res.item;
            this.refreshList(this.currentCompany);
            await this.staticCollectionsService.refreshFornitori();
        });
    }

    async showXml(merce: any) {

        lastValueFrom(this.magazzinoService.getRawXml(merce.FileName))
            .then((xml: any) => {

                let blob = new Blob([xml], { type: 'text/xml' });
                let url = URL.createObjectURL(blob);
                window.open(url);
                URL.revokeObjectURL(url); //Releases the resources

            })
            .catch((err: any) => {
                console.log(err);
            });
    }

    async searchGoods(event: any, doc: any) {
        const tr = $(event.target).closest('tr');
        const name = tr.find('td:nth-child(2)').clone().find('span').remove().end().text();
        const num: any = $(event.target).closest('tr').attr('rownumber');
        const rownumber = parseInt(num);
        let merce: any;
        if (tr.hasClass('warning')) {
            const goodId = $(tr).data('goodid');
            merce = await lastValueFrom(this.gestioneMerciService.getSingleGoods(goodId));

        } else {
            const existingCode = $(tr).find('td:nth-child(1) .tx-xsmall').clone().find('span').remove().end().text().trim();
            const prezzoUnitario = $(tr).find('td:nth-child(4)').clone().find('span').remove().end().text().trim();
            const unita = $(tr).find('td:nth-child(5)').clone().find('span').remove().end().text().trim();
            const sconto = $(tr).find('td:nth-child(6)').clone().find('span').remove().end().text().trim().replace('%', '').replace('-', '').replace('.', ',');
            const iva = $(tr).find('td:nth-child(7)').clone().find('span').remove().end().text().trim();

            merce = await this.newMerce(name, doc.Check.SupplierId, existingCode, prezzoUnitario, iva, unita, sconto, true);
        }

        const quantity = $(tr).find('td:nth-child(3)').clone().find('span').remove().end().text().trim();

        this.loading$.next(true);
        lastValueFrom(this.gestioneMerciService.checkLevenshteinDistance(-1, `%23${name}`))
            .then((result: any) => {
                let invoice: any = {
                    FileName: doc.FileName,
                    RowNumber: rownumber
                };
                const docRow = doc.Check.Rows.find((x: any) => x.RowNumber === rownumber)
                if (docRow) {
                    invoice['IsForAllDocument'] = true;
                    invoice['SupplierId'] = doc.Check.SupplierId;
                    invoice['CurrentGoodsCode'] = docRow.Code;
                    invoice['GoodsId'] = docRow.GoodsId;
                }
                const dialogRef = this.dialog.open(MergeGoodsComponent, {
                    data: {
                        merce: merce,
                        list: result,
                        enableOverwrite: true,
                        invoice: invoice,
                        quantity: quantity,
                        locale: this.locale
                    },
                    width: '90%'
                });

                dialogRef.afterClosed().subscribe(async (res: any) => {
                    if (res && res.type == 'overwrite') {
                        ;
                    } else if (res && res.type == 'MERGE_GOODS') {
                        const merce = await this.gestioneMerciService.getSingleGoods(res.merceId).toPromise();
                        const dialogRef = this.dialog.open(EditMerciComponent, {
                            data: {
                                merce: merce,
                                html: doc.originalHtml,
                                disablePrices: true
                            },
                            width: '100%',
                            height: '100%'
                        });
                    } else if (res && res.type === 'MERGE_SUPPLIERS') {
                        const merce = await this.gestioneMerciService.getSingleGoods(res.merceId).toPromise();
                        const dialogRef = this.dialog.open(EditMerciComponent, {
                            data: {
                                merce: merce,
                                html: doc.originalHtml,
                                disablePrices: true,
                                supplierToMerge: res.supplierToMerge
                            },
                            width: '100%',
                            height: '100%'
                        });
                    }
                    this.refreshList(this.currentCompany);

                });

            })
            .catch(() => this.loading$.next(false))
    }

    async performImportInvoice(invoice: any) {

        const _success = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.IMPORT_DIALOG.MESSAGE');
        lastValueFrom(this.magazzinoService.importInvoices(invoice))
            .then(() => {
                this.layoutUtilsService.showActionNotification(_success, MessageType.Create);
                this.refreshList(this.currentCompany);
            })
            .catch(() => {
                this.loading$.next(false);
            });
    }

    async importInvoices(invoice: any, event: any) {
        event.stopPropagation();
        const _title: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.IMPORT_DIALOG.TITLE');
        const _description: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.IMPORT_DIALOG.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.IMPORT_DIALOG.WAIT_DESCRIPTION');

        const _yesButton = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.IMPORT_DIALOG.YESBUTTON');
        const _noButton = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.IMPORT_DIALOG.NOBUTTON');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(expand => {

            if (expand) {

                // Verificho se ci sono delle mappature che non coprono il 100% delle assegnazioni
                if (invoice.Check.IsToSplit) {

                    let PlItems = JSON.parse(JSON.stringify(invoice.Check.PlItems));

                    const dialogRef = this.dialog.open(MapStructureComponent, {
                        data: {
                            PlItems: PlItems.map((item: any) => {
                                item.Percentage = item.Percentage * 100;
                                return item
                            }),
                            check: invoice.Check
                        },
                        width: '500px'
                    });
                    dialogRef.afterClosed().subscribe((res: any) => {
                        console.log(res);
                        if (_.isEmpty(res) || !res) {
                            return;
                        }
                        if (res && res.length > 0) {
                            invoice.Check.PlItems = res.map((item: any) => {
                                item.Percentage = parseFloat(item.Percentage) / 100;
                                return item
                            });
                            this.performImportInvoice(invoice);
                        }
                    });
                } else {
                    this.performImportInvoice(invoice);
                }


            }

        });
    }

    almoustOneImportable() {
        return $('#checkInvoices mat-expansion-panel:visible').length > 0;
    }

    almoustOneSelectedFor() {
        const checked = $('#checkInvoices .selectedFor input[type="checkbox"]:checked');
        return $('#checkInvoices .selectedFor input[type="checkbox"]:checked').length > 0;
    }

    expand(invoice: any, event: any) {
        event.stopPropagation();
        invoice.expandedPreview = true;
        const html$ = $(event.target).closest('.previewCollapsable');

        let height = 0;

        height += html$.find('.tbHeader').height() || 0;

        html$.find('.tbFoglio').each((index: number, element: any) => {
            height += $(element).height() || 0
        });

        html$.find('.separa').each((index: number, element: any) => {
            height += $(element).height() || 0
        });

        $(event.target).closest('.previewCollapsable').find('.previewInvoice').height(height);
    }

    collapse(invoice: any, height: number, event: any) {
        event.stopPropagation();
        invoice.expandedPreview = false;
        $(event.target).closest('.previewCollapsable').find('.previewInvoice').height(height);
    }

    performHideInvoice(event: any, invoice: any) {
        event.preventDefault();
        event.stopPropagation();
        invoice.IsHidden = !invoice.IsHidden;
        this.filterInvoices(true);
    }

    performSelectAllSupplier(event: any) {
        this.currentCompany.DocumentsFiltered.forEach((invoice: any) => {
            invoice.selectedFor = event.checked;
        })
    }

    performDeSelectAllSupplier() {
        this.currentCompany.DocumentsFiltered.forEach((invoice: any) => {
            invoice.selectedFor = false;
        })
        this.selectAllSupplier = false;
    }

    ddListEntered(event: any) {
        $('body').addClass(event.target.id);
    }

    ddListExited(event: any) {
        $('body').removeClass(event.target.id);
    }

    async performBulkSelectionHeader(event: any) {
        const selectedItems = this.currentCompany.DocumentsFiltered.filter((i: any) => i.selectedFor);

        this.loading$.next(true);
        lastValueFrom(this.bulkActionsService.massiveActions({
            "Action": event,
            "FileNames": selectedItems.map((item: any) => item.FileName),
            "Value": 1
        }))
            .then(() => {
                this.loading$.next(false);
                this.refreshList(this.currentCompany);
            });
    }

    bulkSelectionHeader(event: any) {
        this.ref.detectChanges();

        let _title: string = '';
        switch (event) {
            case 'Fe_SetDisableDocument':
                _title = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.EXCLUDE_ROW_FROM_IMPORT_LEGEND');
                break;
            case 'Fe_SetExcludeCE':
                _title = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.EXCLUDE_FROM_IMPORTCE_LEGEND');
                break;
            case 'Fe_SetExcludePurchase':
                _title = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTED_PURCHASES.EXCLUDE_FROM_IMPORTPUR_LEGEND');
                break;
        }
        const _description: string = this.translate.instant('SCADENZIARIO.BULK_ACTIONS.CONFIRM_ASK');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('COMMONS.CONFIRM');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe(async (ok: any) => {
            if (ok) {
                this.performBulkSelectionHeader(event);
            }
            this.bulkSelect = "";
            this.ref.detectChanges();
        });

    }
}