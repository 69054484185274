import { Component, Injectable, OnInit } from '@angular/core';
import { ManutenzioneService } from '../manutenzione.service';
import { BehaviorSubject, Subscription, lastValueFrom } from 'rxjs';
import { SwitchGroupService } from '@app/core/_base/layout';
import { NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EndpointsService } from '@app/core/services/endpoints.service';
import moment from 'moment';

@Injectable()
class CustomDatepickerI18n extends NgbDatepickerI18n {
    private languageSubscription!: Subscription;

    constructor(private translate: TranslateService) {
        super();
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        // Personalizza l'etichetta per l'accessibilità (aria-label) dei giorni
        return `${date.day}-${date.month}-${date.year}`;
    }

    getWeekdayShortName(weekday: number): string {
        // Restituisci l'abbreviazione dei giorni della settimana (Es. Dom, Lun, Mar, ...)
        return this.translate.instant(`dayNamesShort.${weekday}`);
    }

    getWeekdayLabel(weekday: number): string {
        // Restituisci il nome completo dei giorni della settimana (Es. Domenica, Lunedì, Martedì, ...)
        return this.translate.instant(`dayNamesShort.${weekday}`);
    }

    getMonthShortName(month: number): string {
        // Restituisci l'abbreviazione dei mesi (Es. Gen, Feb, Mar, ...)
        return this.translate.instant(`monthNamesShort.${month}`);
    }

    getMonthFullName(month: number): string {
        // Restituisci il nome completo dei mesi (Es. Gennaio, Febbraio, Marzo, ...)
        return this.translate.instant(`monthNames.${month}`);
    }

    ngOnDestroy(): void {
        // Disiscriviti alla chiusura del componente per evitare memory leaks
        this.languageSubscription.unsubscribe();
    }
}


@Component({
    selector: 'kt-logs',
    templateUrl: './logs.component.html',
    styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

    logs!: string;
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    switchGroupSubcription!: Subscription;
    filterDate: any = {
        day: new Date().getDate(),
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    };
    logType: string = 'General';

    constructor(
        private manutenzioneService: ManutenzioneService,
        private switchGroupService: SwitchGroupService,
        private endpointsService: EndpointsService
    ) { }

    ngOnInit(): void {
        this.refreshLogs();
    }

    async ngAfterViewInit() {
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.refreshLogs();
                }
            }
        );
    }

    refreshLogs() {
        this.loading$.next(true);
        this.logs = '';
        const date = moment(new Date(this.filterDate.year, this.filterDate.month - 1, this.filterDate.day)).format('YYYY-MM-DD');
        lastValueFrom(this.manutenzioneService.getLogFile(this.logType, date))
            .then((data) => {
                this.logs = data;
            })
            .finally(() => {
                this.loading$.next(false);
            });
    }

    downloadLog() {
        const date = moment(new Date(this.filterDate.year, this.filterDate.month - 1, this.filterDate.day)).format('YYYY-MM-DD');
        const dateStr = moment(new Date(this.filterDate.year, this.filterDate.month - 1, this.filterDate.day)).format('YYYY_MM_DD');
        lastValueFrom(this.manutenzioneService.downloadLog(this.logType, date))
        .then((ret: any) => {
                const file = new window.Blob([ret], { type: "octet/stream" });

                const fileURL = URL.createObjectURL(file);

                let a = document.createElement('a');
                document.body.appendChild(a);

                a.setAttribute('style', 'display: none');
                a.href = fileURL;
                a.download = `log_${this.logType}_${dateStr}.zip`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                a.remove();


            }, (error: any) => {
                console.log(error);
            }
        )
        .catch((error: any) => {
            error
        });
    }

}
