import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export interface ValidationResult {
    [key: string]: boolean;
}

export class PasswordValidator {

    public static strong(control: FormControl): ValidationResult {
        let result: any = null;
        let hasNumber = /\d/.test(control.value);
        let hasUpper = /[A-Z]/.test(control.value);
        let hasLower = /[a-z]/.test(control.value);
        console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
        const valid = hasNumber && hasUpper && hasLower;
        if (!valid) {
            // return what´s not valid
            result = { strong: true };
        }
        console.log(result);
        return result;
    }
}


export class RepeatPasswordEStateMatcher implements ErrorStateMatcher {
    isErrorState(control: any, form: FormGroupDirective | NgForm | null): boolean {
        const newPassword = control && control['parent'] ? control['parent'].get('newPassword').value : '';
        const confirmPassword = control && control['parent'] ? control['parent'].get('confirmPassword').value : '';
        return (newPassword !== confirmPassword && control['dirty'])
    }
}
export function RepeatPasswordValidator(group: FormGroup) {
    const password = group.controls['newPassword'].value;
    const passwordConfirmation = group.controls['confirmPassword'].value;

    return password === passwordConfirmation ? null : { passwordsNotEqual: true }
}