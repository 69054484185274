// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// Services
import { EndpointsService } from '@app/core/services/endpoints.service';

@Component({
	selector: 'kt-endpoints-edit',
	templateUrl: './endpoints-edit.component.html',
	styleUrls: ['./endpoints-edit.component.scss']
})
export class EndpointsEditComponent implements OnInit {

	// Public properties
	endpoint: any;
	endpointForm!: FormGroup;
	hasFormErrors: boolean = false;
	viewLoading: boolean = false;
	// Private properties
	private componentSubscriptions!: Subscription;

	constructor(
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
    private endpointsService: EndpointsService
	) { }

	/**
	   * On init
	   */
	ngOnInit() {
		this.endpoint = this.data.endpoint;
		this.createForm();
	}

	/**
   * On destroy
   */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	createForm() {
		this.endpointForm = this.fb.group({
			Name: [this.endpoint.Name, Validators.required],
			Description: [this.endpoint.Description, null],
			ApiEndpointURL: [this.endpoint.ApiEndpointURL, Validators.required]
		});
	}

	/**
   * Returns page title
   */
	getTitle(): string {
		if (this.endpoint.Id.length > 0) {
			return `Edit endpoint '${this.endpoint.Name} (${this.endpoint.ApiEndpointURL})'`;
		}
		return 'New endpoint';
	}

	/**
   * Check control is invalid
   * @param controlName: string
   */
	isControlInvalid(controlName: string): boolean {
		const control = this.endpointForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */

	/**
	 * Returns prepared customer
	 */
	prepareEndpoint(): any {
		const controls = this.endpointForm.controls;
		let _endpoint = {
			Name: controls['Name'].value,
			Description: controls['Description'].value,
			ApiEndpointURL: controls['ApiEndpointURL'].value
		};
		if (this.endpoint.Id) {
			_endpoint['Id'] = this.endpoint.Id
		}
		return _endpoint;
	}

	/**
   * On Submit
   */
	onSubmit() {
		this.hasFormErrors = false;
		const controls = this.endpointForm.controls;
		/** check form */
		if (this.endpointForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		const editedEndpoint = this.prepareEndpoint();
		if (editedEndpoint.Id && editedEndpoint.Id.length > 0) {
			this.updateEndpoint(editedEndpoint);
		} else {
			this.createEndpoint(editedEndpoint);
		}
	}

	/**
	 * Update customer
	 *
	 * @param _customer: CustomerModel
	 */
	updateEndpoint(endpoint: any) {
    this.endpointsService.updateEndpoint(endpoint).subscribe(
      (ret: any) => {
        console.log('updateEndpoint', ret);
          this.dialogRef.close({ endpoint, isEdit: true });
      }, (error: any) => {
        this.dialogRef.close(null);
      }
    )
	}

  /**
   * Update customer
   *
   * @param _customer: CustomerModel
   */
  createEndpoint(endpoint: any) {
    this.endpointsService.createEndpoint(endpoint).subscribe(
      (ret: any) => {
        console.log('updateEndpoint', ret);
          this.dialogRef.close({ endpoint, isEdit: false });
      }, (error: any) => {
        this.dialogRef.close(null);
      }
    )
  }

}
