import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

@Injectable({
	providedIn: 'root'
})
export class FiltriService implements OnDestroy {

	public performFilter$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public readOnly$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);	
	public triggerOpenFilter$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public clearFilters$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private _filterConfig: any;

	// oldTriggerState: boolean = false;

	constructor(
		private translate: TranslateService,
		private translationService: TranslationService
	) {
		// Intercetto il filtro per memorizzarlo.
		this.performFilter$.subscribe(
			(filterConfig: any) => {
				if (!filterConfig || (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object)) return;
				this._filterConfig = filterConfig;
			}
		);
	}

	ngOnDestroy() {
		console.log('foo destroy')
	}

	public get filterConfig() {
		try {
			return JSON.parse(JSON.stringify(this._filterConfig));
		} catch (error) {
			return {}
		}

	}

	public set filterConfig(filter: any) {
		this._filterConfig = JSON.parse(JSON.stringify(filter));
	}

	translatePeriod(period: string): string {
		switch (period.toLowerCase()) {
			case 'yesterday': return this.translate.instant('FILTRI.PERIOD_TYPE.YESTERDAY');
				break;
			case 'ytd': return this.translate.instant('FILTRI.PERIOD_TYPE.YTD');
				break;
			case 'mtd': return this.translate.instant('FILTRI.PERIOD_TYPE.MTD');
				break;
			case 'year': return this.translate.instant('FILTRI.PERIOD_TYPE.YEAR');
				break;
			case 'month': return this.translate.instant('FILTRI.PERIOD_TYPE.MONTH');
				break;
			case 'week': return this.translate.instant('FILTRI.PERIOD_TYPE.WEEK');
				break;
			case 'day': return this.translate.instant('FILTRI.PERIOD_TYPE.DAY');
				break;
			case 'onlyyesterday':
				return this.translate.instant('FILTRI.PERIOD_TYPE.ONLYYESTERDAY');
				break;
		}
		return period;
	}

	getCurrentPeriod() {
		let options: any = { year: 'numeric', month: 'numeric', day: 'numeric' };
		options.year = 'numeric';
		options.month = 'numeric';
		options.day = 'numeric';
		try {
			return this._filterConfig.Period ? this.translate.instant('FILTRI.PERIOD') + ': ' + this.translatePeriod(this._filterConfig.Period)
				: this.translate.instant('FILTRI.FROM') + ': ' + new Date(this._filterConfig.DateFilter.Start).toLocaleDateString(this.translationService.getSelectedLanguage(), options) + ' - ' +
				this.translate.instant('FILTRI.TO') + ': ' + new Date(this._filterConfig.DateFilter.End).toLocaleDateString(this.translationService.getSelectedLanguage(), options);
		} catch (error) {
			console.log(error);
			return '';
		}

	}

}
