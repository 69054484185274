import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kt-select-interval',
  templateUrl: './select-interval.component.html',
  styleUrls: ['./select-interval.component.scss']
})
export class SelectIntervalComponent implements OnInit {

  dateStart:any;
  dateEnd:any;


  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(){
    this.dialogRef.close({
          success: true,
          body: {
            DateStart: this.dateStart,
            DateEnd: this.dateEnd,
          }
        });
  }

}
