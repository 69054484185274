import { Component, OnInit, ChangeDetectorRef, ViewChild, HostListener } from '@angular/core';

// Services
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { SwitchGroupService, TranslationService } from '@app/core/_base/layout';
import { TranslateService } from '@ngx-translate/core';

// RXJS
import { BehaviorSubject, Subscription } from 'rxjs';

//Pipes
import { LayoutUtilsService } from '@app/core/_base/crud';

import { Router } from '@angular/router';
import { CategoryGraphComponent } from '../category-graph/category-graph.component';
import { MatDialog } from '@angular/material/dialog';

declare var $: any;

@Component({
    selector: 'kt-conto-economico',
    templateUrl: './conto-economico.component.html',
    styleUrls: ['./conto-economico.component.scss']
})
export class ContoEconomicoComponent implements OnInit {

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    switchGroupSubcription!: Subscription;
    staticCollectionsSubcription!: Subscription;
    locale!: string;

    filters: any;
    canTraining: boolean = false;
    currentView: string = 'standard';

    public ceView: any;
    @ViewChild('ceView', { static: false }) set _set(content: any) {
        if (content) { // initially setter gets called with undefined
            this.ceView = content;
            //this.clearFilters(false, false);
        }
    }

    constructor(
        public staticCollectionsService: StaticCollectionsService,
        private switchGroupService: SwitchGroupService,
        private translate: TranslateService,
        private translationService: TranslationService,
        private layoutUtilsService: LayoutUtilsService,
        private router: Router,
        private ref: ChangeDetectorRef,
        public dialog: MatDialog
    ) {
        this.filters = {
            ck_hide0: true,
            percTotale: false,
            scostamento: true,
            ck_lordo: true,
            ck_budget: false,
            ck_cover: false,
            ck_prevyear: false,
            ck_abs: true,
            ck_showCover: true,
            filterCompanies: [],
            filtercostCenters: [],
            selecterYear: new Date().getFullYear(),
        };

        const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
        this.canTraining = currentUser.Permissions && currentUser.Permissions.IsTraining;
    }

    @HostListener('document:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {

        if (!this.canTraining) return;

        if (event.ctrlKey && event.altKey && event.code === 'KeyT') {
            this.ceView.showTraining(true);
        } else if (event.ctrlKey && event.altKey && event.code === 'KeyZ') {
            this.ceView.showTraining(false);
        }
    }

    async ngOnInit() {
        document.body.classList.add('kt-aside--minimize');
        this.loading$.next(true);
        await this.staticCollectionsService.fillStaticCollection(false);
        this.loading$.next(false);
    }

    ngOnDestroy() {
        this.switchGroupSubcription.unsubscribe();
    }

    async ngAfterViewInit() {
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            async (event: any) => {
                if (event.change) {
                    this.currentView = '';
                    this.loading$.next(true);
                    await this.staticCollectionsService.fillStaticCollection(true);
                    setTimeout(() => {
                        this.loading$.next(false);
                        this.clearFilters(false, false);
                        //this.ceView.initDataTable();
                        this.currentView = 'standard';
                        this.ref.detectChanges();
                    }, 100);
                }
            }
        );
    }

    currentYear: number = new Date().getFullYear();
    getLastYears(years: number) {
        // crea un array con gli ultimi years anni
        var yearsArray: number[] = [];
        for (var i = 0; i < years; i++) {
            yearsArray.push(this.currentYear - i);
        }
        return yearsArray;
    }

    goToDashboard() {
        localStorage.setItem('currentFilterCfg', JSON.stringify(this.ceView.currentFilterCfg))
        this.router.navigate(['/amministrazione/dashboard'], { queryParams: { noLoad: true } });
    }

    clearFilters(actionFilter: boolean = true, redrawTable: boolean = true) {
        this.filters.ck_hide0 = true;
        this.filters.ck_budget = false;
        this.filters.ck_cover = false;
        this.filters.ck_lordo = true;
        this.filters.ck_prevyear = false;
        this.filters.ck_abs = true;
        this.filters.ck_showCover = true;
        this.filters.scostamento = true;
        this.filters.percTotale = false;
        this.filters.filterCompanies = [];
        this.filters.filtercostCenters = [];
        this.filters.PLCategoryName = '';

        this.ceView.training = 1;

        this.ceView.clearFilters(actionFilter, redrawTable)
    }

    manageFilterCompanies(event: any) {
        if (this.currentView !== 'companies') {
            this.filter(event, 'Companies');
        } else {
            this.ceView.refreshColumnsVisibility(event, 'companies');
        }
    }

    filter(value: any, field: string) {
        this.ceView.currentFilterCfg[field] = value;
        this.ceView.filtriService.filterConfig = this.ceView.currentFilterCfg;
        this.ceView.table.draw();
    }

    filterByYear() {
        this.filter({
            Start: `${this.filters.selecterYear}-01-01`,
            End: `${this.filters.selecterYear}-12-31`
        }, 'DateFilter')
    }

    openCategoryGraph(item: any) {
        this.dialog
            .open(CategoryGraphComponent, {
                data: {
                    item: item,
                    filters: this.filters,
                    currentFilterCfg: this.ceView.currentFilterCfg,
                    locale: this.locale,
                    training: this.ceView.training
                },
                width: '1000px'
            });
    }

    performFilter(value, name) {
        if (!value) value = '';
        const key = name + ":name";
        var column = this.ceView.table.column(key);
        if (column) {
            column.search(value).draw();
        }
        else {
            console.log("Controllare la colonna")
        }
    }

    exportAsXLSX() {
        const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
        const _description: string = this.translate.instant('EXPORT_XLSX.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');
        const _success = this.translate.instant('EXPORT_XLSX.MESSAGE');
        const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');
        const _yesButton2 = this.translate.instant('EXPORT_XLSX.NOBUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        if (this.currentView === 'daily') {
            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
            dialogRef.afterClosed().subscribe(expand => {
                if (expand !== undefined) this.ceView.exportAsXLSX()
            });
        } else {
            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton, _yesButton2);
            dialogRef.afterClosed().subscribe(expand => {
                if (expand !== undefined) this.ceView.exportAsXLSX(expand)
            });
        }
    }

    exportAsPDF(expand: boolean = false) {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: this.currentView !== 'daily',
            explodeRows: this.translate.instant('EXPORT_PDF.EXPLODE_ROWS')
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('GESTIONE_FATTURE.TITLE'),
                    //period: this.filtriService.getCurrentPeriod()
                };
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                };
                result['language'] = this.translationService.getSelectedLanguage();

                this.ceView.exportAsPDF(result)
            };
        });
    }

    performShowTotals(view: string) {
        const forcedValue = this.currentView !== view ? true : false;
        this.setCurrentView(view).then(() => {
            this.ceView.performShowTotals(forcedValue);
        });
    }

    scrollUp() {
        var elem: any = document.getElementById("ceTable");
        elem.scrollIntoView({
            behavior: 'auto',
            block: 'start',
            inline: 'nearest'
        });
    }

    setCurrentView(view: string) {
        return new Promise((resolve) => {
            this.currentView = view;
            setTimeout(() => {
                resolve(true);
            }, 100);
        });
    }
}
