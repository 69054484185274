// Angular
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../../core/auth';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ChangePasswordComponent } from '../../../../pages/auth/change-password/change-password.component';
import { AuthNoticeService, AuthService } from '../../../../../core/auth';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import * as _ from 'lodash';
// Services
import { ConfigService } from '@app/core/services/config.service';
import { LayoutUtilsService, MessageType, QueryParamsModel } from '@app/core/_base/crud';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$!: Observable<User>;

	@Input() avatar: boolean = true;
	@Input() greeting: boolean = true;
	@Input() badge: boolean = false;
	@Input() icon: boolean = false;

	showPwd = false;
	role: string = '';
	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AppState>,
		private router: Router,
		public dialog: MatDialog,
		public authService: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private cdr: ChangeDetectorRef,
		private switchGroupService: SwitchGroupService,
		private configSrv: ConfigService,
		private layoutUtilsService: LayoutUtilsService
	) {
	}

	/**
	 * @ Lifecycle sequence(s: any) => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.user$ = this.store.pipe(select(currentUser));
		const currentLogin = this.switchGroupService.getCurrentGroup();
		this.role = currentLogin.Roles[0];
	}

	/**
	 * Log out
	 */
	logout() {
		this.store.dispatch(new Logout());
	}

	unCamelCase(str: any) {
		return str
			// insert a space between lower & upper
			.replace(/([a-z])([A-Z])/g, '$1 $2')
			// space before last upper in a sequence followed by lower
			.replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
			// uppercase the first character
			.replace(/^./, function (str: any) { return str.toUpperCase(); })
	}

	changePwd() {
		const dialogRef = this.dialog.open(ChangePasswordComponent, {
			data: {},
			width: '600px'
		});
		dialogRef.afterClosed().subscribe((res: any) => {
			if (_.isEmpty(res) || !res) {
				return;
			}

			if (res.success && res.body) {
				this.authService.changePassword(res.body.OldPassword, res.body.NewPassword)
					/*
					.pipe(
						tap(response => {
							if (response && response.length > 0) {
								if (response === 'Ok'){
								this.authNoticeService.setNotice(this.translate.instant('AUTH.FORGOT.SUCCESS'), 'success');
								this.router.navigateByUrl('/auth/login');
								}
								else {
								  this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.GENERROR', {name: this.translate.instant('AUTH.INPUT.EMAIL')}), 'danger');
								}
							} else {
								this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.NOT_FOUND', {name: this.translate.instant('AUTH.INPUT.EMAIL')}), 'danger');
							}
						}),
						takeUntil(this.unsubscribe),
						finalize(() => {
							this.cdr.markForCheck();
						})
					).subscribe();
					*/
					.subscribe((response: any) => {

						if (response && response.length > 0) {
							if (response === 'Ok') {
								this.authNoticeService.setNotice(this.translate.instant('AUTH.FORGOT.SUCCESS'), 'success');
								this.router.navigateByUrl('/auth/login');
							} else {
								this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.GENERROR', { name: this.translate.instant('AUTH.INPUT.EMAIL') }), 'danger');
							}
						} else {
							this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.NOT_FOUND', { name: this.translate.instant('AUTH.INPUT.EMAIL') }), 'danger');
						}

					})

			}

		});
	}

	showRefresh() {
		const currentLogin = this.switchGroupService.getCurrentGroup();
		let role = '';
		if (currentLogin) {
			role = currentLogin.Roles[0];
		}
		return role == 'SuperAdmin' || role == 'CompanyAdmin';
	}

	refreshData() {

		this.configSrv.refreshAllData().subscribe(
			arg => {
				const _title: string = this.translate.instant('TOPBAR.REFRESH.TITLE');
				const _description: string = this.translate.instant('TOPBAR.REFRESH.DESCRIPTION');
				const _waitDesciption: string = this.translate.instant('TOPBAR.REFRESH.MESSAGE');
				const _okButton = this.translate.instant('COMMONS.OK');
				const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _okButton);
				dialogRef.afterClosed().subscribe(() => {
					window.location.reload();
				});
			}
		);

	}

}
