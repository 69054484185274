import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuConfig } from '@app/core/_config/menu.config';
import * as objectPath from 'object-path';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  navigationHistoryChanged: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  navigationList: any = [];
  lastUrl!: string;
  lastPage: any;

  constructor() { 

    const asideMenu = (new MenuConfig()).defaults.aside.items;

    this.navigationHistoryChanged.subscribe((res: any) => {
      if ( res ) {
        const item = this.findRoute(asideMenu, res);

        if ( item ) {

        //  const alreadyExists = this.navigationList.find((el: any) => el.page == item.page);
        //  if (!alreadyExists) {
            this.navigationList.unshift({
              translate: item.translate,
              page: item.page,
              icon: item.icon,
              time: moment()
            });
        //  }

        } else {
          this.navigationList.unshift({
            page: res,
            time: moment()
          })
        }

        if (this.navigationList.length > 5) {
          this.navigationList = this.navigationList.slice(0, 5);
        }

        if(!this.navigationList || this.navigationList.length <= 1) {
          this.lastPage = undefined;
        } else {
          this.lastPage = this.navigationList[1];
        }

      }
    })

  }

  findRoute(items: any, route: string) {
    let find: any = undefined;
    for(let element of items) {
      if ( element.page && element.page == route) {
        return element;
      } else if (element.submenu && element.submenu.length > 0) {
        find =  this.findRoute(element.submenu, route);
        if (find) {
          find.icon = element.icon;
          break;
        }
      }
    }

    return find

  }
}
