import { Component, OnInit, Inject } from '@angular/core';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

@Component({
    selector: 'kt-set-supplier-dialog',
    templateUrl: './set-supplier-dialog.component.html',
    styleUrls: ['./set-supplier-dialog.component.scss']
  })
  export class SetSupplierDialogComponent implements OnInit {

    supplierId!: string;
    suppliers: any;
    companies: any;

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        public staticCollections: StaticCollectionsService
    ) { }

    ngOnInit() {
        this.suppliers = this.data.suppliers;
        this.supplierId = this.data.supplierId;
    }

    onSubmit() {
        this.dialogRef.close({
            supplierId: this.supplierId[0],
        });
    }

}


