import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API } from '@env/environment';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

@Injectable({
    providedIn: 'root'
})
export class AnagraficheService {

    constructor(
        private http: HttpClient,
        private switchGroupService: SwitchGroupService
    ) { }

    getCompanies() {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(`${currentLogin.endpointURL}/api/Filter/CompaniesFull`, { headers: httpHeaders });
    }

    getDistributionDates(date, start, mode, frequency, includeMonth, repetitions) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });
        var payload = {
            Date: date,
            Mode: mode,
            Start: start,
            Frequency: frequency,
            IncludeCurrentMonth: includeMonth,
            Repetitions: repetitions
        };
        return this.http.post<any>(`${currentLogin.endpointURL}/api/Fe/DistributionDates`, payload, { headers: httpHeaders });
    }

    getPLStructure() {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(`${currentLogin.endpointURL}/api/PL/Structure`, { headers: httpHeaders });
    }

    getEntity(entityName: string): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(`${currentLogin.endpointURL}/api/${entityName}`, { headers: httpHeaders });
    }

    getBrands(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(`${currentLogin.endpointURL}/api/Brands`, { headers: httpHeaders });
    }

    async getSupplierById(supplierId: string) {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        const observable: Observable<boolean> = this.http.get<any>(`${currentLogin.endpointURL}/api/Supplier/${supplierId}`, { headers: httpHeaders });
        const v = await observable.toPromise();
        return v;
    }

    postEntity(entityName: string, entityBody: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.post<any>(`${currentLogin.endpointURL}/api/${entityName}`, entityBody, { headers: httpHeaders });
    }

    putEntity(entityName: string, entityBody: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.put<any>(`${currentLogin.endpointURL}/api/${entityName}/${entityBody.Id}`, entityBody, { headers: httpHeaders });
    }

    deleteEntity(entityName: string, entityBody: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.delete<any>(`${currentLogin.endpointURL}/api/${entityName}/${entityBody.Id}`, { headers: httpHeaders });
    }

    getUnitList(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(`${currentLogin.endpointURL}/api/Unit`, { headers: httpHeaders });
    }

    getOrderUnitList(): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.get<any>(`${currentLogin.endpointURL}/api/OrderUnit`, { headers: httpHeaders });
    }

    wasteTypesSetFlag(payload: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();

        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentLogin.access_token
        });

        return this.http.post<any>(`${currentLogin.endpointURL}/api/WasteTypes/SetFlag`, payload, { headers: httpHeaders });
    }
}
