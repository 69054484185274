import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RoleService } from '@app/core/_base/layout';
import { API } from '@env/environment';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'kt-support-selector',
    templateUrl: './support-selector.component.html',
    styleUrls: ['./support-selector.component.scss']
})
export class SupportSelectorComponent implements OnInit {

    @Input() iconType!: '' | 'brand';
    constructor(
        public roleService: RoleService,
        private router: Router,
        private http: HttpClient
    ) { }

    ngOnInit() {

        const routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.checkHelpForThisPage();
            }
        });

        this.checkHelpForThisPage();
    }

    isMac() {
        return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    }

    openHelp() {
        const currentUrl = this.router.url.substring(1).replace(/\//g, '_');
        const url = `/assets/support/${currentUrl}.pdf`;
        window.open(url, '_blank');
    }

    showHelpForThisPage: boolean = false;
    checkHelpForThisPage() {
        const checkFileExists = async (url: string) => {
            const response = await fetch(url, {
                method: 'HEAD',
            });
            if (response.ok) {
                // Controlla il tipo di contenuto della risposta
                const contentType = response.headers.get('Content-Type');
                if (contentType && contentType.startsWith('application/pdf')) {
                    return true;
                }
            }
            return false;
        };

        const currentUrl = this.router.url.substring(1).replace(/\//g, '_');
        const url = `/assets/support/${currentUrl}.pdf`;

        console.log('checkHelpForThisPage', url);

        checkFileExists(url).then((fileExists) => {
            this.showHelpForThisPage = fileExists;
        }).catch((error) => {
            console.log('checkHelpForThisPage error', error);
            this.showHelpForThisPage = false;
        });
    }

}
