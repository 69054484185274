import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { catchError, map, take, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FiltriService } from '@app/core/services/filtri.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { TranslateService } from '@ngx-translate/core';
// Layout
import { LayoutConfigService } from '@app/core/_base/layout';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { InserimentoAcquistiPopupComponent } from './inserimento-acquisti-popup/inserimento-acquisti-popup.component';
import { ColumnsSelectionPopupComponent } from '@app/views/partials/content/crud/columns-selection-popup/columns-selection-popup.component';
import { InserimentoType } from '@app/views/pages/magazzino/inserimento-comune/inserimento-comune.component';
import * as _ from 'lodash';
import * as util from '@app/core/services/utilityfunctions';

import { RoleService } from '@app/core/_base/layout/services/role.service';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { environment } from '@env/environment';

// Components
import { ImportedPurchasesComponent } from '../../../partials/content/crud/imported-purchases/imported-purchases.component';
import { ContoEconomicoService } from '../../amministrazione/conto-economico/conto-economico.service';
import { ContoEconomicoDetailsComponent } from '../../amministrazione/conto-economico/conto-economico-details/conto-economico-details.component';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { AnalisiPrezziFornitoriDialogComponent } from '@app/views/partials/content/crud/analisi-prezzi-fornitori-dialog/analisi-prezzi-fornitori-dialog.component';

@Component({
    selector: 'kt-acquisti',
    templateUrl: './acquisti.component.html',
    styleUrls: ['./acquisti.component.scss', '../common-styles.scss']
})
export class AcquistiComponent implements OnInit, AfterViewInit, OnDestroy {

    utility: any;
    lista: any;
    listaFiltered: any;
    listaSubscribtions!: Subscription
    companies: any;
    currentCompanyFilter!: string;

    switchGroupSubcription!: Subscription;
    filtriServiceSubscription!: Subscription;
    staticCollectionsSubcription!: Subscription;

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    dataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    table: any;
    currentFilterCfg: any;
    pagination: any;
    productStartsWith: number = 0;
    ordStartsWith: number = 0;

    // FILTRI
    categorieFiltri: any;
    fornitoriFiltri: any;

    @ViewChild('ProductOrCode', { static: true }) ProductOrCode!: ElementRef;
    @ViewChild('OrderOrDocument', { static: true }) OrderOrDocument!: ElementRef;

    columnsList: any = [];
    locale!: string;

    constructor(
        public filtriService: FiltriService,
        private magazzinoService: MagazzinoService,
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private switchGroupService: SwitchGroupService,
        public staticCollectionsService: StaticCollectionsService,
        public dialog: MatDialog,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public roleService: RoleService,
        private excelService: ExcelService,
        private pdfService: PDFService,
        private contoEconomicoService: ContoEconomicoService,
        private gestioneMerciService: GestioneMerciService
    ) {
        this.utility = util;
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
        this.columnsList = [
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DATE'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COMPANY'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COSTCENTERS'), enabled: false },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.SUPPLIER'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DOC_NUMBER'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.ORDER_NUMBER'), enabled: false },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CODE'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CATEGORY'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NAME'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.QUANTITY'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.UNIT'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.PRICE'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.TOTAL'), enabled: true },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DEPARTMENT'), enabled: false },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NOTE'), enabled: true }
        ];

        //this.showImportedPurchases();
    }

    /**
     * After view init
     */
    async ngAfterViewInit() {
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.loading$.next(true);
                    this.clearFilters(false);
                    $('#table').DataTable().clear().destroy();
                    this.lista = [];
                    this.listaFiltered = [];
                    this.dataReady$.next(false);
                    this.ref.detectChanges();
                    this.loading$.next(false);
                }
            }
        );
        await this.staticCollectionsService.fillStaticCollection(false);
        this.loading$.next(false);

        this.activatedRoute.queryParams.subscribe((params: any) => {
            setTimeout(() => {
                if (params.ProductOrCode) {
                    this.ProductOrCode.nativeElement.value = params.ProductOrCode;
                    this.filter(params.ProductOrCode, 'ProductOrCode');
                } else if (params.filters) {
                    try {
                        const filters = JSON.parse(params.filters);
                        if (!this.currentFilterCfg) this.currentFilterCfg = {};
                        for (const [key, value] of Object.entries(filters)) {
                            this.currentFilterCfg[key] = value;
                        }
                        this.filtriService.performFilter$.next(this.currentFilterCfg);
                    } catch (error) {
                        console.log(error);
                    }

                }

            }, 1000);
        });
    }

    ngOnDestroy() {
        if (this.listaSubscribtions) this.listaSubscribtions.unsubscribe();
        this.switchGroupSubcription.unsubscribe();
        // if (this.staticCollectionsSubcription) this.staticCollectionsSubcription.unsubscribe();
        this.filtriServiceSubscription.unsubscribe();

        // cancello il filtro
        this.filtriService.filterConfig = {};

        // Se l'url non include inserimento-comune allora svuoto la localstorage
        if (!this.router.url.includes('/magazzino/inserimento-comune')) {
            localStorage.removeItem('currentFilterCfg');
        }
    }

    searchByCode(code: string) {
        this.ProductOrCode.nativeElement.value = code;
        this.filter(code, 'ProductOrCode')
    }

    searchByDocNumber(code: string) {
        this.OrderOrDocument.nativeElement.value = code;
        this.filter(code, 'OrderOrDocument')
    }

    getTitle() {
        return this.translate.instant('MAGAZZINO.ACQUISTI.TITLE');
    }

    clearFilters(actionFilter: boolean = true) {
        this.categorie = undefined;
        this.fornitori = undefined;
        this.tagsList = undefined;
        if (this.ProductOrCode) this.ProductOrCode.nativeElement.value = '';
        if (this.OrderOrDocument) this.OrderOrDocument.nativeElement.value = '';

        this.filtriService.clearFilters$.next(actionFilter);
        $('#table').DataTable().clear().destroy();
        this.lista = [];
        this.listaFiltered = [];
        this.dataReady$.next(false);
        this.currentFilterCfg = [];

    }

    ngOnInit() {
        this.categorie = undefined;
        this.fornitori = undefined;
        this.tagsList = undefined;
        document.body.classList.add('kt-aside--minimize');
        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            (filterConfig: any) => {

                // Se ho un filtro memorizzato lo recupero

                if (localStorage.getItem('currentFilterCfg')) {
                    try {
                        this.currentFilterCfg = JSON.parse(localStorage.getItem('currentFilterCfg') || '');
                        localStorage.removeItem('currentFilterCfg');
                        this.filtriService.performFilter$.next(this.currentFilterCfg);
                        return;
                    } catch (error) {
                        localStorage.removeItem('currentFilterCfg');
                    }
                }


                if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) {
                    return
                };
                if (filterConfig.constructor !== Object) {
                    return
                };
                this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

                this.pagination = {
                    page: 1,
                    pageSize: 15,
                    sort: 'Date',
                    sortMode: 'Desc',
                    TotalRows: 0
                };

                // Inizializzo EVENTUALMENTE le select
                setTimeout(() => {
                    if (this.currentFilterCfg && this.currentFilterCfg.CategoryIds) {
                        this.categorie = this.currentFilterCfg.CategoryIds;
                    }

                    if (this.currentFilterCfg && this.currentFilterCfg.SupplierIds) {
                        this.fornitori = this.currentFilterCfg.SupplierIds;
                    }

                    if (this.currentFilterCfg && this.currentFilterCfg.Tags) {
                        this.tagsList = this.currentFilterCfg.Tags;
                    }
                }, 1000)

                if (this.currentFilterCfg && this.currentFilterCfg.ProductOrCode) {
                    if (this.currentFilterCfg.ProductOrCode[0] == '#') {
                        if (this.currentFilterCfg.ProductOrCode[this.currentFilterCfg.ProductOrCode.length-1] == '#') {
                            this.productStartsWith = 2;
                        } else {
                            this.productStartsWith = 1
                        }
                    }
                    this.ProductOrCode.nativeElement.value = this.currentFilterCfg.ProductOrCode.replace(/#/g, '');
                }

                if (this.currentFilterCfg && this.currentFilterCfg.OrderOrDocument) {

                    if (this.currentFilterCfg.OrderOrDocument[0] == '#') {
                        if (this.currentFilterCfg.OrderOrDocument[this.currentFilterCfg.OrderOrDocument.length-1] == '#') {
                            this.ordStartsWith = 2;
                        } else {
                            this.ordStartsWith = 1
                        }
                    }

                    this.OrderOrDocument.nativeElement.value = this.currentFilterCfg.OrderOrDocument.replace(/#/g, '');
                }

                this.refreshTable();
            }
        );

        fromEvent(this.ProductOrCode.nativeElement, 'keyup')
            .pipe(
                // tslint:disable-next-line:max-line-length
                debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
                distinctUntilChanged(), // This operator will eliminate duplicate values
                tap((ev: any) => {
                    if (ev.keyCode == 13) this.filter(this.ProductOrCode.nativeElement.value, 'ProductOrCode');
                })
            )
            .subscribe();

        fromEvent(this.OrderOrDocument.nativeElement, 'keyup')
            .pipe(
                // tslint:disable-next-line:max-line-length
                debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
                distinctUntilChanged(), // This operator will eliminate duplicate values
                tap((ev: any) => {
                    if (ev.keyCode == 13) this.filter(this.OrderOrDocument.nativeElement.value, 'OrderOrDocument');
                })
            )
            .subscribe();
    }

    categorie: any = undefined;
    fornitori: any = undefined;
    tagsList: any = undefined;

    async downloadXml(merce) {
        this.loading$.next(true);
        const html: any = await this.magazzinoService.getXml(merce.OriginDocument).toPromise();
        this.pdfService.htmlToPdf(html, merce.OriginDocument).then(() => this.loading$.next(false));
    }

    popupXml: any;
    async showXml(merce: any) {

        this.gestioneMerciService.openXmlHighlightedByFileName(merce.OriginDocument, merce.Name, merce.Code)
            .then(() => console.log)
            .catch((error: any) => {
                this.layoutUtilsService.showActionNotification(this.translate.instant(error.error_code), MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-danger');
            })

    }

    refreshTable() {
        console.log('refreshTable', this.pagination);
        if (this.listaSubscribtions) this.listaSubscribtions.unsubscribe();
        this.loading$.next(true);
        this.filtriService.readOnly$.next(true);
        if ($.fn.dataTable.isDataTable('#table')) {
            $('#table').DataTable().destroy();
        }
        //console.log(this.pagination);
        this.companies = [];
        this.dataReady$.next(false);
        $('#table').DataTable().clear().destroy();

        this.listaSubscribtions = this.magazzinoService.getWarehouse('GetPurchase', this.currentFilterCfg, this.pagination)
            .subscribe((ret: any) => {
                console.log('lista', ret);

                // TODO: Elaborare dati ricevuti
                ret['Quantity'] = 0;
                ret['Total'] = 0;
                ret.Rows.forEach((row: any) => {
                    ret.Quantity += row.Quantity;
                    ret.Total += row.Total;
                });

                if (this.pagination.TotalRows !== ret.TotalRows) {
                    this.pagination.TotalRows = ret.TotalRows;
                }

                this.lista = ret;
                this.listaFiltered = JSON.parse(JSON.stringify(ret));

                // Verifico se ci sono fatture importate
                this.listaFiltered.Rows.forEach((el: any) => {
                    if (el.Tags) {
                        if (el.Tags.find(t => t.IsImported)) {
                            el.IsImported = true;
                        } else {
                            el.IsImported = false;
                        }
                    } else {
                        el.IsImported = false;
                    }
                });

                this.initDataTable();
                this.loading$.next(false);
                this.filtriService.readOnly$.next(false);
                this.dataReady$.next(true);
                this.listaSubscribtions.unsubscribe();
            });
    }

    initDataTable() {
        setTimeout(() => {
            let dtOptions: any = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: true,
                orderCellsTop: true,
                scrollY: 'calc(100vh - 400px)',
                scrollCollapse: true,
                aaSorting: [], // inizialmente nessun ordinamento
                columnDefs: [
                    { targets: [0], width: '70px', visible: this.columnsList[0].enabled }, 	// Data
                    { targets: [1], width: '100px', visible: this.columnsList[1].enabled },	// Punto Vendita
                    { targets: [2], width: '100px', visible: this.columnsList[2].enabled },	// Centro di costo
                    { targets: [3], width: '120px', visible: this.columnsList[3].enabled },	// Fornitore
                    { targets: [4], width: '200px', visible: this.columnsList[4].enabled },	// N Documento
                    { targets: [5], width: '100px', visible: this.columnsList[5].enabled },	// N Ordine
                    { targets: [6], width: '80px', visible: this.columnsList[6].enabled },	// Codice
                    { targets: [7], width: '60px', visible: this.columnsList[7].enabled },	// Categoria
                    { targets: [8], visible: this.columnsList[8].enabled, className: 'max-width-300' },	// Descrizione
                    { targets: [9], width: '60px', visible: this.columnsList[9].enabled },	// Qta
                    { targets: [10], width: '60px', visible: this.columnsList[10].enabled },	// Unità
                    { targets: [11], width: '40px', visible: this.columnsList[11].enabled },	// Prezzo
                    { targets: [12], width: '80px', visible: this.columnsList[12].enabled },	// Totale
                    { targets: [13], width: '80px', visible: this.columnsList[13].enabled },	// Reparto
                    { targets: [14], width: '70px', visible: this.columnsList[14].enabled, orderable: false }	// Actions
                ]
            };
            this.table = $('#table').DataTable(dtOptions);
            this.table.on('page.dt', function () {
                $('.dataTables_scrollBody').animate({
                    scrollTop: 0
                }, 'slow');
            });
            //this.table.columns.adjust().draw();

            setTimeout(() => {
                const width = $('#table .descriptionColumn').width();
                $('#table .descriptionColumnDiv').css('width', `${width}px`);
            }, 100);
        }, 100);
    }

    changePagination(pagination: PageEvent) {
        this.pagination.page = pagination.pageIndex + 1;
        this.pagination.pageSize = pagination.pageSize;
        console.log('changePagination', pagination, this.pagination);
        this.refreshTable();
    }

    sort(event: any, index: number, type: string) {
        console.log('sort', event, index);
        this.pagination.sort = type;

        if (this.columnsList[index].sort && this.columnsList[index].sort === 'ASC') {
            this.columnsList[index].sort = 'DESC';
            this.pagination.sortMode = 'Desc';
        } else {
            this.columnsList[index].sort = 'ASC';
            this.pagination.sortMode = 'Asc';
        }

        console.log('sort => ', 'refreshTable', this.pagination.sort, this.pagination.sortMode);
        this.refreshTable();
    }



    filter(event: any, type: string) {
        console.log('filter', event, type);
        if (!this.currentFilterCfg) this.currentFilterCfg = {};
        switch (type) {
            case 'ProductOrCode':
                this.currentFilterCfg['ProductOrCode'] = this.productStartsWith == 1 ? '#' + event : (this.productStartsWith == 2 ? '#' + event + '#' : event);
                break;
            case 'OrderOrDocument':
                this.currentFilterCfg['OrderOrDocument'] = this.ordStartsWith == 1 ? '#' + event : (this.ordStartsWith == 2 ? '#' + event + '#' : event);
                break;
            case 'CategoryIds':
                this.currentFilterCfg['CategoryIds'] = event;
                break;
            case 'SupplierIds':
                this.currentFilterCfg['SupplierIds'] = event;
                break;
            case 'Tags':
                this.currentFilterCfg['Tags'] = [event];
                break;
            default:
                this.currentFilterCfg = [];
        }
        this.filtriService.performFilter$.next(this.currentFilterCfg);
    }

    openPopup() {
        const newEndpoint = {};
        this.editPopup(newEndpoint);
    }

    editPopup(endpoint: any) {
        const dialogRef = this.dialog.open(InserimentoAcquistiPopupComponent, {
            data: { endpoint },
            width: '600px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            if (res.success && res.body) {
                this.magazzinoService.setCurrentConfig(res);

                // mi salvo la configurazione dei filtri nei cookie.
                console.log('mi salvo la configurazione dei filtri nei cookie.');
                localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));

                this.router.navigate(['/magazzino/inserimento-comune'], {
                    queryParams: {
                        inserimentoType: InserimentoType.ACQUISTI,
                        referral: '/magazzino/acquisti'
                    }
                });
            }

        });
    }

    refreshColumnsVisibility() {
        console.log('refreshColumnsVisibility');
        for (let index = 0; index < this.columnsList.length; index++) {
            var column = this.table.column(index);
            if (column) column.visible(this.columnsList[index].enabled);
        }
    }

    openColumnsDialog() {
        const dialogRef = this.dialog.open(ColumnsSelectionPopupComponent, {
            data: { columnsList: this.columnsList },
            width: '300px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            console.log(res);
            if (res.success && res.columnsList) {
                this.columnsList = res.columnsList;
                this.refreshColumnsVisibility();
            }
        });
    }

    editItem(itemId: any) {

        this.magazzinoService.itemsForUpdate(itemId, 'Purchase')
            .subscribe((result: any) => {
                console.log('itemsForUpdate', result);

                const resultConfig = JSON.parse(JSON.stringify(result));
                const resultsRows: any = JSON.parse(JSON.stringify(result.Rows));
                delete resultConfig.Rows;
                this.magazzinoService.setCurrentConfig({
                    insertType: InserimentoType.ACQUISTI,
                    body: resultConfig,
                    items: resultsRows,
                    prePop: {
                        Items: resultsRows
                    }
                });

                // mi salvo la configurazione dei filtri nei cookie.
                console.log('mi salvo la configurazione dei filtri nei cookie.');
                localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));

                this.router.navigate(['/magazzino/inserimento-comune'], {
                    queryParams: {
                        inserimentoType: InserimentoType.ACQUISTI,
                        referral: '/magazzino/acquisti',
                        itemId: itemId,
                    }
                });

            })
    }

    deleteItem(itemId: any) {

        const _title: string = this.translate.instant('MAGAZZINO.ACQUISTI.DELETE_DIALOG.TITLE');
        const _description: string = this.translate.instant('MAGAZZINO.ACQUISTI.DELETE_DIALOG.DESC_MULTI');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('MAGAZZINO.ACQUISTI.DELETE_DIALOG.BUTTON_SINGLE');
        const _yes2Button = this.translate.instant('MAGAZZINO.ACQUISTI.DELETE_DIALOG.BUTTON_MULTI');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton, _yes2Button);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            console.log(confirm);
            if (confirm === 2) {

                this.magazzinoService.deleteMultiPurchase(itemId).subscribe(
                    (result: any) => {
                        console.log(result);
                        let message: string = '';
                        if (result.Msg === 'Ok') {
                            message = this.translate.instant('MAGAZZINO.ACQUISTI.DELETE_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                            this.refreshTable();
                        } else {
                            message = this.translate.instant('MAGAZZINO.ACQUISTI.DELETE_DIALOG.ERROR');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                        }
                    }
                )

            } else if (confirm) {

                this.magazzinoService.delete(InserimentoType.ACQUISTI, [itemId]).subscribe(
                    (result: any) => {
                        console.log(result);
                        let message: string = '';
                        if (result.Msg === 'Ok') {
                            message = this.translate.instant('MAGAZZINO.ACQUISTI.DELETE_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                            this.refreshTable();
                        } else {
                            message = this.translate.instant('MAGAZZINO.ACQUISTI.DELETE_DIALOG.ERROR');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                        }
                    }
                )

            }
        });

    }

    showFromYearStart() {
        this.currentFilterCfg = { Period: 'YEAR', ShowDisabled: true, ShowInvisible: true };
        this.filtriService.performFilter$.next(this.currentFilterCfg);
        this.ref.detectChanges();
    }

    showToday() {
        const today = new Date();
        const todayStr = today.toLocaleDateString('ja-JP');
        this.currentFilterCfg = { DateFilter: { Start: todayStr, End: todayStr }, ShowDisabled: true, ShowInvisible: true };
        this.filtriService.performFilter$.next(this.currentFilterCfg);
        this.ref.detectChanges();
    }

    getPLTooltip(item) {
        if (item.HasPLEntry && item.PL_SendId) {
            //C'è qualcosa nel contoeconomico
            return this.translate.instant('MAGAZZINO.ACQUISTI.PL.TOOLTIPS.HAS_ENTRY_HAS_SENDID');
        }
        if (item.HasPLEntry && !item.PL_SendId) {
            //C'è qualcosa nel contoeconomico ma non è visualizzabile (Acquisti vecchi)
            return this.translate.instant('MAGAZZINO.ACQUISTI.PL.TOOLTIPS.HAS_ENTRY_NOT_HAS_SENDID');
        }
        if (!item.HasPLEntry && item.IsCategoryMapped) {
            //non c'è nel contoeconomico, ma è inviabile perché la categoria è mappata
            return this.translate.instant('MAGAZZINO.ACQUISTI.PL.TOOLTIPS.NOT_HAS_ENTRY_HAS_SENDID');
        }

        if (!item.HasPLEntry && !item.IsCategoryMapped) {
            //non c'è nel contoeconomico, ma è non è inviabile perché la categoria non è mappata
            // return "Non può essere inviato al conto economico perché la categoria " + item.Category.Name + " non è mappata";
            return this.translate.instant('MAGAZZINO.ACQUISTI.PL.TOOLTIPS.NOT_HAS_ENTRY_NOT_HAS_SENDID', { 'category': item.Category.Name });
        }
    }

    async managePL(item) {
        let description = "";
        let yesButton = "";
        let noButton = '';
        let canSend = false;
        let apiUrl = "";
        let payload: any;
        if (item.HasPLEntry && item.PL_SendId) {
            //C'è qualcosa nel contoeconomico
            this.dialog
                .open(ContoEconomicoDetailsComponent, {
                    data: {
                        item: item,
                        currentFilterCfg: this.currentFilterCfg,
                        locale: this.locale,
                        SendingId: item.PL_SendId
                    },
                    width: '1200px'
                });

            return;
        }
        else if (item.HasPLEntry && !item.PL_SendId) {
            //C'è qualcosa nel contoeconomico ma non è visualizzabile (Acquisti vecchi)
            description = this.translate.instant('MAGAZZINO.ACQUISTI.PL.TOOLTIPS.HAS_ENTRY_NOT_HAS_SENDID');
            yesButton = this.translate.instant('MAGAZZINO.ACQUISTI.PL.SENDTOPL_DIALOG.UNDERSTOOD');
        }
        else if (!item.HasPLEntry && item.IsCategoryMapped) {
            //non c'è nel contoeconomico, ma è inviabile perché la categoria è mappata
            description = this.translate.instant('MAGAZZINO.ACQUISTI.PL.TOOLTIPS.NOT_HAS_ENTRY_HAS_SENDID');
            yesButton = this.translate.instant('MAGAZZINO.ACQUISTI.PL.SENDTOPL_DIALOG.CONFIRM');
            noButton = this.translate.instant('COMMONS.CANCEL');
            canSend = true;
            payload = [item.Id];
        }

        else if (!item.HasPLEntry && !item.IsCategoryMapped) {
            //non c'è nel contoeconomico, ma è non è inviabile perché la categoria non è mappata
            description = this.translate.instant('MAGAZZINO.ACQUISTI.PL.TOOLTIPS.NOT_HAS_ENTRY_NOT_HAS_SENDID', { 'category': item.Category.Name });
            yesButton = this.translate.instant('MAGAZZINO.ACQUISTI.PL.SENDTOPL_DIALOG.UNDERSTOOD');
        }

        const _title = this.translate.instant('MAGAZZINO.ACQUISTI.PL.SENDTOPL_DIALOG.TITLE');
        const _description = description;
        const _yesButton = yesButton
        const _noButton = noButton;

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, '', _yesButton, _noButton);
        if (canSend) {
            dialogRef.afterClosed().subscribe((confirm: any) => {
                if (confirm) {
                    this.contoEconomicoService.SendFromPurchase(payload).toPromise()
                        .then(() => {
                            this.layoutUtilsService.showActionNotification(this.translate.instant('MAGAZZINO.ACQUISTI.PL.SENDTOPL_DIALOG.SUCCESS'), MessageType.Create, 3000);
                            this.refreshTable();
                        })
                        .catch(() => {
                            this.layoutUtilsService.showActionNotification(this.translate.instant('MAGAZZINO.ACQUISTI.PL.SENDTOPL_DIALOG.ERROR'), MessageType.Error, 3000);
                        })
                }
            });
        }
    }

    /****************************************************************************
            ______                         _
           |  ____|                       | |
           | |__ ___  _ __ _ __ ___   __ _| |_
           |  __/ _ \| '__| '_ ` _ \ / _` | __|
           | | | (_) | |  | | | | | | (_| | |_
           |_|  \___/|_|  |_| |_| |_|\__,_|\__|
  
    ****************************************************************************/
    formatNumber(x: any, decimal: number = 2, showZero: boolean = true, currency: boolean = false) {
        return this.utility.formatNumber(x, this.locale, decimal, showZero, currency);
    }

    /****************************************************************************
      ______                       _
     |  ____|                     | |
     | |__  __  ___ __   ___  _ __| |_
     |  __| \ \/ / '_ \ / _ \| '__| __|
     | |____ >  <| |_) | (_) | |  | |_
     |______/_/\_\ .__/ \___/|_|   \__|
                 | |
                 |_|
     ****************************************************************************/

    exportAsXLSX() {



        let pagination = JSON.parse(JSON.stringify(this.pagination));
        if (this.pagination.TotalRows) {

            if (pagination.TotalRows < environment.ExcelPaginationMaxSize)
                pagination.pageSize = pagination.TotalRows;
            else {
                pagination.pageSize = environment.ExcelPaginationMaxSize;
                const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
                let _description: string = this.translate.instant('EXPORT_XLSX.MAX5000');
                _description = _description.replace('{0}', '' + environment.ExcelPaginationMaxSize);
                const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');

                const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');


                const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton);
                dialogRef.afterClosed().subscribe(expand => {

                });
            }

        }
        else {
            const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
            const _description: string = this.translate.instant('EXPORT_XLSX.NO_ROWS');
            const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');

            const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');


            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton);
            dialogRef.afterClosed().subscribe(expand => {
                return;
            });
            return;
        }



        this.loading$.next(true);
        let xlsx: any[] = [];
        let merges: any[] = [];
        let listaSubscribtions = this.magazzinoService.getWarehouse('GetPurchase', this.currentFilterCfg, pagination)
            .subscribe((ret: any) => {
                ret['Quantity'] = 0;
                ret['Total'] = 0;
                ret.Rows.forEach((row: any) => {
                    ret.Quantity += row.Quantity;
                    ret.Total += row.Total;
                });

                if (this.pagination.TotalRows !== ret.TotalRows) {
                    this.pagination.TotalRows = ret.TotalRows;
                }


                let printList = JSON.parse(JSON.stringify(ret));



                // Totali
                xlsx.push({
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DATE')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COMPANY')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COSTCENTERS')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.SUPPLIER')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DOC_NUMBER')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.ORDER_NUMBER')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CODE')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CATEGORY')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NAME')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.QUANTITY')}`]: printList.Quantity ? this.utility.formatNumberExcel(printList.Quantity, 2) : '',
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.UNIT')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.PRICE')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.TOTAL')}`]: printList.Total ? this.utility.formatNumberExcel(printList.Total, 2) : '',
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DEPARTMENT')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NOTE')}`]: ''
                });

                printList.Rows.forEach((item: any) => {
                    //this.listaFiltered.Rows.forEach((item: any) => {

                    xlsx.push({
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DATE')}`]: item.Date ? (new Date(item.Date)).toLocaleDateString('it-IT') : '',
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COMPANY')}`]: item.Company ? item.Company.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COSTCENTERS')}`]: item.CostCenter ? item.CostCenter.Name.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.SUPPLIER')}`]: item.Supplier ? item.Supplier.Name : '',
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DOC_NUMBER')}`]: item.DocNumber ? item.DocNumber : '',
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.ORDER_NUMBER')}`]: item.Order ? item.Order : '',
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CODE')}`]: item.Code ? item.Code : '',
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CATEGORY')}`]: item.Category ? item.Category.Name.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NAME')}`]: item.Goods.Name ? item.Goods.Name : '',
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.QUANTITY')}`]: item.Quantity ? this.utility.formatNumberExcel(item.Quantity, 2) : '',
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.UNIT')}`]: item.Unit ? item.Unit : '',
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.PRICE')}`]: item.Price ? this.utility.formatNumberExcel(item.Price, 2) : '',
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.TOTAL')}`]: item.Total ? this.utility.formatNumberExcel(item.Total, 2) : '',
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DEPARTMENT')}`]: item.Department ? item.Department.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NOTE')}`]: item.Note ? item.Note : ''
                    });
                });
                this.loading$.next(false);
                this.excelService.exportAsExcelFile(xlsx, 'purchase', merges, this.columnsList, 2, [], true);

                listaSubscribtions.unsubscribe();
            });


    }

    exportAsPDF() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.getTitle() }),
                    //period: ''
                }
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                }
                result['language'] = this.translationService.getSelectedLanguage();
                result['table'].headerRows = 1;
                let widths: any = [];
                this.columnsList.filter((item: any) => item.enabled).forEach((element: any) => {
                    widths.push('auto')
                });
                widths[widths.length - 1] = '*';

                this.pdfService.makePdf(result, this.getPDFTableBody(), widths);
            };
        });

    }

    getPDFTableBody() {
        let body: any = [];
        let tmpRow: any = [];

        // aggiungo intestazione
        // totali Header

        ''

        tmpRow = [
            { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DATE')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[1].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COMPANY')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[2].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COSTCENTERS')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[3].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.SUPPLIER')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[4].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DOC_NUMBER')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[5].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.ORDER_NUMBER')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[6].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CODE')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[7].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CATEGORY')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[8].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NAME')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[9].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.QUANTITY')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[10].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.UNIT')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[11].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.PRICE')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[12].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.TOTAL')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[13].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DEPARTMENT')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[14].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NOTE')}`, style: 'tableHeaderStyle' }
        ];

        body.push(tmpRow.filter((item: any) => item.visible));

        tmpRow = [
            { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[1].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[2].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[3].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[4].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[5].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[6].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[7].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[8].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[9].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.listaFiltered.Quantity ? this.utility.formatNumber(this.listaFiltered.Quantity) : ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[10].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[11].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[12].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.listaFiltered.Total ? this.utility.formatNumber(this.listaFiltered.Total) : ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[13].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[14].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' }
        ];
        body.push(tmpRow.filter((item: any) => item.visible));

        let i: number = 0;
        let sortedList = this.utility.sortList(this.listaFiltered.Rows, 'table');
        sortedList.forEach((item: any) => {
            //this.listaFiltered.Rows.forEach((item: any) => {
            tmpRow = [
                { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Date ? (new Date(item.Date)).toLocaleDateString('it-IT') : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[1].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Company ? item.Company.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[2].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.CostCenter ? item.CostCenter.Name.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[3].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Supplier ? item.Supplier.Name : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[4].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.DocNumber ? item.DocNumber : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[5].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Order ? item.Order : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[6].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Code ? item.Code : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[7].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Category ? item.Category.Name.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[8].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Goods ? item.Goods.Name.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[9].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Quantity ? this.utility.formatNumber(item.Quantity, 0) : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[10].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Unit ? item.Unit : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[11].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Price ? this.utility.formatNumber(item.Price) : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[12].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Total ? this.utility.formatNumber(item.Total) : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[13].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Department ? item.Department.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[14].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Note ? item.Note : ' ', style: 'tableBodyStyle' }
            ];
            body.push(tmpRow.filter((item: any) => item.visible));
            i++;
        });

        return body;
    }


    goToStatistiche() {
        this.filtriService.filterConfig['Grouping'] = 'Supplier';
        this.router.navigate(['/magazzino/statistiche'], { queryParams: { entity: 'Purchase', localized: 'MAGAZZINO.STATISTICHE.WAREHOUSE_SELECTOR.ACQUISTI' } });
    }

    getTags(tags: any) {
        return tags && tags.length > 0 ? tags.map(t => t.Name).toString() : '';
    }

    /**
     * 
     * @param event 
     */

    importAcquisti(event: any) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let file: File = fileList[0];
            let formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            this.loading$.next(true);
            this.magazzinoService.importAcquisti(formData).toPromise()
                .then((result) => {
                    let message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTAZIONE_OK');
                    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                    event.target.value = ''
                })
                .catch((error) => {
                    let message = this.translate.instant('MAGAZZINO.ACQUISTI.IMPORTAZIONE_FAIL');
                    this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                    event.target.value = ''
                })
                .finally(() => this.loading$.next(false));
        }
    }

    showPriceGraph(item: any) {
        console.log('showPriceGraph', item);
        const dialogRef = this.dialog.open(AnalisiPrezziFornitoriDialogComponent, {
            data: {
                ProductId: item.Goods.Id
            },
            width: '100%',
            height: '800px'
        });
    }

    showImportedPurchases() {
        const dialogRef = this.dialog.open(ImportedPurchasesComponent, {
            data: {},
            width: '100%',
            height: '100%'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            console.log(res);
            if (_.isEmpty(res) || !res) {
                return;
            }
            if (res.success && res.body) {

            }
        });
    }
}
