import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'kt-edit-tags',
  templateUrl: './edit-tags.component.html',
  styleUrls: ['./edit-tags.component.scss']
})
export class EditTagsComponent implements OnInit {

//  Tags: any = [];
  tagsList: any;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private anagraficheService: AnagraficheService
  ) { }

  async ngOnInit() {
    console.log('this.data', this.data);

    this.tagsList = await this.anagraficheService.getEntity('Tags').toPromise();
    console.log('tagsList', this.tagsList);

    (<any>$('#select2Tags')).select2({
      placeholder: this.translate.instant('MAGAZZINO.TAGS.PLACEHOLDER'),
      allowClear: true,
      closeOnSelect: false,
      multiple: true,
      width: '300px',
      containerCssClass: 'select2Multiple',
      language: {
          errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
          inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
          inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
          loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
          maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
          noResults: () => this.translate.instant('SELECT2.noResults'),
          searching: () => this.translate.instant('SELECT2.searching')
      }
    });
    $('#select2Tags').on('select2:select', (e: any) => {
        if(!this.data.item.Tags) {
            this.data.item.Tags = [];
        }
        this.data.item.Tags.push({
        Id: e.params.data.id,
        Name: e.params.data.text
      });
    });
    $('#select2Tags').on('select2:clear', (e: any) => {
        this.data.item.Tags = [];
    });
    $('#select2Tags').on('select2:unselect', (e: any) => {
        this.data.item.Tags = this.data.item.Tags.filter((t: any) => t.Id != e.params.data.id);
    });

    if (this.data && this.data.item && this.data.item.Tags && this.data.item.Tags.length > 0) {
        //this.Tags = this.data.item.Tags.map(t => { return {t.Id} } );
        setTimeout(() => {
          $('#select2Tags').val(this.data.item.Tags.map(t => { return t.Id } )).trigger('change');
        }, 250);
      }

  }

  onSubmit() {
    this.dialogRef.close({
      success: true,
      Tags: this.data.item.Tags
    });
  }

}
