import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API } from '@env/environment';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

@Injectable({
	providedIn: 'root'
})
export class UsersService {

	constructor(
		private http: HttpClient,
		private switchGroupService: SwitchGroupService
	) { }

	getHeader(){
		const currentLogin = this.switchGroupService.getCurrentGroup();

		const token = currentLogin? currentLogin.access_token: '';
		const url =  currentLogin? currentLogin.endpointURL : '';
		const httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' +token
		});

		return  {url: url, header: httpHeaders};
	}

	getUsers(): Observable<any> {
		const httpHeaders = this.getHeader();

		return this.http.get<any>(`${httpHeaders.url}/api/Admin/UsersFull`, { headers: httpHeaders.header });
	}

	getRoles(): Observable<any> {
		const httpHeaders = this.getHeader();

		return this.http.get<any>(`${httpHeaders.url}/api/Admin/Roles`, { headers: httpHeaders.header });
	}

	getStores(): Observable<any> {
		const httpHeaders = this.getHeader();
		return this.http.get<any>(`${httpHeaders.url}/api/Admin/Stores`, { headers: httpHeaders.header });
	}

	getPermissions(): Observable<any> {
		const httpHeaders = this.getHeader();
		return this.http.get<any>(`${httpHeaders.url}/api/Account/UsersPermissions`, { headers: httpHeaders.header });
	}

	postUser( entityBody: any): Observable<any> {
		const httpHeaders = this.getHeader();
		return this.http.post<any>(`${httpHeaders.url}/api/Admin/UserDetails`, entityBody, { headers: httpHeaders.header });
	}

	getUserSettings(): Observable<any> {
		const httpHeaders = this.getHeader();
		return this.http.get<any>(`${httpHeaders.url}/api/Account/UsersSettings`, { headers: httpHeaders.header });
	}

    getStoreConfiguration(): Observable<any> {
		const httpHeaders = this.getHeader();
		return this.http.get<any>(`${httpHeaders.url}/api/Account/StoreConfigurations`, { headers: httpHeaders.header });
	}
}
