import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, combineLatest } from 'rxjs';
import { map, tap, catchError, filter, take, switchMap, finalize } from 'rxjs/operators';
import { RoleService } from './role.service';
import { environment } from '../../../../../environments/environment';
import { MenuConfigService } from '@app/core/_base/layout/services/menu-config.service';

@Injectable({
    providedIn: 'root'
})
export class SwitchGroupService {

    //TODO da settare a 0
    private _currentIndexGroup: number = 0;
    performSwitch: BehaviorSubject<any> = new BehaviorSubject<any>({ change: false });
    private propagationControllerObsv: BehaviorSubject<any> = new BehaviorSubject<any>({ change: false });
    public performSwitchObsv$ = this.propagationControllerObsv.asObservable();

    private oldIndex!: number;

    constructor(
        private roleService: RoleService,
        private menuConfigService: MenuConfigService
    ) {

        this.performSwitch
            .subscribe(
                (event: any) => {
                    if (event.change && event.index !== this.oldIndex) {
                        if (this.menuConfigService.onEndpointUpdated$) this.menuConfigService.onEndpointUpdated$.next(true);

                        this.oldIndex = event.index;
                        this.propagationControllerObsv.next({ change: true, index: event.index });
                        setTimeout(() => {
                            this.propagationControllerObsv.next({ change: false });
                        }, 5000);
                    } else if (event.change) {
                        this.propagationControllerObsv.next({ change: false });
                    }
                }
            );

    }

    setCurrentGroup(index: number) {
        this._currentIndexGroup = index;
        localStorage.setItem('currentIndexGroup', index.toString());
    }

    getCurrentEmail(): string {
        let email: string = '';
        try {
            const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
            email = currentUser.email;
        } catch (e) {
            console.log(e);
        } finally {
            return email;
        }
    }

    getCurrentGroup(): any {
        let currentGroup: any = {};
        try {
            const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');

            const savedIndexGroup = localStorage.getItem('currentIndexGroup');
            if (savedIndexGroup) {
                this._currentIndexGroup = parseInt(savedIndexGroup);
            }

            currentGroup = currentUser.Logins[this._currentIndexGroup];

            if (!currentGroup) return undefined;

            localStorage.setItem('currentDB', JSON.stringify({
                Roles: currentGroup.Roles,
                description: currentGroup.description,
                groupId: currentGroup.groupId,
                group: currentGroup.group
            }));

            this.roleService.checkWritePermission(currentGroup.Roles[0]);

        } catch (e) {
            console.log(e);
        } finally {
            return currentGroup;
        }
    }

    getGroupByEndPoint(endpointURL: string): any {
        let currentGroup: any = {};
        try {
            const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');

            currentGroup = currentUser.Logins.find((x: any) => x.endpointURL === endpointURL);

            if (!currentGroup) return undefined;

            localStorage.setItem('currentDB', JSON.stringify({
                Roles: currentGroup.Roles,
                description: currentGroup.description,
                groupId: currentGroup.groupId,
                group: currentGroup.group
            }));

            this.roleService.checkWritePermission(currentGroup.Roles[0]);

        } catch (e) {
            console.log(e);
        } finally {
            return currentGroup;
        }
    }

    getCurrentGroupIndex(): number {
        return this._currentIndexGroup;
    }

    getGroupsList(): any[] {
        let currentUser: any;
        try {
            currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
        } catch (e) {

        } finally {
            let groups = currentUser.Logins;
            if (environment.UseTestEndpoint) {
                //USA SOLO DATABASE DI TEST 
                groups = groups.filter((x: any) => x.endpointURL === environment.TestEndpoint)
            }
            else {
                if (groups && groups.length > 0 && !groups[0].Roles.includes('SuperAdmin')) {
                    if (!(groups.length === 1 && groups[0].endpointURL !== environment.TestEndpoint)) {
                        //NON VEDE DATABASE DI TEST
                        groups = groups.filter((x: any) => x.endpointURL !== environment.TestEndpoint)
                    }
                }
                // groups = groups.filter((x: any) => x.endpointURL !== environment.TestEndpoint);
            }
            return groups;
        }
    }

    getIntegrations(): string {
        let integrations: any;
        try {
            const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
            integrations = currentUser.integrations;
        } catch (e) {
            console.log(e);
        } finally {
            return integrations;
        }
    }

    canSwitch(): boolean {
        let canSwitch: boolean = false;
        try {
            const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
            canSwitch = (currentUser.Logins.length > 1);
        } catch (e) {
            console.log(e);
        } finally {
            return canSwitch;
        }
    }

    removeFromGroupList(group: any) {
        let currentUser: any;
        try {
            currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
        } catch (e) {

        }
    }

    setFirstAvailableGroup() {
        const list = this.getGroupsList();
        const firstAvailable = list.findIndex(endpoint => endpoint.access_token);
        this.setCurrentGroup(firstAvailable);
    }

    setGroupIntegrations(endpointURL: string, integrations: any) {
        let currentUser: any;
        try {
            currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
            currentUser.Logins.forEach((element: any) => {
                if (element.endpointURL === endpointURL) {
                    element.integrations = integrations;
                }
            });
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
        } catch (e) {
            console.log(e);
        }
    }
}
