import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'kt-dnd-upload',
    templateUrl: './dnd-upload.component.html',
    styleUrls: ['./dnd-upload.component.scss']
})
export class DndUploadComponent implements OnInit {

    @Output() onMaxFileSizeExceeded = new EventEmitter<boolean>();

    constructor(
        private ref: ChangeDetectorRef,
        private translate: TranslateService
    ) { }

    ngOnInit() {
    }

    files: any[] = [];

    filesUI$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

    /**
     * on file drop handler
     */
    onFileDropped($event: any) {
        this.prepareFilesList($event);
    }

    isImage(file: File) {
        return file.type.indexOf('image') >= 0;
    }

    isVideo(file: File) {
        return file.type.indexOf('video') >= 0;
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler($event: any) {
        let files: any = $event.target.files || undefined;

        if (!files) return;

        const _2MB = 2048 * 1000 * 1000;

        if (files instanceof FileList) {
            files = Array.from(files);
            files = files.filter((file: File) => file.type.includes('image/') || file.type.includes('video/mp4'));

            let messages: string = "";
            files.forEach((file: File) => {
                const _FileSize = file.size * 1000;
                if (_FileSize > _2MB) {
                    messages += file.name + ': ' + this.translate.instant('DND_UPLOAD.MAX_FILE_SIZE_EXCEEDED') + "\n";
                }
                if(file.type.includes('application')) {
                    messages += file.name + ': ' + this.translate.instant('DND_UPLOAD.FILE_CODING_NOT_SUPPORTED') + "\n";
                }
            })

            if(messages.length > 0) {
                alert(messages)
            }

            files = files.filter((file: File) => file.size * 1000 <= _2MB);

            if (files.length == 0) return;
        } else {
            return;
        }

        this.prepareFilesList(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        this.files.splice(index, 1);
    }

    /**
     * Simulate the upload process
     */
    uploadFilesSimulator(index: number) {
        setTimeout(() => {
            if (index === this.files.length) {
                return;
            } else {
                const progressInterval = setInterval(() => {
                    if (!this.files[index]) {
                        clearInterval(progressInterval);
                        return;
                    }
                    if (this.files[index].progress === 100) {
                        clearInterval(progressInterval);
                        this.uploadFilesSimulator(index + 1);
                    } else {
                        this.files[index].progress += 5;
                    }
                    this.ref.detectChanges();
                }, 10);
            }
        }, 500);
    }

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    async prepareFilesList(files: Array<any>) {
        for await (const item of files) {
            item.progress = 0;
            item.base64 = await blobToData(item);
            this.files.push(item);
        }

        this.filesUI$.next(this.files);
        this.uploadFilesSimulator(0);
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes: any, decimals: any) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    clear() {
        this.files = [];
    }
}

export const blobToData = (blob: Blob) => {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
    })
}