// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
//import { UserManagementModule } from './user-management/user-management.module';

import { AutosizeModule } from 'ngx-autosize';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';

import { DragDropModule } from '@angular/cdk/drag-drop';


import { AnalisiVenditeComponent } from './analisi/analisi-vendite/analisi-vendite.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule, IConfig } from 'ngx-mask'
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {}

import { EndpointsComponent } from './endpoints/endpoints.component';
import { EndpointsEditComponent } from './endpoints/endpoints-edit/endpoints-edit.component';
import { UsersEditComponent } from './endpoints/users-edit/users-edit.component';
import { AnalisiFoodcostComponent } from './analisi/analisi-foodcost/analisi-foodcost.component';
import { GestioneMerciComponent } from './gestione-dati/gestione-merci/gestione-merci.component';
import { UnitComponent } from './anagrafiche/unit/unit.component';
import { OrderUnitComponent } from './anagrafiche/order-unit/order-unit.component';
import { RepartiComponent } from './anagrafiche/reparti/reparti.component';
import { RazioniComponent } from './anagrafiche/razioni/razioni.component';
import { FornitoriComponent } from './anagrafiche/fornitori/fornitori.component';
import { CategorieMerciComponent } from './anagrafiche/categorie-merci/categorie-merci.component';
import { CategorieProdottiComponent } from './anagrafiche/categorie-prodotti/categorie-prodotti.component';
import { BrandsComponent } from './anagrafiche/brands/brands.component';
import { CostCentersComponent } from './anagrafiche/cost-centers/costcenters.component';
import { WastetypesComponent } from './anagrafiche/wastetypes/wastetypes.component';
import { CategorieMerciEditComponent } from './anagrafiche/categorie-merci/categorie-merci-edit/categorie-merci-edit.component';
import { BrandsEditComponent } from './anagrafiche/brands/brands-edit/brands-edit.component';
import { CostCentersEditComponent } from './anagrafiche/cost-centers/costcenters-edit/costcenters-edit.component';
import { WastetypesEditComponent } from './anagrafiche/wastetypes/wastetypes-edit/wastetypes-edit.component';
import { CategorieProdottiEditComponent } from './anagrafiche/categorie-prodotti/categorie-prodotti-edit/categorie-prodotti-edit.component';
import { FornitoriEditComponent } from './anagrafiche/fornitori/fornitori-edit/fornitori-edit.component';
import { OrderUnitEditComponent } from './anagrafiche/order-unit/order-unit-edit/order-unit-edit.component';
import { RazioniEditComponent } from './anagrafiche/razioni/razioni-edit/razioni-edit.component';
import { RepartiEditComponent } from './anagrafiche/reparti/reparti-edit/reparti-edit.component';
import { UnitEditComponent } from './anagrafiche/unit/unit-edit/unit-edit.component';
import { GruppiComponent } from './anagrafiche/gruppi/gruppi.component';
import { GruppiEditComponent } from './anagrafiche/gruppi/gruppi-edit/gruppi-edit.component';
import { AcquistiComponent } from './magazzino/acquisti/acquisti.component';
import { TrasferimentiComponent } from './magazzino/trasferimenti/trasferimenti.component';
import { SprechiComponent } from './magazzino/sprechi/sprechi.component';
import { InventariComponent } from './magazzino/inventari/inventari.component';
import { GiacenzeComponent } from './magazzino/giacenze/giacenze.component';
import { StorageMainGraphComponent } from './magazzino/statistiche/storagemaingraph/storagemaingraph.component';
import { TopItemsComponent } from './magazzino/statistiche/top-items/top-items.component';

import { FiltroPopupComponent } from './gestione-dati/vendite-manuali/filtro-popup/filtro-popup.component';

/* Popup inserimento */
import { InserimentoAcquistiPopupComponent } from './magazzino/acquisti/inserimento-acquisti-popup/inserimento-acquisti-popup.component';
import { InserimentoInventariPopupComponent } from './magazzino/inventari/inserimento-inventari-popup/inserimento-inventari-popup.component';
import { InserimentoRettifichePopupComponent } from './magazzino/sprechi/inserimento-rettifiche-popup/inserimento-rettifiche-popup.component';
import { InserimentoTrasferimentiPopupComponent } from './magazzino/trasferimenti/inserimento-trasferimenti-popup/inserimento-trasferimenti-popup.component';
import { InserimentoGiacenzePopupComponent } from './magazzino/giacenze/inserimento-giacenze-popup/inserimento-giacenze-popup.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MyMatPaginatorIntl } from '@app/core/services/my-mat-paginator-intl.service';

import { InserimentoComuneComponent } from '@app/views/pages/magazzino/inserimento-comune/inserimento-comune.component';
/* ORDINI */
import { OrdiniComponent } from './magazzino/ordini/ordini.component';
import { InserimentoOrdiniPopupComponent } from './magazzino/ordini/inserimento-ordini-popup/inserimento-ordini-popup.component';
import { InserimentoOrdiniComponent } from './magazzino/ordini/inserimento-ordini/inserimento-ordini.component';
import { EditNotesComponent } from './magazzino/components/edit-notes/edit-notes.component';
import { SendMailComponent } from './magazzino/ordini/send-mail/send-mail.component';

/* ORDINI-INTERNI */
import { OrdiniInterniComponent } from './magazzino/ordini-interni/ordini-interni.component';
import { InserimentoOrdiniInterniPopupComponent } from './magazzino/ordini-interni/inserimento-ordini-popup/inserimento-ordini-popup.component';
import { InserimentoOrdiniInterniComponent } from './magazzino/ordini-interni/inserimento-ordini/inserimento-ordini.component';

/* ORDINI-SEMPLIFICATI */
import { OrdiniSemplificatiComponent } from './magazzino/ordini-semplificati/ordini-semplificati.component';
import { InserimentoOrdiniSemplificatiPopupComponent } from './magazzino/ordini-semplificati/inserimento-ordini-popup/inserimento-ordini-popup.component';
import { InserimentoOrdiniSemplificatiComponent } from './magazzino/ordini-semplificati/inserimento-ordini/inserimento-ordini.component';

/**/
import { AnalisiConsumiComponent } from './analisi/analisi-consumi/analisi-consumi.component';
import { ConsumiComponent } from './analisi/analisi-consumi/consumi/consumi.component';
import { ConsumiDettagliatiComponent } from './analisi/analisi-consumi/consumi-dettagliati/consumi-dettagliati.component';
import { StoricoVenditeComponent } from './analisi/storico-vendite/storico-vendite.component';
import { CompanyUsersComponent } from './configurazione/company-users/company-users.component';

import { ImportexportComponent } from '@app/views/pages/manutenzione/importexport/importexport.component';
import { ManutenzioneService } from '@app/views/pages/manutenzione/manutenzione.service';
import { PopupDettaglioComponent } from './analisi/analisi-consumi/consumi/popup-dettaglio/popup-dettaglio.component';

import { ScreenSizeService } from '@app/core/services/screen-size.service';
import { GestioneRicetteComponent } from './gestione-dati/gestione-ricette/gestione-ricette.component';
import { EditRicettaComponent } from './gestione-dati/gestione-ricette/edit-ricetta/edit-ricetta.component';
import { InsertNotesComponent } from './gestione-dati/gestione-ricette/edit-ricetta/insert-notes/insert-notes.component';

import {
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { SostituisciIngredienteComponent } from './gestione-dati/gestione-ricette/sostituisci-ingrediente/sostituisci-ingrediente.component';
import { StoresComponent } from './configurazione/stores-component/stores-component';
import { EndpointSettingsComponent } from './configurazione/endpoint-settings-component/endpoint-settings-component';
import { ForzaDateInizioComponent } from './gestione-dati/gestione-ricette/edit-ricetta/forza-date-inizio/forza-date-inizio.component';
import { EditMerciComponent } from './gestione-dati/gestione-merci/edit-merci/edit-merci.component';
import { VisibilitaMerciComponent } from './gestione-dati/gestione-merci/visibilita-merci/visibilita-merci.component';


import { VenditeManualiComponent } from './gestione-dati/vendite-manuali/vendite-manuali.component';

import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { SendDdtComponent } from './magazzino/ordini-interni/send-ddt/send-ddt.component';
import { SendInnerMailComponent } from './magazzino/ordini-interni/send-inner-mail/send-inner-mail.component';
import { StatisticheComponent } from './magazzino/statistiche/statistiche.component';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RouterModule } from '@angular/router';
import { StoricoVenditeEditComponent } from './analisi/storico-vendite/storico-vendite-edit/storico-vendite-edit.component';
import { ImportIngredientsComponent } from './gestione-dati/gestione-ricette/edit-ricetta/import-ingredients/import-ingredients.component';
import { BloccoInventariComponent } from './magazzino/inventari/blocco-inventari/blocco-inventari.component';
import { SupportComponent } from './support/support.component';
import { EditIssueComponent } from './support/edit-issue/edit-issue.component';
import { CercaMerceComponent } from './magazzino/inserimento-comune/cerca-merce/cerca-merce.component';
import { SetTypeDlgComponent } from './gestione-dati/gestione-ricette/set-type-dlg/set-type-dlg.component';
import { SetCategoryDlgComponent } from './gestione-dati/gestione-ricette/set-category-dlg/set-category-dlg.component';
import { SelectVariationDialogComponent } from './gestione-dati/gestione-merci/edit-merci/select-variation-dialog/select-variation-dialog.component';
import { SetVATDlgComponent } from './gestione-dati/gestione-merci/set-vatdlg/set-vatdlg.component';
import { AnalisiPrezziFornitoriComponent } from './analisi/analisi-prezzi-fornitori/analisi-prezzi-fornitori.component';
import { EditTagsComponent } from './magazzino/components/edit-tags/edit-tags.component';

import { MBComponentsModule } from '@app/mb-components/mb-components.module';
import { MergeGoodsComponent } from './gestione-dati/gestione-merci/merge-goods/merge-goods.component';
import { SetSupplierComponent } from './gestione-dati/gestione-merci/set-supplier/set-supplier.component';
import { SelectIntervalComponent } from './endpoints/select-interval/select-interval.component';
import { AddBrandsDlgComponent } from './gestione-dati/gestione-ricette/add-brands-dlg/add-brands-dlg.component';
import { TasksComponent } from './manutenzione/tasks/tasks.component';
import { GruppiMerciComponent } from './anagrafiche/gruppi-merci/gruppi-merci.component';
import { GruppiMerciEditComponent } from './anagrafiche/gruppi-merci/gruppi-merci-edit/gruppi-merci-edit.component';
import { GestioneFattureComponent } from './gestione-dati/gestione-fatture/gestione-fatture.component';

import { LocalizedDateTimePipe } from '@app/core/pipes/localized-date-time.pipe';

import { ContoEconomicoComponent } from './amministrazione/conto-economico/conto-economico/conto-economico.component';
import { ContoEconomicoEditComponent } from './amministrazione/conto-economico/conto-economico-edit/conto-economico-edit.component';
import { ContoEconomicoDetailsComponent } from './amministrazione/conto-economico/conto-economico-details/conto-economico-details.component';
import { DashboardComponent } from './amministrazione/conto-economico/dashboard/dashboard.component';
import { IncidenzaRicaviCostiComponent } from './amministrazione/conto-economico/dashboard/incidenza-ricavi-costi/incidenza-ricavi-costi.component';
import { MacroCategoriePeriodoComponent } from './amministrazione/conto-economico/dashboard/macro-categorie-periodo/macro-categorie-periodo.component';
import { SviluppoRicaviComponent } from './amministrazione/conto-economico/dashboard/sviluppo-ricavi/sviluppo-ricavi.component';
import { IndicatoriPerformanceComponent } from './amministrazione/conto-economico/dashboard/indicatori-performance/indicatori-performance.component';
import { RecipesFromGoodsComponent } from './gestione-dati/gestione-merci/recipes-from-goods/recipes-from-goods.component';

import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CompaniesDistributionComponent } from './gestione-dati/gestione-fatture/companies-distribution/companies-distribution.component';
import { ConsumiSemplificatiComponent } from './analisi/analisi-consumi/consumi-semplificati/consumi-semplificati.component';
import { ManutenzioneMerciComponent } from './gestione-dati/gestione-merci/manutenzione-merci/manutenzione-merci.component';
import { ManutenzioneRicetteComponent } from './gestione-dati/gestione-ricette/manutenzione-ricette/manutenzione-ricette.component';
import { DdtComponent } from './magazzino/ddt/ddt.component';
import { DdtEditComponent } from './magazzino/ddt/ddt-edit/ddt-edit.component';
import { LinkDDTComponent } from './gestione-dati/gestione-fatture/link-ddt/link-ddt.component';
import { WidgetModule } from '../partials/content/widgets/widget.module';
import { SetDepartmentDlgComponent } from './gestione-dati/gestione-ricette/set-department-dlg/set-department-dlg.component';
import { StandardViewComponent } from './amministrazione/conto-economico/conto-economico/standard-view/standard-view.component';
import { CompaniesViewComponent } from './amministrazione/conto-economico/conto-economico/companies-view/companies-view.component';
import { MonthlyViewComponent } from './amministrazione/conto-economico/conto-economico/monthly-view/monthly-view.component';
import { PlDetailComponent } from './gestione-dati/gestione-fatture/pl-detail/pl-detail.component';
import { CategoryGraphComponent } from './amministrazione/conto-economico/category-graph/category-graph.component';
import { EstrazioneDatiComponent } from './analisi/estrazione-dati/estrazione-dati.component';
import { FoodCostDetailComponent } from './analisi/analisi-foodcost/food-cost-detail/food-cost-detail.component';
import { AnalisiVariazionePrezziComponent } from './analisi/analisi-variazione-prezzi/analisi-variazione-prezzi.component';
import { MbDialogsModule } from '@app/mb-dialogs/mb-dialogs.module';
import { ScadenziarioComponent } from './amministrazione/scadenziario/scadenziario.component';
import { ScadenziarioEditComponent } from './amministrazione/scadenziario/scadenziario-edit/scadenziario-edit.component';
import { ScadenziarioTabellaComponent } from './amministrazione/scadenziario/scadenziario-tabella/scadenziario-tabella.component';
import { ScadenziarioGraficoComponent } from './amministrazione/scadenziario/scadenziario-grafico/scadenziario-grafico.component';
import { ScadenziarioPayComponent } from './amministrazione/scadenziario/scadenziario-pay/scadenziario-pay.component';
import { BloccoUnitaComponent } from './gestione-dati/gestione-merci/blocco-unita/blocco-unita.component';
import { DettaglioComponent } from './analisi/analisi-variazione-prezzi/dettaglio/dettaglio.component';
import { LogsComponent } from './manutenzione/logs/logs.component';
import { AvpAlertComponent } from './analisi/analisi-variazione-prezzi/avp-alert/avp-alert.component';
import { ConsumiAcquistiComponent } from './analisi/analisi-consumi/consumi-acquisti/consumi-acquisti.component';
import { UsersListComponent } from './endpoints/users-list/users-list.component';
import { DailyViewComponent } from './amministrazione/conto-economico/conto-economico/daily-view/daily-view.component';
import { IntegrationsEditComponent } from './endpoints/integrations-edit/integrations-edit.component';
import { BulkPaymentDialogComponent } from './amministrazione/scadenziario/scadenziario-tabella/bulk-payment-dialog/bulk-payment-dialog.component';

@NgModule({
    declarations: [
        AnalisiVenditeComponent,
        EndpointsComponent,
        EndpointsEditComponent,
        UsersEditComponent,
        AnalisiFoodcostComponent,
        GestioneMerciComponent,
        UnitComponent,
        OrderUnitComponent,
        RepartiComponent,
        RazioniComponent,
        FornitoriComponent,
        CategorieMerciComponent,
        CategorieProdottiComponent,
        BrandsComponent,
        CostCentersComponent,
        WastetypesComponent,
        CategorieMerciEditComponent,
        BrandsEditComponent,
        WastetypesEditComponent,
        CostCentersEditComponent,
        CategorieProdottiEditComponent,
        FornitoriEditComponent,
        OrderUnitEditComponent,
        RazioniEditComponent,
        RepartiEditComponent,
        UnitEditComponent,
        GruppiComponent,
        GruppiEditComponent,
        AcquistiComponent,
        TrasferimentiComponent,
        SprechiComponent,
        InventariComponent,
        GiacenzeComponent,
        FiltroPopupComponent,
        InserimentoAcquistiPopupComponent,
        InserimentoInventariPopupComponent,
        InserimentoRettifichePopupComponent,
        InserimentoTrasferimentiPopupComponent,
        InserimentoGiacenzePopupComponent,
        InserimentoComuneComponent,
        OrdiniComponent,
        InserimentoOrdiniPopupComponent,
        InserimentoOrdiniComponent,
        EditNotesComponent,
        SendMailComponent,
        SendDdtComponent,
        OrdiniInterniComponent,
        InserimentoOrdiniInterniPopupComponent,
        InserimentoOrdiniInterniComponent,
        OrdiniSemplificatiComponent,
        InserimentoOrdiniSemplificatiPopupComponent,
        InserimentoOrdiniSemplificatiComponent,
        StorageMainGraphComponent,
        TopItemsComponent,
        AnalisiConsumiComponent,
        ConsumiComponent,
        ConsumiDettagliatiComponent,
        StoricoVenditeComponent,
        CompanyUsersComponent,
        // MANUTENZIONE
        ImportexportComponent,
        PopupDettaglioComponent,
        GestioneRicetteComponent,
        EditRicettaComponent,
        InsertNotesComponent,
        SostituisciIngredienteComponent,
        StoresComponent,
        EndpointSettingsComponent,
        ForzaDateInizioComponent,
        EditMerciComponent,
        VisibilitaMerciComponent,
        VenditeManualiComponent,
        SendDdtComponent,
        SendInnerMailComponent,
        StatisticheComponent,
        StoricoVenditeEditComponent,
        ImportIngredientsComponent,
        BloccoInventariComponent,
        SupportComponent,
        EditIssueComponent,
        CercaMerceComponent,
        SetTypeDlgComponent,
        SetCategoryDlgComponent,
        SelectVariationDialogComponent,
        SetVATDlgComponent,
        AnalisiPrezziFornitoriComponent,
        EditTagsComponent,
        MergeGoodsComponent,
        SetSupplierComponent,
        SelectIntervalComponent,
        AddBrandsDlgComponent,
        TasksComponent,
        GruppiMerciComponent,
        GruppiMerciEditComponent,
        CompaniesDistributionComponent,
        GestioneFattureComponent,
        ContoEconomicoComponent,
        ContoEconomicoEditComponent,
        ContoEconomicoDetailsComponent,
        DashboardComponent,
        IncidenzaRicaviCostiComponent,
        MacroCategoriePeriodoComponent,
        SviluppoRicaviComponent,
        IndicatoriPerformanceComponent,
        RecipesFromGoodsComponent,
        ConsumiSemplificatiComponent,
        ManutenzioneMerciComponent,
        ManutenzioneRicetteComponent,
        DdtComponent,
        DdtEditComponent,
        LinkDDTComponent,
        SetDepartmentDlgComponent,
        StandardViewComponent,
        CompaniesViewComponent,
        MonthlyViewComponent,
        PlDetailComponent,
        CategoryGraphComponent,
        EstrazioneDatiComponent,
        FoodCostDetailComponent,
        AnalisiVariazionePrezziComponent,
        ScadenziarioComponent,
        ScadenziarioEditComponent,
        ScadenziarioTabellaComponent,
        ScadenziarioGraficoComponent,
        ScadenziarioPayComponent,
        BloccoUnitaComponent,
        DettaglioComponent,
        LogsComponent,
        AvpAlertComponent,
        ConsumiAcquistiComponent,
        UsersListComponent,
        DailyViewComponent,
        IntegrationsEditComponent,
        BulkPaymentDialogComponent
    ],
    exports: [TranslateModule],
    imports: [
        RouterModule,
        CommonModule,
        HttpClientModule,
        CoreModule,
        PartialsModule,
        //UserManagementModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatTreeModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatInputModule,
        MatButtonModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatIconModule,
        MatButtonToggleModule,
        MatDialogModule,
        MatSelectModule,
        MatPaginatorModule,
        MatRadioModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatCardModule,
        FormsModule,
        TranslateModule.forChild(),
        NgxMaskModule.forRoot(options),
        ReactiveFormsModule,
        NgbModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        NgxMaterialTimepickerModule,
        AutosizeModule,
        MBComponentsModule,
        MbDialogsModule,
        DragDropModule,
        InfiniteScrollModule,
        WidgetModule
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: MyMatPaginatorIntl },
        ManutenzioneService,
        ScreenSizeService,
        { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        NgxMatDatetimePickerModule,
        LocalizedDateTimePipe
    ]
})
export class PagesModule {
}
