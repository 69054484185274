import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API } from '@env/environment';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

@Injectable({
  providedIn: 'root'
})

export class AnalisiVenditeService {

  constructor(
    private http: HttpClient,
    private switchGroupService: SwitchGroupService
  ) { }

  getByCategories(config: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    //console.log('AnalisiVenditeService', 'getByCategories', config);
    return this.http.post<any>(currentLogin.endpointURL + '/api' + API.API_ANALISI_VENDITE + API.API_BY_CATEGORIES, config , { headers: httpHeaders });
  }

  getByDepartments(config: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.post<any>(currentLogin.endpointURL + '/api' + API.API_ANALISI_VENDITE + API.API_BY_DEPARTMENTS, config , { headers: httpHeaders });
  }

  getByMenu(config: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    //console.log('AnalisiVenditeService', 'getByMenu', config);
    return this.http.post<any>(currentLogin.endpointURL + '/api' + API.API_ANALISI_VENDITE + API.API_BY_MENU, config , { headers: httpHeaders });
  }

  getByCompany(config: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    //console.log('AnalisiVenditeService', 'getByCompany', config);
    return this.http.post<any>(currentLogin.endpointURL + '/api' + API.API_ANALISI_VENDITE + API.API_BY_COMPANY, config , { headers: httpHeaders });
  }

  getByTimeSlot(config: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    //console.log('AnalisiVenditeService', 'getByTimeSlot', config);
    return this.http.post<any>(currentLogin.endpointURL + '/api' + API.API_ANALISI_VENDITE + API.API_BY_TIMESLOT, config , { headers: httpHeaders });
  }

  getMenuEngeenering(config: any): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    //console.log('AnalisiVenditeService', 'getMenuEngeenering', config);
    return this.http.post<any>(currentLogin.endpointURL + '/api' + API.API_ANALISI_VENDITE +API.API_MENU_ENGEENERING, config , { headers: httpHeaders });
  }
}
