import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

//Services
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { FiltriService } from '@app/core/services/filtri.service';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { PDFService } from '@app/core/services/pdf.service';

// RXJS
import { BehaviorSubject, Subscription } from 'rxjs';

import * as util from '@app/core/services/utilityfunctions';
import * as _ from 'lodash';

// Pipes
import { LocalizedDateTimePipe } from '@app/core/pipes/localized-date-time.pipe';

import { MatDialog } from '@angular/material/dialog';
import { DdtEditComponent } from './ddt-edit/ddt-edit.component';

import moment from 'moment';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
declare var $: any;

@Component({
    selector: 'kt-ddt',
    templateUrl: './ddt.component.html',
    styleUrls: ['./ddt.component.scss']
})
export class DdtComponent implements OnInit {

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    switchGroupSubcription!: Subscription;
    filtriServiceSubscription!: Subscription;
    staticCollectionsSubcription!: Subscription;
    currentFilterCfg: any;
    locale!: string;
    fornitori: any;
    invoiceNumber!: string;
    ordStartsWith: boolean = false;
    showInitialImage: boolean = true;
    utility: any;
    companies: any;
    filterType: string = '0';

    constructor(
        public staticCollectionsService: StaticCollectionsService,
        public filtriService: FiltriService,
        private switchGroupService: SwitchGroupService,
        public roleService: RoleService,
        private magazzinoService: MagazzinoService,
        private router: Router,
        private translate: TranslateService,
        private localizedDateTimePipe: LocalizedDateTimePipe,
        private translationService: TranslationService,
        private layoutUtilsService: LayoutUtilsService,
        private pdfService: PDFService,
        private ref: ChangeDetectorRef,
        public dialog: MatDialog,
        private gestioneMerciService: GestioneMerciService
    ) {
        this.utility = util;
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
    }

    ngOnInit(): void {
        document.body.classList.add('kt-aside--minimize');

        this.fornitori = [];
        this.invoiceNumber = '';

        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            (filterConfig: any) => {

                // Se ho un filtro memorizzato lo recupero

                if (localStorage.getItem('currentFilterCfg')) {
                    try {
                        this.currentFilterCfg = JSON.parse(localStorage.getItem('currentFilterCfg') || '');
                        localStorage.removeItem('currentFilterCfg');
                        this.filtriService.performFilter$.next(this.currentFilterCfg);
                        return;
                    } catch (error) {
                        localStorage.removeItem('currentFilterCfg');
                    }
                }


                if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) {
                    return
                };
                if (filterConfig.constructor !== Object) {
                    return
                };
                this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

                console.log('SONO PASSATO ==>', filterConfig);

                // Inizializzo EVENTUALMENTE le select
                if (this.currentFilterCfg && this.currentFilterCfg.SupplierIds) {
                    this.fornitori = this.currentFilterCfg.SupplierIds;
                }

                //
                if (this.fornitori && this.fornitori.length > 0) {
                    this.currentFilterCfg['SupplierName'] = this.fornitori;
                } else {
                    delete this.currentFilterCfg.SupplierName;
                }

                this.initDataTable();
            }
        );

    }

    showFromYearStart() {
        this.showInitialImage = false;
        this.currentFilterCfg = { Period: 'YTD' };
        this.filtriService.performFilter$.next(this.currentFilterCfg);
        this.ref.detectChanges();
    }

    async ngAfterViewInit() {
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.clearFilters(false);
                    $('#ddtTable').DataTable().clear().destroy();
                }
            }
        );

        await this.staticCollectionsService.fillStaticCollection(false);
    }

    ngOnDestroy() {
        this.switchGroupSubcription.unsubscribe();
        this.filtriServiceSubscription.unsubscribe();

        // cancello il filtro
        this.filtriService.filterConfig = {};

        // Se l'url non include inserimento-comune allora svuoto la localstorage
        if (!this.router.url.includes('/magazzino/acquisti')) {
            localStorage.removeItem('currentFilterCfg');
        }
    }

    clearFilters(actionFilter: boolean = true) {
        this.filterType = '0';
        this.fornitori = [];
        this.invoiceNumber = '';
        this.filtriService.clearFilters$.next(actionFilter);
        $('#ddtTable').DataTable().clear().destroy();
        this.currentFilterCfg = [];

        this.showInitialImage = true;

        this.ref.detectChanges();
    }

    pagination: any = {
        TotalRows: 0,
        pageSize: 10,
        pageIndex: 0
    }

    changePagination(event: any) {
        if (this.pagination.pageIndex != event.pageIndex) {
            this.pagination.pageIndex = event.pageIndex;
            this.table.page(this.pagination.pageIndex).draw('page');
        } else if (this.pagination.pageSize != event.pageSize) {
            this.pagination.pageSize = event.pageSize;
            this.table.page.len(this.pagination.pageSize).draw();
        }
    }

    table: any;
    totalDelta: number = 0;
    totalPurchase: number = 0;
    totalPLAmount: number = 0;
    totalPLGrosAmount: number = 0;
    totalVat: number = 0;
    totalInvoices: number = 0;
    initDataTable() {
        this.loading$.next(true);

        if ($.fn.dataTable.isDataTable('#ddtTable')) {
            $('#ddtTable').DataTable().destroy();
        }

        setTimeout(() => {
            const currentLogin = this.switchGroupService.getCurrentGroup();

            const dtOptions: any = {
                orderCellsTop: true,
                destroy: true,
                paging: true,
                searching: true,
                dom: 'rt<"paginator">',
                ordering: true,
                scrollY: 'calc(100vh - 300px)',
                scrollCollapse: true,
                autoWidth: true,
                order: [[0, 'desc']],
                processing: true,
                serverSide: true,
                ajax: {
                    url: currentLogin.endpointURL + "/api/DDT/Paged",
                    type: "POST",
                    data: (d: any) => {
                        d.filters = this.currentFilterCfg;
                        return JSON.stringify(d);
                    },
                    headers: {
                        "Authorization": "Bearer " + currentLogin.access_token,
                        "Content-Type": "application/json"
                    },
                    dataSrc: (response) => {
                        console.log(response);
                        this.pagination.TotalRows = response.recordsFiltered;
                        this.totalDelta = response.TotalDelta;
                        this.totalInvoices = response.TotaleInvoces;
                        this.totalPurchase = response.TotalPurchase;
                        this.totalPLAmount = response.TotalPLAmount;
                        this.totalPLGrosAmount = response.TotalPLGrosAmount;
                        this.totalVat = response.TotalVat;
                        this.ref.detectChanges();
                        return response.data;
                    }
                },
                columnDefs: [
                    {
                        targets: 0, data: 'Date', name: 'Date', render: (data, type, p, meta) => {
                            return this.localizedDateTimePipe.transform(data, undefined, true);
                        }, className: 'max-width-60'
                    },
                    {
                        targets: 1, data: 'Store', name: 'Store', render: (data, type, p, meta) => {
                            return `<div class="overflow-ellipses exportableTooltip" title="${data}">${data}</div>`;
                        }, className: 'max-width-100'
                    },
                    {
                        targets: 2, data: 'SupplierName', name: 'SupplierName', render: (data, type, p, meta) => {
                            return `<div class="overflow-ellipses exportableTooltip" title="${data}">${data}</div>`;
                        }, className: 'max-width-100'
                    },
                    {
                        targets: 3, data: 'Number', name: 'Number', render: (data, type, p, meta) => {
                            let msg = data;
                            if (p.IsCredit) {
                                msg = '<i class="fas fa-coins" title="Credit"></i>&nbsp;' + msg
                            }
                            const classEllipses = p.IsCredit ? 'overflow-ellipses-100' : 'overflow-ellipses'
                            return `<div class="${classEllipses} exportableTooltip" data-toggle="tooltip" title="${data}">${msg}</div>`;
                        }, className: 'max-width-100'
                    },
                    {
                        targets: 4, data: 'Description', name: 'Description', render: (data, type, p, meta) => {
                            return `<div class="overflow-ellipses exportableTooltip" data-toggle="tooltip" title="${data}">${data}</div>`;
                        }, className: 'max-width-300'
                    },
                    {
                        targets: 5, data: 'Note', name: 'Note', className: 'max-width-300', render: (data, type, p, meta) => {
                            return `<div class="overflow-ellipses exportableTooltip" data-toggle="tooltip" title="${data ? data : ''}">${data ? data : ''}</div>`;
                        }
                    },
                    {
                        targets: 6, data: 'Amount', name: 'Amount', className: 'text-right', render: (data, type, p, meta) => {
                            return util.formatNumber(data, this.locale);
                        }
                    },
                    // Actions
                    {
                        targets: 7, orderable: false, className: 'actions section max-width-50', render: (data, type, p, meta) => {

                            const modelButton = this.roleService.canWrite() ? `<div class="h_spacer"></div><i class="actionBtn fas fa-clone text-primary pointer" name="modelItem" data-toggle="tooltip" title="${this.translate.instant('MAGAZZINO.DDT.USE_LIKE_MODEL')}"></i>` : '';
                            const editButton = this.roleService.canWrite() && !p.LinkedInvoiceId ? `<div class="h_spacer"></div><i class="actionBtn fas fa-pencil-alt text-primary pointer" name="editItem" data-toggle="tooltip" title="${this.translate.instant('MAGAZZINO.DDT.EDIT')}"></i>` : '';
                            const deleteButton = this.roleService.canWrite() && !p.LinkedInvoiceId ? `<div class="h_spacer"></div><i class="actionBtn fas fa-trash text-danger pointer" name="deleteItem" data-toggle="tooltip" title="${this.translate.instant('MAGAZZINO.DDT.DELETE')}"></i>` : '';
                            const previewXml = p.InvoiceFileName ? `<div class="h_spacer"></div><i class="actionBtn fas fa-eye text-primary pointer" name="previewXml" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.SHOW_XML')}"></i>` : '';
                            const downloadXml = p.InvoiceFileName ? `<div class="h_spacer"></div><i class="actionBtn fas fa-download text-primary pointer" name="downloadXml" data-toggle="tooltip" title="${this.translate.instant('GESTIONE_MERCI.IMPORTAZIONE_CHECK_OMONIMI.DOWNLOAD_XML')}"></i>` : '';

                            return `
                            <div class="display-flex-right">
                                ${modelButton}
                                ${previewXml}   
                                ${downloadXml}                             
                                ${editButton}
                                ${deleteButton}
                            </div>
                        `;
                        }
                    },

                    // Serve per evitare di riceve avvisi quando arrivano campi a null
                    {
                        defaultContent: '-',
                        targets: '_all'
                    }
                ],
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                    //lengthMenu: this.coreService.getTranslation('Admin.NumberItemsPerPage') + ' _MENU_',
                    //zeroRecords: "Nothing found - sorry",
                    //info: this.coreService.getTranslation('Generic.NumRows') + " _TOTAL_",
                    //infoEmpty: "No records available",
                    //infoFiltered: "(filtered from _MAX_ total records)"
                },
                buttons: [
                    {
                        className: 'toolbarButton',
                        extend: 'pdf',
                        //text: '<i class="fa fa-file-pdf text-danger"></i>',
                        orientation: 'landscape',
                        title: this.PDFPreferences?.title,
                        pageSize: 'A4',
                        download: 'open',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)',
                            format: {
                                body: function (data, row, column, node) {
                                    if (data.indexOf('exportableTooltip') >= 0) {
                                        var parser = new DOMParser();
                                        var html = parser.parseFromString(data, 'text/html');
                                        let html$ = $(html);
                                        data = html$.find(`.exportableTooltip`).attr("title");
                                    }

                                    return data;
                                }
                            }
                        },
                        customize: (doc: any) => {

                            const imageLogoB64 = $('#kt_header .logoimg').attr('src');
                            const size = {
                                width: $('#kt_header .logoimg').width(),
                                height: $('#kt_header .logoimg').height()
                            };

                            const arrayCols = ['auto', 'auto', 'auto', 'auto', '*', 'auto'];

                            const docDefinition = this.pdfService.getDocDefinition(this.PDFPreferences, arrayCols, '', imageLogoB64, size);
                            doc.content[0] = docDefinition.content[0];
                            doc.content[1].layout = docDefinition.content[1].layout;
                            doc.content[1].table['headerRows'] = docDefinition.content[1].table['headerRows'];
                            doc.content[1].table['widths'] = docDefinition.content[1].table['widths'];
                            ////doc.defaultStyle = docDefinition.defaultStyle;
                            doc.footer = docDefinition.footer;
                            doc.header = docDefinition.header;
                            doc.pageMargins = docDefinition.pageMargins;
                            doc.pageOrientation = docDefinition.pageOrientation;
                            doc.pageSize = docDefinition.pageSize;
                            doc.styles = docDefinition.styles;

                            // Imposto gli allineamenti
                            var rowCount = doc.content[1].table.body.length;
                            for (let i = 0; i < rowCount; i++) {
                                doc.content[1].table.body[i][0].alignment = 'left';
                                doc.content[1].table.body[i][1].alignment = 'left';
                                doc.content[1].table.body[i][2].alignment = 'left';
                                doc.content[1].table.body[i][3].alignment = 'left';
                                doc.content[1].table.body[i][4].alignment = 'left';
                                doc.content[1].table.body[i][5].alignment = 'right';
                            }
                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.pdfHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    },
                    {
                        className: 'toolbarButton',
                        extend: 'excelHtml5',
                        //autoFilter: true,
                        //sheetName: 'Exported data',
                        exportOptions: {
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null,
                            },
                            columns: ':visible:not(.actions)',
                            format: {
                                body: (data, row, column, node) => {
                                    if (data.indexOf('exportableTooltip') >= 0) {
                                        var parser = new DOMParser();
                                        var html = parser.parseFromString(data, 'text/html');
                                        let html$ = $(html);
                                        data = html$.find(`.exportableTooltip`).attr("title");
                                    }

                                    data = '\0' + data;

                                    return data;
                                }
                            },
                            orthogonal: 'export'
                        },
                        action: function (e, dt, node, config) {
                            var self = this;
                            var currentPageLen = dt.page.len();
                            var currentPage = dt.page.info().page;
                            var totRecords = dt.page.info().recordsTotal
                            dt.one('draw', () => {
                                (<any>$.fn.dataTable.ext).buttons.excelHtml5.action.call(self, e, dt, node, config);
                                setTimeout(function () {
                                    dt.page.len(currentPageLen).draw(); //set page length
                                    dt.page(currentPage).draw('page'); //set current page
                                });
                            });
                            dt.page.len(totRecords).draw();
                        }
                    }
                ]
            };

            this.table = $('#ddtTable').DataTable(dtOptions);
            this.table.on('page.dt', function () {
                $('.dataTables_scrollBody').animate({
                    scrollTop: 0
                }, 'slow');
            });
            if (this.invoiceNumber) {
                const v = this.ordStartsWith ? '#' + $("#invoiceNumberSearch").val() : $("#invoiceNumberSearch").val();
                this.performFilter(v, 'Number');
            }

            this.loading$.next(false);

            this.showInitialImage = false;
            this.ref.detectChanges();

            $('tbody').off('click');
            $('tbody').on('click', '.actionBtn', async (event: any) => {
                const tr = $(event.target).closest('tr');
                const btn = $(event.target).closest('i');
                const data = this.table.row(tr.index()).data();
                console.log(data);

                switch (btn.attr('name')) {
                    case 'modelItem':
                        let model = {
                            Date: data.Date,
                            Description: data.Description,
                            SupplierId: data.SupplierId,
                            SupplierName: data.SupplierName,
                            Store: data.Store
                        };
                        this.editItem(model);
                        break;
                    case 'editItem':
                        this.editItem(data);
                        break;
                    case 'deleteItem':
                        this.deleteItem(data.Id);
                        break;
                    case 'previewXml':
                        this.showXml({
                            FileName: data.InvoiceFileName,
                            Name: data.InvoiceFileName
                        });
                        break;
                    case 'downloadXml':
                        //this.loading$.next(true);
                        const xml: any = await this.magazzinoService.getRawXml(data.InvoiceFileName).toPromise();
                        let blob = new Blob([xml], { type: 'text/xml' });
                        let url = URL.createObjectURL(blob);
                        window.open(url);
                        URL.revokeObjectURL(url); //Releases the resources
                        //this.pdfService.htmlToPdf(html, data.InvoiceFileName).then(() => this.loading$.next(false));
                        break;
                }

            });

        }, 100);

    }

    showVatDetails() {
        const visible = this.table.column(6).visible();
        this.table.columns(6).visible(!visible).draw();
    }

    popupXml: any;
    async showXml(merce: any) {


        this.gestioneMerciService.openXmlHighlightedByFileName(merce.FileName, "", "")
            .then(() => console.log)
            .catch((error: any) => {
                this.layoutUtilsService.showActionNotification(this.translate.instant(error.error_code), MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-danger');
            })

    }

    PDFPreferences: any;
    exportAsPDF(expand: boolean = false) {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
            explodeRows: this.translate.instant('EXPORT_PDF.EXPLODE_ROWS')
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            //if (result) this.preparePdfConfiguration(result);
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('GESTIONE_FATTURE.TITLE'),
                    period: this.filtriService.getCurrentPeriod()
                };
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                };
                result['language'] = this.translationService.getSelectedLanguage();

                this.PDFPreferences = result;

                this.table.button(0).trigger();
            };
        });
    }

    addNewDdt() {
        //let date;
        //if (!this.currentFilterCfg) {
        //  date = moment().subtract(1, 'days').format('YYYY-MM-DD'); // Perchè di default il giorno è impostato a ieri
        //} else if (this.currentFilterCfg.DateFilter) {
        //  date = moment(this.currentFilterCfg.DateFilter.Start).format('YYYY-MM-DD');
        //} else if (this.currentFilterCfg.Period) {
        //  switch (this.currentFilterCfg.Period.toLowerCase()) {
        //    case 'onlyyesterday':
        //    case 'yesterday':
        //      date = moment().subtract(1, 'days').format('YYYY-MM-DD');
        //      break;
        //    case 'ytd':
        //      date = moment().subtract(1, 'years').format('YYYY-MM-DD');
        //      break;
        //    case 'mtd':
        //      date = moment().subtract(1, 'months').format('YYYY-MM-DD');
        //      break;
        //    case 'year':
        //      date = moment().startOf('year').format('YYYY-MM-DD');
        //      break;
        //    case 'month':
        //      date = moment().startOf('month').format('YYYY-MM-DD');
        //      break;
        //    case 'week':
        //      date = moment().startOf('week').format('YYYY-MM-DD');
        //      break;
        //    case 'day':
        //      date = moment().format('YYYY-MM-DD');
        //      break;
        //  }
        //}
        //const description = this.localizedDateTimePipe.transform(date, 'MMM YY', true);
        this.editItem({})
    }

    editItem(item: any) {
        const dialogRef = this.dialog.open(DdtEditComponent, {
            data: {
                item: item
            },
            width: '500px'
        });

        dialogRef.afterClosed().subscribe((res: any) => {
            setTimeout(() => {
                if (res) {
                    if (this.table) this.table.draw();
                    let message = item.Id ? this.translate.instant('MAGAZZINO.DDT.UPDATE_DIALOG.SUCCESS') : this.translate.instant('GESTIONE_MERCI.SAVE_DIALOG.SUCCESS');
                    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                }
            }, 100);
        });
    }

    deleteItem(Id: string) {

        const _title: string = this.translate.instant('MAGAZZINO.DDT.DELETE_DIALOG.TITLE');
        const _description: string = this.translate.instant('MAGAZZINO.DDT.DELETE_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('MAGAZZINO.DDT.DELETE_DIALOG.BUTTON');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            console.log(confirm);
            if (confirm) {
                this.loading$.next(true);
                this.magazzinoService.deleteDDT(Id).toPromise()
                    .then(() => {
                        const message = this.translate.instant('MAGAZZINO.DDT.DELETE_DIALOG.SUCCESS');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                        this.table.draw();
                    })
                    .catch(() => {
                        const message = this.translate.instant('MAGAZZINO.DDT.DELETE_DIALOG.ERROR');
                        this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                    })
                    .finally(() => this.loading$.next(false));

            }
        })

    }

    filter(event: any) {
        if (event.keyCode == 13) {
            const v = this.ordStartsWith ? '#' + $(event.target).val() : $(event.target).val();
            this.performFilter(v, event.target.name);
        }
    }

    performFilter(value, name) {
        if (!value) value = '';
        const key = name + ":name";
        var column = this.table.column(key);
        if (column) {
            column.search(value).draw();
        }
        else {
            console.log("Controllare la colonna")
        }
    }

    filterByInvoiceNumber(event: any) {
        if (event.keyCode == 13) {
            
            const value = this.ordStartsWith ? '#' + $(event.target).val() : $(event.target).val();
            if (!this.currentFilterCfg) {
                this.currentFilterCfg = { Period: 'YTD' };
            }
            this.currentFilterCfg['Number'] = [value];
            if (this.table) {
                this.table.draw();
            }
            else {
                this.showInitialImage = false;
                this.filtriService.performFilter$.next(this.currentFilterCfg);
            }
        }
    }

    filterBySupplier(value: any) {
        if (!this.currentFilterCfg) {
            this.currentFilterCfg = { Period: 'YTD', ShowDisabled: true, ShowInvisible: true };
        }
        this.currentFilterCfg['SupplierIds'] = [value];
        if (this.table) {
            this.table.draw();
        }
        else {
            this.showInitialImage = false;
            this.filtriService.performFilter$.next(this.currentFilterCfg);
        }
    }

    filterByCompany(value: any) {
        if (!this.currentFilterCfg) {
            this.currentFilterCfg = { Period: 'YTD', ShowDisabled: true, ShowInvisible: true };
        }
        this.currentFilterCfg['Companies'] = [value];
        if (this.table) {
            this.table.draw();
        }
        else {
            this.showInitialImage = false;
            this.filtriService.performFilter$.next(this.currentFilterCfg);
        }
    }

    async exportAsXLSX() {
        this.table.button(1).trigger();
        this.loading$.next(true);
        setTimeout(() => {
            this.ref.detectChanges();
            this.loading$.next(false);
        }, 100);
    }

}

