import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as util from '@app/core/services/utilityfunctions';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

@Component({
  selector: 'kt-numeric-keyboard',
  templateUrl: './numeric-keyboard.component.html',
  styleUrls: ['./numeric-keyboard.component.scss']
})
export class NumericKeyboardComponent implements OnInit {

  utility: any;
  locale: string = 'en';
  ds: string;
  @Input() amount: string;
  @Output() onAction = new EventEmitter<any>();

  constructor(
    private translationService: TranslationService
  ) { 
    this.utility = util;
    		this.translationService.performSwitchLanguage.subscribe((lang) => {
			this.locale = lang;
		});
    this.ds = this.getDecimalSeparator(this.translationService.getSelectedLanguage());
    this.amount = `0${this.ds}0`;
  }

  ngOnInit() {
    console.log(this.amount);
  }

  getDecimalSeparator(locale: string) {
		const numberWithDecimalSeparator = 1.1;
    const intl = Intl.NumberFormat(locale)
    .formatToParts(numberWithDecimalSeparator)
    .find(part => part.type === 'decimal');
		return intl ? intl.value : '.';
	}

  onTouchend(key: any, event: any) {
    //console.log('onTouchend', key);
    if (this.amount === `0${this.ds}0`) this.amount = '';
    switch (key) {
      case '.':
        if (this.amount.indexOf(key) >= 0) {
          ;
        } else {
          this.amount += (this.ds === ',') ? ',' : '.';
        }
        break;
      case 'del':
        this.amount = this.amount.slice(0, -1);
        break;
      case 'enter':
        const indexDs = this.amount.indexOf(this.ds);
        const decimals = this.amount.substring(indexDs+1).length;
        if(decimals < 2) this.amount += '0';
        this.onAction.emit({
          type: 'enter',
          value: this.amount
        });
        break;
      case 'esc':
        this.onAction.emit({
          type: 'esc'
        });
        break;
      default:
        this.amount += key;
    }
  }

}
