import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import * as utils from '@app/core/services/utilityfunctions';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout';
declare var $: any;

@Component({
    selector: 'kt-mb-time-distribution',
    templateUrl: './mb-time-distribution.component.html',
    styleUrls: ['./mb-time-distribution.component.scss']
})
export class MbTimeDistributionComponent implements OnInit {

    @Input() invoice: any;
    @Input() readOnly: boolean = false;
    @Output() onDistributionReady = new EventEmitter<boolean>(false);
    SplitPurchase: boolean = false;
    showSlitPurchases: boolean = false;

    onlyOneDate: boolean = false;
    dateStart: any;
    dateEnd: any;
    frequency: string;

    utility: any;
    locale!: string;
    dates: any;

    table: any;
    plAmount: number = 0;


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<any>,
        private translationService: TranslationService,
        private translate: TranslateService
    ) {
        this.utility = utils;
        this.frequency = 'end';

        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
    }

    ngOnInit(): void {
        if (this.data) {
            this.invoice = this.data.invoice;
            this.readOnly = this.data.readOnly;
            this.showSlitPurchases = this.data.showSlitPurchases ? this.data.showSlitPurchases : false;
            this.plAmount = this.utility.parseNumber(this.invoice.PLAmount, this.locale);

            if (this.invoice.PLSplits) {
                this.dates = [];
                this.invoice.PLSplits.forEach((data: any) => {
                    this.dates.push({
                        date: data.Date,
                        amount: data.Amount,
                        perc: (100 / this.plAmount) * data.Amount,
                        amountFormatted: this.utility.formatNumber(data.Amount, this.locale),
                        percFormatted: this.utility.formatNumber((100 / this.plAmount) * data.Amount, this.locale),
                    })
                });

                this.sortDates();
            }
        }

        this.dateStart = this.invoice.Date;
        this.dateEnd = this.invoice.Date;

        this.initDataTable();
    }

    //sort this.dates by date
    sortDates() {
        this.dates.sort((a: any, b: any) => {
            return a.date.localeCompare(b.date);
        });
    }

    mode!: string;
    changeMode() {
        this.performDistribution();
    }

    performDistribution() {
        if (this.dateStart && this.dateEnd && this.frequency) {

            const startDate = moment(this.dateStart).startOf('day');
            const endDate = moment(this.dateEnd).endOf('day');

            if (endDate.isBefore(startDate)) {
                throw Error('End date must be greater than start date.');
            }

            let datesList: any = [];
            while (startDate.isSameOrBefore(endDate)) {
                let date = '';
                switch (this.frequency) {
                    case 'start':
                        date = startDate.startOf('month').format('YYYY/MM/DD');
                        break;
                    case 'middle':
                        date = startDate.date(15).format('YYYY/MM/DD');
                        break;
                    default:
                        date = startDate.endOf('month').format('YYYY/MM/DD');
                }

                datesList.push(date);

                startDate.add(1, 'month');
            }

            
            const amount = this.plAmount / datesList.length;
            const perc = (100 / this.plAmount) * amount;

            this.dates = [];
            datesList.forEach((date: any) => {
                this.dates.push({
                    date: date,
                    amount: amount,
                    perc: perc,
                    amountFormatted: this.utility.formatNumber(amount, this.locale),
                    percFormatted: this.utility.formatNumber(perc, this.locale)
                })
            });

            this.sortDates();

            this.initDataTable();

            this.onDistributionReady.emit(this.dates);
        }
    }

    initDataTable() {

        if ($.fn.dataTable.isDataTable('#distributionTable')) {
            $('#distributionTable').DataTable().destroy();
        }

        setTimeout(() => {
            this.table = $('#distributionTable').DataTable({
                destroy: true,
                paging: false,
                searching: false,
                ordering: false,
                scrollY: '300px',
                scrollCollapse: true,
                autoWidth: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                columnDefs: [
                    { targets: [1], width: '100px', className: 'text-right' },
                    { targets: [2], width: '100px', className: 'text-right' },
                ]
            });

            this.refreshDistribution();

        }, 100);
    }

    confirm() {

        if (this.data) {
            this.dialogRef.close({
                success: true,
                body: this.dates,
                SplitPurchase: this.SplitPurchase
            });
        }

    }

    totDistributionPerc: number = 0;
    totDistributionAmount: number = 0;

    calcAmount(item: any) {
        if (!item.perc) return '';
        const value = this.plAmount * this.utility.parseNumber(item.percFormatted, this.locale) / 100;
        return value;
    }

    calcPercentage(item: any) {
        if (!item.amount) return '';
        const value = this.utility.parseNumber(item.amountFormatted, this.locale) / this.plAmount * 100;
        return value;
    }

    updateDistribution(event: KeyboardEvent) {

        this.totDistributionPerc = 0;
        this.totDistributionAmount = 0;

        const isPerc = $(event.target).attr('name') == 'perc';

        if (this.dates && this.dates.length > 0) {
            this.dates.forEach((item: any) => {
                if (isPerc && item.percFormatted) {
                    item.amount = this.calcAmount(item);
                    item.amountFormatted = this.utility.formatNumber(item.amount, this.locale);
                    item.perc = this.utility.parseNumber(item.percFormatted, this.locale)
                } else if (item.amountFormatted) {
                    item.perc = this.calcPercentage(item);
                    item.percFormatted = this.utility.formatNumber(item.perc, this.locale);
                    item.amount = this.utility.parseNumber(item.amountFormatted, this.locale)
                }
                if (item.perc && item.perc > 0) {
                    this.totDistributionPerc += item.perc;
                    this.totDistributionAmount += item.amount;
                }
            });
        }
    }


    refreshDistribution() {
        this.totDistributionPerc = 0;
        this.totDistributionAmount = 0;
        if (this.dates && this.dates.length > 0) {
            this.dates.forEach((item: any) => {
                if (item.perc && item.perc > 0) {
                    this.totDistributionPerc += item.perc;
                    this.totDistributionAmount += item.amount;
                }
            });
        }
    }

    checkEnableConfirmButton() {

        const a = Math.abs(this.totDistributionPerc - 100) < 0.01;
        const b = Math.abs(this.totDistributionAmount - this.plAmount) < 0.01;

        return Math.abs(this.totDistributionPerc - 100) < 0.01 && Math.abs(this.totDistributionAmount - this.plAmount) < 0.01;
    }

}
