import { Component, OnInit, Inject } from '@angular/core';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'kt-set-vatdlg',
  templateUrl: './set-vatdlg.component.html',
  styleUrls: ['./set-vatdlg.component.scss']
})
export class SetVATDlgComponent implements OnInit {

  VAT!: string;

  constructor(
    private dialogRef: MatDialogRef<any>,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.dialogRef.close(this.VAT);
	}


}
