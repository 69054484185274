import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API } from '@env/environment';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

@Injectable({
  providedIn: 'root'
})
export class CostCenterService {

  constructor(
		private http: HttpClient,
		private switchGroupService: SwitchGroupService
	) {}

  getCostCenters(): Observable<any> {
    const currentLogin = this.switchGroupService.getCurrentGroup();

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + currentLogin.access_token
    });

    return this.http.get<any>(currentLogin.endpointURL + '/api/Filter' + API.API_COSTCENTERS, { headers: httpHeaders });
  }

}
