import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import {
	MAT_MOMENT_DATE_FORMATS,
	MomentDateAdapter,
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, fromEvent, lastValueFrom } from 'rxjs';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import * as _ from 'lodash';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';

@Component({
	selector: 'kt-inserimento-rettifiche-popup',
	templateUrl: './inserimento-rettifiche-popup.component.html',
	styleUrls: ['./inserimento-rettifiche-popup.component.scss'],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
	]
})
export class InserimentoRettifichePopupComponent implements OnInit {

	loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	company: any;
	costCenter: any;
	department: any;
	date!: Date;

	listaCompanies: any;
	listaCostCenters: any;
	listaDepartment: any;

	viewLoading: boolean = false;
	hasFormErrors: boolean = false;
	errorMessage!: string;

	minDate: Date;
	maxDate: Date;

	isEdit: boolean = false;
	searchFilter:string ='';
	selectedWasteType: any = 0;
    wasteTypes: any = [];

	constructor(
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private translate: TranslateService,
		private staticCollectionsService: StaticCollectionsService,
		private _adapter: DateAdapter<any>,
		private anagraficheService: AnagraficheService,
	) {
		this.minDate = new Date();
		this.minDate.setFullYear((new Date()).getFullYear() - 1);
		this.maxDate = new Date();
		this.maxDate.setFullYear((new Date()).getFullYear() + 1);
	}

	getTitle() {
		return this.isEdit ? this.translate.instant('MAGAZZINO.SPRECHI.INSERIMENTO_SPRECHI.TITLE_UPDATE') : this.translate.instant('MAGAZZINO.SPRECHI.INSERIMENTO_SPRECHI.TITLE');
	}

	async ngOnInit() {

        const wasteTypes = await lastValueFrom(this.anagraficheService.getEntity('Wastetypes'));
        if(wasteTypes) {
            this.wasteTypes = wasteTypes.filter((wt: any) => wt.IsWarehouse);
        }

		if (_.isEmpty(this.data.endpoint) || !this.data) {
			;
		} else {
			this.isEdit = true;
		}

		this._adapter.setLocale('it');//this._adapter.setLocale(this.translationService.getSelectedLanguage());

		this.listaCompanies = this.staticCollectionsService.aziende$;
		this.listaCostCenters = this.staticCollectionsService.costCenters$;
		this.listaDepartment = this.staticCollectionsService.repartiMagazzino$;


		if (!this.isEdit) {
			if (this.listaCompanies.length == 1) {
				this.company = this.listaCompanies[0].Name;
			}
			if (this.listaCostCenters.length > 0) {
				const defCostCenter = this.listaCostCenters.filter(costCenter => costCenter.Name === '---');
				this.costCenter = defCostCenter ? defCostCenter[0].Id : undefined;
			}
			if (this.listaDepartment.length > 0) {
				const defDepartment = this.listaDepartment.filter(department => department.Name === '---');
				this.department = defDepartment ? defDepartment[0].Id : undefined;
			}

		} else {

			console.log('this.data.endpoint', this.data.endpoint);

			if (this.data.endpoint.Company) {
				const existsCompany = this.listaCompanies.find( (company: any) => company.Name.toString() ===  this.data.endpoint.Company.toString());
				if (existsCompany) {
					this.company = existsCompany.Name;
				}
			}
			if (this.data.endpoint.CostCenter) {
				const existsCostCenter = this.listaCostCenters.find( (costCenter: any) => costCenter.Id.toString() ===  this.data.endpoint.CostCenter.Id.toString());
				if (existsCostCenter) {
					this.costCenter = existsCostCenter.Id;
				}
			}
			if (this.data.endpoint.DepartmentId) {
				const existsDepartment = this.listaDepartment.find( (department: any) => department.Id.toString() ===  this.data.endpoint.DepartmentId.toString());
				if (existsDepartment) {
					this.department = existsDepartment.Id;
				}
			}
			if (this.data.endpoint.Date) {
				this.date = new Date((new Date(this.data.endpoint.Date).getTime() - 0));
			}

		}

	}

	onAlertClose($event) {
		this.hasFormErrors = false;
	}



	onSubmit() {
		if (!this.company) {
			this.errorMessage = this.translate.instant('FILTRI.COMPANIES') + ' ' + this.translate.instant('COMMONS.IS_REQUIRED');
			this.hasFormErrors = true;
			return;
		}
		if (!this.date) {
			this.errorMessage = this.translate.instant('MAGAZZINO.ACQUISTI.INSERIMENTO_ACQUISTI_POPUP.DATA_DOCUMENT') + ' ' + this.translate.instant('COMMONS.IS_REQUIRED');
			this.hasFormErrors = true;
			return;
		}

		this.dialogRef.close({
			success: true,
			body: {
				Company: this.company,
				CostCenterId: this.costCenter,
				DepartmentId: this.department,
				hideExcluded: false,
				hideInvisible: false,
			    SelectedWasteType: this.selectedWasteType,
				//SupplierId: 27,
				Date: moment(this.date).format('YYYY-MM-DDTHH:mm:ss'),
				SearchText: this.searchFilter
			}
		});

	}

}
