import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'm-simple-entity-dialog',
	templateUrl: './simple-entity-dialog.component.html'
})
export class SimpleEntityDialogComponent implements OnInit {
	viewLoading: boolean = false;

	@ViewChild('yesButton', { static: false }) yesButton!: MatButton;
	@ViewChild('noButton', { static: false }) noButton!: MatButton;

	constructor(
		public dialogRef: MatDialogRef<SimpleEntityDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	EnterManagement(event: any) {
		if (event.key === 'Enter' || event.key == 'NumpadEnter') {
			event.stopPropagation();
			this.onYesClick();
		}
	}

	ngAfterViewInit() {
		if (this.data.yesButton) {
			this.yesButton.focus();
		} else {
			this.noButton.focus();
		}
	}

	ngOnInit() {
		document.addEventListener('keydown', this.EnterManagement.bind(this));
		// if (this.data.yesButton) {
		// 	$('#yesButton').focus()
		// } else {
		// 	$('#noButton').focus()
		// }
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onYesClick(): void {
		this.viewLoading = true;
		this.dialogRef.close(true); // Keep only this row
	}

	onYes2Click(): void {
		this.viewLoading = true;
		this.dialogRef.close(2); // Keep only this row
	}

	/**
 * On destroy
 */
	ngOnDestroy() {
		document.removeEventListener('keydown', this.EnterManagement.bind(this));
	}
}
