import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'kt-merge-order-unit-dialog',
    templateUrl: './merge-order-unit-dialog.component.html',
    styleUrls: ['./merge-order-unit-dialog.component.scss']
})
export class MergeOrderUnitDialogComponent implements OnInit {

    orderUnitDestination: any;

    constructor(
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<MergeOrderUnitDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public staticCollectionsService: StaticCollectionsService,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
    }

    addNewUnit() {
        const _title: string = this.translate.instant('BULK_EDIT_NEW_MERCI.ADD_NEW_UNIT_DIALOG.TITLE');
        const _description: string = this.translate.instant('BULK_EDIT_NEW_MERCI.ADD_NEW_UNIT_DIALOG.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('BULK_EDIT_NEW_MERCI.ADD_NEW_UNIT_DIALOG.WAIT_DESCRIPTION');
        const _successMessage = this.translate.instant('BULK_EDIT_NEW_MERCI.ADD_NEW_UNIT_DIALOG.MESSAGE');
        const _yesButton = this.translate.instant('COMMONS.CONFIRM');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((ok: boolean) => {
            if (!ok) {
                return;
            }
            this.dialogRef.close({ success: true, action: 'addNewUnit' });
        });
    }

    mergeUnit() {
        this.dialogRef.close({ success: true, action: 'mergeUnit', orderUnitDestination: this.staticCollectionsService.orderUnit$.find(x => x.Id == this.orderUnitDestination) });
    }
}
