import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'kt-save-settings',
    templateUrl: './save-settings.component.html',
    styleUrls: ['./save-settings.component.scss']
})
export class SaveSettingsComponent implements OnInit {

    settingName: any;

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
    ) { 
        this.settingName = data.SettingName || '';
    }

    ngOnInit(): void {
    }

    onSettingNameChange(event) {
        this.settingName = event.target.value;
    }

    onSubmit() {
        this.dialogRef.close(this.settingName);
    }

}
