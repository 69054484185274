import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { MbSelect2Component } from './mb-select2/mb-select2.component';
import { MbSpinnerComponent } from './mb-spinner/mb-spinner.component';
import { MbTimeDistributionComponent } from './mb-time-distribution/mb-time-distribution.component';

// Material
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

//
import { LocalizedDateTimePipe } from '@app/core/pipes/localized-date-time.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MbInputSearchAdvancedComponent } from './mb-input-search-advanced/mb-input-search-advanced.component';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MbDaterangepickerComponent } from './mb-daterangepicker/mb-daterangepicker.component';



@NgModule({
  declarations: [
    MbSelect2Component,
    MbSpinnerComponent,
    MbTimeDistributionComponent,
    MbInputSearchAdvancedComponent,
    MbDaterangepickerComponent,
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    TranslateModule.forChild(),
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [
    MbSelect2Component,
    MbSpinnerComponent,
    MbInputSearchAdvancedComponent,
    MbDaterangepickerComponent
  ],
  providers: [
    LocalizedDateTimePipe
  ]
})
export class MBComponentsModule { }
