import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { Observable, Subscription, debounceTime, switchMap } from 'rxjs';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { Router, Event, NavigationEnd } from '@angular/router';
import * as moment from 'moment';
import * as util from '@app/core/services/utilityfunctions';
import { MatDialog } from '@angular/material/dialog';
import { MonthsSelectorComponent } from './months-selector/months-selector.component';
import * as _ from 'lodash';

// Services
import { CategorieProdottiService } from '@app/core/services/categorie-prodotti.service';
import { ProdottiService } from '@app/core/services/prodotti.service';
import { GestioneMerciService } from '@app/core/services/gestione-merci.service';
import { FiltriService } from '@app/core/services/filtri.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { NavigationService } from '@app/core/services/navigation.service';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'kt-filtri',
    templateUrl: './filtri.component.html',
    styleUrls: ['./filtri.component.scss'],
    providers: [
        // The locale would typically be provided on the root module of your application. We do it at
        // the component level here, due to limitations of our example generation script.
        { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },

        // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
        // `MatMomentDateModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})
export class FiltriComponent implements OnInit, OnDestroy {

    private autoComplete!: MatAutocompleteTrigger;
    @ViewChild('trigger', { static: false }) set _setAutoComplete(content: MatAutocompleteTrigger) {
        if (content) { // initially setter gets called with undefined
            this.autoComplete = content;
        }
    }

    // mb-select2 components
    loading$!: Observable<boolean>;
    readOnly: boolean = false;
    showSpinner: boolean = false;
    showPriceAnalysis: boolean = false;
    openFilter: boolean = false;
    period: string = '';
    periodTranslate: string = '';
    DateSelectionType: string = '';
    categorie: any[] = [];
    prodotti: any[] = [];
    dayWeek: any[] = [];
    aziende: string[] = [];
    costCenters: any[] = [];
    dateStart: any;
    dateList: any[] = [];
    dateEnd: any;
    fbType: any;
    timeSlot: string = '';
    timeInterval: string = '';
    fromH: string = '';
    toH: string = '';
    bestItems!: number;
    grouping: string = '';

    fbtypeDisabled: boolean = false;
    categorieDisabled: boolean = false;
    prodottiDisabled: boolean = false;

    categorieFiltrate: any[] = [];
    prodottiFiltrati: any[] = [];

    goodsOrSubrecipeNames = new FormControl();
    filteredOptions!: Observable<any[]>;

    dayWeekArray: any = [];

    switchGroupSubcription!: Subscription;
    filtriServiceSubscription!: Subscription;
    filtriServiceReadOnlyObsvSubscription!: Subscription;
    filtriServiceClearFiltersSubscription!: Subscription;
    filtriServiceTriggerOpenFilterSubscription!: Subscription;
    magazzinoServiceSubscription!: Subscription;

    statisticheSelector: any = {
        model: 'Purchase'
    };

    // gestione merci
    categorieMerciFiltrate: any[] = [];
    gruppiMerciFiltrati: any[] = [];
    fornitoriFiltrati: any[] = [];

    categorieMerci: any[] = [];
    gruppiMerci: any[] = [];
    fornitori: any[] = [];
    brands: any[] = [];

    // ANALISI CONSUMI
    GoodState: string = '';

    // STORICO VENDITE
    AdjustmentType: number = -1;
    PriceZero: number = 0;
    CostZero: number = 0;
    MenuSellings: number = 0;

    // Magazzino
    productName: string = '';
    productCode: string = '';
    showInvisible: boolean = true;
    showDisabled: boolean = true;
    reparti: any[] = [];
    merci: any[] = [];
    gruppi: any[] = [];
    sentType: number = 0;
    originCostCenter: any;
    originCostCenterTranslate: string = '';

    // Gestione Ricette
    //enableState: number = 2;
    //visibleState: number = 3;
    enableState: any;
    visibleState: any;
    recipeType: any;
    goodsOrSubrecipeName: string = '';

    priceEvolutionType: string = '';
    priceEvolutionDays!: number;

    DASHBOARD: boolean = false;
    GESTIONE_MERCI: boolean = false;
    ANALISI: boolean = false;
    ANALISI_FOODCOST: boolean = false;
    ANALISI_CONSUMI: boolean = false;
    ANALISI_VENDITE: boolean = false;
    ANALISI_PREZZI_FORNITORI: boolean = false;
    ANALISI_VARIAZIONE_PREZZI: boolean = false;
    CONTO_ECONOMICO: boolean = false;
    SCADENZIARIO: boolean = false;
    GESTIONE_FATTURE: boolean = false;
    GESTIONE_MAGAZZINO: boolean = false;
    GESTIONE_MAGAZZINO_ORDINI: boolean = false;
    GESTIONE_MAGAZZINO_DDT: boolean = false;
    GESTIONE_MAGAZZINO_ACQUISTI: boolean = false;
    GESTIONE_MAGAZZINO_TRASFERIMENTI: boolean = false;
    GESTIONE_MAGAZZINO_INVENTARI: boolean = false;
    GESTIONE_MAGAZZINO_SPRECHI: boolean = false;
    GESTIONE_MAGAZZINO_GIACENZE: boolean = false;
    GESTIONE_MAGAZZINO_STATISTICHE: boolean = false;
    STORICO_VENDITE: boolean = false;
    GESTIONE_RICETTE: boolean = false;
    VENDITE_MANUALI: boolean = false;


    oldFilterConfig: string = '';

    currentFilterCfg: any;

    utility: any;

    // SelectAll
    select2AllDayWeek: any;
    select2AllAziende: any;
    select2AllCostCenters: any;
    select2AllGruppi: any;
    select2AllCategorie: any;
    select2AllProdotti: any;
    select2AllReparti: any;
    select2AllCategorieMerci: any;
    select2AllGruppiMerci: any;
    select2AllSuppliers: any;

    constructor(
        public staticCollections: StaticCollectionsService,
        private categorieProdottiService: CategorieProdottiService,
        private prodottiService: ProdottiService,
        public filtriService: FiltriService,
        private translate: TranslateService,
        private translationService: TranslationService,
        private switchGroupService: SwitchGroupService,
        public ref: ChangeDetectorRef,
        private router: Router,
        private dialog: MatDialog,
        private gestioneMerciService: GestioneMerciService,
        private magazzinoService: MagazzinoService,
        private navigationService: NavigationService
    ) {

        this.utility = util;

        this.period = 'OnlyYesterday';
        this.periodTranslate = translate.instant('FILTRI.PERIOD_TYPE.ONLYYESTERDAY');
        this.whatFilterConfiguration(router.url);
        this.saveOldFilterConfig();

        window.addEventListener('resize', () => this.adaptFilterContainer());

        this.filtriServiceReadOnlyObsvSubscription = this.filtriService.readOnly$.subscribe((readOnly: boolean) => this.readOnly = readOnly);
        this.filtriServiceClearFiltersSubscription = this.filtriService.clearFilters$.subscribe((actionFilter: boolean) => {
            this.clearFilters();
            if (actionFilter === true) {
                this.actionFilter();
            }
        });

        this.filtriServiceTriggerOpenFilterSubscription = this.filtriService.triggerOpenFilter$.subscribe((open: boolean) => {
            if (open) {
                this.openFilter = true;
                this.adaptFilterContainer();

                setTimeout(() => {
                    // Blocco la propagazione
                    this.filtriService.triggerOpenFilter$.next(false);
                }, 1000);
            }
        });

        this.magazzinoServiceSubscription = this.magazzinoService.selectorChanged$.subscribe(
            (selector: any) => {
                if (!selector || (Object.keys(selector).length === 0 && selector.constructor === Object) || (selector.constructor !== Object)) {
                    return
                };
                this.statisticheSelector = selector;
            }
        )

    }

    ngOnInit() {

        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            (filterConfig: any) => {
                if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) return;
                if (filterConfig.constructor !== Object) return;
                this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

                // Se non arriva da locale, vuol dire che arriva la richiesta da una pagina
                // e devo impostare i parametri correttamente per visualizzare il summary del filtro
                if (!this.currentFilterCfg.fromLocal) this.setFiltersFromRemote();
            }
        );
        this.timeInterval = 'none';
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {

                if (this.navigationService.lastUrl == this.router.url) return;
                this.navigationService.lastUrl = this.router.url;

                this.whatFilterConfiguration(this.router.url);

                this.checkActionFilter(this.router.url);

                if ((this.GESTIONE_MERCI && this.oldFilterConfig !== 'GESTIONE_MERCI') ||
                    (this.CONTO_ECONOMICO && this.oldFilterConfig !== 'CONTO_ECONOMICO') ||
                    (this.ANALISI && !this.ANALISI_FOODCOST && this.oldFilterConfig !== 'ANALISI') ||
                    (this.ANALISI_VENDITE && this.oldFilterConfig !== 'ANALISI_VENDITE') ||
                    (this.ANALISI_PREZZI_FORNITORI && this.oldFilterConfig !== 'ANALISI_PREZZI_FORNITORI') ||
                    (this.ANALISI_VARIAZIONE_PREZZI && this.oldFilterConfig !== 'ANALISI_VARIAZIONE_PREZZI') ||
                    (this.GESTIONE_FATTURE && this.oldFilterConfig !== 'GESTIONE_FATTURE') ||
                    (this.SCADENZIARIO && this.oldFilterConfig !== 'SCADENZIARIO') ||
                    (this.ANALISI_CONSUMI && this.oldFilterConfig !== 'ANALISI_CONSUMI') ||
                    (this.GESTIONE_MAGAZZINO_ACQUISTI && this.oldFilterConfig !== 'GESTIONE_MAGAZZINO_ACQUISTI') ||
                    (this.GESTIONE_MAGAZZINO_DDT && this.oldFilterConfig !== 'GESTIONE_MAGAZZINO_DDT') ||
                    (this.GESTIONE_MAGAZZINO_TRASFERIMENTI && this.oldFilterConfig !== 'GESTIONE_MAGAZZINO_TRASFERIMENTI') ||
                    (this.GESTIONE_MAGAZZINO_INVENTARI && this.oldFilterConfig !== 'GESTIONE_MAGAZZINO_INVENTARI') ||
                    (this.GESTIONE_MAGAZZINO_SPRECHI && this.oldFilterConfig !== 'GESTIONE_MAGAZZINO_SPRECHI') ||
                    (this.GESTIONE_MAGAZZINO_GIACENZE && this.oldFilterConfig !== 'GESTIONE_MAGAZZINO_GIACENZE') ||
                    (this.GESTIONE_MAGAZZINO_STATISTICHE && this.oldFilterConfig !== 'GESTIONE_MAGAZZINO_STATISTICHE') ||
                    (this.STORICO_VENDITE && this.oldFilterConfig !== 'STORICO_VENDITE') ||
                    (this.GESTIONE_RICETTE && this.oldFilterConfig !== 'GESTIONE_RICETTE') ||
                    (this.VENDITE_MANUALI && this.oldFilterConfig !== 'VENDITE_MANUALI')
                ) {
                    this.saveOldFilterConfig();
                    this.initFilters(true);
                }
            }
        });


        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {

                    localStorage.removeItem('currentFilter');

                    this.clearFilters();

                    if (this.ANALISI || this.GESTIONE_MAGAZZINO || this.ANALISI_CONSUMI || this.STORICO_VENDITE || this.CONTO_ECONOMICO) {
                        this.categorie = [];
                        this.aziende = [];
                        this.prodotti = [];
                        this.costCenters = [];

                        this.reparti = [];
                        this.gruppi = [];
                        this.productName = '';
                        this.productCode = '';

                        (<any>$('#select2Aziende')).off('select2:select');
                        (<any>$('#select2Aziende')).off('select2:clear');
                        (<any>$('#select2Aziende')).off('select2:unselect');

                        (<any>$('#select2CostCenters')).off('select2:select');
                        (<any>$('#select2CostCenters')).off('select2:clear');
                        (<any>$('#select2CostCenters')).off('select2:unselect');

                        if (!this.ANALISI_CONSUMI) (<any>$('#select2FBType')).off('select2:select');
                        if (!this.ANALISI_CONSUMI) (<any>$('#select2FBType')).off('select2:clear');
                        if (!this.ANALISI_CONSUMI) (<any>$('#select2FBType')).off('select2:unselect');

                        (<any>$('#select2Prodotti')).off('select2:select');
                        (<any>$('#select2Prodotti')).off('select2:clear');
                        (<any>$('#select2Prodotti')).off('select2:unselect');

                        (<any>$('#select2Reparti')).off('select2:select');
                        (<any>$('#select2Reparti')).off('select2:clear');
                        (<any>$('#select2Reparti')).off('select2:unselect');

                        (<any>$('#select2Gruppi')).off('select2:select');
                        (<any>$('#select2Gruppi')).off('select2:clear');
                        (<any>$('#select2Gruppi')).off('select2:unselect');

                        (<any>$('#select2Timeslot')).off('select2:select');
                        (<any>$('#select2Timeslot')).off('select2:clear');
                        (<any>$('#select2Timeslot')).off('select2:unselect');

                        if (this.STORICO_VENDITE) {
                            (<any>$('#select2AdjustmentType')).off('select2:select');
                            (<any>$('#select2AdjustmentType')).off('select2:clear');
                            (<any>$('#select2AdjustmentType')).off('select2:unselect');

                            (<any>$('#select2exclusionTypePriceZero')).off('select2:select');
                            (<any>$('#select2exclusionTypePriceZero')).off('select2:clear');
                            (<any>$('#select2exclusionTypePriceZero')).off('select2:unselect');

                            (<any>$('#select2exclusionTypeCostZero')).off('select2:select');
                            (<any>$('#select2exclusionTypeCostZero')).off('select2:clear');
                            (<any>$('#select2exclusionTypeCostZero')).off('select2:unselect');

                            (<any>$('#select2exclusionTypeSellZero')).off('select2:select');
                            (<any>$('#select2exclusionTypeSellZero')).off('select2:clear');
                            (<any>$('#select2exclusionTypeSellZero')).off('select2:unselect');
                        }

                        if (this.ANALISI_CONSUMI) {
                            (<any>$('#select2GoodState')).off('select2:select');
                            (<any>$('#select2GoodState')).off('select2:clear');
                            (<any>$('#select2GoodState')).off('select2:unselect');
                        }

                        if (this.GESTIONE_MAGAZZINO_ACQUISTI || this.GESTIONE_MAGAZZINO_INVENTARI) {
                            (<any>$('#select2SentType')).off('select2:select');
                            (<any>$('#select2SentType')).off('select2:clear');
                            (<any>$('#select2SentType')).off('select2:unselect');
                        }

                        this.fbtypeDisabled = false;
                        this.categorieDisabled = false;
                        this.prodottiDisabled = false;

                        if (this.GESTIONE_MAGAZZINO_STATISTICHE || this.CONTO_ECONOMICO) {
                            this.period = 'year';
                        } else {
                            this.period = 'OnlyYesterday';
                        }

                        this.periodTranslate = this.translate.instant('FILTRI.PERIOD_TYPE.ONLYYESTERDAY');
                    }

                    if (this.GESTIONE_MERCI) {
                        this.enableState = undefined;
                        (<any>$('#select2StatoArticoli')).off('select2:select');
                        (<any>$('#select2StatoArticoli')).off('select2:clear');
                        (<any>$('#select2StatoArticoli')).off('select2:unselect');

                        this.visibleState = undefined;
                        (<any>$('#select2Visibilita')).off('select2:select');
                        (<any>$('#select2Visibilita')).off('select2:clear');
                        (<any>$('#select2Visibilita')).off('select2:unselect');
                    }

                    if (this.GESTIONE_RICETTE) {
                        this.categorie = [];

                        this.enableState = undefined;
                        (<any>$('#select2StatoArticoli')).off('select2:select');
                        (<any>$('#select2StatoArticoli')).off('select2:clear');
                        (<any>$('#select2StatoArticoli')).off('select2:unselect');

                        this.visibleState = undefined;
                        (<any>$('#select2Visibilita')).off('select2:select');
                        (<any>$('#select2Visibilita')).off('select2:clear');
                        (<any>$('#select2Visibilita')).off('select2:unselect');

                        this.recipeType = undefined;
                        (<any>$('#select2RecipeType')).off('select2:select');
                        (<any>$('#select2RecipeType')).off('select2:clear');
                        (<any>$('#select2RecipeType')).off('select2:unselect');

                        this.aziende = [];
                        (<any>$('#select2Aziende')).off('select2:select');
                        (<any>$('#select2Aziende')).off('select2:clear');
                        (<any>$('#select2Aziende')).off('select2:unselect');

                        this.reparti = [];
                        (<any>$('#select2Reparti')).off('select2:select');
                        (<any>$('#select2Reparti')).off('select2:clear');
                        (<any>$('#select2Reparti')).off('select2:unselect');
                    }

                    if (this.GESTIONE_MAGAZZINO_STATISTICHE) {
                        this.categorie = [];
                    }

                    this.initFilters(true, true);
                }
            }
        );

        this.filteredOptions = this.goodsOrSubrecipeNames.valueChanges
            .pipe(
                //startWith(''),
                debounceTime(500),
                //distinctUntilChanged(),
                switchMap((value: string) => this._filterGoods(value))
            );

        this.initFilters(true, true);
    }

    counter(i: number) {
        return new Array(i);
    }

    setFiltersFromRemote() {

        this.DateSelectionType = '';

        if (this.SCADENZIARIO || this.ANALISI_VARIAZIONE_PREZZI) {

            if (this.currentFilterCfg.Period) {
                this.DateSelectionType = 'Period';
                this.period = this.currentFilterCfg.Period;
                (<any>$('#select2Period')).val(this.period).trigger('change');
            } else if (this.currentFilterCfg.DateFilter && !this.currentFilterCfg.DateFilter.List) {
                this.dateStart = moment(this.currentFilterCfg.DateFilter.Start);
                this.dateEnd = moment(this.currentFilterCfg.DateFilter.End);
                this.DateSelectionType = 'Range';
            } else if (this.currentFilterCfg.DateFilter && !this.currentFilterCfg.DateFilter.List) {
                this.DateSelectionType = 'List';
                this.dateList = this.currentFilterCfg.DateFilter.List;
            }

        } else if (this.ANALISI || this.GESTIONE_MAGAZZINO || this.ANALISI_CONSUMI || this.STORICO_VENDITE || this.GESTIONE_FATTURE || this.CONTO_ECONOMICO) {

            if (!this.GESTIONE_MAGAZZINO_GIACENZE) {

                if (this.currentFilterCfg.Period) {
                    this.DateSelectionType = 'Period';
                    this.period = this.currentFilterCfg.Period;
                    (<any>$('#select2Period')).val(this.period).trigger('change');
                } else if (this.currentFilterCfg.DateFilter && !this.currentFilterCfg.DateFilter.List) {
                    this.dateStart = moment(this.currentFilterCfg.DateFilter.Start);
                    this.dateEnd = moment(this.currentFilterCfg.DateFilter.End);
                    this.DateSelectionType = 'Range';
                } else if (this.currentFilterCfg.DateFilter && this.currentFilterCfg.DateFilter.List) {
                    this.DateSelectionType = 'List';
                    this.dateList = this.currentFilterCfg.DateFilter.List;
                }
            }

            if (this.currentFilterCfg.Companies && this.currentFilterCfg.Companies.length > 0) {
                this.aziende = JSON.parse(JSON.stringify(this.currentFilterCfg.Companies));
            }

            this.costCenters = [];
            if (this.currentFilterCfg.CostCenterIds && this.currentFilterCfg.CostCenterIds.length > 0) {
                this.currentFilterCfg.CostCenterIds.forEach((id: any) => {
                    this.costCenters.push({
                        Id: id
                    });
                });
            }

            this.categorie = [];
            if (this.currentFilterCfg.CategoryIds && this.currentFilterCfg.CategoryIds.length > 0) {
                this.currentFilterCfg.CategoryIds.forEach((id: any) => {
                    const cat = this.categorieFiltrate.find((c: any) => c.Id == id);
                    this.categorie.push({
                        Id: id,
                        Name: cat ? cat.Name : ''
                    });
                });
            }

            this.prodotti = [];
            if (this.currentFilterCfg.ProductIds && this.currentFilterCfg.ProductIds.length > 0) {
                this.currentFilterCfg.ProductIds.forEach((id: any) => {
                    this.prodotti.push({
                        Id: id
                    });
                });
            }

            this.timeSlot = '';
            if (this.currentFilterCfg.TimeSlot) {
                this.timeSlot = this.currentFilterCfg.TimeSlot;
            }

            let tmpDoWRemain = this.currentFilterCfg.DayOfWeek;
            this.dayWeek = [];
            let dayWeekArraySortedDesc = JSON.parse(JSON.stringify(this.dayWeekArray));
            dayWeekArraySortedDesc.sort((a: any, b: any) => {
                return a.Value < b.Value ? 1 : (a.Value > b.Value ? -1 : 0);
            })

            dayWeekArraySortedDesc.forEach((day: any) => {
                if (day.Value <= tmpDoWRemain) {
                    this.dayWeek.push(day.Value);
                    tmpDoWRemain -= day.Value
                }
            });

            (<any>$('#select2DayWeek')).val(this.dayWeek).trigger('change');


            if (this.currentFilterCfg.FBType) {
                this.fbType = {
                    Id: this.currentFilterCfg.FBType
                }
            }

            if (this.currentFilterCfg.BestItems > 0) {
                this.bestItems = this.currentFilterCfg.BestItems;
            }

            // Se sono nella dashboard o in analisi vendite
            // controllo che non ci sia già un filtro salvato
            // const savedLocalFilter = localStorage.getItem('currentFilter') && localStorage.getItem('currentFilter').length > 0 ? JSON.parse(localStorage.getItem('currentFilter')) : undefined;
            // if (this.ANALISI && savedLocalFilter) {
            //     //console.log('savedLocalFilter', savedLocalFilter, this.categorie);

            //     if (savedLocalFilter && savedLocalFilter.Companies) {
            //         setTimeout(() => {
            //             (<any>$('#select2Aziende')).val(savedLocalFilter.Companies).trigger('change');
            //         }, 2000);
            //     }

            //     if (savedLocalFilter && savedLocalFilter.CategoryIds) {
            //         setTimeout(() => {
            //             (<any>$('#select2Categories')).val(savedLocalFilter.CategoryIds).trigger('change');
            //         }, 2000);
            //     }

            // } else if (this.ANALISI) {
            //     // Salvo il filtro in locale per poterlo richiamare successivamente
            //     localStorage.setItem('currentFilter', JSON.stringify(this.currentFilterCfg));
            // }

        }

        if (this.GESTIONE_MAGAZZINO || this.ANALISI_CONSUMI) {

            this.gruppi = [];
            if (this.currentFilterCfg.GroupIds && this.currentFilterCfg.GroupIds.length > 0) {
                this.currentFilterCfg.GroupIds.forEach((id: any) => {
                    this.gruppi.push({
                        Id: id
                    });
                });
            }

            this.reparti = [];
            if (this.currentFilterCfg.DepartmentsIds && this.currentFilterCfg.DepartmentsIds.length > 0) {
                this.currentFilterCfg.DepartmentsIds.forEach((id: any) => {
                    this.reparti.push({
                        Id: id
                    });
                });
            }

            this.productName = this.currentFilterCfg.ProductName;
            this.productCode = this.currentFilterCfg.ProductCode;
            this.showDisabled = this.currentFilterCfg.ShowDisabled;
            this.showInvisible = this.currentFilterCfg.ShowInvisible;

        }
        if (this.GESTIONE_MERCI || this.ANALISI_CONSUMI) {

            // Sfrutto il parametro già esistente di categorie sul summary,
            // anche se è categorie merci
            this.categorie = [];
            if (this.currentFilterCfg.CategoryIds && this.currentFilterCfg.CategoryIds.length > 0) {
                this.currentFilterCfg.CategoryIds.forEach((id: any) => {
                    this.categorie.push(this.staticCollections.categorieMerci$.find((m: any) => m.Id == id));
                });
            }

            if (this.currentFilterCfg.Company && this.currentFilterCfg.Company.length > 0) {
                this.aziende = [this.currentFilterCfg.Company];
            }

            if (this.currentFilterCfg.FBType) {
                this.fbType = {
                    id: this.currentFilterCfg.FBType,
                    Name: this.currentFilterCfg.FBType === 1 ? this.translate.instant('FILTRI.FB_TYPE.FOOD') : (this.currentFilterCfg.FBType === 2 ? this.translate.instant('FILTRI.FB_TYPE.BEVERAGE') : this.translate.instant('FILTRI.FB_TYPE.ALL'))
                };
            }

            //console.log('this.gruppi', this.gruppi, 'this.fornitori', this.fornitori);

        }

        if (this.ANALISI_CONSUMI) {
            this.GoodState = this.currentFilterCfg.ShowDisabled;
        }

        // if (this.grouping) {
        if (this.currentFilterCfg['Grouping']) {
            this.grouping = this.currentFilterCfg['Grouping'];
        }


        if (this.GESTIONE_RICETTE) {

            this.categorie = [];
            if (this.currentFilterCfg.CategoryIds && this.currentFilterCfg.CategoryIds.length > 0) {
                this.currentFilterCfg.CategoryIds.forEach((id: any) => {
                    this.categorie.push({
                        Id: id
                    });
                });
            }

            this.enableState = this.currentFilterCfg.enableState;
            this.visibleState = this.currentFilterCfg.visibleState;
            this.recipeType = this.currentFilterCfg.recipeType;

            if (this.currentFilterCfg.Companies && this.currentFilterCfg.Companies.length > 0) {
                this.aziende = JSON.parse(JSON.stringify(this.currentFilterCfg.Companies));
            }

            this.reparti = [];
            if (this.currentFilterCfg.DepartmentIds && this.currentFilterCfg.DepartmentIds.length > 0) {
                this.currentFilterCfg.DepartmentIds.forEach((id: any) => {
                    this.reparti.push({
                        Id: id
                    });
                });
            }

            this.goodsOrSubrecipeName = this.currentFilterCfg.GoodsOrSubrecipeName;

        }

        this.ref.detectChanges();
    }

    saveOldFilterConfig() {
        if (this.GESTIONE_MERCI) {
            this.oldFilterConfig = 'GESTIONE_MERCI';
        } else if (this.ANALISI) {
            this.oldFilterConfig = 'ANALISI';
        } else if (this.ANALISI_VENDITE) {
            this.oldFilterConfig = 'ANALISI_VENDITE';
        } else if (this.ANALISI_PREZZI_FORNITORI) {
            this.oldFilterConfig = 'ANALISI_PREZZI_FORNITORI';
        } else if (this.ANALISI_VARIAZIONE_PREZZI) {
            this.oldFilterConfig = 'ANALISI_VARIAZIONE_PREZZI';
        } else if (this.CONTO_ECONOMICO) {
            this.oldFilterConfig = 'CONTO_ECONOMICO';
        } else if (this.GESTIONE_FATTURE) {
            this.oldFilterConfig = 'GESTIONE_FATTURE';
        } else if (this.SCADENZIARIO) {
            this.oldFilterConfig = 'SCADENZIARIO';
        } else if (this.ANALISI_CONSUMI) {
            this.oldFilterConfig = 'ANALISI_CONSUMI';
        } else if (this.GESTIONE_MAGAZZINO_DDT) {
            this.oldFilterConfig = 'GESTIONE_MAGAZZINO_DDT';
        } else if (this.GESTIONE_MAGAZZINO_ACQUISTI) {
            this.oldFilterConfig = 'GESTIONE_MAGAZZINO_ACQUISTI';
        } else if (this.GESTIONE_MAGAZZINO_TRASFERIMENTI) {
            this.oldFilterConfig = 'GESTIONE_MAGAZZINO_TRASFERIMENTI';
        } else if (this.GESTIONE_MAGAZZINO_INVENTARI) {
            this.oldFilterConfig = 'GESTIONE_MAGAZZINO_INVENTARI';
        } else if (this.GESTIONE_MAGAZZINO_SPRECHI) {
            this.oldFilterConfig = 'GESTIONE_MAGAZZINO_SPRECHI';
        } else if (this.GESTIONE_MAGAZZINO_GIACENZE) {
            this.oldFilterConfig = 'GESTIONE_MAGAZZINO_GIACENZE';
        } else if (this.GESTIONE_MAGAZZINO_STATISTICHE) {
            this.oldFilterConfig = 'GESTIONE_MAGAZZINO_STATISTICHE';
        } else if (this.STORICO_VENDITE) {
            this.oldFilterConfig = 'STORICO_VENDITE';
        } else if (this.GESTIONE_RICETTE) {
            this.oldFilterConfig = 'GESTIONE_RICETTE';
        } else if (this.VENDITE_MANUALI) {
            this.oldFilterConfig = 'VENDITE_MANUALI';
        } else {
            this.oldFilterConfig = '';
        }
    }

    filtraProdotti() {
        this.readOnly = true;
        this.showSpinner = true;
        this.prodottiDisabled = true;
        let payload: any = {
            CategoryIds: []
        };
        if (this.fbType && this.fbType.Id) {
            payload['fbType'] = this.fbType.Id;
        }
        this.categorie.forEach((categoria: any) => {
            payload.CategoryIds.push(categoria);
        });

        this.prodottiFiltrati = [];
        this.prodottiService.filterProdotti(payload)
            .subscribe(
                (prodotti: any) => {
                    //console.log('prodottiFiltrati', payload, this.prodotti);
                    this.prodottiFiltrati = prodotti;
                    this.readOnly = false;
                    this.prodottiDisabled = false;
                    this.showSpinner = false;
                    this.ref.detectChanges();
                    this.refreshSelectProdotti();
                }, (error: any) => {
                    //console.log('filterProdotti', error);
                    this.readOnly = false;
                    this.prodottiDisabled = false;
                    this.showSpinner = false;
                    this.ref.detectChanges();
                    this.refreshSelectProdotti();
                }
            );
    }

    filtraCategorie() {
        //filtro le categorie
        this.readOnly = true;
        this.showSpinner = true;
        this.categorieDisabled = true;
        this.categorieProdottiService.filterCategorieProdotti(this.fbType.Id).toPromise()
            .then(
                (categorie: any) => {
                    this.categorieFiltrate = categorie;
                    this.categorieDisabled = false;
                    this.filtraProdotti();
                }, (error: any) => {
                    //////console.log('filterCategorieProdotti', error);
                    this.readOnly = false;
                    this.categorieDisabled = false;
                    this.showSpinner = false;
                    //this.ref.detectChanges();
                }
            );
    }

    async initFiltersComponents() {

        // Se ho solo uno store raggruppo il per "Date Nel Periodo" per il MainGraph
        if (this.DASHBOARD && this.staticCollections.aziende$ && this.staticCollections.aziende$.length === 1) {
            this.DateSelectionType = 'Range';
            this.dateStart = moment().subtract(7, 'days');
            this.dateEnd = moment();
            this.grouping = 'Date';
        }

        if (this.SCADENZIARIO) {
        }

        if (this.ANALISI_VARIAZIONE_PREZZI) {
        }

        if (this.ANALISI || this.GESTIONE_MAGAZZINO || this.ANALISI_CONSUMI || this.STORICO_VENDITE || this.GESTIONE_FATTURE || this.CONTO_ECONOMICO) {

            if (this.GESTIONE_MAGAZZINO_TRASFERIMENTI) {

                this.originCostCenter = undefined;
                if ((<any>$('#select2OriginCostCenter')).data('select2')) {
                    (<any>$('#select2OriginCostCenter')).select2('destroy');
                }
                (<any>$('#select2OriginCostCenter')).select2({
                    allowClear: false,
                    closeOnSelect: true,
                    minimumResultsForSearch: -1,
                    width: '100%',
                    containerCssClass: 'select2Single',
                    language: {
                        errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                        inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                        inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                        loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                        maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                        noResults: () => this.translate.instant('SELECT2.noResults'),
                        searching: () => this.translate.instant('SELECT2.searching')
                    }
                });
                (<any>$('#select2OriginCostCenter')).on('select2:select', (e: any) => {
                    this.originCostCenter = e.params.data.id;
                    this.originCostCenterTranslate = e.params.data.text;
                });
                (<any>$('#select2OriginCostCenter')).on('select2:clear', (e: any) => {
                    this.originCostCenter = undefined;
                });
            }

            if (this.ANALISI_CONSUMI) {

                this.GoodState = '';
                if ((<any>$('#select2GoodState')).data('select2')) {
                    (<any>$('#select2GoodState')).select2('destroy');
                }
                (<any>$('#select2GoodState')).select2({
                    allowClear: false,
                    closeOnSelect: true,
                    minimumResultsForSearch: -1,
                    width: '100%',
                    containerCssClass: 'select2Single',
                    language: {
                        errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                        inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                        inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                        loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                        maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                        noResults: () => this.translate.instant('SELECT2.noResults'),
                        searching: () => this.translate.instant('SELECT2.searching')
                    }
                });
                (<any>$('#select2GoodState')).on('select2:select', (e: any) => {
                    this.GoodState = e.params.data.id;
                });
                (<any>$('#select2GoodState')).on('select2:clear', (e: any) => {
                    this.GoodState = '';
                });
            }

            if (this.GESTIONE_MAGAZZINO_ACQUISTI || this.GESTIONE_MAGAZZINO_INVENTARI) {

                this.sentType = 0;
                if ((<any>$('#select2SentType')).data('select2')) {
                    (<any>$('#select2SentType')).select2('destroy');
                }
                (<any>$('#select2SentType')).select2({
                    allowClear: false,
                    closeOnSelect: true,
                    minimumResultsForSearch: -1,
                    width: '150px',
                    containerCssClass: 'select2Single',
                    language: {
                        errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                        inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                        inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                        loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                        maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                        noResults: () => this.translate.instant('SELECT2.noResults'),
                        searching: () => this.translate.instant('SELECT2.searching')
                    }
                });
                (<any>$('#select2SentType')).on('select2:select', (e: any) => {
                    this.sentType = e.params.data.id;
                });
                (<any>$('#select2SentType')).on('select2:clear', (e: any) => {
                    this.sentType = 0;
                });
            }

            /**********************************************************/
            if ((<any>$('#select2Period')).data('select2')) {
                (<any>$('#select2Period')).select2('destroy');
            }
            (<any>$('#select2Period')).select2({
                allowClear: false,
                closeOnSelect: true,
                minimumResultsForSearch: -1,
                width: '100%',
                containerCssClass: 'select2Single',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            (<any>$('#select2Period')).on('select2:select', (e: any) => {
                this.period = e.params.data.id;
                this.periodTranslate = e.params.data.text;
            });
            (<any>$('#select2Period')).on('select2:clear', (e: any) => {
                this.period = 'OnlyYesterday';
                //this.ref.detectChanges();
            });
            /**********************************************************/
            if (this.ANALISI || this.STORICO_VENDITE || this.GESTIONE_FATTURE || this.CONTO_ECONOMICO) {
                this.dayWeekArray = [
                    { Value: 1, Name: this.translate.instant('DAYSWEEK.SUNDAY') },
                    { Value: 2, Name: this.translate.instant('DAYSWEEK.MONDAY') },
                    { Value: 4, Name: this.translate.instant('DAYSWEEK.TUESDAY') },
                    { Value: 8, Name: this.translate.instant('DAYSWEEK.WEDNESDAY') },
                    { Value: 16, Name: this.translate.instant('DAYSWEEK.THURSDAY') },
                    { Value: 32, Name: this.translate.instant('DAYSWEEK.FRIDAY') },
                    { Value: 64, Name: this.translate.instant('DAYSWEEK.SATURDAY') },
                ];
                this.dayWeek = [];
                if ((<any>$('#select2DayWeek')).data('select2')) {
                    (<any>$('#select2DayWeek')).select2('destroy');
                }
                (<any>$('#select2DayWeek')).select2({
                    placeholder: this.translate.instant('FILTRI.DAYSWEEK_PLACEHOLDER'),
                    allowClear: true,
                    closeOnSelect: false,
                    multiple: true,
                    width: '100%',
                    containerCssClass: 'select2Multiple',
                    language: {
                        errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                        inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                        inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                        loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                        maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                        noResults: () => this.translate.instant('SELECT2.noResults'),
                        searching: () => this.translate.instant('SELECT2.searching')
                    }
                });
                (<any>$('#select2DayWeek')).on('select2:select', (e: any) => {
                    this.dayWeek.push({
                        Id: e.params.data.id,
                        Name: e.params.data.text
                    });
                });
                (<any>$('#select2DayWeek')).on('select2:clear', (e: any) => {
                    this.dayWeek = [];
                });
                (<any>$('#select2DayWeek')).on('select2:unselect', (e: any) => {
                    this.dayWeek = this.dayWeek.filter((dayWeek: any) => dayWeek.Id != e.params.data.id);
                    this.select2AllDayWeek = false;
                    this.ref.detectChanges();
                });
            }
            /**********************************************************/
            this.aziende = [];
            if ((<any>$('#select2Aziende')).data('select2')) {
                (<any>$('#select2Aziende')).select2('destroy');
            }
            (<any>$('#select2Aziende')).select2({
                placeholder: this.translate.instant('FILTRI.COMPANIES_PLACEHOLDER'),
                allowClear: true,
                closeOnSelect: false,
                multiple: true,
                width: '100%',
                containerCssClass: 'select2Multiple',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            (<any>$('#select2Aziende')).on('select2:select', (e: any) => {
                if (!this.aziende.includes(e.params.data.id)) this.aziende.push(e.params.data.id);
                //this.ref.detectChanges();
            });
            (<any>$('#select2Aziende')).on('select2:clear', (e: any) => {
                this.aziende = [];
                //this.ref.detectChanges();
            });
            (<any>$('#select2Aziende')).on('select2:unselect', (e: any) => {
                this.aziende = this.aziende.filter((azienda: any) => azienda.toLowerCase().trim() !== e.params.data.text.toLowerCase().trim());
                this.select2AllAziende = false;
                this.ref.detectChanges();
            });
            /**********************************************************/
            this.costCenters = [];
            if ((<any>$('#select2CostCenters')).data('select2')) {
                (<any>$('#select2CostCenters')).select2('destroy');
            }
            (<any>$('#select2CostCenters')).select2({
                placeholder: this.translate.instant('FILTRI.COSTS_CENTERS_PLACEHOLDER'),
                allowClear: true,
                closeOnSelect: false,
                multiple: true,
                width: '100%',
                containerCssClass: 'select2Multiple',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            (<any>$('#select2CostCenters')).on('select2:select', (e: any) => {
                this.costCenters.push({
                    Id: e.params.data.id,
                    Name: e.params.data.text
                });
                //this.ref.detectChanges();
            });
            (<any>$('#select2CostCenters')).on('select2:clear', (e: any) => {
                this.costCenters = [];
                //this.ref.detectChanges();
            });
            (<any>$('#select2CostCenters')).on('select2:unselect', (e: any) => {
                this.costCenters = this.costCenters.filter((costCenter: any) => costCenter.Id != e.params.data.id);
                this.select2AllCostCenters = false;
                this.ref.detectChanges();
            });
            /**********************************************************/
            if (this.ANALISI || this.GESTIONE_MAGAZZINO || this.STORICO_VENDITE) {
                this.fbType;
                if ((<any>$('#select2FBType')).data('select2')) {
                    (<any>$('#select2FBType')).select2('destroy');
                }
                (<any>$('#select2FBType')).select2({
                    allowClear: false,
                    closeOnSelect: true,
                    minimumResultsForSearch: -1,
                    width: '100%',
                    containerCssClass: 'select2Single',
                    language: {
                        errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                        inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                        inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                        loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                        maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                        noResults: () => this.translate.instant('SELECT2.noResults'),
                        searching: () => this.translate.instant('SELECT2.searching')
                    }
                });
                (<any>$('#select2FBType')).on('select2:select', (e: any) => {
                    this.fbType = {
                        Id: e.params.data.id,
                        Name: e.params.data.text
                    };
                    //////console.log('showSpinner');
                    //this.showSpinner = true;
                    this.filtraCategorie();
                    //this.ref.detectChanges();
                });
                (<any>$('#select2FBType')).on('select2:clear', (e: any) => {
                    this.fbType = undefined;
                    //this.ref.detectChanges();
                });
            }
            /**********************************************************/
            this.categorie = [];
            /**********************************************************/
            if (this.ANALISI || this.STORICO_VENDITE || this.ANALISI_CONSUMI) {
                this.prodotti = [];
                this.refreshSelectProdotti();

                if ((<any>$('#select2Timeslot')).data('select2')) {
                    (<any>$('#select2Timeslot')).select2('destroy');
                }
                (<any>$('#select2Timeslot')).select2({
                    allowClear: true,
                    closeOnSelect: true,
                    minimumResultsForSearch: -1,
                    width: '100%',
                    containerCssClass: 'select2Single',
                    language: {
                        errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                        inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                        inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                        loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                        maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                        noResults: () => this.translate.instant('SELECT2.noResults'),
                        searching: () => this.translate.instant('SELECT2.searching')
                    }
                });

                (<any>$('#select2Timeslot')).on('select2:select', (e: any) => {
                    this.timeSlot = e.params.data.text;
                });
                (<any>$('#select2Timeslot')).on('select2:clear', (e: any) => {
                    this.timeSlot = '';
                });
            }
            /**********************************************************/
            this.bestItems = 0;
        }

        if (this.ANALISI_CONSUMI || this.GESTIONE_MAGAZZINO_STATISTICHE) {

            const filter = {
                FBType: 6,
                EnableState: 'yes'
            };
            this.merci = [];
            this.reparti = [];
            if ((<any>$('#select2Reparti')).data('select2')) {
                (<any>$('#select2Reparti')).select2('destroy');
            }
            (<any>$('#select2Reparti')).select2({
                placeholder: this.translate.instant('FILTRI.REPARTO_PLACEHOLDER'),
                allowClear: true,
                closeOnSelect: false,
                multiple: true,
                width: '100%',
                containerCssClass: 'select2Multiple',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            (<any>$('#select2Reparti')).on('select2:select', (e: any) => {
                this.reparti.push({
                    Id: e.params.data.id,
                    Name: e.params.data.text
                });
                ////console.log('this.reparti', this.reparti);
                // //this.showSpinner = true;
            });
            (<any>$('#select2Reparti')).on('select2:clear', (e: any) => {
                this.reparti = [];
                //////console.log('showSpinner');
                // //this.showSpinner = true;
            });
            (<any>$('#select2Reparti')).on('select2:unselect', (e: any) => {
                this.reparti = this.reparti.filter((reparto: any) => reparto.Id != e.params.data.id);
                //////console.log('showSpinner');
                // //this.showSpinner = true;
                this.select2AllReparti = false;
                this.ref.detectChanges();
            });
            if (this.GESTIONE_MAGAZZINO_STATISTICHE) {
                /* GRUPPI*/
                this.gruppi = [];
                if ((<any>$('#select2Gruppi')).data('select2')) {
                    (<any>$('#select2Gruppi')).select2('destroy');
                }
                (<any>$('#select2Gruppi')).select2({
                    placeholder: this.translate.instant('FILTRI.GRUPPO_PLACEHOLDER'),
                    allowClear: true,
                    closeOnSelect: false,
                    multiple: true,
                    width: '100%',
                    containerCssClass: 'select2Multiple',
                    language: {
                        errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                        inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                        inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                        loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                        maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                        noResults: () => this.translate.instant('SELECT2.noResults'),
                        searching: () => this.translate.instant('SELECT2.searching')
                    }
                });
                (<any>$('#select2Gruppi')).on('select2:select', (e: any) => {
                    this.gruppi.push({
                        Id: e.params.data.id,
                        Name: e.params.data.text
                    });
                    //////console.log('showSpinner');
                    // //this.showSpinner = true;
                });
                (<any>$('#select2Gruppi')).on('select2:clear', (e: any) => {
                    this.gruppi = [];
                    //////console.log('showSpinner');
                    // //this.showSpinner = true;
                });
                (<any>$('#select2Gruppi')).on('select2:unselect', (e: any) => {
                    this.gruppi = this.gruppi.filter((gruppo: any) => gruppo.Id != e.params.data.id);
                    //////console.log('showSpinner');
                    // //this.showSpinner = true;
                    this.select2AllGruppi = false;
                    this.ref.detectChanges();
                });
            }


            this.categorie = [];
        }

        if (this.GESTIONE_MAGAZZINO) {
            this.reparti = [];
            if ((<any>$('#select2Reparti')).data('select2')) {
                (<any>$('#select2Reparti')).select2('destroy');
            }
            (<any>$('#select2Reparti')).select2({
                placeholder: this.translate.instant('FILTRI.REPARTO_PLACEHOLDER'),
                allowClear: true,
                closeOnSelect: false,
                multiple: true,
                width: '100%',
                containerCssClass: 'select2Multiple',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            (<any>$('#select2Reparti')).on('select2:select', (e: any) => {
                this.reparti.push({
                    Id: e.params.data.id,
                    Name: e.params.data.text
                });
                ////console.log('this.reparti', this.reparti);
                //this.showSpinner = true;
            });
            (<any>$('#select2Reparti')).on('select2:clear', (e: any) => {
                this.reparti = [];
                //////console.log('showSpinner');
                //this.showSpinner = true;
            });
            (<any>$('#select2Reparti')).on('select2:unselect', (e: any) => {
                this.reparti = this.reparti.filter((reparto: any) => reparto.Id != e.params.data.id);
                //////console.log('showSpinner');
                //this.showSpinner = true;
                this.select2AllReparti = false;
                this.ref.detectChanges();
            });
            /* GRUPPI*/
            this.gruppi = [];
            if ((<any>$('#select2Gruppi')).data('select2')) {
                (<any>$('#select2Gruppi')).select2('destroy');
            }
            (<any>$('#select2Gruppi')).select2({
                placeholder: this.translate.instant('FILTRI.GRUPPO_PLACEHOLDER'),
                allowClear: true,
                closeOnSelect: false,
                multiple: true,
                width: '100%',
                containerCssClass: 'select2Multiple',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            (<any>$('#select2Gruppi')).on('select2:select', (e: any) => {
                this.gruppi.push({
                    Id: e.params.data.id,
                    Name: e.params.data.text
                });
                //////console.log('showSpinner');
                //this.showSpinner = true;
            });
            (<any>$('#select2Gruppi')).on('select2:clear', (e: any) => {
                this.gruppi = [];
                //////console.log('showSpinner');
                //this.showSpinner = true;
            });
            (<any>$('#select2Gruppi')).on('select2:unselect', (e: any) => {
                this.gruppi = this.gruppi.filter((gruppo: any) => gruppo.Id != e.params.data.id);
                //////console.log('showSpinner');
                //this.showSpinner = true;
                this.select2AllGruppi = false;
                this.ref.detectChanges();
            });
            if (this.GESTIONE_MAGAZZINO_ACQUISTI) {
                this.priceEvolutionType = "1";
                this.priceEvolutionDays = 90;
            }
        }

        if (this.GESTIONE_MAGAZZINO_STATISTICHE) {
            this.categorie = [];

            this.period = 'year';
            (<any>$('#select2Period')).val(this.period).trigger('change');

            this.fornitori = [];
        }

        if (this.GESTIONE_MERCI) {
            /**********************************************************/
            this.enableState;
            if ((<any>$('#select2StatoArticoli')).data('select2')) {
                (<any>$('#select2StatoArticoli')).select2('destroy');
            }
            (<any>$('#select2StatoArticoli')).select2({
                allowClear: false,
                closeOnSelect: true,
                minimumResultsForSearch: -1,
                width: '100%',
                containerCssClass: 'select2Single',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            (<any>$('#select2StatoArticoli')).on('select2:select', (e: any) => {
                this.enableState = e.params.data.id;
            });
            (<any>$('#select2StatoArticoli')).on('select2:clear', (e: any) => {
                this.enableState = undefined;
            });
            /**********************************************************/
            this.visibleState;
            if ((<any>$('#select2Visibilita')).data('select2')) {
                (<any>$('#select2Visibilita')).select2('destroy');
            }
            (<any>$('#select2Visibilita')).select2({
                allowClear: false,
                closeOnSelect: true,
                minimumResultsForSearch: -1,
                width: '100%',
                containerCssClass: 'select2Single',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            (<any>$('#select2Visibilita')).on('select2:select', (e: any) => {
                this.visibleState = e.params.data.id;
            });
            (<any>$('#select2Visibilita')).on('select2:clear', (e: any) => {
                this.visibleState = undefined;
            });
            /**********************************************************/
            this.gruppiMerci = [];
            if ((<any>$('#select2GruppiMerci')).data('select2')) {
                (<any>$('#select2GruppiMerci')).select2('destroy');
            }
            (<any>$('#select2GruppiMerci')).select2({
                placeholder: this.translate.instant('FILTRI.GROUP_PLACEHOLDER'),
                allowClear: true,
                closeOnSelect: false,
                multiple: true,
                width: '100%',
                containerCssClass: 'select2Multiple',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            (<any>$('#select2GruppiMerci')).on('select2:select', (e: any) => {
                this.gruppiMerci.push({
                    Id: e.params.data.id,
                    Name: e.params.data.text
                });
                this.ref.detectChanges();
            });
            (<any>$('#select2GruppiMerci')).on('select2:clear', (e: any) => {
                this.gruppiMerci = [];
                this.ref.detectChanges();
            });
            (<any>$('#select2GruppiMerci')).on('select2:unselect', (e: any) => {
                this.gruppiMerci = this.gruppiMerci.filter((gruppo: any) => gruppo.Id != e.params.data.id);
                this.select2AllGruppiMerci = false;
                this.ref.detectChanges();
            });
            /**********************************************************/
            this.fornitori = [];
            this.brands = [];
        }

        if (this.GESTIONE_RICETTE) {
            this.categorie = [];
            /**********************************************************/
            this.enableState;
            if ((<any>$('#select2StatoArticoli')).data('select2')) {
                (<any>$('#select2StatoArticoli')).select2('destroy');
            }
            (<any>$('#select2StatoArticoli')).select2({
                allowClear: false,
                closeOnSelect: true,
                minimumResultsForSearch: -1,
                width: '100%',
                containerCssClass: 'select2Single',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            (<any>$('#select2StatoArticoli')).on('select2:select', (e: any) => {
                this.enableState = e.params.data.id;
                this.ref.detectChanges();
            });
            (<any>$('#select2StatoArticoli')).on('select2:clear', (e: any) => {
                this.enableState = undefined;
                this.ref.detectChanges();
            });
            /**********************************************************/
            this.visibleState;
            if ((<any>$('#select2Visibilita')).data('select2')) {
                (<any>$('#select2Visibilita')).select2('destroy');
            }
            (<any>$('#select2Visibilita')).select2({
                allowClear: false,
                closeOnSelect: true,
                minimumResultsForSearch: -1,
                width: '100%',
                containerCssClass: 'select2Single',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            (<any>$('#select2Visibilita')).on('select2:select', (e: any) => {
                this.visibleState = e.params.data.id;
                this.ref.detectChanges();
            });
            (<any>$('#select2Visibilita')).on('select2:clear', (e: any) => {
                this.visibleState = undefined;
                this.ref.detectChanges();
            });
            /**********************************************************/
            this.recipeType;
            if ((<any>$('#select2RecipeType')).data('select2')) {
                (<any>$('#select2RecipeType')).select2('destroy');
            }
            (<any>$('#select2RecipeType')).select2({
                allowClear: false,
                closeOnSelect: true,
                minimumResultsForSearch: -1,
                width: '100%',
                containerCssClass: 'select2Single',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            (<any>$('#select2RecipeType')).on('select2:select', (e: any) => {
                this.recipeType = e.params.data.id;
            });
            (<any>$('#select2RecipeType')).on('select2:clear', (e: any) => {
                this.recipeType = undefined;
            });
            /**********************************************************/
            this.aziende = [];
            if ((<any>$('#select2Aziende')).data('select2')) {
                (<any>(<any>$('#select2Aziende'))).select2('destroy');
            }
            (<any>(<any>$('#select2Aziende'))).select2({
                placeholder: this.translate.instant('FILTRI.COMPANIES_PLACEHOLDER'),
                allowClear: true,
                closeOnSelect: false,
                multiple: true,
                width: '100%',
                containerCssClass: 'select2Multiple',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            (<any>$('#select2Aziende')).on('select2:select', (e: any) => {
                if (!this.aziende.includes(e.params.data.id)) this.aziende.push(e.params.data.id);
                ////console.log(e.params.data);
                //this.ref.detectChanges();
            });
            (<any>$('#select2Aziende')).on('select2:clear', (e: any) => {
                this.aziende = [];
                //this.ref.detectChanges();
            });
            (<any>$('#select2Aziende')).on('select2:unselect', (e: any) => {
                this.aziende = this.aziende.filter((azienda: any) => azienda != e.params.data.text);
                this.select2AllAziende = false;
                this.ref.detectChanges();
            });
            /**********************************************************/

            this.reparti = [];
            if ((<any>$('#select2Reparti')).data('select2')) {
                (<any>$('#select2Reparti')).select2('destroy');
            }
            (<any>$('#select2Reparti')).select2({
                placeholder: this.translate.instant('FILTRI.REPARTO_PLACEHOLDER'),
                allowClear: true,
                closeOnSelect: false,
                multiple: true,
                width: '100%',
                containerCssClass: 'select2Multiple',
                language: {
                    errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                    inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                    inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                    loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                    maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                    noResults: () => this.translate.instant('SELECT2.noResults'),
                    searching: () => this.translate.instant('SELECT2.searching')
                }
            });
            (<any>$('#select2Reparti')).on('select2:select', (e: any) => {
                this.reparti.push({
                    Id: e.params.data.id,
                    Name: e.params.data.text
                });
                ////console.log('this.reparti', this.reparti);
                //this.showSpinner = true;
            });
            (<any>$('#select2Reparti')).on('select2:clear', (e: any) => {
                this.reparti = [];
                //////console.log('showSpinner');
                //this.showSpinner = true;
            });
            (<any>$('#select2Reparti')).on('select2:unselect', (e: any) => {
                this.reparti = this.reparti.filter((reparto: any) => reparto.Id != e.params.data.id);
                //////console.log('showSpinner');
                //this.showSpinner = true;
                this.select2AllReparti = false;
                this.ref.detectChanges();
            });
        }
    }

    hideSummary: boolean = false;

    async initFilters(initComponents: boolean = true, forceReloadStaticCollection: boolean = false) {

        await this.staticCollections.fillStaticCollection(forceReloadStaticCollection);

        if (this.ANALISI || this.STORICO_VENDITE || this.ANALISI_CONSUMI) {
            this.categorieFiltrate = this.staticCollections.categorieProdotti$;
            this.prodottiFiltrati = this.staticCollections.prodotti$;
        }
        if (this.GESTIONE_MAGAZZINO && !this.GESTIONE_MAGAZZINO_STATISTICHE) {
            this.categorieFiltrate = this.staticCollections.categorieMerci$;
        }
        if (this.GESTIONE_MERCI) {
            this.categorieMerciFiltrate = this.staticCollections.categorieMerci$;
            this.gruppiMerciFiltrati = this.staticCollections.gruppiMerci$;
            this.fornitoriFiltrati = this.staticCollections.fornitori$;

            this.enableState = 2;
            (<any>$("#select2StatoArticoli")).select2().select2("val", "2");
            this.visibleState = 1;
            (<any>$("#select2Visibilita")).select2().select2("val", "1");
        }

        if (this.GESTIONE_RICETTE) {
            this.categorieFiltrate = this.staticCollections.categorieProdotti$;
            this.enableState = 2; // Forzo a vedere anche quelle disabilitate
            (<any>$("#select2StatoArticoli")).select2().select2("val", "2");
            this.visibleState = 1;
            (<any>$("#select2Visibilita")).select2().select2("val", "1");
        }

        if (initComponents) this.initFiltersComponents();

        // Inizializzo di default il raggruppamento per Fornitore
        // Ma se è richiamata la pagina statistica da una pagina interna del magazzino allora raggruppo per Company
        if (this.GESTIONE_MAGAZZINO_STATISTICHE) {

            if (this.router.url.includes('?entity=')) {
                console.log(this.router.url, this.router.url.includes('?entity=Purchase'));
                this.grouping = this.router.url.includes('?entity=Purchase') ? 'Supplier' : 'Company';
                this.period = 'year';
            } else {
                this.grouping = 'Supplier';
            }

            this.gruppiMerciFiltrati = this.staticCollections.gruppiMerci$;
            this.fornitoriFiltrati = this.staticCollections.fornitori$;
        }

        this.checkActionFilter(this.router.url);

    }

    checkActionFilter(url: string) {
        // Verifico Se è presente ul parametro noLoad, 
        // così blocco l'inizalizzazione del filtro
        // Questo serve perchè a volte passo da una pagina all'altra
        // e non voglio che venga inizializzato un filtro di default
        // altrimenti mi sovrascrive i filtri che ho già impostato
        const urlSplit = url.split('?');
        const queryParams = urlSplit.length > 1 ? urlSplit[1].split('&') : [];
        const queryParamsObj: any = {};
        queryParams.forEach((param: any) => {
            const split = param.split('=');
            queryParamsObj[split[0]] = split[1];
        });

        // Triggero la scelta del filtro, così invio a tutti la configurazione di partenza.
        // Se non sono in una di queste pagine lancio subito l'api per richiedere i dati
        if (
            (!this.GESTIONE_MAGAZZINO || this.GESTIONE_MAGAZZINO_STATISTICHE)
            && !this.GESTIONE_FATTURE
            && !this.GESTIONE_MERCI
            && !this.GESTIONE_RICETTE
            && !this.STORICO_VENDITE
            && !this.ANALISI_FOODCOST
            && !this.ANALISI_CONSUMI
            && !this.GESTIONE_RICETTE
            && !this.VENDITE_MANUALI
            && (!queryParamsObj['noLoad'] || queryParamsObj['noLoad'] !== 'true')) {
            this.actionFilter(true);
        }
    }

    ngOnDestroy() {
        // cancello il filtro
        this.filtriService.filterConfig = {};
        this.filtriService.performFilter$.next({});

        this.switchGroupSubcription.unsubscribe();
        this.filtriServiceSubscription.unsubscribe();
        this.filtriServiceReadOnlyObsvSubscription.unsubscribe();
        this.filtriServiceClearFiltersSubscription.unsubscribe();
        this.filtriServiceTriggerOpenFilterSubscription.unsubscribe();
        this.magazzinoServiceSubscription.unsubscribe();

    }

    whatFilterConfiguration(url: string) {
        this.DASHBOARD = url.includes('dashboard') && !url.includes('amministrazione/dashboard');
        this.GESTIONE_MERCI = url.includes('gestione-merci');
        this.ANALISI = (url.includes('analisi') || (url.includes('dashboard') && !url.includes('conto-economico'))) && !url.includes('analisi-consumi') && !url.includes('analisi-prezzi-fornitori') && !url.includes('variazione-prezzi');
        this.ANALISI_VENDITE = url.includes('analisi-vendite');
        this.ANALISI_PREZZI_FORNITORI = url.includes('analisi-prezzi-fornitori');
        this.ANALISI_VARIAZIONE_PREZZI = url.includes('variazione-prezzi');
        this.CONTO_ECONOMICO = url.includes('conto-economico');
        this.GESTIONE_FATTURE = url.includes('gestione-fatture');
        this.SCADENZIARIO = url.includes('scadenziario');
        this.ANALISI_CONSUMI = url.includes('analisi-consumi');
        this.ANALISI_FOODCOST = url.includes('analisi-foodcost');
        this.GESTIONE_MAGAZZINO = url.includes('magazzino/acquisti') ||
            url.includes('magazzino/ordini') ||
            url.includes('magazzino/ddt') ||
            url.includes('magazzino/trasferimenti') ||
            url.includes('magazzino/sprechi') ||
            url.includes('magazzino/inventari') ||
            url.includes('magazzino/giacenze') ||
            url.includes('magazzino/statistiche');
        this.GESTIONE_MAGAZZINO_ORDINI = url.includes('magazzino/ordini');
        this.GESTIONE_MAGAZZINO_DDT = url.includes('magazzino/ddt');
        this.GESTIONE_MAGAZZINO_ACQUISTI = url.includes('magazzino/acquisti');
        this.GESTIONE_MAGAZZINO_TRASFERIMENTI = url.includes('magazzino/trasferimenti');
        this.GESTIONE_MAGAZZINO_INVENTARI = url.includes('magazzino/inventari');
        this.GESTIONE_MAGAZZINO_SPRECHI = url.includes('magazzino/sprechi');
        this.GESTIONE_MAGAZZINO_GIACENZE = url.includes('magazzino/giacenze');
        this.GESTIONE_MAGAZZINO_STATISTICHE = url.includes('magazzino/statistiche');
        this.STORICO_VENDITE = url.includes('storico-vendite');
        this.GESTIONE_RICETTE = url.includes('gestione-ricette');
        this.VENDITE_MANUALI = url.includes('vendite-manuali') || url.includes('prodotti-finiti') || url.includes('rettifiche-vendita') || url.includes('inventario');
    }

    /**
     * 
     * @param init => serve per sapere se richiamo la actionFilter in fase di inizializzazione e non di modifica
     */
    actionFilter(init: boolean = false) {
        // Se non esiste ancora lo inizializzo
        // altrimenti tengo traccia di quello che c'è già dentro perchè magari
        // alcuni filtri vengono fatti in pagina locale e non quì
        if (!this.currentFilterCfg) this.currentFilterCfg = {};

        if (this.SCADENZIARIO) {
            if (this.DateSelectionType === 'Period') {
                delete this.currentFilterCfg.DateFilter;
                this.currentFilterCfg.Period = this.period;
            } else if (this.DateSelectionType === 'Range') {
                delete this.currentFilterCfg.Period;
                this.currentFilterCfg.DateFilter = {
                    Start: this.dateStart.format('YYYY-MM-DD'),
                    End: this.dateEnd.format('YYYY-MM-DD')
                };
            } else if (this.DateSelectionType === 'List') {
                delete this.currentFilterCfg.Period;
                delete this.currentFilterCfg.DateFilter;
                this.currentFilterCfg.DateFilter = {
                    List: this.dateList,
                };
            }
        }

        if (this.ANALISI_VARIAZIONE_PREZZI) {
            if (this.DateSelectionType === 'Period') {
                delete this.currentFilterCfg.DateFilter;
                this.currentFilterCfg.Period = this.period;
            } else if (this.DateSelectionType === 'Range') {
                delete this.currentFilterCfg.Period;
                this.currentFilterCfg.DateFilter = {
                    Start: this.dateStart.format('YYYY-MM-DD'),
                    End: this.dateEnd.format('YYYY-MM-DD')
                };
            } else if (this.DateSelectionType === 'List') {
                delete this.currentFilterCfg.Period;
                delete this.currentFilterCfg.DateFilter;
                this.currentFilterCfg.DateFilter = {
                    List: this.dateList,
                };
            }
        }

        if (this.ANALISI || this.GESTIONE_MAGAZZINO || this.ANALISI_CONSUMI || this.STORICO_VENDITE || this.GESTIONE_FATTURE || this.CONTO_ECONOMICO) {

            if (!this.GESTIONE_MAGAZZINO_GIACENZE) {
                if (this.DateSelectionType === 'Period') {
                    delete this.currentFilterCfg.DateFilter;
                    this.currentFilterCfg.Period = this.period;
                } else if (this.DateSelectionType === 'Range') {
                    delete this.currentFilterCfg.Period;
                    this.currentFilterCfg.DateFilter = {
                        Start: this.dateStart.format('YYYY-MM-DD'),
                        End: this.dateEnd.format('YYYY-MM-DD')
                    };
                } else if (this.DateSelectionType === 'List') {
                    delete this.currentFilterCfg.Period;
                    delete this.currentFilterCfg.DateFilter;
                    this.currentFilterCfg.DateFilter = {
                        List: this.dateList,
                    };
                }
            }

            if (this.aziende && this.aziende.length > 0) {
                this.currentFilterCfg.Companies = [];
                this.aziende.forEach((azienda: any) => {
                    this.currentFilterCfg.Companies.push(azienda);
                });
            }

            if (this.costCenters && this.costCenters.length > 0) {
                this.currentFilterCfg.CostCenterIds = [];
                this.costCenters.forEach((costCenter: any) => {
                    this.currentFilterCfg.CostCenterIds.push(costCenter.Id);
                });
            }

            if (this.categorie && this.categorie.length > 0) {
                this.currentFilterCfg.CategoryIds = [];
                this.categorie.forEach((categoria: any) => {
                    this.currentFilterCfg.CategoryIds.push(categoria);
                });
            };

            if (this.prodotti && this.prodotti.length > 0) {
                this.currentFilterCfg.ProductIds = [];
                this.prodotti.forEach((prodotto: any) => {
                    this.currentFilterCfg.ProductIds.push(prodotto.Id);
                });
            };

            if (this.timeSlot) {
                this.currentFilterCfg.TimeSlot = this.timeSlot;
            }

            if (this.timeInterval) {
                this.currentFilterCfg.TimeInterval = this.timeInterval;
                if (this.timeInterval === 'custom') {
                    this.currentFilterCfg.FromHour = this.fromH;
                    this.currentFilterCfg.ToHour = this.toH;
                }
            }
            else {
                this.currentFilterCfg.TimeInterval = 'none'
            }

            this.currentFilterCfg.DayOfWeek = 0;
            if (this.dayWeek.length > 0) {
                this.dayWeek.forEach((day: any) => {
                    this.currentFilterCfg.DayOfWeek += parseInt(day.Id);
                });
            }

            if (this.fbType) {
                this.currentFilterCfg.FBType = this.fbType.Id;
            }

            if (this.bestItems > 0) {
                this.currentFilterCfg.BestItems = this.bestItems;
            }

            // Se sono nella dashboard o in analisi vendite
            // controllo che non ci sia già un filtro salvato
            // if (this.ANALISI && init) {
            //     const savedLocalFilter = localStorage.getItem('currentFilter') && localStorage.getItem('currentFilter').length > 0 ? JSON.parse(localStorage.getItem('currentFilter')) : undefined;
            //     // //console.log('savedLocalFilter', savedLocalFilter);

            //     // Se c'è qualcosa di salvato allora lo prendo.
            //     this.currentFilterCfg = savedLocalFilter ? savedLocalFilter : this.currentFilterCfg;

            // } else if (this.ANALISI) {
            //     // Salvo il filtro in locale per poterlo richiamare successivamente
            //     localStorage.setItem('currentFilter', JSON.stringify(this.currentFilterCfg));
            // }

        }

        if (this.GESTIONE_MAGAZZINO || this.ANALISI_CONSUMI) {
            if (this.gruppi.length > 0) {
                this.currentFilterCfg.GroupIds = [];
                this.gruppi.forEach((gruppo: any) => {
                    this.currentFilterCfg.GroupIds.push(gruppo.Id);
                });
            };

            if (this.reparti.length > 0) {
                this.currentFilterCfg.DepartmentsIds = [];
                this.reparti.forEach((reparto: any) => {
                    this.currentFilterCfg.DepartmentsIds.push(reparto.Id);
                });
            };

            if (this.productName && this.productName.length > 0) {
                this.currentFilterCfg.ProductName = this.productName;
            };

            if (this.productCode && this.productCode.length > 0) {
                this.currentFilterCfg.ProductCode = this.productCode;
            };

            this.currentFilterCfg.ShowDisabled = this.showDisabled;
            this.currentFilterCfg.ShowInvisible = this.showInvisible;
        }

        if (this.GESTIONE_MERCI || this.ANALISI_CONSUMI) {
            if (this.categorieMerci.length > 0) {
                this.currentFilterCfg.CategoryIds = [];
                this.categorieMerci.forEach((categoria: any) => {
                    this.currentFilterCfg.CategoryIds.push(categoria.Id);
                });
            };
            if (this.gruppiMerci && this.gruppiMerci.length > 0) {
                this.currentFilterCfg.GroupIds = [];
                this.gruppiMerci.forEach((gruppo: any) => {
                    this.currentFilterCfg.GroupIds.push(gruppo.Id);
                });
            };
            if (this.fornitori && this.fornitori.length > 0) {
                this.currentFilterCfg.SupplierIds = [];
                this.fornitori.forEach((fornitore: any) => {
                    this.currentFilterCfg.SupplierIds.push(fornitore.Id);
                });
            };

            if (this.brands && this.brands.length > 0) {
                this.currentFilterCfg.Brands = [];
                this.brands.forEach((brands: any) => {
                    this.currentFilterCfg.Brands.push(brands.Id);
                });
            };

            if (this.enableState) {
                this.currentFilterCfg['EnableState'] = this.enableState;
            }

            if (this.visibleState) {
                this.currentFilterCfg['VisibleState'] = this.visibleState;
            }
        }

        if (this.ANALISI_CONSUMI) {
            if (this.GoodState) {
                this.currentFilterCfg.ShowDisabled = this.GoodState;
            }

            if (this.merci && this.merci.length) {
                this.currentFilterCfg['ProductIds'] = [];
                this.merci.forEach((merce: any) => {
                    this.currentFilterCfg['ProductIds'].push(merce);
                });
            }
        }

        if (this.GESTIONE_RICETTE) {

            if (this.categorie && this.categorie.length > 0) {
                this.currentFilterCfg.CategoryIds = [];
                this.categorie.forEach((categoria: any) => {
                    this.currentFilterCfg.CategoryIds.push(categoria);
                });
            };

            if (this.enableState) {
                this.currentFilterCfg['EnableState'] = this.enableState;
            }

            if (this.visibleState) {
                this.currentFilterCfg['VisibleState'] = this.visibleState;
            }

            if (this.recipeType) {
                this.currentFilterCfg['Type'] = this.recipeType;
            }

            if (this.aziende && this.aziende.length > 0) {
                this.currentFilterCfg.Companies = [];
                this.aziende.forEach((azienda: any) => {
                    this.currentFilterCfg.Companies.push(azienda);
                });
            }

            if (this.reparti.length > 0) {
                this.currentFilterCfg.DepartmentIds = [];
                this.reparti.forEach((reparto: any) => {
                    this.currentFilterCfg.DepartmentIds.push(reparto.Id);
                });
            };
        }

        if (this.GESTIONE_MAGAZZINO_STATISTICHE) {
            if (this.merci && this.merci.length) {
                this.currentFilterCfg['ProductIds'] = [];
                this.merci.forEach((merce: any) => {
                    this.currentFilterCfg['ProductIds'].push(merce);
                });
            }
            if (this.fornitori && this.fornitori.length > 0) {
                this.currentFilterCfg.SupplierIds = [];
                this.fornitori.forEach((fornitore: any) => {
                    this.currentFilterCfg.SupplierIds.push(fornitore.Id);
                });
            };
        }

        if (this.grouping) {
            this.currentFilterCfg['Grouping'] = this.grouping;
        }

        /**
         * Se non aririva la richiesta da una pagina esterna evito 
         * di processare questa richiesta dal setFiltersFromRemote().
         * 
         * Se sono in dashboard o p.mix e ho un filtro salvato allora invece si!
         */
        this.currentFilterCfg.fromLocal = !this.ANALISI || !init;

        // Invio il filtro
        this.filtriService.performFilter$.next(this.currentFilterCfg);

        this.openFilter = false;
    }

    getTooltips(list: any[]) {
        let ret: string = '';
        list.forEach((item: any) => {
            if (item) {
                if (ret && ret.length > 0) ret += ', ';
                ret += item.Name ? item.Name : item;
            };
        });
        return ret;
    }

    clearFilters() {
        this.currentFilterCfg = {};
        if (this.GESTIONE_MAGAZZINO_STATISTICHE) {
            this.period = 'year';

            this.merci = [];
            this.fornitori = [];
            this.categorie = [];

        } else if (this.CONTO_ECONOMICO) {
            this.period = 'year';
        } else if (this.GESTIONE_FATTURE || this.ANALISI_VARIAZIONE_PREZZI) {
            this.period = 'YTD';
        } else if (this.SCADENZIARIO) {
            this.period = 'START_SCHEDULE';
        } else {
            this.period = 'OnlyYesterday';
        }
        this.periodTranslate = this.translate.instant('FILTRI.PERIOD_TYPE.ONLYYESTERDAY');
        this.DateSelectionType = 'Period';

        if (this.GESTIONE_RICETTE || this.GESTIONE_MERCI) {
            this.DateSelectionType = '';
        }

        this.categorie = [];
        this.aziende = [];
        this.prodotti = [];
        this.costCenters = [];
        this.dayWeek = [];
        this.fornitori = [];

        this.dateStart = undefined;
        this.dateEnd = undefined;
        this.fbType = undefined;
        this.timeSlot = '';
        this.bestItems = 0;

        this.timeInterval = 'none';

        (<any>$('#select2Period')).val('OnlyYesterday').trigger('change');

        if (this.ANALISI || this.GESTIONE_MAGAZZINO || this.ANALISI_CONSUMI || this.STORICO_VENDITE || this.GESTIONE_FATTURE || this.CONTO_ECONOMICO) {
            (<any>$('#select2Aziende')).val('').trigger('change');
            (<any>$('#select2CostCenters')).val('').trigger('change');
            if (!this.ANALISI_CONSUMI) (<any>$('#select2FBType')).val('').trigger('change');
        }

        if (this.ANALISI || this.ANALISI_CONSUMI || this.STORICO_VENDITE) {
            (<any>$('#select2Prodotti')).val('').trigger('change');
            (<any>$('#select2Timeslot')).val('').trigger('change');
        }

        if (this.GESTIONE_MAGAZZINO || this.ANALISI_CONSUMI) {
            (<any>$('#select2Reparti')).val('').trigger('change');
            (<any>$('#select2Gruppi')).val('').trigger('change');
        } else {
            (<any>$('#select2DayWeek')).val('').trigger('change');
        }

        if (this.ANALISI_CONSUMI) {
            (<any>$('#select2GoodState')).val('').trigger('change');
        }

        /*
        if (this.STORICO_VENDITE) {
            (<any>$('#select2AdjustmentType')).val(-1).trigger('change');
            (<any>$('#select2exclusionTypePriceZero')).val(0).trigger('change');
            (<any>$('#select2exclusionTypeCostZero')).val(0).trigger('change');
            (<any>$('#select2exclusionTypeSellZero')).val(0).trigger('change');
        }
        */

        if (this.GESTIONE_MAGAZZINO_ACQUISTI || this.GESTIONE_MAGAZZINO_INVENTARI) {
            (<any>$('#select2SentType')).val(0).trigger('change');
            //$('#select2Fornitori').val('').trigger('change');
        }

        if (this.GESTIONE_MAGAZZINO_TRASFERIMENTI) {
            (<any>$('#select2OriginCostCenter')).val('').trigger('change');
        }

        // TOLTO perchè compariva onvunque nei filtri anche quando non serviva
        //if (!this.ANALISI) {
        //    this.enableState = 2;
        //    this.visibleState = 1;
        //}

        if (this.GESTIONE_MERCI) {
            (<any>$('#select2GruppiMerci')).val('').trigger('change');
            this.enableState = 2;
            this.visibleState = 1;
            this.categorieMerci = [];
            this.gruppiMerci = [];
            (<any>$('#select2StatoArticoli')).val(2).trigger('change');
            (<any>$('#select2Visibilita')).val(1).trigger('change');
        }

        this.recipeType = undefined;
        this.goodsOrSubrecipeName = '';
        if (this.GESTIONE_RICETTE) {
            this.enableState = 2;
            this.visibleState = 1;
            setTimeout(() => {
                (<any>$('#select2Aziende')).val('').trigger('change');
                (<any>$('#select2Reparti')).val('').trigger('change');

                (<any>$('#select2StatoArticoli')).val(2).trigger('change');
                (<any>$('#select2Visibilita')).val(3).trigger('change');
                (<any>$('#select2RecipeType')).val('').trigger('change');
            }, 1000);
        }

    }

    adaptFilterContainer() {
        var quantoTogliere = (<any>$('#kt_aside')).width();
        (<any>$('#filtriContainer')).css('width', `calc(100vw - ${quantoTogliere}px)`);
    }

    removeTime(date: string) {
        const dateInfo = date.split(',');
        return dateInfo[0];
    }

    getPeriodDetails() {
        const today = new Date(new Date().setHours(0, 0, 0, 0));

        switch (this.period.toLowerCase()) {
            case 'onlyyesterday':
                return this.translate.instant('FILTRI.PERIOD_TYPE.ONLYYESTERDAY');
                break;
            case 'yesterday':
                return this.translate.instant('FILTRI.PERIOD_TYPE.YESTERDAY');
                break;
            case 'ytd':
                let ytd = new Date();
                ytd.setDate(today.getDate() - 366);
                return this.removeTime(ytd.toLocaleString(this.translationService.getSelectedLanguage()));
                break;
            case 'mtd':
                let mtd = new Date();
                mtd.setDate(today.getDate() - 30);
                return this.removeTime(mtd.toLocaleString(this.translationService.getSelectedLanguage()));
                break;
            case 'year':
                let yStart = new Date(today.getFullYear(), 0, 1);
                let yEnd = new Date();
                yEnd.setDate(today.getDate() - 1);
                return `${this.removeTime(yStart.toLocaleString(this.translationService.getSelectedLanguage()))} - ${this.removeTime(yEnd.toLocaleString(this.translationService.getSelectedLanguage()))}`
                break;
            case 'month':
                let mStart = new Date(today.getFullYear(), today.getMonth(), 1);
                let mEnd = new Date();
                mEnd.setDate(today.getDate() - 1);
                return `${this.removeTime(mStart.toLocaleString(this.translationService.getSelectedLanguage()))} - ${this.removeTime(mEnd.toLocaleString(this.translationService.getSelectedLanguage()))}`
                break;
            case 'week':
                const first = today.getDate() - today.getDay() + 1;
                let wStart = new Date(new Date().setDate(first));
                let wEnd = new Date();
                wEnd.setDate(new Date().getDate() - 1);
                return `${this.removeTime(wStart.toLocaleString(this.translationService.getSelectedLanguage()))} - ${this.removeTime(wEnd.toLocaleString(this.translationService.getSelectedLanguage()))}`
                break;
            case 'day':
                return `${this.removeTime(today.toLocaleString(this.translationService.getSelectedLanguage()))}`
                break;
        }

        return '';
    }

    selectMonth() {
        const dialogRef = this.dialog.open(MonthsSelectorComponent, { data: {}, width: '980px' });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            this.DateSelectionType = 'Range';
            this.dateStart = res.dateStart;
            this.dateEnd = res.dateEnd;
        });
    }

    refreshSelectProdotti() {
        if ((<any>$('#select2Prodotti')).data('select2')) {
            (<any>$('#select2Prodotti')).off('select2:select');
            (<any>$('#select2Prodotti')).off('select2:clear');
            (<any>$('#select2Prodotti')).off('select2:opening');
            (<any>$('#select2Prodotti')).select2('destroy');
        }
        (<any>$('#select2Prodotti')).select2({
            placeholder: this.translate.instant('FILTRI.PRODUCTS_PLACEHOLDER'),
            allowClear: true,
            closeOnSelect: false,
            multiple: true,
            width: '100%',
            containerCssClass: 'select2Multiple',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });

        (<any>$('#select2Prodotti')).on('select2:select', (e: any) => {
            //console.log('select2Prodotti select');
            this.prodotti.push({
                Id: e.params.data.id,
                Name: e.params.data.text
            });
            // Se non ho selezionato nessuna categoria ma ho già selezionato i prodotti allora disabilito le categorie.
            this.categorieDisabled = !this.categorie || this.categorie.length === 0;
            //this.ref.detectChanges();
        });
        (<any>$('#select2Prodotti')).on('select2:clear', (e: any) => {
            //console.log('select2Prodotti clear');
            this.prodotti = [];
            this.categorieDisabled = false;
            //this.ref.detectChanges();
        });
        (<any>$('#select2Prodotti')).on('select2:unselect', (e: any) => {
            //console.log('select2Prodotti unselect');
            this.prodotti = this.prodotti.filter((prodotto: any) => prodotto.Id != e.params.data.id);
            if (this.prodotti.length == 0) this.categorieDisabled = false;
            this.select2AllProdotti = false;
            this.ref.detectChanges();
        });
    }

    performFilterPeriod(period: string) {
        if (!this.currentFilterCfg) {
            this.currentFilterCfg = {};
        }
        delete this.currentFilterCfg.DateFilter;
        this.currentFilterCfg.Period = period;
        this.currentFilterCfg.fromLocal = false;

        if (this.currentFilterCfg.Period) {
            this.periodTranslate = this.translate.instant(`FILTRI.PERIOD_TYPE.${period.toUpperCase()}`);
        }

        this.filtriService.performFilter$.next(this.currentFilterCfg);
    }

    performResetFilters() {
        this.clearFilters();
        this.actionFilter();
    }

    performSelectMonth() {
        const dialogRef = this.dialog.open(MonthsSelectorComponent, { data: {}, width: '980px' });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            delete this.currentFilterCfg.Period;
            this.currentFilterCfg.DateFilter = {
                Start: moment(res.dateStart).format('YYYY-MM-DD'),
                End: moment(res.dateEnd).format('YYYY-MM-DD')
            };
            this.currentFilterCfg.fromLocal = false;
            this.filtriService.performFilter$.next(this.currentFilterCfg);

        });
    }

    performCustomDateRange(dateRange: any) {
        if (!this.currentFilterCfg) this.currentFilterCfg = {};
        this.dateStart = dateRange.startDate;
        this.dateEnd = dateRange.endDate;
        this.currentFilterCfg.DateFilter = {
            Start: this.dateStart.format('YYYY-MM-DD'),
            End: this.dateEnd.format('YYYY-MM-DD')
        };

        delete this.currentFilterCfg.Period;
        this.currentFilterCfg.fromLocal = false;
        this.filtriService.performFilter$.next(this.currentFilterCfg);
    }

    performSelectedWeeks(weeks: any) {
        if (!this.currentFilterCfg) this.currentFilterCfg = {};
        this.currentFilterCfg.DateFilter = {
            Start: weeks.startDate.format('YYYY-MM-DD'),
            End: weeks.endDate.format('YYYY-MM-DD')
        };
        delete this.currentFilterCfg.Period;
        this.currentFilterCfg.fromLocal = false;
        this.filtriService.performFilter$.next(this.currentFilterCfg);
    }

    performSelectedSpecificDates(dates: any) {
        if (!this.currentFilterCfg) this.currentFilterCfg = {};
        this.currentFilterCfg.DateFilter = {
            List: dates.list.map((date: any) => moment(date).format('YYYY-MM-DD'))
        };
        delete this.currentFilterCfg.Period;
        this.currentFilterCfg.fromLocal = false;
        this.filtriService.performFilter$.next(this.currentFilterCfg);
    }

    ckeckResetFilter(event: KeyboardEvent) {
        if ((event.code === 'Enter' || event.code == 'NumpadEnter') && !this.goodsOrSubrecipeNames.value) {
            this.autoComplete.closePanel();
        }
    }

    private _filterGoods(value: any) {
        let filterValue = '';
        if (value.constructor === String) {
            filterValue = value.toLowerCase();
        } else if (value.constructor === Object) {
            filterValue = value.Name ? value.Name.toLowerCase() : '';
        }

        const payload = {
            Name: filterValue
        };

        if (this.categorie && this.categorie.length > 0) {
            payload['CategoryIds'] = this.categorie;
        }
        if (this.fornitori && this.fornitori.length > 0) {
            payload['SupplierIds'] = this.fornitori.map((sup: any) => sup.Id);
        }

        return this.gestioneMerciService.getGoods(payload);
    }

    selectElement(event: any) {
        this.goodsOrSubrecipeNames.setValue(event.option.value);
        this.merci = event ? [event.option.value.Id] : [];
    }

    displayFn(product: any): string {
        if (!product) return '';
        return product.Name.trim();
    }

    onSelectCategory() {
        // Se non ho selezionato fbType ma ho già selezionato le categorie allora disabilito fbType.
        this.fbtypeDisabled = !this.fbType || !this.fbType.Id;

        this.showSpinner = true;
        if (this.ANALISI || this.STORICO_VENDITE) this.filtraProdotti();
    }
}
