import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';

declare var $: any;

@Component({
	selector: 'm-export-pdf-sheet-dialog',
	templateUrl: './export-pdf-sheet-dialog.component.html'
})
export class ExportPdfSheetDialogComponent implements OnInit {
	viewLoading: boolean = false;
	selectedOrderMode: any;
	selectedCompany: any;
	selectedLayout: any;
	categorie: any;

	constructor(
		public dialogRef: MatDialogRef<ExportPdfSheetDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private translate: TranslateService,
		public staticCollections: StaticCollectionsService
	) { }

	ngOnInit() {
		this.selectedOrderMode = 'Name';
		this.selectedCompany = '-';
		this.selectedLayout = '1';

		this.categorie = [];
		if ($('#select2Categories').data('select2')) {
			(<any>$('#select2Categories')).select2('destroy');
		}
		(<any>$('#select2Categories')).select2({
			placeholder: this.translate.instant('FILTRI.CATEGORIES_PLACEHOLDER'),
			allowClear: true,
			closeOnSelect: false,
			multiple: true,
			width: '100%',
			containerCssClass: 'select2Multiple',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
		});
		$('#select2Categories').on('select2:select', (e: any) => {
			this.categorie.push(e.params.data.id);
		});
		$('#select2Categories').on('select2:clear', (e: any) => {
			this.categorie = [];
		});
		$('#select2Categories').on('select2:unselect', (e: any) => {
			this.categorie = this.categorie.filter((categoria: any) => categoria != e.params.data.id);
		});
	}

	prepareResponse(confirm: boolean) {
		let response: any = {
			confirm: confirm,
			orderMode: this.selectedOrderMode,
			company: this.selectedCompany,
			layout: this.selectedLayout,
			categoryIds: this.categorie
		};
		return response;
	}

	onYesClick(): void {
		this.viewLoading = true;
		this.dialogRef.close(this.prepareResponse(true));
	}

	onCloseClick(): void {
		this.dialogRef.close(undefined);
	}
}
