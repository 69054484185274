import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

@Injectable({
  providedIn: 'root'
})
export class GestioneFattureService {

    constructor(
        private http: HttpClient,
        private switchGroupService: SwitchGroupService
      ) { }
    
      getInvoices(totalRows: number): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
    
        const httpHeaders = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + currentLogin.access_token
        });
    
        return this.http.post<any>(currentLogin.endpointURL + '/api/Fe/ImportedFeList', {
            length: totalRows,
            start: 0
        }, { headers: httpHeaders });
      }

      deleteInvoice(fileName: string): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
    
        const httpHeaders = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + currentLogin.access_token
        });
    
        return this.http.delete<any>(currentLogin.endpointURL + `/api/Fe/Delete?fileName=${fileName}`, { headers: httpHeaders });
      }


      deleteInvoices(fileNames: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
    
        const httpHeaders = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + currentLogin.access_token
        });

        let fileNamesUrl = '';
        fileNames.forEach((fileName: string, index: number) => {
            if(index>0) {
                fileNamesUrl += `&`;    
            }
            fileNamesUrl += `fileName=${fileName}`;
        });
    
        return this.http.delete<any>(currentLogin.endpointURL + `/api/Fe/Delete?${fileNamesUrl}`, { headers: httpHeaders });
      }

      deleteBulkInvoices(invoices: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
    
        const httpHeaders = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + currentLogin.access_token
        });
    
        return this.http.post<any>(currentLogin.endpointURL + `/api/Fe/Delete`, invoices, { headers: httpHeaders });
      }

      reverseInvoices(invoices: any): Observable<any> {
        const currentLogin = this.switchGroupService.getCurrentGroup();
    
        const httpHeaders = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + currentLogin.access_token
        });
    
        return this.http.post<any>(currentLogin.endpointURL + `/api/Fe/Reverse`, invoices.fileNames, { headers: httpHeaders });
      }

}
