// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Subscription, lastValueFrom, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// Services
import { EndpointsService } from '@app/core/services/endpoints.service';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'kt-users-edit',
    templateUrl: './users-edit.component.html',
    styleUrls: ['./users-edit.component.scss']
})
export class UsersEditComponent implements OnInit {


    // Public properties
    user: any;
    endpoint: any;
    endpoints: any;
    userForm!: FormGroup;
    hasFormErrors: boolean = false;
    viewLoading: boolean = false;
    selectedRole: string = 'User';
    cloneMode: boolean = false;
    editMode: boolean = false;

    // Private properties
    private componentSubscriptions!: Subscription;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private endpointsService: EndpointsService,
        private switchGroupService: SwitchGroupService,
        private translate: TranslateService
    ) { }

    /**
       * On init
       */
    ngOnInit() {
        this.user = this.data.user;
        this.endpoint = this.data.endpoint;
        this.cloneMode = this.data.action === 'duplicate';
        this.editMode = this.data.action === 'edit';
        this.selectedRole = 'User';
        this.createForm();
    }

    /**
   * On destroy
   */
    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    /**
     * Close alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    createForm() {
        this.userForm = this.fb.group({
            Email: [this.cloneMode ? '' : this.user.Email, Validators.compose([Validators.required, Validators.email])],
            FullName: [this.user.FullName, Validators.required],
        });

        // set disabled email is in edit mode
        if (this.editMode) {
            this.userForm.controls['Email'].disable();
            $('#select2RoleEndPoint').prop('disabled', true);
        }

        // If is cloneMode add AllPrivileges checkbox field
        if (this.cloneMode) {
            this.userForm.addControl('AllPrivileges', this.fb.control(false));
        }

        this.selectedRole = 'User';
        (<any>$('#select2RoleEndPoint')).select2({
            placeholder: this.translate.instant('ENDPOINTS.EDIT_USER.FIELDS.ROLE_PLACEHOLDER'),
            allowClear: false,
            closeOnSelect: true,
            minimumResultsForSearch: -1,
            width: '100%',
            dropdownParent: $('#userEdit'),
            containerCssClass: 'select2Single',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });
        $('#select2RoleEndPoint').on('select2:select', (e: any) => {
            this.selectedRole = e.params.data.id;
        });
        $('#select2RoleEndPoint').on('select2:clear', (e: any) => {
            this.selectedRole = 'User';
        });

        if (this.editMode || this.cloneMode) {
            setTimeout(() => {
                $('#select2RoleEndPoint').val(this.selectedRole).trigger('change');
            }, 1000);
        }
    }

    /**
   * Returns page title
   */
    getTitle(): string {
        if (this.editMode) {
            return this.translate.instant('ENDPOINTS.EDIT_USER.TITLE_EDIT', {
                email: this.user.Email,
                role: this.user.Roles
            });  `Edit user '${this.user.Email} (${this.user.Role})'`;
        } else if (this.cloneMode) {
            return this.translate.instant('ENDPOINTS.EDIT_USER.TITLE_DUPLICATE', {
                email: this.user.Email,
                role: this.user.Roles
            });
        }   
        return this.translate.instant('ENDPOINTS.EDIT_USER.TITLE_NEW');
    }

    /**
   * Check control is invalid
   * @param controlName: string
   */
    isControlInvalid(controlName: string): boolean {
        const control = this.userForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    /** ACTIONS */

    /**
     * Returns prepared customer
     */
    prepareUser(): any {
        const controls = this.userForm.controls;
        let _authorization: any = {
            Email: this.switchGroupService.getCurrentEmail(),
            Authorizations: []
        };
        const groupList = this.switchGroupService.getGroupsList();
        const endpointInfo = groupList.filter((group: any) => group.groupId == this.endpoint.Id);
        if (endpointInfo && endpointInfo.length > 0) {
            _authorization.Authorizations.push({
                Id: endpointInfo[0].groupId,
                Token: endpointInfo[0].access_token
            });
        }

        let _data: any = {
            FullName: controls['FullName'].value,
            Email: controls['Email'].value,
            Groups: []
        };
        _data.Groups.push({
            Id: this.endpoint.Id, Role: this.selectedRole
        })
        if (this.user.Id) {
            _data['Id'] = this.user.Id
        }
        return {
            Authorization: _authorization,
            Data: _data
        };
    }

    /**
   * On Submit
   */
    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.userForm.controls;
        /** check form */
        if (this.userForm.invalid) {
            Object.keys(controls).forEach(controlName => {
                controls[controlName].markAsTouched();
                console.log(controlName);
            });
            this.hasFormErrors = true;
            return;
        }

        const editedUser = this.prepareUser();
        console.log('prepareUser', editedUser);
        if(this.cloneMode){
            this.duplicateUser(editedUser);
        } else {
            if (this.editMode) {
                this.updateUser(editedUser);
            } else {
                this.createUser(editedUser);
            }
        }
    }

    /**
     * Update customer
     *
     * @param _customer: CustomerModel
     */
    updateUser(user: any) {
        lastValueFrom(this.endpointsService.updateUser(user))
            .then((ret: any) => {
                console.log('updateUser', ret);
                this.dialogRef.close({ user, isEdit: true });
            }, (error: any) => {
                this.dialogRef.close(null);
            }
            )
    }

    /**
     * Update customer
     *
     * @param _customer: CustomerModel
     */
    createUser(user: any) {
        this.endpointsService.createUser(user).subscribe(
            (ret: any) => {
                console.log('createUser', ret);
                this.dialogRef.close({ user, isEdit: false });
            }, (error: any) => {
                this.dialogRef.close(null);
            }
        )
    }

    duplicateUser(user: any) {
        const payload = {
            Authorization: user.Authorization,
            Data: {
                Email: this.user.Email,
                NewEmail: user.Data.Email,
                Role: this.selectedRole,
                ImportPermissions: this.userForm.controls['AllPrivileges'].value,
                FullName: user.Data.FullName
            }
        };
        lastValueFrom(this.endpointsService.duplicateUser(payload))
            .then((ret: any) => {
                this.dialogRef.close({ user, isDuplicated: true });
            }, (error: any) => {
                this.dialogRef.close(null);
            }
            )
    }

}
