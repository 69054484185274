import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, fromEvent, lastValueFrom } from 'rxjs';
import { catchError, map, take, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FiltriService } from '@app/core/services/filtri.service';
import { MagazzinoService } from '@app/core/services/magazzino.service';
import { TranslateService } from '@ngx-translate/core';
// Layout
import { LayoutConfigService } from '@app/core/_base/layout';
import { ExcelService } from '@app/core/services/excel.service';
import { PDFService } from '@app/core/services/pdf.service';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';

import { MessageType, LayoutUtilsService } from '@app/core/_base/crud';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';

import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import * as _ from 'lodash';
import { InserimentoRettifichePopupComponent } from './inserimento-rettifiche-popup/inserimento-rettifiche-popup.component';
import { ColumnsSelectionPopupComponent } from '@app/views/partials/content/crud/columns-selection-popup/columns-selection-popup.component';
import { InserimentoType } from '@app/views/pages/magazzino/inserimento-comune/inserimento-comune.component';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { environment } from '@env/environment';
import * as util from '@app/core/services/utilityfunctions';
import { AnagraficheService } from '@app/core/services/anagrafiche.service';
import { hasClassName } from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
    selector: 'kt-sprechi',
    templateUrl: './sprechi.component.html',
    styleUrls: ['./sprechi.component.scss', '../common-styles.scss']
})
export class SprechiComponent implements OnInit, AfterViewInit, OnDestroy {

    lista: any;
    listaFiltered: any;
    listaSubscribtions!: Subscription;
    companies: any;
    currentCompanyFilter!: string;

    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    dataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    table: any;
    currentFilterCfg: any;
    locale!: string;

    @ViewChild('ProductOrCode', { static: true }) ProductOrCode!: ElementRef;

    pagination: any;
    columnsList: any = [];

    switchGroupSubcription!: Subscription;
    filtriServiceSubscription!: Subscription;
    // staticCollectionsSubcription!: Subscription;

    utility: any;
    constructor(
        private filtriService: FiltriService,
        private magazzinoService: MagazzinoService,
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private layoutConfigService: LayoutConfigService,
        private excelService: ExcelService,
        private pdfService: PDFService,
        private layoutUtilsService: LayoutUtilsService,
        private translationService: TranslationService,
        private switchGroupService: SwitchGroupService,
        public dialog: MatDialog,
        public staticCollectionsService: StaticCollectionsService,
        private router: Router,
        public roleService: RoleService,
        private activatedRoute: ActivatedRoute,
        private anagraficheService: AnagraficheService
    ) {
        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
        this.utility = util;
        this.columnsList = [
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DATE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COMPANY'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.COSTCENTERS'), enabled: false, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CODE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.CATEGORY'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NAME'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.QUANTITY'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.UNIT'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.PRICE'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.TOTAL'), enabled: true, format: '#.##0,00', style: { alignment: { horizontal: "right" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.DEPARTMENT'), enabled: false, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.TYPE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { label: this.translate.instant('MAGAZZINO.ACQUISTI.COLUMNS.NOTE'), enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } }
        ];
    }

    /**
     * After view init
     */
    async ngAfterViewInit() {
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.loading$.next(true);
                    this.clearFilters(false);
                    $('#table').DataTable().clear().destroy();
                    this.lista = [];
                    this.listaFiltered = [];
                    this.loading$.next(false);
                }
            }
        );

        await this.staticCollectionsService.fillStaticCollection(false);
        this.loading$.next(false);
        //this.initFiltri();

        this.activatedRoute.queryParams.subscribe((params: any) => {
            setTimeout(() => {
                this.initFiltri();
                if (params.filters) {
                    try {
                        const filters = JSON.parse(params.filters);
                        if (!this.currentFilterCfg) this.currentFilterCfg = {};
                        for (const [key, value] of Object.entries(filters)) {
                            this.currentFilterCfg[key] = value;
                        }
                        this.filtriService.performFilter$.next(this.currentFilterCfg);
                    } catch (error) {
                        console.log(error);
                    }
                }

            }, 1000);
        });
    }

    ngOnDestroy() {
        if (this.listaSubscribtions) this.listaSubscribtions.unsubscribe();
        this.switchGroupSubcription.unsubscribe();
        // this.staticCollectionsSubcription.unsubscribe();
        this.filtriServiceSubscription.unsubscribe();

        // cancello il filtro
        this.filtriService.filterConfig = {};

        // Se l'url non include inserimento-comune allora svuoto la localstorage
        if (!this.router.url.includes('/magazzino/inserimento-comune')) {
            localStorage.removeItem('currentFilterCfg');
        }
    }

    searchByCode(code: string) {
        this.ProductOrCode.nativeElement.value = code;
        this.filter(code, 'ProductOrCode')
    }

    getTitle() {
        return this.translate.instant('MAGAZZINO.SPRECHI.TITLE');
    }

    getWasteTypeCaption(i: number) {
        //return this.translate.instant('FILTRI.WASTE_TYPEN.' + i);
        if(this.AllWasteTypes && this.wasteTypesList.length > 0) {
            const wt = this.wasteTypesList.find((w: any) => w.Id == i);
            if(wt) return wt.Name;
        }
    }

    async ngOnInit() {

        this.AllWasteTypes = await lastValueFrom(this.anagraficheService.getEntity('Wastetypes'));
        if(this.AllWasteTypes) {
            this.wasteTypesList = this.AllWasteTypes.filter((wt: any) => wt.IsWarehouse);
        }

        fromEvent(this.ProductOrCode.nativeElement, 'keyup')
            .pipe(
                // tslint:disable-next-line:max-line-length
                debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
                distinctUntilChanged(), // This operator will eliminate duplicate values
                tap((ev: any) => {
                    if (ev.keyCode == 13) this.filter(this.ProductOrCode.nativeElement.value, 'ProductOrCode');
                })
            )
            .subscribe();

        this.categorie = [];
        this.wasteType = [];
        document.body.classList.add('kt-aside--minimize');
        this.filtriServiceSubscription = this.filtriService.performFilter$.subscribe(
            (filterConfig: any) => {

                if (localStorage.getItem('currentFilterCfg')) {
                    try {
                        this.currentFilterCfg = JSON.parse(localStorage.getItem('currentFilterCfg')||'');
                        localStorage.removeItem('currentFilterCfg');
                        this.filtriService.performFilter$.next(this.currentFilterCfg);
                        return;
                    } catch (error) {
                        localStorage.removeItem('currentFilterCfg');
                    }
                }

                
                if (Object.keys(filterConfig).length === 0 && filterConfig.constructor === Object) return;
                if (filterConfig.constructor !== Object) return;
                this.currentFilterCfg = JSON.parse(JSON.stringify(filterConfig));

                this.pagination = {
                    page: 1,
                    pageSize: 15,
                    sort: 'Date',
                    sortMode: 'Desc',
                    TotalRows: 0
                };

                // Inizializzo EVENTUALMENTE le select
                setTimeout(() => {
                    if (this.currentFilterCfg && this.currentFilterCfg.CategoryIds && this.currentFilterCfg.CategoryIds.length > 0) {
                        $('#select2Categorie').val(this.currentFilterCfg.CategoryIds).trigger('change');
                        this.categorie = this.currentFilterCfg.CategoryIds;
                    }

                    if (this.currentFilterCfg && this.currentFilterCfg.WasteTypes && this.currentFilterCfg.WasteTypes.length > 0) {
                        $('#select2Type').val(this.currentFilterCfg.WasteTypes).trigger('change');
                        this.wasteType = this.currentFilterCfg.WasteTypes;
                    }

                    // if (this.currentFilterCfg && this.currentFilterCfg.SupplierIds && this.currentFilterCfg.SupplierIds.length > 0) {
                    // 	$('#select2Fornitori').val(this.currentFilterCfg.SupplierIds).trigger('change');
                    // 	this.fornitori = this.currentFilterCfg.SupplierIds;
                    // }
                }, 1000)

                this.refreshTable();
            }
        );
    }

    clearFilters(actionFilter: boolean = true) {
        $('#select2Categorie').val('').trigger('change');
        $('#select2Type').val('').trigger('change');

        this.ProductOrCode.nativeElement.value = '';

        this.filtriService.clearFilters$.next(actionFilter);

        $('#table').DataTable().clear().destroy();
        this.lista = [];
        this.listaFiltered = [];
        this.dataReady$.next(false);
    }

    categorie: any;
    // fornitori: any;
    wasteType: any;
    wasteTypesList: any;
    AllWasteTypes: any;
    initFiltri() {
        console.log('initFiltri');
        (<any>$('#select2Categorie')).select2({
            placeholder: this.translate.instant('FILTRI.CATEGORIES_PLACEHOLDER'),
            allowClear: true,
            closeOnSelect: false,
            multiple: true,
            width: '100%',
            containerCssClass: 'select2Multiple',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });
        $('#select2Categorie').on('select2:select', (e: any) => {
            if (this.categorie.indexOf(e.params.data.id) < 0) {
                this.categorie.push(e.params.data.id);
                this.filter(this.categorie, 'CategoryIds');
            }
        });
        $('#select2Categorie').on('select2:clear', (e: any) => {
            if (this.categorie.length > 0) {
                this.categorie = [];
                this.filter(this.categorie, 'CategoryIds');
            }
        });
        $('#select2Categorie').on('select2:unselect', (e: any) => {
            if (this.categorie.indexOf(e.params.data.id) >= 0) {
                this.categorie = this.categorie.filter((categoria: any) => categoria !== e.params.data.id);
                this.filter(this.categorie, 'CategoryIds');
            }
        });

        (<any>$('#select2Type')).select2({
            placeholder: this.translate.instant('FILTRI.WASTE_TYPE.Title'),
            allowClear: true,
            closeOnSelect: false,
            multiple: true,
            width: '100%',
            containerCssClass: 'select2Multiple',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });
        $('#select2Type').on('select2:select', (e: any) => {
            if (this.wasteType.indexOf(e.params.data.id) < 0) {
                this.wasteType.push(e.params.data.id);
                this.filter(this.wasteType, 'WasteTypes');
            }
        });
        $('#select2Type').on('select2:clear', (e: any) => {
            if (this.wasteType.length > 0) {
                this.wasteType = [];
                this.filter(this.wasteType, 'WasteTypes');
            }
        });
        $('#select2Type').on('select2:unselect', (e: any) => {
            if (this.wasteType.indexOf(e.params.data.id) >= 0) {
                this.wasteType = this.wasteType.filter((categoria: any) => categoria !== e.params.data.id);
                this.filter(this.wasteType, 'WasteTypes');
            }
        });

        setTimeout(() => {
            $('#select2Categorie').val('').trigger('change');
            $('#select2Type').val('').trigger('change');
        }, 1000);

    }

    refreshTable() {
        if (this.listaSubscribtions) this.listaSubscribtions.unsubscribe();
        this.loading$.next(true);
        this.dataReady$.next(false);
        this.filtriService.readOnly$.next(true);
        if ($.fn.dataTable.isDataTable('#table')) {
            $('#table').DataTable().destroy();
        }
        console.log(this.pagination);
        this.companies = [];
        this.listaSubscribtions = this.magazzinoService.getWarehouse('GetAdjustment', this.currentFilterCfg, this.pagination)
            .subscribe((ret: any) => {
                console.log('lista', ret, this.currentFilterCfg);

                // TODO: Elaborare dati ricevuti
                ret['Quantity'] = 0;
                ret['Total'] = 0;
                ret.Rows.forEach((row: any) => {
                    ret.Quantity += row.Quantity;
                    ret.Total += row.Total;
                });

                if (this.pagination.TotalRows !== ret.TotalRows) {
                    this.pagination.TotalRows = ret.TotalRows;
                }

                this.lista = ret;
                this.listaFiltered = JSON.parse(JSON.stringify(ret));
                this.initDataTable();
                this.loading$.next(false);
                this.dataReady$.next(true);
                this.filtriService.readOnly$.next(false);
            });
    }

    initDataTable() {
        $('#table').DataTable().clear().destroy();
        setTimeout(() => {
            let dtOptions: any = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: true,
                orderCellsTop: true,
                scrollY: 'calc(100vh - 400px)',
                scrollCollapse: true,
                aaSorting: [], // inizialmente nessun ordinamento
                columnDefs: [
                    { targets: 0, width: '70px', visible: this.columnsList[0].enabled },
                    { targets: 1, width: '80px', visible: this.columnsList[1].enabled },
                    { targets: 2, width: '70px', visible: this.columnsList[2].enabled },
                    { targets: 3, width: '70px', visible: this.columnsList[3].enabled },
                    { targets: 4, width: '70px', visible: this.columnsList[4].enabled },		// Categoria
                    { targets: 5, visible: this.columnsList[5].enabled },					// descrizione
                    { targets: 6, width: '70px', visible: this.columnsList[6].enabled },
                    { targets: 7, width: '70px', visible: this.columnsList[7].enabled },
                    { targets: 8, width: '70px', visible: this.columnsList[8].enabled },
                    { targets: 9, width: '70px', visible: this.columnsList[9].enabled },
                    { targets: 10, width: '70px', visible: this.columnsList[10].enabled },
                    { targets: 11, visible: this.columnsList[11].enabled, className: 'max-width-100' },
                    { targets: 12, width: '50px', visible: this.columnsList[12].enabled }
                ],
                autoWidth: true
            };
            this.table = $('#table').DataTable(dtOptions);
            this.table.on('page.dt', function () {
                $('.dataTables_scrollBody').animate({
                    scrollTop: 0
                }, 'slow');
            });
            // this.refreshColumnsVisibility();
            //this.table.columns.adjust().draw();
        }, 100);
    }

    changePagination(pagination: PageEvent) {
        this.pagination.page = pagination.pageIndex + 1;
        this.pagination.pageSize = pagination.pageSize;
        this.refreshTable();
    }

    sort(event: any, index: number, type: string) {
        console.log('sort', event, index);
        this.pagination.sort = type;

        if (this.columnsList[index].sort && this.columnsList[index].sort === 'ASC') {
            this.columnsList[index].sort = 'DESC';
            this.pagination.sortMode = 'Desc';
        } else {
            this.columnsList[index].sort = 'ASC';
            this.pagination.sortMode = 'Asc';
        }

        console.log('sort => ', 'refreshTable', this.pagination.sort, this.pagination.sortMode);
        this.refreshTable();
    }

    filter(event: any, type: string) {
        console.log('filter', event, type);
        if (!this.currentFilterCfg) {
            this.currentFilterCfg = {};
        }
        this.currentFilterCfg[type] = event;
        this.filtriService.performFilter$.next(this.currentFilterCfg);
    }

    openPopup() {
        const newEndpoint = {};
        this.editPopup(newEndpoint);
    }

    editPopup(endpoint: any) {
        const dialogRef = this.dialog.open(InserimentoRettifichePopupComponent, {
            data: { endpoint },
            width: '600px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            console.log(res);
            if (res.success && res.body) {
                res.body.hideExcluded = true;
                this.magazzinoService.setCurrentConfig(res);

                // mi salvo la configurazione dei filtri nei cookie.
                console.log('mi salvo la configurazione dei filtri nei cookie.');
                localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));

                this.router.navigate(['/magazzino/inserimento-comune'], {
                    queryParams: {
                        inserimentoType: InserimentoType.SPRECHI,
                        referral: '/magazzino/sprechi'
                    }
                });
            }
        });
    }

    refreshColumnsVisibility() {
        console.log('refreshColumnsVisibility');
        for (let index = 0; index < this.columnsList.length; index++) {
            var column = this.table.column(index);
            if (column) column.visible(this.columnsList[index].enabled);
        }
    }

    openColumnsDialog() {
        const dialogRef = this.dialog.open(ColumnsSelectionPopupComponent, {
            data: { columnsList: this.columnsList },
            width: '300px'
        });
        dialogRef.afterClosed().subscribe((res: any) => {
            if (_.isEmpty(res) || !res) {
                return;
            }
            console.log(res);
            if (res.success && res.columnsList) {
                this.columnsList = res.columnsList;
                for (let index = 0; index < this.columnsList.length; index++) {
                    var column = this.table.column(index);
                    if (column) column.visible(this.columnsList[index].enabled);
                }
            }
        });
    }

    editItem(itemId: any) {

        this.loading$.next(true);
        this.magazzinoService.itemsForUpdate(itemId, 'Adjustment')
            .subscribe((result: any) => {
                console.log('itemsForUpdate', result);

                const resultConfig = JSON.parse(JSON.stringify(result));
                const resultsRows: any = JSON.parse(JSON.stringify(result.Rows));
                delete resultConfig.Rows;
                this.magazzinoService.setCurrentConfig({
                    insertType: InserimentoType.SPRECHI,
                    body: resultConfig,
                    items: resultsRows,
                    prePop: {
                        Items: resultsRows
                    }
                });

                // mi salvo la configurazione dei filtri nei cookie.
                console.log('mi salvo la configurazione dei filtri nei cookie.');
                localStorage.setItem('currentFilterCfg', JSON.stringify(this.currentFilterCfg));

                this.loading$.next(false);
                this.router.navigate(['/magazzino/inserimento-comune'], {
                    queryParams: {
                        inserimentoType: InserimentoType.SPRECHI,
                        referral: '/magazzino/sprechi',
                        itemId: itemId,
                    }
                });

            })
    }

    deleteItem(itemId: any) {

        const _title: string = this.translate.instant('MAGAZZINO.SPRECHI.DELETE_DIALOG.TITLE');
        const _description: string = this.translate.instant('MAGAZZINO.SPRECHI.DELETE_DIALOG.DESC');
        const _waitDesciption: string = '';
        const _yesButton = this.translate.instant('COMMONS.DELETE');
        const _noButton = this.translate.instant('COMMONS.CANCEL');

        const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton, _noButton);
        dialogRef.afterClosed().subscribe((confirm: any) => {
            console.log(confirm);
            if (confirm) {

                this.magazzinoService.delete(InserimentoType.SPRECHI, [itemId]).subscribe(
                    (result: any) => {
                        console.log(result);
                        let message: string = '';
                        if (result.Msg === 'Ok') {
                            message = this.translate.instant('MAGAZZINO.SPRECHI.DELETE_DIALOG.SUCCESS');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 3000, true, false, 3000, 'top', 'snackbar-success');
                            this.refreshTable();
                        } else {
                            message = this.translate.instant('MAGAZZINO.SPRECHI.DELETE_DIALOG.ERROR');
                            this.layoutUtilsService.showActionNotification(message, MessageType.Error, 3000, true, false, 3000, 'top', 'snackbar-error');
                        }
                    }
                )

            }
        });

    }

    showFromYearStart() {
        this.currentFilterCfg = { Period: 'YEAR', ShowDisabled: true, ShowInvisible: true };
        this.filtriService.performFilter$.next(this.currentFilterCfg);
        this.ref.detectChanges();
    }

    showToday() {
        const today = new Date();
        const todayStr = today.toLocaleDateString('ja-JP');
        this.currentFilterCfg = { DateFilter: { Start: todayStr, End: todayStr }, ShowDisabled: true, ShowInvisible: true };
        this.filtriService.performFilter$.next(this.currentFilterCfg);
        this.ref.detectChanges();
    }

    /******************************************************************************
      ______                       _    __   ___       _______   __
       |  ____|                     | |   \ \ / / |     / ____\ \ / /
       | |__  __  ___ __   ___  _ __| |_   \ V /| |    | (___  \ V /
       |  __| \ \/ / '_ \ / _ \| '__| __|   > < | |     \___ \  > <
       | |____ >  <| |_) | (_) | |  | |_   / . \| |____ ____) |/ . \
       |______/_/\_\ .__/ \___/|_|   \__| /_/ \_\______|_____//_/ \_\
         | |
         |_|
      ******************************************************************************/

    exportAsXLSX() {

        let pagination = JSON.parse(JSON.stringify(this.pagination));
        if (this.pagination.TotalRows) {

            if (pagination.TotalRows < environment.ExcelPaginationMaxSize)
                pagination.pageSize = pagination.TotalRows;
            else {
                pagination.pageSize = environment.ExcelPaginationMaxSize;
                const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
                let _description: string = this.translate.instant('EXPORT_XLSX.MAX5000');
                _description = _description.replace('{0}', '' + environment.ExcelPaginationMaxSize);
                const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');

                const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');


                const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton);
                dialogRef.afterClosed().subscribe(expand => {

                });
            }

        }
        else {
            const _title: string = this.translate.instant('EXPORT_XLSX.TITLE');
            const _description: string = this.translate.instant('EXPORT_XLSX.NO_ROWS');
            const _waitDesciption: string = this.translate.instant('EXPORT_XLSX.WAIT_DESCRIPTION');

            const _yesButton = this.translate.instant('EXPORT_XLSX.YESBUTTON');


            const dialogRef = this.layoutUtilsService.simpleElement(_title, _description, _waitDesciption, _yesButton);
            dialogRef.afterClosed().subscribe(expand => {
                return;
            });
            return;
        }



        this.loading$.next(true);
        let xlsx: any[] = [];
        let merges: any[] = [];
        let listaSubscribtions = this.magazzinoService.getWarehouse('GetAdjustment', this.currentFilterCfg, pagination)
            .subscribe((ret: any) => {

                // TODO: Elaborare dati ricevuti
                ret['Quantity'] = 0;
                ret['Total'] = 0;
                ret.Rows.forEach((row: any) => {
                    ret.Quantity += row.Quantity;
                    ret.Total += row.Total;
                });

                if (this.pagination.TotalRows !== ret.TotalRows) {
                    this.pagination.TotalRows = ret.TotalRows;
                }


                let printList = JSON.parse(JSON.stringify(ret));


                // Totali
                xlsx.push({
                    [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.DATE')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.COMPANY')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.COSTCENTERS')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.CODE')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.CATEGORY')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.NAME')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.QUANTITY')}`]: printList.Quantity ? this.utility.formatNumberExcel(printList.Quantity, 2) : '',
                    [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.UNIT')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.PRICE')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.TOTAL')}`]: printList.Total ? this.utility.formatNumberExcel(printList.Total, 2) : '',
                    [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.DEPARTMENT')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.TYPE')}`]: '',
                    [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.NOTE')}`]: ''
                });

                printList.Rows.forEach((item: any) => {
                    //this.listaFiltered.Rows.forEach((item: any) => {


                    xlsx.push({
                        [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.DATE')}`]: item.Date ? (new Date(item.Date)).toLocaleDateString('it-IT') : '',
                        [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.COMPANY')}`]: item.Company ? item.Company.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.COSTCENTERS')}`]: item.CostCenter ? item.CostCenter.Name.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.CODE')}`]: item.Code ? item.Code : '',
                        [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.CATEGORY')}`]: item.Category ? item.Category.Name.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.NAME')}`]: item.Goods ? item.Goods.Name.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.QUANTITY')}`]: item.Quantity ? this.utility.formatNumberExcel(item.Quantity, 2) : '',
                        [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.UNIT')}`]: item.Unit ? item.Unit : '',
                        [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.PRICE')}`]: item.Price ? this.utility.formatNumberExcel(item.Price, 2) : '',
                        [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.TOTAL')}`]: item.Total ? this.utility.formatNumberExcel(item.Total, 2) : '',
                        [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.DEPARTMENT')}`]: item.Department ? item.Department.toUpperCase() : '',
                        [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.TYPE')}`]: this.getWasteTypeCaption(item.Type),
                        [`${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.NOTE')}`]: item.Note ? item.Note : ''
                    });
                });
                this.loading$.next(false);
                this.excelService.exportAsExcelFile(xlsx, 'waste', merges, this.columnsList, 2, [], true);
            });


    }

    /******************************************************************************
      ______                       _     _____  _____  ______
     |  ____|                     | |   |  __ \|  __ \|  ____|
     | |__  __  ___ __   ___  _ __| |_  | |__) | |  | | |__
     |  __| \ \/ / '_ \ / _ \| '__| __| |  ___/| |  | |  __|
     | |____ >  <| |_) | (_) | |  | |_  | |    | |__| | |
     |______/_/\_\ .__/ \___/|_|   \__| |_|    |_____/|_|
           | |
           |_|
    ******************************************************************************/

    exportAsPDF() {

        const config: any = {
            title: this.translate.instant('EXPORT_PDF.TITLE'),
            description: this.translate.instant('EXPORT_PDF.DESCRIPTION'),
            waitDesciption: this.translate.instant('EXPORT_PDF.WAIT_DESCRIPTION'),
            success: this.translate.instant('EXPORT_PDF.MESSAGE'),
            yesButton: this.translate.instant('EXPORT_PDF.YESBUTTON'),
            noButton: this.translate.instant('EXPORT_PDF.NOBUTTON'),
            closeButton: this.translate.instant('EXPORT_PDF.CLOSEBUTTON'),
            askTitle: true,
            pdfTitle: this.translate.instant('EXPORT_PDF.INSERT_TITLE'),
            askExplodeRows: false,
        };

        const dialogRef = this.layoutUtilsService.exportElement(config);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                result['header'] = {
                    export_title: this.translate.instant('EXPORT_PDF.TITLE_PARAM', { title: this.getTitle() }),
                    //period: ''
                }
                result['footer'] = {
                    printed_by: this.translate.instant('EXPORT_PDF.PRINTED_BY'),
                    page: this.translate.instant('EXPORT_PDF.PAGE'),
                    of: this.translate.instant('EXPORT_PDF.OF')
                }
                result['language'] = this.translationService.getSelectedLanguage();
                result['table'].headerRows = 1;
                let widths: any = [];
                this.columnsList.filter((item: any) => item.enabled).forEach((element: any) => {
                    widths.push('auto')
                });
                widths[widths.length - 1] = '*';

                this.pdfService.makePdf(result, this.getPDFTableBody(), widths);
            };
        });

    }

    getPDFTableBody() {
        let body: any = [];
        let tmpRow: any = [];

        // aggiungo intestazione
        // totali Header
        tmpRow = [
            { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.DATE')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[1].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.COMPANY')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[2].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.COSTCENTERS')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[3].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.CODE')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[4].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.CATEGORY')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[5].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.NAME')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[6].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.QUANTITY')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[7].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.UNIT')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[8].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.PRICE')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[9].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.TOTAL')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[10].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.DEPARTMENT')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[11].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.TYPE')}`, style: 'tableHeaderStyle' },
            { visible: this.columnsList[12].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: `${this.translate.instant('MAGAZZINO.SPRECHI.COLUMNS.NOTE')}`, style: 'tableHeaderStyle' }
        ];

        body.push(tmpRow.filter((item: any) => item.visible));

        tmpRow = [
            { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[1].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[2].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[3].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[4].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[5].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[6].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.listaFiltered.Quantity ? this.utility.formatNumber(this.listaFiltered.Quantity, this.locale) : ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[7].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[8].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[9].enabled, alignment: 'right', fillColor: '#eeeeee', border: [true, true, true, true], text: this.listaFiltered.Total ? this.utility.formatNumber(this.listaFiltered.Total, this.locale) : ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[10].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[11].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' },
            { visible: this.columnsList[12].enabled, alignment: 'left', fillColor: '#eeeeee', border: [true, true, true, true], text: ' ', style: 'tableHeaderStyle' }
        ];
        body.push(tmpRow.filter((item: any) => item.visible));

        let i: number = 0;
        let sortedList = this.utility.sortList(this.listaFiltered.Rows, 'table');
        sortedList.forEach((item: any) => {
            //this.listaFiltered.Rows.forEach((item: any) => {
            tmpRow = [
                { visible: this.columnsList[0].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Date ? (new Date(item.Date)).toLocaleDateString('it-IT') : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[1].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Company ? item.Company.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[2].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.CostCenter ? item.CostCenter.Name.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[3].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Code ? item.Code : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[4].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Category ? item.Category.Name.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[5].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Goods ? item.Goods.Name.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[6].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Quantity ? this.utility.formatNumber(item.Quantity, this.locale, 0) : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[7].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Unit ? item.Unit : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[8].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Price ? this.utility.formatNumber(item.Price, this.locale) : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[9].enabled, alignment: 'right', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Total ? this.utility.formatNumber(item.Total, this.locale) : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[10].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Department ? item.Department.toUpperCase() : ' ', style: 'tableBodyStyle' },
                { visible: this.columnsList[11].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: this.getWasteTypeCaption(item.Type), style: 'tableBodyStyle' },
                { visible: this.columnsList[12].enabled, alignment: 'left', fillColor: (i % 2 === 1) ? '#eeeeee' : '', border: [true, true, true, true], text: item.Note ? item.Note : ' ', style: 'tableBodyStyle' }
            ];
            body.push(tmpRow.filter((item: any) => item.visible));
            i++;
        });

        return body;
    }

}
