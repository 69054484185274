import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'kt-recipes-from-goods',
    templateUrl: './recipes-from-goods.component.html',
    styleUrls: ['./recipes-from-goods.component.scss']
})
export class RecipesFromGoodsComponent implements OnInit {

    baseUnit: any;
    unit: any;
    unitaMisuraLst: any;
    qta: string = "1";
    scarto: string = "0";
    category: any;
    suffix: string = "";

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        public staticCollectionsService: StaticCollectionsService,
        private translate: TranslateService
    ) { }

    ngOnInit() {
        this.unitaMisuraLst = this.staticCollectionsService.unit$.filter((u: any) => u.MainUnitId === this.data.baseUnitId);
        console.log(this.unitaMisuraLst);
    }

    ngAfterViewInit() {
        (<any>$('.select2Category')).select2({
            containerCssClass: 'select2Single',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });
        $('.select2Category').on('select2:select', (e: any) => {
            try {
                this.category = this.staticCollectionsService.categorieProdotti$.find( u => u.Id == e.params.data.id);
            } catch (error) {
                console.log(error);
            }
        });
        setTimeout(() => {
            $('.select2Category').val('').trigger('change');
        }, 100);

        (<any>$('.select2BaseUnit')).select2({
            containerCssClass: 'select2Single',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });
        $('.select2BaseUnit').on('select2:select', (e: any) => {
            try {
                this.baseUnit = this.staticCollectionsService.unit$.find( u => u.Id == e.params.data.id);                
            } catch (error) {
                console.log(error);
            }
        });
        setTimeout(() => {
            $('.select2BaseUnit').val('').trigger('change');
        }, 100);

        (<any>$('.select2Unit')).select2({
            containerCssClass: 'select2Single',
            language: {
                errorLoading: () => this.translate.instant('SELECT2.errorLoading'),
                inputTooLong: () => this.translate.instant('SELECT2.inputTooLong'),
                inputTooShort: () => this.translate.instant('SELECT2.inputTooShort'),
                loadingMore: () => this.translate.instant('SELECT2.loadingMore'),
                maximumSelected: () => this.translate.instant('SELECT2.maximumSelected'),
                noResults: () => this.translate.instant('SELECT2.noResults'),
                searching: () => this.translate.instant('SELECT2.searching')
            }
        });
        $('.select2Unit').on('select2:select', (e: any) => {
            try {
                this.unit = this.staticCollectionsService.unit$.find( u => u.Id == e.params.data.id);
            } catch (error) {
                console.log(error);
            }
        });
        setTimeout(() => {
            $('.select2Unit').val('').trigger('change');
        }, 100);
    }

    onSubmit() {
        this.dialogRef.close({
            CategoryId: this.category.Id,
            baseUnitId: this.baseUnit.Id,
            unitId: this.unit.Id,
            quantity: this.qta,
            scarto: this.scarto,
            suffix: this.suffix
        });
    }

}


