import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import * as util from '@app/core/services/utilityfunctions';

@Component({
    selector: 'kt-analisi-prezzi-fornitori',
    templateUrl: './analisi-prezzi-fornitori.component.html',
    styleUrls: ['./analisi-prezzi-fornitori.component.scss']
})
export class AnalisiPrezziFornitoriComponent implements OnInit {

    utility: any;
    priceGapData: any;
    currentFilterCfg: any;
    currentFilterCfgOLD: any;
    productId!: string;
    showGraph: boolean = false;

    constructor(
        private staticCollectionsService: StaticCollectionsService,
        private activatedRoute: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        document.body.classList.add('kt-aside--minimize');
        this.utility = util;
    }

    ngOnDestroy() {
    }

    async ngOnInit() {
        await this.staticCollectionsService.fillStaticCollection(false);

        this.activatedRoute.queryParams.subscribe((params: any) => {
            if (params.ProductId) {
                this.productId = params.ProductId;                
            }
            this.showGraph = true;
            this.changeDetectorRef.detectChanges();
        });
    }
}
