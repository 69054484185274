import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';

@Pipe({
  name: 'localizedDateTime',
  pure: false
})
export class LocalizedDateTimePipe implements PipeTransform, OnDestroy {

  constructor(private translateService: TranslateService) {}

  transform(value: any, pattern?: string, hideTime: boolean = false): any {
	    registerLocaleData(localeFr);
		const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);

		let overridePattern = pattern;

		if ( !pattern ) {
			if ( this.translateService.currentLang != 'it' ) {
				overridePattern = 'MM/dd/yyyy';
			} else {
				overridePattern = 'dd/MM/yyyy';
			}
			if (!hideTime) overridePattern += ' HH:mm';
		}
		
		return datePipe.transform(value, overridePattern);
	}

    ngOnDestroy(): void {
        
    }

}
