import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ManutenzioneService } from '@app/views/pages/manutenzione/manutenzione.service';
import { SwitchGroupService } from '@app/core/_base/layout/services/switch-group.service';
import { BehaviorSubject, combineLatest, timer, Observable, Subject, Subscription, fromEvent, lastValueFrom } from 'rxjs';
import { TranslationService } from '@app/core/_base/layout/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '@app/core/services/excel.service';
import { catchError, map, take, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { RoleService } from '@app/core/_base/layout/services/role.service';
import { StaticCollectionsService } from '@app/core/services/static-collections.service';
import { MbSelect2Component } from '@app/mb-components/mb-select2/mb-select2.component';

@Component({
    selector: 'kt-importexport',
    templateUrl: './importexport.component.html',
    styleUrls: ['./importexport.component.scss']
})
export class ImportexportComponent implements OnInit, OnDestroy {
    switchGroupSubcription!: Subscription;
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    filterChanged: Subject<any> = new Subject<any>();

    importTypes: any;

    importTypeSelected!: string;
    importList: any;
    logDetailsList: any;
    logDetailsListFilted: any;
    logErrorsFiltered: any;
    selectedFile!: File;
    selectedLog: any;
    searchText!: string;
    filterType!: string;
    companies: any;
    authors: any;
    availableFiles: any;
    rejectedFiles: any;
    loadRejected = false;
    intLogDetailDataTableDone = false;
    filterData: any = { period: 'Week', company: '-', author: '-' };
    dateStart: any;
    dateEnd: any;

    importTimer!: Observable<number>;
    importSubscription!: Subscription;
    importing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    importProgress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    filterCompanies = [];

    constructor(
        private manutenzioneService: ManutenzioneService,
        private switchGroupService: SwitchGroupService,
        private translate: TranslateService,
        private excelService: ExcelService,
        private ref: ChangeDetectorRef,
        public roleService: RoleService,
        public staticCollections: StaticCollectionsService,
    ) { }

    ngOnDestroy() {
        if (this.importSubscription) this.importSubscription.unsubscribe();
        this.switchGroupSubcription.unsubscribe();
    }

    ngOnInit() {
        this.staticCollections.fillStaticCollection(true);
        this.switchGroupSubcription = this.switchGroupService.performSwitchObsv$.subscribe(
            (event: any) => {
                if (event.change) {
                    this.initCallServices();
                }
            }
        );

        this.initCallServices();
        this.importTimer = timer(0, 10000);

        this.importSubscription = this.importTimer.subscribe(
            (r: any) => {
                var o: any;
                if (this.importTypeSelected === 'Zucchetti2') {
                    o = this.manutenzioneService.getImportStatus('zucchetti2');
                } else if (this.importTypeSelected === 'JSON_LIGHTSPEED') {
                    o = this.manutenzioneService.getImportStatus('LightSpeed');
                } else if (this.importTypeSelected === 'Zucchetti') {
                    o = this.manutenzioneService.getImportStatus('zucchetti');
                }
                else if (this.importTypeSelected === 'Tilby') {
                    o = this.manutenzioneService.getImportStatus('Tilby');

                }
                else if (this.importTypeSelected === 'ILGLU') {
                    o = this.manutenzioneService.getImportStatus('ILGLU');
                }
                else if (this.importTypeSelected === 'IPratico') {
                    o = this.manutenzioneService.getImportStatus('IPratico');
                }
                else if (this.importTypeSelected === 'QLS') {
                    o = this.manutenzioneService.getImportStatus('QLS');
                } else if (this.importTypeSelected === 'SellingsXLS') {
                    o = this.manutenzioneService.getImportStatus('SellingsXLS');
                }


                if (o) {
                    o.subscribe((result: any) => {
                        if (result.IsImporting) {
                            this.importing$.next(true);
                            this.importProgress$.next(result.Progress);
                        }
                        else {
                            this.importing$.next(false);
                            this.importProgress$.next(0);
                        }
                    });
                }


            });

        this.filterChanged
            .pipe(
                debounceTime(500),
                //distinctUntilChanged()
            )
            .subscribe(() => {
                this.filter();
            })
    }


    unlock(type) {
        this.manutenzioneService.unlock(type)
            .subscribe((result: any) => {
                this.importing$.next(false);
                this.importProgress$.next(0);
            });
    }

    initCallServices() {
        this.importTypes = [];
        this.loading$.next(true);


        this.manutenzioneService.getImportTypes().subscribe(
            (result: any) => {
                
                const currentGroup: any = this.switchGroupService.getCurrentGroup();

                result.forEach((element: any) => {
                    const integration = currentGroup.integrations.find((i: any) => i.Integration === element);
                    if(integration && integration.IsAvailable) {
                        this.importTypes.push(element);
                    }
                });

                if (this.importTypes.length > 0) {
                    this.importTypeSelected = this.importTypes[0];
                    this.refreshImportTypeList(this.importTypeSelected);
                }
                this.loading$.next(false);
            }
        );

        this.manutenzioneService.getCompanies().subscribe(
            (result: any) => {
                console.log('getCompanies', result);
                this.companies = result;

                this.loading$.next(false);
            }
        );

        this.manutenzioneService.getAuthors().subscribe(
            (result: any) => {
                console.log('getAuthors', result);
                this.authors = result;

                this.loading$.next(false);
            }
        );

        this.loadAllFiles();


    }

    getImportTypeName(type) {
        return this.translate.instant(`INTEGRATIONS.SISTEMI_CASSA.${type}`)
    }

    changeImportType(event: any) {
        if (event.value) {
            this.refreshImportTypeList(event.value);
            this.loadAllFiles();
        }
    }

    loadAllFiles() {
        this.loading$.next(true);
        lastValueFrom(combineLatest([
            this.manutenzioneService.getAllFiles(this.importTypeSelected),
            this.manutenzioneService.getRejectedFiles(this.importTypeSelected)]))
            .then(
                (result: any) => {
                    this.availableFiles = result[0];
                    this.rejectedFiles = result[1];
                }
            )
            .finally(() => {
                this.loading$.next(false);
            });
    }

    public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.loadRejected = tabChangeEvent.index === 1;

    }

    importAllFiles() {
        if (this.loadRejected) {
            this.manutenzioneService.importRejectedFiles(this.importTypeSelected).subscribe(
                (result: any) => {
                    //TODO Visualizzare popup di 'Preso in Carico'
                },
                (err: any) => {
                    //TODO Visualizzare popup di 'Errore'
                }
            );
        } else {


            this.manutenzioneService.importAllFiles(this.importTypeSelected).subscribe(
                (result: any) => {
                    //TODO Visualizzare popup di 'Preso in Carico'
                },
                (err: any) => {
                    //TODO Visualizzare popup di 'Errore'
                }
            );
        }
    }

    refreshImportTypeList(importType: string) {
        console.log('refreshImportTypeList', importType);

        const body: any = {
            type: importType
        }

        body.Period = this.filterData.period;

        if (this.filterData.company !== '-') {
            body.companies = [];
            body.companies.push(this.filterData.company);
        }
        if (this.filterData.author !== '-') {
            body.authors = [];
            body.authors.push(this.filterData.author);
        }

        this.loading$.next(true);
        this.manutenzioneService.getImportedFiles(body).subscribe(
            (result: any) => {
                console.log('getImportedFiles', result);
                this.importList = result;
                this.logDetailsList = undefined;
                this.logDetailsListFilted = undefined;
                this.loading$.next(false);
                this.initLogDataTable();
            }
        )

        this.manutenzioneService.getImportedErrors(body).subscribe(
            (result: any) => {
                console.log('getImportedErrors', result);
                this.logErrorsFiltered = result;
                this.loading$.next(false);
                this.initLogErrorTable();
            }
        )
    }

    logsErrTable: any;
    initLogErrorTable() {
        //this.hoverglassActive = true;
        if ($.fn.dataTable.isDataTable('#logErrors')) {
            $('#logErrors').DataTable().destroy();
        }
        setTimeout(() => {
            let dtOptions: any = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: false,
                scrollY: '600px',
                scrollCollapse: true,
                autoWidth: false,
                columnDefs: [
                    { targets: [0], width: '50px' },
                    { targets: [1], width: '110px' }
                ]
            };
            this.logsErrTable = $('#logErrors').DataTable(dtOptions);
            this.logsErrTable.columns.adjust().draw();

        }, 100);

    }

    logsTable: any;
    initLogDataTable() {
        //this.hoverglassActive = true;
        if ($.fn.dataTable.isDataTable('#logsTable')) {
            $('#logsTable').DataTable().destroy();
        }
        setTimeout(() => {
            let dtOptions: any = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: false,
                scrollY: '300px',
                scrollCollapse: true,
                autoWidth: false,
                columnDefs: [
                    { targets: [0], width: '130px' },
                    { targets: [1], width: '50' },
                    { targets: [2], width: '180px' },
                    { targets: [3], width: '150px' }
                ]
            };
            this.logsTable = $('#logsTable').DataTable(dtOptions);
            this.logsTable.columns.adjust().draw();

        }, 100);

    }

    logDetailTable: any;
    initLogDetailDataTable() {

        //this.hoverglassActive = true;

        setTimeout(() => {
            let dtOptions: any = {
                destroy: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
                paging: false,
                searching: false,
                ordering: false,
                scrollY: '300px',
                scrollCollapse: true,
                autoWidth: false,
                columnDefs: [
                    { targets: [0], width: '50px' },
                    { targets: [1], width: '150px' }
                ]
            };
            if (this.logDetailTable) {
                $('#logDetailTable').DataTable().destroy();
            }
            this.logDetailTable = $('#logDetailTable').DataTable(dtOptions);
            this.logDetailTable.columns.adjust().draw();
        }, 100);

    }

    processFile(fileImport: any) {
        this.selectedFile = fileImport.files[0];
        console.log('processFile', this.selectedFile);
    }

    importFile() {
        this.manutenzioneService.importFile(this.importTypeSelected, this.selectedFile).subscribe(
            (result: any) => {
                console.log('importFile', result);
                this.refreshImportTypeList(this.importTypeSelected);
            }
        )
    }
    getCompanies() {
        if (this.filterCompanies && this.filterCompanies.length > 0) {
            let list: any = [];
            this.filterCompanies.forEach((element: any) => {
                list.push(element);
            });
            return list;
        }
        else return null;
    }

    importFromAPI(t) {
        let start = this.dateStart;
        let end = this.dateEnd;
        this.importing$.next(true);
        var obj: any = null;
        if (t === 'Zucchetti')
            obj = this.manutenzioneService.importFromZucchetti(start, end);
        else if (t === 'Zucchetti2') {
            var cms = this.getCompanies();
            obj = this.manutenzioneService.importFromZucchetti2(start, end, cms);
        }
        else if (t === 'Tilby') {
            var cms = this.getCompanies();
            obj = this.manutenzioneService.importTilby(start, end, cms);
        }

        else if (t === 'IPratico') {
            var cms = this.getCompanies();
            obj = this.manutenzioneService.importIPratico(start, end, cms);
        }
        else if (t === 'ILGLU') {
            var cms = this.getCompanies();
            obj = this.manutenzioneService.importILGLU(start, end, cms);
        }

        lastValueFrom(obj)
            .then(
                (result: any) => {
                    console.log('importFromAPI', result);
                    this.refreshImportTypeList(this.importTypeSelected);
                }
            ).finally(() => {
                this.importing$.next(false);
            });
    }

    importProduct() {
        
        var t=this.importTypeSelected;
        if (t === 'IPratico') {           
            lastValueFrom(this.manutenzioneService.importIPraticoProducts()).then(
                (result: any) => {
                    console.log('importFromAPI', result);
                    this.refreshImportTypeList(t);
                }
            )
        }
        else if (t === 'ILGLU') {
            lastValueFrom(this.manutenzioneService.importILGLUProducts()).then(
                (result: any) => {
                    console.log('importFromAPI', result);
                    this.refreshImportTypeList(t);
                }
            )
        }
        
    }

    viewLogDetails(log: any) {
        console.log('viewLogDetails', log);
        this.logDetailsList = undefined;
        this.logDetailsListFilted = undefined;
        this.selectedLog = log;
        this.manutenzioneService.getImportLog(log.Id).subscribe(
            (result: any) => {
                console.log('getImportLog', result);
                this.logDetailsList = result;
                this.logDetailsListFilted = result;
                this.resetFilter();

                this.ref.detectChanges();

                this.initLogDetailDataTable();
            }
        )
    }

    changeType() {
        this.filter();
    }

    onChangeFilter() {
        this.refreshImportTypeList(this.importTypeSelected);

    }

    exportErrorAsXLSX() {
        this.loading$.next(true);
        let xlsx: any[] = [];
        let merges: any[] = [];
        let i: number = 0;
        let sortedList = this.logErrorsFiltered;
        sortedList.forEach((item: any) => {
            //this.listaFiltered.forEach((item: any) => {
            xlsx.push({
                [`${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.NAME')}`]: item.Type ? item.Type.toUpperCase() : '',
                [`${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.CODE')}`]: item.Date ? item.Date : '',
                [`${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.PL_CODE')}`]: item.Message ? item.Message : ''

            });
            i++;
        });

        const columnsInfo = [
            { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } }
        ];

        this.loading$.next(false);
        this.excelService.exportAsExcelFile(xlsx, 'ImportErr', merges, columnsInfo, 1, [], true);
    }
    exportAsXLSX() {
        this.loading$.next(true);
        let xlsx: any[] = [];
        let merges: any[] = [];
        let i: number = 0;
        let sortedList = this.logDetailsListFilted;
        sortedList.forEach((item: any) => {
            //this.listaFiltered.forEach((item: any) => {
            xlsx.push({
                [`${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.NAME')}`]: item.Type ? item.Type.toUpperCase() : '',
                [`${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.CODE')}`]: item.Date ? item.Date : '',
                [`${this.translate.instant('ANAGRAFICHE.CATEGORIE_MERCI.COLUMNS.PL_CODE')}`]: item.Message ? item.Message : ''
            });
            i++;
        });

        const columnsInfo = [
            { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } },
            { enabled: true, format: '', style: { alignment: { horizontal: "left" }, name: 'Calibri', sz: 11 } }
        ];
        this.loading$.next(false);
        this.excelService.exportAsExcelFile(xlsx, 'inmpoting', merges, columnsInfo, 1, [], true);
    }


    filterText() {
        this.filterChanged.next(true);
    }

    filter() {
        this.logDetailsListFilted = JSON.parse(JSON.stringify(this.logDetailsList));
        if (this.filterType) {
            this.logDetailsListFilted = this.logDetailsListFilted.filter((log: any) => log.Type.toLowerCase() === this.filterType.toLowerCase());
        }
        if (this.searchText) {
            this.logDetailsListFilted = this.logDetailsListFilted.filter((log: any) => log.Message.toLowerCase().includes(this.searchText.toLowerCase()));
        }
        this.ref.detectChanges();
    }

    resetFilter() {
        this.searchText = '';
        this.filterType = '';
        this.logDetailsListFilted = JSON.parse(JSON.stringify(this.logDetailsList));
    }

}