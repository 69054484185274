// Angular
import { Injectable, Component, OnInit, Inject } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NestedTreeControl } from "@angular/cdk/tree";

// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { AnagraficheService } from '@app/core/services/anagrafiche.service';

// Translate Module
import { TranslateService } from '@ngx-translate/core';

import * as utils from '@app/core/services/utilityfunctions';
import { TranslationService } from '@app/core/_base/layout';

declare var $: any;
import moment from 'moment';
import { combineLatest, lastValueFrom } from 'rxjs';
import { ScadenziarioService } from '@app/views/pages/amministrazione/scadenziario/scadenziario.service';

export enum InfoType {
    name = 'name',
    id = 'id',
    initiallyChecked = 'initiallyChecked',
    percentage = 'percentage'
}

/**
 * Node for to-do name
 */
export class PLNode {
    children!: PLNode[];
    name!: string;
    id?: string;
    initiallyChecked: boolean = false;
    percentage!: number;
}

@Component({
    selector: 'kt-fornitori-edit',
    templateUrl: './fornitori-edit.component.html',
    styleUrls: ['./fornitori-edit.component.scss'],
})
export class FornitoriEditComponent implements OnInit {
    // Public properties
    content: any;
    generalForm!: FormGroup;
    settingsForm!: FormGroup;
    distributionForm!: FormGroup;
    scadenziarioForm!: FormGroup;
    rulesForm!: FormGroup;
    hasFormErrors: boolean = false;
    viewLoading: boolean = false;
    editMode: boolean = false;
    companies: any;
    selectedCompany: any;

    treeControl = new NestedTreeControl<PLNode>(node => node.children);
    dataSource = new MatTreeNestedDataSource<PLNode>();

    currentTabIndex: number = 0;

    utility: any;
    locale!: string;
    dates: any = [];
    datesList: any;

    table: any;

    distributionDate: any;
    distributionAmount: any = 100;

    agents: any = {};

    // Scadenziario
    banksList: any;
    bankOperationsList: any;
    paymentModalityList: any;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private anagraficheService: AnagraficheService,
        private translate: TranslateService,
        private translationService: TranslationService,
        private scadenziarioService: ScadenziarioService
    ) {
        this.utility = utils;

        this.translationService.performSwitchLanguage.subscribe((lang) => {
            this.locale = lang;
        });
    }

    createStructure(structure: any, level: number = 0) {
        let node = {};
        const initiallyChecked = this.content.LinkedProfittAndLossIds.find((item: any) => item.Id == structure.Id);
        const percentage = initiallyChecked && Object.keys(initiallyChecked).length > 0 ? initiallyChecked.Percentage : 0;
        const key = `${structure.Name}#${structure.Id}#${initiallyChecked && Object.keys(initiallyChecked).length > 0}#${percentage}`;

        node[key] = [];
        if (structure.Children && structure.Children.length > 0) {
            structure.Children.forEach(child => {
                node[key].push(this.createStructure(child, level + 1));
            });

            let result = {};
            result[key] = Object.values(node[key]);
            return result;

        } else {
            return key;
        }
    }

    async ngOnInit() {

        this.content = this.data.item;
        // Se non è impostato nulla allora lo metto come fornitore
        if (!this.content.SubjectType) {
            this.content.SubjectType = 1;
        }

        if (!this.content.LinkedProfittAndLossIds || this.content.LinkedProfittAndLossIds.length == 0) {
            this.content['LinkedProfittAndLossIds'] = [];
        }

        this.createForm();

        this.editMode = this.content.Id && this.content.Id > 0;

        this.viewLoading = true;

        let plStructure: any = [];

        const values = await lastValueFrom(
            combineLatest([this.scadenziarioService.getBanks(),
            this.scadenziarioService.getBankOperations(),
            this.scadenziarioService.getPaymentModality(),
            this.anagraficheService.getCompanies(),
            this.anagraficheService.getPLStructure()])
        )
        if (values && values.length !== 5) return;
        this.banksList = values[0];
        this.bankOperationsList = values[1];
        this.paymentModalityList = values[2];
        this.companies = values[3];
        plStructure = values[4];

        // Ottenuto la struttura la mando alla libreria per la tree view
        let structure = {};
        plStructure.forEach(node => {
            const parentStructure = this.createStructure(node);
            structure[`${node.Name}#${node.Id}`] = {};

            Object.keys(parentStructure).forEach(child => {
                parentStructure[child].forEach(child => {
                    if (typeof child === 'object') {
                        for (const [key, value] of Object.entries(child)) {
                            structure[`${node.Name}#${node.Id}`][key] = value
                        }
                    }
                })
            })

        });

        this.dataSource.data = this.initialize(structure);

        Object.keys(this.dataSource.data).forEach((x: any) => {
            // imposto il parent di ogni nodo
            this.setParent(this.dataSource.data[x], null);

            // vado a verificare se inizialmente c'è qualche nodo che deve essere flaggato.
            this.checkInitliallyChecked(this.dataSource.data[x])
        });
        this.viewLoading = false;

        $('body').on('keyup', (e: any) => {
            if (e.code == 'F2') {
                e.preventDefault();
                e.stopPropagation();
                this.onSubmit();
            }
        });
    }

    isSupplier() {
        return (this.content.SubjectType & 1) !== 0;
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        $('body').off('keyup');
    }

    /**
     * Close alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    change(event: any) {
        this.selectedCompany = event.value;
    }

    createForm() {

        let body: any = {
            Name: [this.content.Name, Validators.required],
            Code: [this.content.Code],
            Email: [this.content.Email, null],
            Phone: [this.content.Phone, null],
            Company: [this.content.Company, null]
        }

        if (this.isSupplier()) {
            body['MinOrder'] = [this.content.MinOrder, null];
            body['EDICode'] = [this.content.EDICode, null];
            body['Conditions'] = [this.content.Conditions, null];
        }

        this.generalForm = this.fb.group(body);

        body = {
            IsDisabled: [this.content.IsDisabled, null],
            EnableScadenziario: [this.content.EnableScadenziario, null]
        };

        if (this.isSupplier()) {
            body['CheckPrice'] = [this.content.CheckPrice, null];
            body['IsEDI'] = [this.content.IsEDI, null];
            body['IsDefault'] = [this.content.IsDefault, null];
            body['IsDisabled'] = [this.content.IsDisabled, null];
            body['PurchaseImportExcluded'] = [this.content.PurchaseImportExcluded, null];
            body['CEImportExcluded'] = [this.content.CEImportExcluded, null];
            body['IsPLDestribution'] = [this.content.IsPLDestribution, null];
            body['DistributeOnlyCreditNote'] = [this.content.DistributeOnlyCreditNote, null];
            body['IsEscludedFromPurchase'] = [this.content.IsEscludedFromPurchase, null];
            body['IsInternal'] = [this.content.IsInternal, null];
            body['UseXmlInvoiceForSchedule'] = [this.content.UseXmlInvoiceForSchedule, null];
        }

        this.settingsForm = this.fb.group(body);

        this.distributionForm = this.fb.group({
            DistributionMode: [this.content.DistributionMode ? this.content.DistributionMode : 0, null],
            DistributionFrequency: [this.content.DistributionFrequency ? this.content.DistributionFrequency : 2, null],
            DistributionRepetition: [this.content.DistributionRepetition, null],
            DistributionStart: [this.content.DistributionStart ? this.content.DistributionStart : 0, null],
            DistributionIncludeCurrentMonth: [this.content.DistributionIncludeCurrentMonth],
        });

        this.scadenziarioForm = this.fb.group({
            ScadenziarioMode: [this.content.PaymentType ? this.content.PaymentType : 1, null],
            ScadenziarioModalitaPagamento: [this.content.PaymentModalityId ? this.content.PaymentModalityId : null, null],
            ScadenziarioBanca: [this.content.BankId ? this.content.BankId : null, null],
            ScadenziarioTipologiaPagamento: [this.content.OperationId ? this.content.OperationId : null, null],
        });

        if (this.isSupplier()) {

            this.rulesForm = this.fb.group({
                GoodsRule: [this.content.GoodsRule, null],
            });

            // Completo la tabella se ci sono degli agents impostati
            if (this.content.Agents && this.content.Agents.length > 0) {
                this.content.Agents.forEach((agent: any) => {
                    this.agents[agent.Store] = agent.Email;
                })
            }

        }
    }

    /**
    * Returns page title
    */
    getTitle(): string {
        if (this.editMode) {
            return this.translate.instant('COMMONS.EDIT') + ` '${this.content.Name}'`;
        } else {
            switch (this.content.SubjectType) {
                case 1:
                    return this.translate.instant('ANAGRAFICHE.FORNITORI.NEW_ENTITY');
                case 2:
                    return this.translate.instant('ANAGRAFICHE.CLIENTI.NEW_ENTITY');
                case 4:
                    return this.translate.instant('ANAGRAFICHE.DIPENDENTI.NEW_ENTITY');
                default:
                    return '';

            }
        }
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string, form: FormGroup = this.generalForm): boolean {
        const control = form.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    /** ACTIONS */

    /**
     * Returns prepared customer
     */
    prepareItem(): any {
        const generalControls = this.generalForm.controls;

        this.content.Name = generalControls['Name'].value;
        this.content.Code = generalControls['Code'].value;
        this.content.Email = generalControls['Email'].value;
        this.content.Phone = generalControls['Phone'].value;
        this.content.Company = generalControls['Company'].value;

        if (this.isSupplier()) {
            this.content.MinOrder = generalControls['MinOrder'].value;
            this.content.EDICode = generalControls['EDICode'].value;
            this.content.Conditions = generalControls['Conditions'].value;
        }

        const settingsControls = this.settingsForm.controls;
        this.content.IsDisabled = settingsControls['IsDisabled'].value;
        this.content.EnableScadenziario = settingsControls['EnableScadenziario'].value;

        if (this.isSupplier()) {
            this.content.IsInternal = settingsControls['IsInternal'].value;
            this.content.CheckPrice = settingsControls['CheckPrice'].value;
            this.content.IsEDI = settingsControls['IsEDI'].value;
            this.content.IsDefault = settingsControls['IsDefault'].value;
            this.content.PurchaseImportExcluded = settingsControls['PurchaseImportExcluded'].value;
            this.content.CEImportExcluded = settingsControls['CEImportExcluded'].value;
            this.content.IsPLDestribution = settingsControls['IsPLDestribution'].value;
            this.content.DistributeOnlyCreditNote = settingsControls['DistributeOnlyCreditNote'].value;
            this.content.EnableScadenziario = settingsControls['EnableScadenziario'].value;
            this.content.IsEscludedFromPurchase = settingsControls['IsEscludedFromPurchase'].value;
            this.content.UseXmlInvoiceForSchedule = settingsControls['UseXmlInvoiceForSchedule'].value;
        }

        const distributionControls = this.distributionForm.controls;
        this.content.DistributionMode = distributionControls['DistributionMode'].value;
        this.content.DistributionStart = distributionControls['DistributionStart'].value;
        this.content.DistributionFrequency = distributionControls['DistributionFrequency'].value;
        this.content.DistributionRepetition = distributionControls['DistributionRepetition'].value;
        this.content.DistributionIncludeCurrentMonth = distributionControls['DistributionIncludeCurrentMonth'].value;

        if (this.isSupplier()) {

            //Regole merci
            const rulesControl = this.rulesForm.controls;
            this.content.GoodsRule = rulesControl['GoodsRule'].value;

            // Email ordini
            if (this.agents && Object.keys(this.agents).length > 0) {
                this.content.Agents = [];
                Object.keys(this.agents).forEach((store: string) => {
                    this.content.Agents.push({
                        SupplierId: this.content.Id,
                        Email: this.agents[store],
                        Store: store
                    })
                })
            } else {
                this.content.Agents = null
            }
        }

        const scadenziarioControls = this.scadenziarioForm.controls;
        this.content.PaymentType = scadenziarioControls['ScadenziarioMode'].value;
        this.content.PaymentModalityId = scadenziarioControls['ScadenziarioModalitaPagamento'].value;
        this.content.BankId = scadenziarioControls['ScadenziarioBanca'].value;
        this.content.OperationId = scadenziarioControls['ScadenziarioTipologiaPagamento'].value;

        return this.content;
    }

    /**
     * On Submit
     */
    onSubmit() {

        if(this.checkDisabled()) return;

        this.hasFormErrors = false;
        const generalControls = this.generalForm.controls;
        /** check form */
        if (this.generalForm.invalid) {
            Object.keys(generalControls).forEach(controlName => {
                generalControls[controlName].markAsTouched();
                console.log(controlName);
            });
            this.hasFormErrors = true;
            return;
        }

        const editedItem = this.prepareItem();
        this.viewLoading = true;
        if (this.editMode) {
            this.updateEntityItem(editedItem);
        } else {
            this.createEntityItem(editedItem);
        }
    }

    /**
     * Update customer
     *
     * @param _customer: CustomerModel
     */
    updateEntityItem(item: any) {
        this.anagraficheService.putEntity('Supplier', item).toPromise()
            .then(
                (ret: any) => {
                    console.log('updateEntityItem', ret);
                    this.viewLoading = false;
                    this.dialogRef.close({ item, isEdit: true });
                })
            .catch((error: any) => {
                this.viewLoading = false;
                this.dialogRef.close(null);
            }
            )
    }

    /**
     * Update customer
     *
     * @param _customer: CustomerModel
     */
    createEntityItem(item: any) {
        this.anagraficheService.postEntity('Supplier', item).toPromise()
            .then(
                (ret: any) => {
                    console.log('createEntityItem', ret);
                    this.viewLoading = false;
                    this.dialogRef.close({ item: ret, isEdit: false });
                })
            .catch((error: any) => {
                this.viewLoading = false;
                this.dialogRef.close(null);
            }
            )
    }

    /************************************************************************************************************************
     
    $$$$$$$$\ $$$$$$$\  $$$$$$$$\ $$$$$$$$\       $$\    $$\ $$$$$$\ $$$$$$$$\ $$\      $$\ 
    \__$$  __|$$  __$$\ $$  _____|$$  _____|      $$ |   $$ |\_$$  _|$$  _____|$$ | $\  $$ |
       $$ |   $$ |  $$ |$$ |      $$ |            $$ |   $$ |  $$ |  $$ |      $$ |$$$\ $$ |
       $$ |   $$$$$$$  |$$$$$\    $$$$$\          \$$\  $$  |  $$ |  $$$$$\    $$ $$ $$\$$ |
       $$ |   $$  __$$< $$  __|   $$  __|          \$$\$$  /   $$ |  $$  __|   $$$$  _$$$$ |
       $$ |   $$ |  $$ |$$ |      $$ |              \$$$  /    $$ |  $$ |      $$$  / \$$$ |
       $$ |   $$ |  $$ |$$$$$$$$\ $$$$$$$$\          \$  /   $$$$$$\ $$$$$$$$\ $$  /   \$$ |
       \__|   \__|  \__|\________|\________|          \_/    \______|\________|\__/     \__|
    
    ************************************************************************************************************************/


    initialize(treeData) {
        // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
        //     file node as children.
        const data = this.buildFileTree(treeData, 0);

        // Notify the change.
        return data;
    }

    getNodeInfos(node: string, infotype: InfoType) {
        const arr = node.split('#');
        let infos: any = {
            name: '',
            id: '',
            initiallyChecked: false,
            percentage: 0
        }
        if (arr.length > 1) {
            infos.name = arr[0];
            infos.id = arr[1];
            infos.initiallyChecked = arr[2] == 'true';
            infos.percentage = arr[3];
        } else {
            infos.name = arr[0];
        }
        return infos[infotype];
    }

    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `TodoItemNode`.
     */
    buildFileTree(obj: { [key: string]: any }, level: number): PLNode[] {
        return Object.keys(obj).reduce<PLNode[]>((accumulator, key) => {
            const value = obj[key];
            const node = new PLNode();
            node.name = this.getNodeInfos(key, InfoType.name);
            node.id = this.getNodeInfos(key, InfoType.id);
            node.initiallyChecked = this.getNodeInfos(key, InfoType.initiallyChecked);
            node.percentage = this.getNodeInfos(key, InfoType.percentage);

            if (value != null) {
                if (typeof value === 'object') {
                    node.children = this.buildFileTree(value, level + 1);
                } else {
                    node.name = this.getNodeInfos(value, InfoType.name);
                    node.id = this.getNodeInfos(value, InfoType.id);
                    node.initiallyChecked = this.getNodeInfos(value, InfoType.initiallyChecked);
                    node.percentage = this.getNodeInfos(value, InfoType.percentage);
                }
            }

            return accumulator.concat(node);
        }, []);
    }

    hasChild = (_: number, node: PLNode) =>
        !!node.children && node.children.length > 0;

    setParent(data, parent) {
        data.parent = parent;
        if (data.children) {
            data.children.forEach((x: any) => {
                this.setParent(x, data);
            });
        }
    }

    checkAllParents(node) {
        if (node.parent) {
            const descendants = this.treeControl.getDescendants(node.parent);
            node.parent.selected = descendants.every((child: any) => child.selected);
            node.parent.indeterminate = descendants.some((child: any) => child.selected);
            this.checkAllParents(node.parent);
        }
    }

    expandAllParents(node) {
        if (node.parent) {
            this.treeControl.expand(node.parent);
            this.expandAllParents(node.parent);
        }
    }

    checkInitliallyChecked(node) {
        if (node.initiallyChecked) {
            this.todoItemSelectionToggle(true, node);
            this.expandAllParents(node);
        } else if (node.children) {
            node.children.forEach((x: any) => {
                this.checkInitliallyChecked(x);
            });
        }
    }

    updatePercentage(event: any, node: any) {
        const index = this.content.LinkedProfittAndLossIds.findIndex((item: any) => item.Id == node.id);
        if (index >= 0) this.content.LinkedProfittAndLossIds[index].Percentage = event;
        this.calcDistribution();
    }

    todoItemSelectionToggle(checked, node) {
        node.selected = checked;

        if (node.children) {
            node.children.forEach((x: any) => {
                this.todoItemSelectionToggle(checked, x);
            });

        } else {
            if (this.content && !this.content.LinkedProfittAndLossIds) {
                this.content.LinkedProfittAndLossIds = [];
            }

            if (checked) {
                if (!this.content.LinkedProfittAndLossIds.find((item: any) => item.Id == node.id)) {
                    this.content.LinkedProfittAndLossIds.push({
                        Id: node.id,
                        Name: node.name,
                        Percentage: node.percentage
                    });
                }
            } else {
                this.content.LinkedProfittAndLossIds = this.content.LinkedProfittAndLossIds.filter((item: any) => item.Id != node.id);
            }

            this.calcDistribution();
        }

        this.checkAllParents(node);
    }

    totDistribution: number = 0;
    calcDistribution() {
        this.totDistribution = 0;
        if (this.content.LinkedProfittAndLossIds.length == 0) return;
        this.content.LinkedProfittAndLossIds.forEach((node: any) => {
            this.totDistribution += parseFloat(node.Percentage);
        });
    }

    checkDisabled() {
        //if (this.totDistribution > 0 || (this.content.LinkedProfittAndLossIds && this.content.LinkedProfittAndLossIds.length > 0)) {
        //    return this.totDistribution !== 100;
        //}
        return !this.generalForm.controls['Name'].value;
    }

    scrollToNode(node: any) {
        console.log('scrollToNode', node);
        const nodeEl = $(`kt-fornitori-edit .mat-checkbox-label:contains('${node.Name}')`).closest('.mat-tree-node');
        nodeEl[0].scrollIntoView({ behavior: 'smooth' });
    }

    /*
     /$$$$$$$  /$$$$$$  /$$$$$$  /$$$$$$$$ /$$$$$$$  /$$$$$$ /$$$$$$$  /$$   /$$ /$$$$$$$$ /$$$$$$  /$$$$$$  /$$   /$$
    | $$__  $$|_  $$_/ /$$__  $$|__  $$__/| $$__  $$|_  $$_/| $$__  $$| $$  | $$|__  $$__/|_  $$_/ /$$__  $$| $$$ | $$
    | $$  \ $$  | $$  | $$  \__/   | $$   | $$  \ $$  | $$  | $$  \ $$| $$  | $$   | $$     | $$  | $$  \ $$| $$$$| $$
    | $$  | $$  | $$  |  $$$$$$    | $$   | $$$$$$$/  | $$  | $$$$$$$ | $$  | $$   | $$     | $$  | $$  | $$| $$ $$ $$
    | $$  | $$  | $$   \____  $$   | $$   | $$__  $$  | $$  | $$__  $$| $$  | $$   | $$     | $$  | $$  | $$| $$  $$$$
    | $$  | $$  | $$   /$$  \ $$   | $$   | $$  \ $$  | $$  | $$  \ $$| $$  | $$   | $$     | $$  | $$  | $$| $$\  $$$
    | $$$$$$$/ /$$$$$$|  $$$$$$/   | $$   | $$  | $$ /$$$$$$| $$$$$$$/|  $$$$$$/   | $$    /$$$$$$|  $$$$$$/| $$ \  $$
    |_______/ |______/ \______/    |__/   |__/  |__/|______/|_______/  \______/    |__/   |______/ \______/ |__/  \__/
     */

    async performDistribution() {

        const DistributionMode = this.distributionForm.controls['DistributionMode'].value;
        const DistributionFrequency = this.distributionForm.controls['DistributionFrequency'].value;
        const DistributionStart = this.distributionForm.controls['DistributionStart'].value;
        const DistributionRepetition = this.distributionForm.controls['DistributionRepetition'].value;
        const DistributionIncludeCurrentMonth = this.distributionForm.controls['DistributionIncludeCurrentMonth'].value;
        let datesList = [];
        let startDate
        let count = 0;

        datesList = await this.anagraficheService.getDistributionDates(this.distributionDate, DistributionStart, DistributionMode, DistributionFrequency, DistributionIncludeCurrentMonth, DistributionRepetition)
            .toPromise();
        // Periodo
        // if (DistributionMode === 0) {
        //     startDate = moment(this.distributionDate);
        //     while (count < DistributionRepetition) {
        //         let date = '';
        //         switch (DistributionFrequency) {
        //             case 0: // start
        //                 // se la data di partenza è minore della data della fattura
        //                 // e l'inizio distribuzione è impostato come "after invoice"
        //                 // allora devo spostarmi avanti come partenza
        //                 if (startDate.startOf('month').isSameOrBefore(this.distributionDate) && DistributionStart === 1) {
        //                     date = startDate.add(1, 'months').startOf('month').format('YYYY/MM/DD');
        //                 } else {
        //                     date = startDate.startOf('month').format('YYYY/MM/DD');
        //                 }
        //                 break;
        //             case 1: //'middle'

        //                 // se la data di partenza è minore della data della fattura
        //                 // e l'inizio distribuzione è impostato come "after invoice"
        //                 // allora devo spostarmi avanti come partenza
        //                 if (startDate.date(15).isSameOrBefore(this.distributionDate) && DistributionStart === 1) {
        //                     date = startDate.add(1, 'months').date(15).format('YYYY/MM/DD');

        //                     // se la data di partenza è maggiore della data della fattura
        //                     // e l'inizio distribuzione è impostato come "before invoice"
        //                     // allora devo spostarmi indietro come partenza
        //                 } else if (startDate.date(15).isSameOrAfter(this.distributionDate) && DistributionStart === 0) {

        //                     date = startDate.subtract(1, 'months').date(15).format('YYYY/MM/DD');

        //                 } else {
        //                     date = startDate.date(15).format('YYYY/MM/DD');
        //                 }

        //                 break;

        //             // end
        //             default:
        //                 // se la data di partenza è maggiore della data della fattura
        //                 // e l'inizio distribuzione è impostato come "before invoice"
        //                 // allora devo spostarmi indietro come partenza
        //                 if (startDate.endOf('month').isSameOrAfter(this.distributionDate) && DistributionStart === 0) {
        //                     date = startDate.subtract(1, 'months').endOf('month').format('YYYY/MM/DD');
        //                 } else {
        //                     date = startDate.endOf('month').format('YYYY/MM/DD');
        //                 }
        //         }

        //         datesList.push(date);

        //         if (DistributionStart == 1) {
        //             startDate.add(1, 'month');
        //         } else {
        //             startDate.subtract(1, 'month');
        //         }

        //         count++;
        //     }
        //     // Annuale
        // } else if (DistributionMode === 1) {

        //     startDate = moment(DistributionRepetition, 'YYYY');

        //     if (DistributionRepetition < 2000 || !startDate.isValid()) return;

        //     for (let month = 1; month <= 12; month++) {
        //         let date;

        //         switch (DistributionFrequency) {
        //             case 0: // start
        //                 date = startDate.startOf('month').format('YYYY/MM/DD');
        //                 break;
        //             case 1: //'middle'
        //                 date = startDate.date(15).format('YYYY/MM/DD');
        //                 break;
        //             default: // end
        //                 date = startDate.endOf('month').format('YYYY/MM/DD');
        //         }

        //         startDate.add(1, 'month');

        //         datesList.push(date);
        //     }

        // }

        const amount = this.distributionAmount / datesList.length;
        const perc = (100 / this.distributionAmount) * amount;

        this.dates = [];
        datesList.forEach((date: any) => {
            this.dates.push({
                date: date,
                amount: amount,
                perc: perc
            })
        });

        this.initDataTable();

    }

    initDataTable() {

        if ($.fn.dataTable.isDataTable('#distributionTable')) {
            $('#distributionTable').DataTable().destroy();
        }

        setTimeout(() => {
            this.table = $('#distributionTable').DataTable({
                destroy: true,
                paging: false,
                searching: false,
                ordering: false,
                scrollY: '250px',
                scrollCollapse: true,
                autoWidth: true,
                language: {
                    emptyTable: this.translate.instant('COMMONS.EMPTY_TABLE'),
                    zeroRecords: this.translate.instant('COMMONS.ZERO_RECORDS'),
                    processing: '<img class="spinner-abs-centered" src="assets/media/gif/loader.gif" alt="">'
                },
            });
        });
    }

    /*
    /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$$  /$$$$$$$$ /$$   /$$ /$$$$$$$$ /$$$$$$  /$$$$$$  /$$$$$$$  /$$$$$$  /$$$$$$ 
    /$$__  $$ /$$__  $$ /$$__  $$| $$__  $$| $$_____/| $$$ | $$|_____ $$ |_  $$_/ /$$__  $$| $$__  $$|_  $$_/ /$$__  $$
    | $$  \__/| $$  \__/| $$  \ $$| $$  \ $$| $$      | $$$$| $$     /$$/   | $$  | $$  \ $$| $$  \ $$  | $$  | $$  \ $$
    |  $$$$$$ | $$      | $$$$$$$$| $$  | $$| $$$$$   | $$ $$ $$    /$$/    | $$  | $$$$$$$$| $$$$$$$/  | $$  | $$  | $$
    \____  $$| $$      | $$__  $$| $$  | $$| $$__/   | $$  $$$$   /$$/     | $$  | $$__  $$| $$__  $$  | $$  | $$  | $$
    /$$  \ $$| $$    $$| $$  | $$| $$  | $$| $$      | $$\  $$$  /$$/      | $$  | $$  | $$| $$  \ $$  | $$  | $$  | $$
    |  $$$$$$/|  $$$$$$/| $$  | $$| $$$$$$$/| $$$$$$$$| $$ \  $$ /$$$$$$$$ /$$$$$$| $$  | $$| $$  | $$ /$$$$$$|  $$$$$$/
    \______/  \______/ |__/  |__/|_______/ |________/|__/  \__/|________/|______/|__/  |__/|__/  |__/|______/ \______/ 
    */

    async performScadenziario() { }

    selectedCustomDatesScadenziario(event: any) {
        console.log('selectedCustomDatesScadenziario', event);
    }

    /********************************** */

    tabChanged(selectedTab: any) {
        if (selectedTab.index == 3) {
            if (!this.distributionDate) {
                this.distributionDate = moment();
                this.performDistribution();
            }
            this.distributionAmount = 100;
        } else if (selectedTab.index == 4) {
            if ($.fn.dataTable.isDataTable('#tableEmails')) {
                $('#tableEmails').DataTable().destroy();
            }

            setTimeout(() => {
                $('#tableEmails').dataTable({
                    scrollY: '300px',
                    destroy: true,
                    paging: false,
                    searching: false,
                    ordering: false,
                    scrollCollapse: true,
                    autoWidth: true,
                });
            });
        }
    }

    modeKeyDown(event: any) {
        if (event.code == 'Enter' || event.code == 'NumpadEnter') {
            this.performDistribution();
        }
    }
}
